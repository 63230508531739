<template>
    <base-modal 
        :title="computedTitle" 
        :visible="visible"
        @close="close()"
        no-padding
        :styles="`
            overflow-y: auto; 
            ${
                step == 1 ? `max-height: 80vh; max-width: 800px; width: 800px;` : 
                step == 2 ? `max-height: 80vh; max-width: 80vw; width: 80vw;` : 
                `max-height: 80vh; max-width: 80vw; width: 80vw;` 
            }`"
        headerStyles="position: sticky; z-index: 1; top: 0; left: 0; right: 0; background-color: #f8f7f1;"
    >

        <div class="container">
            
            <div class="content">

                <!-- step 1 - Network select -->
                <tab :visible="step == 1">
                    <div class="description">Select a platform to view {{computedFullName}}'s posts</div>
                    <div class="network-list">
                        <div 
                            class="item"
                            v-for="(network, index) in audienceDataNetworks" 
                            :key="index"
                            @click="selectNetwork(network)"
                        >
                            <base-icon size="10" :name="network.name" />
                            <div class="display-name">{{ network.display_name }}</div>
                        </div>
                    </div>
                </tab>

                <!-- Step 2 - View Audience Data -->
                <tab :visible="step == 2">
                    <div class="post-list">
                        <div class="empty-state" v-if="!top_posts || !top_posts.length">There are no posts for this platform</div>
                        <div class="post-item" v-for="(post, index) in top_posts" :key="index">
                            <div class="replaced-post-button-container" v-if="replacedPost(post)">   
                                <base-button label="Replaced post" class="font-bold h-8" @action="selectedReplacedPost = replacedPost(post); replacedPostModalVisible = true;" />
                            </div>
                            <report-post-card 
                                is-preview 
                                class="post-card mb-4"
                                :post="post"
                                allowReplace
                            />
                        </div>
                    </div>
                    <!-- replaced post modal -->
                    <base-modal
                        title="Replaced post"
                        :visible="replacedPostModalVisible"
                        @close="replacedPostModalVisible = false"
                        styles="overflow-y: auto; max-height: 80vh; max-width: 800px; width: 800px;"
                    >
                        <div class="replaced-post-modal-container">
                            <!-- content -->
                            <report-post-card 
                                is-preview 
                                class="post-card mb-4"
                                :post="selectedReplacedPost"
                            />
                            <!-- footer -->
                            <div class="footer w-full">
                                <base-button 
                                    label="Close" 
                                    @action="replacedPostModalVisible = false" 
                                    type="secondary" 
                                    size="md" 
                                />
                            </div>
                        </div>
                    </base-modal>
                </tab>
            </div>
            
            <div class="footer">
                <base-button 
                    label="Back" 
                    v-if="step == 2 && hasMultipleAudienceData" 
                    @action="back()"
                    icon="arrow-left"
                    type="secondary" 
                    bold
                    size="md" 
                />
                <base-button 
                    label="Close" 
                    bold
                    v-if="step == 1 || step == 2" 
                    @action="close()" 
                    type="secondary" 
                    size="md" 
                />
                <!-- <base-button label="Submit" v-if="step == 3" :disabled="submitDisabled()" @action="submit()"/> -->
            </div>
           
        </div>
    </base-modal>
</template>

<script>
    import { getNetwork, getParamsFromSocialPostUrl } from '@/lib/network';
    import ReportPostCard from '../../components/ReportPostCard';
    import { notifyCatchError } from '../../common'; 
    export default {
        name: 'ViewPostReplaceModal',
        props: ['visible', 'user', 'replacedPosts'],
        components: {
            ReportPostCard
        },
        data() {
            return {
                step: 1,
                selectedNetwork: null,
                loading: false,
                
                // the audience_data payload (for a specific network id)
                username: "",
                full_name: "",
                total_followers: 0,
                total_following: 0,
                total_likes: 0,
                profile_picture_url: "",

                // not editable
                description: '', // not used
                bio: '', // not editable
                social_id: '', // not editable
                profile_url: '',
                
                top_posts: [],

                uploadingFile: false,

                replacedPostModalVisible: false,
                selectedReplacedPost: null

            }
        },
        watch: {
            visible(val, old){
                if(val && !old && this.hasOneAudienceData){
                    this.selectNetwork(this.audienceDataNetworks[0]);
                }
            }
        },
        methods: {
            // navigation
            back(){
                this.step--;
                this.selectedNetwork = null;
            },
            close(){
                this.step = 1;
                this.selectedNetwork = null;
                this.replacedPostModalVisible = false;
                this.selectedReplacedPost = null;
                this.$emit('close');
            },
            selectNetwork(network){
                this.selectedNetwork = network;
                this.step = 2;
                this.setData();
            },
            setData(){
                
                // [getting audience data by network id]
                const audience_data = this.user.audience_data.find(element => element.network_id == this.selectedNetwork.id)
                const { payload } = audience_data
                const { user_profile } = payload

                // [setting audience data for user info]
                this.username = user_profile.username
                this.full_name = user_profile.fullname
                this.total_followers = user_profile.followers
                this.total_following = user_profile.following
                this.total_likes = user_profile.avg_likes
                this.profile_picture_url = user_profile.picture
                this.bio = user_profile.description
                this.social_id = user_profile.user_id
                this.profile_url = user_profile.url

                // [setting top posts]
                
                // setting platform
                let platform = null
                const networks = [
                    {id: 'facebook', name: 'Facebook', label: 'Facebook', value: 6},
                    {id: 'instagram', name: 'Instagram', label: 'Instagram', value: 2},
                    {id: 'tiktok', name: 'TikTok', label: 'TikTok', value: 14},
                    {id: 'twitter', name: 'Twitter', label: 'Twitter', value: 7},
                    {id: 'pinterest', name: 'Pinterest', label: 'Pinterest', value: 4},
                    {id: 'linkedin', name: 'LinkedIn', label: 'LinkedIn', value: 16},
                ];
                const found = networks.find(element => element.id == getNetwork(this.selectedNetwork.id, 'name'))
                if(found){
                    platform = found
                }

                // setting user profile for posts
                const userProfile = {
                    bio: user_profile.description,
                    uri: user_profile.url,
                    username: user_profile.username,
                    full_name: user_profile.fullname,
                    social_id: user_profile.user_id,
                    total_followers: user_profile.followers,
                    total_following: user_profile.following,
                    total_likes: user_profile.avg_likes,
                    profile_picture_url: user_profile.picture,
                    total_views: 0,
                    total_videos: 0
                }
                
                // setting top posts
                this.top_posts = user_profile.top_posts.map(top_post => {

                    // check medium, should be dependant on the post
                    let medium = { id: 'video', name: 'Video' }
                    let is_video = medium.id === 'video';
                    let video_duration = '00:00:00'

                    return {
                        post_identifier: top_post.post_id,
                        link: top_post.link,
                        date: top_post.created,
                        network: platform.name.toLowerCase(),
                        content_type_id: 1,
                        type: {id: 1, name: 'post'},
                        is_video: is_video,
                        url: top_post.video,
                        caption: top_post.text,
                        description: top_post.text,
                        display_url: top_post.thumbnail,
                        likes: top_post.stat.likes,
                        dislikes: top_post.stat.dislikes,
                        comments: top_post.stat.comments,
                        views: top_post.stat.views,
                        shares: top_post.stat.shares,
                        media: [
                            {mimetype: is_video ? 'video/mp4' : 'image/png', video_duration, url: top_post.video, poster: top_post.thumbnail}
                        ],
                        profile_item: userProfile
                    }
                });

            },

            async submit(){
                this.loading = true;
                try {
                    // user vars
                    const { username, full_name, total_followers, total_following, total_likes, profile_picture_url } = this
                    // post vars
                    const { description, platform, medium, url, display_url, date, caption, post_identifier, video_duration, likes, dislikes, comments, views, shares} = this
                    // other
                    const { post_url, profile_url, bio, social_id } = this

                    let { data } = await this.$http.post(`/api/users/manual-audience-data`, { 
                        userId: this.user.id,
                        networkId: this.networkId,
                        reportId: this.reportId,
                        ...( this.payloadExists ? [] : {
                            manualAudienceData: {
                                username,
                                full_name,
                                total_followers,
                                total_following,
                                total_likes,
                                profile_picture_url
                            }
                        }),
                        manualPost: {
                            
                            // user vars
                            profile_picture_url,
                            full_name,
                            username,
                            total_followers,
                            total_following,
                            total_likes,

                            // post vars
                            post_url,
                            description,
                            platform,
                            medium,
                            url,
                            display_url,
                            date,
                            caption,
                            post_identifier,
                            video_duration,
                            likes,
                            dislikes,
                            comments,
                            views,
                            shares,

                            // other
                            profile_url,
                            bio,
                            social_id

                        } 
                    });

                    this.$notify({ title: 'Saved Successfully', text: `You can manually add more posts for this creator`, type: 'success' });
                    
                    // reset to initial state
                    this.post_url = ''
                    this.url = ''
                    this.display_url = ''
                    this.video_duration = '00:00:00'
                    this.caption = ''
                    this.post_identifier = ''
                    this.date = ''
                    this.likes = 0
                    this.dislikes = 0
                    this.comments = 0
                    this.views = 0
                    this.shares = 0

                    this.payloadExists = true;
                    this.step = 2;

                }
                catch(err) {
                    console.log(err);
                    notifyCatchError(err, this.$notify, 'Unable to save manual post. Please try again later')
                }
                finally {
                    this.loading = false;
                }
            },
            
            // validation, check if we are using this
            submitDisabled() {
                let requiredFields = ['url', 'display_url', 'video_duration', 'caption', 'post_identifier', 'date', 'likes', 'dislikes', 'comments', 'views', 'shares' ];
                // validation
                let errors = 0;
                for (let i in requiredFields) {
                    let val = this[requiredFields[i]];
                    if (val === undefined || val === null || val === '' || (Array.isArray(val) && !val.length)) {
                        errors++;
                    }
                }
                return !!errors;
            },

            async getInfoFromPostUrl() {
                // remove query params
                this.post_url = this.post_url.split('?')[0]
                const params = getParamsFromSocialPostUrl(this.post_url)
                if(params.postId) this.post_identifier = params.postId;
                if(params.videoId) this.post_identifier = params.videoId;
            },

            // file uploads
            uploadProfilePicture(e){
                this.uploadFile(e, 'profile_picture_url');
            },
            uploadMediaFile(e){
              this.uploadFile(e, 'url');
            },
            uploadDisplayFile(e){
              this.uploadFile(e, 'display_url');
            },
            uploadFile(e, path) {
              this.uploadingFile = true;
              let fileData = new FormData();
              fileData.append('file', e.target.files[0]);
              this.$http.post(`/api/files/manual/posts`, fileData).then(async ({data}) => {
                this[path] = data.url;
              }).catch((err) => {
                this[path] = '';
                console.log('upload picture error', err.response);
                this.$notify({ title: 'Something went wrong while uploading media.', text: 'Try again later', type: 'warn' });
              }).finally(() => {
                this.uploadingFile = false;
              });
            },
            openLink(url) {
              if (url !== '') {
                let a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                a.click();
              }
            },
            replacedPost(p){
                if(!this.replacedPosts) return null;
                const foundReplacedPost = this.replacedPosts.find(element => {
                    return element.original_url == p.link
                })
                if(!foundReplacedPost || !foundReplacedPost.post) return null;
                let post = foundReplacedPost.post;
                return {
                    ...post,
                    network: getNetwork(post.network_id)
                }
            }
        },
        computed: {

            computedFullName(){
                if(!this.user) return `creator`;
                return `${this.user.first_name} ${this.user.last_name}`;
            },

            computedTitle(){
                if(this.step == 2){
                    return `Showing ${this.computedFullName}'s ${this.selectedNetwork.display_name} posts` 
                }
                return 'Available post data';
            },

            audienceDataNetworks() {
                if(!this.user) return [] 
                return this.user.audience_data.map(element => {
                    return getNetwork(element.network_id)
                })
            },

            hasOneAudienceData(){
                return this.audienceDataNetworks.length == 1;
            },

            hasMultipleAudienceData(){
                return this.audienceDataNetworks.length > 1;
            },

            computedManualPost() {
                let {
                    description,
                    platform,
                    medium,
                    url,
                    display_url,
                    date,
                    caption,
                    profile_picture_url,
                    bio,
                    full_name,
                    username,
                    profile_url,
                    social_id,
                    post_identifier,
                    video_duration,
                    likes,
                    dislikes,
                    comments,
                    views,
                    shares,
                    total_followers,
                    total_following,
                    total_likes,
                } = this;

                platform.name = platform.name.toLowerCase();
                let is_video = medium.id === 'video';

                return {
                    post_identifier,
                    date,
                    network: platform,
                    content_type_id: 1,
                    type: {id: 1, name: 'post'},
                    is_video,
                    url,
                    caption,
                    description,
                    display_url,
                    likes,
                    dislikes,
                    comments,
                    views,
                    shares,
                    media: [
                        {mimetype: is_video ? 'video/mp4' : 'image/png', video_duration, url, poster: display_url}
                    ],
                    profile_item: {
                        bio,
                        uri: profile_url,
                        username,
                        full_name,
                        social_id,
                        total_followers,
                        total_following,
                        total_likes,
                        profile_picture_url,
                        total_views: 0,
                        total_videos: 0
                    }
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    // global
    .field{
        @apply gap-y-1;
    }
    .label{
        @apply text-h6 font-bold;
    }
    .container{
        @apply flex flex-col justify-between;
        min-height: 260px;

        .content{
            @apply py-6 px-14;
            .description{
                @apply text-h5 font-bold mb-4;
            }
            // network list
            .network-list{
                @apply flex gap-x-4;
                .item{
                    width: 150px;
                    height: 150px;
                    @apply border border-gray-300 rounded-lg;
                    @apply flex flex-col justify-center items-center gap-y-4;
                    @apply cursor-pointer transition-colors duration-500;
                    &:hover{
                        @apply bg-gray-300;
                    }
                    .display-name{
                        @apply font-bold;
                    }
                }
            }
            
            .post-list{
                @apply w-full;
                @apply flex justify-center flex-wrap gap-12;
                .post-item{
                    //
                    .replaced-post-button-container{
                        @apply w-full bg-black text-white flex justify-center items-center py-2;
                    }
                }
            }
            .replaced-post-modal-container{
                @apply flex flex-col justify-center items-center;
                > .footer{
                    @apply sticky w-full bottom-0 left-0 right-0 bg-tan-m py-6 px-14 flex justify-center;
                    z-index: 80;
                }
            }
        }

        > .footer{
            @apply sticky bottom-0 left-0 right-0 bg-tan-m py-6 px-14 flex justify-between;
            z-index: 80;
        }
    }
</style>

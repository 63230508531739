<template>
    <button type="button" @click.stop="toggle"
    :class="['relative inline-flex flex-shrink-0 h-5 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500',
            on ? 'bg-gray-m-dark' : 'bg-gray-m-light',
            disabled ? 'cursor-not-allowed' : ' cursor-pointer']">
        <span class="sr-only">Use setting</span>
        <span aria-hidden="true" :class="['inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200', 
            on ? 'translate-x-6' : 'translate-x-0']"></span>
    </button>
</template>
<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        on() {
            return this.value ? true : false;
        }
    },

    methods: {
        toggle() {
            if(!this.disabled)
                this.$emit('toggled', !this.value);
        }
    }
}
</script>
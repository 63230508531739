<template>
  <div @click="$emit('open')" class="w-36 md:w-80 cursor-pointer space-y-6 flex flex-col flex-no-wrap rounded-2xl overflow-hidden break-inside">
    <div class="relative">
      <div class="absolute bottom-0 px-2 py-2 w-full text-white bg-gradient-to-b from-transparent to-black uppercase flex gap-1">
        <base-icon name="views-count"/>
        {{ post.views | numeral('0,0a') }}
      </div>
      <img class="object-cover animate-fade-in rounded-xl" width="100%" height="240px" :src="post.display_url" alt="Post poster" @error="changeMedia"/>
    </div>
  </div>
</template>

<script>
  const defaultPicture = require('../assets/images/default-picture.png');
  export default {
    props: {
      post: {},
    },

    methods: {
      changeMedia() {
        this.post.display_url = defaultPicture;
        this.$emit('redraw');
      }
    }
  }
</script>

<style lang="scss">
</style>

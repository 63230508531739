<template>
<div class="wrapper" @mouseover="collapsed = false;" @mouseleave="collapsed = true;">
    
    <!-- Mobile Sidebar Pending -->
    <!-- ... -->

    <!-- Desktop Sidebar -->
    <div class="desktop-container">

        <div class="sidebar" :class="{ 'collapsed': collapsed }">
            
            <div class="logo" @click="$router.push('/home')">
                <img v-if="collapsed" src="../assets/images/icon.svg" />
                <img v-else src="../assets/images/logo.svg" />
            </div>

            <nav class="content">

                <div class="navigation">

                    <div 
                        v-for="(item, index) in computedNavigationItems" 
                        :key="index" 
                        :class="`item ${isActive(item.value) ? 'active' : ''}`"
                    >
                        <base-button
                            @action="$router.push(item.route)" 
                            type="label" 
                            :icon="item.icon"
                            theme="dark"
                            :active="isActive(item.value)" 
                            :label="( !collapsed ) ? item.label : null"   
                            class="font-bold"                             
                        />
                    </div>

                    <notification-bell 
                        :isActive="isActive('/notifications')"
                        :collapsed="collapsed"
                    />

                </div>
            </nav>

            <!-- footer -->
            <div class="footer-wrapper" v-if="user.id">
                <div class="footer-container">
                    <options-dropdown
                        top
                        right
                        grow
                        no-bg-icon="chevron-down"
                        hide-secondary-icon
                        :custom-z-index="0"
                        :options="[
                            {label: 'Profile Settings', icon: 'cog', event: 'profile-settings'},
                            ...(isAdminOrHigher  ? [{ label: 'Company Settings', icon: 'cog', event: 'company-settings' }]: []),
                            {label: 'Logout', icon: 'logout', event: 'logout'}
                        ]"
                        @profile-settings="$router.push(`/profile/edit`)"
                        @company-settings="$router.push(`/company`)"
                        @logout="logout"
                    >
                        <template v-slot:icon>
                            <div class="user-container">
                                <avatar class="mr-2" size="medium" :user="user"/>
                            </div>
                        </template>
                    </options-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import NotificationBell from './NotificationBell.vue'
export default {
    components: {
        NotificationBell
    },
    data() {
        return {
            collapsed: true,
            navigationItems: {
                auth: [
                    {
                        label: 'Projects', // The label
                        value: ['/home', '/projects'], // used to see if route is active ('projects', '/', '/home')
                        route: '/home', // will take you to this route
                        icon: 'folder'
                    },
                    { label: 'Deliverables', value: '/deliverables', route: '/deliverables', icon: 'deliverable-check' },
                    { label: 'Reports', value: '/reports', route: '/reports', icon: 'graph-stats' },
                    { label: 'Creators', value: '/creators', route: '/creators', icon: 'user-group' },
                    //{ label: 'Agents', value: '/agents', route: '/agents', icon: 'user-group' },
                    { label: 'Team Members', value: '/team', route: '/team', icon: 'user-circle' }
                ],
                collaborator: [
                    { label: 'Projects', value: ['/home', '/projects'], route: '/home', icon: 'folder' },
                    { label: 'Deliverables', value: '/deliverables', route: '/deliverables', icon: 'deliverable-check' },
                    { label: 'Creators', value: '/creators', route: '/creators', icon: 'user-group' },
                    //{ label: 'Agents', value: '/agents', route: '/agents', icon: 'user-group' },
                    { label: 'Team Members', value: '/team', route: '/team', icon: 'user-circle' }
                ],
                guest: [
                    { label: 'Login', value: '/login', route: '/login', icon: 'guest-user' },
                    { label: 'Register', value: '/register', route: '/register', icon: 'deliverable-check' }
                ],
                client: [
                    { label: 'Projects', value: ['/home', '/projects'], route: '/home', icon: 'folder' },
                    { label: 'Deliverables', value: '/deliverables', route: '/deliverables', icon: 'deliverable-check' }
                ],
                creator: [ 
                    { label: 'Projects', value: ['/home', '/projects'], route: '/home', icon: 'folder' },
                    { label: 'Profile', value: '/me', route: '/me', icon: 'user-circle' } 
                ]
            },
        }
    },

    mounted(){
    },

    computed: {
        ...mapGetters(['user', 'isCollaborator', 'isAdminOrHigher', 'isOnlyCreator']),
        computedNavigationItems(){
            if(this.user.id){
                if(this.user.roles && this.user.roles.includes('client')){
                    return this.navigationItems.client
                }
                if(this.isCollaborator){
                    return this.navigationItems.collaborator
                }
                if(this.isOnlyCreator){
                    return this.navigationItems.creator
                }
                return this.navigationItems.auth
            }else{
                return this.navigationItems.guest
            }
        }
    },

    methods: {
        
        isActive(value){
            let arr = typeof value === 'string' ? [value] : value
            let matchFound = false
            for (const val of arr) {
                if(this.$route.path.includes(val)){
                    matchFound = true
                }
            }
            return matchFound
        },

        async logout() {
            await this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        },
    }
}
</script>
<style lang="scss" scoped>
    .wrapper{
        @apply flex;
        > .desktop-container{
            @apply hidden flex flex-shrink-0;
            
            > .sidebar{
                @apply flex flex-shrink-0 flex-col py-10 bg-purple-m-main w-64;
                transition: width 0.2s;

                > .logo{
                    @apply flex items-center flex-shrink-0 cursor-pointer px-10;
                    & img{
                        @apply h-full w-full object-contain;
                        max-height: 32px; 
                        height: 32px;
                    }
                }

                > .content{
                    @apply overflow-visible flex-1 flex flex-col mt-20;

                    > .navigation{
                        @apply px-0 flex flex-col gap-y-2;

                        > .item{
                            @apply flex justify-start items-center border-transparent h-10 w-full pl-10 border-l-4;
                            &.active{
                                @apply border-green-m-main;
                            }
                        }

                    }
                }

                // all styles for collapsed state
                &.collapsed{
                    @apply w-28 items-center;
                    > .logo{
                        @apply px-8;
                    }
                    > .content{
                        @apply w-full;
                        > .navigation {
                            @apply flex flex-col items-center;
                        }
                    }
                    > .footer-wrapper{
                        > .footer-container{
                            @apply flex-col items-center;
                        }
                    }
                }
                > .footer-wrapper{
                    @apply self-start flex flex-col flex-grow justify-end gap-4;
                    > .footer-container{
                        @apply ml-5 flex gap-4 items-center;
                        // not direct children
                        .user-container{
                            @apply flex justify-start items-center gap-2 ml-2;
                        }
                    }
                }
            }
        }
    }
</style>
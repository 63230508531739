<template>
    <div class="container">
        <loader label="Preparing Google Account" visible/>
        <div class="overflow-hidden max-h-0">
            <linked-accounts is-logged/>
        </div>
    </div>
</template>

<script>
import LinkedAccounts from '../ProfileEdit/LinkedAccounts.vue';
export default {
    components: {
        LinkedAccounts
    },
    
    data() {
        return {
            data: ''
        }
    },
    beforeMount() {
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
    .container{
        @apply py-16 px-24;
    }
</style>
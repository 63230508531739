<template>
    <div class="header">
        <div class="left">
            <img style="width: 150px;" src="../assets/images/logo.svg" />
            <div class="text-pxs font-bold text-white text-bottom">Docs</div>
        </div>
        <div class="right">
            <div class="search-input-container" @click="searchModalVisible = true">
                <div class="icon-container">
                    <base-icon name="search" class="absolute" />
                </div>
                <input 
                    placeholder="Search Docs" 
                    class="search-input"
                    readonly
                />
            </div>
            <search-modal :visible="searchModalVisible" @close="searchModalVisible = false"/>
        </div>
    </div>
</template>

<script>
import SearchModal from './SearchModal.vue'
export default {
    components: {
        SearchModal
    },
    data() {
        return {
            loading: false,
            searchModalVisible: false
        }
    },
    mounted(){
         
    }
}
</script>
<style lang="scss" scoped>
    .header{
        @apply bg-purple-m-main flex-shrink-0 flex-grow-0 flex justify-between items-center px-12 py-4 border-b border-white sticky top-0 left-0 z-20;
        .left{
            @apply flex items-end gap-x-1;
        }
    }
    .search-input-container{
        @apply relative cursor-pointer transition-opacity duration-500;
        &:hover{
            @apply opacity-50;
        }
        .icon-container{
            @apply absolute left-3 top-0 bottom-0 flex items-center;
        }
        .search-input{
            @apply pl-10 py-2 rounded-xl cursor-pointer;
        }
    }
</style>
<template>
<div class="w-full flex gap-x-6 flex-wrap">
    <item 
        v-for="(option, index) in options" 
        :key="index"
        :data="option"
    />
</div>
</template>
<script>
import Item from './DeliverableTypeSelectItem.vue'
export default {
    components: {
        Item
    },
    data(){
        return {
            options: [
                {
                    parentDeliverable: 'Photograph',
                    network: {
                        name: 'Instagram',
                        slug: 'instagram'
                    },
                    label: 'In-feed',
                    budget: '$3,000'
                },
                {
                    parentDeliverable: 'Photograph',
                    network: {},
                    label: '',
                    budget: ''
                },
                {
                    parentDeliverable: 'Video',
                    network: {
                        name: 'Tiktok',
                        slug: 'tiktok'
                    },
                    label: 'TikTok',
                    budget: ''
                }
            ]
        }
    },
    mounted(){
        // this.getDeliverableTypes()
        // this.getDeliverables()
    },
    methods: {
        // // parents
        // async getDeliverablesTypes() {
        //     const { data } = await this.$http.get('/api/userDeliverables/deliverablesTypes');
        //     this.parents = data.map(type => {
        //         type.value = type.id;
        //         type.id = type.slug;
        //         return type;
        //     });
        // },
        // // childs
        // async getDeliverables() {
        //     const { data } = await this.$http.get('/api/userDeliverables/deliverables');
        //     this.childs = data;
        // },
    }
}
</script>
<style lang="scss" scoped>

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Title',{attrs:{"type":"main"}},[_vm._v("Base Modal")]),_c('Title',[_vm._v("Usage")]),_c('Code',{attrs:{"title":"Simple use","code":"\n        <base-modal\n            :visible=\"modalVisible\"\n            @close=\"modalVisible = false\"\n        />\n    "}}),_c('Code',{attrs:{"title":"Use with custom options","code":"\n        <base-modal \n            visible=\"\"\n            invisible=\"\" \n            title=\"\" \n            hideHeader=\"\"\n            classes=\"\"\n            hideCloseButton=\"\"\n            headerStyles=\"\"\n            titleStyles=\"\"\n            overlayClasses=\"\"\n            styles=\"\"\n            withPicture=\"\"\n            modalTitle=\"\" \n            modalSubTitle=\"\" \n            modalIcon=\"\" \n            mode=\"\" \n            noPadding=\"\" \n            centerTitle=\"\"\n            preventEscape=\"\" \n            bigger=\"\" \n        />\n    "}}),_c('Title',[_vm._v("Main Props")]),_c('Table',{attrs:{"title":"These are the main props for the component:","columns":['Name', 'Type', 'Default', 'Description'],"data":[
            ['visible', 'Boolean', '-', 'Toggles the visibility of the modal'],
            ['title', 'String', '-', 'The title of the modal'],
            ['hideHeader', 'Boolean', '-', 'Hides the header'],
            ['hideCloseButton', 'Boolean', '-', 'Hides the close button'],
            ['noPadding', 'Boolean', 'false', 'Use this in case you want to handle your own padding inside the modal']
        ]}}),_c('Title',[_vm._v("Style Props")]),_c('Table',{attrs:{"title":"These are the props for styling:","columns":['Name', 'Type', 'Default', 'Description'],"data":[
            ['classes', 'String', '-', 'Classes to be applied to the modal itself'],
            ['headerStyles', 'String', '-', 'Styles to be applied to the header'],
            ['titleStyles', 'String', '-', 'Styles to be applied to the title'],
            ['overlayClasses', 'String', '-', 'Styles to be applied to the overlay'],
            ['styles', 'String', '-', 'Styles to be applied to the modal itself']
        ]}}),_c('Title',[_vm._v("Additional Props")]),_c('Table',{attrs:{"title":"These are the main props for the component:","columns":['Name', 'Type', 'Default', 'Description'],"data":[
            ['withPicture', 'Boolean', 'false', 'Shows the dinosaur themed sidebar, requires the next 3 props:'],
            ['modalTitle', 'String', '-', 'The title for the dinosaur themed sidebar'],
            ['modalSubTitle', 'String', '-', 'The subtitle for the dinosaur themed sidebar'],
            ['modalIcon', 'String', '-', 'The icon for the dinosaur themed sidebar'],
            ['mode', 'String', 'dark', 'The theme of the modal, right now it only supports the dark theme'],
            ['centerTitle', 'Boolean', 'false', 'Centers the title'],
            ['preventEscape', 'Boolean', 'false', 'Prevents closing the modal with the escape button'],
            ['bigger', 'Boolean', 'false', 'Applies the class max-w-6xl to the modal element']
            ['invisible', 'Boolean', '-', 'The modal will be rendered, but an `invisible` class will be added to the containers'] ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
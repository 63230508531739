<template>
    <base-modal 
        title="Post Summary" 
        :visible="visible"
        styles="min-width: 900px; width: 900px"
        @close="close()"
    >
        <div class="container" v-if="post && post.id">
            <div class="title">{{post.profile_item.username}}'s {{getNetwork(post.network_id, 'display_name')}} post</div>
            <div class="content">
                
                <div class="top">
                    <div class="text">We were able to retrieve this data from the post:</div>
                    <div class="list">
                        <div v-if="!validProperties.length">No data</div>
                        <stat-card
                            v-for="property in validProperties"
                            :key="`stat-card-${property.name}`"
                            :label="property.label"
                            :icon="property.icon"
                            :value="property.value(post)"
                            :iconSize="3"
                            :cardSize="8"
                            :type="'mini'"
                        />
                    </div>
                </div>

                <div class="bottom">
                    <div class="left">
                        <div class="text" v-if="!unvalidProperties.length">All fields are filled</div>
                        <div class="text" v-else>Please fill in the missing fields:</div>
                        
                        <div class="fields">
                            
                            <div 
                                class="field" 
                                v-for="property in unvalidProperties" 
                                :key="`field-${property.name}`"
                            >
                                <div class="label">
                                    <div>
                                        {{property.label}}
                                        <span v-if="property.required && !property.validator(property.vmodel)"> *</span>
                                    </div>  
                                    <span 
                                        class="todays-date-button"
                                        :class="{ 'disabled': loading }" 
                                        v-if="property.type == 'date'"
                                        @click="property.vmodel = getTodaysDate()"
                                    >
                                        Use today's date
                                    </span>
                                </div>
                                <FormulateInput
                                    v-if="property.type == 'number'"
                                    v-model="property.vmodel"
                                    type="number"
                                    :disabled="loading"
                                    wrapper-class="border rounded-md py-2 px-3 bg-white"
                                    outer-class="formulate-input w-44"
                                />
                                <date-picker
                                    v-if="property.type == 'date'"
                                    :value="property.vmodel"
                                    :disabled="loading"
                                    inputWrapperClass="border rounded-md py-2 px-3 bg-white"
                                    inputOuterClass="formulate-input w-44"
                                    @change="(date)=>property.vmodel = date"
                                />
                            </div>


                        </div>

                    </div>
                    <div class="right" v-if="post.url">
                        
                        <iframe 
                            width="320" 
                            height="440" 
                            :class="{ 'disabled': loading }"
                            :src="`${post.url}embed/`" 
                            frameborder="0"
                        >
                        </iframe>

                        <base-button
                            v-if="post.url"
                            @action="viewPost(post.url)"
                            :disabled="loading"
                            class="font-bold"
                            type="secondary"
                            label="View Post"
                        />
                    </div>
                </div>

            </div>
            <div class="footer">
                <base-button
                    label="Close"
                    type="secondary"
                    :disabled="loading"
                    @action="close()"
                />
                <div class="flex justify-end items-center gap-x-4">
                    <base-icon
                        v-if="loading"
                        name="loader"
                    />
                    <base-button
                        v-if="unvalidProperties.length"
                        label="Update Post"  
                        :disabled="loading"
                        bold
                        @action="updatePost()"
                    />
                    <base-button
                        v-else
                        label="Continue"  
                        :disabled="loading"
                        bold
                        @action="close()"
                    />
                </div>
            </div>
        </div>
    </base-modal>
</template>

<script>
import { getNetwork } from '@/lib/network';
import StatCard from '../../components/StatCard.vue';
import moment from 'moment';
import { isNumber, isString, isValidDate } from '../../common';
import { formatDateTimezone } from '../../lib/strings';

export default {
    components: { StatCard },
    name: 'PostSummaryModal',
    props: ['visible', 'post', 'reportId'],
    data() {
        return {
            properties: [
                {
                    name: 'likes',
                    label: 'Likes',
                    icon: 'post-like',
                    type: 'number',
                    required: true,
                    validator: (val) => this.validateNumber(val), 
                    value: (obj) => obj['likes'],
                    vmodel: 0
                },
                { 
                    name: 'comments',
                    label: 'Comments', 
                    icon: 'post-comments', 
                    type: 'number',
                    required: true,
                    validator: (val) => this.validateNumber(val), 
                    value: (obj) => obj['comments'],
                    vmodel: 0
                },
                { 
                    name: 'views',
                    label: 'Views', 
                    icon: 'post-views', 
                    type: 'number',
                    required: true,
                    validator: (val) => this.validateNumber(val), 
                    value: (obj) => obj['views'],
                    vmodel: 0
                },
                { 
                    name: 'date', 
                    label: 'Date', 
                    icon: 'calendar', 
                    type: 'date',
                    required: true, 
                    validator: (val) => isValidDate(val), 
                    value: (obj) => moment(obj.date).format('YYYY-MM-DD'),
                    vmodel: ''
                },
                { 
                    name: 'followers',
                    label: 'Followers', 
                    icon: 'post-reach', 
                    type: 'number',
                    required: false,
                    validator: (val) => this.validateNumber(val), 
                    value: (obj) => obj.profile_item.total_followers,
                    vmodel: 0
                },
                { 
                    name: 'shares',
                    label: 'Shares', 
                    icon: 'post-share', 
                    type: 'number',
                    required: false,
                    validator: (val) => this.validateNumber(val), 
                    value: (obj) => obj['shares'],
                    vmodel: 0
                },
                { 
                    name: 'saves',
                    label: 'Saves', 
                    icon: 'post-save', 
                    type: 'number',
                    required: false,
                    validator: (val) => this.validateNumber(val), 
                    value: (obj) => obj['saves'],
                    vmodel: 0
                }
            ],
            loading: false,
            getNetwork
        }
    },
    computed: {
        validProperties(){
            return this.properties.filter(property => {
                return property.validator(property.value(this.post))
            });
        },
        unvalidProperties(){
            return this.properties.filter(property => {
                return !property.validator(property.value(this.post))
            });
        }
    },
    methods: {
        getTodaysDate(){
            return moment().format('YYYY-MM-DD')
        },
        // also checks its if its a number in a string and that its not 0
        validateNumber(val){
            if(isString(val)){
                const int = parseInt(val)
                return isNumber(int) && int > 0;
            }
            return isNumber(val) && val > 0;
        },
        viewPost(url){
            window.open(url, '_blank')
        },
        // get unvalid properties values, if field was not filled returns null
        getPropertiesValues(){
            const obj = { };
            for (const property of this.unvalidProperties) {
                const { type, vmodel, name } = property
                obj[name] = null;
                if(type == 'number'){
                    if(vmodel && vmodel != 0){
                        obj[name] = vmodel
                    }
                }
                if(type == 'date'){
                    if(vmodel && vmodel.trim() != ''){
                        obj[name] = vmodel
                    }
                }
            }
            return obj
        },
        close(){
            for (const property of this.properties) {
                let val = 0;
                if(property.name == 'date') val = '';
                property.vmodel = val
            }
            this.$emit('close');
        },
        async updatePost() {
            try {
                this.loading = true;

                // these return null if its an already valid property or if the field was no filled (0 or '')
                let { likes, comments, views, date, followers, shares, saves } = this.getPropertiesValues();
                // properties that the post already has
                let { id, caption, profile_item, is_video, display_url, dislikes, url, media } = this.post;
                
                if(followers){
                    profile_item.total_followers = followers
                }
                if(date){
                    date = formatDateTimezone(date)
                }
                let post = { 
                    id, 
                    caption, 
                    is_video, 
                    display_url, 
                    dislikes, 
                    url, 
                    media,

                    profile_item,
                    
                    likes: likes ? likes : this.post.likes, 
                    comments: comments ? comments : this.post.comments, 
                    shares: shares ? shares : this.post.shares, 
                    saves: saves ? saves : this.post.saves, 
                    views: views ? views : this.post.views, 
                    date: date ? date : this.post.date,

                    ...( this.reportId ? { reportId: this.reportId } : {} )
                };
                const { data } = await this.$http.post(`/api/reports/update-post`, post);
                this.$notify({ title: 'Success', text: `Content updated successfully`, type: 'success' });
                this.$emit('done');
                this.close();
            } catch(e) {
                console.log(e);
                this.$notify({ title: 'Error', text: `Error updating post, please try again later!`, type: 'warn' });
            } finally {
                this.loading = false;
            }
        }
    }    
}
</script>
<style lang="scss" scoped>
    .container{
        @apply flex flex-col;
        .title{
            @apply font-bold text-h5;
        }
        .content{
            .top{
                @apply border-b border-solid border-gray-200;
                .text{
                    @apply text-pxs;
                }
                .list{
                    @apply py-6 flex flex-wrap justify-center gap-x-6 gap-y-4;
                }
            }
            .bottom{
                @apply flex;
                .left{
                    @apply pt-6 flex-grow flex-shrink;
                    .text{
                        @apply font-bold text-h5;
                    }
                    .fields{
                        @apply mt-3 flex flex-wrap gap-x-6 gap-y-4;
                        > .field{
                            @apply flex flex-col gap-y-1;
                            > .label{
                                @apply flex justify-between items-center font-bold;
                                > div span{
                                    @apply text-red-500;
                                }
                                > span.todays-date-button{
                                    @apply text-pxs font-normal text-blue-600 cursor-pointer;
                                    @apply transition-colors duration-500;
                                    &:hover{
                                        @apply text-blue-900;
                                    }
                                    &.disabled{
                                        @apply pointer-events-none;
                                    }
                                }
                            }
                        }
                    }
                }
                .right{
                    @apply flex-grow-0 flex-shrink-0 py-6 pl-6 border-l border-solid border-gray-200;
                    @apply flex flex-col items-center gap-y-6;
                    > iframe{
                        &.disabled{
                            @apply pointer-events-none; 
                        }
                    }
                }
            }
        }
        .footer{
            @apply border-t border-solid border-gray-200 mt-0;
            @apply pt-6 flex justify-between;
        }
    }
</style>



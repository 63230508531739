<template>
    <div class="inset-0 fixed bg-black overflow-y-auto z-100" v-if="visible">
        
        <div class="relative w-screen h-screen py-16 px-36">
            
            <div class="absolute right-4 top-4">
                <base-icon 
                    class="text-white cursor-pointer hover:opacity-50" 
                    :size="10" 
                    @action="$emit('close')" 
                    name="close" 
                />
            </div>

            <div class="absolute left-4 top-4 flex gap-x-8">

                <div>

                    <base-button
                        @action="downloadOptionsVisible = !downloadOptionsVisible"
                        label="Download"
                        secondary-icon="download"
                        size="lg"
                        type="secondary"
                    />
                    
                    <div v-if="downloadOptionsVisible" class="mt-4 w-56 bg-white rounded-lg shadow-lg overflow-hidden">
                        <div v-for="(option, i) in downloadOptions" :key="i">
                            <base-button 
                                @action="onDownloadOptionClick(option)" 
                                type="label" 
                                theme="neutral" 
                                rounded="none" 
                                justify="start"
                                class="w-full px-4 py-2 hover:bg-tan-m"
                                :label="option.label"
                            />
                        </div>         
                    </div>
                    
                    <!-- hidden element that actually downloads the file -->
                    <a ref="video" class="invisible" :href="attachment.signedUrl" :download="attachment.name"/>
                    <a v-if="audioAttachment" ref="audio" class="invisible" :href="audioAttachment.signedUrl" :download="audioAttachment.name"/>
                </div>

                <!-- audio attachment -->
                <audio
                    v-if="audioAttachment"
                    :autoplay="true"
                    controls
                >
                    <source :src="audioAttachment.signedUrl" :type="audioAttachment.mimetype">
                    Your browser does not support the video tag.
                </audio>
            </div>

            <div v-if="contentType == 'video'" class="w-full h-full flex justify-center items-center overflow-hidden">
                <video
                    style="height: 100%"
                    :autoplay="true"
                    controls
                    @loadstart="onVideoLoadStart" 
                    @loadeddata="onVideoLoadedData"
                >
                    <source :src="attachment.signedUrl" :type="computedMimetype">
                    Your browser does not support the video tag.
                </video>
            </div>

            <div v-if="contentType == 'audio'" class="w-full h-full flex justify-center items-center overflow-hidden">
                <video
                    :autoplay="true"
                    controls
                >
                    <source :src="attachment.signedUrl" :type="computedMimetype">
                    Your browser does not support the video tag.
                </video>
            </div>

            <div v-if="contentType == 'image'" class="w-full h-full flex justify-center items-center overflow-hidden">
                <img
                    style="height: 100%"
                    :src="attachment.signedUrl"
                />
            </div>

            <iframe
                v-if="contentType == 'pdf'"
                style="width:100%;height:100%;" 
                :src="`/files/${attachment.guid}`"
            />

            <template v-if="contentType == 'link'">
                <div @click="copyToClipboard" class="cursor-pointer hover:opacity-50">
                    <FormulateInput
                        type="text"
                        label="External Link"
                        class="w-full col-span-2"
                        readonly
                        :value="attachment.url"
                        :input-class="['focus:shadow-none', 'resize-none', 'cursor-pointer']"
                        :label-class="['font-bold', 'cursor-pointer']"
                    />
                </div>
            </template>
        </div>

    </div>
</template>

<script>
    import { getContentType } from '../lib/files'
    import BaseIcon from './BaseIcon.vue'
    import PdfPreview from './PdfPreview.vue'
    export default {
        props: ['visible', 'attachment', 'audioAttachment'],
        components: {
            PdfPreview,
            BaseIcon
        },
        data() {
            return {
                currentVideoStatus: {
                    loading: false,
                    loaded: false
                },
                downloadOptionsVisible: false,
                downloadOptions: [
                    {label: 'All files', action: 'all'},
                    {label: 'Video file', action: 'video'},
                    {label: 'Audio file', action: 'audio'}
                ]
            }
        },
        computed: {
            computedMimetype(){
                return this.attachment.mimetype == 'video/quicktime' ? 'video/mp4' : this.attachment.mimetype
            },
            contentType(){
                return getContentType(this.attachment.mimetype)
            }
        },
        methods: {
            onDownloadOptionClick(option){
                if(option.action == 'all'){
                    this.$refs['video'].click()
                    setTimeout(() => this.$refs['audio'].click(), 300);
                }else{
                    this.$refs[option.action].click()
                }
                this.downloadOptionsVisible = false
            },
            onVideoLoadStart(){
                this.currentVideoStatus.loading = true
            },
            onVideoLoadedData(){
                this.currentVideoStatus.loading = false 
                this.currentVideoStatus.loaded = true
            },
            copyToClipboard(){
                this.$copyToClipboard(this.$notify, this.attachment.url);
            },
        }
    }
</script>
<template>
    <div>         
        <!-- <div class="mx-8 lg:mt-12 sm:mt-8 lg:mb-8 sm:mb-6"> -->
        <div class="">
            <div class="flex justify-end gap-4 px-8 py-4 bg-white">
                <base-button type="label" icon="bell" class="text-green-m hover:text-green-m my-2" />
                <div>
                    <options-dropdown  
                        :grow="true"       
                        :options="[
                            {label: 'Profile Settings', icon: 'cog', event: 'profile-settings'},
                            ...(isSuperAdmin ? [{ label: 'Company Settings', icon: 'cog', event: 'company-settings' }]: []),
                            {label: 'Logout', icon: 'logout', event: 'logout'}                            
                        ]"
                        @profile-settings="$router.push(`/profile/edit`)"
                        @company-settings="$router.push(`/company`)"
                        @logout="logout"
                    >
                        <template v-slot:icon>                            
                            <div class="flex justify-start items-center gap-2">                                
                                <avatar class="h-6 w-6" size="small" :user="user"/>
                                <div class="flex flex-col items-start">
                                    <span class="text-sm text-black font-medium">{{`${user.first_name} ${user.last_name}`}}</span>
                                    <span class="text-xs text-gray-m-disable font-medium">{{bestRole}}</span>
                                </div>
                            </div>
                        </template>
                    </options-dropdown>                                  
                </div>
            </div>
            <template v-if="showBackBtn">
                <div class="flex justify-between p-8">
                    <base-button @action="$router.back()" type="label" icon="arrow-circle-left" label="Back"/>
                    <div class="flex items-center justify-end gap-x-2" v-if="breadcrumbs">
                        <span class="inline-flex gap-x-2" v-for="(link, i) in breadcrumbs" :key="i">
                            <base-button v-if="(i+1) < breadcrumbs.length" @action="$router.push(link.route)" type="label" :label="link.label"/>
                            <base-button v-else type="label" :disabled="true" :label="link.label"/>
                            {{((i+1) >= breadcrumbs.length ? '' : ' / ')}}
                        </span>                        
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="mb-8"></div>
            </template>
        </div>        
    </div>
</template>

<script>
import AvatarList from './AvatarList.vue';
import { mapGetters } from 'vuex';
import Dropdown from './Dropdown';

export default {
    props: {
        breadcrumbs: {
            type: Array
        }
    },

    components: {
        Dropdown,
        AvatarList
    },

    data() {
        return {
        }
    },

    computed: {
        ...mapGetters(['user', 'isSuperAdmin', 'bestRole']),

        showBackBtn() {     
            if(this.$route.meta.showBackBtn != null)       
                return this.$route.meta.showBackBtn;
            else
                return false;
        }

    },

    methods: {
        async logout() {
            await this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        }
    }
}
</script>
<template>
    <div class="header">
        <div class="wrapper">
            <div class="icon-container">
                <base-icon class="text-green-m-main" name="user-group" :size="5" />
            </div>
            <div class="title">
                Import Report
            </div>
        </div>
    </div>
</template>
<script>
export default { }
</script>
<style lang="scss" scoped>
    .header{
        @apply py-8 px-12 border-b border-gray-200;
        @apply flex justify-between items-center;
        .wrapper{
            @apply flex gap-4 items-start items-center;
            .icon-container{
                @apply rounded-full bg-purple-m-main w-15 h-15;
                @apply inline-flex items-center justify-center;
            }
            .title{
                @apply text-h2 font-bold text-purple-m-secondary;
            }
        }
    }
</style>
<template>
    <div class="origin-top-right absolute left-0 top-full w-72 rounded-md shadow-lg">
        <div v-if="visible" class="z-10 rounded-md bg-white shadow-xs relative z-20" role="menu" aria-orientation="vertical" aria-labelledby="project-options-menu-0">
            <div class="border-t border-gray-100"></div>
            <div class="py-1">
                <div @click="addProjectOwner(user.id)" v-for="(user, index) in filteredUsers" :key="index" class="px-4 py-2 flex items-center gap-x-2 cursor-pointer hover:bg-gray-100 transition">
                    <avatar size="small" :user="user"/>
                    <h3>{{`${user.first_name} ${user.last_name}`}}</h3>
                </div>
            </div>
        </div>
        <div v-if="visible" @click.prevent="$emit('close')" class="fixed max-w-full max-h-full inset-0 z-10 bg-transparent"/>
    </div>
</template>

<script>
export default {
    props: ['visible', 'projectId', 'currentOwners'],

    data() {
        return {
            users: [],
        }
    },

    mounted() {
        this.getUsers();
    },

    methods: {
        async getUsers() {
            const { data } = await this.$http.get('/api/users')
            this.users = data
        },
        async addProjectOwner(userId){
            try{
                const { data } = await this.$http.post(`/api/projects/${this.projectId}/owner/${userId}`)
                if(data && data.success){
                    this.$emit('close')
                    this.$emit('done')
                    this.$notify({ title: 'Success', text: 'Project owner added', type: 'success' })
                }
            }catch(err){
                console.log(err)
            }
        }
    },

    computed: {
        filteredUsers: function () {
            return this.users.filter(user => !this.mappedOwners.includes(user.id))
        },
        mappedOwners: function () {
            return this.currentOwners.map(currentOwner => currentOwner.user_id)
        }
    }
}
</script>

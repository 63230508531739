<template>

    <base-modal
        :visible="visible"
        title="Invite with email or link"
        @close="$emit('close')"
        with-picture
        modal-title="Invite New Members"
        modal-icon="user-inverted"
        no-padding
    >
        <div class="wrapper">
            <div class="content">
                <div class="form">
                    <div class="field">
                        <div v-if="roles.length <= 0">
                            No roles found
                        </div>
                        <div v-else class="role-select-container">
                            <custom-select
                                v-model="selectedRole" 
                                :options="computedRoles"
                                label="Role"
                                placeholder="Select"
                            />
                        </div>
                    </div>
                    <!-- <div class="field"> 
                        <custom-select 
                            v-model="selectedLoginType"
                            :options="[
                                { label: 'Any', value: null }
                            ]"
                            label="Login Type" 
                            placeholder="Select"
                        />
                        Social logins disabled on register for now 
                        { label: 'Facebook', value: 6 },
                        { label: 'Twitter', value: 7 },
                        { label: 'Google', value: 10 }
                    </div> -->
                </div>
                <div class="link">
                    <FormulateInput 
                        :key="locationKey" 
                        :value="getLocation" 
                        readonly 
                        class="w-full" 
                        :disabled="!intentData.token"
                    />
                    <base-button 
                        @action="copyToClipboard()" 
                        :disabled="!roleReady || loading || !intentData.token" 
                        size="sm" 
                        label="Copy"
                    />
                </div>

                <FormulateInput
                    v-model="email"
                    class="w-full mt-2"
                    label="Invite with email"
                    placeholder="example@mail.com"
                    validation="email"
                />
            </div>

            <div class="footer">
                <base-button 
                    @action="inviteByEmail" 
                    :disabled="!roleReady || loading || email === '' || !validEmail" 
                    size="md" 
                    :icon-size="4" 
                    bold 
                    icon="arrow-right" 
                    label="Invite"
                />
            </div>
        </div>
    </base-modal>
</template>

<script>
import FormulateInput from "@braid/vue-formulate/src/FormulateInput";
import { validateEmail } from '../../lib/strings';
export default {
    components: {FormulateInput},
    props: ['visible'],
    data() {
        return {
            intentData: {},
            loading: false,

            roles: [],
            rolesLoading: false,

            locationKey: false,

            step: 1,

            selectedRole: null,
            roleId: '',
            email: '',

            selectedLoginType:  { label: 'Any', value: null }
        }
    },
    watch: {
        selectedRole: function (val) {
            if (val) {
                this.roleId = val.id;
                this.postInvitationIntent();
            }
        },
        selectedLoginType: function (val) {
            if (val) {
                this.postInvitationIntent();
            }
        }
    },
    mounted() {
        this.getRoles()
    },
    computed: {
        validEmail(){
            return validateEmail(this.email)
        },
        getLocation() {
            if (this.intentData.token) {
                return `${window.location.origin}/register?t=${this.intentData.token}`
            }

            return 'Please, select a role first';
        },
        roleReady(){
            return this.roleId !== '';
        },
        computedRoles() {
            return this.roles.map(role => {
                role.color = null
                role.label = role.display_name;
                return role;
            })
        }
    },
    methods: {
        async postInvitationIntent(){
            this.loading = true
            try{
                const { data } = await this.$http.post('/api/invite/intent', {
                    roleId: this.roleId,
                    network_id: this.selectedLoginType.value,
                    currentToken: this.intentData.token
                });
                this.intentData = data
            }catch(error){
                console.log(error)
                if(error.response.data.message){
                    this.$notify({ title: 'Error', text: `${error.response.data.message}`, type: 'error' })
                }
            }finally{
                this.loading = false
                this.locationKey = !this.locationKey;
            }
        },
        async getRoles(){
            this.rolesLoading = true
            const { data } = await this.$http.get('/api/roles');
            this.rolesLoading = false
            const excludedRoles = ['agent']
            this.roles = data.filter(role => !excludedRoles.includes(role.name))
        },
        copyToClipboard(){
            this.$copyToClipboard(this.$notify, this.getLocation);
        },
        async inviteByEmail() {
            this.loading = true
            try{
                await this.$http.post('/api/invite', {
                    email: this.email,
                    url: this.getLocation
                });
                this.$notify({ title: 'Success', text: 'Email sent successfully', type: 'success' })
                this.$emit('done')
            }catch(error){
                console.log(error)
                this.$notify({ title: 'An error ocurred', text: `${error.response && error.response.data && error.response.data.message && (`:${error.response.data.message}`)}`, type: 'error' })
            }finally{
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .wrapper{
        @apply flex flex-col justify-between;
        .content{
            @apply pt-5 pb-20 px-14;
            .form{
                @apply flex gap-x-4 mt-3;
                .field{
                    @apply w-1/2;
                    .role-select-container{
                        @apply flex flex-col gap-y-1;
                    }
                }
            }
            .link{
                @apply flex justify-between gap-x-5 my-5;
            }
        }
        .footer{
            @apply flex justify-end gap-3 bg-white rounded-br-2xl py-3 px-14;
        }
    }
</style>
<template>
    
<div class="main">

    <div class="header">
        <div class="title">
           Notifications
        </div>    
       
        <div class="tabs-container">
            <tabs theme="light" :tabs="['All', 'Unread', 'Assigned to me']" v-model="viewTab" />            
        </div>     
    </div>

    <div class="content">
        
        <div class="actions">
            <base-button label="Mark all as read" size="lg" @action="markAllAsRead()" />
        </div>

        <!-- all -->
        <tab :visible="viewTab === 'All'">
            <base-icon name="loader" v-if="loading"/>
            <div class="filter-list" v-else>
                <div class="empty-state" v-if="!userNotifications.length">You have no notifications</div>
                <template v-for="(filter, index) in dateFilters">
                    <div class="filter" :key="index" v-if="filter.apply(userNotifications).length">
                        <div class="title">{{filter.name}}</div>         
                        <div class="user-notification-list">
                            <user-notification-item 
                                :key="index" 
                                v-for="(userNotification, index) in filter.apply(userNotifications)"
                                :userNotification="userNotification"
                                @userNotificationUpdate="onUserNotificationUpdate"
                                @refresh="refresh"
                            />
                        </div>
                    </div>
                </template>
            </div>
        </tab>

        <!-- unread -->
        <tab :visible="viewTab === 'Unread'">
            <base-icon name="loader" v-if="loading" />
            <div class="filter-list" v-else>
                <div class="empty-state" v-if="!unread.length">You have no unread notifications</div>
                <template v-for="(filter, index) in dateFilters">
                    <div class="filter" :key="index" v-if="filter.apply(unread).length">
                        <div class="title">{{filter.name}}</div>         
                        <div class="user-notification-list">
                            <user-notification-item 
                                :key="index" 
                                v-for="(userNotification, index) in filter.apply(unread)"
                                :userNotification="userNotification"
                                @userNotificationUpdate="onUserNotificationUpdate"
                                @refresh="refresh"
                            />
                        </div>
                    </div>
                </template>
            </div>
        </tab>

        <!-- assigned to me -->
        <tab :visible="viewTab === 'Assigned to me'">
            <base-icon v-if="loading" name="loader" />
            <div v-else class="filter-list">
                <div class="empty-state" v-if="!assignedToMe.length">You have no notifications assigned to you</div>
                <template v-for="(filter, index) in dateFilters">
                    <div class="filter" :key="index" v-if="filter.apply(assignedToMe).length">
                        <div class="title">{{filter.name}}</div>         
                        <div class="user-notification-list">
                            <user-notification-item 
                                :key="index" 
                                v-for="(userNotification, index) in filter.apply(assignedToMe)"
                                :userNotification="userNotification"
                                @userNotificationUpdate="onUserNotificationUpdate"
                                @refresh="refresh"
                            />
                        </div>
                    </div>
                </template>
            </div>
        </tab>

    </div>
</div>

</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex';
import UserNotificationItem from './UserNotificationItem.vue'
import { notifyCatchError } from '../../common';
const today = moment()
export default {
    components: {
        UserNotificationItem
    },
    data() {
        return {
            viewTab: 'All',
            loading: false,
            userNotifications: [],

            dateFilters: [
                {
                    id: 'today',
                    name: 'Today',
                    // if a date matches this filter
                    dateIs: (date) => {
                        return date.isSame(today, 'day')
                    },
                    // if an array of userNotifications belong to this filter
                    apply: (userNotifications) => {
                        return userNotifications.filter(userNotification => userNotification.filter == 'today').sort(function(a,b) {
                            return Date.parse(b.created_at) - Date.parse(a.created_at);
                        });
                    }
                },
                {
                    id: 'week',
                    name: 'This week',
                    dateIs: (date) => {
                        return date.isSame(today, 'week')
                    },
                    apply: (userNotifications) => {
                        return userNotifications.filter(userNotification => userNotification.filter == 'week').sort(function(a,b) {
                            return Date.parse(b.created_at) - Date.parse(a.created_at);
                        });
                    }
                },
                {
                    id: 'month',
                    name: 'This month',
                    dateIs: (date) => {
                        return date.isSame(today, 'month')
                    },
                    apply: (userNotifications) => {
                        return userNotifications.filter(userNotification => userNotification.filter == 'month').sort(function(a,b) {
                            return Date.parse(b.created_at) - Date.parse(a.created_at);
                        })
                    }
                },
                {
                    id: 'year',
                    name: 'This year',
                    dateIs: (date) => {
                        return date.isSame(today, 'year')
                    },
                    apply: (userNotifications) => {
                        return userNotifications.filter(userNotification => userNotification.filter == 'year').sort(function(a,b) {
                            return Date.parse(b.created_at) - Date.parse(a.created_at)
                        });
                    }
                },
                {
                    id: 'beforeYear',
                    name: 'Before this year',
                    dateIs: (date) => {
                        return date.isBefore(today, 'year')
                    },
                    apply: (userNotifications) => {
                        return userNotifications.filter(userNotification => userNotification.filter == 'beforeYear').sort(function(a,b) {
                            return Date.parse(b.created_at) - Date.parse(a.created_at);
                        })
                    }
                }
            ] 
        }
    },

    mounted() {
        if(this.$route.query.tab){
            this.viewTab = decodeURIComponent(this.$route.query.tab)
        }
        this.getUserNotifications()
    },

    watch: {
        viewTab(val){
            this.$router.push({query: {tab: encodeURIComponent(val)}})
        }
    },

    computed: {
        ...mapGetters(['me']),
        unread(){
            return this.userNotifications.filter(userNotification => userNotification.status == 1)
        },
        assignedToMe(){
            return this.userNotifications.filter(userNotification => userNotification.notification.assigned_to == this.me.id)
        },
    },

    methods: {
        getUserNotifications(){
            this.loading = true
            this.$http.get('/api/user-notification').then(async (res) => {
                res.data.map(userNotification => {
                    const date = moment(userNotification.created_at)
                    // find if date belongs to today, this week, this month, etc...
                    let match = null
                    for (const filter of this.dateFilters) {
                        if(filter.dateIs(date)){
                            match = filter.id
                            break;
                        }
                    }
                    userNotification.filter = match
                    return userNotification
                })
                this.userNotifications = res.data
            }).catch((err) => {
                console.log('getUserNotifications error', err)
            }).finally(() => {
                this.loading = false      
            })
        },
        // frontend update only
        onUserNotificationUpdate(userNotificationId, body){
            const { status } = body
            this.userNotifications.map(element => {
                if(element.id == userNotificationId) element.status = status
                return element
            })
            const option = status == 1 ? 'sum' : status == 2 ? 'sub' : ''
            this.$store.dispatch('notifications/updatePendingNotificationsCount', option)
        },
        async markAllAsRead(){
            this.loading = true
            this.$http.post(`/api/user-notification/mark-all-as-read`).then(({ data }) => {
                if(data){
                    this.$notify({title: 'Success', text: `Notifications cleared successfully`, type: 'success'})
                    this.refresh()
                }
            }).catch(err => {
                notifyCatchError(err, this.$notify)
            }).finally(() => {
                this.loading = false
            });
        },
        refresh(){
            this.getUserNotifications(); 
            this.$store.dispatch('notifications/getPendingNotificationsCount')
        },
    }
}
</script>

<style lang="scss" scoped>
    .main{
        @apply flex-1 min-w-0 bg-white h-screen overflow-auto;
        > .header{
            @apply flex flex-col py-10 px-12 pb-0 gap-10;
            > .title{
                @apply text-h2 font-bold text-purple-m-secondary;
            }
            > .tabs-container{
                @apply flex justify-between items-center;
            }
        }
        > .content{
            @apply py-10 px-12;
            > .actions{
                @apply w-full flex justify-end;
            }
        }
    }
    .filter-list{
        @apply flex flex-col gap-y-16;
        .empty-state{
            @apply text-h4 font-bold;
        }
        .filter{
            @apply flex flex-col gap-y-6;
            .title{
                @apply text-h1 font-bold;
            }
            .user-notification-list{
                @apply flex flex-col gap-y-4;
            }
        }
    }
</style>

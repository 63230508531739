<template>
    <div class="long-text">
        <div 
            class="content"
            v-tooltip="{ content: `Click to view full ${label.toLowerCase()}`, classes: 'v-tooltip-black', delay: { show: 100, hide: 50 } }"
            @click="modalVisible = true"
        >
            {{ value }}
        </div>
        <base-table-column-long-text-modal
            :visible="modalVisible"
            :value="value"
            :label="label"
            @close="modalVisible = false"
        />
    </div>
</template>
<script>
import BaseTableColumnLongTextModal from './BaseTableColumnLongTextModal.vue';
export default {
    props: {
        value: {
            type: String,
            required: false,
            default: ''
        },
        label: {
            type: String,
            required: false,
            default: ''
        }
    },
    components: {
        BaseTableColumnLongTextModal
    },
    data(){
        return {
            modalVisible: false
        }
    }
}
</script>
<style lang="scss" scoped>
    .long-text{
        .content{
            @apply truncate cursor-pointer transition-colors duration-200 rounded-lg;
            width: 225px; 
            max-width: 225px; 
            min-width: 225px;
            &:hover{
                @apply bg-gray-200;
            }
        }
    }
</style>

<template>
    <div v-if="visible" :class="[`z-${index}`]" class="fixed gap-4 cursor-wait flex-col justify-center items-center w-40 h-24 h-25 bg-black bg-opacity-70 text-white right-4 top-28 rounded-lg">
        <div class="flex gap-4 cursor-wait flex-col justify-center items-center inset-0 text-center my-4">
            <img :src="spinner" class="w-8 h-8"/>
            {{ label }}
        </div>
    </div>
</template>
<script>
import spinner from './../assets/images/spinner.gif';
export default {
    props: {
        label: {
            type: String,
            default: 'Refreshing'
        },
        visible: {
            type: Boolean,
            default: false
        },
        absolute: {
            type: Boolean,
            default: false
        },
        opacity: {
            type: Number,
            default: 80
        },
        index: {
            type: Number,
            default: 70
        }
    },
    data() {
        return {
            spinner: spinner
        }
    }
}
</script>
<template>
    <div class="fb-business-wrapper">
        
        <fb-business-id-form v-if="isAdminOrHigher" />

        <div class="fb-business-container">
            
            <no-facebook-account v-if="Object.keys(fbLogin).length && !fbLogin.is_valid"/>

            <template v-else> 

                <sidebar />

                <div class="content">
                    <tab :visible="route === 'people'">
                        <people />
                    </tab>
                    <tab :visible="route === 'client-pages'">
                        <client-pages />
                    </tab>
                    <tab :visible="route === 'system-users'">
                        <system-users />
                    </tab>
                    <tab :visible="route === 'pages'">
                        <pages />
                    </tab>
                    <tab :visible="route === 'ad-accounts'">
                        <ad-accounts />
                    </tab>
                    <tab :visible="route === 'business-resource-groups'">
                        <business-resource-groups />
                    </tab>
                    <tab :visible="route === 'apps'">
                        <apps />
                    </tab>
                    <tab :visible="route === 'instagram-accounts'">
                        <instagram-accounts />
                    </tab>
                    <tab :visible="route === 'whatsapp-business-accounts'">
                        <whatsapp-business-accounts />
                    </tab>
                    <tab :visible="route === 'permission-requests'">
                        <permission-requests />
                    </tab>
                    <tab :visible="route === 'webhooks'">
                        <webhook-subscriptions /> 
                    </tab>
                    <tab :visible="route === 'info'">
                        <info />
                    </tab>
                </div>

            </template>
        </div>
    </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import People from './People.vue'
import Pages from './Pages.vue'
import SystemUsers from './SystemUsers.vue'
import ClientPages from './ClientPages.vue'
import PermissionRequests from './PermissionRequests.vue'
import WebhookSubscriptions from './WebhookSubscriptions.vue'
import AdAccounts from './AdAccounts.vue'
import BusinessResourceGroups from './BusinessResourceGroups.vue'
import Apps from './Apps.vue'
import InstagramAccounts from './InstagramAccounts.vue'
import WhatsappBusinessAccounts from './WhatsappBusinessAccounts.vue'
import Info from './Info.vue'
import NoFacebookAccount from './NoFacebookAccount.vue'
import FbBusinessIdForm from './FbBusinessIdForm.vue'
import { mapGetters } from 'vuex';
export default {
    components: {
        Sidebar,
        People,
        Pages,
        SystemUsers,
        ClientPages,
        PermissionRequests,
        BusinessResourceGroups,
        WebhookSubscriptions,
        AdAccounts,
        Apps,
        InstagramAccounts,
        WhatsappBusinessAccounts,
        Info,
        NoFacebookAccount,
        FbBusinessIdForm
    },
    computed: {
        ...mapGetters(['company', 'fbLogin', 'isAdminOrHigher']),
        route(){
            return this.$route.query.route;
        }
    }
}
</script>

<style lang="scss" scoped>
    .fb-business-container{
        @apply w-full border-t border-gray-500;
        @apply flex;
    }
    .content{
        @apply px-12 py-10 flex-shrink flex-grow;
        @apply overflow-y-auto;
        height: calc(100vh - 190px);
    }
</style>
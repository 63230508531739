<template>
    <div class="header">
        <img class="logo" :src="logo" />
        <div class="title">{{title}}</div>
        <div class="description">{{description}}</div>

        <div class="close-button-wrapper">
            <base-icon @action="$emit('close')" name="close" size="6"/>
        </div>
    </div>
</template>

<script>
import logo from '@/assets/images/logo.svg';
export default {
    props: ['step'],
    data(){
        return {
            logo,
            // titles and decriptions for steps
            texts: {
                // Connections
                1: {
                    title: `Set up your workstation`,
                    description: `Connect your favorite apps` 
                },
                // Additional Info
                2: {
                    title: `Additional Information`,
                    description: `Tell us more about yourself` 
                },
                // Gender
                3: {
                    title: `Additional Information`,
                    description: `Tell us more about yourself` 
                },
                // Brands
                4: {
                    title: `Additional Information`,
                    description: `Tell us more about yourself` 
                },
                // Interests
                // n: {
                //     title: `Additional Information`,
                //     description: `Tell us more about yourself` 
                // }
            }
        }
    },

    computed: {
        title(){
            return this.texts[this.step].title 
        },
        description(){
            return this.texts[this.step].description
        }
    }
}
</script>
<style lang="scss" scoped>
    .header{
        @apply relative bg-purple-m-main flex flex-col justify-center items-center text-white;
        @apply flex-grow-0 flex-shrink-0;

        height: 300px;
        .logo{
            height: 50px;
        }
        .title{
            @apply text-h3 font-bold mt-6;
        }
        .description{
            @apply mt-2 text-center;
        }
        .close-button-wrapper{
            @apply absolute top-6 right-6 cursor-pointer;
        }
    }
</style>

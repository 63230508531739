<template>
    <div class="inset-0 fixed bg-black overflow-y-auto z-100" v-if="visible">
        
        <div class="relative w-screen h-screen py-16 px-36 flex justify-center items-center">
            
            <div class="absolute right-4 top-4">
                <base-icon 
                    class="text-white cursor-pointer hover:opacity-50" 
                    :size="10" 
                    @action="$emit('close')" 
                    name="close" 
                />
            </div>

            <div :class="[
                'w-1/2 flex justify-center', 
                { 'w-7/12 p-10 bg-white': deliverable.deliverable.slug === 'social-post-youtube' || deliverable.deliverable.slug === 'blog-post' }]">
                <preview
                    class="w-full justify-center"
                    :deliverableSlug="deliverable.deliverable.slug"
                    :files="files"
                    :audios="[]"
                    :description="selectedDraft.description"
                    :stats="deliverable.project_user.user.audience_data"
                    :networkId="deliverable.network_id"
                    :user="deliverable.project_user.user"
                    :viewOnly="true"
                />    
            </div>      

        </div>

    </div>
</template>

<script>
    import Preview from '../VersionCreate/Preview.vue'
    export default {
        props: ['visible', 'deliverable', 'selectedDraft'],
        components: {
            Preview
        },
        computed: {
            files(){
                return this.selectedDraft.media.map(mediaItem => {
                    const file = mediaItem.attachment
                    if(file){
                        file.audioElementId = 'audio'
                        file.audio = mediaItem.audio_attachment
                    }
                    return file
                })
            }
        }
    }
</script>
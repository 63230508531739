import { isValidString, NETWORKS } from '../common';

const postRoutes = [
    {
        base: 'https://www.facebook.com',
        route: '/:userId/videos/:videoId/',
        sample: 'https://www.facebook.com/123456/videos/123456789/',
        params: (string) => {
            return { 
                userId: string.split('/')[0],
                videoId: string.split('videos/')[1],
            }
        },
        platform: 'facebook',
        platform_id: 6,
        type: 'video'
    },
    {
        base: 'https://www.facebook.com',
        route: '/watch/?v=',
        sample: 'https://www.facebook.com/watch/?v=123456789',
        params: (string) => {
            return { 
                videoId: string.split('v=')[1].split('&')[0],
                postId: string.split('v=')[1].split('&')[0]
            }
        },
        platform: 'facebook',
        platform_id: 6,
        type: 'video'
    },
    {
        base: 'https://www.facebook.com',
        route: '/photo/?fbid=',
        sample: 'https://www.facebook.com/photo/?fbid=1038123247577660&set=a.384462419610416',
        params: (string) => {
            return { 
                postId: string.split('fbid=')[1].split('&')[0]
            }
        },
        platform: 'facebook',
        platform_id: 6,
        type: 'image'
    },
    {
        base: 'https://www.facebook.com',
        route: '/:userName/photos/:albumId/:postId',
        sample: 'https://www.facebook.com/username/photos/abc.123456789/123456789',
        params: (string) => {
            return { 
                userName: string.split('/')[0],
                albumId: string.split('photos/')[1].split('/')[0],
                postId: string.split('photos/')[1].split('/')[1]
            }
        },
        platform: 'facebook',
        platform_id: 6,
        type: 'image'
    },
    {
        base: 'https://www.facebook.com',
        route: '/reel/:postId',
        sample: 'https://www.facebook.com/reel/123456789123456678',
        params: (string) => {
            return {
                postId: string.split('reel/')[1].split('/')[0]
            }
        },
        platform: 'facebook',
        platform_id: 6,
        type: 'video'
    },
    {
        base: 'https://www.tiktok.com',
        route: '/:userName/video/:postId',
        sample: 'https://www.tiktok.com/@mexican_geo/video/7263146174893477125',
        params: (string) => {
            return { 
                userName: string.split('@')[1].split('/')[0],
                postId: string.split('video/')[1].split('/')[0],
            }
        },
        platform: 'tiktok',
        platform_id: 14,
        type: 'video'
    },
    {
        base: 'https://www.instagram.com',
        route: '/p/:postId',
        sample: 'https://www.instagram.com/p/CqJtKcFuMau/',
        params: (string) => {
            return { 
                postId: string.split('p/')[1].split('/')[0] 
            }
        },
        platform: 'instagram',
        platform_id: 2,
        type: 'video'
    },
    {
        base: 'https://www.instagram.com',
        route: '/reels/:postId',
        sample: 'https://www.instagram.com/reels/CtfK__mrnTu/',
        params: (string) => {
            return { 
                postId: string.split('reels/')[1].split('/')[0] 
            }
        },
        platform: 'instagram',
        platform_id: 2,
        type: 'video'
    },
    {
        base: 'https://www.instagram.com',
        route: '/reel/:postId',
        sample: 'https://www.instagram.com/reel/CtfK__mrnTu/',
        params: (string) => {
            return { 
                postId: string.split('reel/')[1].split('/')[0] 
            }
        },
        platform: 'instagram',
        platform_id: 2,
        type: 'video'
    }
];

const profileRoutes = [
    {
        base: 'https://www.instagram.com',
        route: '/:handle',
        sample: 'https://www.instagram.com/markruffalo/',
        match: (url) => {
            if(url.includes('instagram.com')){
                const handle = url.split('instagram.com/')[1].split('/')[0];
                if(isValidString(handle) && handle != 'reels' && handle != 'p'){
                    return true
                }
            }
            return false;
        },
        params: (string) => {
            return { 
                handle: string.split('instagram.com/')[1].split('/')[0] 
            }
        },
        platform: 'instagram',
        platform_id: 2,
        type: 'profile'
    },
    {
        base: 'https://www.tiktok.com',
        route: '/:handle',
        sample: 'https://www.tiktok.com/@microsoft',
        match: (url) => {
            return url.includes('tiktok.com/@')
        },
        params: (string) => {
            return { 
                handle: string.split('tiktok.com/')[1].split('/')[0] 
            }
        },
        platform: 'tiktok',
        platform_id: 14,
        type: 'profile'
    }
]

function getNetwork(networkId, key = null){
    const network = NETWORKS[networkId] 
    if(key){
        return network[key]
    }
    return network
}

function getParamsFromSocialPostUrl(url){
    try {
       // search for post id in the url
       for (let route of postRoutes) {
           url = removeUnnecessaryQueryParams(url);
           url = trimc(url.replace(route.base, ''), '/');
           if (compareRoute(url, route)) {
               let params = route.params(url)
               params = {
                    ...params, 
                    platform: route.platform, 
                    type: route.type
                }
               return params
           }
       }
       return {}
   } catch (e) {
       console.log('Could not get data from post url', e);
       return {}
   }
}

function getNetworkFromSocialPostUrl(url){
    try {
       // search for post id in the url
       for (let route of postRoutes) {
           url = removeUnnecessaryQueryParams(url);
           url = trimc(url.replace(route.base, ''), '/');
           if (compareRoute(url, route)) {
                return getNetwork(route.platform_id);
           }
       }
       return {}
   } catch (e) {
       console.log('Could not get data from post url', e);
       return {}
   }
}

function getNetworkFromProfileUrl(url){
    try {
        // search for post id in the url
        for (let route of profileRoutes) {
            url = removeUnnecessaryQueryParams(url);
            if (route.match(url)){
                return getNetwork(route.platform_id);
            }
        }
        return {}
    } catch (e) {
        console.log('Could not get data from profile url', e);
        return {}
    }
}

function getParamsFromProfileUrl(url){
    try {
       // search for post id in the url
       for (let route of profileRoutes) {
           url = removeUnnecessaryQueryParams(url);
           if (route.match(url)) {
               let params = route.params(url)
               params = {
                    ...params, 
                    platform: route.platform, 
                    type: route.type
                }
               return params
           }
       }
       return {}
   } catch (e) {
       console.log('Could not get data from profile url', e);
       return {}
   }
}

function isProfileUrl(url){
    try {
       // search for post id in the url
       for (let route of profileRoutes) {
            url = removeUnnecessaryQueryParams(url);
            if (route.match(url)) {
                return true;
            }
       }
       return false;
   } catch (e) {
       console.log('Could not get data from profile url', e);
       return false;
   }
}

function removeUnnecessaryQueryParams(url){
    const queryParams = postQueryParams();
    let start = url.split('?')[0];
    let params = url.split('?')[1];
    if(!params) return start;
    let paramsArray = params.split('&');
    let acceptedParamsArray = []
    for (let param of paramsArray) {
        const name = param.split('=')[0]
        if(queryParams.includes(name)){
            acceptedParamsArray.push(param)
        }
    }
    let queryStr = acceptedParamsArray.length ? '?' + acceptedParamsArray.join('&') : '';
    // fb url exceptions
    let res = start + queryStr;
    if(res.includes('/watch') && !res.includes('/watch/')){
        res = res.replace('/watch', '/watch/')
    }
    if(res.includes('/photo') && !res.includes('/photo/')){
        res = res.replace('/photo', '/photo/')
    }
    return res;
}

// private function
// function to trim str
function trimc(string, char = ' ') {
    string = string.charAt(0) === char ? string.substring(1) : string;
    string = string.charAt(string.length - 1) === char ? string.slice(0, -1) : string;
    return string
}

// private function
// function to compare to postRoutes
function compareRoute(string, route) {
    let stringItems = string.split('/');
    let compare = trimc(route.route, '/');
    let compareItems = compare.split('/');
    if (stringItems.length !== compareItems.length) {
        return false;
    }

    let check = 0;
    for (let i = 0; i < stringItems.length; i++) {
        let char = compareItems[i].substring(0, 1);
        if (char !== ':') {
            if (compareItems[i] === stringItems[i]) {
                check++;
            }

            if (char === '?') {
                if (stringItems[i].includes(compareItems[i])) {
                    check++;
                }
            }
        } else {
            check++;
        }
    }

    return check === stringItems.length;
}

// private function
// Searches for all the query params on the postRoutes var
// returns an array with all the query params found ['v', 'fbId', '...']
function postQueryParams() {
    let arr = []

    // remove routes that dont have query params
    const routes = postRoutes.filter(element => {
        return element.route.includes('?')
    }).map(element => element.route)

    // get the query params of the routes that do have it
    for (let route of routes) {
        let done = false
        let iteration = 0
        while(!done){
            iteration++;
            if(!route){
                done = true;
                continue;
            }

            let found = null
            if(route.split('&')[1]){
                found = '&'
            }
            if(route.split('?')[1]){
                found = '?'
            }
            if(found){
                let queryParam = route.split(found)[1].split('=')[0]
                arr.push(queryParam)
                route = route.split('=')[1]
            }else{
                done = true;
            }
            // max 10 iterations
            if(iteration > 10){
                done = true;
                continue;
            }
        }
    }
    return arr
}

export {
    postRoutes,
    getNetwork,
    removeUnnecessaryQueryParams,
    getParamsFromSocialPostUrl,
    getNetworkFromSocialPostUrl,
    getNetworkFromProfileUrl,
    getParamsFromProfileUrl,
    isProfileUrl
};
<template>
    <div class="due-dates">

        <div 
            :class="['content', { 'clickable': isAnyDateMissing }]" 
            @click.stop="isAnyDateMissing ? dropdownVisible = !dropdownVisible : null"
            v-tooltip="isAnyDateMissing ? { 
                content: `There are missing due dates, if they are not set, <br> the creator won't get notifications about due dates. <br> Click to view details.`, 
                classes: 'v-tooltip-black'
            } : {}"
        >
            <div class="warning-container">
                <base-icon
                    name="exclamation-2"
                    class="cursor-pointer text-yellow-300"
                />
            </div>

            <div class="title">Next due date:</div>

            <div 
                class="next-due-date" 
                v-if="isValidObject(nextDueDate)"
            >
                {{ nextDueDate.label }} on {{ nextDueDate.value }}
            </div>
            <div class="empty-state" v-else>
                None
            </div>

        </div>

        <div 
            class="dropdown"
            v-if="dropdownVisible"
        >
            <div class="header">Missing due dates</div>
            <div class="content">
                <div
                    class="date-item" 
                    v-for="(date, index) in missingDates" :key="index"
                >
                    <div class="label">- {{ date.label }}</div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { isValidArray, isValidDate, isValidObject } from '../../common';
import moment from 'moment';
export default {
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            dropdownVisible: false,
            isValidObject
        }
    },
    computed: {
        // array
        val(){
            if(this.value && isValidObject(this.value)){
                return [
                    { label: 'End date', value: this.value.end_date },
                    { label: 'Concept approval', value: this.value.concept_approval },
                    { label: 'Draft approval', value: this.value.post_approval }
                ]
            }
            return [];
        },
        filteredDueDates(){
            return this.val.filter(element => {
                return isValidDate(element.value)
            });
        },
        nextDueDate(){
            const today = moment();
            let nearestDate = null; // obj
            for (const date of this.filteredDueDates) {
                let dueDate = moment(date.value);
                let diff = dueDate.diff( today, 'days');

                // valid diff
                if (diff > 0) {
                    // if nearestDate is already set, we check the difference
                    if (nearestDate != null) {
                        if (moment(date.value).diff(moment(nearestDate.value), 'days') < 0) {
                            nearestDate = date;
                        }
                    } else {
                        // else we just set it 
                        nearestDate = date;
                    }
                }
            }
            return nearestDate;
        },
        isAnyDateMissing(){
            return this.val.length !== this.filteredDueDates.length;
        },
        missingDates(){
            return this.val.filter(element => {
                return !isValidDate(element.value)
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .due-dates{
        @apply relative;
        > .content{
            @apply flex items-center gap-x-3;
            &:not(.clickable){
                > .warning-container{
                    @apply hidden;
                }
            }
            &.clickable{
                @apply cursor-pointer transition-opacity duration-200;
                &:hover{
                    @apply opacity-50;
                }
                > .warning-container{
                    @apply flex items-center;
                }
            }
            > .title{
                @apply text-white text-sm;
            }
            > .next-due-date{
                @apply text-white text-sm;
            }
            > .empty-state{
                @apply text-white text-sm;
            }
        }
        > .dropdown{
            @apply absolute bg-white py-3 px-5 rounded-lg border border-gray-500;
            z-index: 101;
            > .header{
                @apply font-bold pb-2 mb-2 border-b border-gray-200;
            }
            > .content{
                @apply flex flex-col;
                > .date-item{
                    @apply flex flex-col py-1;
                    > .label{
                        @apply text-sm font-bold;
                    }
                    > .value{
                        @apply;
                    }
                }
            }
        }
    }
</style>

<template>
<div>

    <Title type="main">Base Modal</Title>

    <Title>Usage</Title>

    <Code title="Simple use" :code='`
        <base-modal
            :visible="modalVisible"
            @close="modalVisible = false"
        />
    `'/>

    <Code title="Use with custom options" :code='`
        <base-modal 
            visible=""
            invisible="" 
            title="" 
            hideHeader=""
            classes=""
            hideCloseButton=""
            headerStyles=""
            titleStyles=""
            overlayClasses=""
            styles=""
            withPicture=""
            modalTitle="" 
            modalSubTitle="" 
            modalIcon="" 
            mode="" 
            noPadding="" 
            centerTitle=""
            preventEscape="" 
            bigger="" 
        />
    `'/>

    <Title>Main Props</Title>
    <Table
        title="These are the main props for the component:"
        :columns="['Name', 'Type', 'Default', 'Description']"
        :data="[
            ['visible', 'Boolean', '-', 'Toggles the visibility of the modal'],
            ['title', 'String', '-', 'The title of the modal'],
            ['hideHeader', 'Boolean', '-', 'Hides the header'],
            ['hideCloseButton', 'Boolean', '-', 'Hides the close button'],
            ['noPadding', 'Boolean', 'false', 'Use this in case you want to handle your own padding inside the modal']
        ]"
    />

    <Title>Style Props</Title>
    <Table
        title="These are the props for styling:"
        :columns="['Name', 'Type', 'Default', 'Description']"
        :data="[
            ['classes', 'String', '-', 'Classes to be applied to the modal itself'],
            ['headerStyles', 'String', '-', 'Styles to be applied to the header'],
            ['titleStyles', 'String', '-', 'Styles to be applied to the title'],
            ['overlayClasses', 'String', '-', 'Styles to be applied to the overlay'],
            ['styles', 'String', '-', 'Styles to be applied to the modal itself']
        ]"
    />

    <Title>Additional Props</Title>
    <Table
        title="These are the main props for the component:"
        :columns="['Name', 'Type', 'Default', 'Description']"
        :data="[
            ['withPicture', 'Boolean', 'false', 'Shows the dinosaur themed sidebar, requires the next 3 props:'],
            ['modalTitle', 'String', '-', 'The title for the dinosaur themed sidebar'],
            ['modalSubTitle', 'String', '-', 'The subtitle for the dinosaur themed sidebar'],
            ['modalIcon', 'String', '-', 'The icon for the dinosaur themed sidebar'],
            ['mode', 'String', 'dark', 'The theme of the modal, right now it only supports the dark theme'],
            ['centerTitle', 'Boolean', 'false', 'Centers the title'],
            ['preventEscape', 'Boolean', 'false', 'Prevents closing the modal with the escape button'],
            ['bigger', 'Boolean', 'false', 'Applies the class max-w-6xl to the modal element']
            ['invisible', 'Boolean', '-', 'The modal will be rendered, but an `invisible` class will be added to the containers'],
        ]"
    />

</div>
</template>
<script>
import Code from '../Code.vue'
import Table from '../Table.vue'
import Title from '../Title.vue'
import Paragraph from '../Paragraph.vue'
export default {
    components: {
        Code,
        Table,
        Title,
        Paragraph
    }
}
</script>
<template>
    <div class="stats-wrapper">
        <div class="stats-container">
            <stat-card label="Active Projects" :value="pendingProjectsCount" icon="layers" />
            <stat-card label="Pending Earned" :value="pendingEarned | numeral('$0,0')" icon="cash" />
            <stat-card label="Total Earned" :value="totalEarned | numeral('$0,0')" icon="cash" />
            <stat-card v-if="firstDate" label="Employed Since" :value="firstDate | moment('MMM D, Y')" icon="calendar" />
            <stat-card v-else label="Employed Since" value="-" icon="calendar" />
        </div>
    </div>
</template>

<script>
    import StatCard from '@/components/StatCard.vue';
    export default {
        props: ['user'],
        components: {
            StatCard
        },
        data(){
            return {
            }
        },
        computed: {
            projects() {
                if(this.user.user_projects){
                    return this.user.user_projects.map(element => element.project)
                }
                return []
            },
    
            deliverables() {
                if(this.user.user_projects){
                    let arr = []
                    for (const user_project of this.user.user_projects) {
                        for (const project_user_deliverable of user_project.project_user_deliverables) {
                            arr.push(project_user_deliverable)
                        }
                    }
                    return arr
                }
                return []
            },

            pendingProjectsCount() {
                return this.projects.filter(project => project.project_status.name.toLowerCase() != 'completed').length
            },

            firstDate() {
                return this.user?.created_at
            },

            totalEarned() {
                return this.deliverables.reduce((a, c) => {
                    return a += c.is_completed ? c.budget : 0
                }, 0)
            },

            pendingEarned() {
                return this.deliverables.reduce((a, c) => {
                    return a += !c.is_completed ? c.budget : 0
                }, 0)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .stats-wrapper{
        @apply bg-tan-m flex justify-between items-center px-12 py-10;
        .stats-container{
            @apply flex w-full justify-between items-start;
        }
    }
</style>
<template>
    <div class="container">
        <!-- header -->
        <div class="header">
            <div class="user">
                <avatar size="medium" :user="postedBy"/>
                <div>
                    {{postedBy.handle}}
                </div>
            </div>
            <div class="edit">
                <base-icon :size="8" name="ig-options" v-if="!isReport"/>
                <span v-else>{{ postItem.profile_item.total_followers | numeral('0a') }}</span>
            </div>
        </div>
        <!-- content -->
        <div class="content">
            <media-viewer :file="files[page]" :is-report="isReport" :viewOnly="viewOnly" @set-fail="$emit('set-fail')"/>
            <!-- navigation buttons -->
            <div v-if="page > 0" class="navigation-container left">
                <div @click="$emit('navigate', 'prev')" class="button">
                    <base-icon name="chevron-left" />
                </div>
            </div>
            <div v-if="page < files.length-1" class="navigation-container right">
                <div @click="$emit('navigate', 'next')" class="button">
                    <base-icon name="chevron-right" />
                </div>
            </div>
            <!-- navigation dots -->
            <div v-if="files.length > 1" class="dots-container">
                <div class="dots">
                    <div 
                        v-for="(file, index) in files"
                        class="dot"
                        :class="{ 'active': index == page }"
                        :key="index"
                    />
                </div>
            </div>
        </div>
        <!-- interaction -->
        <div class="interaction" :class="isReport && files.length > 1 ? 'mt-8' : ''">
            <div class="flex gap-x-4">
                <base-icon :size="6" name="ig-like"/>
                <span v-if="isReport" class="font-bold uppercase">{{ postItem.likes | numeral('0.0a') }}</span>
                <base-icon :size="6" name="ig-comments"/>
                <span v-if="isReport" class="font-bold uppercase">{{ postItem.comments | numeral('0.0a') }}</span>
                <base-icon :size="6" name="ig-share" v-if="!isReport || (isReport && postItem.shares)"/>
                <span v-if="isReport && postItem.shares > 0" class="font-bold uppercase">{{ postItem.shares | numeral('0.0a') }}</span>
                <!-- <base-icon :size="6" name="ig-save" v-if="!isReport || (isReport && postItem.saves)"/>
                <span v-if="isReport && postItem.saves > 0" class="font-bold uppercase">{{ postItem.saves | numeral('0.0a') }}</span> -->
            </div>
            <div v-if="!isReport">
                <base-icon :size="8" name="ig-bookmark"/>
            </div>
        </div>
        <div class="interaction font-bold" v-if="isReport && postItem.views > 0">
            {{ postItem.views | numeral('0,0') }} views
        </div>
        <div class="interaction" v-if="isReport && description">{{ description }}</div>
    </div>
</template>

<script>
import MediaViewer from '../MediaViewer.vue'
export default {
    props: ['files', 'description', 'postedBy', 'postedAt', 'page', 'viewOnly', 'isReport', 'postItem'],
    components: {
        MediaViewer
    },
    computed: {
        file(){
            return this.files[this.page]
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        max-width: 616px;
        background-color: rgba(255,255,255,1);
        border: 1px solid #dbdbdb;
        border: 1px solid rgba(219,219,219,1);
        border-radius: 3px;
    }
    .header{
        @apply flex justify-between items-center;
        & .user{
            @apply flex items-center gap-x-4;
            padding: 14px 4px 14px 16px;
        }
        & .edit{
            @apply flex justify-center items-center;
            padding: 8px;
            padding-right: 16px;
        }
    }
    .content{
        align-items: stretch;
        flex-direction: column;
        position: relative;
        background-color: rgba(239,239,239,1);
        display: block;
        width: 100%;

        & .navigation-container{
            position: absolute;
            bottom: 0px;
            top: 0px;
            display: flex;
            align-items: center;            

            &.left{
                left: 16px;
            }

            &.right{
                right: 16px;
            }

            & .button {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #bbbbbd;
                border-radius: 50%;
                height: 25px;
                width: 25px;
                cursor: pointer;
                box-shadow: #a3a3a3 -1px 0px 13px 0px;
            }
        }

        & .dots-container{
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: -22px;
            display: flex;
            justify-content: center;
            align-items: center;

            & .dots{
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 4px;

                & .dot{
                    border-radius: 50%;
                    height: 6px;
                    -webkit-transition: all .2s ease-in-out;
                    transition: all .2s ease-in-out;
                    width: 6px;
                    background: #a8a8a8;
                    &.active{
                        background: #0095f6;
                    }
                }
            }
        }
    }
    .interaction{
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.mt-8 {
            margin-top: 2rem !important;
        }
    }
</style>
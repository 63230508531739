var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"searchbar-wrapper"},[_c('div',{staticClass:"searchbar-container"},[_c('div',{staticClass:"icon"},[_c('base-icon',{attrs:{"name":"search"}})],1),_c('FormulateInput',{attrs:{"type":"text","placeholder":("Search " + (_vm.title.toLowerCase()) + " by name"),"outer-class":"w-full","element-class":"w-full","wrapper-class":"border rounded-md py-2 px-3 pl-16 bg-white h-16 flex items-center"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)]),(_vm.mimetype == 'reports')?_c('div',{staticClass:"filter-container"},[_c('custom-select',{attrs:{"label":"Report type","options":[
                { label: 'All', value: 'all' },
                { label: 'Creators', value: 'creators' },
                { label: 'Content', value: 'content' },
                { label: 'Brand', value: 'brand' }
            ]},model:{value:(_vm.selectedReportTypeFilter),callback:function ($$v) {_vm.selectedReportTypeFilter=$$v},expression:"selectedReportTypeFilter"}})],1):_vm._e(),_c('base-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'Refresh content',
            classes: 'v-tooltip-black' 
        }),expression:"{\n            content: 'Refresh content',\n            classes: 'v-tooltip-black' \n        }"}],staticClass:"cursor-pointer",attrs:{"name":"refresh","size":"4"},on:{"action":function () { return _vm.$emit('refresh'); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('base-table',{key:("creator-list-table-key-" + _vm.key),staticClass:"mt-8 mb-12",attrs:{"data":_vm.creatorsVal,"columns":[
            {
                name: 'status',
                label: 'Status',
                type: 'user-importable-status',
                onUpdateUser: _vm.onUpdateUser
            },
            {
                name: 'first_name', 
                label: 'First name'
            },
            {
                name: 'last_name', 
                label: 'Last name'
            },
            { 
                name: 'email', 
                label: 'Email'
            },
            {
                name: 'profile_link', 
                label: 'Profile Link'
            },
            {
                name: 'handle', 
                label: 'Handle',
                type: 'handle',
                verifyHandle: true,
                value: function (element) {
                    return element.handle;
                }
            },
            {
                name: 'platform',
                label: 'Platform',
                type: 'network-selector',
                value: function (element) {
                    return element.target_network
                },
                socialAccounts: function (element) {
                    return element.user && element.user.social_accounts ? element.user.social_accounts : [];
                },
                user: function (element) {
                    return element.user   
                },
                onUpdateValue: _vm.onUpdateTargetNetworkValue
            },
            { 
                name: 'description', 
                label: 'Description',
                type: 'long-text',
                value: function (element) {
                    return element.description;
                }
            },
            { 
                name: 'posts', 
                label: 'Posts',
                type: 'featured-posts',
                value: function (element) {
                    return element.posts
                },
                onUpdateValue: _vm.onUpdateValue
            },
            {
                name: 'additional-info',
                label: 'Additional Info',
                type: 'additional-info',
                value: function (element) {
                    return element.additional_information
                },
                onUpdateValue: _vm.onUpdateAdditionalInformationValue
            }
        ],"search":false},on:{"set-accounts":_vm.toggleNetwork}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { isValidString } from '../common'

export default function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export async function isSize(file, size) {
    return file.size <= size
}

export async function isType(file, type) {

    if(typeof type === 'string'){
        return file.type.includes(type)
    }

    if(Array.isArray(type)){
        let found = 0
        type.forEach(element => {
            if(file.type.includes(element)){
               found++ 
            }
        })
        return found ? true : false
    }
    
    return false
}

export async function isVideoAspectRatioInRange(file, minRatio, maxRatio){
    const ratio = await videoAspectRatio(file)
    const min =  await convertRatio(minRatio)
    const max = await convertRatio(maxRatio)
    return (ratio.value >= min) && (ratio.value <= max)
}

// converts 16:9 => 1.7777777
export async function convertRatio(ratio){
    let split = ratio.split(':')
    return parseFloat(split[0]) / parseFloat(split[1])
}

export async function videoAspectRatio(file) {
    var video = document.createElement('video')
    video.preload = 'metadata'

    let promise = new Promise((resolve, reject) => {
        video.onloadedmetadata = function() {
            window.URL.revokeObjectURL(video.src)

            // Gets the greatest common divisor 
            // If we want to get a video's aspect ratio we have to divide both values (width and height) by that
            // aspect = w/gcd(w,h) : h/gcd(w,h)
            function gcd (a, b) {
                return (b == 0) ? a : gcd (b, a%b);
            }

            const { videoWidth, videoHeight } = video

            const g = gcd(videoWidth, videoHeight)
            const value = videoWidth / videoHeight
            const displayName = `${videoWidth/g}:${videoHeight/g}`

            resolve({value, displayName})
        }
        video.onerror = function() {
            reject('videoAspectRatio Load Error')
        }
    })

    video.src = URL.createObjectURL(file)
    
    let result = await promise
    return result
}

export async function videoDuration(file) {
    var video = document.createElement('video')
    video.preload = 'metadata'

    let promise = new Promise((resolve, reject) => {
        video.onloadedmetadata = function() {
            window.URL.revokeObjectURL(video.src)
            resolve(video.duration)
        }
        video.onerror = function() {
            reject('videoDuration Load Error')
        }
    })

    video.src = URL.createObjectURL(file)
    
    let result = await promise
    return result
}

// basic method to get the file extension by its name
export function getFileExtension(file) {
    if(file && isValidString(file.name)){
        const split = file.name.split('.');
        const last = split[split.length - 1];
        return `.${last}`;
    } 
    return null;
}

// video/mp4 -> video
// image/png -> image
export function getContentType(mimetype){
    let contentTypes = {
        'video': ['video/mp4', 'video/mov', 'video/quicktime'],
        'image': ['image/png', 'image/jpeg', 'image/jpg'],
        'pdf': ['application/pdf'],
        'link': ['link'],
        'audio': ['audio/mpeg', 'audio/wav', 'audio/ogg']
        // 'other': [],
    }

    let found = null
    Object.entries(contentTypes).forEach(([key, value]) => {
        value.forEach(element => {
            if(mimetype == element){
                found = key
            }
        })
    });

    return found
    
}
<template>
    <base-modal 
        title="Search Docs"
        :visible="visible"
        @close="$emit('close')"
    >
        <FormulateForm name="form" @submit="submit()">
            
            <div class="flex flex-col gap-y-2">

                <FormulateInput
                    type="text"
                    placeholder="Search anything"
                    validation="required"
                    label="Search"
                    v-model="term"
                    :input-class="['searchbar']"
                    @input="key++"
                />

                <div class="flex flex-col justify-start items-start gap-y-4" :key="key">
                    <div 
                        class="cursor-pointer border py-3 px-3 rounded-xl border-black font-bold" 
                        v-for="(component, index) in foundComponents" 
                        :key="index"
                        @click="$router.push(`/docs/${component.replace('./','').replace('.vue','')}`); $emit('close'); term=''"
                    >
                        {{component.replace('./','').replace('.vue','')}}
                    </div> 
                </div>         

            </div>

            <div class="pt-3 sm:flex sm:flex-row-reverse">

                <base-button 
                    @action="$formulate.submit('form')" 
                    :disabled="loading" 
                    submit 
                    label="Search"
                />

            </div>

        </FormulateForm>
    </base-modal>
</template>

<script>
export default {
    props: ['visible'],
    data() {
        return {
            term: '',
            key: 0,
            loading: false,
            components: []
        }
    },
    watch: {
        async visible(val){
            if(val){
                this.getComponents()
                setTimeout(() => {
                    document.getElementsByClassName('searchbar')[0].focus();
                }, 200)
            }
        }
    },
    computed: {
        foundComponents(){
            if(!this.term || this.term == '') return []
            return this.components.filter(component => component.toLowerCase().includes(this.term.toLowerCase()))
        }
    },
    methods: {
        async submit() {
            this.loading = true
            try{
                const { data } = await this.$http.put(`/api/docs/search`, { 
                    editName: this.editName,
                    editUrl: this.editUrl,                                        
                });                
                this.$notify({ title: 'Success', text: 'File edited successfully', type: 'success' });
                this.$emit('file-edited');  
                this.$emit('close');                
            }catch(error){
                console.log(error)
            }finally{
                this.loading = false
            }
        },
        getComponents(){
            const components = require.context('./components', true, /^.*\.vue$/)
            this.components = components.keys()
        }
    }
}
</script>
<template>
    <div class="container">
        
        <!-- evaluating external user -->
        <div v-if="user">
            <avatar-list :users="[user]"/>
            <div v-if="socialLogin" class="small-text">Profile ID: {{socialLogin.profile_id}}</div>
        </div>

        <div>
            
            <div v-if="socialLogin" class="text">
                <div v-if="tokenInfo && tokenInfo.is_valid">
                    <base-icon name="check-circle" class="text-green"/> Logged in with facebook
                </div>
                <div v-else>
                    <base-icon name="check-circle" class="text-green"/> Has Logged in with facebook
                </div>
            </div>

            <div v-if="tokenInfo" class="text">
                <base-icon name="key"/> Access Token Exists
            </div>
            
            <base-icon v-if="loading" name="loader"/>

            <div v-if="tokenInfo">

                <div v-if="tokenInfo.is_valid" class="text">
                   <base-icon name="info-circle"/> Valid Token
                </div>
                <div v-else class="text">
                    <base-icon name="close"/> Not Valid
                </div>

                <div v-if="tokenInfo.issued_at" class="text">
                    <base-icon name="info-circle"/> Issued on {{ parseTimestamp(tokenInfo.issued_at) }}
                </div>
                
                <div v-if="tokenInfo.is_valid">
                    <div v-if="tokenInfo.expires_at == 0" class="text">
                        <base-icon name="info-circle"/> Never Expires
                    </div>
                    <div v-else>
                        <base-icon name="info-circle"/> Expires at: {{ parseTimestamp(tokenInfo.expires_at) }}
                    </div>
                </div>

                <div v-if="tokenInfo.scopes.length" class="mt-4">
                    <!-- title -->
                    Granted Permissions ({{tokenInfo.scopes.length}}) 
                    
                    <template v-if="tokenInfo.scopes.length >= 6"> 
                        <span class="link" @click="showAllScopes = !showAllScopes">
                            {{ `${showAllScopes ? `Hide` : `Show all`}` }}
                        </span>
                    </template>
                    
                    <!-- scope list -->
                    <div class="scope-list">
                        <div v-for="(scope, index) in tokenInfo.scopes.filter((element, index) => showAllScopes ? true : index < 6)" :key="index" class="scope">
                            {{scope}}
                        </div>
                    </div>
                    
                    <!-- missing scopes -->
                    <div v-if="missingPermissions()" class="mt-4">
                        <div class="flex items-center">
                            <base-icon name="exclamation-circle" />
                            <div>There are some missing permissions:</div>
                        </div>
                        <div class="scope-list">
                            <div v-for="(permission, index) in missingPermissions()" :key="index" class="scope">
                                {{permission}}
                            </div>
                        </div>
                    </div>

                    <!-- ig config always showing -->
                    <div style="width: 200px">
                        <facebook-login
                            v-if="subject == 'me'"
                            classes="mt-4 w-full"
                            label="Edit Settings"
                            action="request-instagram-permissions"
                            @success="data => $emit('onFacebookLoginSuccess', data)"
                        />
                    </div>

                    <!-- instagram accounts -->
                    
                    <!-- no instagram accounts -->
                    <div v-if="!igAccounts.length" class="mt-4 flex items-center">
                        <base-icon name="exclamation-circle" />
                        <div>There is no instagram account connected to this facebook account</div>
                    </div>

                    <!-- instagram accounts -->
                    <div v-else class="mt-4 pb-1">
                        <div class="text">
                            <base-icon name="instagram" /> 
                            Connected Instagram accounts ({{igAccounts.length}}) 
                            <template v-if="igAccounts.length > 3"> 
                                <span class="link" @click="showAllInstagramBusinessAccounts = !showAllInstagramBusinessAccounts">
                                    {{ `${showAllInstagramBusinessAccounts ? `Hide` : `Show all`}` }}
                                </span>
                            </template>
                        </div>
                        <div 
                            class="instagram-account" 
                            v-for="(igAccount, index) in igAccounts.filter((element, index) => showAllInstagramBusinessAccounts ? true : index <= 2 )" 
                            :key="index"
                            @click="profileRedirect(`https://instagram.com/${igAccount.username}`)"
                        >
                            <img class="profile-picture" :src="igAccount.profile_picture_url" />
                            <div class="names">
                                <div class="username">{{igAccount.username}}</div>
                                <div class="name">{{igAccount.name}}</div>
                            </div>
                        </div>
                    </div>

                    <!-- facebook accounts -->

                    <!-- no facebook accounts -->
                    <div v-if="!fbAccounts.length" class="mt-4 flex items-center">
                        <base-icon name="exclamation-circle" />
                        <div>There is no owned facebook pages</div>
                    </div>

                    <!-- facebook acocunts -->
                    <div v-else class="py-6">
                        <div class="text">
                            <base-icon name="facebook" /> 
                            Connected Facebook accounts ({{fbAccounts.length}}) 
                            <template v-if="fbAccounts.length > 3"> 
                                <span class="link" @click="showAllFacebookAccounts = !showAllFacebookAccounts">
                                    {{ `${showAllFacebookAccounts ? `Hide` : `Show all`}` }}
                                </span>
                            </template>
                        </div>
                        
                        <div 
                            class="facebook-account" 
                            v-for="(fbAccount, index) in fbAccounts.filter((element, index) => showAllFacebookAccounts ? true : index <= 2 )" 
                            :key="index"
                            @click="profileRedirect(fbAccount.link)"
                        >
                            <img v-if="getObjectValue(fbAccount, 'picture.data.url')" class="profile-picture" :src="fbAccount.picture.data.url" />
                            <div class="names">
                                <div class="username">{{fbAccount.name}}</div>
                                <div class="name">{{fbAccount.category}}</div>
                            </div>
                            <div class="task-list">
                                <div class="task" v-for="(task, index) in fbAccount.tasks" :key="index">
                                    {{task}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="missingPermissions() || !tokenInfo || !tokenInfo.is_valid" class="mt-4">
                    
                    <div class="mt-4">{{ subject === 'me' ? 
                        `Log in again to add all of the missing permissions` : 
                        'Ask the user to log in again to add all of the missing permissions' 
                    }}</div>

                    <div v-if="subject === 'me'" style="width: 320px">
                        <facebook-login
                            label="Login with Facebook"
                            @success="data => $emit('onFacebookLoginSuccess', data)"
                        />
                    </div>
                    <base-button
                        v-else
                        @action="$emit('showInviteToLoginWithFacebook')"
                        style="width: 300px; padding: 10px 10px; margin-top: 10px;"
                        type="secondary"
                        icon="facebook"
                        label="Invite to login with facebook"
                    />
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import AvatarList from './AvatarList.vue';
import FacebookLogin from './FacebookLogin.vue';
import { scope as permissions } from '@/lib/facebook';
import { getObjectValue } from '../common'
import moment from 'moment'
export default {
    components: {
        AvatarList,
        FacebookLogin
    },
    props: {
        userId: {
            type: Number,
            required: true
        },
        // just if you want to show the profile with the facebook id
        user: {
            type: Object,
            required: false
        },
        subject: {
            type: String,
            required: false,
            default: 'creator' // or "me"
        }
    }, 
    data(){
        return {
            
            permissions: permissions,
            
            socialLogin: {},
            tokenInfo: null,
            igAccounts: [],
            fbAccounts: [],
            
            loading: false,

            showAllScopes: false,
            showAllInstagramBusinessAccounts: false,
            showAllFacebookAccounts: false,
            getObjectValue
        }
    },
    mounted(){
        this.getSocialLogin()
    },
    methods: {
        getSocialLogin(){
            this.loading = true
            this.$http.get(`/api/social-login`, {
                params: {
                    network_id: 6,
                    user_id: this.userId
                }
            }).then(async (res) => {
                if(res.data){
                    this.socialLogin = res.data
                    this.getTokenInfo()
                    this.getIgAccounts()
                    this.getFbAccounts()
                }
            }).catch((err) => {
                console.log('getSocialLogin error', err)
            }).finally(() => {
                this.loading = false
            }) 
        },
        getTokenInfo(){
            this.loading = true
            this.$http.get(`/api/facebook/access-token?social_login_id=${this.socialLogin.id}`).then(async (res) => {
                if(res.data){
                    this.tokenInfo = res.data
                }
            }).catch((err) => {
                console.log('getTokenInfo error', err)
            }).finally(() => {
                this.loading = false
            }) 
        },
        getIgAccounts(){
            this.loading = true
            this.$http.get(`/api/facebook/ig-accounts?social_login_id=${this.socialLogin.id}`).then(res => {
                if(res.data){
                    this.igAccounts = res.data
                }
            }).catch(err => {
                console.log('getIgAccounts error', err)
            }).finally(() => {
                this.loading = false
            })
        },
        getFbAccounts(){
            this.loading = true
            this.$http.get(`/api/facebook/fb-accounts?social_login_id=${this.socialLogin.id}`).then(res => {
                if(res.data){
                    this.fbAccounts = res.data
                }
            }).catch(err => {
                console.log('getFbAccounts error', err)
            }).finally(() => {
                this.loading = false
            })
        },
        parseTimestamp(timestamp){
            var date = new Date(timestamp * 1000)
            return moment(date).format('LL')
        },
        missingPermissions(){
            if(this.tokenInfo.scopes && this.tokenInfo.scopes.length > 0){
                const arr = this.permissions.trim().replaceAll(',', '').split(' ')
                let missingPermissions = []
                for (const permission of arr) {
                    if( !this.tokenInfo.scopes.includes(permission) ) {
                        missingPermissions.push(permission)
                    }
                }
                if(missingPermissions.length){
                    return missingPermissions
                }
            }
            return false
        },
        profileRedirect(url){
            window.open(url, '_blank');
        },
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply flex gap-x-4;
        max-height: 75vh;
        overflow-y: auto;
    }
    .small-text{
        @apply text-pxs text-gray-400;
    }
    .text{
        @apply flex items-center gap-x-2
    }
    .link{
        @apply cursor-pointer text-pxs text-blue-700;
    }

    .scope-list{
        @apply flex flex-wrap gap-x-2 gap-y-2 mt-2;
        .scope{
            @apply bg-purple-m-main text-white text-pxs rounded-full px-3 py-1;
        }
    }

    .instagram-account{
        @apply flex gap-x-4 py-2 cursor-pointer transition-opacity duration-500;
        &:hover{
            @apply opacity-60
        }
        .profile-picture{
            @apply rounded-full border border-gray-500 w-14 h-14;
        }
        .names{
            @apply flex flex-col justify-center gap-y-0.5;
            .username{
                @apply font-bold text-h6;
            }
            .name{
                @apply text-gray-500 text-h6;
            }
        }
    }
    .facebook-account{
        @apply flex gap-x-4 py-2 cursor-pointer transition-opacity duration-500;
        &:hover{
            @apply opacity-60
        }
        .profile-picture{
            @apply rounded-full border border-gray-500 w-14 h-14;
        }
        .names{
            @apply flex flex-col justify-center gap-y-0.5;
            .name{
                @apply font-bold text-h6;
            }
            .category{
                @apply text-gray-500 text-h6;
            }
        }
        .task-list{
            @apply flex gap-x-2 flex-wrap items-center;
            .task{
                @apply bg-purple-m-main text-white text-pxs rounded-full px-3 py-1;
            }
        }
    }
</style>
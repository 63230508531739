<template>
    <div class="container">
        <div class="content">
            <div class="header">
                <div class="font-bold mb-2">{{data.name}}</div>
                <base-icon v-if="loading" name="loader" />
            </div>
            <Checkbox
                :options="options"
                v-model="selected"
            />
            <div v-if="selected.length" class="flex justify-end">
                <base-button
                    type="secondary" 
                    label="Clear all"
                    icon="close"
                    @action="selected = []"
                    size="md"
                />
            </div>

        </div>
        <div class="footer">
            <base-button
                :label="`${filters.tags ? `Update Filter` : `Add Filter`}`"
                icon="filter"
                size="lg"
                @action="addFilter"
            />

            <base-button
                type="secondary"
                label="Cancel"
                @action="$emit('close')"
            />
        </div>
    </div>
</template>

<script>
import Checkbox from '../../../components/Checkbox.vue'
export default {
    props: ['data', 'filters'],
    components: {
        Checkbox
    },
    data(){
        return {
            options: [],
            selected: [],
            loading: false
        }
    },
    mounted(){
        this.getOptions()
    },
    methods: {
        async getOptions(){
            this.loading = true
            try {
                const { data } = await this.$http.get(`/api/tags`)
                this.options = data.map(tag => {
                    return {
                        id: tag.slug,
                        label: tag.name
                    }
                })
                if(this.filters.tags){
                    this.selected = this.filters.tags
                }
            } catch(e) {
                console.log(e)
            } finally{
                this.loading = false
            }
        },
        addFilter(){
            this.$emit('addFilter', 'tags', this.selected)
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply relative h-full flex flex-col;
        .content{
            @apply flex-grow flex-shrink;
            .header{
                @apply flex justify-between items-center;
            }
        }
        .footer{
            @apply sticky bottom-0 left-0 right-0 py-5 bg-tan-m flex justify-between items-center;
        }
    }
</style>
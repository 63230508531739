var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select-wrapper relative flex w-full h-full"},[_c('base-button',{class:[("w-full h-full text-pxs py-1 " + (!_vm.collapsed ? 'pr-3 pl-4' : '') + " focus:outline-none transition ease-in-out duration-150"),
            ("rounded-" + _vm.rounded + " border border-transparent bg-tan-m"),
            {'rounded-b-none': _vm.open && !_vm.colors},
            {'bg-white hover:bg-gray-m-light': _vm.theme === 'light'},
            {'bg-gray-m-light': _vm.open && _vm.theme === 'light'},
            {'bg-black hover:bg-gray-m-dark': _vm.theme === 'dark'},
            {'bg-gray-m-dark': _vm.open && _vm.theme === 'dark'},
            {'bg-transparent': _vm.disabled},
            {'hover:opacity-75': _vm.getLabelColor}],style:(_vm.getLabelColor ? ("background-color: " + _vm.getLabelColor) : ''),attrs:{"type":"label","rounded":_vm.colors ? 'full' : _vm.rounded,"disabled":_vm.disabled,"theme":_vm.getLabelColor ? (_vm.hexToRgbSum(_vm.getLabelColor) > _vm.themeThreshold ? 'light' : 'dark') : _vm.theme,"hover":_vm.getLabelColor ? false : true},on:{"action":function($event){return _vm.toggleDropdown(!_vm.open)}}},[(!_vm.collapsed)?_c('div',{staticClass:"w-full h-full flex gap-x-2 justify-start items-center"},[_c('div',{staticClass:"line-clamp-1 text-left"},[_vm._v(_vm._s(_vm.getLabel))]),(!_vm.disabled)?_c('base-icon',{staticClass:"ml-auto",attrs:{"name":"chevron-down"}}):_vm._e()],1):_c('div',[_c('base-icon',{staticClass:"m-auto",attrs:{"name":"chevron-down"}})],1)]),(_vm.open)?_c('div',{class:[("select-menu absolute z-10 top-full " + (!_vm.colors ? '-mt-px' : '') + " w-72 shadow-lg rounded-lg"),
            {'rounded-t-none': !_vm.colors},
            ("" + (_vm.collapsed ? 'left-0 rounded-t-md rounded-tl-none' : 'left-1/2 -ml-28')),                
            {'bg-white': _vm.theme === 'light'}, {'bg-black': _vm.theme === 'dark'}],style:(("" + (_vm.selectMenuStyle ? _vm.selectMenuStyle : "")))},[_c('div',{},[(_vm.options.length || _vm.defaultOption || _vm.addingOption)?_c('div',{class:_vm.colors ? 'space-y-2 p-3 pb-2' : ''},[_c('div',{staticClass:"h-6 flex items-center justify-between",on:{"click":function (e){ return e.stopPropagation(); }}},[(_vm.addingOption)?_c('base-button',{attrs:{"type":"label","icon":"arrow-left","rounded":"none","theme":"neutral","label":_vm.addLabel},on:{"action":function($event){return _vm.cancelAddOption()}}}):(_vm.editingOption)?_c('base-button',{attrs:{"type":"label","icon":"arrow-left","rounded":"none","theme":"neutral","label":"Edit Status"},on:{"action":function($event){return _vm.cancelEditOption()}}}):_c('span',{staticClass:"text-h6 text-purple-m-secondary"},[_vm._v("Statuses")]),(!_vm.addingOption && !_vm.editingOption)?_c('base-button',{attrs:{"icon":"plus","type":"label"},on:{"action":_vm.startAddOption}}):_vm._e()],1),(_vm.addingOption)?_c('div',{},[_c('div',{class:['w-full space-y-2', {'py-1 px-2': !_vm.colors},
                      {'hover:bg-gray-m-light': _vm.theme === 'light' && !_vm.colors},
                      {'hover:bg-gray-m-dark': _vm.theme === 'dark' && !_vm.colors}]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newOption),expression:"newOption"}],class:['w-full text-left text-pxs', ("" + (_vm.colors ? 'py-3 px-4' : 'py-1 px-2')),
                      ("rounded-" + (_vm.colors ? 'lg' : _vm.rounded)), 'border border-gray-m focus:outline-none',
                      {'text-purple-m-secondary bg-white': _vm.theme === 'light'},
                      {'text-white bg-black': _vm.theme === 'dark'}],attrs:{"type":"text","placeholder":"Label"},domProps:{"value":(_vm.newOption)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newOption=$event.target.value}}}),(_vm.colors)?_c('div',{staticClass:"grid grid-cols-4 gap-2"},_vm._l((_vm.colors),function(color,i){return _c('span',{key:i,staticClass:"relative cursor-pointer h-10 rounded-lg border border-transparent",style:(("background-color: " + (color.name))),on:{"click":function($event){$event.preventDefault();_vm.newOptionColor = color}}},[(_vm.newOptionColor === color)?_c('base-icon',{class:("absolute top-2 left-4 text-" + ((_vm.hexToRgbSum(color.name) > _vm.themeThreshold ? 'black' : 'white'))),attrs:{"name":"check"}}):_vm._e()],1)}),0):_vm._e()])]):(_vm.editingOption)?_c('div',{},[_c('div',{class:['w-full space-y-2', {'py-1 px-2': !_vm.colors},
                      {'hover:bg-gray-m-light': _vm.theme === 'light' && !_vm.colors},
                      {'hover:bg-gray-m-dark': _vm.theme === 'dark' && !_vm.colors}]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.options[_vm.changedOption].editName),expression:"options[changedOption].editName"}],class:['w-full text-left text-pxs', ("" + (_vm.colors ? 'py-3 px-4' : 'py-1 px-2')),
                      ("rounded-" + (_vm.colors ? 'lg' : _vm.rounded)), 'border border-gray-m focus:outline-none',
                      {'text-purple-m-secondary bg-white': _vm.theme === 'light'},
                      {'text-white bg-black': _vm.theme === 'dark'}],attrs:{"type":"text","placeholder":"Label"},domProps:{"value":(_vm.options[_vm.changedOption].editName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.options[_vm.changedOption], "editName", $event.target.value)}}}),(_vm.colors)?_c('div',{staticClass:"grid grid-cols-4 gap-2"},_vm._l((_vm.colors),function(color,i){return _c('span',{key:i,staticClass:"relative cursor-pointer h-10 rounded-lg border border-transparent",style:(("background-color: " + (color.name))),on:{"click":function($event){$event.preventDefault();_vm.options[_vm.changedOption].editColor = color}}},[(_vm.options[_vm.changedOption].editColor && _vm.options[_vm.changedOption].editColor.id === color.id)?_c('base-icon',{class:("absolute top-2 left-4 text-" + ((_vm.hexToRgbSum(color.name) > _vm.themeThreshold ? 'black' : 'white'))),attrs:{"name":"check"}}):_vm._e()],1)}),0):_vm._e()])]):_c('div',{staticClass:"flex flex-col gap-y-2"},[(_vm.defaultOption)?[_c('div',{class:[{'hover:bg-gray-m-light': _vm.theme === 'light' && !_vm.colors},
                          {'hover:bg-gray-m-dark': _vm.theme === 'dark' && !_vm.colors}]},[_c('base-button',{class:['w-full px-4', ("" + (_vm.colors ? 'py-2' : 'py-2')),
                        {'hover:bg-gray-m-light': _vm.theme === 'light' && _vm.colors},
                        {'hover:bg-gray-m-dark': _vm.theme === 'dark' && _vm.colors}],attrs:{"type":"label","rounded":_vm.rounded,"justify":"start","theme":_vm.theme,"label":_vm.defaultOption},on:{"action":function($event){return _vm.selectDefault()}}})],1)]:_vm._e(),(_vm.options.length)?_vm._l((_vm.options),function(option,i){return _c('div',{key:i,on:{"mouseenter":function($event){option.showActions = true},"mouseleave":function($event){option.showActions = false}}},[_c('div',{class:['flex justify-between items-center gap-x-1',
                          {'hover:bg-gray-m-light': _vm.theme === 'light' && !_vm.colors},
                          {'hover:bg-gray-m-dark': _vm.theme === 'dark' && !_vm.colors}]},[_c('div',{staticClass:"w-5/6 max-w-5/6 overflow-x-hidden"},[(!option.editing)?_c('base-button',{class:['w-full max-w-full truncate px-4 text-pxs bg-tan-m', ("" + (_vm.colors ? 'py-3' : 'py-2')),
                            {'border border-transparent': _vm.colors},
                            {'hover:bg-gray-m-light': _vm.theme === 'light' && _vm.colors},
                            {'hover:bg-gray-m-dark': _vm.theme === 'dark' && _vm.colors},
                            {'hover:opacity-75': option.color}],style:(option.color ? ("background-color: " + (option.color.name)) : ''),attrs:{"type":"label","rounded":_vm.colors ? 'lg' : _vm.rounded,"justify":"start","theme":option.color ? (_vm.hexToRgbSum(option.color.name) > _vm.themeThreshold ? 'light' : 'dark') : _vm.theme,"hover":option.color ? false : true,"label":option.name},on:{"action":function($event){return _vm.selectOption(option)}}}):_vm._e()],1),_c('div',{class:['h-8 w-1/6 flex justify-center items-center', {'my-auto mr-2': !_vm.colors}],on:{"click":function (e){ return e.stopPropagation(); }}},[(!option.editing)?_c('base-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(option.is_default ? { content: "Default status", classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } } : {}),expression:"option.is_default ? { content: `Default status`, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } } : {}"}],class:['w-full h-full'],attrs:{"disabled":_vm.editingOption || _vm.addingOption || option.is_default,"theme":option.is_default ? 'light' : 'neutral',"icon":"pencil","type":"label","rounded":"full"},on:{"action":function($event){return _vm.startEditOption(option)}}}):_vm._e()],1)])])}):_vm._e()],2)]):[_c('div',{staticClass:"px-6 leading-10 py-4 text-sm text-gray-m-disable cursor-not-allowed text-center"},[_vm._v(" There are no options available. ")])],_c('div',{staticClass:"px-3 pb-3 pt-0",on:{"click":function (e){ return e.stopPropagation(); }}},[(_vm.addingOption)?[_c('div',{staticClass:"flex justify-between items-center"},[_c('base-button',{class:['w-full px-4 py-3 bg-purple-m-main text-pxs',
                      {'hover:bg-purple-m-secondary': _vm.theme === 'light'},
                      {'hover:bg-gray-m-dark': _vm.theme === 'dark'}],attrs:{"type":"label","rounded":"lg","theme":"neutral-dark","label":"Create"},on:{"action":function($event){return _vm.addOption()}}})],1)]:(_vm.editingOption)?[_c('div',{staticClass:"flex justify-between gap-x-2 items-center"},[_c('base-button',{class:['w-full px-4 py-3 bg-purple-m-main text-pxs',
                      {'hover:bg-purple-m-secondary': _vm.theme === 'light'},
                      {'hover:bg-gray-m-dark': _vm.theme === 'dark'}],attrs:{"type":"label","rounded":"lg","theme":"neutral-dark","label":"Save"},on:{"action":function($event){return _vm.editOption()}}}),_c('base-button',{class:['w-full px-4 py-3 bg-tan-m text-pxs',
                    {'hover:bg-gray-m-light': _vm.theme === 'light'},
                    {'hover:bg-gray-m-dark': _vm.theme === 'dark'}],attrs:{"type":"label","rounded":"lg","theme":"neutral","label":"Delete"},on:{"action":function($event){return _vm.startDeleteOption(_vm.options[_vm.changedOption])}}})],1)]:_vm._e()],2)],2)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
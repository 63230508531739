<template>
    <base-modal
        :visible="visible"
        @close="$emit('close')"
        with-picture
        title="Add Agent"
        modal-icon="brief"
        modal-title="Agent"
    >
        <div class="h-full flex flex-col justify-between">

            <div class="grid grid-cols-2 gap-x-5 gap-y-4">
                
                <FormulateInput
                    type="text"
                    label="First name"
                    class="w-full"
                    placeholder="Jane"
                    validation="required"
                    validation-name="First name"
                    v-model="secondaryContact.first_name"
                    :label-class="['font-bold']"
                />
                <FormulateInput
                    type="text"
                    label="Last name"
                    class="w-full"
                    placeholder="Smith"
                    validation="required"
                    validation-name="Last name"
                    v-model="secondaryContact.last_name"
                    :label-class="['font-bold']"
                />
                <FormulateInput
                    type="email"
                    label="Email"
                    placeholder="Enter email"
                    validation="required"
                    validation-name="Email"
                    v-model="secondaryContact.email"
                    @input="onSecondaryContactEmailInput"
                    v-debounce="findUserByEmail"
                />
                <FormulateInput
                    type="number"
                    label="Phone (Optional)"
                    class="w-full"
                    placeholder="123-456-789"
                    v-model="secondaryContact.phone"
                    :label-class="['font-bold']"
                    inputmode="numeric"
                    pattern="[0-9]*"
                />
                
                <entity-select
                    :selectedEntity="selectedEntity"
                    @selected="(entity) => selectedEntity = entity"
                    :overrideTerm="entitySelectTerm"
                />
        
            </div>

            <div class="w-full flex gap-x-4 justify-end">
                <base-button 
                    @action="submit" 
                    label="Submit"
                />
            </div>

        </div>

    </base-modal>
</template>

<script>
import BaseModal from '../../components/BaseModal';
import EntitySelect from '../../components/EntitySelect.vue';
import { validateEmail } from '../../lib/strings';
export default {
    props: ['visible'],
    components: {
        BaseModal,
        EntitySelect
    },
    data(){
        return {
            
            secondaryContact: {
                first_name: '',
                last_name: '',
                email: '',
                phone: ''
            },
            selectedEntity: null,
            entitySelectTerm: '',
            
            loading: false
        }
    },
    methods: {
        async submit(){
            this.loading = true
            try {
                const { data } = await this.$http.post(`/api/users/secondary-contact`, {
                    secondary_contact: this.secondaryContact,
                    entity: this.selectedEntity
                })

                if(data && data.user){
                    this.$notify({ title: 'Success', text: 'Agent created successfully', type: 'success' })
                    this.$emit('created')
                    this.$emit('close')
                }
                
            } catch(e) {
                console.log(e);
                this.$notify({ title: 'Error', text: `${(e.response && e.response.data) ? e.response.data.message : ``}`, type: 'warn' })
            } finally {
                this.loading = false;
            }
        },
        async findUserByEmail() {
            if (this.secondaryContact.email !== '' && this.validEmail(this.secondaryContact.email)) {
                const {data} = await this.$http.get(`/api/users/byEmail`, {
                    params: {
                        email: this.secondaryContact.email
                    }
                });
                let user = data
                if (user) {
                    // if user is found, then we populate names, phone
                    const {first_name, last_name, phone} = user
                    this.secondaryContact.first_name = first_name;
                    this.secondaryContact.last_name = last_name;
                    this.secondaryContact.phone = phone ? phone : this.secondaryContact.phone;

                    this.$notify({title: 'Found user', text: '', type: 'success'})
                }
            }
        },

        validEmail(email){
            return validateEmail(email);
        },
        onSecondaryContactEmailInput(value){
            let v = value.split('@')
            if(v[1]) this.entitySelectTerm = v[1]
        },
    }
}
</script>
<template>
    <base-modal 
        title="Custom Fields" 
        :visible="visible"
        @close="close()"
        styles="max-height: 90vh; overflow-y: auto; min-width: 80vw; width: 80vw;"
    >   
        <!-- ensure that the data is ready for the render -->
        <div 
            class="additional-info" 
            v-if="user && user.report_user && user.report_user.id && userVal && userVal.additional_info"
        >
            <!-- header -->
            <div class="header">
                <div class="title">Select the creator's custom fields you want for this report</div>
                <div class="actions">
                    <base-button
                        label="Add Info"
                        bold
                        icon="plus"
                        @action="createAdditionalInfoModalVisible = true"
                    />
                </div>
            </div>

            <!-- tabs -->
            <div class="tabs">
                <tabs 
                    :tabs="['This Report', 'Other']" 
                    v-model="tab"
                    styles="padding-bottom: 12px;"
                />
            </div>
            
            <!-- content -->
            <div class="content">

                <!-- This report's additional informations -->
                <tab :visible="tab == 'This Report'">
                    <div class="empty-state" v-if="!isValidArray(filteredAdditionalInfo)">
                        No additional info
                    </div>
                    <div class="info-list" v-else>
                        <custom-fields-modal-item
                            v-for="(info, index) in filteredAdditionalInfo"
                            :key="`info-${index}`"
                            :info="info"
                            :is-selected="isSelected(info)"
                            @toggle-info="toggleInfo(info)"
                            @open-create-additional-info-modal="openCreateAdditionalInfoModal(info)"
                            @delete-additional-info-intent="deleteAdditionalInfoIntent(info.id)"
                        />
                    </div>
                </tab>

                <!-- all other additional infos -->
                <tab :visible="tab == 'Other'">
                    <div class="empty-state" v-if="!isValidArray(otherAdditionalInfo)">
                        No additional info
                    </div>
                    <div class="info-list" v-else>
                        <custom-fields-modal-item
                            v-for="(info, index) in otherAdditionalInfo"
                            :key="`info-${index}`"
                            :info="info"
                            :is-selected="isSelected(info)"
                            @toggle-info="toggleInfo(info)"
                            @open-create-additional-info-modal="openCreateAdditionalInfoModal(info)"
                            @delete-additional-info-intent="deleteAdditionalInfoIntent(info.id)"
                        />
                    </div>
                </tab>

            </div>

            <div class="footer">
                <base-button
                    label="Save"
                    bold
                    @action="submit()"
                />
            </div>

            <create-additional-info-modal
                :visible="createAdditionalInfoModalVisible"
                :user-id="user.id"
                :current-additional-info="targetAdditionalInfo"
                :report-user-id="user.report_user.id"
                @reload="getAdditionalInfo()"
                @close="closeCreateAdditionalInfoModal()"
            />

        </div>
    </base-modal>
</template>

<script>
    import { isArray, notifyCatchError } from '@/common';
    import CreateAdditionalInfoModal from './CreateAdditionalInfoModal.vue'
    import { isValidArray } from '../../common';
    import CustomFieldsModalItem from './CustomFieldsModalItem.vue';
    export default {
        components: { 
            CreateAdditionalInfoModal,
            CustomFieldsModalItem 
        },
        name: 'CustomFieldsModal',
        props: ['visible', 'user'],
        watch: {
            user(val){
                // copy the object
                this.userVal = val;
            },
            visible(val){
                if(val == true){
                    this.getAdditionalInfo();
                }
            }
        },
        data(){
            return {
                tab: 'This Report',
                userVal: this.user,
                selectedAdditionalInfo: [],
                createAdditionalInfoModalVisible: false,
                targetAdditionalInfo: null,
                loading: false,
                isArray,
                isValidArray
            }
        },
        computed: {
            // Filter additional info so we only show the additional informations that are currently showing on the report
            // all other additional informations that dont go through this filter will be shown on the "Other" tab
            filteredAdditionalInfo(){
                const additional_info = (this.userVal && this.userVal.additional_info) ? this.userVal.additional_info : null;
                const report_user_id = (this.user && this.user.report_user && this.user.report_user.id) ? this.user.report_user.id : null;
                if(additional_info && report_user_id){
                    return additional_info.filter(element => {
                        if(isValidArray(element.report_user_additional_info)){
                            const found = element.report_user_additional_info.find(e => {
                                // Here we might also want to filter depending on if they are selected or not,
                                // by checking: "!e.deleted_at" however we would have to make sure we are also 
                                // fetching soft deleted records.
                                //
                                // We might also want to filter depending on if the additional information is 
                                // exclusive or not by checking: "e.is_exclusive"
                                //
                                // For now, we are only checking whether there is a relationship between the
                                // additional info and the report user, by checking the report_user_id. This might
                                // not bring soft deleted records which means that if an additional field gets 
                                // deselected, it will appear on the "Other" tab so just keep that in mind. 
                                return e.report_user_id == report_user_id;
                            });
                            if(found) return true;
                        }
                        return false;
                    });
                }
                return [];
            },
            // Returns an array of the excluded elements in the above "filteredAdditionalInfo" function.
            otherAdditionalInfo(){
                const additional_info = (this.userVal && this.userVal.additional_info) ? this.userVal.additional_info : null;
                if(additional_info){
                    return additional_info.filter(element => {
                        const found = this.filteredAdditionalInfo.find(e => e.id == element.id);
                        if(found){
                            return false
                        }else{
                            return true;
                        }
                    });
                }
                return [];
            }
        },
        methods: {

            submit(){
                this.loading = true;
                this.$http.put(`/report-user-additional-info/${this.user.report_user.id}`, {
                    additional_info: this.selectedAdditionalInfo
                }).then(({ data }) => {
                    this.$notify({title: 'Info saved successfully', text: '', type: 'success'});
                    this.$emit('refresh');
                    this.close();
                    this.$forceUpdate();              
                }).catch(err => {
                    notifyCatchError(err, this.$notify);
                    console.log('submit error', err);
                }).finally(() => {
                    this.loading = false;
                });
                return;
            },

            toggleInfo(info){
                if(this.isSelected(info)){
                    this.selectedAdditionalInfo = this.selectedAdditionalInfo.filter(element => element.id !== info.id);
                }else{
                    this.selectedAdditionalInfo.push(info);
                }
                this.$emit('change', this.selectedAdditionalInfo);
            },
            
            // like toggle info but you can set the value specifically
            setInfo(info, val = true){
                if(val == true){
                    this.selectedAdditionalInfo.push(info);
                }else{
                    this.selectedAdditionalInfo = this.selectedAdditionalInfo.filter(element => element.id !== info.id);
                }
                this.$emit('change', this.selectedAdditionalInfo);
            },

            isSelected(info){
                const found = this.selectedAdditionalInfo.find(element => element.id == info.id);
                if(found){
                    return true;
                }
                return false;
            },

            // probably wont need this
            getAdditionalInfo(){
                this.loading = true;
                this.$http.get(`/user-additional-info/report-user/${this.user.report_user.id}`).then(({ data }) => {
                    this.userVal.additional_info = data
                    if(this.user && this.user.report_user && isArray(this.user.report_user.additional_info)){
                        for (const additional_info of this.user.report_user.additional_info) {
                            const foundInfo = this.userVal.additional_info.find(element => element.id == additional_info.id);
                            if(foundInfo){
                                this.setInfo(foundInfo, true);
                            }
                        }
                    }
                    this.$forceUpdate();              
                }).catch(err => {
                    console.log('getAdditionalInfo error', err);
                }).finally(() => {
                    this.loading = false;
                });
                return;
            },

            deleteAdditionalInfoIntent(id){
                this.$swal.fire({
                    title: 'Are you sure you want to delete this information for this creator?',
                    text: `Changes made will also be reflected on this creator's reports`,
                    icon: 'warning',
                    iconColor: '#0E092C',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    reverseButtons: true,
                }).then((result) => {
                    if(result.isConfirmed){
                        this.deleteAdditionalInfo(id)
                    }
                })
            },

            async deleteAdditionalInfo(id){
                this.loading = true;
                this.$http.delete(`/user-additional-info/${id}`).then(async (res) => {
                    if(res.data){
                        this.$notify({title: 'Info deleted successfully', text: '', type: 'success'})
                        this.getAdditionalInfo();
                    }
                }).catch((err) => {
                    console.log('deleteAdditionalInfo error', err)
                    notifyCatchError(err, this.$notify);
                }).finally(() => {
                    this.loading = false
                })
            },
            
            openCreateAdditionalInfoModal(info){
                this.targetAdditionalInfo = info;
                this.createAdditionalInfoModalVisible = true;
            },

            closeCreateAdditionalInfoModal(){
                this.targetAdditionalInfo = null;
                this.createAdditionalInfoModalVisible = false;
            },

            close(){
                this.tab = 'This Report';
                this.selectedAdditionalInfo = [];
                this.createAdditionalInfoModalVisible = false;
                this.targetAdditionalInfo = null;
                this.loading = false;
                this.$emit('close');
            }
        }
    }
</script>
<style lang="scss" scoped>
    .additional-info{
        > .header{
            @apply w-full mb-4 flex justify-between items-center;
            > .title{
                @apply font-bold text-h5;
            }
        }
        > .tabs{
            @apply flex justify-between;
        }
        > .content{
            @apply mt-8 mb-10;
             .empty-state{
                @apply;
            }
             .info-list{
                @apply flex flex-wrap gap-3;
            }
        }
        > .footer{
            @apply flex justify-end;
        }
    }
</style>

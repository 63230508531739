
import { getObjectValue } from '../common'

// used to display deleted or missing users on avatar/avatarlist
export const defaultUserObject = {
    "id": 0,
    "first_name": "Deleted",
    "last_name": "Account",
    "avatar": null,
    "email": "deleted-account@creatorsaurus.com"
}

// Returns first found audience location or ''
export function location(user){
    try{
        const audience_data = getObjectValue(user, 'audience_data', 'no audience data')
        const ig_audience_data = audience_data.find(element => element.network_id == 2)
        const tiktok_audience_data = audience_data.find(element => element.network_id == 14)
        return getObjectValue(ig_audience_data, 'location.country.name') || getObjectValue(tiktok_audience_data, 'location.country.name') || ''
    }catch(ex){
        return ''
    }
}

// Returns an specific audience data location and type, with null default value
export function audienceDataLocation(user, network = 'instagram', type = 'country', defaultValue = null){
    try{
        const audience_data = getObjectValue(user, 'audience_data', [])
        
        if(network == 'instagram'){
            const ig_audience_data = audience_data.find(element => element.network_id == 2)
            return getObjectValue(ig_audience_data, `location.${type}.name`, defaultValue)
        }

        if(network == 'tiktok'){
            const tiktok_audience_data = audience_data.find(element => element.network_id == 14)
            return getObjectValue(tiktok_audience_data, `location.${type}.name`, defaultValue)
        }
    }catch(ex){
        return ''
    }
}

export function getAudienceData(user, network = 'instagram', defaultValue = null){
    try{
        const audience_data = getObjectValue(user, 'audience_data', [])
        
        if(network == 'instagram'){
            const ig_audience_data = audience_data.find(element => element.network_id == 2)
            if(ig_audience_data){
                return ig_audience_data
            }
        }

        if(network == 'tiktok'){
            const tiktok_audience_data = audience_data.find(element => element.network_id == 14)
            if(tiktok_audience_data){
                return tiktok_audience_data
            }
        }
        
        return defaultValue

    }catch(ex){
        return defaultValue
    }
}

export function tiktokFollowers(user){
    try{
        const social_accounts = getObjectValue(user, 'social_accounts')
        if(!social_accounts) return ''
        const foundIg = social_accounts.find(element => element.network_id == 14)
        if(!foundIg) return '-'
        return getObjectValue(foundIg, 'reach')
    }catch(ex){
        return ''
    }
}

export function instagramFollowers(user){
    try{
        const social_accounts = getObjectValue(user, 'social_accounts')
        if(!social_accounts) return ''
        const foundIg = social_accounts.find(element => element.network_id == 2)
        if(!foundIg) return '-'
        return getObjectValue(foundIg, 'reach')
    }catch(ex){
        return ''
    }
}

export function youtubeFollowers(user){
    try{
        const social_accounts = getObjectValue(user, 'social_accounts')
        if(!social_accounts) return ''
        const foundIg = social_accounts.find(element => element.network_id == 3)
        if(!foundIg) return '-'
        return getObjectValue(foundIg, 'reach')
    }catch(ex){
        return ''
    }
}

export function instagramAccounts(user){
    try{
        const social_accounts = getObjectValue(user, 'social_accounts')
        if(!social_accounts) return []
        const ig_accounts = social_accounts.filter(element => element.network_id == 2)
        if(!ig_accounts || !Array.isArray(ig_accounts) || !ig_accounts.length) return []
        return ig_accounts
    }catch(ex){
        return []
    }
}

export function getProfileUri(platform, handle) {
    if (platform == 'tiktok') {
        handle = '@' + handle;
    }

    return `https://www.${platform}.com/${handle}`;
}
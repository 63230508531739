<template>
    <div class="main" v-if="Object.keys(company).length !== 0 && fbLogin">

        <div class="header">
            <div class="title">
                Company Settings
            </div>
            <div class="tabs-container">
                <tabs theme="light" :tabs="['General', 'FB Business']" v-model="viewTab" />
            </div> 
        </div>

        <tab :visible="viewTab === 'General'">
            <general-tab />
        </tab>

        <tab :visible="viewTab === 'FB Business'">
            <fb-business-tab />
        </tab>

    </div>
</template>

<script>
import FbBusinessTab from './FbBusinessTab/index.vue'
import GeneralTab from './GeneralTab.vue'
import { mapGetters } from 'vuex';
export default {
    components: {
        FbBusinessTab,
        GeneralTab
    },
    
    data() {
        return {
            viewTab: 'General'
        }
    },

    watch: {
        viewTab(val){
            // we override the route query if the tab is general to remove the facebook business routes
            if(val === 'General') return this.$router.push({ query: { tab: val }}) 
            this.$router.push({ query: { ...this.$route.query, tab: val }})
        }
    },

    mounted(){
        if (this.$route.query.tab) {
            this.viewTab = this.$route.query.tab;
        }
    },
    
    computed: {
        ...mapGetters(['company', 'fbLogin']),
    },
}
</script>
<style lang="scss" scoped>
    .main{
        @apply flex-1 min-w-0 bg-white h-screen overflow-hidden;
        .header{
            @apply flex flex-col py-10 px-12 pb-6 gap-10;
            .title{
                @apply text-h2 font-bold text-purple-m-secondary;
            }
            .tabs-container{
                @apply flex justify-between items-center;
            }
        }
    }
</style>
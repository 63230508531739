<template>
    <base-button @action="returnUri" type="label" label="Back" icon="arrow-left" theme="dark" :icon-size="3"/>
</template>

<script>
export default {
    props: {
        prevRoute: {
            type: Object,
            default: function () {
                return null;
            }
        }
    },
    
    data() {
        return {
        }
    },

    methods: {
        returnUri() {
            this.$router.replace(this.prevRoute.fullPath);
        }
    },

    computed: {
    }
}
</script>
<template>
    <base-modal
        :visible="open || isForce"
        @close="cancel"
        :title="title"
        persistent
        styles="max-height: 95vh; overflow-y: auto;"
        :hide-close-button="isForce"
    >
        
        <p v-if="subtitle">{{ subtitle }}</p>

        <div class="media" v-if="isForce || post.media" :key="`${!open}_media`">

            <!-- tab selector -->
            <p class="tab-option" v-if="method == 'files'">Click below to change the media or <span @click="method = 'links'">use links</span></p>
            <p class="tab-option" v-if="method == 'links'">Type the links to change the media or <span @click="method = 'files'">use files</span></p>
            
            <!-- upload files -->
            <div class="tab" v-if="method == 'files'">
                
                <!-- media  -->
                <div class="field">
                    <!-- loader -->
                    <div class="loader-container" v-if="uploadingMedia">
                        <img src="../../assets/images/spinner.gif"/>
                    </div>
                    <template v-else>
                        <!-- embeded tiktok video -->
                        <div class="video" v-if="useEmbed">
                            <iframe
                                :src="`https://www.tiktok.com/embed/${this.post.post_identifier}`" 
                                scrolling="no"
                                allow="encrypted-media;"
                                frameborder="0" border="0" cellspacing="0"
                                style="width: 325px; height: 575px; transform: scaleX(0.2) scaleY(0.21) translate(-502px, -1075px); margin-bottom: -448px;" 
                            />
                        </div>
                        <!-- show video -->
                        <video 
                            class="video" 
                            :class="{ 'disabled': uploadingAnyFile }"
                            v-else-if="post.is_video && post.media && post.media[0] && post.media[0].mimetype == 'video/mp4'" 
                            @click="$refs.media.click()" autoplay muted
                        >
                            <source :src="post.media[0].url" type="video/mp4" @error="onVideoError"/>
                        </video>
                        <!-- show image -->
                        <img 
                            class="image"
                            :class="{ 'disabled': uploadingAnyFile }"
                            v-else-if="!post.is_video && post.media && post.media[0] && post.media[0].mimetype != 'video/mp4'" 
                            :src="post.media[0].url" 
                            @click="$refs.media.click()"
                        />
                        <!-- show default image -->
                        <img 
                            class="image" 
                            :class="{ 'disabled': uploadingAnyFile }"
                            v-else 
                            :src="DefaultMediaLogo" 
                            @click="$refs.media.click()"
                        />
                    </template>
                    <!-- embedded content label -->
                    <p class="label italic" v-if="useEmbed">Embedded media</p>
                    <!-- label -->
                    <p class="label" v-else>Media <span class="require" v-if="isFieldPending('media')">*</span></p>
                    <!-- input -->
                    <div class="input-container">
                        <input type="file" ref="media" :disabled="uploadingAnyFile" @change="uploadHandlerMedia">
                    </div>
                </div>
                
                <!-- profile picture -->
                <div class="field">
                    <!-- loader -->
                    <div class="loader-container" v-if="uploadingProfilePicture">
                        <img src="../../assets/images/spinner.gif"/>
                    </div>
                    <!-- show image -->
                    <img 
                        class="image"
                        :class="{ 'disabled': uploadingAnyFile }"
                        v-else 
                        :src="post.profile_item.profile_picture_url" 
                        @click="$refs.profile.click()"
                    />
                    <!-- label -->
                    <p class="label">Profile Picture</p>
                    <!-- input -->
                    <div class="input-container">
                        <input type="file" ref="profile" accept="image/*" :disabled="uploadingAnyFile" @change="uploadHandlerProfile">
                    </div>
                </div>

                <!-- display url -->
                <div class="field">
                    <!-- loader -->
                    <div class="loader-container" v-if="uploadingThumbnail">
                        <img src="../../assets/images/spinner.gif"/>
                    </div>
                    <!-- show image -->
                    <img 
                        class="image"
                        :class="{ 'disabled': uploadingAnyFile }"
                        v-else 
                        :src="post.display_url" 
                        @click="$refs.display_url.click()"
                    />
                    <!-- label -->
                    <p class="label">Thumbnail</p>
                    <!-- input -->
                    <div class="input-container">
                        <input type="file" ref="display_url" accept="image/*" :disabled="uploadingAnyFile" @change="uploadHandlerDisplay">
                    </div>
                </div>

            </div>

            <!-- use links -->
            <div class="tab gap-x-4" v-if="method == 'links'">
                
                <!-- media url -->
                <div class="field">
                    <!-- label -->
                    <p class="label italic" v-if="useEmbed">Media URL (embedded)</p>
                    <p class="label" v-else>Media URL</p>
                    <!-- embeded tiktok video -->
                    <div class="video" v-if="useEmbed">
                        <iframe
                            :src="`https://www.tiktok.com/embed/${this.post.post_identifier}`" 
                            scrolling="no"
                            allow="encrypted-media;"
                            frameborder="0" border="0" cellspacing="0"
                            style="width: 325px; height: 575px; transform: scaleX(0.2) scaleY(0.21) translate(-502px, -1075px); margin-bottom: -448px;" 
                        />
                    </div>
                    <!-- show video -->
                    <video 
                        class="video solid"
                        :class="{ 'disabled': uploadingAnyFile }" 
                        v-else-if="post.is_video && post.media && post.media[0] && post.media[0].mimetype == 'video/mp4'" 
                        autoplay muted
                    >
                        <source :src="post.media[0].url" type="video/mp4" @error="onVideoError"/>
                    </video>
                    <!-- show image -->
                    <img 
                        class="image solid"
                        :class="{ 'disabled': uploadingAnyFile }"
                        v-else-if="!post.is_video && post.media && post.media[0] && post.media[0].mimetype != 'video/mp4'" 
                        :src="post.media[0].url"
                    />
                    <!-- show default image -->
                    <img 
                        class="image solid"
                        :class="{ 'disabled': uploadingAnyFile }"
                        v-else 
                        :src="DefaultMediaLogo"
                    />

                    <!-- input -->
                    <FormulateInput 
                        class="w-full" 
                        type="text"
                        placeholder="Type the media url" 
                        v-model="media_url"
                    />
                    <base-button 
                        :label="uploadingMedia ? 'Uploading' : 'Upload'" 
                        :disabled="uploadingAnyFile || !mediaLinkPending"
                        size="md"
                        bold
                        style="padding: 10px 0px"
                        :icon="uploadingMedia ? 'loader' : 'upload2'"
                        @action="uploadMedia"
                    />
                </div>
                
                <!-- profile picture -->
                <div class="field">
                    <!-- label -->
                    <p class="label">Profile Picture URL</p>
                    <!-- show image -->
                    <img 
                        class="image solid"
                        :class="{ 'disabled': uploadingAnyFile }"
                        :src="post.profile_item.profile_picture_url"
                    />
                    <!-- input -->
                    <FormulateInput 
                        class="w-full" 
                        type="text"
                        placeholder="Type the profile picture url" 
                        v-model="profile_picture_url"
                    />
                    <base-button 
                        :label="uploadingProfilePicture ? 'Uploading' : 'Upload'" 
                        :disabled="uploadingAnyFile || !profilePictureLinkPending" 
                        size="md"
                        bold
                        style="padding: 10px 0px"
                        :icon="uploadingProfilePicture ? 'loader' : 'upload2'"
                        @action="uploadProfilePicture"
                    />
                </div>

                <!-- Thumbnail -->
                <div class="field">
                    <!-- label -->
                    <p class="label">Thumbnail</p>
                    <!-- show image -->
                    <img 
                        class="image solid" 
                        :class="{ 'disabled': uploadingAnyFile }"
                        :src="post.display_url"
                    />
                    <!-- input -->
                    <FormulateInput 
                        class="w-full" 
                        type="text"
                        placeholder="Type the thumbnail url" 
                        v-model="display_url"
                    />
                    <base-button 
                        :label="uploadingThumbnail ? 'Uploading' : 'Upload'" 
                        :disabled="uploadingAnyFile || !displayUrlLinkPending" 
                        size="md"
                        bold
                        style="padding: 10px 0px"
                        :icon="uploadingThumbnail ? 'loader' : 'upload2'"
                        @action="uploadThumbnail"
                    />
                </div>
            
            </div>

        </div>

        <!-- form -->
        <div class="form-wrapper" :key="!open">
            <div class="form">
                <div class="field col-span-4">
                    <div class="label">Caption</div>
                    <FormulateInput type="text" validation="required" placeholder="Type the content caption" v-model="post.caption"/>
                </div>
                <div class="field col-span-2">
                    <div class="label">Date <span class="require" v-if="isFieldPending('date')">*</span></div>
                    <date-picker
                        :value="post.date"
                        position="top"
                        wrapperStyles="left: -75px;"
                        @change="(date)=>post.date = date"
                    />
                </div>
                <div class="bottom">
                    <div class="field">
                        <div class="label">Reach (Followers)</div>
                        <FormulateInput type="number" validation="required" placeholder="Type the creator followers count" v-model="post.profile_item.total_followers"/>
                    </div>
                    <div class="field">
                        <div class="label">Likes <span class="require" v-if="isFieldPending('likes')">*</span></div>
                        <FormulateInput type="number" validation="required" placeholder="Type the likes count" v-model="post.likes"/>
                    </div>
                    <div class="field">
                        <div class="label">Comments <span class="require" v-if="isFieldPending('comments')">*</span></div>
                        <FormulateInput type="number" validation="required" placeholder="Type the comments count" v-model="post.comments"/>
                    </div>
                    <div class="field">
                        <div class="label">Views <span class="require" v-if="isFieldPending('views')">*</span></div>
                        <FormulateInput type="number" validation="required" placeholder="Type the views count" v-model="post.views"/>
                    </div>
                    <div class="field">
                        <div class="label">Shares</div>
                        <FormulateInput type="number" validation="required" placeholder="Type the shares count" v-model="post.shares"/>
                    </div>
                    <div class="field">
                        <div class="text-h6 font-bold">Saves</div>
                        <FormulateInput type="number" validation="required" placeholder="Type the saves count" v-model="post.saves"/>
                    </div>
                </div>
            </div>
        </div>

        <!-- footer -->
        <div class="footer">
            <base-button @action="cancel" :disabled="saving" type="secondary" size="md" label="Cancel"/>
            <base-button 
                @action="updatePost" 
                :disabled="disabledManualPost || saving || pendingUploadLink" 
                size="md" 
                :icon-size="4" 
                label="Update" 
                bold
                v-tooltip="disabledManualPost ? { 
                    content: `Please fill out all fields to proceed`, 
                    classes: 'bg-black text-white rounded py-1 px-2 text-xs vue-tooltip-index' 
                } : pendingUploadLink ? {
                    content: `Upload pending files first!`, 
                    classes: 'bg-black text-white rounded py-1 px-2 text-xs vue-tooltip-index' 
                } : {}"
            />
        </div>
    </base-modal>
</template>

<script>
    import { formatDateTimezone, validateNumbers } from '../../lib/strings';
    import { fileUpload, getObjectValue, isArray, isValidDate } from '../../common';
    import DefaultMediaLogo from './../../assets/images/default-user.png';
    export default {
        name: 'UpdatePost',
        props: {
            open: {
                type: Boolean,
                default: false
            },
            isForce: {
                type: Boolean,
                default: false
            },
            admitMedia: {
                type: Boolean,
                default: false
            },
            currentPost: {
                type: Object
            },
            title: {
                type: String,
                default: 'Edit Content Stats'
            },
            subtitle: {
                type: String,
            }
        },
        data() {
            return {
                saving: false,
                uploadingMedia: false,
                uploadingProfilePicture: false,
                uploadingThumbnail: false,
                method: 'files',
                DefaultMediaLogo: DefaultMediaLogo,
                display_url: '',
                media_url: '',
                profile_picture_url: '',
                post: {
                    profile_item: {}
                },
                // this is used to compare initial state to modified state and disable buttons if field values are different
                initialPost: {
                    profile_item: {}
                },
                useEmbed: false
            }
        },
        mounted() {
            if (this.isForce) {
                this.setCurrentPost()
            }
        },
        watch: {
            open: function (val, old) {
                if (val && val !== old) {
                    this.setCurrentPost()
                }
            },
            media_url: function (val, old) {
                if(val !== undefined && val !== ''){
                    let is_video = val.includes('.mp4');
                    if (!is_video) {
                        this.post.is_video = false;
                        this.post.display_url = val;
                    } else {
                        this.post.is_video = true;
                    }
                    this.post.media = [{
                        post_id: this.currentPost.id,
                        mimetype: is_video ? 'video/mp4' : 'image/png',
                        url: val
                    }];
                }
            },
            profile_picture_url: function (val, old) {
                if (val !== undefined && val !== '') {
                    let is_video = val.includes('.mp4'); 
                    if (!is_video) {
                        this.post.profile_item.profile_picture_url = val;
                    } else {
                        this.$notify({ title: 'The profile picture accepts only image formats.', text: 'Try with another link', type: 'warn' });
                    }
                }
            }
        },
        computed: {
            mediaLinkPending(){
                if(!Array.isArray(this.initialPost.media) || !this.initialPost.media.length || !this.initialPost.media[0].url) return false;
                return this.initialPost.media[0].url != this.media_url;
            },
            profilePictureLinkPending(){
                return this.initialPost.profile_item.profile_picture_url != this.profile_picture_url;
            },
            displayUrlLinkPending(){
                return this.initialPost.display_url != this.display_url;
            },
            // will return true if any of the upload links differs from the initial post links 
            pendingUploadLink(){
                return this.mediaLinkPending || this.profilePictureLinkPending || this.displayUrlLinkPending;
            },
            // will return if any file is being uploaded
            uploadingAnyFile(){
                return this.uploadingMedia || this.uploadingProfilePicture || this.uploadingThumbnail;
            },
            disabledManualPost() {
                let isValidNumbers = validateNumbers(this.post, [
                    'likes',
                    'comments',
                    'views',
                    // 'shares',
                    // 'saves'
                ]);

                return !isValidNumbers || this.saving;
            }
        },
        methods: {
            setCurrentPost(){
                this.post = JSON.parse(JSON.stringify(this.currentPost));
                this.initialPost = JSON.parse(JSON.stringify(this.currentPost));
                // media
                const media = getObjectValue(this.post, 'media', null);
                if(isArray(media) && media[0] && media[0].url){
                    this.media_url = media[0].url
                }
                // profile picture
                const found = getObjectValue(this.post, 'profile_item.profile_picture_url', null);
                if(found){
                    this.profile_picture_url = found;
                }
                // thumbnail
                const _found = getObjectValue(this.post, 'display_url', null);
                if(_found){
                    this.display_url = _found;
                }
            },
            cancel() {
                this.method = 'files';
                this.post = { profile_item: {} };
                this.display_url = '';
                this.media_url = '';
                this.profile_picture_url = '';
                this.useEmbed = false;
                this.$emit('close');
            },
            // uploading media by using a link and hitting the upload button
            async uploadMedia() {
                try {
                    this.uploadingMedia = true;
                    let { media_url } = this;
                    let { data } = await this.$http.post(`/api/reports/store-updated-media`, { 
                        media_url
                    });

                    this.$notify({ title: 'Success', text: 'Media uploaded successfully', type: 'success' });

                    if (data.media !== undefined && data.media !== '') {
                        let is_video = data.media.url.includes('.mp4');
                        if (!is_video) {
                            // wait 2500ms for the file to be processed on the bucket
                            // we use a promise so we stop the execution and keep the loading state
                            await new Promise(resolve => setTimeout(() => {
                                this.post.is_video = false;
                                this.post.display_url = data.media.url;
                                // disables upload button
                                this.initialPost.display_url = data.media.url;
                                this.media_url = data.media.url;
                                resolve();
                            }, 2500));

                        } else {
                            this.post.is_video = true;
                        }

                        this.post.media = [{
                            post_id: this.currentPost.id,
                            mimetype: is_video ? 'video/mp4' : 'image/png',
                            url: data.media.url
                        }];
                    }
                } catch (err) {
                    console.log('upload media error', err);
                    this.$notify({ title: 'Something went wrong while uploading media.', text: 'Try again later', type: 'warn' });
                } finally {
                    this.uploadingMedia = false;
                }
            },
            // uploading profile picture by using a link and hitting the upload button
            async uploadProfilePicture() {
                try {
                    this.uploadingProfilePicture = true;
                    let { profile_picture_url } = this;
                    let { data } = await this.$http.post(`/api/reports/store-updated-media`, { 
                        profile_picture_url
                    });

                    this.$notify({ title: 'Success', text: 'Profile picture uploaded successfully', type: 'success' });

                    if (data.profile !== undefined && data.profile !== '') {
                        let is_video = data.profile.url.includes('.mp4'); 
                        if (!is_video) {
                            // wait 2500ms for the file to be processed on the bucket
                            // we use a promise so we stop the execution and keep the loading state
                            await new Promise(resolve => setTimeout(() => {
                                this.post.profile_item.profile_picture_url = data.profile.url;
                                // disables upload button
                                this.initialPost.profile_item.profile_picture_url = data.profile.url;
                                this.profile_picture_url = data.profile.url;
                                resolve();
                            }, 2500));

                        } else {
                            this.$notify({ title: 'The profile picture accepts only image formats.', text: 'Try with another link', type: 'warn' });
                        }
                    }
                    
                } catch (err) {
                    console.log('upload profile picture error', err);
                    this.$notify({ title: 'Something went wrong while uploading the profile picture.', text: 'Try again later', type: 'warn' });
                } finally {
                    this.uploadingProfilePicture = false;
                }
            },
            // uploading thumbnail by using a link and hitting the upload button
            async uploadThumbnail() {
                try {
                    this.uploadingThumbnail = true;
                    let { display_url } = this;
                    let { data } = await this.$http.post(`/api/reports/store-updated-media`, { 
                        display_url
                    });

                    this.$notify({ title: 'Success', text: 'Thumbnail uploaded successfully', type: 'success' });

                    if (data.display !== undefined && data.display !== '') {
                        let _is_video = data.display.url.includes('.mp4'); 
                        if (!_is_video) {
                            // wait 2500ms for the file to be processed on the bucket
                            // we use a promise so we stop the execution and keep the loading state
                            await new Promise(resolve => setTimeout(() => {
                                this.post.display_url = data.display.url;
                                this.initialPost.display_url = data.display.url;
                                this.display_url = data.display.url;
                                resolve();
                            }, 2500));
                        } else {
                            this.$notify({ title: 'The thumbnail only accepts image formats.', text: 'Try with another link', type: 'warn' });
                        }
                    }
                    
                } catch (err) {
                    console.log('upload thumbnail error', err);
                    this.$notify({ title: 'Something went wrong while uploading the thumbnail.', text: 'Try again later', type: 'warn' });
                } finally {
                    this.uploadingThumbnail = false;
                }
            },
            
            // uploading media by clicking the dropzone 
            async uploadHandlerMedia(e) {
                let response = await this.uploadHandler(e, true, 'uploadingMedia');
                if (response && response.file) {
                    if (response.file.mimetype) {
                        if (response.file.mimetype.includes('image')) {
                            this.post.is_video = false;
                            this.post.display_url = response.url;
                        } else {
                            this.post.is_video = true;
                        }

                        this.post.media = [{
                            post_id: this.currentPost.id,
                            mimetype: response.file.mimetype,
                            url: response.url
                        }];
                    }
                }
            },
            // uploading thumbnail by clicking the dropzone
            async uploadHandlerDisplay(e) {
                let response = await this.uploadHandler(e, false, 'uploadingThumbnail');
                if (response && response.file) {
                    if (response.file.mimetype && response.file.mimetype.includes('image')) {
                        this.$notify({ title: 'Success', text: 'Thumbnail submitted successfully', type: 'success' });
                        this.post.display_url = response.url;
                    } else {
                        this.$notify({ title: 'The display accepts only image formats.', text: 'Try with another file', type: 'warn' });
                    }
                }
            },
            // uploading profile picture by clicking the dropzone
            async uploadHandlerProfile(e) {
                let response = await this.uploadHandler(e, false, 'uploadingProfilePicture');
                if (response && response.file) {
                    if (response.file.mimetype && response.file.mimetype.includes('image')) {
                        this.$notify({ title: 'Success', text: 'Profile picture submitted successfully', type: 'success' });
                        this.post.profile_item.profile_picture_url = response.url;
                    } else {
                        this.$notify({ title: 'The profile picture accepts only image formats.', text: 'Try with another file', type: 'warn' });
                    }
                }
            },

            async uploadHandler(e, successMessage = true, loaderVariable = 'uploadingMedia') {
                this[loaderVariable] = true;

                let fileData = new FormData();
                fileData.append('file', e.target.files[0]);

                try {
                    let { data } = await this.$http.post(`/api/files/content/media`, fileData);
                    if (successMessage) {
                        this.$notify({ title: 'Success', text: 'Media submitted successfully', type: 'success' });
                    }
                    return data;
                } catch (err) {
                    console.log('upload media error', err.response);
                    this.$notify({ title: 'Something went wrong while uploading media.', text: 'Try again later', type: 'warn' });
                } finally {
                    this[loaderVariable] = false;
                }

                return null;
            },
            isFieldPending(fieldName){
                if(fieldName == 'media'){
                    return !this.post.media || !Array.isArray(this.post.media) || !this.post.media.length;
                }
                if(fieldName == 'date'){
                    return !isValidDate(this.post.date); 
                }
                if(['likes', 'comments', 'views'].includes(fieldName)){
                    let isValid = validateNumbers(this.post, [fieldName]);
                    return !isValid
                }
                return false
            },
            async updatePost() {
                this.saving = true;
                if(!this.post.shares) this.post.shares = 0; 
                try {
                    const { id, caption, profile_item, is_video, display_url, likes, dislikes, comments, shares, saves, views, url, media, date } = this.post;
                    let post = { id, caption, profile_item, is_video, display_url, likes, dislikes, comments, shares, saves, media, views, url, date };
                    post.date = formatDateTimezone(post.date)
                    if (!id && this.$route.params.id) {
                        post.reportId = this.$route.params.id;
                    }
                    await this.$http.post(`/api/reports/update-post`, post);
                    this.$notify({ title: 'Success', text: `Content updated successfully`, type: 'success' });
                    this.$emit('finish-update', post);
                    this.cancel();
                } catch(e) {
                    this.$notify({ title: 'Error', text: `Error saving! Please try again!`, type: 'warn' });
                    console.log(e);
                } finally {
                    this.saving = false;
                }
            },
            onVideoError(e){
                if(this.post.network_id == 14){
                    this.useEmbed = true;
                }
            }
        }
    }
</script>
<style lang="scss">
    .vue-tooltip-index{
        z-index: 10000;
    }
    span.require{
        @apply text-red-500;
    }
    .media{
        @apply flex flex-col items-center;
        .tab-option{
            @apply text-xs mb-2;
            > span{
                @apply cursor-pointer font-bold;
            }
        }
        > .tab{
            @apply flex justify-between w-full;
            > .field{
                @apply w-1/3 flex flex-col justify-center items-center gap-y-2;
                > .loader-container{
                    @apply w-32 h-32 justify-center items-center flex border-2 border-dashed object-contain;
                    > img{
                        @apply h-auto w-5;
                    }
                }
                .video, .image{
                    @apply w-32 h-32 cursor-pointer border-2 object-contain transition duration-300;
                    &:not(.solid) {
                        @apply border-dashed;
                    }
                    &.solid{
                        @apply border-solid;
                    }
                    &.disabled{
                        filter: grayscale(100%);
                    }
                }
                .label{
                    @apply m-0 text-center text-h6 font-bold;
                }
                .input-container{
                    @apply h-0 overflow-hidden;
                    > input{
                        @apply invisible h-0;
                    }
                }
            }
        }
    }
    .form-wrapper{
        @apply flex flex-col justify-between mt-6;
        .form{
            @apply grid grid-cols-6 gap-4;
            > .field{
                > .label{
                    @apply text-h6 font-bold;
                }
            }
            .bottom{
                @apply col-span-6 grid grid-cols-4 gap-4;
                > .field{
                    > .label{
                        @apply text-h6 font-bold;
                    }
                }
            }
        }
    }
    .footer{
        @apply flex justify-between gap-3 mt-6 rounded-br-2xl;
    }
</style>


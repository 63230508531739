<template>
    <base-modal
        title="Create Folder"
        :visible="visible"
        @close="$emit('close')"
    >
        <FormulateForm name="create-folder-form" @submit="submit()">
            
            <div class="flex flex-col gap-y-2">

                <FormulateInput
                    type="text"
                    v-model="name"
                    placeholder="New Folder"
                    validation="required"
                    label="Folder name"
                />        

            </div>

            <div class="pt-3 gap-x-2 flex justify-end">
                <base-button 
                    type="secondary"
                    class="font-bold"
                    size="md"
                    :iconSize="4"
                    theme="dark"
                    @action="$emit('close')" 
                >
                    Cancel
                </base-button>

                <base-button 
                    theme="dark"
                    @action="$formulate.submit('create-folder-form')" 
                    :disabled="loading || !name"
                    submit
                >
                    Create Folder
                </base-button>

            </div>

        </FormulateForm>
    </base-modal>
</template>

<script>
import { notifyCatchError } from '../common'
export default {
    props: {
        visible: {
            type: Boolean
        },
        folderId: {
            type: Number
        }, 
        attachmentType: {
            type: String,
            default: null
        },
        resource: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            name: '',
            loading: false
        }
    },
    methods: {
        async submit() {
            this.loading = true
            try {
                const { data } = await this.$http.post('/api/folders', {
                    folderable_id: this.resource.id,
                    folderable_type: this.resource.type,
                    parent_id: this.folderId,
                    attachment_type: this.attachmentType,
                    name: this.name
                })
                if(data){
                    this.$notify({ title: 'Success!', text: 'Folder created successfully', type: 'success' })
                    this.$emit('created')
                    this.$emit('close')
                }
            } catch(e) {
                console.log(e);
                notifyCatchError(e, this.$notify);
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>
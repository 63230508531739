<template>
    <div class="container">
        <div class="content">
            <div class="header">
                <div class="flex gap-x-4">
                    <div class="font-bold mb-2">{{data.name}}</div>
                    <base-icon v-if="loading" name="loader" />
                </div>
                <div class="flex items-center gap-x-2">
                    <div>{{selected.length}} selected</div>
                        <base-icon 
                            name="minus-circle" 
                            :size="6" 
                            class="text-gray-500 cursor-pointer hover:opacity-50 transition-opacity duration-200" 
                            @action="selected = []"
                            v-tooltip="{ 
                                content: 'Clear Selection', 
                                classes: 'bg-black text-white rounded py-1 px-2 text-xs', 
                                delay: { 
                                    show: 30,
                                    hide: 50 
                                } 
                            }"
                        />
                        <base-icon 
                            :name="onlySelected ? 'eye-closed' : 'eye'" 
                            :size="6" 
                            class="text-gray-500 cursor-pointer hover:opacity-50 transition-opacity duration-200"
                            @action="(!selected.length && !onlySelected) ? null : onlySelected = !onlySelected"
                            v-tooltip="{ 
                                content: onlySelected ? 'Hide Selected' : `View Selected ${(!selected.length && !onlySelected) ? `, none selected yet` : ``}`,
                                classes: 'bg-black text-white rounded py-1 px-2 text-xs',
                                placement: 'left',
                                delay: { 
                                    show: 30,
                                    hide: 50 
                                } 
                            }"
                        />
                </div>
            </div>

            <filter-select
                placeholder="Search by name, email, phone..."
                :loading="loading"
                :options="computedOptions"
                v-model="selected"
                :onlySelected="onlySelected"
                @toggle-only-selected="(option) => onlySelected = option"
                @search="term => searchTerm = term"
            >
                <div slot-scope="{ option }">
                    <div class="font-bold">{{option.name}}</div>
                    <div v-if="option.phone && option.phone.trim() != ''" class="flex items-center gap-x-2 text-gray-400">
                        <base-icon name="phone" :size="3"/> 
                        <div class="text-pxs">{{option.phone}}</div>
                    </div>
                    <div class="flex items-center gap-x-2 text-gray-400">
                        <base-icon name="mail" :size="3"/> 
                        <div class="text-pxs">{{option.email}}</div>
                    </div>
                    <div class="flex items-center flex-wrap gap-x-2 text-gray-400 mt-1">
                        <div 
                            v-for="(sharedProject, index) in option.sharedProjects" 
                            :key="index"
                            class="rounded-full bg-purple-m-main text-pxs text-white px-4 py-1"
                        >
                            {{sharedProject.project.name}}
                        </div>
                    </div>
                </div>   
            </filter-select>

        </div>
        <div class="footer">
            <base-button
                :label="`${filters.clients ? `Update Filter` : `Add Filter`}`"
                icon="filter"
                size="lg"
                @action="addFilter"
            />

            <base-button
                type="secondary"
                label="Cancel"
                @action="$emit('close')"
            />
        </div>
    </div>

</template>

<script>
import FilterSelect from "../FilterSelect.vue"
export default {
    props: ['data', 'filters'],
    components: { 
        FilterSelect 
    },
    data(){
        return {
            options: [],
            selected: [],
            onlySelected: false,
            searchTerm: '',
            loading: false
        }
    },
    mounted(){
        this.getOptions()
    },
    computed: {
        computedOptions(){
            if(!this.searchTerm || this.searchTerm.trim() == ''){
                return this.options
            }else{
                return this.options.filter(element => {
                    const fields = ['name', 'firstName', 'lastName', 'email', 'phone']
                    let passes = false
                    for (const field of fields) {
                        const value = element[field]
                        if(value && value.toLowerCase().includes(this.searchTerm.toLowerCase())) passes = true
                    }
                    return passes
                })
            }
        }
    },
    methods: {
        async getOptions(){
            this.loading = true
            try {
                const { data } = await this.$http.get(`/api/users`, {
                    params: {
                        clients: true
                    }
                });
                this.options = data.map(client => {
                    return {
                        value: client.id,
                        name: `${client.first_name} ${client.last_name}`,
                        firstName: client.first_name,
                        lastName: client.last_name,
                        email: client.email,
                        phone: client.phone,
                        sharedProjects: client.shared_projects
                    }
                })
                if(this.filters.clients){
                    this.options.push(this.filters.clients)
                    this.selected = this.filters.clients
                }
            } catch(e) {
                console.log(e)
            } finally{
                this.loading = false
            }
        },
        clear(){
            this.selected = []
        },
        addFilter(){
            this.$emit('addFilter', 'clients', this.selected)
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>

    .container{
        @apply relative h-full flex flex-col;
        .content{
            @apply flex-grow flex-shrink;
            .header{
                @apply flex justify-between items-center;
            }
        }
        .footer{
            @apply sticky bottom-0 left-0 right-0 py-5 bg-tan-m flex justify-between items-center;
        }
    }


    .option-list{
        @apply absolute z-20 w-full top-full left-0 right-0 bg-white overflow-y-auto flex flex-col gap-y-2;
        height: 300px;
        .option{
            @apply px-4 py-2 cursor-pointer transition-opacity duration-200;
            &:hover{
                @apply opacity-50;
            }
            &.active{
                @apply bg-purple-m-main text-white font-bold;
            }
        }
    }
</style>
<template>
    <div>
        <slot />
    </div>
</template>

<script>
export default {
    components: {
    },

    data() {
        return {
        }
    }
}
</script>

<template>
    <base-side-modal 
        :visible="visible"
        :hideHeader="true"
        noPadding
        @close="$emit('close')"
    >
        <div class="wrapper">
            
            <div class="header">
                <div class="content">
                    <h3 class="title">
                        Filters
                    </h3>
                    <div class="icon-container">
                        <base-icon 
                            @action="$emit('close')"
                            name="x" 
                            border 
                            background="bg-white" 
                            :size="7" 
                            :icon-size="3"
                        />
                    </div>
                </div>
            </div>

            <!-- filter-selection -->
            <div v-if="!viewingFilter" class="filter-selection">

                <FormulateInput
                    type="text"
                    class="sticky top-0 left-0 right-0 mb-4 z-10"
                    style="top: 68px"
                    placeholder="Search filters..."
                    v-model="searchFiltersTerm"
                />
                
                <div class="filter-selector">
                    <div v-if="!searchedColumns.length" class="px-6 py-6 text-center">No filters found</div>
                    <div
                        v-for="(column, index) in searchedColumns"
                        :key="index" 
                        :class="`filter-option ${isActive(column) ? `active` : `` }`"
                    >
                        <div @click="viewingFilter = column.creator_column" class="filter-text">
                            <div>{{ column.creator_column.name }}</div>
                        </div>
                        <div class="icon-container" @click="removeFromFilters(column)">
                            <base-icon name="close" />
                        </div>
                    </div>
                </div>
                
            </div>

            <!-- filter viewer -->
            <!-- this gets slotted into the appropriate filter component based on slug -->
            <div v-else class="filter-viewer">
                <component 
                    :is="viewingFilter.type || viewingFilter.slug"
                    :data="viewingFilter"
                    :filters="filters"
                    @addFilter="onAddFilter"
                    @close="viewingFilter = null"
                />
            </div>

            <div v-if="!viewingFilter" class="footer">
                <base-button
                    icon="filter"
                    :label="`Apply ${Object.keys(filters).length} filter${Object.keys(filters).length == 1 ? `` : `s`}`"
                    size="lg"
                    @action="onApply"
                />

                <base-button
                    type="secondary"
                    label="Cancel"
                    @action="$emit('close')"
                />
            </div>

        </div>
    </base-side-modal>
</template>

<script>
import { Range, Agents, City, Country, State, Tags, Projects, Clients, CompletedDeliverables, Reports } from './Filters/index.js';
export default {
    props: ['visible', 'columns', 'networks'],
    components: {
        Range, Agents, City, Country, State, Tags, Projects, Clients, CompletedDeliverables, Reports
    },
    data() {
        return {
            searchFiltersTerm: '',

            viewingFilter: null,

            // applied filters
            filters: {

            },
            excluded: [
                'email',
                'links',
                'phone',
                'agent-name',
                'agent-email',
                'agent-phone',
                'country',
                'city',
                'state'
            ],
            standardFilters: {
                creator_column: {
                    id: 1,
                    name: 'Projects',
                    slug: 'projects',
                    es_name: 'user_projects'
                    
                }
            },
            // {
            //     creator_column: {
            //         id: 2,
            //         name: 'Agents',
            //         slug: 'agents'
            //     }
            // },
            // {
            //     creator_column: {
            //         id: 3,
            //         name: 'Clients',
            //         slug: 'clients'
            //     }
            // },
            // {
            //     creator_column: {
            //         id: 4,
            //         name: 'Completed Deliverables',
            //         slug: 'completed-deliverables'
            //     }
            // },
            // {
            //     creator_column: {
            //         id: 5,
            //         name: 'Reports',
            //         slug: 'reports'
            //     }
            // }
        }
    },
    computed: {
        // exclude and sort filters from the list
        filteredColumns(){
            return this.columns.concat(this.standardFilters).filter(element => {
                return !this.excluded.includes(element.creator_column.slug) 
            }).sort((a, b) => {
                const columnOrder = ['country', 'state', 'city', 'tags', 'instagram-followers', 'tiktok-followers', 'projects', 'agents', 'clients', 'completed-deliverables', 'reports']

                const aColorIndex = columnOrder.indexOf( a.creator_column.slug );
                const bColorIndex = columnOrder.indexOf( b.creator_column.slug );

                if ( aColorIndex === bColorIndex )
                    return a.creator_column.id - b.creator_column.id;

                return aColorIndex - bColorIndex;
            })
        },
        searchedColumns(){
            const term = this.searchFiltersTerm;
            
            if(!term || term.trim() == ''){
                return this.filteredColumns
            }

            return this.filteredColumns.filter(column => column.creator_column.name.toLowerCase().includes(term.toLowerCase()))
        }
    },
    methods: {
        onApply(){
            this.$emit('apply', this.prepareFilters());
            this.$emit('close')
        },
        onAddFilter(type, value, data){
            if(type == 'projects' && (!value.length)){
                delete this.filters[type];
            }else{
                if(!value || (Array.isArray(value) && value.length <= 0)){
                    delete this.filters[type];
                }else{
                    this.filters[type] = {value, data};
                }
            }
        },
        prepareFilters(){
            // change the filters object to and array and modify the values so that they make sense for elastic search
            let filters = this.filters;
            let arr = [];
            for (const key in filters) {
                if (Object.hasOwnProperty.call(filters, key)) {
                    
                    let objectValue = ( typeof filters[key].value !== 'undefined' ) ? filters[key].value : filters[key];
                    let objectData = filters[key].data;

                    console.log('objectValue', objectValue);
                    console.log('objectData.es_map', objectData);
                    const _paths = (objectData && typeof objectData.es_map !== 'undefined') ? objectData.es_map.split('.') : '';
                    let nested_path = ( _paths !== '' ) ? _paths[1] : '';
                    let target_property = ( _paths !== '' ) ? _paths[0].replace('*', '') : '';

                    let filter_type = ( objectData && typeof objectData.type !== 'undefined' ) ? objectData.type : '';
                    let field = key;
                    let value = objectValue;
                    let where_clause = {};

                    const keyParts = key.split('-');
                    const networkName = keyParts[0];
                    const hasFollowersSuffix = keyParts[1] === 'followers';

                    if (key == 'country' || key == 'state' || key == 'city') {
                        value = objectValue;
                    }
                    if (key == 'tags') {
                        value = objectValue;
                        target_property = "tags";
                    }
                    
                    if ( filter_type == "range" ) {
                        
                        value = objectValue;
                        if (hasFollowersSuffix) {
                            const network = this.networks.find(network => network.name === networkName);
                            where_clause = { "network_id": network ? network.id : null };
        
                        } 
                    }

                    if (key == 'projects') {
                        value = objectValue.map(project => project.value);
                        nested_path = "user_projects";
                        target_property = "project_id";
                    }
                    if (key == 'agents') {
                        value = objectValue.map(agent => agent.value.slug)
                    }
                    if (key == 'clients') {
                        let arr = [];
                        for (const client of objectValue) {
                            arr = arr.concat(client.sharedProjects.map(element => element.project_id));
                        }

                        value = arr;

                    }
                    
                    if (key == 'completed-deliverables') {
                        value = objectValue;
                    }

                    if (key == 'reports') {
                        value = objectValue.map(report => report.value.slug);
                    }

                    arr.push({ field, value, nested_path, target_property, where_clause, filter_type });
                }

            }
            if(!arr.length) return null;
            return arr;
        },

        isActive(column){
            return this.filters[column.creator_column.slug];
        },
        removeFromFilters(column){ 
            delete this.filters[column.creator_column.slug];
            this.$forceUpdate();
        }
    }
}
</script>
<style lang="scss" scoped>
    .wrapper{
        @apply w-full h-full flex flex-col px-14 flex-grow flex-shrink;
    }
    .header{
        @apply pt-5 sticky bg-tan-m z-50 left-0 right-0 top-0 w-full;
        .content{
            @apply w-full py-5 flex justify-between items-center;
            .title{
                @apply text-2xl leading-6 font-bold text-black flex items-center;
            }
            .icon-container{
                @apply flex justify-end items-center gap-x-6 cursor-pointer;
            }
        }
    }
    .filter-selection{
        @apply flex-shrink flex-grow relative;
        .filter-selector{
            @apply flex flex-col gap-y-2 mb-4;
           .filter-option{
                @apply rounded-lg cursor-pointer flex justify-between items-center transition duration-200;
                .filter-text{
                    @apply pl-2 pt-2 pb-2 flex items-center flex-grow flex-shrink;
                }
                .icon-container{
                    @apply pr-2 pt-2 pb-2 invisible flex items-center;
                }
                &:hover{
                    @apply shadow-lg;
                    &.active{
                        .icon-container{
                            @apply visible;
                        }
                    }
                }
                &.active{
                    @apply font-bold text-white bg-purple-m-main;
                }
           } 
        }
    }
    .filter-viewer{
        @apply flex-shrink flex-grow relative;
    }

    .footer{
        @apply sticky bg-tan-m left-0 bottom-0 top-0 w-full py-5 flex justify-between items-center;
    }
</style>
<template>
    <div 
        class="item" 
        :class="{ 'active': isActive }"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
        @click="$router.push('/notifications')"
    >
        
        <div class="notification-bell">
            <base-icon name="bell" :class="hovered || isActive ? 'text-green-m-main' : 'text-white'" />
            <div class="count-wrapper" v-if="pendingNotificationsCount > 0">
                <div class="count-container">
                    <div class="count-text">{{pendingNotificationsCount}}</div>
                </div>
            </div>
        </div>

        <span class="text" v-if="!collapsed">Notifications</span>

    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        isActive: {
            type: Boolean,
            default: false
        },
        collapsed: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            hovered: false
        }
    },
    mounted(){
        this.$store.dispatch('notifications/getPendingNotificationsCount')
    },
    computed: {
        ...mapGetters(['pendingNotificationsCount', 'pendingNotificationsCountLoading'])
    }
}
</script>
<style lang="scss" scoped>
    .item{
        @apply w-full h-10 pl-10 cursor-pointer;
        @apply border-l-4 border-transparent;
        @apply flex justify-start items-center;
        &.active{
            @apply border-green-m-main;
        }
        > .text{
            @apply rounded-full ml-3 w-36 w-auto;
            @apply border border-transparent;
            @apply flex justify-center items-center gap-3;
            @apply font-bold text-h6 text-white;
            @apply transition duration-150 ease-in-out;
            &:hover{
                @apply text-gray-m-light
            }
            &:focus{
                @apply text-gray-m-light;
                @apply outline-none;
            }
        }
    }

    .notification-bell{
        @apply relative flex border-l border-transparent;
    }
    .count-wrapper {
        @apply absolute;
        top: -4px;
        right: -7px;
        .count-container {
            @apply flex justify-center items-center bg-green-m-main rounded-full h-4 w-4;
            .count-text {
                @apply font-bold;
                font-size: 11px; 
            }
        }
    }
</style>

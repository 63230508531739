
<template>

    <div class="wrapper" v-if="computedData">
        
        <!-- <div class="title">Genders</div> -->

        <div class="container">
            <pie
                :chart-data="computedData" 
                :options="options" 
                :styles="{ height: '100%', width: '100%' }"
            />
        </div>

        <div class="legend">
            <div class="item">
                <div class="marker" :class="getSquareColor(computedData.labels[0])"/>
                <div class="label">{{computedData.labels[0]}} {{ computedData.datasets[0].data[0] / 100 | numeral('0.0%') }}</div>
            </div>

            <div class="item">
                <div class="marker" :class="getSquareColor(computedData.labels[1])"/>
                <div class="label">{{ computedData.labels[1]}} {{ computedData.datasets[0].data[1] / 100 | numeral('0.0%') }}</div>
            </div>

            <!-- <div class="item" v-if="computedData.labels[2] != undefined">
                <div class="marker" :class="getSquareColor(computedData.labels[2])"/>
                <div class="label">{{ computedData.labels[2]}} {{ computedData.datasets[0].data[2] / 100 | numeral('0.0%') }}</div>
            </div> -->
        </div>
    </div>

</template>
<script>
import Pie from './Pie.vue';

const colors = {
    MALE: [ '#0E092C', '#0E092C' ],
    FEMALE: [ '#70F880', '#70F880' ],
    // UNKNOWN: [ '#D8D8D8', '#D8D8D8' ],
}
export default {
    components: {
        Pie
    },
    props: ['data', 'printView'],
    data(){
        return {
            computedData: null,
            colors,
            options: { 
                animation: { duration: this.printView ? 0 : 1000 }, 
                legend: { display: false },
                tooltips: {
                    enabled: true,
                    callbacks: {
                        label: ((tooltipItem, data) => {
                            let percent = data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']] + '%'
                            let label = data['labels'][tooltipItem.index]
                            let space = ' '
                            return (label && percent) ? space + label + ' ' + percent : null
                        })
                    }
                }
            }
        }
    },
    mounted () {

        let output = {
            labels: ['Female', 'Male'],
            datasets: [{
                data: [],
                backgroundColor: [
                    colors.FEMALE[0],
                    colors.MALE[0]
                ],
                hoverBackgroundColor: [
                    colors.FEMALE[1],
                    colors.MALE[1]
                ]
            }],
        };

        // copy data so we can change its values
        let data = JSON.parse(JSON.stringify(this.data))
        
        // we are no longer user unknown data, so we have to re calculate the 100% with the values that we have
        const hasUnknown = data.find(element => element.code == "UNKNOWN")
        if(hasUnknown){
            data = data.filter(element => ["MALE", "FEMALE"].includes(element.code));
            const totalWeight = data.map(e => e.weight).reduce((acc, cur) => acc + cur, 0);
            data = data.map(element => {
                const percentage = (element.weight * 100) / totalWeight;
                const value = percentage / 100;
                element.weight = value
                return element;
            })
        }

        if (data && Array.isArray(data) && data.length) {

            // If data does not have unknown, compute value and push that to the array
            // let sum = this.data.map(g => g.weight).reduce((acc, cur) => acc + cur, 0);
            // let value = 1 - sum;
            // genders.push({ code: 'UNKNOWN', weight: value });
        
            // let item = this.data.find(g => g.code == 'UNKNOWN');
            // let hasUnknown = item && item.weight > 0;

            output.datasets[0].data = [
                parseFloat((data[0].weight * 100).toFixed(1)),
                parseFloat((data[1].weight * 100).toFixed(1))
            ];

            // if (hasUnknown && this.data[2] !== undefined) {
            //     let itemToPush = parseFloat((this.data[2].weight * 100).toFixed(1));
            //     output.datasets[0].data.push(itemToPush);
            // }

            if (data[0].code === 'MALE') {
                output.datasets[0].backgroundColor = output.datasets[0].backgroundColor.reverse();
                output.datasets[0].hoverBackgroundColor = output.datasets[0].hoverBackgroundColor.reverse();
                output.labels = output.labels.reverse();
            }

            // if (hasUnknown) {
            //     output.labels.push('Unknown');
            //     output.datasets[0].backgroundColor.push(colors.UNKNOWN[0]);
            //     output.datasets[0].hoverBackgroundColor.push(colors.UNKNOWN[1]);
            // }
        }

        this.computedData = output;

    },
    methods: {
        getSquareColor(label) {
            let string = label.toLowerCase();
            return string === 'male' ? 'option-2' : string === 'female' ? 'option-1' : 'option-3';
        }
    }
};
</script>
<style lang="scss" scoped>
    .wrapper{
        
        @apply flex flex-col items-center;

        .container{
            width: 200px;
            height: 200px;
        }

        .legend{
            @apply mt-6 flex justify-center items-center gap-x-4;

            .item{
            
                @apply flex gap-x-2 items-center;
                
                .marker {
                    @apply w-3 h-3;
                    display: inline-block;
                    &.option-1{
                        background-color: #70F880;
                    }
                    &.option-2{
                        background-color: #0E092C;
                    }
                    // &.option-3{
                    //     background-color: #D8D8D8;
                    // }
                }

                .label {
                    @apply font-semibold;
                    text-align: center;
                    font-size: 16px;
                }

            }
        }
    }
</style>

<template>
    <main class="wrapper">
        <Header />
        <div class="container">
            <sidebar />
            <!-- https://highlightjs.org/usage/ -->
            <div class="content">
                <component :is="component" @scrollTo="scrollTo" />
            </div>
        </div>
    </main>
</template>

<script>
import Header from './Header.vue'
import Sidebar from './Sidebar.vue'
export default {
    components: {
        Header,
        Sidebar
    },
    computed: {
        component(){
            const componentName = this.$route.params.component || 'Introduction'
            return () => {
                const comp = import(`./components/${componentName}.vue`).then(res => {
                    return res
                }).catch(() => {
                    return import(`./components/NotFound.vue`)
                })
                return comp
            }
        }
    },
    methods: {
        scrollTo(element, duration = 200){
            this.$router.push({hash: element})
            var VueScrollTo = require('vue-scrollto')
            var options = {
                element: element,
                offset: 0
            }
            VueScrollTo.scrollTo(element, duration, options)
        }
    },
    data() {
        return {
            loading: false
        }
    }
}
</script>
<style lang="scss" scoped>
    .wrapper{
        @apply bg-purple-m-main flex flex-col;
        .container{
            @apply h-full flex flex-shrink flex-grow;
            .content{
                @apply p-12 h-full w-full flex-grow flex-shrink text-white;
            }
        }
    }
</style>
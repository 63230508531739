<template>
    <div class="additional-info">
        <div class="form">
            <div class="field">
                <div class="label">What kind of content do you like to make ?</div>
                <FormulateInput
                    type="textarea"
                    placeholder="Tell us more about yourself..."
                    :value="bio"
                    @input="value => $emit('update:bio', value)"
                    input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-20"
                    :rows="5"
                />
            </div>
            <div class="field">  
                <div class="label">Birthdate</div>
                <div class="calendar-container">
                    <base-icon name="calendar" :size="5"/>
                </div>
                <date-picker
                    :value="birthdate"
                    inputWrapperClass="border rounded-md py-4 pr-3 pl-16"
                    @change="date => $emit('update:birthdate', date)"
                />
            </div>
        </div>
        <div class="actions">
            <base-button label="Next" size="xl" class="font-bold" @action="$emit('next')" />
            <base-button label="Back" type="label" @action="$emit('back')" />
        </div>
    </div>
</template>
<script>
export default {
    props: ['bio', 'birthdate']
}
</script>
<style lang="scss" scoped>
    .additional-info{
        @apply h-full flex flex-col justify-start items-center;
        .form{
            @apply flex flex-col gap-y-10;
            width: 500px;
            .field{
                @apply relative gap-y-1;
                .label{
                    @apply font-bold;
                }
                .calendar-container{
                    @apply absolute left-5 bottom-3.5;
                }
            }
        }
        .actions{
            @apply mt-10 flex flex-col items-center gap-y-6;
        }
    }
</style>

<template>
    <base-modal
        :visible="visible"
        title="How do you want to create this report ?"
        @close="$emit('close')"
        headerStyles="padding-left: 2.5rem;padding-right: 2.5rem;"
        noPadding
        containerClass="pt-5 pb-10 px-10 flex-grow flex-shrink"
    >   
        <div class="option-list">
            <div 
                class="option-item"
                v-for="(option, index) in options" 
                :key="index"
                @click="() => option.action()"
            >
                <base-icon :name="option.icon" />
                <div class="right">
                    <div class="title">{{option.title}}</div>
                    <div class="description">{{option.description}}</div>
                </div>
            </div>
        </div>
    </base-modal>
</template>

<script>
export default {
    props: ['visible'],
    data(){
        return {
            options: [
                {
                    title: 'Import CSV',
                    description: 'Create report with a spreadsheet of all the creators in the report.',
                    icon: 'import',
                    action: () => {
                        this.$emit('redirect-to-report-import');
                        this.$emit('close');
                    }
                },
                {
                    title: 'Create manually',
                    description: 'Manually select creators for the report',
                    icon: 'plus',
                    action: () => {
                        this.$emit('open-create-report-modal');
                        this.$emit('close');
                    }
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
    .option-list{
        @apply flex flex-col gap-y-4;
        .option-item{
            @apply border border-gray-500 rounded-lg p-4;
            @apply flex items-center gap-x-4;
            @apply cursor-pointer transition-opacity duration-200;
            &:hover{
                @apply opacity-60;
            }
            .right{
                @apply flex flex-col;
                .title{
                    @apply font-bold;
                }
            }
        }
    }
</style>

<template>
    <main class="project-settings flex-1" tabindex="0">

        <!-- header -->
        <div class="flex items-center gap-x-4 px-12 py-10">
            <div class="inline-flex items-center justify-center rounded-full bg-purple-m-main w-15 h-15">
                <base-icon class="text-green-m-main" name="layers" :size="6" />
            </div>

            <div class="text-h2 font-bold text-purple-m-secondary">{{isEditView ? 'Edit Project' : isCreateView ? 'New Project' : ''}}</div>
        </div>

        <content-skeleton :isEditView="isEditView" v-if="loading"/>
        
        <div v-else class="content-pane">

            <FormulateForm class="px-12 pb-10 pt-6" name="project-create-edit-form" @submit="()=>null">
                <div class="grid grid-cols-2 gap-6">
                    <div class="field">
                        <div class="text-h6 font-bold">Project Title <span class="text-red-500">*</span></div>
                        <FormulateInput
                            type="text"
                            placeholder="An Awesome Project"
                            validation="required|matches:/^[-_ a-zA-Z0-9]+$/"
                            validation-name="Project Title"
                            :validation-messages="{
                                matches: 'Project Title must be alphanumeric'
                            }"
                            v-model="project.name"
                        />
                    </div>

                    <div class="field">
                        <div class="text-h6 font-bold">Owner <span class="text-red-500">*</span></div>
                        <custom-select
                            class="w-full"
                            placeholder="Select the owner(s)"
                            :options="users"
                            :multiple="true"
                            v-model="owners"
                            size="auto"
                            min-base
                            @input="onOwnersInput"
                        />
                        <div 
                            v-if="errors.owners" 
                            style="margin-top: -0.75rem" 
                            class="text-red-500 text-xs mb-1">{{errors.owners}}</div>
                    </div>
                    
                    <div class="field col-span-2">
                        <div class="field relative">
                            <div class="text-h6 font-bold">Brief</div>
                            <custom-vue-editor
                                :key="key"
                                :briefAttachment="project.brief_attachment"
                                v-model="project.brief"
                                @upload="briefAttachmentUploadHandler"
                                @remove-brief-attachment="onRemoveBriefAttachment"
                            />
                        </div>
                    </div>

                    <div class="field">
                        <div class="text-h6 font-bold">Brand <span class="text-red-500">*</span></div>
                        <FormulateInput
                            type="text"
                            placeholder="Brand Name"
                            validation="required"
                            validation-name="Brand"
                            v-model="project.brand"
                        />
                    </div>

                    <div v-if="isAdminOrHigher" class="field relative">
                        <div class="text-h6 font-bold">Budget <span class="text-red-500">*</span></div>

                        <FormulateInput
                            type="text"
                            wrapper-class="border rounded-md py-4 pr-3 pl-16 h-16"
                            placeholder="25000"
                            validation="required"
                            validation-name="Budget"
                            @input="onBudgetInput"
                            v-model="project.budget"
                        />

                        <div class="field-icon-container">
                            <base-icon name="dollar-sign" :size="4"/>
                        </div>
                    </div>

                    <div class="field relative">
                        <div class="text-h6 font-bold">Starts At <span class="text-red-500">*</span></div>
                        <date-picker
                            validation="required"
                            validationName="Starts At"
                            :value="project.starts_at"
                            position="bottom"
                            inputWrapperClass="border rounded-md py-4 pr-3 pl-16 h-16"
                            @change="(date) => onStartsAtChange(date)"
                        />
                        <div class="field-icon-container">
                            <base-icon name="calendar" :size="4"/>
                        </div>
                        <div 
                            v-if="errors.starts_at" 
                            class="text-red-500 text-xs mb-1">{{errors.starts_at}}
                        </div>
                    </div>

                    <div class="field relative">
                        <div class="text-h6 font-bold">Ends At <span class="text-red-500">*</span></div>
                        <date-picker
                            validation="required"
                            validationName="Ends At"
                            :value="project.ends_at"
                            position="bottom"
                            inputWrapperClass="border rounded-md py-4 pr-3 pl-16 h-16"
                            @change="(date) => onEndsAtChange(date)"
                        />
                        <div class="field-icon-container">
                            <base-icon name="calendar" :size="4"/>
                        </div>
                        <div
                            v-if="errors.ends_at"
                           
                            class="text-red-500 text-xs mb-1">{{errors.ends_at}}
                        </div>
                    </div>

                    <div v-if="project.id" class="field">
                        <div>
                            <div class="text-h6 font-bold">Upload Files</div>
                            <div class="text-ps text-gray-m-disable font-normal mt-1">Upload the project brief or other important files and documents</div>
                        </div>
                        <file-list
                            :project-id="project.id"
                        />
                    </div>
                    <div class="opacity-50 pointer-events-none" v-else>
                        <div class="text-h6 font-bold mb-2">Upload Files</div>
                        Create the project first to be able to upload files and links
                        <file-list preventDataLoad />
                    </div>
                </div>
            </FormulateForm>

            <template v-if="project.id">

                <div class="flex justify-between px-12 py-10">
                    <div class="text-h2 font-bold text-purple-m-secondary">
                        Creators Assigned
                        <span class="text-gray-m-disable">({{ computedProjectUsers.length }})</span>
                    </div>
                    <div class="flex gap-x-6">
                        <base-button @action="createInfluencerModalVisible = true" type="secondary" class="font-bold" theme="dark" size="lg" label="New Creator" icon="plus"/>
                        <base-button @action="assignCreatorModalVisible = true" class="font-bold" size="lg" label="Assign Creator"/>
                    </div>
                </div>

                <base-table class="px-12 pb-10 mb-24"
                    :columns="[
                        {
                            name: 'creator-name',
                            label: 'Creator Name',
                            type: 'avatar-list',                                             
                            value: (element) => {
                                return [element]
                            },
                            searchBy: (element) => {
                                return `${element.first_name} ${element.last_name}`
                            }
                        },
                        {
                            name: 'creator-name-searchable',
                            label: 'Creator Name Searchable',
                            type: 'text',                                             
                            value: (element) => {
                                return `${element.first_name} ${element.last_name}`
                            },
                            hidden: true
                        },
                        {
                            name: 'user_tags',
                            label: 'Tags',
                            type: 'user-tags',
                            searchBy: customUserTagsSearchBy,
                            filter: {
                                options: tags.map(tag => {return { label: tag.name, value: tag.slug }}),
                                placeholder: 'Tags',
                                type: 'select',
                                allSelectedPlaceholder: 'All Tags'
                            },
                        },
                        {
                            name: 'location',
                            label: 'Location',                            
                            value: locationValue
                        },
                        {
                            name: 'instagram-followers',
                            label: 'Instagram Followers',
                            type: 'number',
                            value: instagramFollowersValue,                
                        },
                        {
                            name: 'tiktok-followers',
                            label: 'TikTok Followers',
                            type: 'number',
                            value: tiktokFollowersValue,
                        },
                    ]"
                    :data="creators.filter(creator => computedProjectUsers.map(user => user.id).includes(creator.id))"                    
                    :loading="false"
                    searchbarStyles="width: 400px;"                 
                />

            </template>
            <div class="opacity-50 pointer-events-none" v-else>
                <div class="flex justify-between px-12 py-10">
                    <div class="text-h2 font-bold text-purple-m-secondary">
                        Creators Assigned
                    </div>
                    <div class="flex gap-x-6">
                        <base-button @action="()=>null" type="secondary" class="font-bold" theme="dark" size="lg" label="New Creator" icon="plus"/>
                        <base-button @action="()=>null" class="font-bold" size="lg" label="Assign Creator"/>
                    </div>
                </div>
                <div class="w-full flex justify-center pt-4 pb-20">
                    <div class="flex flex-col items-center gap-y-2 w-56">
                        <base-icon name="user-group" :size="10" />
                        <div class="text-center">Create the project first to be able to assign creators</div>
                    </div>
                </div>
            </div>
            <div class="sticky border-gray-300 border-t bottom-0 right-0 flex justify-end gap-x-4 px-12 py-10 bg-white w-full z-10">
                <base-button
                    @action="$router.back()" 
                    :disabled="false"
                    class="bg-tan-m"
                    type="secondary"
                    label="Discard"
                />

                <base-button 
                    v-if="isEditView"
                    @action="updateProject" 
                    :disabled="false" 
                    submit 
                    label="Save"
                />

                <base-button
                    v-if="isCreateView" 
                    @action="createProject" 
                    :disabled="false" 
                    submit 
                    label="Create Project"
                />
            </div>
        </div>

        <create-influencer-modal
            standalone
            :visible="createInfluencerModalVisible"
            @close="createInfluencerModalVisible = false"
            @created-influencer="onCreatedInfluencer"
        />

        <!-- show modal once project creators are loaded up -->
        <template v-if="project && computedProjectUsers && creators">
            <assign-creator-modal
                hide-create
                :visible="assignCreatorModalVisible"
                :creators="reducedCreators"
                :initialSelectedElements="[]"
                :projectSlug="$route.params.projectId"
                :tags="tags"
                title="Assign Creator"
                @close="assignCreatorModalVisible = false"
                @confirm-selected-creators="onConfirmSelectedCreators"
                @open-create-influencer-modal="assignCreatorModalVisible = false; createInfluencerModalVisible = true;"
            />
        </template>

        <assign-created-creator-modal
            :visible="assignCreatedInfluencerModalVisible"
            :creator="createdInfluencer"
            :projectId="project.id"
            @close="assignCreatedInfluencerModalVisible = false"
            @done="onAssignCreatedInfluencerDone"
        />

    </main>
</template>

<script>
import FileList from '../../components/FileList'
import ContentSkeleton from './ContentSkeleton.vue'
import { instagramFollowers, tiktokFollowers, location } from '../../lib/user'
import AssignCreatorModal from '../../components/AssignCreatorModal.vue'
import CreateInfluencerModal from '../../components/CreateInfluencerModal.vue'
import Avatar from '../../components/Avatar.vue'
import AssignCreatedCreatorModal from '../../components/AssignCreatedCreatorModal.vue'
import BaseIcon from '../../components/BaseIcon.vue'
import moment from 'moment'
import { formatBudget, budgetToDouble } from '../../lib/strings'
import { briefAttachmentUpload } from '../../common'
import CustomVueEditor from './CustomVueEditor.vue'
import { mapGetters } from 'vuex';

export default {
    components: {
        FileList,
        AssignCreatorModal,
        CreateInfluencerModal,
        Avatar,
        AssignCreatedCreatorModal,
        BaseIcon,
        ContentSkeleton,
        CustomVueEditor
    },
    data() {
        return {
            
            project: {},
            projectUsers: [],

            files: [],
            users: [],
            owners: [],
            creators: [],
            tags: [],

            errors: {},
      
            loading: false,
            autoUpdateEnabled: false,

            createdInfluencer: {},

            createInfluencerModalVisible: false,
            assignCreatorModalVisible: false,
            assignCreatedInfluencerModalVisible: false,

            key: false
        }
    },

    computed: {
        ...mapGetters(['isAdminOrHigher']),
        uploadedFiles() {
            if (!this.files.length === 0) {
                return '';
            }

            return this.files.map(a => { return { url: a.name }});
        },
        computedProjectUsers() {
            return this.projectUsers.filter(pu => pu);
        },
        isEditView() {
            return this.$route.params.projectId && this.$route.name.includes('edit-project')
        },
        isCreateView(){
            return !this.$route.params.projectId && this.$route.name.includes('create-project')
        },
        reducedCreators() {
            let ids = [];
            if (this.project.project_users && this.project.project_users.length > 0) {
                ids = this.project.project_users.map(row => row.user_id);
            }
            return this.creators.filter(item => {
                return !ids.includes(item.id);
            });
        },
    },

    mounted() {
        if(this.isEditView) this.getProject();
        this.getUsers();
        this.getCreators();
        this.getTags();
    },

    methods: {
        async getUsers() {
            const { data } = await this.$http.get('/api/users');
            this.users = data.map(user => {
                return {
                    value: user.id,
                    label: user.first_name + ' ' + user.last_name
                }
            });
        },
        async getCreators() {
            const { data } = await this.$http.get('/api/users?creators=true');
            this.creators = data
        },
        async getProject(loading = true) {
            this.loading = loading
            const { data } = await this.$http.get(`/api/projects/${this.$route.params.projectId}`);
            this.project = data
            if(this.project.budget) this.project.budget = formatBudget(this.project.budget)
            this.owners = this.project.project_owners.map(project_owner => {
                return {
                    value: project_owner.user.id, 
                    label: project_owner.user.first_name + ' ' + project_owner.user.last_name 
                } 
            })
            this.projectUsers = this.project.project_users.map(project_user => project_user.user)
            this.autoUpdateEnabled = true
            this.loading = false
            this.getFiles();
        },

        async getTags(){
            const { data } = await this.$http.get(`/api/tags`)
            this.tags = data
        },

        async getFiles() {
            const { data } = await this.$http.get(`/api/files?projectId=${this.project.id}`);
            this.files = data;
        },

        async updateProject() {
            // run formulate validation
            this.$formulate.submit('project-create-edit-form')
            // run our own validation
            const valid = await this.validate();
            if(!valid) return;
            this.loading = true;
            this.$http.post(`/api/projects/${this.project.id}/update`, {
                name: this.project.name,
                brief: this.project.brief,
                brief_attachment_id: this.project.brief_attachment_id,
                brand: this.project.brand,
                starts_at: this.project.starts_at,
                ends_at: this.project.ends_at,
                ...(this.isAdminOrHigher ? { budget: budgetToDouble(this.project.budget) } : {}),
                owners: this.owners.map(owner => owner.value),
                users: this.projectUsers.map(pu => {
                    return {id: pu.id}
                })
            }).then(res => {
                if (res.status === 200) {
                    this.$notify({ title: 'Success', text: 'Project updated successfully', type: 'success' });
                    this.$router.push(`/projects/${this.project.slug}`);
                } else {
                    this.$notify({ title: 'Error', text: 'Error trying to update the project', type: 'error' })
                }
            }).catch((err) => {
                this.$notify({ title: 'Error', text: 'Error trying to update the project', type: 'error' })
            }).finally(() => {
                 this.loading = false;
            });
        },

        async createProject() {
            // run formulate validation
            this.$formulate.submit('project-create-edit-form')
            // run our own validation
            const valid = await this.validate();
            if(!valid) return;
            this.loading = true
            try{
                const { data } = await this.$http.post('/api/projects/create', { 
                    name: this.project.name,
                    brief: this.project.brief,
                    brand: this.project.brand,
                    starts_at: this.project.starts_at,
                    ends_at: this.project.ends_at,
                    ...(this.isAdminOrHigher ? { budget: budgetToDouble(this.project.budget) } : {}),
                    owners: this.owners.map(owner => owner.value),
                });
                this.$notify({ title: 'Success', text: 'Project created successfully', type: 'success' })
                this.$notify({ title: 'Finish setting up the project', text: '', type: 'warn' })
                this.$router.push(`/projects/${data.slug}/edit`)
                this.getProject()
            }catch(error){
                console.log(error)
            }finally{
                this.loading = false
            }
        },

        async validate(){
            let msg = null
            if (!this.project.name) msg = 'Project Title required'
            if (!this.project.name?.match(/^[-_ a-zA-Z0-9]+$/)) msg = 'Project Title must be alphanumeric'
            if (this.isAdminOrHigher && !this.project.budget) msg = 'Budget required'
            if (this.owners.length <= 0) {
                msg = 'Owners required'
                this.errors.owners = 'Owners required'
            }
            if (moment(this.project.starts_at) >= moment(this.project.ends_at)){
                msg = 'End date cannot be before start date'
                this.errors.starts_at = 'End date cannot be before start date'
                this.errors.ends_at = 'End date cannot be before start date'
            } 
            this.$forceUpdate();
            if(msg){
                this.$notify({ title: 'Warning', text: msg, type: 'warn' })
                return false;
            }
            return true;
        },

        async briefAttachmentUploadHandler(file){
            const uploadedFile = await briefAttachmentUpload(file, {
                projectId: null
            })
            this.project.brief_attachment = uploadedFile
            this.project.brief_attachment_id = uploadedFile.id
            this.key = !this.key
        },
        onRemoveBriefAttachment(){
            this.project.brief_attachment = null
            this.project.brief_attachment_id = null
            this.key = !this.key
        },
        onStartsAtChange(date){
            this.project.starts_at = date; 
            (this.autoUpdateEnabled && this.isEditView) ? this.updateProject : null;
            this.errors.starts_at = null
            this.$forceUpdate();
        },

        onEndsAtChange(date){
            this.project.ends_at = date; 
            (this.autoUpdateEnabled && this.isEditView) ? this.updateProject : null
            this.errors.ends_at = null
            this.$forceUpdate();
        },

        onOwnersInput(){
            (this.autoUpdateEnabled && this.isEditView) ? this.updateProject : null; 
            this.errors.owners = null
            this.$forceUpdate();
        },

        onBudgetInput(val){
            this.project.budget = formatBudget(val)
        },

        onCreatedInfluencer(influencer, assign = true) {
            this.createInfluencerModalVisible = false;
            this.createdInfluencer = influencer;
            this.assignCreatedInfluencerModalVisible = assign;
            if (!assign) {
                this.onAssignCreatedInfluencerDone();
            }
        },
        instagramFollowersValue(element){
            return instagramFollowers(element)
        },
        tiktokFollowersValue(element){
            return tiktokFollowers(element)
        },
        locationValue(element){
            return location(element)
        },
        customUserTagsSearchBy(element){
            const {user_tags = []} = element
            let str = ``
            user_tags.forEach(user_tag => {
                str = str + user_tag.tag.slug + ' '
            }); 
            return str;
        },

        async onConfirmSelectedCreators(selectedCreators) {
            let users = selectedCreators.map(c => {
                return {id: c.id}
            });
            const { data } = await this.$http.post(`/api/projects/${this.project.id}/update-users`, { users });
            if (data) {
                this.$notify({ title: 'Creators assigned successfully', text: '', type: 'success' });
                this.onAssignCreatedInfluencerDone();
            } else {
                this.$notify({ title: 'Warning', text: 'Something went wrong', type: 'warn' })
            }
        },

        onAssignCreatedInfluencerDone(){
            this.getCreators();
            this.getProject(false);
            this.assignCreatedInfluencerModalVisible = false;
        }

    }
}
</script>
<style lang="scss" scoped>
    .column{
        @apply w-1/2 flex flex-col gap-y-6;
    }
    .field{
        @apply flex flex-col gap-y-3;
    }
    .field-icon-container{
        @apply h-9 w-9 absolute flex justify-center items-center border border-gray-300 top-11 left-2.5;
    }
    .project-settings .content-pane {
        overflow: auto;
        max-height: calc(100vh - 140px);
        position: relative;
    }

    input[type = "date"]::-webkit-inner-spin-button,
    input[type = "date"]::-webkit-calendar-picker-indicator {
        visibility: none;
        -webkit-appearance: none;
    }
</style>
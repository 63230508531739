<template>
    <div class="bg-tan-m creator-report-wrapper">
        <div class="inner-content">
            <div class="left-bar">
                <ul role="list" class="divide-y divide-gray-100">
                    <li
                        v-for="(creator, i) in creators"
                        :key="i"
                        :class="['creator-list-item', { active: selectedCreatorIndex === i }]"
                        @click="selectedCreatorIndex = i"
                    >
                        <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="creator.avatar" alt="" />
                        <div class="min-w-0">
                            <p class="text-sm font-semibold leading-6 text-gray-900">{{ [creator.first_name, creator.last_name].join(" ") }}</p>
                            <p class="mt-1 truncate text-xs leading-5 text-gray-500" v-if="creator.report_user && creator.report_user.networks">
                                {{ creator.report_user.networks.map((n) => networks[n].label).join(", ") }}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="main-area">
                <div class="text-right my-2">
                    <div class="text-md font-extrabold">{{ report.name }}</div>
                    <div class="text-sm text-gray-500" v-html="report.description"></div>
                </div>

                <loading :active="loading" />
                <CreatorReportUser
                    :creator="selectedCreator"
                    :can-edit="canEdit"
                    :loading="loading"
                    @audience-refresh="updateSelectedAudienceData"
                    @reload-tab="reloadTab"
                />
            </div>
        </div>
    </div>
</template>

<script>
import CreatorReportUser from "./CreatorReportUser.vue";
import Loading from "vue-loading-overlay";

export default {
    components: {
        CreatorReportUser,
        Loading,
    },

    props: {
        report: {},
        networks: {},
        canEdit: {
            type: Boolean,
            default: false,
        },
    },

    mounted() {
        this.loadSelectedCreator();
    },

    data() {
        return {
            selectedCreator: {},
            selectedCreatorIndex: 0,
            loading: true,
        };
    },

    computed: {
        creators() {
            return this.report.influencers || [];
        },
    },

    watch: {
        selectedCreatorIndex(val, oldVal) {
            if (val !== oldVal) {
                this.loadSelectedCreator();
            }
        },
    },

    methods: {
        async loadSelectedCreator() {
            try {
                this.loading = true;
                const userId = this.creators[this.selectedCreatorIndex].id;
                const reportUserId = this.creators[this.selectedCreatorIndex].report_user.id;
                const { data } = await this.$http.get(`/api/reports/v2/${this.$route.params.id}/${userId}`);
                this.selectedCreator = data;
                this.selectedCreator.report_user_id = reportUserId;
            } catch (e) {
                console.log("error loading individual report", e);
            } finally {
                this.loading = false;
            }
        },
        async updateSelectedAudienceData() {
            this.loading = true;
            const { data } = await this.$http.get(`/jobs/update-profiles?report_user_id=${this.selectedCreator.report_user_id}`);
            console.log("got data response", data);
            this.loadSelectedCreator();
        },
        async reloadTab() {
            this.loadSelectedCreator();
        },
    },
};
</script>

<style lang="scss">
.creator-report-wrapper {
    height: 100vh;
    overflow: auto;

    h1 {
        @apply mt-2 mb-2 text-2xl;
    }
    h2 {
        @apply mb-12;
    }

    .inner-content {
        display: flex;
    }
}
.left-bar {
    @apply bg-white w-1/5;
    height: 100vh;
}
.creator-list-item {
    @apply flex gap-x-4 py-3 px-4 cursor-pointer;
    &:hover {
        @apply bg-gray-50;
    }
    &.active {
        background-image: linear-gradient(to bottom, var(--gradient-color-stops));
        --gradient-from-color: #42339f;
        --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 51, 159, 0));
        --gradient-to-color: #191245;
        p {
            color: #fff !important;
        }
    }
}
.main-area {
    @apply w-4/5 px-16;
}
.tabbed-view {
    @apply bg-white rounded-lg py-4 px-8 mb-2;
    .tab {
        @apply pb-4 mb-4;
    }

    .tab:first-child {
        @apply ml-0 pl-0;
    }
}
</style>

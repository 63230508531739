var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['relative block border-2 border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out relative', { 'has-upload-error bg-red-100': _vm.file.error, 'hidden' : !_vm.visible }]},[_c('div',{staticClass:"cursor-pointer p-4 w-full flex justify-between items-start gap-x-4",on:{"click":function($event){$event.preventDefault();return _vm.openFileIntent($event)}}},[_c('div',{staticClass:"flex items-center justify-center flex-grow-0 flex-shrink-0 rounded-full bg-white w-10 h-10 border border-gray-m-light"},[_c('base-icon',{staticClass:"text-purple-m-main",attrs:{"name":_vm.fileIcon,"size":5}})],1),_c('div',{staticClass:"flex flex-col flex-grow flex-shrink"},[_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',{staticClass:"flex-grow flex-shrink w-80"},[_c('div',{staticClass:"truncate font-semibold text-lg text-black"},[_vm._v(" "+_vm._s(_vm.file.name)+" ")]),_c('div',{staticClass:"text-ps text-gray-m-disable font-normal"},[_vm._v(" "+_vm._s(_vm.computedFileSize)+" "+_vm._s(_vm.file.permissions === 3 ? 'Private' : '')+" ")]),(_vm.file.error)?_c('div',[_vm._v(" ERROR UPLOADING ")]):_vm._e()]),_c('div',{staticClass:"flex-grow-0 flex-shrink-0",on:{"click":function (e){ return e.stopPropagation(); }}},[(_vm.isProjectOwner || _vm.isAdminOrHigher)?_c('options-dropdown',{attrs:{"options":(this.isSuperAdmin ? [{
                                label: 'Permissions',
                                icon: 'locked',
                                event: 'permissions',
                            }] : []).concat( ([{
                                label: 'Edit',
                                icon: 'pencil-alt',
                                event:'edit'
                            }]),
                            (this.isAdminOrHigher ? [{
                                label: 'Delete',
                                icon: 'trash',
                                event: 'del',
                                divider: true
                            }] : [])
                        )},on:{"permissions":function($event){return _vm.$emit('share', { type: 'file', id: _vm.file.id })},"edit":function($event){return _vm.$emit('edit-file', _vm.file)},"del":function($event){_vm.$swal.fire({
                                title: ("Are you sure you want to delete " + (_vm.file.name) + " ?"),
                                text: "You won't be able to revert this!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Delete',
                                buttonsStyling: false,
                                reverseButtons: true,
                                focusCancel: true
                            }).then(function (result) {
                                if (result.isConfirmed) {
                                    _vm.deleteFile()
                                }
                            });}}}):_vm._e()],1)]),(_vm.file.uploading)?_c('div',{staticClass:"progress-container"},[_c('div',{staticClass:"progress-bar",style:(("width: " + (_vm.file.uploadProgress) + "%"))})]):_vm._e()])]),(_vm.file.permissions == 3)?_c('div',{staticClass:"absolute inset-0 flex justify-center items-center pointer-events-none"},[_c('svg',{staticClass:"ml-2 h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z","clip-rule":"evenodd"}})])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="list">

        <!-- page -->
        <div class="page" v-for="(page, index) in pages" :key="index">
            
            <div class="header">
                <!-- left side -->
                <div class="left">
                    <img class="picture" :src="page.picture" />
                    <div>
                        <div class="name">{{ page.name }}</div>
                        <div class="category">{{ page.category }}</div>
                    </div>
                </div>
                <!-- right side -->
                <div />
            </div>

            <div class="stats">

                <div class="stat">
                    <base-icon :size="12" class="text-green-m-main" name="user" />
                    <div class="content">
                        <div class="name">Followers</div>
                        <div class="value">{{ page.followers_count }}</div>
                    </div>
                </div>

                <div class="stat">
                    <base-icon :size="12" class="text-green-m-main" name="report-comments" />
                    <div class="content">
                        <div class="name">Engagement</div>
                        <div class="value">{{ page.engagement }}</div>
                    </div>
                </div>

                <div class="stat">
                    <base-icon :size="12" class="text-green-m-main" name="facebook" />
                    <div class="content">
                        <div class="name">Fans</div>
                        <div class="value">{{ page.fan_count }}</div>
                    </div>
                </div>

            </div>

            <div class="posts">

                <div v-for="(post, index) in page.posts" :key="`post-${index}`" class="post">

                    <img class="picture" :src="post.full_picture" />

                    <div class="author">
                        <avatar-list class="font-bold" :users="[user]" />
                        <div class="created-at">{{ formatDate(post.created_time)  }}</div>
                    </div>

                    <div class="message"> {{ post.message }} </div>

                    <div class="interactions">
                        <div class="interaction"><base-icon name="like"/> {{ post.likes }} </div>
                        <div class="interaction"><base-icon name="comments"/> {{ post.comments }} </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</template>

<script>
import AvatarList from '../../components/AvatarList.vue'
import moment from 'moment';

export default {
  components: { AvatarList },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    
    data(){
        return {
            pages: [],
            loading: false
        }
    },

    mounted(){
        this.getData()
    },

    methods: {
        formatDate(date) {
            return moment(date).format('ll');
        },
        async getData(){
            this.loading = true
            this.$http.get(`/api/user-fb-page?user_id=${this.user.id}&stats=true&posts=true`).then(async (res) => {
                if(res.data){
                    this.pages = res.data
                }
            }).catch((err) => {
                console.log('getPages error', err)
            }).finally(() => {
                this.loading = false
            }) 
        },
    }

}
</script>
<style lang="scss" scoped>
    .list{
        @apply p-12 flex flex-col gap-y-10;
        .page{
            @apply flex flex-col gap-y-6;
            .header{
                @apply w-full px-4 flex justify-between items-center;
                .left{
                    @apply flex items-center gap-x-4;
                    .picture{
                        @apply rounded-full;
                    }
                    .name{
                        @apply font-bold;
                    }
                    .category{
                        @apply text-gray-500;
                    }
                }
                .right {}
            }
            .stats{
                @apply w-full py-4 flex items-center justify-between;
                .stat{
                    @apply w-1/3 mx-4 p-4 flex items-center gap-x-4;
                    @apply rounded-md border border-gray-300;
                    .content{
                        .name{
                            @apply font-bold;
                        }
                        .value{

                        }
                    }
                }
            }
            .posts{
                @apply w-full flex justify-start flex-wrap;
                .post{
                    @apply w-1/3 p-4;
                    .picture{

                    }
                    .author{
                        @apply pt-4;
                        .created-at{
                            @apply text-gray-400 text-pxs;
                        }
                    }
                    .message{
                        @apply py-4;
                    }
                    .interactions{
                        @apply w-full flex justify-between;
                        .interaction{
                            @apply w-1/2 p-4 flex justify-center items-center gap-x-2;
                        }
                    }
                }
            }
        }
    }
</style>
<template>
    <div>
        <Paragraph class="mb-0" />
        <table class="table">
            <thead>
                <th 
                    v-for="(column, index) in columns" 
                    :key="`th-${index}`"
                    class="th"
                >
                    {{column}}
                </th>
            </thead>
            <tbody>
                <tr 
                    v-for="(row, index) in data" 
                    :key="`tr-${index}`"
                    class="tr"
                >
                    <td 
                        v-for="(value, index) in row" 
                        :key="`td-${index}`"
                        class="td"
                    >
                        <div v-html="curated(value)"/>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Paragraph from './Paragraph.vue'
export default {
    props: ['columns', 'data'],
    components: {
        Paragraph
    },
    methods: {
        curated(value){
            if(this.hasAnchorLinks(value)){
                return this.parseAnchorLinks(value)
            }
            return value
        },
        hasAnchorLinks(val){
            return val.includes('[') || val.includes(']') 
        },
        parseAnchorLinks(val){
            let str = val
            let done = false
            let count = 0
            while(!done){
                count++
                let matches = str.match(/\[(.*?)\]/)
                if (matches) {
                    let match = matches[0]; // [Link, anchor]
                    let submatch = matches[1]; // link, anchor
                    let link = submatch.split(',')[0]
                    let anchor = submatch.split(',')[1]
                    str = str.replace(match, `<a style="color: #a1a1ff" href="${anchor}">${link}</a>`)
                } else{
                    done = true
                }
                // max 10 runs
                if(count >= 10){
                    done = true
                }
            }
            return str
        }
    }
}
</script>
<style lang="scss" scoped>
    .table{
        @apply mb-10;
    }
    .table, .th, .td {
        border: 1px solid white;
    }
    .th{
        @apply text-left py-2 px-4;
    }
    .td{
        @apply py-2 px-4;
    }
</style>
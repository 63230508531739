<template>
    <div class="container">
        <div class="content">
            <div class="header">
                <div class="font-bold mb-2">{{data.name}}</div>
            </div>

            <custom-select
                :key="key"
                :label="`Select filter type`"
                :options="types"
                class="mb-4"
                v-model="selectedType"
            />

            <div v-if="selectedType && selectedType !== ''">
                
                <template v-if="selectedType.order == 'label-min'">
                    <FormulateInput
                        type="text"
                        class="w-1/2"
                        label="Value"
                        placeholder="0"
                        @input="onMinInput"
                        v-model="min"
                    />
                </template>

                <template v-if="selectedType.order == 'label-max'">
                    <FormulateInput
                        type="text"
                        class="w-1/2"
                        label="Value"
                        placeholder="0"
                        @input="onMaxInput"
                        v-model="max"
                    />
                </template>

                <div v-if="selectedType.order == 'between'" class="flex justify-between items-center gap-x-4">
                    <FormulateInput
                        type="text"
                        class="w-1/2"
                        label="Min"
                        placeholder="0"
                        @input="onMinInput"
                        v-model="min"
                    />
                    <FormulateInput
                        type="text"
                        class="w-1/2"
                        label="Max"
                        placeholder="0"
                        @input="onMaxInput"
                        v-model="max"
                    />
                </div>
            </div>

        </div>
        <div class="footer">
            <base-button
                :label="`${filters[data['slug']] ? `Update Filter` : `Add Filter`}`"
                icon="filter"
                size="lg"
                @action="addFilter"
            />

            <base-button
                type="secondary"
                label="Cancel"
                @action="$emit('close')"
            />
        </div>
    </div>
</template>

<script>
import { formatNumberString } from '../../../lib/strings'
export default {
    props: ['data', 'filters'],
    data(){
        return {
            selectedType: '',
            types: [
                { value: 'equal', label: 'is equal to', order: 'label-min' },
                { value: 'greater', label: 'is greater than', order: 'label-min'},
                { value: 'greaterEqual', label: 'is greater than or equal to', order: 'label-min'},
                { value: 'less', label: 'is less than', order: 'label-max'},
                { value: 'lessEqual', label: 'is less than or equal to', order: 'label-max'},
                { value: 'between', label: 'is between', order: 'between' }
            ],
            min: '',
            max: '',
            key: 0
        }
    },
    mounted(){
        if(this.filters[this.data['slug']]){
            const type = this.filters[this.data['slug']].type
            const found = this.types.find(element => element.value === type)
            this.selectedType = found
            this.min = this.filters[this.data['slug']].min
            this.max = this.filters[this.data['slug']].max
            this.key++
        }
    },
    methods: {
        onMinInput(val){
            this.min = formatNumberString(val)
        },
        onMaxInput(val){
            this.max = formatNumberString(val)
        },
        addFilter(){
            const min = ( this.min ) ? parseInt(this.min.toString().replaceAll(',','')) : 0;
            const max = ( this.max ) ? parseInt(this.max.toString().replaceAll(',','')) : 0;
            this.$emit('addFilter', this.data['slug'], { type: this.selectedType.value, min, max }, this.data)
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply relative h-full flex flex-col;
        .content{
            @apply flex-grow flex-shrink;
            .header{
                @apply flex justify-between items-center;
            }
        }
        .footer{
            @apply sticky bottom-0 left-0 right-0 py-5 bg-tan-m flex justify-between items-center;
        }
    }
</style>
// Strict and should be fixed immediatelly
export const fields = [
    {
        label: 'First name',
        slug: 'first_name',
        required: true,
        variants: ['first_name', 'firstname', 'first-name', 'first name', 'first']
    },
    {
        label: 'Last name',
        slug: 'last_name',
        required: true,
        variants: ['last_name', 'lastname', 'last-name', 'last name', 'last']  
    },
    {
        label: 'Email',
        slug: 'email',
        required: true,
        variants: ['email', 'email_address', 'email-address', 'emailaddress', 'email address']
    },
    {
        label: 'Handle',
        slug: 'handle',
        required: true,
        variants: ['handle','link','blog','profile','profile link','username','screen name']
    },
    // additional, fixable but optional
    {
        label: 'Description',
        slug: 'description',
        required: false,
        variants: ['bio', 'influencer bio']
    },
    {
        label: 'Posts',
        slug: 'posts',
        required: false,
        variants: ['example posts', 'featured posts']
    },
    // commenting this for now as now we are considering every unidentified column as a potential additional information 
    // {
    //     label: 'Additional Information',
    //     slug: 'additional_information',
    //     required: false,
    //     variants: []
    // }
];
<template>
<div class="register-container">

    <Header :invitation="invitation" />

    <div class="loader-container">
        <base-icon v-if="loading" name="loader" class="text-white" />
    </div>

    <div class="form-wrapper">

        <div class="form-container">
            <alert class="mb-8" type="danger" v-if="error">
                <template v-slot:icon>
                    <base-icon name="exclamation-2" />
                </template>
                {{ error }}
                <div v-if="error.includes('email already exists') || error.includes('You have already linked this facebook account')">
                    You can try <a @click.prevent="$router.push(loginRoute)" href="#" class="link">Logging In</a>
                </div>
            </alert>
            <FormulateForm 
                class="flex flex-col items-center" 
                name="register-form" 
                @submit="register"
            >
                <FormulateInput
                    type="text"
                    label="First Name"
                    v-model="firstName"
                    validation="required"
                    validation-name="First Name"
                    class="w-full"
                />

                <FormulateInput
                    type="text"
                    label="Last Name"
                    v-model="lastName"
                    validation="required"
                    validation-name="Last Name"
                    class="w-full mt-6"
                />

                <FormulateInput
                    type="email"
                    label="Email address"
                    v-model="email"
                    autocomplete="off"
                    validation="required|email"
                    validation-name="Email"
                    class="w-full mt-6"
                />

                <FormulateInput
                    autocomplete="off"
                    type="password"
                    label="Password"
                    v-model="password"
                    validation="required"
                    validation-name="Password "
                    class="w-full mt-6"
                />

                <button
                    :disabled="loading || !formValidated"
                    type="submit"
                    class="mt-6 w-full button justify-center items-center gap-3 rounded-full
                    text-h6 font-base border focus:outline-none transition duration-150 ease-in-out
                    flex w-36 px-4 py-4 border-transparent focus:shadow-outline-gray 
                    text-purple-m-main bg-green-m-main hover:bg-green-m-main-dark focus:bg-green-m-dark"
                >Register</button>

                <div class="flex justify-center mt-6">
                    <a @click.prevent="$router.push(loginRoute)" href="#" class="link">
                        Already have an account? Log in
                    </a>
                </div>
                
            </FormulateForm>

        </div>
    </div>
</div>
</template>

<script>
import Alert from '../../components/Alert';
import Header from './Header.vue'
import { mapGetters } from 'vuex';
export default {
    components: {
        Alert,
        Header
    },
    data() {
        return {
            invitation: {},

            email: '',
            firstName: '',
            lastName: '',
            password: '',

            loading: false,
            error: null,

            isNewCompanySignup: this.$route.meta.companySignup,
        }
    },
    mounted(){
        let token = this.hiddenToken;
        if (token) {
            return this.getInvitation(token)
        }
        if (this.isGuest) {
            return;
        }
        // this.error = 'No token provided'
    },
    computed: {
        ...mapGetters(['isGuest']),
        loginRoute(){
            const t = this.$route.query.t
            return `/login${t ? `?t=${t}` : ``}`
        },
        formValidated(){
            let err = 0
            if(!this.firstName || this.firstName == '') err++
            if(!this.lastName || this.lastName == '') err++
            if(!this.email || this.email == '') err++
            if(!this.password || this.password == '') err++
            return !err
        },
        hiddenToken() {
            let token = this.$route.query.t;
            let state = this.$route.query.state;
            if (!token && state && state !== '') {
                state = state.split('&');
                if (state.length) {
                    for (let item of state) {
                        let query = item.split('=');
                        if (query.length && query[0] === 't') {
                            return query[1];
                        }
                    }
                }
            }
            return token;
        },
    },
    methods: {
        async getInvitation() {
            this.loading = true
            this.$http.get(`/api/invite/${this.$route.query.t}`).then(({data}) => {
                if(data){
                    if(data.company){
                        this.invitation = data
                    }
                    if (data.target !== null && data.target !== undefined) {
                        this.email = data.target.email;
                        this.firstName = data.target.first_name;
                        this.lastName = data.target.last_name;
                    }
                }
            }).catch(e => {
                console.log(e);
                if (e.response && e.response.data) {
                    return this.error = e.response.data.message;
                }
                this.error = 'An unknown error occurred. Please try again.';
            }).finally(() => {
                this.loading = false
            })
        },
        async register() {
            try {
                let token = this.hiddenToken;
                this.error = null;
                this.loading = true;
                const apiEndpoint = this.isNewCompanySignup ? `/api/auth/register/company-user` : `/api/auth/register/invited`;
                const { data } = await this.$http.post(apiEndpoint, { 
                    token,
                    first_name: this.firstName,
                    last_name: this.lastName,
                    email: this.email,
                    password: this.password
                });

                if (data && data.access_token) {
                    await this.$store.dispatch("auth/login", data);
                    await this.$store.dispatch('auth/getMe');

                     // user is signing up to create their own platform
                    if (this.isNewCompanySignup) {
                        return this.$router.push('/new-company');
                    }

                    await this.$store.dispatch('colors/getColors')
                    await this.$store.dispatch('statuses/getStatuses')
                    await this.$store.dispatch('boards/getBoards')
                    await this.$store.dispatch('company/getCompany');
                    await this.$store.dispatch('fb/getLoginStatus');

                    if (this.redirect && this.redirect !== '') {
                        const redirect = this.redirect;
                        this.$router.push(redirect);
                        this.$store.dispatch("auth/setRedirect", null);
                    }
                    else {
                        if(data.roles.includes('client')){
                            this.$router.push('/deliverables')
                        }else{
                            this.$router.push('/home')   
                        }
                    }
                }
            } catch(e) {
                console.log(e);
                if (e.response && e.response.data) {
                    return this.error = e.response.data.message;
                }
                this.error = 'An unknown error occurred. Please try again.';
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .register-container{
        @apply min-h-screen bg-purple-m-main flex flex-col overflow-y-auto;
        @media (min-width: 640px) {
            @apply px-6;
        }
        @media (min-width: 1024px) {
            @apply px-8;
        }
        .loader-container{
           @apply h-8 w-full flex justify-center items-center;
           min-height: 2rem;
        }
        .form-wrapper{
            @apply mb-12;
            @media (min-width: 640px) {
                @apply mx-auto w-full max-w-md;
            }
            .form-container{
                @apply bg-white py-8 px-4 shadow;
                @media (min-width: 640px) {
                    @apply rounded-lg px-10;
                }
            }
        }
    }
    .separator{
        @apply flex w-full mt-4 items-center gap-2 justify-between;
        .center{
            @apply text-gray-headers;
        }
        .left, .right{
            @apply w-full border-t;
        }
    }
    .link{
        @apply text-sm leading-5 font-medium text-gray-m-dark; 
        &:hover{
            @apply opacity-75 transition-opacity;
        } 
        &:focus{
            @apply outline-none underline;   
        }
    }
</style>
<template>
    <!-- https://codepen.io/team/codepen/pen/axwMPo -->
    <div>
        <!-- overlay -->
        <transition name="fade" appear>
            <div v-if="visible" @click="closeOverlay" :class="['modal-overlay', {'cursor-pointer': !persistent}, {'invisible': invisible}, this.overlayClasses]"/>
        </transition>

        <!-- modal -->
        <transition name="pop" appear>
            <div v-if="visible" :class="['modal', this.classes, {'animated': attemptingClose}, {'max-w-3xl': !withPicture && !bigger, 'max-w-4xl': withPicture && !bigger}, {'max-w-6xl': bigger}, {'invisible': invisible}]" :style="`${this.styles}`" role="dialog">
                <div class="flex">
                    <div v-if="withPicture" class="w-1/3 flex rounded-l-2xl flex-col justify-between" :class="{'border-purple-m-main border-1 bg-purple-m-main text-white': mode === 'dark'}">
                        <div class="p-6 flex flex-col gap-4">
                            <div v-if="modalIcon" :class="{'text-purple-m-main': mode === 'dark'}"><base-icon :name="modalIcon" border :size="10" :background="mode === 'dark' ? 'bg-green-m-main' : ''"/></div>
                            <div class="text-h2 font-bold">{{ modalTitle }}</div>
                            <div class="text-sm font-light">{{ modalSubtitle }}</div>
                        </div>
                        <div><img :src="dinosaur" class="rounded-bl-2xl w-full"></div>
                    </div>
                    <div class="w-full flex flex-col justify-between">
                        <div v-if="!hideHeader" class="pt-10 pb-5 px-7 relative flex justify-between items-center" :style="`${headerStyles}`">
                            <div v-if="centerTitle && !hideCloseButton" class="w-7"></div>
                            <div class="flex justify-start items-center">
                                <slot name="title-icon"></slot>
                                <h3 class="text-2xl leading-6 font-bold text-black flex items-center" :style="`${titleStyles}`">
                                    {{ title }}
                                </h3>
                            </div>

                            <div v-if="!hideCloseButton" class="flex justify-end items-center gap-x-6 cursor-pointer">
                                <base-icon @action="$emit('close')" name="x" border background="bg-white" :size="7" :icon-size="3"/>
                            </div>
                        </div>

                        <div :class="[{'pt-5 pb-10 px-14 flex-grow flex-shrink': !noPadding}, containerClass]">
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import dinosaur from './../assets/images/dinosaur-modal.png';
    export default {
        props: {
            visible: {},
            invisible: {},
            title: {},
            hideHeader: {},
            classes: {},
            hideCloseButton: {},
            headerStyles: {},
            titleStyles: {},
            overlayClasses: {},
            styles: {},
            withPicture: {
                type: Boolean,
                default: false
            },
            modalTitle: {},
            modalSubtitle: {},
            modalIcon: {},
            mode: {
                type: String,
                default: 'dark'
            },
            noPadding: {
                type: Boolean,
                default: false
            },
            centerTitle: {
                type: Boolean,
                default: false
            },
            preventEscape: {
                type: Boolean,
                default: false
            },
            bigger: {
                type: Boolean,
                default: false
            },
            persistent: {
                type: Boolean,
                default: false
            },
            containerClass: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                dinosaur: dinosaur,
                attemptingClose: false,
            }
        },
        watch: {
            visible: function (val, old) {
                if (!this.preventEscape && val !== old) {
                    if (val) {
                        window.addEventListener('keyup', this.handleKeys);
                    } else {
                        window.removeEventListener('keyup', this.handleKeys);
                    }
                }
            }
        },
        methods: {
            handleKeys(event) {
                if (event.which === 27) { // esc
                    this.$emit('close');
                }
            },
            closeOverlay() {
                if (this.persistent) {
                    this.attemptingClose = true;
                    setTimeout(() => {
                        this.attemptingClose = false;
                    }, 1500);
                } else {
                    this.$emit('close');
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    .modal {
        z-index: 255;
        @apply absolute fixed inset-0 m-auto rounded-2xl shadow-lg bg-tan-m transform-none w-full;
        height: fit-content;
    }

    .modal h1 {
        margin: 0 0 1rem;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.2s linear;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .modal-overlay {
        z-index:250;
        @apply absolute fixed inset-0;
        content: '';
        background-color: rgb(14 9 44 / 90%);
        &.opaque{
            background-color: rgb(14 9 44 / 100%);
        }
    }

    .pop-enter-active, .pop-leave-active {
        transition: transform 0.2s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.2s linear;
    }

    .pop-enter, .pop-leave-to {
        opacity: 0;
        transform: scale(0.3) translateY(-50%);
    }

    .animated {
        animation-name: modal-scale;
        animation-duration: .15s;
        animation-timing-function: cubic-bezier(.25,.8,.25,1);
    }
    
    @keyframes modal-scale {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.04);
        }
        100% {
            transform: scale(1);
        }
    }
</style>
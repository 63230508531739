<template>
    <base-table-new
        overflow
        :border="false" 
        class="px-12 py-10"
        :columns="[
            { name: 'name', label: 'Name' },
            { name: 'status', label: 'Status', value: (element) => { return deliverablePhasesProgress(element) } },
            { name: 'payment', label: 'Payment', type: 'money-format', value: (element) => { return element.budget } },
            { name: 'due-date', label: 'Due Date', type: 'date', value: (element) => { return element.end_date } }
        ]"
        :search="false"
        :data="localDeliverables"
        :onRowClick="element => $router.push(`/deliverables/${element.id}`)" 
        :loading="false"
    />
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: ['project', 'deliverables'],
    computed: {
        ...mapGetters(['me'])
    },
    data(){
        return {
            localDeliverables: []
        }
    },
    watch: {
        deliverables(val) {
            this.localDeliverables = this.deliverables;
        }
    },
    mounted(){
        this.getDeliverables();
    },
    methods: {
        getDeliverables(){

            if ( this.deliverables ) {
                this.localDeliverables = this.deliverables;
            } else {
                let found = this.project.project_users.find(element => element.user_id == this.me.id)
                if(found){
                    let project_user_id = found.id
                    this.$http.get(`/api/userDeliverables/${project_user_id}`).then(({data}) => {
                        this.localDeliverables = data                
                    }).catch(err => {
                        console.log('get deliverables error', err)
                    });
                }
            }
        },
        deliverablePhasesProgress(deliverable){
            console.log('deliverable', deliverable);
            if ( deliverable.phases_progress.display_name ) {
                return deliverable.phases_progress.display_name;
            }

            if (deliverable.is_completed) {
                return 'Completed';
            } else {            
                switch(deliverable.phases_progress){
                    case 0:
                        return 'Concept Phase';
                    case 10:
                        return 'Draft Phase';
                    case 20:
                        if(deliverable.posts_needed != null & deliverable.posts_needed > 0 && deliverable.project_user_deliverable_posts != null){
                            if(deliverable.project_user_deliverable_posts.length < deliverable.posts_needed)
                                return 'Waiting For Posts';
                            else
                                return 'Completed'
                        }
                        else
                            return 'Completed';
                    default:
                        return 'Default Phase';
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>

</style>
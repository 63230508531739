<template>

    <div style="max-width: 400px; min-width: 200px;" class="cursor-default flex w-full flex-wrap gap-2 items-center" v-if="tags && tags.length">
        <span v-for="(tag, index) in tags.slice(0, limit > 0 ? limit : tags.length)" :key="index"
            :class="['text-pxs font-bold h-8 px-3 rounded-full inline-flex items-center justify-center',
                {'bg-purple-m-secondary text-green-m-main': theme === 'light'},
                {'border border-green-m-main text-green-m-main': theme === 'dark'}]"
        >
            {{ computedName(tag.tag.name) }}
        </span>
        <span v-if="limit > 0 && tags.length > limit" @mouseenter="showExtra = true;" @mouseleave="showExtra = false;"
            class="text-pxs text-gray-m-disable relative">
            {{`+ ${tags.length - limit} more`}}
            <div v-if="showExtra" 
                class="absolute flex flex-col gap-2 p-2 bg-white rounded-lg shadow-lg left-full top-1/2 transform translate-x-2 -translate-y-1/2">
               <span v-for="(tag, index) in tags.slice(limit, tags.length)" :key="index"
                    :class="['text-pxs font-bold h-8 px-3 rounded-full inline-flex items-center justify-center',
                        {'bg-purple-m-secondary text-green-m-main': theme === 'light'},
                        {'border border-green-m-main text-green-m-main': theme === 'dark'}]"
                >
                    {{ computedName(tag.tag.name) }}
                </span> 
            </div>
        </span>
    </div>
    <div v-else><br></div>
    
</template>

<script>
export default {
    name: 'Tags',
    props: {
        tags: {
            type: Array
        },
        theme: {
            type: String,
            default: 'light'
        },        
        limit: {
            type: Number,
            default: 1
        }
    }, 

    data() {
        return {
            showExtra: false
        };
    },

    methods: {
        computedName(name) {
            return name.charAt(0).toUpperCase() + name.slice(1)
        }
    }
}
</script>
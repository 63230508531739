<template>
    <div>
        <Header title="Webhook Subscriptions" :count="webhookSubscriptions.length" :loading="loading" />

        <div class="webhook-subscription-list">
            <div v-if="!webhookSubscriptions.length">No webhooks were found</div>
            <div class="webhook-subcription" v-for="(webhookSubscription, index) in webhookSubscriptions" :key="index">
                
                <div>Object: <span class="font-bold">{{webhookSubscription.object}}</span></div>
                <div>Callback URL: <span class="font-bold">{{webhookSubscription.callback_url}}</span></div>
                <div>Active: <span class="font-bold">{{webhookSubscription.active}}</span></div>
                
                <div v-if="webhookSubscription.fields.length">
                    <div class="font-bold">
                        Fields ({{webhookSubscription.fields.length}}): 
                        <base-button 
                            @action="() => { webhookSubscription.dropdownOpen = webhookSubscription.dropdownOpen ? false : true; $forceUpdate() }" 
                            type="label"
                            :icon="webhookSubscription.dropdownOpen ? 'chevron-up' : 'chevron-down'" 
                        />
                    </div>
                    <div v-if="webhookSubscription.dropdownOpen" class="fields-list">
                        <div class="field" v-for="(field, index) in webhookSubscription.fields" :key="`f-${index}`">
                            {{ field.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
 
    </div>
</template>

<script>
import Header from './Header.vue'
export default {
    components: {
        Header
    },
    data() {
        return {
            loading: false,
            webhookSubscriptions: []
        }
    },
    mounted(){
        this.getWebhookSubscriptions()
    },
    methods: {
        async getWebhookSubscriptions(){
            this.loading = true
            this.$http.get('/api/facebook/webhook/subscriptions').then(async (res) => {
                if(res.data && res.data.data){
                    this.webhookSubscriptions = res.data.data
                }
            }).catch((err) => {
                console.log('getWebhookSubscriptions error', err)
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .webhook-subscription-list{
        @apply flex flex-col mt-6 gap-y-4 items-start;
        .webhook-subcription{
            @apply px-6 py-2 border border-black rounded-xl;
            .fields-list{
                @apply flex w-full mt-2 gap-x-2 flex-wrap gap-y-2;
                .field{
                    @apply rounded-full bg-blue-500 px-4 py-2 text-white text-pxs;
                }
            }
        }
    }
</style>
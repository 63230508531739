<template>
    <span class="flex items-center">
        <base-icon 
            :name="sort.column !== col ? 'chevron-updown' : (!sort.asc ? 'chevron-o-down' : 'chevron-o-up')" 
            :size="sort.column !== col ? 5 : 3"
            wrapper-class="flex flex-col gap-min justify-center items-center"
        />
    </span>
</template>

<script>
export default {
    props: ['sort', 'col'],
}
</script>

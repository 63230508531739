// temporarily removed until we implement new 
// firestore lib which reduces import size
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyA5AnKW6N_08x5bKzurYZ5X8SrnTG5Wd8U",
    authDomain: "glassy-tube-622.firebaseapp.com",
    databaseURL: "https://markerly-platform.firebaseio.com",
    projectId: "glassy-tube-622",
    storageBucket: "glassy-tube-622.appspot.com",
    messagingSenderId: "132233585000",
    appId: "1:132233585000:web:b4cdb8c5fe07d4d5ec76dd"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.database();

export default db;
// export default {}

<template>
    <base-modal 
        title="Edit File"
        :visible="visible"
        @close="$emit('close')"
    >
        <FormulateForm name="form" @submit="submit()">
            
            <div class="flex flex-col gap-y-2" v-if="file">

                <FormulateInput
                    type="text"
                    placeholder="New Name"
                    validation="required"
                    label="File name"
                    v-model="editName"
                />  

                <FormulateInput
                    v-if="file.mimetype === 'link'"
                    type="text"
                    placeholder="https://"
                    validation="required"
                    label="File link"
                    v-model="editUrl"
                />              

            </div>

            <div class="pt-3 sm:flex sm:flex-row-reverse">

                <base-button @action="$formulate.submit('form')" :disabled="loading || !editName || !editUrl" submit label="Submit"/>

            </div>

        </FormulateForm>
    </base-modal>
</template>

<script>
export default {
    props: ['visible', 'file'],
    data() {
        return {
            editName: '',
            editUrl: '',
            loading: false,
        }
    },
    watch: {
        'file': {
            deep: true,
            handler: function (val, old) {
                if (val && val !== old) {
                    this.editName = val.name;
                    this.editUrl = val.url;
                }
            }
        }
    },
    methods: {
        async submit() {
            this.loading = true
            try{
                const { data } = await this.$http.put(`/api/files/edit/${this.file.id}`, { 
                    editName: this.editName,
                    editUrl: this.editUrl,                                        
                });                
                this.$notify({ title: 'Success', text: 'File edited successfully', type: 'success' });
                this.$emit('file-edited');  
                this.$emit('close');                
            }catch(error){
                console.log(error)
            }finally{
                this.loading = false
            }
        }
    }
}
</script>
<template>
<div>

    <Title type="main">Options Dropdown</Title>

    <Title>Usage</Title>
    <Code title="Simple use" :code='`
        <options-dropdown               
            :options="[
                {label: "Edit", icon: "pencil-alt", event: "edit"},
                {label: "Delete", icon: "trash", event: "delete"}
            ]"
            @delete=""
            @edit=""
        />
    `'/>

    <Title>Props</Title>
    <Table
        title="These are the props:"
        :columns="['Name', 'Type', 'Default', 'Description']"
        :data="[
            ['options', 'Array', 'empty array', 'An array of objects representing the options. Each object can have the following keys: {label: String, disabled: Boolean, disabledTooltip: Boolean'],
            ['right', 'Boolean', 'false', 'If the dropdown will open to the right'],
            ['top', 'Boolean', 'false', 'If the dropdown will open on top'],
            ['grow', 'Boolean', 'false', 'If the dropdown can grow depending on its contents (just adds padding insead of setting static width and height)'],
            ['hideSecondaryIcon', 'Boolean', 'false', 'Hides the secondary icon'],
            ['theme', 'String', 'light', 'Changes the theme of the dropdown. Accepts: {light, dark, cancel, neutral}'],
            ['customDropDown', 'String', '-', 'Classes to be applied to the dropdown'],
            ['noBgIcon', 'String', `''`, 'Name of the icon to be displayed if the secondary icon is showing'],
            ['externalStatus', '-', '-', 'We can progamatically toggle the dropdown from the parent component with this prop']

        ]"
    />
</div>
</template>
<script>
import Code from '../Code.vue'
import Table from '../Table.vue'
import Title from '../Title.vue'
import Paragraph from '../Paragraph.vue'
export default {
    components: {
        Code,
        Table,
        Title,
        Paragraph
    }
}
</script>
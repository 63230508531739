<template>
    <main class="main" tabindex="0">

        <div v-if="!isEmpty(user)" class="wrapper">

            <Header :user="user" :loading="loading" v-model="viewTab" />

            <tab :visible="viewTab === 'About'">
                <about :user="user" :loading="loading" @refresh="getData()" />
            </tab>
            
            <tab :visible="viewTab === 'Portfolio'">
                <portfolio :user="user"/>
            </tab>
        </div>

    </main>
</template>

<script>
    import Header from './Header.vue'
    import About from './About.vue'
    import Portfolio from './Portfolio.vue';
    import { isEmpty } from '@/common'
    export default {
        components: {
            Header,
            About,
            Portfolio
        },
        data() {
            return {
                viewTab: 'About',
                user: {},
                loading: false,

                isEmpty
            }
        },

        mounted() {
            this.getData()
            if (this.$route.query.tab) {
                this.viewTab = this.$route.query.tab;
            }
        },
        watch: { viewTab(val){ this.$router.push({ query: { ...this.$route.query, tab: val }}) } },
        methods: {
            getData() {
                this.loading = true;
                this.$http.get(`/api/users/me/details`).then(({data}) => {
                    this.user = data;
                }).catch((err) => {
                    console.log('getData error', err);
                    this.$notify({title: 'Something went wrong', text: 'try again later', type: 'error'});
                }).finally(() => {
                    this.loading = false;
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

    .main{
        @apply flex-1 overflow-y-auto;
        &:focus{
            @apply outline-none;
        }

        .wrapper {
            @apply flex-1 min-w-0 bg-white;
        }

    }
</style>
<template>
    <base-modal
        title="Selected Interests"
        :visible="visible"
        @close="$emit('close')"
    >
        <div class="list">
            <div class="item" v-for="(interest, index) in value" :key="index">
                <div class="name">{{interest.name}}</div>
                <base-icon name="trash" class="cursor-pointer" @action="$emit('remove-interest', interest)" />
            </div>
        </div>
    </base-modal>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean
        },
        value: {
            type: Array
        }
    },
    watch: {
        // if you remove all interests, the modal closes
        value(val){
            if(val && Array.isArray(val) && !val.length){
                this.$emit('close')
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .list{
        @apply w-1/2 flex flex-col gap-y-2;
        .item{
            @apply flex justify-between items-center;
            @apply rounded-lg p-1 transition-colors duration-500;
            &:hover{
                @apply bg-gray-200;
            }
        }
    }
</style>

<template>
<div class="flex flex-col py-16 gap-y-20">

    <FormulateForm class="form" name="create-deliverable-form" @submit="()=>null">
      
        <!-- For required fields: <span class="text-red-700">*</span> -->
        <div class="flex gap-x-16">

            <div class="field w-1/2">
                <div class="text-h6 font-bold">Deliverable name</div>
                <FormulateInput
                    type="text"
                    validation="required"
                    validation-name="Deliverable name"
                    placeholder="Type the deliverable name"
                    v-model="deliverable.name"
                />
            </div>

            <div class="field w-1/2">
                <div class="text-h6 font-bold">Requirements</div>
                <FormulateInput
                    type="checkbox"
                    v-model="deliverable.deliverables[0]"
                    value="concept_approval"
                    label="Concept required"
                />
                <FormulateInput
                    type="checkbox"
                    v-model="deliverable.deliverables[1]"
                    value="post_approval"
                    label="Draft required"
                />
            </div>

        </div>

        <div class="field w-full">
            <div class="text-h6 font-bold">Description</div>
            <FormulateInput
                type="textarea"
                placeholder="Type the description"
                class="w-full"
                :input-class="['description-textarea']"
                v-model="deliverable.description"
            />
        </div>
        
    </FormulateForm>

    <section class="deliverables">
        
        <div class="header">
            <div class="text-h2 font-bold text-purple-m-secondary">
                Deliverables
            </div>
            <div class="flex gap-x-6">
                <base-button 
                    @action="()=>null" 
                    class="font-bold" 
                    size="xlg" 
                    label="Add New Deliverable"
                    icon="plus"
                />
            </div>
        </div>

        <deliverable-type-select />

    </section>
</div>
</template>
<script>
import DeliverableTypeSelect from './DeliverableTypeSelect.vue'
export default {
    components: {
        DeliverableTypeSelect
    },
    data(){
        return {
            deliverable: {
                name: '',
                deliverables: ['concept_approval','draft_approval'], // requirements
                description: '',
                deliverable: null, // deliverable type
            }
        }
    },
    methods: {
        submit(){
            alert('Submit')
        }
    }
}
</script>
<style lang="scss" scoped>
    .form {
        @apply flex flex-col gap-y-16 px-12;
    }
    section.deliverables{
        @apply px-12;
        .header{
            @apply flex justify-between;
        }
    }
</style>
<style lang="scss">
    .description-textarea{
        min-height: 150px;
    }
</style>>
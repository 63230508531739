<template>
    <div class="w-full px-12 py-10 flex flex-col gap-4">
        <div v-for="user in users" :key="user.id">
            <div class="flex justify-between items-center cursor-pointer" @click="user.open = !user.open">
                <div class="flex gap-2 items-center">
                    <avatar :user="user"/>
                    <div class="text-sm font-bold">{{ user.name }} {{ user.last_name }}</div>
                </div>
                <div><base-icon :name="user.open ? 'chevron-up' : 'chevron-down'"/></div>
            </div>
            <div v-if="user.open" class="flex flex-col gap-4 my-4">
                <div class="bg-tan-m px-6 py-4 rounded flex justify-between" v-for="deliverable in user.deliverables" :key="deliverable.id">
                    <div class="flex gap-6 items-center">
                        <FormulateInput
                            type="checkbox"
                            v-model="deliverable.selected"
                            value="concept_approval"
                        />
                        <div class="relative">
                            <div class="w-8 h-8 bg-white rounded-full border border-gray-400 flex justify-center items-center">
                                <base-icon :size="6" name="video" />
                            </div>
                            <div
                                class="w-5 h-5 bg-black rounded-full absolute flex justify-center items-center"
                                style="right: -0.85rem;top: 0.425rem;"
                            >
                                <base-icon class="text-white" :size="3" name="instagram" />
                            </div>
                        </div>
                        <div class="font-bold">{{ deliverable.type }}</div>
                        <div>{{ deliverable.description }}</div>
                    </div>
                    <div>{{ deliverable.amount | numeral('$0,0') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            users: [
                {
                    id: 1,
                    name: 'Veronica',
                    last_name: 'Lodge',
                    avatar: null,
                    open: true,
                    deliverables: [
                        {
                            id: 1,
                            name: '',
                            type: 'Photograph',
                            description: 'Instagram-in-feed',
                            amount: 3000,
                            selected: false
                        },
                        {
                            id: 2,
                            name: '',
                            type: 'Photograph',
                            description: 'Instagram-in-feed',
                            amount: 3000,
                            selected: false
                        }
                    ],
                },
                {
                    id: 2,
                    name: 'Veronica',
                    last_name: 'Lodge',
                    avatar: null,
                    open: true,
                    deliverables: [
                        {
                            id: 3,
                            name: '',
                            type: 'Photograph',
                            description: 'Instagram-in-feed',
                            amount: 3000,
                            selected: false
                        },
                        {
                            id: 4,
                            name: '',
                            type: 'Photograph',
                            description: 'Instagram-in-feed',
                            amount: 3000,
                            selected: false
                        }
                    ],
                }
            ]
        }
    },
}
</script>

<template>
    <div
        class="info-item"
        :class="{ 'active': isSelected }"
        @click="$emit('toggle-info')"
    >
        <div class="content-wrapper">
            <div class="header">               
                <input
                    class="creatorsaurus-checkbox" 
                    type="checkbox" 
                    :checked="isSelected"
                />
                <div
                    class="exclusive" 
                    v-if="isValidArray(info.report_user_additional_info) && info.report_user_additional_info.filter(e => e.is_exclusive).length"
                >
                    <base-icon name="locked" />
                    <div class="tooltip">
                        This custom field is only shown on this report. 
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="name">{{info.name}}</div>
                <!-- link type -->
                <a 
                    v-if="info.type_detail.slug == 'link'" 
                    :href="info.value.split('|')[1]"
                    target="_blank"
                    class="value link"
                >
                    {{info.value.split('|')[0]}}
                </a>
                <!-- other values -->
                <div v-else class="value">{{ info.value }}</div>
            </div>
        </div>
        <div @click.stop class="actions">
            <options-dropdown               
                :options="[
                    {label: 'Edit', event: 'edit'},
                    {label: 'Delete', event: 'delete'}
                ]"
                :theme="isSelected ? 'dark' : 'light'"
                @edit="$emit('open-create-additional-info-modal')"
                @delete="$emit('delete-additional-info-intent')"
            />
        </div>
    </div>
</template>
<script>
    import { isValidArray } from '../../common';
    export default {
        name: 'CustomFieldsModalItem',
        props: ['info', 'isSelected'],
        data(){
            return {
                isValidArray
            }
        }
    }
</script>
<style lang="scss" scoped>
    .info-item{
        @apply py-3 px-6;
        width: 350px;
        @apply flex gap-x-10 justify-between items-center; 
        @apply cursor-pointer rounded-lg transition-colors duration-300;
        &.active{
            @apply bg-purple-m-main text-white;
        }
        > .content-wrapper{
            @apply w-full overflow-hidden flex flex-col; 
            
            > .header{
                @apply flex gap-x-3 mb-2 items-center;
                > .exclusive{
                    @apply relative cursor-pointer flex items-center;
                    &:hover{
                        > .tooltip{
                            @apply visible;
                        }
                    }
                    > .tooltip{
                        @apply absolute invisible;
                        @apply text-pxs bg-black text-white p-2 rounded-lg;
                        top: 10px;
                        width: 200px;
                    }
                }
            }

            > .content{
                @apply overflow-hidden flex flex-col;
                > .name{
                    @apply font-bold;
                }
                > .value{
                    text-overflow: ellipsis;
                    overflow: hidden;
                    &.link{
                        @apply font-bold text-blue-500 cursor-pointer;
                        &:hover{
                            @apply opacity-70;
                        }
                    }
                }
            }
        }
        > .actions{
            @apply invisible;
        }
        &:hover{
            @apply opacity-80;
            > .actions{
                @apply visible;
            }
        }
    }
</style>

<template>
    <div v-if="file" :class="`${containerClasses} media-viewer ${isPreview ? 'media-preview-container' : ''}`">
        <p v-if="fail" class="text-gray-headers text-center pt-16 w-4/5 mx-auto">We are getting the media for this post. Please, wait a moment to reload.</p>
        <video
            v-if="isVideo && !fail"
            ref="video"
            class="media"
            :class="{'media-preview': isPreview}"
            :autoplay="!isReport"
            @play="playing = true"
            @click="playing ? pause() : play()"
            @ended="$emit('videoEnded')"
        >
            <source :src="url" :type="type" @error="setFail">
            Your browser does not support the video tag.
        </video>
        <img v-if="isImage && !fail" class="media" :src="url" @error="setFail"/>

        <div v-if="controls.display" class="controls-container">
            <div :class="`icon ${controls.iconClasses}`">
                <base-icon :name="controls.icon" :size="10" iconClass="drop-shadow h-10 w-10 text-white" />
            </div>
        </div>
    </div>
</template>

<script>
import { getContentType } from '../../../lib/files'
import {prepareAsB64} from '../../../lib/strings';
export default {
    props: {
        file: {},
        containerClasses: {
            type: String,
            default: ''
        },
        isReport: {
            type: Boolean,
            default: false
        },
        viewOnly: {
            type: Boolean,
            default: false
        },
        isPreview: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        file(){
            const video = this.$refs.video;
            if (video) {
                video.currentTime = 0;
                video.load();
            }
        }
    },
    data(){
        return {
            playing: false,
            controls: {
                display: false,
                icon: 'play',
                iconClasses: null
            },
            fail: false
        }
    },
    methods: {
        play(){
            this.controls = { display: true, icon: 'play' }
            setTimeout(()=>this.controls = { display: true, icon: 'play', iconClasses: 'hide' }, 100) 
            setTimeout(()=>this.controls.display = false, 500) 

            document.getElementById(this.file.audioElementId)?.play()
            this.$refs.video.play()
            this.playing = true
        },
        pause(){
            this.controls = { display: true, icon: 'pause' }
            setTimeout(()=>this.controls = { display: true, icon: 'pause', iconClasses: 'hide' }, 100) 
            setTimeout(()=>this.controls.display = false, 500) 

            document.getElementById(this.file.audioElementId)?.pause()
            this.$refs.video.pause()
            this.playing = false
        },
        setFail() {
            this.fail = true;
            this.$emit('set-fail');
        }
    },
    computed: {
        // when we are on view only we are dealing with an attachment from the database with its signedUrl, mimetype, etc
        // but when we are NOT on view only we are dealing with a local File object with its type, etc.
        url(){
            if(this.viewOnly) return this.file.signedUrl
            let url;
            try {
                url = URL.createObjectURL(this.file);
            } catch (e) {
                url = this.file.url || '';
            }

            return prepareAsB64(url);
        },
        isImage(){
            if(this.viewOnly) return getContentType(this.file.mimetype) == 'image';
            
            if ( typeof this.file != "undefined" ) {
                return getContentType(this.file.type) == 'image';
            } else {
                console.warn('Missing file.type Please fix if you see this!!!!!!!');
                return null;
            }
        },
        isVideo(){
            if(this.viewOnly) return getContentType(this.file.mimetype) == 'video';
            
            if ( typeof this.file != "undefined" ) {
                return getContentType(this.file.type) == 'video';
            } else {
                console.warn('Missing file.type Please fix if you see this!!!!!!!');
                return null;
            }
        },
        type(){
            if(this.viewOnly) return this.file.mimetype
            if(this.file.type === 'video/quicktime') return 'video/mp4'
            return this.file.type
        },
        isPlaying(){
            const video = this.$refs.video
            return !video.paused
        }
    }
}
</script>

<style lang="scss" scoped>
    .media-viewer{
        align-items: stretch;
        flex-direction: column;
        // position: relative;
        display: block;
        overflow: hidden;
        padding-bottom: 66.2037%;
        & .media{
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            object-fit: cover;

            &.media-preview {
                position: unset !important;
                height: 25rem;
            }
        }

        &.media-preview-container {
            padding-bottom: 0 !important;
        }

        & .controls-container{
            @apply w-full h-full absolute flex justify-center items-center;
            & .icon{
                opacity: 1;
                transition: opacity 0.5s ease-in-out;
                &.hide{
                    opacity: 0;
                }
            }
        }
    }
</style>
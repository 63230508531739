<template>
    <div class="landing-site">
    <div class="wrapper">
        <header class="header">
            <div class="shell flex justify-between items-center">

                <div>
                    <button class="btn form__btn invisible" type="button" @click="redirectLogin">Login</button>
                </div>
                <div class="text-center">
                    <a href="#" class="logo"><img :src="logo" alt=""></a>
                </div>
                <div>
                    <button class="btn form__btn" type="button" @click="redirectLogin">{{ isLogged ? 'Go Home' : 'Login' }}</button>
                </div>
            </div>
        </header>

        <div class="main">
            <section class="section">
                <div class="shell">
                    <div class="section__inner">
                        <header class="section__head">
                            <h2>A breakthrough app for wrangling cats (aka Content Creators)</h2>

                            <p>Unleash Creative Power with One-Stop Management for Projects & People!</p>

                            <div class="form" v-if="!showMessage">
                                <input class="field" :disabled="sending" type="email" v-model="email" @keyup="showError = false" @keyup.enter="saveEmail" placeholder="Email Address">

                                <button :disabled="sending" class="btn form__btn" type="button" @click="saveEmail">Stay in the loop</button>
                            </div>
                            <p v-if="showError" class="pt-2 text-white text-2xl font-bold">Please insert a valid email</p>
                            <p v-if="showMessage" class="pt-2 text-green-200 text-2xl">{{ message }}</p>
                        </header>

                        <div class="section__body">
                            <div class="dashboard">
                                <img :src="dashboard" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="link-cont">
            <a target="_blank" href="/privacy-policy" class="link">Privacy Policy</a>
            <a target="_blank" href="/terms-of-service" class="link">Terms of Service</a>
        </div>

    </div>
    </div>
</template>

<script>

import dashboard from '../../assets/images/dashboard.png';
import logo from '../../assets/images/logo.svg';
import { validateEmail } from '../../lib/strings';
import { mapGetters } from 'vuex';

export default {
    components: {},
    data() {
        return {
            dashboard: dashboard,
            logo: logo,
            htmlItem: null,
            bodyItem: null,
            email: '',
            showError: false,
            sending: false,
            showMessage: false,
            message: ''
        }
    },

    computed: {
        ...mapGetters(['user']),
        isLogged() {
            return !!this.user.id;
        }
    },

    mounted() {
        let html = document.getElementsByTagName('html');
        let body = document.getElementsByTagName('body');
        if (html.length) {
            this.htmlItem = html[0];
            this.htmlItem.classList.add('landing-font-size');
        }

        if (body.length) {
            this.bodyItem = body[0];
            this.bodyItem.classList.add('landing-body');
        }
    },

    beforeDestroy() {
        this.removeLandingClasses();
    },

    beforeRouteLeave() {
        this.removeLandingClasses();
    },

    methods: {
        removeLandingClasses() {
            this.htmlItem.classList.remove('landing-font-size');
            this.bodyItem.classList.remove('landing-body');
        },

        redirectLogin() {
            location.href = this.isLogged ? '/home' : '/login';
        },

        async saveEmail() {
            if (this.email !== '' && validateEmail(this.email) && !this.sending) {
                this.sending = true;
                this.showError = false;
                try {
                    const {data} = await this.$http.post(`/api/auth/registerWaiting`, {
                        email: this.email
                    });

                    if (data) {
                        this.showMessage = true;
                        this.message = data.message;
                    }
                } catch (e) {
                    this.showError = true;
                } finally {
                    this.sending = false;
                }
            } else {
                this.showError = true;
            }
        }
    }
}
</script>

<style lang="scss">
    .link-cont {
        position: fixed;
        bottom: 20px;
        left: 30px;

        .link {
            font-size: 12px;
            text-decoration: none !important;
            margin-right: 30px !important;
            text-shadow: 1px 1px 1px #333;
            color: #fff;
        }
    }

    @import "../../assets/bundle";
</style>
<template>
    <div v-if="user && user.id !== 0">
        <audience-data :user="user"/>
    </div>
</template>
<script>
import AudienceData from './AudienceData.vue';
export default {
    props: ['user'],
    components: {
        AudienceData
    },
    data(){
        return {
           
        }
    },
    methods: {

    }
}
</script>
<style lang="scss" scoped>

</style>

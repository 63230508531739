<template>
  <!-- most of the times we are using report-post-card (creators and posts report) -->
  <div class="w-80 gap-3 flex flex-col flex-no-wrap rounded overflow-hidden break-inside">
    <component
      :is="component"
      :files="postMedia"
      :description="description"
      :postedBy="postedBy"
      :postedAt="postedAt"
      :page="page"
      :container="false"
      :class="fail ? 'fail-media' : ''"
      :key="`item_${post.id}_${itemKey}`"
      @navigate="(option) => option == 'prev' ? page-- : page++"
      @resize="$emit('redraw')"
      @set-fail="setFail"
      :is-report="true"
      :print-view="printView"
      :post-item="post"
      :is-preview="isPreview"
    />

    <div v-if="fail">
      <base-button @action="reloadPost" class="w-full" bold label="Reload content"/>
    </div>

    <report-post-bottom
      :post="post"
      :print-view="printView"
      :hide-stats="hideStats"
      :show-boosted-data="showBoostedData"
      :allow-edit="allowEdit"
      :allow-replace="allowReplace"
      :is-preview="isPreview"
      :allow-unlink="allowUnlink && $listeners.unlink !== undefined && post.project_user_deliverable_posts == null"
      :deliverables="deliverables"
      :current-reports="currentReports"
      @link="$emit('link')"
      @replace-post="(p) => $emit('replace-post', p)"
      @edit-post="(p) => $emit('edit-post', p)"
      @unlink="(postId) => $emit('unlink', postId)"
      @attach="(reportId, postId) => $emit('attach', reportId, postId)"
    />
  </div>
</template>

<script>
import {
  BlogPost,
  FacebookPost,
  TwitterPost,
  InstagramPost,
  InstagramReel,
  InstagramStory,
  PinterestPost,
  TiktokVideo,
  YoutubeVideo,
  LinkedinPost
} from '../views/VersionCreate/NetworkComponents/index';
import ReportPostBottom from './ReportPostBottom';
import moment from 'moment';

export default {
    props: {
      post: {
        type: Object,
        required: true
      },
      printView: {
        type: Boolean,
        default: false
      },
      hideStats: {
        type: Boolean,
        default: false
      },
      showBoostedData: {
        type: Boolean,
        default: false
      },
      allowEdit: {
        type: Boolean,
        default: false
      },
      allowReplace: {
        type: Boolean,
        default: false
      },
      allowUnlink: {
        type: Boolean,
        default: true
      },
      isPreview: {
        type: Boolean,
        default: false
      },
      isReport: {
        type: Boolean,
        default: false
      },
      deliverables: {
        type: Array,
        default: function () {
          return []
        }
      },
      currentReports: {
        type: Array,
        default: function () {
          return []
        }
      }
    },

    components: {
      BlogPost, FacebookPost, TwitterPost, InstagramPost, InstagramReel, InstagramStory, PinterestPost, TiktokVideo, YoutubeVideo, LinkedinPost,
      ReportPostBottom
    },
    
    computed: {
      description() {
        if (this.post.description && this.post.description !== '') {
          return this.post.description;
        }

        return this.post.caption;
      },

      component() {
        let { name } = this.post.network;
        switch (name) {
          case 'instagram':
            return 'instagram-reel';
          case 'tiktok':
            return 'tiktok-video';
          case 'facebook':
            return 'facebook-post';
          case 'twitter':
            return 'twitter-post';
          case 'pinterest':
            return 'pinterest-post';
          case 'youtube':
            return 'youtube-video';
          case 'linkedin':
            return 'linkedin-post';
        }

        return 'blog-post';
      },

      postedBy() {
        let { profile_picture_url, full_name, username, social_id } = this.post.profile_item;
        return {
          id: social_id,
          name: full_name !== '' ? full_name : username,
          avatar: profile_picture_url,
          handle: username
        }
      },

      postedAt() {
        let date = new Date(this.post.date);
        let now = new Date();
        now.setFullYear(now.getFullYear() - 10);
        if (date < now) {
          date = this.post.created_at;
        }
        return moment(date).format('MMMM DD, YYYY [at] h:mm a')
      },

      postMedia() {
        return this.post.media.map(media => {
          media.type = media.mimetype;
          return media;
        })
      }
    },

    data() {
        return {
          page: 0,
          itemKey: false,
          fail: false
        }
    },
    methods: {
      openLink(url) {
        window.open(url);
      },
      reloadPost() {
        this.fail = false;
        this.itemKey = !this.itemKey;
      },
      setFail() {
        this.fail = true;
        this.$emit('redraw');
      }
    }
}
</script>

<style lang="scss">
.post-info-container {
  min-height: 11rem;
}

.caption-wrapper {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  transition: max-height 3s cubic-bezier(0, 1, 0, 1);
}
</style>

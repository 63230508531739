<template>    
    <nav :class="`flex gap-x-2 ${navClass}`" :style="navStyles">
        <button 
            v-for="(tab, i) in computedTabs" :key="i"
            class="tab"
            :class="[
                theme, 
                { 'selected': isSelected(tab.name) },
                { 'capitalize': capitalize}, 
                customClass,
                { 'cursor-not-allowed': !tab.active }
            ]"
            :style="styles"
            :disabled="!tab.active"
            @click.prevent="selectTab(tab)"
        >
            <h3 :class="`h5 font-bold ${h3Class}`">{{ fixTab(tab.name) }}</h3>
        </button>
    </nav>
</template>
<script>
export default {
    props: {
        tabs: {
            type: Array,
            required: true
        },
        value: {
            type: String            
        },
        theme: {
            type: String,
            default: 'light'
        },
        capitalize: {
            type: Boolean,
            default: false
        },
        customClass: {
            type: String,
            default: ''
        },
        styles: {
            type: String,
            default: '' 
        },
        navClass: {
            type: String,
            default: ''
        },
        navStyles: {
            type: String,
            default: ''
        },
        h3Class: {
            type: String,
            default: ''
        }
    },
    computed: {
        computedTabs() {
            return this.tabs.map(tab => {
                if(Object.prototype.toString.call(tab) == '[object String]') {
                    return {
                        name: tab,
                        active: true
                    }
                }
                return tab;
            })
        }
    },
    methods: {
        // 'Files (2)' => 'files'
        formatStr(str){
            return str.split('(')[0].trim().toLowerCase()
        },
        isSelected(tab){    
            return this.formatStr(this.value) == this.formatStr(tab)
        },
        selectTab(tab) {
            if (tab.active) {
                this.$emit('input', tab.name);
            }
        },
        fixTab(tab) {
            return tab.replaceAll('-', ' ');
        }
    }
}
</script>
<style lang="scss" scoped>
    .tab{
        @apply w-auto pb-6 text-h6 font-bold mr-10 border-b-2 border-transparent transition-colors duration-150;

        &:focus{
            @apply outline-none;
        }
        
        &.light {
            @apply text-purple-m-secondary;

            &.hover{
                @apply border-purple-m-secondary;
            }

            &.selected{
                h3 {
                    color: black
                }
                @apply text-green-m-main border-green-m-main;
            }
        } 

        &.dark {
            @apply text-white;

            &:hover{
                @apply border-green-m-main-dark;
            }

            &.selected{
                @apply text-green-m-main border-green-m-main;
            }
        }
    }
</style>
<template>
    <div class="create-report-influencer-list">
        
        <div  
            v-for="(influencer, index) in computedInfluencers" 
            :key="index"
            :class="['item', { 'selectable': select }, { 'direct': direct }]"
        >

            <div class="general-info">

                <div class="checkbox-container" v-if="select && !direct" @click="selectInfluencer(influencer)">
                    <FormulateInput 
                        disabled 
                        type="checkbox" 
                        v-model="influencer.selected"
                        label-class="cursor-pointer"
                    />
                </div>
                
                <!-- avatar -->
                <div class="avatar-container" @click="selectInfluencer(influencer)">
                    <avatar 
                        :class="select ? '' : 'ml-4'"
                        :user="influencer.user" 
                    />
                </div>
                
                <!-- content -->
                <div class="content">

                    <div class="left">
                        <div class="name" @click="selectInfluencer(influencer)">
                            {{displayName(influencer)}}
                        </div>
                        <div class="email" @click="selectInfluencer(influencer)">
                            {{influencer.user.email}}
                        </div>
                    </div>

                    <div class="right">
                        <div class="reach">
                            <base-icon name="reach"/>{{ influencer.followers | numeral('0a') }}
                        </div>
                        
                        <!-- likes, not showing them for now -->
                        <!--<div class="flex gap-1 items-center"><base-icon name="like"/>{{ influencer.likes | numeral('0a') }}</div>-->

                        <div class="social-accounts">

                            <!-- create report modal -->
                            <template v-if="!direct && influencer.user.social_accounts && influencer.user.social_accounts.length">
                                
                                <div class="content" v-if="currentLabel(influencer) !== null">
                                    
                                    <!-- list -->
                                    <div class="list">
                                        <div 
                                            v-for="platform in currentLabel(influencer)" 
                                            :key="`u_icon_${platform}`"
                                            class="item"
                                        >
                                            <!-- exclamation icon for ig or tiktok -->
                                            <div 
                                                v-if="showExclamationIcon(platform, influencer.user)"
                                                class="exclamation-icon" 
                                            >
                                                <base-icon
                                                    name="exclamation-circle"
                                                    :class="platform == 'instagram' && !isBusinessAccount(influencer.user) ? 'text-red-400' : 'text-orange-400'"
                                                    :size="3"
                                                />
                                            </div>

                                            <!-- platform icon -->
                                            <base-icon
                                                :name="platform"
                                                @action="handlePlatformEvent(platform, influencer)"
                                                v-tooltip="platformTooltip(platform, influencer)"
                                            />

                                        </div>

                                        <!-- no platform selected -->
                                        <p v-if="!currentLabel(influencer).length">Select a platform</p>

                                        <!-- platform selection toggle chevron     -->
                                        <base-icon v-if="toggle" @action="setPlatformSelector(influencer.id)" :name="influencer.open ? 'chevron-up' : 'chevron-down'" :size="4"/>
                                    
                                    </div>

                                    <!-- platform selection -->
                                    <div
                                        class="platform-selection"
                                        :class="influencer.new ? 'p-4' : ''"
                                        v-if="influencer.open"
                                    >
                                        <template v-if="!influencer.new">

                                            <template v-for="network in availableModalNetworks">
                                                <div
                                                    class="platform-item"
                                                    v-if="currentInfluencer(influencer, network.name) !== undefined"
                                                    :key="`${network.id}_${influencer.id}`"
                                                    @click="toggleNetwork(influencer, network.name)"
                                                >
                                                    <div>{{ network.display_name }}</div>
                                                    <toggle-button :value="currentInfluencer(influencer, network.name)" :disabled="!toggle" @toggled="toggleNetwork(influencer, network.name)" />
                                                </div>
                                            </template>

                                            <div class="add-new-network" @click="setNewPlatform(influencer, true)">
                                                <div>Add New</div>
                                                <base-icon name="plus" :size="3"/>
                                            </div>
                                        </template>

                                        <!-- new network -->
                                        <div class="new-network" v-if="influencer.new">
                                            <network-select
                                                :values="currentHandle"
                                                :excluded-networks="currentExcludedNetworks"
                                                :container-class="'flex flex-col gap-2'"
                                                item-class="flex flex-col gap-2 relative"
                                                minimized
                                                element-class="field w-full"
                                                handle-class="field"
                                                inner-class="field relative"
                                                :key="`new_platform_${influencer.new}_${influencer.id}`"
                                                @cancel="setNewPlatform(influencer, false, false)"
                                                @accept="setNewPlatform(influencer, false, true)"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div v-else class="empty-state">No social media platforms added</div>

                                <div 
                                    class="no-options"
                                    v-if="!toggle && !currentInfluencer(influencer, 'youtube') && !currentInfluencer(influencer, 'instagram') && !currentInfluencer(influencer, 'tiktok')"
                                >
                                    No added social media platforms are available
                                </div>

                            </template>

                            <!-- report edit/create -->
                            <template v-if="direct">
                                
                                <div v-if="creatorHasNetwork(influencer, 2)" class="flex flex-col items-center justify-center">
                                    <toggle-button
                                        :value="creatorNetworkActive(influencer, 'instagram')" 
                                        @toggled="toggleCreatorNetwork(influencer, 'instagram')" 
                                    />
                                    <base-icon name="instagram" />
                                </div>

                                <div v-if="creatorHasNetwork(influencer, 14)" class="flex flex-col items-center justify-center">
                                    <toggle-button
                                        :value="creatorNetworkActive(influencer, 'tiktok')" 
                                        @toggled="toggleCreatorNetwork(influencer, 'tiktok')" 
                                    />
                                    <base-icon name="tiktok" />
                                </div>

                                <base-button 
                                    :disabled="disabled" 
                                    label="Add to Report" 
                                    @action="$emit('direct-select', influencer)"
                                />
                            </template>

                        </div>
                    </div>

                </div>
                
                <!-- description is a boolean that sets if we want to show/edit the description and custom fields or not -->
                <!-- TODO: use this button to expand or collapse -->
                <!-- 
                <base-button
                    v-if="description"
                    label="Description & Custom fields"
                    size="2xl"
                    bold
                    secondary-icon="chevron-down"
                    @action="() => $emit('toggle-show-influencer-description-and-custom-fields', !!influencer.showDescriptionAndCustomFields, influencer.id)"
                /> -->

                <div class="actions" v-if="remove">
                    <base-button type="label" label="Remove" @action="removeInfluencer(influencer.user.id)"/>
                </div>
            </div>

            <!-- TODO: be able to expand and collapse this section -->
            <div class="description-and-custom-fields" v-if="description">
                    
                <div>
                    <div class="text-h5 font-bold">Description</div>
                    <!-- description is a boolean that sets if we want to show/edit the description and custom fields or not -->
                    <div class="description">
                        <FormulateInput
                            type="textarea"
                            placeholder="Description..."
                            @keyup="(e) => setDescription(e, influencer.id)" 
                            :value="currentInfluencer(influencer).description"
                            class="w-full mt-2"
                            input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-12 text-pxs"
                            :rows="1"
                        />
                    </div>
                </div>
                
                <!--
                TODO: enable this section 
                    <create-report-influencer-list-custom-fields
                    v-if="influencer && influencer.user"
                    :user="influencer.user"
                    @change="(additional_info) => $emit('set-custom-fields', influencer.id, additional_info)" 
                /> -->

            </div>
      
        </div>

        <send-invite-modal
            @close="cancelInvite"
            :visible="platformIntent.show"
            :user="platformIntent.user"
            :networkLabel="platformIntent.networkLabel"
            :networkId="platformIntent.networkId"
        />
    </div>
</template>
<script>
import CreateReportInfluencerDescription from './CreateReportInfluencerDescription.vue';
import SendInviteModal from './SendInviteModal.vue';
import { isArray, NETWORKS } from '../common';
import NetworkSelect from './NetworkSelect';
import CreateReportInfluencerListCustomFields from './CreateReportInfluencerListCustomFields.vue';
export default {
    components: {
        NetworkSelect,
        CreateReportInfluencerDescription,
        SendInviteModal,
        CreateReportInfluencerListCustomFields
    },

    props: {
        influencers: {},
        select: {},
        toggle: {},
        remove: {},
        // description is a boolean that sets if we want to show/edit the description and custom fields or not
        description: {},
        selected: {},
        direct: {},
        disabled: {},
        toggledElement: {
            type: Object,
            default: function () {
                return null;
            }
        }
    },

    data() {
        return {
            networks: NETWORKS,
            currentHandle: {},
            currentExcludedNetworks: [],
            platformIntent: {
                show: false,
                user: null,
                networkId: null,
                networkLabel: null
            }
        }
    },

    watch: {
        toggledElement(val) {
            if (val) {
                this.selectInfluencer(val);
            }
        }
    },

    computed: {
        availableModalNetworks() {
            let availables = [];
            for (let i in this.networks) {
                availables.push({
                    id: parseInt(i),
                    name: this.networks[i].name,
                    display_name: this.networks[i].display_name,
                })
            }
            let networks = ['instagram', 'tiktok', 'youtube'];
            return availables.filter(n => networks.includes(n.name));
        },

        computedInfluencers() {
            return this.influencers.map(user => {
                let followers = 0;
                let likes = 0;
                if (user.user.audience_data || user.user.social_accounts) {
                    let audiences = user.user.audience_data.length ? user.user.audience_data : user.user.social_accounts;
                    audiences = audiences ? audiences : [];
                    for (let audience of audiences) {
                        followers = followers + (audience.followers || audience.reach || 0);
                        likes = likes + (audience.likes || audience.avg_likes || 0);
                    }
                }
                user.followers = followers;
                user.likes = likes;
                return user;
            })
        },
    },

    methods: {
        handlePlatformEvent(platform, influencer) {
            let platformNumber = null;
            let platformName = null;

            if (platform == 'instagram') {
                platformNumber = 6;
                platformName = 'Facebook';
            }

            if (platform == 'tiktok') {
                platformNumber = 14;
                platformName = 'Tiktok';
            }

            if (platformNumber !== null && platformName !== null && !this.loggedInWithPlatform(influencer.user, platformNumber)) {
                return this.inviteToLoginWithPlatformIntent(influencer.user, platformNumber, platformName);
            }

            return null;
        },

        platformTooltip(platform, influencer) {
            let classes = 'bg-white rounded py-1 px-2 text-xs';
            let placement = 'left';
            let content = this.getHandle(influencer, platform) ? `<div><strong>Handle: ${this.getHandle(influencer, platform)}</strong></div>` : ``;

            let loggedInMsg, notLoggedInMsg, connectedByFbBusinessManagerMsg;

            if (platform == 'instagram') {
                if(!this.isBusinessAccount(influencer.user)) content += `<div>An Instagram business or creator account is required to get data from this user.</div>`;
                loggedInMsg = `<div>Logged in with facebook</div>
                            <div>All insights available</div>`;
                notLoggedInMsg = `<div>Not logged in with facebook</div>
                            <div>Only basic insights available</div>
                            <div>Click to invite them to connect their account</div>`;
                connectedByFbBusinessManagerMsg = `<div>Not logged in with facebook but connected by FB Business Manager</div>
                                                  <div>Insights available</div>
                                                  <div>Click to invite them to connect their account</div>`
                
                const isLoggedIn = this.loggedInWithPlatform(influencer.user, 6);
                const isConnectedByFbBusinessManager = this.isConnectedByFbBusinessManager(influencer.user);
                content += isLoggedIn ? loggedInMsg : isConnectedByFbBusinessManager ? connectedByFbBusinessManagerMsg : notLoggedInMsg;
            }

            if (platform == 'tiktok') {
                loggedInMsg = `<div>Logged in with tiktok</div>
                            <div>All insights available</div>`;
                notLoggedInMsg = `<div>Not logged in with tiktok</div>
                            <div>Click to invite them to connect their account</div>`;
                            
                content += !this.loggedInWithPlatform(influencer.user, 14) ? notLoggedInMsg : loggedInMsg;
            }

            return { html: true, classes, placement, content };
        },

        setDescription(e, id) {
            if (this.description) {
                this.$emit('set-description', {id, description: e.target.value});
            }
        },

        setPlatformSelector(id) {
            if (this.toggle) {
                this.$emit('set-platform-selector', id);
            }
        },

        setNewPlatform(influencer, val, accept = true) {
            if (this.toggle) {
                let handle = {};
                let excluded = [];
                if (val && accept) {
                    let used = influencer.user.audience_data.map(n => n.network_id);
                    excluded = this.availableModalNetworks.filter(n => {
                        return used.includes(n.id);
                    });
                    this.availableModalNetworks.filter(n => {
                        return !used.includes(n.id);
                    }).forEach(n => {
                        handle[`${n.name}_handle`] = '';
                    });
                } else {
                    if (accept) {
                        this.$emit('set-handles', influencer, this.currentHandle, this.availableModalNetworks);
                    }
                }
                this.currentHandle = handle;
                this.currentExcludedNetworks = excluded;
                this.$emit('set-new-platform', influencer.id, val);
            }
        },

        currentLabel(influencer) {
            let igValue = this.currentInfluencer(influencer, 'instagram');
            let tiktokValue = this.currentInfluencer(influencer, 'tiktok');
            let youtubeValue = this.currentInfluencer(influencer, 'youtube');

            let hasIg = igValue !== undefined;
            let hasTiktok = tiktokValue !== undefined;
            let hasYoutube = youtubeValue !== undefined;

            let igSelected = !!igValue;
            let tiktokSelected = !!tiktokValue;
            let youtubeSelected = !!youtubeValue;

            let platforms = [];
            if (igSelected) {
                platforms.push('instagram');
            }
            if (tiktokSelected) {
                platforms.push('tiktok');
            }
            if (youtubeSelected) {
                platforms.push('youtube');
            }

            if (hasIg || hasTiktok || hasYoutube) {
                return platforms;
            }

            return null;
        },

        getHandle(influencer, platform){
            const { social_accounts } = influencer.user
            const found = social_accounts.filter(e => e.network).find(element => element.network.name == platform )
            if(found){
                return found.handle
            }
            return false
        },

        selectInfluencer(influencer) {
            this.$emit('set-platform-selector', null);
            if (this.select) {
                this.$emit('select-influencer', influencer);
            }
            this.$forceUpdate();
        },

        toggleNetwork(influencer, network) {
            this.$emit('toggle-network', influencer.user.id, network);
        },

        removeInfluencer(influencerId) {
            if (this.remove) {
                this.$emit('remove-influencer', influencerId);
            }
        },

        displayName(influencer) {
            // use company name if it exists
            let useCompanyName = influencer.user.user_companies && influencer.user.user_companies[0] && (influencer.user.user_companies[0].first_name && influencer.user.user_companies[0].last_name)
            return useCompanyName ? `${influencer.user.user_companies[0].first_name} ${influencer.user.user_companies[0].last_name}` : `${influencer.user.first_name} ${influencer.user.last_name}`
        },

        currentInfluencer(user, key, type = 'undefined') {
            if (user) {
                if (!key) {
                    return user;
                }

                if (user[key] !== undefined) {
                    return user[key];
                }
            }

            if (!key) {
                return {};
            }
            if (type === 'boolean') {
                return false;
            }

            if (type === 'null') {
                return null;
            }
            return undefined;
        },

        loggedInWithPlatform(user, network_id){
            const found = user.social_logins?.find( socialLogin => socialLogin.network_id == network_id ) || null;
            return found && (found.access_token || found.long_lived_access_token)
        },

        isConnectedByFbBusinessManager(user){
            const found = user.social_logins?.find(socialLogin => socialLogin.network_id == 6) || null;
            return found && found.fb_business_token
        },

        isBusinessAccount(user){
            const { social_accounts } = user
            const found = social_accounts.filter(e => e.network).find(element => element.network_id == 2)
            if(found && found.is_business !== null && (found.is_business == false || found.is_business == 0)){
                return false
            }
            return true
        },

        showExclamationIcon(platform, user){
            if(platform == 'instagram'){
                return this.loggedInWithPlatform(user, 6) ? false : this.isConnectedByFbBusinessManager(user) ? false : true;
            }
            if(platform == 'tiktok'){
                return !this.loggedInWithPlatform(user, 14)
            }
        },

        cancelInvite() {
            this.platformIntent = {
                show: false,
                user: null,
                networkId: null,
                networkLabel: null
            };
        },

        inviteToLoginWithPlatformIntent(user, networkId, networkLabel){
            this.platformIntent = {
                show: true,
                user,
                networkId,
                networkLabel
            };
        },

        creatorHasNetwork(influencer, network_id){
            if(influencer && influencer.user && influencer.user.social_accounts && isArray(influencer.user.social_accounts)){
                const found = influencer.user.social_accounts.find(element => element.network_id == network_id);
                if(found){
                    return true;
                }
            }
            return false;
        },
        
        creatorNetworkActive(influencer, key){
            return influencer[key];
        },
        
        toggleCreatorNetwork(influencer, key){

            this.$emit('toggle-creator-network', influencer, key);
            setTimeout(() => {
                this.$forceUpdate();
            }, 500)
        }

    }
}
</script>
<style lang="scss" scoped>
    .create-report-influencer-list{
        @apply w-full h-full;
        > .item{
            @apply px-4;
            &.selectable{
                @apply cursor-pointer rounded-lg;
                &:hover{
                    @apply bg-gray-200;
                }
            }
            &.direct{
                @apply pl-4;
            }

            > .general-info{
                @apply flex justify-start items-center gap-4 h-20;
                
                > .checkbox-container{
                    @apply w-6 h-full flex items-center justify-center;
                }

                > .avatar-container{
                    @apply h-full flex items-center;
                }

                > .content{
                    @apply flex-1 h-full flex w-full justify-between items-center h-full gap-6;
                    > .left{
                        > .name{
                            @apply font-semibold text-lg text-black w-full h-full flex items-center;
                        }
                        > .email{
                            @apply text-pxs text-gray-500;
                        }
                    }
                    > .right{
                        @apply flex gap-x-4 items-center;
                        > .reach{
                            @apply flex gap-1 items-center;
                        }
                        > .social-accounts{
                            @apply flex text-sm gap-x-4 items-center font-medium;
                            > .content{
                                @apply relative text-right;
                                > .list{
                                    @apply flex gap-3 items-center font-bold relative;
                                    .item{
                                        @apply relative flex;
                                        > .exclamation-icon{
                                            @apply absolute;
                                            top: -9px; 
                                            left: -6px;
                                        }
                                    }
                                }
                                > .platform-selection{
                                    @apply absolute bg-white right-0 z-50 w-60 rounded-xl border shadow-xl flex flex-col;
                                    > .platform-item{
                                        @apply flex justify-between gap-2 p-4 border-b text-left items-center;
                                        &:hover{
                                            @apply bg-gray-200;
                                        }
                                    }
                                    > .add-new-network{
                                        @apply flex justify-between p-4 rounded-b-lg items-center;
                                        &:hover{
                                            @apply bg-gray-200;
                                        }
                                    }
                                    > .new-network{
                                        @apply cursor-default text-left;
                                    }
                                }
                            }
                            > .empty-state{
                                @apply flex flex-col text-gray-m-disable;
                            }
                            > .no-options{
                                @apply flex flex-col text-gray-m-disable;
                            }
                        }
                    }
                }

                > .description{
                    @apply flex-1 mr-4;
                }
                
                > .actions{
                    @apply ml-auto my-auto mr-4;
                }
            }

            > .description-and-custom-fields{
                @apply p-6 flex flex-col gap-y-6;
            }

        }
    }
</style>


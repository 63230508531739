<template>
    <div>
        <template v-if="clientPages.length">
            <div class="flex gap-x-8 items-center">

                <div class="flex justify-between">
                    <div class="text-h2 font-bold text-purple-m-secondary">
                        Client pages ({{ clientPages.length }})
                    </div>    
                </div>
                
                <base-icon 
                    v-if="loading"
                    name="loader" 
                />

            </div>

            <div class="client-pages-list">
                <div class="item" v-for="(clientPage, index) in clientPages" :key="index">
                    <div class="container">
                        <div>Page ID: <span class="font-bold">{{clientPage.id}}</span></div>
                        <div class="permitted-task-list">
                            <div class="permitted-task" v-for="(permittedTask, index) in clientPage.permitted_tasks" :key="`pt-${index}`">
                                {{ permittedTask }}
                            </div>
                        </div>
                    </div>
                    <div class="delete">
                        <base-button 
                            type="label"
                            icon="trash"
                            @action="deleteClientPageIntent(clientPage.id)"
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            clientPages: [],
            loading: false
        }
    },
    mounted() {
        this.getClientPages()
    },
    computed: {
        ...mapGetters(['company']),
    },
    methods: {
        async getClientPages(){
            this.loading = true
            this.$http.get('/api/facebook', {
                params: {
                    entity_id: this.company.fb_business_id,
                    edge: 'client_pages',
                    fields: ['permitted_tasks'],
                    user_access_token: true
                }
            }).then(async (res) => {
                if(res.data){
                    this.clientPages = res.data
                }
            }).catch((err) => {
                console.log('getClientPages error', err)
            }).finally(() => {
                this.loading = false
            })
        },
        
        async deleteClientPageIntent(pageId){
            this.$swal.fire({
                title: '¿ Are you sure you want to dissociate this client page from the business ?',
                text: 'You can link it again in the future',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                reverseButtons: true,
            }).then((result) => {
                if(result.isConfirmed){
                    this.deleteClientPage(pageId)
                }
            })
        },

        async deleteClientPage(pageId){
            this.loading = true
            this.$http.delete('/api/facebook/client-pages', { 
                data: { 
                    page_id: pageId 
                } 
            }).then(async (res) => {
                if(res.data){
                    this.$notify({title: 'Page dissociated successfully', text: '', type: 'success'})
                    this.getClientPages()
                }
            }).catch((err) => {
                console.log('deleteClientPage error', err)
            }).finally(() => {
                this.loading = false
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .client-pages-list{
        @apply flex flex-col mt-6 gap-y-4 items-start;
        .item{
            @apply flex w-full;
            .container{
                @apply flex-grow flex-shrink px-6 py-2 border border-black rounded-xl;
                .permitted-task-list{
                    @apply flex w-full mt-2 gap-x-2;
                    .permitted-task{
                        @apply rounded-full bg-blue-500 px-4 py-2 text-white text-pxs;
                    }
                }
            }
            .delete{
                @apply flex-grow-0 flex-shrink-0 flex justify-center items-center px-4;
            }
        }
    }
</style>
<template>
    <div class="container">

        <section class="report-settings bordered">

            <div class="title">
                Overview
            </div>
            <div class="subtitle">
                The report will be created with this information:
            </div>

            <div class="content">
                <div class="field">
                    <div class="label">Report name</div>
                    <div class="value">{{name}}</div>
                </div>
                
                <!-- static -->
                <div class="field">
                    <div class="label">Report type</div>
                    <div class="value">Creators</div>
                </div>

                <div class="field">
                    <div class="label">Project</div>
                    <div class="value">{{projectName}}</div>
                </div>

                <div class="field" v-if="tags && tags.length">
                    <div class="label">Tags</div>
                    <div class="value">{{tags.map(e => e.label).join(', ')}}</div>
                </div>

                <div class="field">
                    <div class="label">Description</div>
                    <div class="text enriched-format" v-html="description"/>
                </div>
            </div>

        </section>

        <section class="settings bordered">
            <div class="title">
                Settings
            </div>
            <div class="content">

                <div class="field">
                    <div class="label">Posts limit</div>
                    <div class="value">{{settings.posts_limit.label}}</div>
                </div>

                <div v-if="passwordProtected" class="field">
                    <div class="label">Password protection</div>
                    <div class="value">The report will be password protected</div>
                </div>

                <div v-if="settings.allow_feedback" class="field">
                    <div class="label">Feedback</div>
                    <div class="value">Creator feedback is enabled for this report</div>
                </div>

                <div v-if="settings.show_boosted_data" class="field">
                    <div class="label">Boosted data</div>
                    <div class="value">The report will show organic + boosted stat breakdowns and post ads</div>
                </div>

                <div v-if="settings.hide_stats" class="field">
                    <div class="label">Hide stats</div>
                    <div class="value">Individual post stats will be hidden</div>
                </div>
                
                <div v-if="settings.preview" class="field">
                    <div class="label">Preview</div>
                    <div class="value">The report will show users that don't have audience data</div>
                </div>

                <div class="field">
                    <div class="label">Existing data</div>
                    <div class="value" v-if="selectedExistingDataOption == 'keep'">The report will keep the existing creator's data</div>
                    <div class="value" v-if="selectedExistingDataOption == 'replace'">The report will replace the existing creator's data</div>
                </div>

                <div class="field">
                    <div class="label">Social invites</div>
                    <div class="value" v-if="selectedSocialInviteOption == 'false'">Social invites wont be sent to creators that are not connected.</div>
                    <div class="value" v-if="selectedSocialInviteOption == 'true'">Social invites will be sent to creators that are not connected.</div>
                </div>
                
            </div>
        </section>
        
        <section class="creators">
            <div class="title">
                Creators
            </div>
            <base-table
                :data="creators"
                key="overview-table-key"
                class="mb-12"
                :columns="[
                    {
                        name: 'status',
                        label: 'Status',
                        type: 'user-importable-status'
                    },
                    {
                        name: 'first_name', 
                        label: 'First name'
                    },
                    {
                        name: 'last_name', 
                        label: 'Last name'
                    },
                    { 
                        name: 'email', 
                        label: 'Email'
                    },
                    {
                        name: 'profile_link', 
                        label: 'Profile Link'
                    },
                    {
                        name: 'handle', 
                        label: 'Handle',
                        options: {
                            cellStyles: 'width: 220px; max-width: 225px; min-width: 225px;',
                            cellClasses: 'truncate'
                        }
                    },
                    {
                        name: 'platform',
                        label: 'Platform',
                        type: 'network-selector',
                        value: (element) => {
                            return element.target_network
                        },
                        socialAccounts: (element) => {
                            return element.user && element.user.social_accounts ? element.user.social_accounts : [];
                        },
                        user: (element) => {
                            return element.user   
                        }
                    },
                    { 
                        name: 'description', 
                        label: 'Description',
                        type: 'long-text',
                        value: (element) => {
                            return element.description;
                        }
                    },
                    { 
                        name: 'posts', 
                        label: 'Posts',
                        type: 'featured-posts',
                        value: (element) => {
                            return element.posts
                        }
                    },
                    {
                        name: 'additional-info',
                        label: 'Additional Info',
                        type: 'additional-info',
                        value: (element) => {
                            return element.additional_information
                        }
                    }
                ]"
                :search="false"
            />
        </section>
    </div>
</template>
<script>
import { isValidArray } from '../../common';
export default {
    props: ['creators', 'name', 'projectName', 'tags', 'description', 'passwordProtected', 'settings', 'selectedExistingDataOption', 'selectedSocialInviteOption'],
    data(){
        return {
            isValidArray    
        }        
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply flex flex-col gap-y-6;
        section{
            @apply pb-20;
            &.bordered{
                @apply border-b border-gray-200;
            }
            > .title{
                @apply text-h3 font-bold text-purple-m-secondary mb-1;
            }
            > .subtitle{
                @apply text-h6 font-bold text-purple-m-secondary;
            }
        }
        > .report-settings{
            //
            > .content{
                @apply flex flex-wrap gap-y-6 mt-6;
                > .field{
                    @apply w-1/2;
                    > .label{
                        @apply text-h6 font-bold text-purple-m-secondary;
                    }
                    > .value{
                        //   
                    }
                }
            }
        }
        > .settings{
            //  
            > .content{
                @apply flex flex-wrap gap-y-6 mt-6;
                > .field{
                    @apply w-1/2;
                    > .label{
                        @apply text-h6 font-bold text-purple-m-secondary;
                    }
                    > .value{
                        //   
                    }
                }
            }          
        }
        > .creators{
            //
        }
    }
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"title"},[_vm._v("Columns")]),_c('base-table',{key:("mapper-table-key-" + _vm.key),staticClass:"mt-8 mb-12",attrs:{"data":_vm.mapperVal,"columns":[
            { 
                name: 'target_column', 
                label: 'Target Column', 
                type: 'html',
                value: _vm.computedTargetColumnValue
            },
            { 
                name: 'mapper', 
                label: 'File Column', 
                type: 'single-select', 
                placeholder: 'Select the file column',
                width: '250px',
                value: function (element, column) {
                    return !element.fileColumn ? {
                        label: 'Ignore column',
                        value: 'ignore-column'
                    } : {
                        label: element.fileColumn,
                        value: element.fileColumn
                    }
                },
                options: function () {
                    return [
                        { label: 'Ignore column', value: 'ignore-column' } ].concat( _vm.fileColumns.map(function (element) {
                            return {
                                label: element,
                                value: element
                            }
                        })
                    )
                } 
            },
            { 
                name: 'example', 
                label: 'Example data',
                value: function (element) {
                    return element.examples.join(', ');
                }
            }
        ],"search":false},on:{"change-single-select":_vm.changeFileColumn}}),_c('div',{staticClass:"title"},[_vm._v("Additional Information")]),_c('base-table',{key:("additional-information-table-key-" + _vm.key),staticClass:"mt-8 mb-12",attrs:{"data":_vm.additionalInformationVal,"columns":[
            {
                name: 'is-selected',
                label: '',
                type: 'checkbox',
                value: function (element) {
                    return element.selected;
                },
                options: {
                    cellStyles: 'width: 62px;'
                }
            },
            {
                name: 'name', 
                label: 'Column'
            },
            { 
                name: 'example', 
                label: 'Example data',
                value: function (element) {
                    return element.examples.join(', ');
                }
            }
        ],"onRowClick":_vm.onRowClick,"search":false},scopedSlots:_vm._u([{key:"table-header",fn:function(){return [_c('div',{staticClass:"additional-information-table-header"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.selectedAdditionalInformation.length)+" selected")]),(_vm.additionalInformationVal.length == _vm.selectedAdditionalInformation.length)?_c('base-button',{attrs:{"size":"md","label":"Deselect all","bold":""},on:{"action":_vm.deselectAllAdditionalInformation}}):_c('base-button',{attrs:{"size":"md","label":"Select all","bold":""},on:{"action":_vm.selectAllAdditionalInformation}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <table class="bg-white" :class="tableClass">
        <tbody>
            <tr v-for="i in rows" :key="'sk' + i" class="h-15 border-b border-gray-m-light">
                <td>
                    <div class="grid justify-items-center">
                        <skeleton :width="5"/>
                    </div>
                </td>
                <td class="px-6 py-6">
                    <div class="flex items-center space-x-3 lg:pl-2">
                        <skeleton :full-width="true"/>
                    </div>
                </td>
                <td class="px-6 py-6">
                    <div class="flex items-center space-x-3 lg:pl-2">
                        <skeleton :full-width="true"/>
                    </div>
                </td>
                <td class="px-6 py-6">
                    <div class="flex items-center space-x-3 lg:pl-2">
                        <skeleton :full-width="true"/>
                    </div>
                </td>
                <td class="px-6 py-6">
                    <div class="flex items-center space-x-3 lg:pl-2">
                        <skeleton :full-width="true"/>
                    </div>
                </td>
                <td>
                    <div class="grid justify-items-center">
                        <skeleton type="circle" :width="5"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'TableLoader',
    props: {
        tableClass: {
            type: String,
            default: '',
        },
        rows: {
            type: Number,
            default: 10
        }
    }
}
</script>
<template>
    <div>

        <stats 
            :projects="projects"
            :status="status"
            :user-deliverables="userDeliverables"
            :reports="reports"
        />

        <template v-if="projects && projects.length">

            <div class="header">
                <div class="title">
                    Project History
                    <span>({{ projects.length }})</span>
                </div>  
                <div class="subtitle">
                    Projects Completed:
                    <span>{{ completedProjects.length }}</span>
                </div>  
            </div>

            <base-table 
                class="px-12 pb-10"
                :columns="[
                    {
                        name: 'name',
                        label: 'Project Name',
                        type: 'link',                        
                        value: (element) => {
                            return `${ element.name } - ${ element.brand }`
                        },
                        options: {
                            cellClasses: 'max-w-screen-sm truncate',
                            cellStyles: 'max-width: 500px;',
                        },
                    },
                    {
                        name: 'start',
                        label: 'Start Date',
                        type: 'date',                                    
                        value:  (element) => {
                            return element.starts_at;
                        }
                    },
                    {
                        name: 'earned',
                        label: 'Earned',
                        type: 'price',
                        hidden: !isAdminOrHigher && !isProjectManager,
                        value: (element) => {
                            return element.project_users.reduce((a, c) => a += (this.userDeliverables.filter(ut => {return ut.project_user_id === c.id}).reduce((t, curr) => t += curr.budget, 0)), 0);
                        }                
                    },
                    {
                        name: 'status',
                        label: 'Status',
                        type: 'string',
                        style: 'pill',
                        disabled: true,
                        searchBy: statusSearchBy,
                        value:  (element) => {
                            return element.status && element.status[0] !== undefined ? element.status[0].label : '-';
                        },
                        options: {
                            stopPropagation: true,
                            cellStyles: 'max-width: 224px; width: 224px; padding-left: 12px; padding-right: 0px;',
                            thStyles: 'padding-left: 12px; padding-left: 12px;'
                        }
                    }
                ]"
                :data="projects"                
                :onRowClick="(element) => false && !isGuest && !isClient ? $router.push(`/projects/${element.slug}`) : null"
                :loading="loading"
                hideDeliverableActions
                disableProjectRedirect
            />

        </template>

        <div v-else class="px-12 py-10">
            <section-empty-state            
                icon="folder"
                message="No projects assigned"
            />
        </div>

    </div>
</template>

<script>
import Stats from './Stats.vue'
import SectionEmptyState from '../../components/SectionEmptyState.vue';
import { mapGetters } from 'vuex';
export default {
    props: ['loading', 'projects', 'project', 'status', 'userDeliverables', 'reports'],
    components: {
        Stats,
        SectionEmptyState
    },
    computed: {
        ...mapGetters(['isAdminOrHigher', 'isProjectManager', 'isGuest', 'isClient']),
        completedStatusId() {
            let status = this.status.find(status => status.name.toLowerCase() === 'completed');
            if (status) {
                return status.id;
            }
            return null;
        },
        completedProjects() {
            let projects = this.projects;
            return projects.filter(project => project.status[0].id === this.completedStatusId);
        },
        pendingStatusId() {
            let status = this.status.find(status => ['pending', 'in progress'].includes(status.name.toLowerCase()));
            if (status) {
                return status.id;
            }
            return null;
        }
    },
    methods: {
        statusSearchBy(element){
            return element.status.map(status => status.label).toString().toLowerCase()
        }
    }
}
</script>
<style lang="scss" scoped>
    .header{
        @apply flex justify-between px-12 py-10;
        .title{
            @apply text-h2 font-bold text-purple-m-secondary;
            >span{
                @apply text-gray-m-disable;
            }
        }
        .subtitle{
            @apply text-h6 font-bold text-purple-m-secondary;
            >span{
                @apply text-gray-m-disable;
            }
        }
    }
</style>

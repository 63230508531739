<template>
    <div class="wrapper" v-if="user && user.id !== 0">
        
        <general-info
            :user="user"
        />

        <additional-info
            :user="user"
        />
     
    </div>
</template>
<script>
import GeneralInfo from './GeneralInfo.vue'
import AdditionalInfo from './AdditionalInfo.vue';
export default {
    props: ['user'],
    components: {
        GeneralInfo,
        AdditionalInfo
    }
}
</script>
<style lang="scss" scoped>
    .wrapper{
        @apply w-full;
    }
</style>

<template>
    <div class="audio-dropzone-wrapper">
        <div
            v-if="!file.audio || viewOnly"
            @drop.prevent="onDrop($event)"
            @dragover.prevent="isAudioOverDropzone = true"
            @dragleave.prevent="isAudioOverDropzone = false"
            :class="`audio-dropzone ${file.audio ? 'with-audio' : ''}`"
        >
            
            <div v-if="!viewOnly" class="background"/>
            
            <div v-if="!viewOnly" class="content">

                 <div class="audio-item" v-if="file.audio">

                    <!-- progress bar -->
                    <!-- <div 
                        v-if="playing" 
                        class="progress-bar"
                        :style="`width: ${progressPercentage}`" 
                    />
                    
                    <div class="icon-wrapper">
                        <base-icon :size="5" name="audio-hollow" />
                    </div>

                    <div class="flex-grow flex-shrink">
                        <div class="font-semibold text-h5 text-purple-m-secondary">{{name}}</div>
                        <div class="text-sm">{{extension}}</div>
                    </div>

                    <div class="flex-grow-0 flex-shrink-0">
                        <base-button
                            type="label"
                            icon="close"
                            @action="$emit('removeAudio')"
                        />
                    </div> -->

                 </div>

                 <div v-else class="text-center">Drag audio files to match with videos</div>

            </div>

            <!-- autoplay -->
            <audio 
                controls
                class="hidden"
                autoplay
                v-if="file.audio"
                id="audio"
                @timeupdate="onTimeUpdate"
                @ended="onEnded"
                @play="playing = true"
            >
                <source :src="url" :type="type">
                Your browser does not support the audio element.
            </audio>

        </div>
    </div>
</template>

<script>
export default {
    props: ['file', 'viewOnly'],
    data(){
        return {
            isAudioOverDropzone: false,
            progress: 0,
            playing: false
        }
    },
    computed: {
        // when we are on view only we are dealing with an attachment from the database with its signedUrl, mimetype, etc
        // but when we are NOT on view only we are dealing with a local File object with its type, etc.
        audioElement(){
            return document.getElementById('audio')
        },
        name(){
            let arr = this.file.audio.name.split('.')
            const lastElement = arr[arr.length-1]
            arr = arr.filter(element => element != lastElement)
            return arr.join()
        },
        extension(){
            const arr = this.file.audio.name.split('.')
            const lastElement = arr[arr.length-1] 
            return `.${lastElement}`
        },
        url(){
            if(this.viewOnly) return this.file.audio.signedUrl
            return URL.createObjectURL(this.file.audio);
        },
        type(){
            if(this.viewOnly) return this.file.audio.mimetype
            return this.file.audio.type
        },
        duration(){
            var audio =  this.audioElement
            return audio?.duration || 0
        },
        progressPercentage(){
            return `${(this.progress * 100) / this.duration}%`
        },
    },
    methods: {
        onDrop(event){
            const audioName = event.dataTransfer.getData('audioName')
            const audioElementId = event.dataTransfer.getData('audioElementId')
            this.$emit('audioDrop', { name: audioName, elementId: audioElementId })
        },
        onTimeUpdate(){
            this.progress = this.audioElement.currentTime
        },
        onEnded(){
            this.playing = false
        }
    }
}
</script>
<style lang="scss" scoped>
    .audio-dropzone-wrapper{
        @apply w-full p-6 flex justify-center items-center;
    }
    .audio-dropzone{
        @apply w-full cursor-pointer rounded-md p-4 relative flex items-center gap-x-3;
        
        border: 1px dashed #0E092C;
        box-shadow: 0px 2px 18px 6px rgba(28, 28, 28, 0.01);
        
        &.with-audio{
            @apply p-0 border-none shadow-none;
        }
        
        & .background{
            @apply absolute z-10 w-full h-full rounded-md bg-white opacity-50 left-0 top-0;
        }
        & .content{
            @apply relative z-20 w-full h-full rounded-md;
            .audio-item{
                @apply w-full cursor-pointer bg-white rounded-md p-4 relative flex items-center gap-x-3 transition duration-300;
                height: 68px;
                &:hover{
                    @apply opacity-80;
                }
                & .icon-wrapper{
                    @apply h-12 w-12 rounded-full border border-gray-300 flex-grow-0 flex-shrink-0 flex justify-center items-center;
                }
                & .progress-bar{
                    @apply bg-black h-1 absolute top-0 left-0 rounded-md;
                }
            }
        }
    }
</style>
import { parseDomain, ParseResultType } from "parse-domain";

const getSubDomain = () => {
    const parseResult = parseDomain(window.location.hostname)
    let subDomain = null;
    if (parseResult.type === ParseResultType.Listed) {
        const { subDomains } = parseResult
        subDomain = subDomains[0]
    }

    if ((process.env.NODE_ENV === 'production') && !subDomain && !window.location.host.includes("development") && !window.location.host.includes("uat")) {
        let host = window.location.hostname;
        host = 'www.' + host;
        console.log("REDIRECTING TO NEW HOST", host);
        window.location.hostname = host;
    }

    console.log("RETURNING SUBDOMAIN", subDomain);

    return subDomain;
};

const devSubDomain = () => {
    let subDomain = window.location.hostname.replace("http://", "").replace("https://", "").split('-dot-')[0].trim()
    if(subDomain !== 'development' && subDomain !== 'uat'){
        return subDomain
    }
    return null
}

export { 
    getSubDomain,
    devSubDomain
};

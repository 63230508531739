<template>
<main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
    <!-- header -->
    <div class="flex flex-col gap-y-6 px-12 pt-10 bg-tan-m">

        <div class="flex items-center gap-x-4 ">
            <div class="inline-flex items-center justify-center rounded-full bg-purple-m-main w-15 h-15">
                <base-icon class="text-green-m-main" name="deliverable-check" :size="6" />
            </div>

            <div class="text-h2 font-bold text-purple-m-secondary">New Deliverables</div>
        </div>

        <tabs :tabs="['New', 'Copy-from-existing']" v-model="tab"/>
    </div>

    <component :is="tab.toLowerCase()"/>

    <div class="text-h2 font-bold text-purple-m-secondary px-12 py-10">
        Creators Assigned
        <span class="text-gray-m-disable">(0)</span>
    </div>

    <div class="creators-assigned">
        <base-table class="px-12 py-10"
            :columns="[
                {
                    name: 'name',
                    label: 'Creator',
                    type: 'avatar-list',
                    value: (element) => {
                        return [element]
                    }
                },
                {
                    name: 'projects',
                    label: 'Active Projects',
                    value: (element) => {
                        return element.activeProjects;
                    }
                },
                company.company_creator_columns.find(col => col.creator_column_id === 6) ? {
                    name: 'user_tags',
                    label: 'Tags',
                    type: 'user-tags',
                    value: (element) => {
                        return element.user_tags
                    },
                    searchBy: customUserTagsSearchBy,
                    filter: {
                        options: tags.map(tag => {return { label: tag.name, value: tag.slug }}),
                        placeholder: 'Tags',
                        type: 'select',
                        allSelectedPlaceholder: 'All Tags'
                    },
                } : {},
                company.company_creator_columns.find(col => col.creator_column_id === 5) ? {
                    name: 'email',
                    label: 'Email',
                    type: 'text',
                    value: (element) => {
                        return element.email ? element.email : '-'
                    }
                } : {},
                company.company_creator_columns.find(col => col.creator_column_id === 4) ? {
                    name: 'phone',
                    label: 'Phone',
                    type: 'text',
                    value: (element) => {
                        return element.phone ? element.phone : '-'
                    }
                } : {},
                company.company_creator_columns.find(col => col.creator_column_id === 7) ? {
                    name: 'links',
                    label: 'Links',
                    type: 'networks',
                    value: (element) => {
                        return element.social_accounts
                    },
                    options: {
                        stopPropagation: true
                    }
                } : {},
                company.company_creator_columns.find(col => col.creator_column_id === 8) ? {
                    name: 'intagram-followers',
                    label: 'Instagram Followers',
                    type: 'number',
                    value: (element) => {
                        return instagramFollowersValue(element)
                    }
                } : {},
                company.company_creator_columns.find(col => col.creator_column_id === 9) ? {
                    name: 'tiktok-followers',
                    label: 'TikTok Followers',
                    type: 'number',
                    value: (element) => {
                        return tiktokFollowersValue(element)
                    }
                } : {},
                company.company_creator_columns.find(col => col.creator_column_id === 1) ? {
                    name: 'city',
                    label: 'City',
                    type: 'text',
                    value: (element) => {
                        return element.audience_data && element.audience_data[0] && element.audience_data[0].location && element.audience_data[0].location.city ? element.audience_data[0].location.city.name : '-';
                    }
                } : {},
                company.company_creator_columns.find(col => col.creator_column_id === 2) ? {
                    name: 'state',
                    label: 'State',
                    type: 'text',
                    value: (element) => {
                        return element.audience_data && element.audience_data[0] && element.audience_data[0].location && element.audience_data[0].location.state ? element.audience_data[0].location.state.name : '-';
                    }
                } : {},
                company.company_creator_columns.find(col => col.creator_column_id === 3) ? {
                    name: 'country',
                    label: 'Country',
                    type: 'text',
                    value: (element) => {
                        return element.audience_data && element.audience_data[0] && element.audience_data[0].location && element.audience_data[0].location.country ? element.audience_data[0].location.country.name : '-';
                    }
                } : {},
            ].filter(
                obj => !(obj && Object.keys(obj).length === 0 && obj.constructor === Object)
            )"
                    :data="creators"
                    :onRowClick="(element) => {
                if(element.user_projects.length){
                    let found = element.user_projects.find(element => {
                        return (element.project && element.project.slug)
                    })
                    if(found){
                        this.$router.push(`/projects/${found.project.slug}/creator/${element.id}`);
                    }
                }
                else {
                    this.$router.push(`/creator/${element.id}`);
                }
            }"
            :key="creatorsKey"
            :loading="loading"
            @changePage="changePage"
            @reload-filters="changeFilters"
            :page="currentPage"
            :per-page="perPage"
            :total="total"
            :default-filters="filters"
            overflow
        />
    </div>

    <div class="footer">
        <base-button @action="$router.back()" class="bg-tan-m" type="secondary" label="Discard"/>
        <base-button @action="submit()" submit label="Save"/>
    </div>
</main>
</template>
<script>
import New from './New.vue'
import CopyFromExisting from './CopyFromExisting.vue'
import Tabs from '../../components/Tabs.vue'
import { strToQuery } from '../../lib/strings';

export default {
    components: {Tabs, New, CopyFromExisting },
    data() {
        return {
            tab: 'New',
            loading: false,

            company: {
                company_creator_columns: [],
            },
            creators: [],
            tags: [],
            createInfluencerModalVisible: false,
            editCreatorsColumnsModalVisible: false,
            currentPage: 1,
            total: 0,
            perPage: 10,
            filters: {},
            creatorsKey: false,
        }
    },
    beforeMount() {
        this.getCompany();
        this.getTags();
    },
    methods: {
        submit() {
            alert('Submit')
        },

        filtersQuery(exceptions) {
            return strToQuery(this, exceptions);
        },

        async changePage(page, reload = true) {
            this.currentPage = page;
            if (reload) {
                this.getData(false);
            }
        },
        changeFilters(filters) {
            this.filters = JSON.parse(JSON.stringify(filters));
            this.getDataTotal();
            this.getData(false);
        },

        async getCompany(){
            await new Promise(resolve => setTimeout(resolve, 500));
            const { data } = await this.$http.get(`/api/companies`)
            this.company = data
        },

        async getTags(){
            const { data } = await this.$http.get(`/api/tags`)
            this.tags = data
        },

        async getDataTotal() {
            this.total = 0;
        },

        async getData() {
            this.creators = [];
        },

        customUserTagsSearchBy(element){
            const {user_tags = []} = element
            let str = ``
            user_tags.forEach(user_tag => {
                str = str + user_tag.tag.slug + ' '
            });
            return str
        },
    }
}
</script>

<style lang="scss" scoped>
    .footer{
        @apply sticky border-gray-300 border-t bottom-0 right-0 flex justify-end gap-x-4 px-12 py-6 bg-white w-full;
    }
</style>
<template>
    <div v-if="user" class="owner-dropdown-container flex items-center gap-x-3 relative">
        
        <button class="flex" @click="showDropdown = !showDropdown">
            <avatar 
                class="text-white shadow-solid"
                size="small" 
                v-tooltip="{ content: `${user.first_name} ${user.last_name}`, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } }"
                :user="user"
            />
        </button>

        <div class="origin-top-right absolute left-0 top-full w-56 rounded-md shadow-lg">
            <div v-if="showDropdown" class="z-10 rounded-md bg-white shadow-xs relative z-20" role="menu" aria-orientation="vertical" aria-labelledby="project-options-menu-0">
                
                <div class="px-4 py-3 flex justify-start items-center gap-x-2">
                    <avatar size="small" :user="user"/>
                    <h3>{{`${user.first_name} ${user.last_name}`}}</h3>
                </div>
                
                <div class="py-1" v-if="projectId">
                    <a href="#" class="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem" @click.prevent="removeFromOwners()">
                        <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                        Remove from owners
                    </a>
                </div>

            </div>
            <div  v-if="showDropdown" @click.prevent="showDropdown = false" class="fixed max-w-full max-h-full inset-0 z-10 bg-transparent"/>
        </div>

    </div>
</template>
<script>
export default {
    props: ['user','projectId'],
    data(){
        return {
            showDropdown: false
        }
    },
    methods: {
        async removeFromOwners() {
            let route = `/api/projects/${this.projectId}/owner/${this.user.id}`;
            this.$http.delete(route).then(res => {
                if (res.status === 200) {
                    this.showDropdown = false;
                    this.$emit('done');
                    this.$store.dispatch('restorer/set', {
                        resourceLabel: 'Project owner',
                        route: `${route}/restore`,
                        action: res.data.show_undo,
                        fn: () => {
                            this.$emit('done');
                        }
                    });
                }
            })
            .catch(() => {
                this.$notify({ title: 'Error', text: 'Error trying to update the project', type: 'error' })
            });
        }
    }
}
</script>
<style scoped>
    .owner-dropdown-container:hover{
        z-index: 1;
    }
</style>
<template>
    <div class="container">

        <div class="header">
            
            <!-- navigation lines -->
            <div class="navigation-lines">
                <div 
                    v-for="(file, index) in files"
                    class="line"
                    :class="{ 'active': index == page }"
                    :style="`width:${100 / files.length}%`"
                    :key="index"
                />
            </div>
            <!-- user -->
            <div class="user">
                <avatar size="medium" :user="postedBy"/>
                <div class="text-shadow">
                    {{postedBy.handle}}
                </div>
                <div class="date text-shadow">
                    2hr
                </div>
            </div>
        </div>

        <!-- content -->
        <div class="content">
            <media-viewer
                :key="key"
                :file="files[page]"
                :viewOnly="viewOnly"
                @videoEnded="page < files.length-1 ? $emit('navigate', 'next') : null"
            />
        </div>

        <!-- navigation buttons -->
        <div v-if="page > 0" class="navigation-container left">
            <div @click="$emit('navigate', 'prev')" class="button">
                <base-icon name="chevron-left" />
            </div>
        </div>
        <div v-if="page < files.length-1" class="navigation-container right">
            <div @click="$emit('navigate', 'next')" class="button">
                <base-icon name="chevron-right" />
            </div>
        </div>

        <!-- audio dropzone -->
        <audio-dropzone
            v-if="!isReport"
            :file="files[page]"
            :key="key"
            :viewOnly="viewOnly"
            @removeAudio="$emit('removeAudio')"
            @audioDrop="(audio)=>$emit('audioDrop', audio)"
        />

        <div class="interaction">
            <div class="input text-shadow">Respond to {{postedBy.handle}}</div>
            <base-icon :size="6" iconClass="text-white drop-shadow" name="ig-share"/>
            <!-- <base-icon :size="6" iconClass="text-white drop-shadow" name="ig-save"/> -->
        </div>
    </div>
</template>

<script>
import MediaViewer from '../MediaViewer.vue'
import AudioDropzone from '../../AudioDropzone.vue'
export default {
    props: ['files', 'description', 'postedBy', 'postedAt', 'page', 'viewOnly', 'isReport'],
    components: {
        MediaViewer,
        AudioDropzone
    },
    data(){
        return {
            key: 0
        }
    },
    watch: {
        files: {
            immediate: true,
            deep: true,
            handler () {
                this.key = !this.key
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .container{
        width: 300px;
        height: 532px;
        position: relative;
        background-color: black;
    }
    .header{
        @apply absolute top-0 w-full flex flex-col gap-y-2 z-50;
        padding: 20px 16px 32px;
    }
    .user{ 
        @apply flex items-center gap-x-4 text-white;
    }
    .date{
        font-weight: 400;
        opacity: .6;
        color: white;
    }
    .navigation-lines{
        @apply w-full flex justify-between gap-x-1;
        & .line{
            background-color: #717070;
            border-radius: 2px;
            height: 2px;
            &.active{
                background-color: white;
            }
        }
    }
    .navigation-container{
        position: absolute;
        bottom: 0px;
        top: 0px;
        display: flex;
        align-items: center;            

        &.left{
            left: -40px;
        }

        &.right{
            right: -40px;
        }

        & .button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #bbbbbd;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            cursor: pointer;
            box-shadow: #a3a3a3 -1px 0px 13px 0px;
        }
    }
    .interaction{
        @apply absolute bottom-6 flex justify-between items-center w-full gap-x-2 overflow-hidden;
        padding: 0px 16px;
        & .input {
            @apply flex justify-start items-center truncate overflow-hidden;
            border: 1px solid #dbdbdb;
            border-radius: 100vh;
            flex-grow: 1;
            min-height: 44px;
            padding-left: 11px;
            padding-right: 8px;
            color: white;
        }
        & .share{
            color: white
        }
        // & .save{
        //     color: white
        // }
    }
</style>
<template>
    <base-modal
        :visible="visible"
        title="Select facebook business"
        @close="$emit('close')"
    >
        <div>
            
            <div v-if="step == 1">
                <div class="text-h6 font-bold mb-4">Choose one of your facebook businesses:</div>
                <base-icon v-if="loading" name="loader"/>
                
                <div v-if="!businesses.length">
                    No facebook business found, make sure that you have created a facebook business for this account.
                </div>
                <div class="business-list">
                    <div 
                        v-for="(business, index) in businesses" 
                        :key="index"
                        @click="toggleBusiness(business)"
                        class="business"
                        :class="{ 'active': isActive(business) }"
                    >
                        <div class="text-h5 font-bold">{{business.name}}</div>
                        <div class="text-pxs text-gray-400">ID: {{business.id}}</div>
                        
                        <div 
                            v-if="isActive(business)" 
                            class="absolute top-0 bottom-0 -right-8 flex items-center">
                            <base-icon name="check" />
                        </div>

                    </div>
                </div>

            </div>

            <div v-if="step == 2">
                <base-icon :size="8" name="exclamation" />
                <div class="text-h4 font-bold">
                    Are you sure you want to select this business?
                </div>
                <div class="mt-4 mb-20">All facebook permission requests for pages, accounts and ads made within this company <span class="font-bold">({{companyName}})</span> will be made from this facebook business: <span class="font-bold">{{selectedBusiness.name}}</span></div>
            </div>

            <div class="mt-8 w-full flex justify-between">
                <base-button
                    v-if="step == 1"
                    type="secondary"
                    label="Cancel"
                    @action="$emit('close')"
                />
                <base-button
                    v-if="step == 2"
                    type="secondary"
                    label="Back"
                    icon="chevron-left"
                    @action="step--"
                />
                <base-button
                    v-if="step == 1"
                    label="Next"
                    secondary-icon="chevron-right"
                    :disabled="selectedBusiness == null"
                    @action="step++"
                />
                <base-button
                    v-if="step == 2"
                    label="Confirm"
                    @action="confirm()"
                />
            </div>
   
        </div>
    </base-modal>
</template>

<script>
import BaseModal from '../../components/BaseModal.vue';
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        facebook: {
            type: Object
        },
        companyName: {
            type: String
        }
    },
    components: {
        BaseModal
    },

    data(){
        return {
            step: 1,
            businesses: [],
            selectedBusiness: null,
            loading: false
        }
    },

    mounted(){
        this.getBusinesses()
    },

    methods: {
        getBusinesses(){
            this.loading = true
            this.$http.get('/api/facebook', {
                params: {
                    entity_id: this.facebook.profile_id,
                    edge: 'businesses'
                }
            }).then(async (res) => {
                if(res.data){
                    this.businesses = res.data
                }
            }).catch((err) => {
                console.log('getBusinesses error', err)
            }).finally(() => {
                this.loading = false
            })
        },
        toggleBusiness(business){
            if(this.selectedBusiness?.id == business.id){
                this.selectedBusiness = null
            }else{
                this.selectedBusiness = business
            }
        },
        isActive(business){
            return business.id == this.selectedBusiness?.id
        },

        confirm(){
            this.$emit('confirm', this.selectedBusiness.id)
        }
    }

}
</script>
<style lang="scss" scoped>
    .business-list{
        @apply flex flex-col gap-y-4 items-start;
        .business{
            @apply relative px-6 py-2 border border-black rounded-xl cursor-pointer transition-opacity duration-500;
            &:hover{
                @apply opacity-50;
            }
            &.active{
                @apply bg-gray-300;
            }

        }
    }
</style>
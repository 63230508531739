export default function displayNames(type){

    // 'Label = Upload ${attachment}'
    // 'Label = ${description}'
    // 'Placeholder = Enter ${description}'

    var deliverableTypes = {
        'social-post-instagram': function () {
            return {
                attachment: 'Instagram Post',
                description: 'Caption'
            }
        },
        'story-post-instagram': function () {
            return {
                attachment: 'Instagram Story',
                description: 'Caption'
            }
        },
        'reel-post-instagram': function () {
            return {
                attachment: 'Instagram Reel',
                description: 'Caption'
            }
        },
        'view-brief': function () {
            return {
                attachment: 'Media',
                description: 'Description'
            }
        },
        'view-contract': function () {
            return {
                attachment: 'Media',
                description: 'Description'
            }
        },
        'concept-approval': function () {
            return {
                attachment: 'Media',
                description: 'Description'
            }
        },
        'post-approval': function () {
            return {
                attachment: 'Media',
                description: 'Description'
            }
        },
        'social-post-tiktok': function () {
            return {
                attachment: 'Tiktok',
                description: 'Caption'
            }
        },
        'social-post-youtube': function () {
           return {
                attachment: 'Youtube Video',
                description: 'Video Description'
            }
        },
        'social-post-facebook': function () {
            return {
                attachment: 'Facebook Post',
                description: 'Caption'
            }
        },
        'blog-post': function () {
            return {
                attachment: 'Blog Post Media',
                description: 'Description'
            }
        },
        'default': function () {
            return {
                attachment: 'Media',
                description: 'Description'
            }
        }
    };
    return deliverableTypes[type] ? deliverableTypes[type]() : deliverableTypes['default']()
}

export function componentName(type){

    var deliverableTypes = {
        'social-post-instagram': 'instagram-post', // no audio required, you have to put it in the video
        'story-post-instagram': 'instagram-story', // audio optional, you choose what section of the song to play
        'reel-post-instagram': 'instagram-reel', // audio optional, you choose what section of the song to play
        'in-feed-video-instagram': 'instagram-in-feed',
        'view-brief': 'blog-post',
        'view-contract': 'blog-post',
        'concept-approval': 'blog-post',
        'post-approval': 'blog-post',
        'social-post-tiktok': 'tiktok-video', // audio optional, you can choose the starting point of the audio
        'social-post-youtube': 'youtube-video', // no audio required, you have to put it in the video
        'social-post-facebook': 'facebook-post', // no audio required, you have to put it in the video
        'blog-post': 'blog-post',
        'default': 'blog-post'
    };

    return deliverableTypes[type] ? deliverableTypes[type] : deliverableTypes['default'];
}
<template>
    <img 
        :draggable="draggable"
        v-if="picture" 
        :src="picture"
        :class="[propClass, {'object-cover': cover}]"
        @error="handleError"
        :width="width"
        :height="height"
    >
</template>

<script>
export default {
    props: {
        images: {
            type: Array,
            default: function () {
                return [];
            }
        },
        isVideo: {
            type: Boolean,
            default: false
        },
        draggable: {
            type: Boolean,
            default: false
        },
        cover: {
            type: Boolean,
            default: false
        },
        propClass: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: ''
        }
    },
    
    data() {
        return {
            index: 0,
        }
    },

    methods: {
        handleError() {
            this.index++;
        },
    },

    computed: {
        computedArray() {
            if (this.images !== undefined && Array.isArray(this.images)) {
                return this.images.filter(img => {
                    return img && img !== '';
                });
            }
            return [];
        },
        
        picture() {
            if (this.computedArray.length) {
                if (this.computedArray[this.index] !== undefined) {
                    return this.computedArray[this.index];
                }
            }
            let picture = require('../assets/images/default-picture.png');
            let video = require('../assets/images/default-video.png');
            return this.isVideo ? video : picture;
        }
    }
}
</script>
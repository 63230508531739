<template>
    <div class="notes">
        <div class="header">
            <div class="title">
                Notes
                <span>({{ notes ? notes.length : 0 }})</span>
            </div>
        </div>

        <div class="content">

            <div class="comment-form" v-if="!isClient && !isGuest">
                <FormulateInput
                    type="textarea"
                    class="w-full"
                    placeholder="Write a note..."
                    input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-12"
                    v-model="newNote"
                    :rows="1"
                />
                <base-button size="lg" theme="dark" secondaryIcon="pencil" label="Add Note" :disabled="sendingNote || newNote === ''" @action="saveNote" class="font-bold" />
            </div>

            <div class="note-list">
                <div 
                    v-for="note in notes" 
                    :key="note.id" 
                    @mouseenter="setOptions(note)" 
                    @mouseleave="note.showOptions = false;$forceUpdate()" 
                    class="note" 
                    :class="{'hidden' : !note.visible}"
                >                        
                    <div class="options-container" v-if="note.showOptions && !note.editing">
                        <options-dropdown                                
                            :options="[                                                
                                {label: 'Edit', icon: 'pencil-alt', event:'edit'},
                                {label: 'Delete', icon: 'trash', event:'delete'}
                            ]"
                            @edit="note.editing = true;$forceUpdate()"
                            @delete="deleteNote(note)"
                        />                  
                    </div>
                    <div class="content">
                        <avatar :user="note.creator"/>
                        <div class="text-wrapper" v-if="!note.editing">
                            <div class="text">
                                {{ note.value }}
                                <span v-if="note.created_at !== note.updated_at">(Edited)</span>
                            </div>
                            <div class="date">{{ note.created_at | moment('MMM D, Y') }}</div>
                        </div>
                        <div class="edit" v-else>
                            <FormulateInput
                                type="textarea"
                                class="w-full"
                                placeholder="Write a note..."
                                v-model="note.value"
                                input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-12"
                                :rows="1"
                            />                                
                            <div class="actions-wrapper">
                                <base-button
                                    @action="note.editing = false;cleanNotes();$forceUpdate()"
                                    size="sm" 
                                    type="secondary"
                                    theme="dark"                                                          
                                    label="Cancel"
                                    class="font-bold" 
                                    :disabled="note.submitting"                                                       
                                />
                                <base-button
                                    @action="editNote(note)"
                                    size="sm" 
                                    theme="dark"                                                          
                                    label="Save"
                                    class="font-bold" 
                                    :disabled="note.submitting"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>                
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: ['notes', 'stagedNotes'],
    data(){
        return {
            newNote: '',
            sendingNote: '',
            
            areas: ['.notes-title', '.white-menu-responsive', '.user-presentation'],
            baseItemsHeight: 0,
            baseFormHeight: 0,
            commentsHeight: 0
        }
    },
    watch: {
        viewTab: function (val) {
            setTimeout(() => {
                this.alterNotesKey();
            }, 50);
        }
    },
    mounted(){
        window.addEventListener('resize', this.alterNotesKey);
        this.alterNotesKey();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.alterNotesKey);
    },
    computed: {
        ...mapGetters(['me', 'isGuest', 'isClient']),
        
        notesContainerHeight () {
            return `min-height: calc(100vh - ${this.baseItemsHeight}px);max-height: calc(100vh - ${this.baseItemsHeight}px)`;
        },

        notesHeight() {
            return `min-height: calc(100vh - ${this.commentsHeight}px);max-height: calc(100vh - ${this.commentsHeight}px)`;
        },
    },
    methods: {
        async saveNote() {
            if (!this.sendingNote && this.newNote !== '') {
                this.sendingNote = true;
                const {data} = await this.$http.post(`/api/projects/${this.$route.query.project}/${this.$route.params.userId}/note`, {note: this.newNote});
                if (data) {
                    this.newNote = '';
                    this.$emit('refresh-data')
                    this.$notify({ title: 'Success', text: 'Note added successfully', type: 'success' })
                } else {
                    this.$notify({ title: 'Error', text: 'The note can not be created. Try again later', type: 'error' })
                }
                this.sendingNote = false;
            }
        },
        setOptions(note) {
            note.showOptions = note.created_by === this.me.id;
            this.$forceUpdate();
        },
        async deleteNote(note) {
            let id = note.id;
            const {data} = await this.$http.post(`/api/projects/${this.$route.query.project}/${this.$route.params.userId}/note/${id}/delete`);
            if (data) {
                note.visible = false;
                this.$forceUpdate();
                this.$store.dispatch('restorer/set', {
                    resourceLabel: 'Note',
                    route: `/api/projects/${this.$route.query.project}/${this.$route.params.userId}/note/${id}/restore`,
                    action: data.show_undo,
                    forceFn: true,
                    fn: (time = 6100) => {
                        setTimeout(() => {
                            this.$emit('refresh-data');
                        }, time);
                    }
                });
            }
        },
        async editNote(note) {
            if (!note.submitting && note.value !== '') {
                note.submitting = true;
                this.$forceUpdate();
                const {data} = await this.$http.post(`/api/projects/${this.$route.query.project}/${this.$route.params.userId}/note`, {note: note});
                if (data) {
                    this.$emit('refresh-data')
                    this.$notify({ title: 'Success', text: 'Note edited successfully', type: 'success' })
                } else {
                    this.$notify({ title: 'Error', text: 'The note can not be edited. Try again later', type: 'error' })
                }
                note.submitting = false;
                this.$forceUpdate();
            }
        },
        cleanNotes() {
            this.notes = JSON.parse(this.stagedNotes);
        },
        alterNotesKey() {
            let len = 0;
            for (let i in this.areas) {
                let item = document.querySelector(this.areas[i]);
                if (item) {
                    len = len + item.offsetHeight;
                }
            }

            let commentForm = document.querySelector('.comment-form');
            let baseComments = 0;
            if (commentForm) {
                baseComments = commentForm.offsetHeight;
            }

            this.baseItemsHeight = len;
            this.baseFormHeight = baseComments;
            this.commentsHeight = this.baseFormHeight + this.baseItemsHeight;
            this.$forceUpdate();
        },
    }
}
</script>
<style lang="scss" scoped>
    .notes{
        >.header{
            @apply flex justify-between px-12 py-10;
            >.title{
                @apply text-h2 font-bold text-purple-m-secondary;
                >span{
                    @apply text-gray-m-disable;
                }
            }
        }
        >.content{
            @apply flex flex-col justify-between;
            >.comment-form{
                @apply flex flex-col gap-5 px-12 pt-0 pb-10;
            }
            >.note-list{
                @apply flex flex-col gap-10 pb-10 px-12;
                >.note{
                    @apply relative;
                    .options-container{
                        @apply absolute origin-top-right right-0 top-0 mt-0;
                    }
                    .content{
                        @apply flex gap-4 items-start;
                        >.text-wrapper{
                            @apply flex flex-col items-start justify-end gap-1;
                            >.text{
                                @apply text-pm text-purple-m-secondary;
                                >span{
                                    @apply text-pxs font-normal text-gray-m-disable;
                                }
                            }
                            >.date{
                                @apply text-pxs text-gray-m-disable;
                            }
                        }
                        >.edit{
                            @apply flex flex-col w-full items-start justify-end gap-4;
                            >.actions-wrapper{
                                @apply w-full flex justify-end gap-2;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

<template>
    <base-modal
        :visible="visible"
        @close="$emit('close')"
    >
        <div class="flex flex-col justify-center items-center">
            <div class="text-h2 font-bold text-purple-m-secondary text-center">Select a company</div>
            <div class="text-h4 font-bold mt-1">User does not have access to this company.</div>
            <div class="mt-1">Select a company from the list of companies that you do have access to.</div>
            <div class="my-8 flex flex-col items-center gap-y-4 w-full">
                <img v-if="userCompaniesLoading" class="w-5" src="../../assets/images/spinner.gif" />
                <div
                    v-for="(company, index) in userCompanies" 
                    :key="index"
                    class="flex flex-row justify-start gap-x-8 bg-purple-m-main border border-indigo rounded-md py-4 px-8 w-96 cursor-pointer hover:bg-purple-m-main-light transition duration-150 ease-in-out"
                    @click="onSelect(company.slug)"
                >
                    <div class="flex items-center w-20">
                        <img 
                            :src="company.logo"
                            style="max-height: 32px; height: 32px;"
                            class="h-full w-full object-contain"  
                        />
                    </div>
                    <div>
                        <div class="text-lg font-semibold text-white">{{company.name}}</div>
                        <div class="font-normal text-pxs text-gray-m">{{company.slug}}.creatorsaurus.com</div>
                    </div>
                </div>
            </div>
        </div>
    </base-modal>
</template>

<script>
import BaseModal from '../../components/BaseModal';
export default {
    props: ['visible', 'userCompanies', 'userCompaniesLoading'],
    components: {
        BaseModal
    },
    methods: {
        onSelect(slug){
            this.$emit('close')
            this.$emit('onCompanySelect', slug)
        }
    }
}
</script>
<template>
    <div v-if="hasContent && allowRefresh && allowedType">
        <base-button v-if="!inReport" size="lg" display="inline-block" :disabled="refreshing" @action="refresh" label="Refresh"/>
        <base-button v-else @action="refresh" :disabled="refreshing" icon="refresh" bold label="Refresh"/>

        <base-modal bigger :visible="changes.length && showChanges" title="Report changes" @close="acceptChanges">
            <p class="mb-4">There is a resume of the updates made on the report.</p>
            <table class="w-full">
                <tr>
                    <td class="font-bold">Post</td>
                    <th class="font-bold text-center" colspan="2">Likes</th>
                    <th class="font-bold text-center" colspan="2">Comments</th>
                    <th class="font-bold text-center" colspan="2">Views</th>
                    <th class="font-bold text-center" colspan="2">Followers</th>
                </tr>
                <tr>
                    <td width="20%" class="font-bold"></td>
                    <td width="10%" class="font-bold text-center text-xs">Old</td>
                    <td width="10%" class="font-bold text-center text-xs">Current</td>
                    <td width="10%" class="font-bold text-center text-xs">Old</td>
                    <td width="10%" class="font-bold text-center text-xs">Current</td>
                    <td width="10%" class="font-bold text-center text-xs">Old</td>
                    <td width="10%" class="font-bold text-center text-xs">Current</td>
                    <td width="10%" class="font-bold text-center text-xs">Old</td>
                    <td width="10%" class="font-bold text-center text-xs">Current</td>
                </tr>
            </table>
            <div class="max-h-80 overflow-y-auto">
                <table class="w-full">
                    <tr v-for="(change, c_key) in changes" :key="c_key" class="text-xs">
                        <td width="20%" class="flex gap-x-1 w-full h-16 items-center">
                            <img :src="change.old.display_url || defaultPicture" class="h-10 w-10 rounded-full object-cover" @error="setAltImg"/>
                            <p class="w-full line-clamp-2">{{ change.old.caption }}</p>
                        </td>
                        <td width="10%" class="text-center uppercase">{{ change.old.likes | numeral('0.0a') }}</td>
                        <td width="10%" class="text-center">
                            <FormulateInput v-model="change.new.likes" type="number" @blur="updateMeta(change)" v-debounce:500="(inputValue, e) => updateMeta(change)"/>
                        </td>
                        <td width="10%" class="text-center uppercase">{{ change.old.comments | numeral('0.0a') }}</td>
                        <td width="10%" class="text-center">
                            <FormulateInput v-model="change.new.comments" type="number" @blur="updateMeta(change)" v-debounce:500="(inputValue, e) => updateMeta(change)"/>
                        </td>
                        <td width="10%" class="text-center uppercase">{{ change.old.views | numeral('0.0a') }}</td>
                        <td width="10%" class="text-center">
                            <FormulateInput v-model="change.new.views" type="number" @blur="updateMeta(change)" v-debounce:500="(inputValue, e) => updateMeta(change)"/>
                        </td>
                        <td width="10%" class="text-center uppercase">{{ change.old.profile_item.total_followers | numeral('0.0a') }}</td>
                        <td width="10%" class="text-center">
                            <FormulateInput v-model="change.new.profile_item.total_followers" type="number" @blur="updateMeta(change)" v-debounce:500="(inputValue, e) => updateMeta(change)"/>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="w-full flex justify-center mx-auto mt-4">
                <base-button label="Accept" @action="acceptChanges"/>
            </div>
        </base-modal>

        <fixed-loader :visible="refreshing" label="Refreshing Report"/>
        
        <div v-if="progressVisible" class="progress-wrapper">
            <div class="progress-container">
                <base-icon name="loader" />
                <div>Refreshing report...</div>
                <div>{{progress}}% done</div>
                <div>Please wait</div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'ReportUpdate',
        props: {
            allowRefresh: {
                type: Boolean,
                default: false
            },
            user: {
                type: Object,
                required: true,
                default: function () {
                    return {
                        roles: [],
                        companies: [],
                    };
                }
            },
            inReport: {
                type: Boolean,
                default: true
            },
            report: {
                type: Object,
                required: true,
                default: function () {
                    return {
                        type: 0,
                        influencers: [],
                        posts: []
                    }
                }
            }
        },
        watch: {
        },
        data() {
            return {
                refreshing: false,
                changes: [],
                showChanges: false,
                defaultPicture: require('../../assets/images/default-picture.png'),
                progressVisible: false,
                progress: 0
            }
        },
        computed: {
            hasContent() {
                return (this.report.influencers && this.report.posts) && (this.report.influencers.length || this.report.posts.length);
            },
            type() {
                return this.report.type == 1 ? 'users' : this.report.type == 2 ? 'posts' : null;
            },
            allowedType() {
                return [1, '1', 2, '2'].includes(this.report.type);
            },
            allowConfig() {
                return this.user ? this.isAdmin && this.isInCompany : false;
            },
            isAdmin() {
                return this.user ? (this.user.roles.includes('admin') || this.user.roles.includes('superAdmin')) : false;
            },
            isInCompany() {
                return this.reportData ? this.user.companies.includes(this.reportData.company_id) : false;
            },
        },
        methods: {
            async refresh() {

                if (this.type !== null) {
                    
                    // long requests may cause the server to error out, so for now we will always do multiple requests
                    if(this.type == 'posts' && this.report.posts.length >= 1){

                        this.progressVisible = true;
                        let urls = this.report.posts.map(p => {
                            return `/api/reports/posts/${this.$route.params.id}/refresh/${p.id}`;
                        });

                        let total = urls.length;
                        let completed = 0;

                        const requests = urls.map(url => this.$http.get(url).then(res => {
                            completed++;
                            if (total > 0) {
                                let percentage = Math.ceil(completed * 100 / total);
                                percentage = percentage <= 100 ? percentage : 100;
                                this.progress = percentage;
                            }
                        }));

                        Promise.allSettled(requests).then(responses => {
                            this.$notify({ title: 'Success', text: `Report was updated successfully`, type: 'success' });
                        }).catch(error => {
                            this.$notify({ title: 'Error while refreshing', text: `Some posts couldn't be refreshed. Try again later.`, type: 'warn' });
                        }).finally(() => {
                            this.progressVisible = false;
                            this.progress = 0;
                            // this is not handled
                            this.$emit('finish-refresh');
                            this.$emit('reload');
                        });
                        
                    }else{
                        // this will update all posts in a single request, which will take longer but shouldn't error out if there are less than 20 posts.
                        this.refreshing = true;
                        this.showChanges = false;

                        // while the long request is running, this will hit the "me" endpoint every 30 seconds, so it also stays alive.
                        var requestLoop = setInterval(() => {
                            this.$http.get(`/api/me`).then(({ data }) => {
                                console.log('keep alive');
                            });
                        }, 30000);

                        this.$http.get(`/api/reports/${this.type}/${this.$route.params.id}/refresh`).then(({data}) => {
                            this.refreshing = false;
                            this.changes = data.changes ? data.changes : [];
                            if (this.changes.length) {
                                this.$emit('show-changes', this.changes);
                                if (this.inReport) {
                                    this.showChanges = true;
                                }
                            }
                            this.$emit('finish-refresh');
                        }).catch(() => {
                            this.refreshing = false;
                            this.$notify({ title: 'Error', text: 'Something went wrong while updating the report. Try again later.', type: 'warn' });
                        }).finally(() => {
                            clearInterval(requestLoop);
                        });
                    }

                }
            },
            async acceptChanges() {
                this.showChanges = false;
                this.$emit('reload');
                this.$notify({ title: 'Success', text: 'Report updated successfully', type: 'success' });
            },
            async updateMeta(postItem) {
                const { id, profile_item, likes, dislikes, comments, shares, saves, views, url } = postItem.new;
                let post = { id, profile_item, likes, dislikes, comments, shares, saves, views, url };
                await this.$http.post(`/api/reports/update-post`, post);
            },
            setAltImg (e) {
                e.target.src = this.defaultPicture;
            },
        }
    }
</script>
<style lang="scss" scoped>
    .progress-wrapper{
        @apply fixed top-36 z-50;
        left: 50%;
        transform: translate(-50%, 0);
        .progress-container{
            @apply text-black bg-green-m-main rounded-md p-4 font-bold w-56;
            @apply flex flex-col items-center;
        }
    }
</style>


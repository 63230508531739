<template>
    <div class="w-full relative">
        <FormulateInput :label="label !== '' ? label : undefined" :key="colorText" type="text" placeholder="Background color" validation="required" :value="color" @input="setValue" @click="openColor = true"/>
        <div class="absolute flex flex-col z-50 gap-2 p-2 bg-white shadow-md" v-if="openColor">
            <chrome-picker :value="color" @input="updateValue" :disable-alpha="true" :disable-fields="true"/>
            <div class="flex gap-2 justify-between">
                <base-button type="label" label="Cancel" bold @action="openColor = false"/>
                <base-button type="label" label="Accept" bold @action="acceptColor"/>
            </div>
        </div>
    </div>
</template>

<script>
import { Chrome } from 'vue-color'
export default {
    props: {
        value: {},
        label: {
            type: String,
            default: ''
        }
    },
    components: {
        'chrome-picker': Chrome,
    },

    data() {
        return {
            color: this.value,
            custom: this.value,
            openColor: false,
            colorText: false,
        }
    },

    methods: {
        setValue(val) {
            this.custom = val;
        },

        updateValue(val) {
            this.custom = val.hex8;
        },

        acceptColor() {
            this.openColor = false;
            this.color = this.custom;
            this.$emit('input', this.custom);
            this.$forceUpdate();
            this.colorText = !this.colorText;
        }
    }
}
</script>
<template>
    <base-modal
        :visible="visible"
        title="Boosted posts"
        @close="$emit('close')"
    >
        <div class="container">
            
            <tab :visible="step == 1">
                <div class="option-list">
                    <div 
                        v-for="(option, index) in options" 
                        :key="index" 
                        class="option"
                        @click="selectedOption = option.value; step = 2"
                    >
                        {{ option.name }}
                    </div>
                </div>
            </tab>
            
            <tab :visible="step == 2">

                <facebook-boosted-posts
                    v-if="selectedOption == 'facebook'"
                    :user="user"
                    @back="step--"
                />

                <instagram-boosted-posts
                    v-if="selectedOption == 'instagram'"
                    :user="user"
                    @back="step--"
                    @close="$emit('close')"
                />

            </tab>

        </div>
    </base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';
import FacebookBoostedPosts from './FacebookBoostedPosts.vue';
import InstagramBoostedPosts from './InstagramBoostedPosts.vue';
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        user: {
            type: Object
        }
    },
    components: {
        BaseModal,
        FacebookBoostedPosts,
        InstagramBoostedPosts
    },

    data(){
        return {
            step: 1,
            options: [
                {value: 'facebook', name: 'Facebook boosted posts'},
                {value: 'instagram', name: 'Instagram boosted posts'}
            ],
            selectedOption: null
        }
    }

}
</script>
<style lang="scss" scoped>
    .container{
        @apply flex flex-col gap-y-4;
    }
    .option-list{
        @apply flex flex-col gap-y-4;
        .option{
            @apply relative px-6 py-6 bg-white rounded-xl cursor-pointer transition-opacity duration-500;
            &:hover{
                @apply opacity-50;
            }
        }
    }
</style>

<template>
    <base-modal 
        title="Enter Password"
        :visible="visible"
        :invisible="hide"
        :hideCloseButton="true"
        overlayClasses="opaque"
        @close="()=>null"
    >
        <div class="relative w-full">
            <div style="top: -180px" class="absolute w-full flex justify-center">
                <img
                    style="max-height: 52px; height: 52px;"
                    class="object-contain"
                    src="../assets/images/icon.svg"
                />
            </div>
        </div>
        <FormulateForm name="password-form" @submit="submit()">

            <FormulateInput
                type="password"
                label="Password"
                placeholder="Enter"
                v-model="password"
                class="w-full"
                autocomplete="off"
            />

            <div class="pt-10 w-full flex justify-end items-center">
                <base-button 
                    @action="$formulate.submit('password-form')" 
                    :disable="loading"
                    submit 
                    label="Access"
                    class="font-bold"
                />
            </div>

        </FormulateForm>
    </base-modal>
</template>

<script>
import Vue from 'vue';
export default {
    props: ['visible'],
    data() {
        return {
            password: '',
            loading: false,
            hide: true
        }
    },
    mounted(){
        this.getProjectShare()
    },
    methods: {
        async getProjectShare(){
            this.loading = true
            try{
                const { data } = await this.$http.get(`/api/project-shares`, {
                    params: {
                        ...(this.$route.query.token ? { token: this.$route.query.token } : {}),
                        ...(this.$route.params.userId ? { userId: this.$route.params.userId } : {}),
                        checkRequiresPassword: true
                    }
                })
                if(data.requiresPassword == false) this.$emit('success')
                if(data.token) {
                    Vue.prototype.$http.defaults.headers.common['x-guest-access-token'] = data.token
                    localStorage.setItem('guest_access_token', data.token)
                    this.$store.dispatch('auth/getGuestMe')
                }
            }catch(error){
                console.log(error)
                if(error.response.status == 404 || error.response.status == 400){
                    this.$notify({ title: error.response.data.message, type: 'warn' })
                }   
            }finally{
                this.hide = false
                this.loading = false
            }
        },
        async submit() {
            this.loading = true
            try{
                const { data } = await this.$http.post(`/api/project-shares/unlock`, {
                    token: this.$route.query.token,
                    password: this.password
                })

                if(data){
                    if(data.token){
                        Vue.prototype.$http.defaults.headers.common['x-guest-access-token'] = data.token
                        localStorage.setItem('guest_access_token', data.token)
                        this.$store.dispatch('auth/getGuestMe')
                    }
                    this.$notify({ title: 'Success', text: 'Correct password', type: 'success' })
                    this.$emit('success')
                }
            
            }catch(error){
                console.log(error)
                if(error.response.status == 422 || error.response.status == 400){
                    this.$notify({ title: error.response.data.message, type: 'warn' })
                }
            }finally{
                this.loading = false
            }
        }
    }
}
</script>
<template>
<div class="min-h-screen bg-purple-m-main flex flex-col justify-center py-12 sm:px-6 lg:px-8">

    <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img class="mx-auto h-12 w-auto" src="../../assets/images/logo.svg">
        <h2 class="mt-6 text-center text-2xl leading-9 font-extrabold text-white">
            Reset your password
        </h2>
        <div class="host">
            <p>{{host}}</p>
        </div>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

            <alert class="mb-8" type="danger" v-if="error">
                <template v-slot:icon>
                    <base-icon name="exclamation-2" />
                </template>
                {{ error }}
            </alert>

            <FormulateForm 
                class="flex flex-col items-center" 
                name="form" 
                @submit="submit"
            >

                <FormulateInput
                    type="email"
                    label="Email address"
                    validation="required"
                    validation-name="Email"
                    class="w-full"
                    v-model="email"
                />

                <base-button
                    @action="$formulate.submit('form')" 
                    :disabled="loading"
                    class="mt-4 w-full"
                    submit
                    label="Submit"
                />


                <base-button 
                    @action="$router.back()" 
                    type="label" 
                    icon="arrow-narrow-left"
                    class="mt-6 text-black"
                    label="Go back"
                />

            </FormulateForm>

        </div>
    </div>

    <forgot-password-success-modal 
        :visible="successModalVisible"
        @close="()=>null"    
    />

</div>
</template>

<script>
import Alert from '../../components/Alert';
import ForgotPasswordSuccessModal from './ForgotPasswordSuccessModal.vue'
export default {
    components: {
        Alert,
        ForgotPasswordSuccessModal
    },
    data() {
        return {
            email: '',

            error: null,
            loading: false,

            successModalVisible: false
        }
    },

    computed: {
        host(){
            return window.location.host
        }
    },

    methods: {
        getLocation(){
            return `${window.location.origin}`
        },
        async submit(){
            try {
                this.loading = true
                this.error = null

                const { data } = await this.$http.post(`/api/auth/forgot-password`, { 
                    email: this.email,
                    appUrl: this.getLocation()
                })

                if(data){
                    this.successModalVisible = true
                }
                
            }
            catch(e) {
                console.log(e);

                if (e.response && e.response.data) {
                    return this.error = e.response.data.message;
                }

                this.error = 'An unknown error occurred. Please try again.';

            } finally {
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .host{
        @apply flex justify-center mt-2;
        > p{
            @apply text-sm text-gray-m;
        }
    }
</style>
<template>
    <div class="flex h-screen bg-white">
        <!-- Main column -->
        <div class="flex flex-col w-0 flex-1">   
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },

    data() {
        return {

        }
    }
}
</script>

<style lang="scss">
.breadcrumb a {
    @apply text-gray-500;

    &:hover {
        @apply text-gray-800;
    }
}

</style>


const removeQueryParams = (url) => {
    return url.split('?')[0];
};

const removeAnchors = (url) => {
    return url.split('#')[0];
};

const removeQueryParamsAndAnchors = (url) => {
    return removeQueryParams(removeAnchors(url));
}

export {
    removeQueryParams,
    removeAnchors,
    removeQueryParamsAndAnchors
};

<template>
    <base-modal
        :visible="visible"
        :title="stepTitle"
        @close="$emit('close')"
        styles="max-height: 90vh; min-width: 90vw; width: 90vw; overflow-y: auto;"
        bigger
        persistent
        with-picture
        modal-title="Create Report"
        modal-icon="trending-up"
        no-padding
    >
        <div class="create-report-modal-container">
            <div class="create-report-modal-content">
                <!-- general form -->
                <tab :visible="step === 1">
                    <general-form
                        :project-id="projectId"
                        :projects="projects"

                        :report-name="report.name"
                        @update:report-name="val => report.name = val"

                        :type="formValues.type"
                        @update:type="val => formValues.type = val"

                        :custom-project="customProject"
                        @update:custom-project="val => customProject = val"

                        :selected-tags="selectedTags"
                        @update:selected-tags="val => selectedTags = val"

                        :report-description="report.description"
                        @update:report-description="val => report.description = val"

                        :report-password-protected="report.password_protected"
                        @update:report-password-protected="val => report.password_protected = val"
                        
                        :report-password="report.password"
                        @update:report-password="val => report.password = val"

                        :posts-limit="settings_data.posts_limit"
                        @update:posts-limit="val => settings_data.posts_limit = val"

                        :allow-feedback="settings_data.allow_feedback"
                        @update:allow-feedback="val => settings_data.allow_feedback = val"

                        :feedback="settings_data.feedback"
                        @update:feedback="(index, val) => settings_data.feedback[index].active = val"

                        :hide-resume="settings_data.hide_resume"
                        @update:hide-resume="val => settings_data.hide_resume = val"

                        :show-boosted-data="settings_data.show_boosted_data"
                        @update:show-boosted-data="val => settings_data.show_boosted_data = val"

                        :hide-stats="settings_data.hide_stats"
                        @update:hide-stats="val => settings_data.hide_stats = val"

                        :preview="settings_data.preview"
                        @update:preview="val => settings_data.preview = val"

                        @add-new-feedback-option="val => settings_data.feedback.push(val)"

                        :post-limit-key="post_limit_key"
                    />
                </tab>

                <!-- brand form -->
                <div class="step-2" v-if="step === 2">
                    <div class="brand-form" v-if="report.type === 3">
                        <custom-select
                            label="Brand"
                            placeholder="Select a brand"
                            :options="brands"
                            class="col-span-3"
                            bold
                            size="base"
                            v-model="formValues.brand"
                        />
                        <FormulateInput
                            v-if="report.brand_id === 'new'"
                            type="text"
                            label="Brand Name"
                            class="w-full col-span-3"
                            placeholder="Enter brand name"
                            validation="required"
                            validation-name="Brand name"
                            v-model="brand.name"
                        />
                        <div class="logo-field" v-if="report.brand_id === 'new'">
                            <img v-if="uploadingFile" class="loader" src="../../assets/images/spinner.gif" />
                            <img :src="brandLogoUrl" class="preview" @click="$refs.file.click()"/>
                            <input type="file" ref="file" accept="image/*" :disabled="uploadingFile" @change="uploadBrandLogo">
                        </div>
                        <div class="colors-field">
                            <color-picker label="Background Color" v-if="report.type && report.type === 3 && report.brand_id === 'new'" v-model="brand.bg_color"/>
                            <color-picker label="Emphasis Color" v-if="report.type && report.type === 3 && report.brand_id === 'new'" v-model="brand.emphasis_color"/>
                        </div>
                    </div>
                </div>

                <!-- audience data options -->
                <div class="flex flex-col scroll-container pr-4" v-if="step === 3">
                    <template v-if="report.type && report.type === 1">
                        <p class="font-medium text-sm">Type of audience to include in the report</p>
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline" v-for="(settings, s_key) in reportSettings" :key="s_key">
                            <div class="mt-4 sm:mt-0 sm:col-span-2" :class="{'hidden' : s_key === 'audience_likers'}">
                                <div class="max-w-lg" v-for="(op, setting_index) in settings" :key="setting_index">
                                    <!-- hide some audience data options -->
                                    <template v-if="!['notable_users', 'credibility_class', 'notable_users_ratio'].includes(setting_index)">
                                        <div 
                                            class="relative" 
                                            v-if="typeof op === 'object' && op !== null"
                                        >
                                            <FormulateInput 
                                                @change="toggleSettings(s_key, setting_index)" 
                                                type="checkbox" 
                                                v-model="sections[s_key][setting_index]" 
                                                :label="kebabToTitle(setting_index)"
                                            />
                                            <template v-for="(deep_op, deep_index) in op">
                                                <!-- hide some audience data options -->
                                                <FormulateInput
                                                    v-if="!['audience_interests', 'audience_languages', 'audience_credibility', 'audience_ethnicities', 'audience_reachability', 'audience_brand_affinity', 'audience_lookalikes'].includes(deep_index)"
                                                    :key="deep_index" 
                                                    class="flex ml-7"
                                                    @change="toggleOption" 
                                                    type="checkbox" 
                                                    v-model="settings[setting_index][deep_index]" 
                                                    :label="kebabToTitle(deep_index).replace('Audience ', '')"
                                                />
                                            </template>
                                        </div>
                                        <FormulateInput 
                                            class="relative flex items-start" 
                                            v-else 
                                            type="checkbox" 
                                            v-model="settings[setting_index]" 
                                            :label="kebabToTitle(setting_index)"
                                        />
                                    </template>

                                </div>
                            </div>
                        </div>
                    </template>
                </div>

                <!-- add creators or content -->
                <div class="flex flex-col space-y-3" v-if="step === 4">
                        
                    <!-- Tabs -->
                    <div class="flex justify-between">
                        <tabs
                            :tabs="contentTabs" 
                            v-model="contentTab" 
                            capitalize
                        />
                    </div>
                    
                    <!-- add creators -->
                    <template v-if="report.type && (report.type === 1 || (report.type === 3 && subStepData === 'users'))">
                        <div class="flex gap-4" v-if="!loadingCreators && !loadingProjectCreators && ['all-creators', 'in-project'].includes(contentTab)">
                            <div class="w-full relative flex">
                                <div class="absolute w-16 h-full flex items-center justify-center pointer-events-none">
                                    <base-icon name="search" class="text-purple-m-main" />
                                </div>
                                <FormulateInput
                                    type="text"
                                    v-model="searchName"
                                    v-debounce:1000="setFilter"
                                    placeholder="Search by name, project or report"
                                    :outer-class="['w-full']"                                  
                                    :element-class="['w-full']"
                                    wrapper-class="border rounded-md py-2 px-3 pl-16 bg-white h-16 flex items-center"
                                />
                            </div>
                            <div class="w-full">
                                <custom-select
                                    class="w-full"
                                    placeholder="Tags"
                                    label="Tags"
                                    :key="search_tag_key"
                                    :options="tags"
                                    v-model="searchTag"
                                    @changed="setFilter"
                                />
                            </div>
                        </div>
                        <div v-if="!loadingCreators && !loadingProjectCreators && showPartialDataAdvice()" class="bg-purple-m-main p-4 rounded-lg text-white flex gap-x-4 items-center">
                            <base-icon name="exclamation-circle" />
                            <div>
                                Some of the selected creators have not logged in with Facebook. Audience data will not be available for these users.
                            </div>
                        </div>
                        <div class="flex flex-col gap-3">

                            <!-- new creators section -->
                            <create-influencer-form 
                                v-if="contentTab === 'new'"
                                :tags="createInfluencerFormTags"
                                inline
                                no-tag-required
                                force-prompt
                                force-networks
                                @created-influencer="onCreateCreator"
                                @filling="setCreatingCreatorStatus"
                                @cancel-form="cancelCreateCreator"
                                @toggle-existent="addFoundCreatorToSelectedCreators"
                            />
                            <div class="w-full flex justify-center">
                                <base-icon name="loader" v-if="searchingCreators" />
                            </div>
                            <div class="scroll-container" v-if="loadingCreators || loadingProjectCreators">
                                <create-report-influencer-list-loader/>
                            </div>
                            <!-- all creators & project creators section -->
                            <div
                                class="scroll-container"
                                v-else-if="(
                                        (contentTab === 'all-creators' && filteredCreators.length) ||
                                        (contentTab === 'in-project' && filteredProjectCreators.length)
                                    ) && ['in-project', 'all-creators'].includes(contentTab)
                                "
                            >
                                <create-report-influencer-list
                                    :toggled-element="toggledUser"
                                    :influencers="contentTab === 'in-project' ? filteredProjectCreators : filteredCreators"
                                    :select="true"
                                    :toggle="true"
                                    :selected="selectedCreators.map(user => user.id)"
                                    @select-influencer="toggleCreator"
                                    @toggle-network="toggleCreatorNetwork"
                                    @set-platform-selector="setPlatformSelector"
                                    @set-new-platform="setNewPlatformSelector"
                                    @set-handles="setHandles"
                                />
                            </div>
                            <p 
                                class="italic text-center text-sm py-6 text-gray-800" 
                                v-else-if="['all-creators', 'in-project'].includes(contentTab)"
                            >
                                There are no creators
                            </p>
                        </div>
                    </template>
                    
                    <!-- add content -->
                    <template v-if="report.type && (report.type === 2 || (report.type === 3 && subStepData === 'posts'))">
                        <div v-if="contentTab === 'in-project'" class="flex flex-col space-y-3">
                            <div class="text-gray-headers">
                                Select the content that you would like to add to the report. If you don't see a particular post, please link it to the creator's deliverable for the project.
                            </div>
                            <div class="scroll-container" v-if="computedPosts.length">
                                <create-report-post-list
                                    :posts="computedPosts"
                                    :selectedPosts="selectedPosts"
                                    :select="true"
                                    @select-post="togglePost"
                                    @edit-post="editPost"
                                />
                            </div>
                            <p v-else class="italic text-center text-sm py-6 text-gray-800">There are no content for this project</p>
                        </div>
                        <div v-if="contentTab === '+-add-new'" class="flex flex-col w-full gap-3">
                            <div class="flex gap-3 items-center">
                                <!-- bookmarklet: change ALL "addPost" function calls to handleLink -->
                                <FormulateInput
                                    type="url"
                                    v-model="link"
                                    class="w-full"
                                    :disabled="savingPost"
                                    label="Post link"
                                    @keyup.enter="addPost"
                                    v-debounce:1000="setFixedLink"
                                    help-position="after"
                                    help-class="font-bold text-red-600"
                                    placeholder="Enter post link"
                                />
                                <base-button 
                                    @action="addPost" 
                                    :label="savingPost ? 'Adding' : 'Add'" 
                                    :disabled="link == '' || savingPost || invalidLink || existingPost"
                                />
                                <base-button 
                                    @action="showManual = true" 
                                    label="Add manual post"
                                    size="lg" 
                                    :disabled="link == ''"
                                />
                                <base-modal :hide-close-button="true" :visible="existingPost" title="This post is already on the project.">
                                    <p>Would you like to select it?</p>
                                    <div class="mt-4 flex justify-between">
                                        <base-button @action="cancelExisting" type="secondary" size="md" label="Cancel"/>
                                        <base-button @action="acceptExisting" label="Accept"/>
                                    </div>
                                </base-modal>
                            </div>
                            <div class="scroll-container-sm" v-if="computedNewPosts.length">
                                <create-report-post-list
                                    :posts="computedNewPosts"
                                    :selectedPosts="selectedPosts"
                                    :remove="true"
                                    @remove-post="togglePost"
                                    @edit-post="editPost"
                                />
                            </div>
                        </div>
                    </template>
                </div>

                <!-- overview -->
                <div class="flex flex-col space-y-3" v-if="step === 5">
                    <div v-if="report.type && (report.type === 1 || (report.type === 3 && subStepResume === 'users'))" class="flex flex-col space-y-3">
                        <div class="text-gray-headers">
                            These creators will be added to the report with their selected social media platforms.
                        </div>
                        <div class="scroll-container" v-if="selectedCreators.length">
                            <create-report-influencer-list 
                                :influencers="selectedCreators" 
                                :description="report.type === 1"
                                @set-description="setDescription"
                                @set-custom-fields="(id, additional_info) => setCustomFields(id, additional_info)"
                            />
                        </div>
                    </div>
                    <div v-if="report.type && ((report.type === 2 && subStepResume === 'users') || (report.type === 3 && subStepResume === 'posts'))" class="flex flex-col space-y-3">
                        <div class="text-gray-headers">
                            This content will be added to the report.
                        </div>
                        <div class="scroll-container">
                            <create-report-post-list :posts="computedSelectedPosts" :selectedPosts="selectedPosts" @edit-post="editPost"/>
                        </div>
                    </div>
                    <div v-if="report.type && report.type === 2 && subStepResume === 'groups'">
                        <report-groups 
                            as-circles 
                            :key="groupsKey" 
                            :refreshed="groupsRefreshed" 
                            @refresh="refreshGroups" 
                            :posts="computedSelectedPosts" 
                            :initial-groups="settings_data.groups" 
                            allow-edit 
                            with-button 
                            @save="saveGroups"
                        />
                    </div>
                </div>
                
                <!-- final -->
                <div class="flex flex-col space-y-3" v-if="step === 6">
                    <div v-if="report.type && [1, 3].includes(report.type)">
                        We are getting the audience data for the creators. The report will be ready in a few minutes.
                    </div>
                    <div>
                        <base-button @action="openReportLink" type="label" icon="eye" label="View Report"/>
                        <div v-if="report.password_protected" class="text-sm text-gray-m-disable ml-9">Password: {{ report.password }}</div>
                    </div>
                    <base-button @action="copyToClipboard" type="label" icon="link" justify="start" label="Copy Link"/>
                </div>
            </div>
            <div class="create-report-modal-footer">
                <div class="actions">
                    <base-button v-if="step > 1 && step < 6" @action="previousStep" :disabled="saving" type="secondary" size="md" :icon-size="4" bold icon="arrow-left" label="Back"/>
                    <base-button 
                        @action="nextStep" 
                        :disabled="disableNext || saving || savingPost" 
                        size="md" 
                        :icon-size="4" 
                        :label="submitButtonLabel" 
                        bold 
                        secondary-icon="arrow-right"
                    />
                </div>
            </div>
        </div>

        <loader label="Loading" :visible="loading"/>

        <update-post
            title="Set missing data for this content"
            subtitle="This content needs attention. Please fill the necessary data in order to show it in the report."
            admit-media
            :open="editingPost && currentPost.id !== undefined"
            :current-post="currentPost"
            @close="cancelEditingPost"
            @finish-update="finishUpdate"
        />

        <update-post
            title="Set missing data for this content"
            subtitle="This content needs attention. Please fill the necessary data in order to show it in the report."
            is-force
            v-for="pendingPost in pendingPosts"
            :key="`${pendingPost.id}_${p_post_key}`"
            :open="p_post_key"
            :current-post="pendingPost"
            @close="removePending(pendingPost.id)"
            @finish-update="removePending(pendingPost.id)"
        />


        <base-modal :visible="promptManual" title="Add Manual Content" @close="promptManual = false">
            <template v-if="link.includes('tiktok.com')">
                <p>We were unable to get this TikTok post. Please make sure that the creator has connected their TikTok account so that we can retrieve their data.</p>
                <p>You can also add this post manually. Do you want to continue?</p>
                <div class="mt-4 flex justify-between">
                    <base-button @action="promptManual = false" type="secondary" size="md" label="Cancel"/>
                    <base-button @action="promptManual = false;showManual = true" size="lg" label="Add post manually"/>
                </div>
            </template>
            <template v-else>    
                <p>This content is either coming from an unsupported platform or the post is unavailable. Do you want to continue with adding it manually?</p>
                <p>You can also invite the user to authenticate with supported platforms in order to retrieve their profile information and posts.</p>
                <div class="mt-4 flex justify-between">
                    <base-button @action="promptManual = false" type="secondary" size="md" label="Cancel"/>
                    <base-button @action="promptManual = false;showManual = true" size="lg" label="Add post manually"/>
                </div>
            </template>

        </base-modal>

        <manual-post 
            @close="showManual = false" 
            :open="showManual" 
            :suggested-link="link" 
            :report-id="0" 
            @finish-manual="finishAdd" 
            @attach-existent="onAttachExistent"
        />

        <base-modal @close="cancelHandle" :visible="requestHandle" title="Insert the handle for the user">
            <p>We are unable to get the username for this content. Please provide the handle so we can retrieve it.</p>
            
            <FormulateInput
                type="text"
                class="w-full"
                placeholder="User Handle"
                label="User Handle"
                v-model="userHandle"
            />

            <div class="mt-4 flex justify-between">
                <base-button @action="cancelHandle" type="secondary" size="md" label="Cancel"/>
                <base-button @action="addPost" label="Continue"/>
            </div>
        </base-modal>

        <base-modal :hide-header="true" persistent :visible="noMediaFn != null">
            <div class="flex flex-col gap-4">
                <base-icon class="m-auto mt-6" size="12" name="exclamation-circle"/>
                <p class="text-h4 text-center mt-4 font-bold">We weren't able to get the media for this post.</p>
                <p class="text-center mt-2">Meta won't return media if the post contains copyrighted material or has been flagged for a copyright violation. Examples of copyrighted material can include audio on reels.</p>
                <p class="text-center mt-2 font-bold">Would you still like to add the post? The post thumbnail will be shown and you can upload the media manually.</p>
                <div class="flex justify-center w-full gap-16 mt-6">
                    <base-button @action="noMediaFn = null" type="secondary" size="md" bold label="Cancel"/>
                    <base-button @action="continueSavingPost" size="md" label="Add" bold/>
                </div>
            </div>
        </base-modal>

        <post-summary-modal 
            :visible="summaryModalVisible" 
            :post="summaryModalPost"
            @close="summaryModalVisible = false"
            @done="()=>null"
        />

        <!-- bookmarklet uncomment -->
        <!-- <tiktok-bookmarklet :open="adviseTiktok" @close="adviseTiktok = false" @confirm="addPost"/> -->

        <advanced-loader :visible="saving" :index="100" label="We are generating the report" :spinner="true"/>

        <fixed-loader :visible="savingPost" label="Saving content"/>
    </base-modal>
</template>

<script>
import { snakeCaseToTitle, validateUrl } from '@/lib/strings';
import DefaultBrandLogo from '@/assets/images/default-user.png';
import FormulateInput from "@braid/vue-formulate/src/FormulateInput";
import CreateReportInfluencerList from '@/components/CreateReportInfluencerList.vue';
import CreateReportInfluencerListLoader from '@/components/CreateReportInfluencerListLoader.vue';
import CreateReportPostList from '@/components/CreateReportPostList.vue';
import ColorPicker from '@/components/ColorPicker';
import Loader from '@/components/Loader';
import CreateInfluencerForm from '@/components/CreateInfluencerForm';
import ReportGroups from '@/views/ReportCreate/ReportGroups';
import ManualPost from "@/views/ReportCreate/ManualPost/index.vue";
import UpdatePost from "@/views/ReportCreate/UpdatePost";
// bookmarklet uncomment
// import TiktokBookmarklet from '@/views/ReportCreate/TiktokBookmarklet';
// import { scrapperFn } from '@/lib/scrapperDom';
import { recommendationsAlert } from '@/lib/alerts';
import { mapGetters } from 'vuex';
import GeneralForm from './GeneralForm.vue';
import PostSummaryModal from '../../views/ReportCreate/PostSummaryModal.vue';
import { arraysEqual } from '../../common';
export default {
    props: ['projectId', 'folderId', 'projects', 'type', 'visible'],
    components: {
        FormulateInput,
        CreateReportInfluencerList,
        CreateReportInfluencerListLoader,
        CreateReportPostList,
        ColorPicker,
        Loader,
        ReportGroups,
        CreateInfluencerForm,
        ManualPost,
        // bookmarklet uncomment
        // TiktokBookmarklet,
        UpdatePost,
        GeneralForm,
        PostSummaryModal
    },
    data() {
        return {
            step: 1,
            post_limit_key: false,
            loading_type_key: false,
            search_tag_key: true,

            formValues: {
                type: null,
                brand: null
            },

            customProject: null,
            toggledUser: null,

            sections: {
                audience_followers: {
                    audience_geo: false,
                    audience: false
                },
                audience_likers: {
                    audience_geo: false,
                    audience: false
                }
            },

            brand: {
                name: '',
                logo: null,
                bg_color: '#E9E9E9',
                emphasis_color: '#AC8A56'
            },

            uploadingFile: false,

            brands: [],

            settings_data: {
                allow_feedback: false,
                platforms: false,
                audience_followers: {
                    audience_geo: false,
                    audience: false
                },
                audience_likers: {
                    audience_geo: false,
                    audience: false
                },
                hide_resume: false,
                show_boosted_data: true,
                hide_stats: false,
                preview: false,
                feedback: [],
                groups: [],
                posts_limit: {id: 9, label: 9},
                loading_type: {id: 'cascade', label: 'Sequentially'}
            },

            settings_hidden: [
                'audience_likers',
                'allow_feedback',
                'feedback',
                'groups',
                'ages',
            ],

            audiences: [
                { value: 1, label: 'Gender' },
                { value: 2, label: 'Age' },
                { value: 3, label: 'Location' },
            ],

            contentTab: 'all-creators',
            
            // are we using this ?
            reportInfluencers: [],

            customPlatforms: [],

            projectCreators: [],
            posts: [],
            selectedCreators: [],
            selectedPosts: [],
            savingPost: false,
            promptManual: false,
            showManual: false,
            currentPlatformSelector: null,
            currentNewPlatformSelector: null,
            link: '',

            saving: false,
            loading: false,

            report: {
                guid: 'Text',
                brand_id: null,
                name: '',
                description: '',
                type: null,
                password_protected: false,
                password: '',
                allow_feedback: false,
                audience: [],
                feedbackOptions: []
            },

            searchName: '',
            searchTag: {
                id: null,
                name: 'All tags',
                slug: 'all'
            },
            creators: [],
            tags: [],
            searchingCreators: false,
           
            selectedTags: [],
            subStepData: 'users',
            subStepResume: 'users',
            elasticSearchUserIds: null,
            createInfluencerFormTags: [],
            creatingCreator: false,
            searchingLink: false,
            linkAccepted: false,
            savingUsers: false,
            groupsKey: false,
            groupsRefreshed: false,
            editingPost: false,
            currentPost: null,
            pendingPosts: [],
            userHandle: '',
            requestHandle: false,
            loadingCreators: false,
            loadingProjectCreators: false,

            loading_types: [
                { id: 'cascade', label: 'Sequentially'},
                { id: 'scroll', label: 'By scrolling'},
                { id: 'all', label: 'All at once'},
            ],

            noMediaFn: null,

            summaryModalVisible: false,
            summaryModalPost: null,
            
            // bookmarklet uncomment
            // adviseTiktok: false
        }
    },

    watch: {
        step: function (val, old) {
            if (val === 2 && ![3].includes(this.report.type)) { //todo: Remove when we know how to handle the category grid
                this.step = old === 3 ? 1 : 3;
            }

            if (val === 3) {
                if ([1, 2, 3].includes(this.report.type)) {
                    this.step = old === 4 ? 2 : 4;
                }
            }

            setTimeout(() => {
                this.search_tag_key = !this.search_tag_key;
                this.post_limit_key = !this.post_limit_key;
                this.loading_type_key = !this.loading_type_key;
            }, 250);
        },
        elasticSearchUserIds(val, old){
            if(old && !val){
                this.getCreators();
            }
            if(val){
                if(!old){
                    this.getCreators();
                }else{
                    const _val = JSON.parse(JSON.stringify(val));
                    const _old = JSON.parse(JSON.stringify(old));
                    if(!arraysEqual(_val, _old)){
                        this.getCreators();
                    }
                }
            }
        },
        formValues: {
            deep: true,
            handler: function (val) {
                if (val.type) {
                    this.report.type = val.type.value;
                    if (!this.projectId) {
                        if (['1', 1].includes(val.type.value)) {
                            if (this.computedProjectId) {
                                this.contentTab = 'all-creators'
                                this.getProjectCreators();
                            }
                        } else if (this.computedProjectId) {
                            this.contentTab = 'in-project'
                            this.getPosts();
                        }
                    }else{
                        if (['1', 1].includes(val.type.value)) {
                            this.contentTab = 'all-creators'
                        } else {
                            this.contentTab = 'in-project'
                        }
                    }
                }

                if (val.brand) {
                    this.report.brand_id = val.brand.value;
                }
            }
        },
        reportSettings: {
            deep: true,
            handler: function (settings) {
                this.runChanging(settings);
            }
        },

        computedProjectId: function(newVal, oldVal) {
            if (newVal != oldVal && newVal) {
                if (['1', 1].includes(this.report.type)) {
                    this.contentTab = 'all-creators'
                    this.getProjectCreators();
                }
                if (['2', 2].includes(this.report.type)) {
                    this.contentTab = 'in-project'
                    this.getPosts();
                }
            }
        },

        projectId: function(newVal, oldVal) {
            if(newVal != oldVal){
                this.getProjectCreators();
                this.getPosts();
            }
        },
        visible: function (val) {
            this.getProjectCreators();
            this.getPosts();
            if(val == true && this.creators.length <= 0){
                this.getSettings();
                this.getBrands();
                this.getCreators();
                this.getTags();
            }
        },
        link: function(val, old){
            const copyPasted = val.length - old.length > 5
            if(copyPasted && val.includes('instagram.com') && this.fbLogin.error) {
                this.$store.dispatch('restorer/set', {
                    notification: {
                        title: `Connect your Facebook account`,
                        text: this.fbLogin.computedMessage(' get more data for instagram posts'),
                        type: 'warn'
                    },
                    fail: 'Unabled to get account',
                    action: true,
                    forceFn: false,
                    data: { undoLabel: 'Connect Account' },
                    fn: () => {
                        this.$store.dispatch('fb/simpleLogin', this.$notify);
                    }
                });
            }
        }
    },

    computed: {
        ...mapGetters(['fbLogin']),
        brandLogoUrl() {
            return this.brand.logo ? this.brand.logo : DefaultBrandLogo;
        },

        invalidLink() {
            return this.link.length > 10 && !validateUrl(this.link);
        },

        existingPost() {
            if (!this.invalidLink) {
                let current = this.computedPosts.find(p => {
                    let url = p.url.toLowerCase();
                    let target = JSON.parse(JSON.stringify(this.link)).toLowerCase();
                    return url.includes(target) || target.includes(url);
                });

                return !!current && this.link.length > 10;
            }

            return false;
        },

        computedProjectId() {
            if (this.projectId) {
                return this.projectId;
            }
            return this.customProject ? this.customProject.value : null;
        },

        computedFolderId() {
            if (this.folderId) {
                return this.folderId;
            }
            return null;
        },
        
        contentTabs() {
            if (this.report.type === 1 || (this.report.type === 3 && this.subStepData === 'users')) {
                return ['all-creators', 'in-project', 'new'];
            }
            return ['in-project', '+-add-new'];
        },


        filteredProjectCreators() {
            // overview
            if (this.step === 5) {
                return this.mapper(this.projectCreators);
            }
            // filterer also maps the results
            return this.filterer(this.projectCreators);
        },
        filteredCreators() {
            // overview
            if (this.step === 5) {
                return this.mapper(this.creators);
            }
            // filterer also maps the results
            return this.filterer(this.creators);
        },

        computedSelectedCreators() {
            let arr = [];
            // concatenates them
            let creators = this.mapper(this.selectedCreators); 

            // makes sure there are no duplicates
            for (let creator of creators) {
                let found = arr.find(element => element.id === creator.id);
                if (!found) {
                    arr.push(creator);
                }
            }
            return arr;
        },

        reportSettings() {
            let options = {};
            let settings = this.report.settings ? this.report.settings : this.settings_data;
            for (let i in settings) {
                if (['feedback', 'groups', 'columns'].includes(i)) {
                    continue;
                }
                if (i === 'platforms') {
                    options[i] = settings[i];
                    continue;
                }
                let elements = {};
                let audience = {};
                for (let j in settings[i]) {
                    let item = settings[i][j];
                    if (typeof item !== 'object' && j.includes('audience')) {
                        audience[j] = item;
                    } else {
                        elements[j] = item;
                    }
                }

                if (Object.keys(audience).length && audience.constructor === Object) {
                    elements['audience'] = audience;
                }

                options[i] = elements;
            }
            return options;
        },

        stepTitle() {
            let val = 'Create Report';
            switch(this.step){
                case 1: 
                    val = 'General';
                    break;
                case 2:
                    if (this.report.type === 3) {
                        val = 'Brand info';
                    } else {
                        val = 'Select Creator Type';
                    }
                    break;
                case 3:
                    val = 'Select data';
                    break;
                case 4:
                    val = 'Select creators';
                    if (this.report.type === 2) {
                        val = 'Select deliverables';
                    }
                    break;
                case 5:
                    if (this.subStepResume === 'groups') {
                        val = 'Groups';
                    } else {
                        val = 'Overview';
                    }
                    break;
                case 6:
                    val = 'Success';
                    break;
                default:                                      
                    break;
            }
            return val;
        },

        computedPosts() {
            let posts = [];
            let current = this.posts.filter(post => !post.is_new);
            for (let post of current) {
                let foundPost = posts.find(p => p.id === post.id);
                if (!foundPost) {
                    posts.push(post);
                }
            }
            return posts.map(p => {
                p.pending = (p.is_video && p.views <= 0) || !p.comments || !p.media || !p.media.length || p.display_url == '';
                return p;
            });
        },

        computedNewPosts() {
            return this.posts.filter(post => post.is_new).map(p => {
                p.pending = (p.is_video && p.views <= 0) || !p.comments || !p.media.length || p.display_url == '';
                return p;
            });
        },

        computedSelectedPosts() {
            let posts = [];
            let current = this.posts.filter(post => {
                return this.selectedPosts.includes(post.id);
            });

            for (let post of current) {
                let foundPost = posts.find(p => p.id === post.id);
                if (!foundPost) {
                    posts.push(post);
                }
            }
            return posts;
        },

        submitButtonLabel() {
            if (this.saving) {
                return 'Saving...';
            }

            if (this.step === 5 && (this.formValues.type.value !== 2 || this.subStepResume !== 'users') && (this.formValues.type.value !== 3 || this.subStepResume !== 'users')) {
                return 'Create';
            }

            if (this.step === 6) {
                return 'Done';
            }

            return 'Next';
        },

        disableNext() {
            let required = [];
            switch (this.step) {
                case 1:
                    required = ['name', 'type'];
                    if (this.report.password_protected) {
                        required.push('password');
                    }
                    if (this.report.type && this.report.type === 1) {
                        if (this.settings_data.allow_feedback) {
                            let once = this.settings_data.feedback.filter(s => s.active);
                            if (!once.length) {
                                return true;
                            }
                        }
                    }
                break;
                case 2:
                    if (this.report.type === 3) {
                        if (this.report.brand_id !== null && this.report.brand_id !== undefined) {
                            if (this.report.brand_id === 'new') {
                                let errors = 0;
                                for (let b_op in this.brand) {
                                    if (this.brand[b_op] === undefined || this.brand[b_op] === null) {
                                        errors++;
                                    }
                                }

                                return !!errors;
                            }
                            return false;
                        }
                        return true;
                    }
                    break;
                case 4: {
                    let type = this.formValues.type.value;
                    let hasCompleteUsers = this.selectedCreators.length && (type === 1 || (type === 3 && this.subStepData === 'users'));
                    let hasCompletePosts = this.selectedPosts.length && (type === 2 || (type === 3 && this.subStepData === 'posts'));
                    if (this.contentTab === 'new') {
                        return true;
                    }
                    return !(hasCompleteUsers || hasCompletePosts);
                }
            }
            return this.allowRequired(required);
        },

        reportLink() {
            return `/reports/${this.report.guid}`;
        }
    },
    
    methods: {
        cancelExisting() {
            this.link = '';
        },

        onAttachExistent(post) {
            this.showManual = false;
            this.togglePost(post.id);
            this.contentTab = 'in-project';
        },

        acceptExisting() {
            let current = this.computedPosts.find(p => {
                let url = p.url.toLowerCase();
                let target = JSON.parse(JSON.stringify(this.link)).toLowerCase();
                return url.includes(target) || target.includes(url);
            });
            if (current) {
                this.link = '';
                this.togglePost(current.id);
                this.contentTab = 'in-project';
            }
        },

        continueSavingPost() {
            if (this.noMediaFn !== null) {
                this.noMediaFn();
                this.noMediaFn = null;
            }
        },
        
        // if you are creating a new creator and you put a handle that already exists
        // you can add that existing creator
        addFoundCreatorToSelectedCreators(user) {
            this.contentTab = 'in-project';
            const mappedArr = this.mapper([{ user }]);
            const mappedUser = mappedArr[0];
            const found = this.selectedCreators.find(element => element.id == user.id);
            if(!found){
                this.selectedCreators.push(mappedUser);
                this.setDescription(user.id);
            }
        },

        cancelHandle() {
            this.requestHandle = false;
            this.userHandle = '';
        },

        refreshGroups() {
            this.groupsKey = !this.groupsKey;
            this.groupsRefreshed = true;
        },

        setFixedLink() {
            let fixedLink = this.fixUrl(this.link);
            if (this.link.length > 10 && this.link !== fixedLink) {
                this.link = fixedLink;
            }
        },
        
        saveGroups(groups) {
            this.settings_data.groups = groups;
        },

        async uploadBrandLogo(e) {
            this.uploadingFile = true;
            let fileData = new FormData();
            fileData.append('image', e.target.files[0]);

            this.$http.post(`/api/files/brand/logo`, fileData).then(async ({data}) => {
                this.$notify({ title: 'Success', text: 'Brand logo submitted successfully', type: 'success' });
                this.brand.logo = data.url;
            }).catch((err) => {
                console.log('upload logo error', err.response);
                this.$notify({ title: 'Something went wrong while uploading logo.', text: 'Try again later', type: 'warn' });
            }).finally(() => {
                this.uploadingFile = false;
            });
        },
        setCreatingCreatorStatus(status) {
            this.creatingCreator = status;
        },
        cancelCreateCreator() {
            this.contentTab = 'in-project';
            this.creatingCreator = false;
        },

        async setFilter() {
            if (this.searchName !== '' || this.searchTag.id !== null) {
                let items = [];
                if (this.searchName !== '') {
                    items.push(`search=${this.searchName}`);
                }

                if (this.searchTag.id !== null) {
                    items.push(`user_tags=${this.searchTag.slug.toLowerCase()}`);
                }

                try{
                    this.searchingCreators = true
                    const { data } = await this.$http.get(`/api/users/search?${items.join('&')}`);
                    this.elasticSearchUserIds = [];
                    if (data) {
                        this.elasticSearchUserIds = data;
                    }
                }catch(e){
                    console.log('setFilter error', e)
                }finally{
                    this.searchingCreators = false
                }
            } else {
                this.elasticSearchUserIds = null;
            }
        },
        filterer(creators) {
            // make sure they have social accounts
            creators = creators.filter(creator => {
                return creator.user && creator.user.social_accounts && creator.user.social_accounts.length;
            }).filter(creator => {
                // if there is a filter set, use it, otherwise return it
                if (this.elasticSearchUserIds !== null) {
                    return this.elasticSearchUserIds.includes(creator.user.id);
                }
                return true;
            });
            return this.mapper(creators);
        },
        mapper(creators) {
            return creators.filter(creator => {
                return !!creator.user;
            }).map(creator => {
                let obj = {}
                obj.id = creator.user.id;
                obj.user = creator.user;
                obj.user.name = creator.user.first_name + ' ' + creator.user.last_name;
                const found = this.selectedCreators.find(element => element.id == creator.user.id);
                if(found){
                    obj.selected = true;
                }else{
                    obj.selected = false;
                }
                obj.description = null;
                obj.additional_info = [];

                obj.open = creator.user.id === this.currentPlatformSelector;
                obj.new = creator.user.id === this.currentNewPlatformSelector;

                let user = this.customPlatforms.find(u => u.id === creator.user.id);

                if (creator.user.social_accounts.find(social_account => social_account.network_id === 2)) {
                    obj.instagram = user ? user.instagram : true;
                }
                if (creator.user.social_accounts.find(social_account => social_account.network_id === 14)) {
                    obj.tiktok = user ? user.tiktok : true;
                }
                if (creator.user.social_accounts.find(social_account => social_account.network_id === 3)) {
                    obj.youtube = user ? user.youtube : true;
                }

                obj.description = user ? user.description : '';
                obj.additional_info = user ? user.additional_info : [];

                obj.tags = creator.user.tags && creator.user.tags.length ? creator.user.tags.map(tag => tag.slug).join(',') : '';

                return obj;
            });
        },

        // here we used to get all creators ever
        async getCreators() {
            this.creators = [];
            this.loadingCreators = true;
            const { data } = await this.$http.get('/api/users/project-users', {
                params: {
                    option: 'accounts',
                    ...(this.elasticSearchUserIds ? { user_ids: this.elasticSearchUserIds } : { })
                }
            });
            this.creators = data.map(creator => {
                return { user: creator };
            });
            this.loadingCreators = false;
        },
        async getBrands() {
            this.brands = [];
            const { data } = await this.$http.get('/api/reports/brands');
            this.brands = await data.map(brand => {
                brand.value = brand.id;
                brand.label = brand.name;
                return brand;
            });

            this.brands.push({
                value: 'new',
                label: 'Create new brand'
            });
        },
        async getTags(){
            const { data } = await this.$http.get(`/api/tags`);
            this.tags = [{
                id: null,
                name: 'All tags',
                slug: 'all'
            }];
            this.createInfluencerFormTags = data.map(tag => {
                return {
                    value: tag.id,
                    label: tag.name
                }
            });

            for (let tag in data) {
                this.tags.push(data[tag]);
            }
        },
        updateReportSettings() {
            let elements = {};
            for (let i in this.settings_data) {
                let element = {};
                let parent = this.settings_data[i];
                if (typeof parent === 'boolean') {
                    element = parent;
                }
                if (i === 'feedback') {
                    element = parent;
                }
                for (let j in parent) {
                    let item = parent[j];
                    if (typeof item === 'object') {
                        let itemObject = {};
                        for (let k in item) {
                            itemObject[k] = item[k];
                        }
                        element[j] = itemObject;
                    } else {
                        if (j.includes('audience')) {
                            element[j] = this.reportSettings[i]['audience'][j];
                        } else {
                            element[j] = this.reportSettings[i][j];
                        }
                    }
                }
                elements[i] = element;
            }
            this.settings_data = elements;
        },

        async getSettings() {
            let id = this.report.id ? this.report.id : 0;
            const { data } = await this.$http.get(`/api/reports/${id}/settings`);
            this.settings_data = data;
        },

        kebabToTitle(string) {
            return snakeCaseToTitle(string);
        },

        toggleOption() {
            this.runChanging(this.reportSettings);
            this.updateReportSettings();
        },

        runChanging(settings) {
            this.$forceUpdate();
            for (let i in settings) {
                for (let j in settings[i]) {
                    let item = settings[i][j];
                    if (typeof item === 'object') {
                        let full = true;
                        for (let k in item) {
                            if (!item[k]) {
                                full = false;
                            }
                        }

                        this.sections[i][j] = full;
                    }
                }
            }
        },

        toggleSettings(parent, index) {
            let value = this.sections[parent][index];
            let items = this.reportSettings[parent][index];

            let parentItems = this.settings_data[parent];
            let childrenItems = parentItems[index];

            let values = childrenItems ? childrenItems : parentItems;

            for (let i in values) {
                if (items[i] !== undefined) {
                    items[i] = value;
                }
            }
            this.updateReportSettings();
        },

        showPartialDataAdvice(){

            if (this.contentTab !== 'new') {

                // if any of the selected users
                for (const user of this.selectedCreators) {

                    // if user has instagram account
                    const foundInstagramSocialAccount = user.user?.social_accounts?.find(element => element.network_id == 2)
                    if(foundInstagramSocialAccount){
                        
                        // if found on custom platforms and does not require instagram, dont show advice
                        const foundOnCustomPlatforms = this.customPlatforms.find(element => element.id == user.user_id)
                        if(foundOnCustomPlatforms && foundOnCustomPlatforms["instagram"] == false){
                            return false
                        }
                    
                        const foundSocialLogin = user.user?.social_logins?.find( socialLogin => socialLogin.network_id == 6 ) || null
                        const isLoggedIn = foundSocialLogin && (foundSocialLogin.long_lived_access_token || foundSocialLogin.fb_business_token) 
                    
                        // is creator logged in ?
                        if(isLoggedIn){
                            return false
                        }

                        // check if audience data is required on settings
                        let settingsHasAudience = false

                        const audience = this.settings_data.audience_followers
                        for (const key in audience) {
                            if (Object.hasOwnProperty.call(audience, key)) {
                                const value = audience[key];
                                if(key == 'audience_geo'){
                                    if(value.cities || value.states || value.countries) settingsHasAudience = true
                                }else{
                                    if(value == true) settingsHasAudience = true
                                }
                            }
                        }

                        if(settingsHasAudience){
                            return 'Creators need to log in with facebook in order to get audience data'
                        }

                        // If you are not logged in with facebook or you dont have an instagram account linked to your facebook login
                        if(this.fbLogin.error){
                            return this.fbLogin.computedMessage('get creator and post data')
                        }

                        // passes

                    }

                }
            }
            
            return false
        },

        toggleCreator(user) {
            const found = this.selectedCreators.find(element => element.id == user.id);
            if(found){
                const index = this.selectedCreators.findIndex(element => element.id == found.id)
                this.selectedCreators.splice(index, 1);
            }else{
                this.selectedCreators.push(user);
            }
            this.$forceUpdate();
        },

        setPlatformSelector(id) {
            this.currentPlatformSelector = this.currentPlatformSelector === id ? null : id;
            this.$forceUpdate();
        },

        setNewPlatformSelector(id, val) {
            this.currentNewPlatformSelector = this.currentNewPlatformSelector === id ? null : (val ? id : null);
            this.getProjectCreators();
            this.$forceUpdate();
        },

        async setHandles(influencer, handles, networks) {
            this.loading = true;
            let user = this.creators.find(c => c.user.id === influencer.id);
            if (user) {
                let items = [];
                let audiences = [];
                for (let network of networks) {
                    let handle = handles[`${network.name}_handle`];
                    if (handle && handle !== '') {
                        items.push({
                            id: network.id,
                            name: network.name,
                            handle,
                            userId: user.user.id
                        });
                        audiences.push({network_id: network.id});
                    }
                }

                let {data} = await this.$http.post(`/api/users/${user.id}/add-networks`, { items });
                if (data) {
                    for (let audience of audiences) {
                        user.user.audience_data.push(audience);
                    }

                    user.user.social_accounts = data.user.social_accounts;
                    this.$emit('update-user', influencer.id, data.user.social_accounts, audiences);
                    this.$forceUpdate();
                }
            }
            this.loading = false;
        },

        setDescription({id, description = ''}) {
            // custom platforms really is an array of objects describing the
            // toggled platforms for every user and also the description and additional infos
            let user = this.customPlatforms.find(u => u.id === id);
            if (user) {
                user.description = description;
            } else {
                this.customPlatforms.push({
                    id,
                    instagram: true,
                    tiktok: true,
                    youtube: true,
                    description
                });
            }

            this.$forceUpdate();
        },

        setCustomFields(id, additional_info = []) {
            // custom platforms really is an array of objects describing the
            // toggled platforms for every user and also the description and additional infos
            let user = this.customPlatforms.find(u => u.id === id);
            if (user) {
                user.additional_info = additional_info;
            } else{
                // very important to set the description here too, otherwise the additional_info
                // wont trigger a refresh on the computed data, and wont be there when the payload is sent
                this.customPlatforms.push({
                    id,
                    instagram: true,
                    tiktok: true,
                    youtube: true,
                    description: '',
                    additional_info
                });
            }
            this.$forceUpdate();
        },

        toggleCreatorNetwork(influencerId, network) {
            let user = this.customPlatforms.find(u => u.id === influencerId);
            if (user) {
                if (network === 'instagram') {
                    user.instagram = !user.instagram;
                }
                if (network === 'tiktok') {
                    user.tiktok = !user.tiktok;
                }
                if (network === 'youtube') {
                    user.youtube = !user.youtube;
                }
            } else {
                this.customPlatforms.push({
                    id: influencerId,
                    instagram: network !== 'instagram',
                    tiktok: network !== 'tiktok',
                    youtube: network !== 'youtube',
                    description: ''
                });
            }

            this.$forceUpdate();
        },

        copyToClipboard () {
            let text = window.location.origin + this.reportLink;
            this.$copyToClipboard(this.$notify, text);
        },

        openReportLink() {
            let a = document.createElement('a');
            a.href = this.reportLink;
            a.target = '_blank';
            a.click();
        },

        togglePost(postId, force = false) {
            let i = this.selectedPosts.indexOf(postId);
            if (i === -1) {
                this.selectedPosts.push(postId);
            } else if (!force) {
                this.selectedPosts.splice(i, 1);
            }
            this.$forceUpdate();
        },

        onCreateCreator(user) {
            this.contentTab = 'all-creators';
            const mappedArr = this.mapper([{ user }]);
            const mappedUser = mappedArr[0];
            this.selectedCreators.push(mappedUser);
            this.setDescription(user.id); 
        },

        async getProjectCreators() {
            this.loadingProjectCreators = true;
            const { data } = await this.$http.get(`/api/projects/${this.computedProjectId}/users`);
            this.projectCreators = data;
            this.loadingProjectCreators = false;
        },

        async getPosts() {
            let new_posts = JSON.parse(JSON.stringify(this.computedNewPosts || [])).map(p => p.id);
            const { data } = await this.$http.get(`/api/projects/${this.computedProjectId}/userDeliverables/posts`);
            this.posts = data.map(p => {
                p.is_new = new_posts.includes(p.id);
                return p;
            });
        },

        async submitForm() {
            if (this.saving) {
                return false;
            }
            
            let payload = {};
            payload.name = this.report.name;
            payload.description = this.report.description;
            payload.password_protected = this.report.password_protected;
            payload.password = this.report.password;
            payload.brand_id = this.report.brand_id;
            payload.type = this.formValues.type.value;
            payload.project_id = this.computedProjectId;
            payload.folder_id = this.computedFolderId;
            payload.influencers = this.computedSelectedCreators.map(influencer => {
                let { id, description, additional_info, instagram = false, tiktok = false, youtube = false, selected} = influencer;
                return { id, description, additional_info, instagram, tiktok, youtube, selected };
            });
            payload.tags = this.selectedTags.map(tag => tag.value);
            payload.settings = {...this.settings_data};
            payload.posts = this.selectedPosts;

            if (this.report.type === 3 && this.report.brand_id === 'new') {
                payload.brand = this.brand;
            }

            this.saving = true;
            if (this.report.type === 1 || this.report.type === 3) {
                this.savingUsers = true;
            }

            try {
                const { data } = await this.$http.post(`/api/reports/create`, payload);
                if (data) {
                    const { password } = this.report;
                    this.savingUsers = false;
                    this.report = data;
                    this.report.password = password;
                    this.step = 6;
                    this.$emit('created-report', data);
                }
            }
            catch(e) {
                console.log(e);
                this.$notify({ title: 'Error saving report!', text: 'Please try again', type: 'error' });
            }
            finally {
                this.saving = false;
            }
        },

        fixUrl(uri) {
            if (uri.includes('http://')) {
                uri = uri.replace('http://', 'https://');
            }

            if (!uri.includes('https://')) {
                uri = 'https://' + uri;
            }

            return uri;
        },
    
        // bookmarklet uncomment both methods
        // async processLink({ scrapperId = null }) {
        //     let payload = { link: this.link };

        //     if (this.userHandle !== '') {
        //         payload.handle = this.userHandle;
        //     }

        //     if (scrapperId !== null) {
        //         payload.scrapperPostId = scrapperId;
        //     }

        //     let { data } = await this.$http.post(`/api/reports/0/add-post`, payload);
        //     if (data) {
        //         this.finishAdd(data);
        //     }
        //     this.savingPost = false;
        // },

        // async handleLink() {
        //     let link = this.link;
        //     let fixedLink = this.fixUrl(this.link);
        //     if (this.link !== fixedLink) {
        //         this.link = fixedLink;
        //     }

        //     this.adviseTiktok = false;
        //     if (link.includes('tiktok')) {
        //         this.adviseTiktok = true;
        //     } else {
        //         this.addPost();
        //     }
        // },

        async addPost() {
            this.promptManual = false;
            // bookmarklet uncomment
            // this.adviseTiktok = false;
            this.requestHandle = false;
            if (!this.savingPost && !this.invalidLink && !this.existingPost) {
                try {
                    this.savingPost = true;
                    // bookmarklet uncomment
                    // scrapperFn({instance: this, link: this.link, callback: this.processLink});                 
                    
                    // bookmarklet comment this whole section
                    let fixedLink = this.fixUrl(this.link);
                    if (this.link !== fixedLink) {
                        this.link = fixedLink;
                    }
                    let payload = { link: this.link };
                    if (this.userHandle !== '') {
                        payload.handle = this.userHandle;
                    }
                    
                    let { data } = await this.$http.post(`/api/reports/0/add-post`, payload);
                    if (data) {
                        if(data.pending){
                            this.summaryModalVisible = true;
                            this.summaryModalPost = data;
                        }
                        this.finishAdd(data);
                    }
                }
                catch(e) {
                    if (e.response && e.response.data && e.response.data.message) {
                        if (e.response.data.pendingHandle) {
                            this.requestHandle = true;
                        } else {
                            this.cancelHandle();
                            recommendationsAlert(this, e.response.data.message, e.response.data.recommendations);
                        }
                    } else if (e.response && e.response.data && e.response.data.error) {
                        this.cancelHandle();
                        this.$notify({
                            title: 'Error',
                            text: e.response.data.error,
                            type: 'error'
                        });
                        this.promptManual = true;
                    } else {
                        this.cancelHandle();
                        this.$notify({
                            title: 'Error',
                            text: 'The content could not be added. Try again later',
                            type: 'error'
                        });
                        this.promptManual = true;
                    }
                }
                finally {
                    this.savingPost = false;
                }
            }
        },

        finishAdd(data) {
            let fn = () => {    
                setTimeout(() => {
                    this.cancelHandle();
                    data.is_new = true;
                    let post = this.posts.find(i => i.id === data.id);
                    if (!post) {
                        this.posts.push(data);
                    }else{
                        // post found but we need to add the is_new property
                        const index = this.posts.findIndex(p => {
                            return p.id == data.id
                        });
                        if(index >= 0){
                            this.posts[index].is_new = true;
                        }
                    }
                    this.togglePost(data.id);
                    let item = this.posts.find(post => {
                        return post.id === data.id;
                    });

                    if (!item) {
                        this.getPosts();
                    }
                    
                    this.showManual = false;
                    this.link = '';
                }, 1000);
            }
            if (data.pending) {
                if (!data.media.length) {
                    this.noMediaFn = fn;
                    // this.$notify({ title: 'Post saved with media not present', text: 'The media files are omitted from the service if the media contains copyrighted material or has been flagged for a copyright violation. Examples of copyrighted material can include audio on reels.', type: 'warn' });
                    // this.pendingPosts.push(data);
                } else {
                    fn();
                    this.$notify({ title: 'Post saved with warnings', text: 'You can invite the user to login in order to get all the post stats.', type: 'warn' });
                    let message = 'There are some fields that need your attention';
                    // if (!data.has_thumbnail) {
                    //     message = 'We could not get the thumbnail for this post. Please check it in order to submit a picture.'
                    // }
                    this.$store.dispatch('restorer/set', {
                        message,
                        success: 'Post added successfully',
                        fail: `Post couldn't be added`,
                        action: true,
                        forceFn: false,
                        data: { undoLabel: 'Check' },
                        fn: () => {
                            this.editPost(data);
                        }
                    });
                }
                this.p_post_key = !this.p_post_key;
            } else {
                fn();
                let title = 'Success';
                let text = 'Post added successfully';
                // if (!data.has_thumbnail) {
                //     title = text;
                //     text = 'We could not get the thumbnail for this post. Please check it in order to submit a picture.'
                // }
                this.$notify({ title, text, type: 'success' });
            }
        },

        editPost(post) {
            this.currentPost = this.posts.find(p => p.id === post.id);
            if (this.currentPost) {
                this.editingPost = true;
            }
        },

        cancelEditingPost() {
            this.currentPost = {profile_item: {}};
            this.editingPost = false;
        },

        finishUpdate() {
            this.getPosts();
            this.cancelEditingPost();
        },
        
        removePending(id) {
            let postIndex = this.pendingPosts.findIndex(p => p.id == id);
            if (postIndex > -1) {
                this.pendingPosts.splice(postIndex, 1);
            }
        },

        previousStep() {
            if (this.step > 1) {
                if (this.step === 4 && this.formValues.type.value === 3) {
                    this.contentTab = 'in-project';
                    if (this.subStepData === 'users') {
                        this.step--;
                    } else if (this.subStepData === 'posts') {
                        this.subStepData = 'users';
                    }
                } else if (this.step === 5 && this.formValues.type.value === 3) {
                    if (this.subStepResume === 'users') {
                        this.step--;
                    } else if (this.subStepResume === 'posts') {
                        this.subStepResume = 'users';
                    }
                } else if (this.step === 5 && this.formValues.type.value === 2) {
                    if (this.subStepResume === 'users') {
                        this.step--;
                    } else if (this.subStepResume === 'groups') {
                        this.subStepResume = 'users';
                    }
                } else {
                    this.step--;
                }
            }
        },

        nextStep() {
            if (this.step < 5) {
                if (this.step === 4 && this.formValues.type.value === 3) {
                    this.contentTab = 'in-project';
                    if (this.subStepData === 'posts') {
                        this.step++;
                    } else if (this.subStepData === 'users') {
                        this.subStepData = 'posts';
                    }
                } else {
                    this.step++;
                }
            } else if (this.step === 6) {
                this.$emit('close');
                this.clearForm();
            } else {
                let fn = () => {
                    let hasCompleteUsers = [1, 3].includes(this.formValues.type.value) && this.reportInfluencers.filter(r => r.selected);
                    let hasCompletePosts = [2, 3].includes(this.formValues.type.value) && this.selectedPosts.length;

                    if (hasCompleteUsers || hasCompletePosts) {
                        this.submitForm();
                    }
                };
                if (this.step === 5 && this.formValues.type.value === 3) {
                    if (this.subStepResume === 'posts') {
                        fn();
                    } else if (this.subStepResume === 'users') {
                        this.subStepResume = 'posts';
                    }
                } else if (this.step === 5 && this.formValues.type.value === 2) {
                    if (this.subStepResume === 'groups') {
                        fn();
                    } else if (this.subStepResume === 'users') {
                        this.subStepResume = 'groups';
                    }
                } else {
                    fn();
                }
            }
        },

        clearForm(){
            this.step = 1;
            
            /* this.report.name = '';
            this.report.description = '';
            this.formValues.type = null;

            this.report.password_protected = false;
            this.report.password = '';

            this.addFeedbackOption = false;
            this.newFeedbackOptionField = '';
            this.newFeedbackOptionType = null; */
        },

        allowRequired(required) {
            let fails = 0;
            for (let i in required) {
                let val = this.report[required[i]];
                if (val === undefined || val === null || val === '' || (Array.isArray(val) && !val.length)) {
                    fails++;
                }
            }
            return !!fails;
        }
    }
}
</script>
<style lang="scss" scoped>
    .create-report-modal-container{
        @apply flex flex-col justify-between;
        > .create-report-modal-content {
            @apply py-5 px-14;
            min-height: 40vh;


            > .step-2{
                //
                > .brand-form{
                    @apply grid grid-cols-3 gap-x-5 gap-y-4;
                    > .logo-field{
                        @apply col-span-1 flex flex-col justify-center;
                        > .loader{
                            @apply h-auto w-5;
                        }
                        > .preview{
                            @apply w-32 h-32 rounded cursor-pointer border-2 border-dashed object-contain;
                        }
                        > input{
                            @apply invisible h-0;
                        }
                    }
                    > .colors-field{
                        @apply col-span-2 flex flex-col gap-2;
                    }
                }
            }


        }
        > .create-report-modal-footer{
            //
            > .actions{
                @apply flex justify-end gap-3 bg-white rounded-br-2xl py-3 px-14;
            }
        }
    }
</style>
<style scoped>
    .scroll-container {
        max-height: 50vh;
        overflow-y: auto;
    }
    .scroll-container-sm {
        max-height: 25vh;
        overflow-y: auto;
    }
    .label-alone {
        min-height: 2.5rem;
    }
</style>

<template>
    <base-modal 
        :title="`Invite this creator to login with ${networkLabel}?`"
        :visible="visible"
        @close="$emit('close')"
        no-padding
    >
        <div class="pt-3 px-14 pb-20">
            <div v-if="step == 'attempt'">
                <p>An invite link will be created, you can copy it or send it by email to the creator to login in on the platform with {{ networkLabel }}</p>
            </div>

            <div v-if="step == 'result'">
                <div class="flex justify-between gap-x-5 my-5">
                    <FormulateInput 
                        :value="computedLink" 
                        readonly 
                        class="w-full" 
                    />
                    <base-button 
                        @action="copyToClipboard()" 
                        :disabled="loading || token == ''"
                        size="sm" 
                        label="Copy"
                    />
                </div>

                <FormulateInput
                    v-model="email"
                    v-if="user"
                    :readonly="!!user.email && user.email != ''"
                    class="w-full mt-2"
                    label="Invite with email"
                    placeholder="example@mail.com"
                    validation="email"
                />

                <FormulateInput
                    type="textarea"
                    class="w-full mt-2"
                    placeholder="Enter message..."
                    v-model="emailMessage"
                    input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-32"
                    :rows="1"
                />
            
            </div>
        </div>

        <div>
            <div class="flex justify-end gap-3 bg-white rounded-2xl py-3 px-14">
                <base-button @action="$emit('close')" :disabled="loading" type="secondary" size="md" bold label="Cancel"/>
                <base-button @action="nextStep" :disabled="loading || sending || (step === 'result' && (token === '' || email === ''))" size="md" :label="submitButtonLabel" bold/>
            </div>
        </div>
    </base-modal>
</template>

<script>
export default {
    name: 'SendInviteModal',
    props: {
        visible: { type: Boolean, default: false},
        networkLabel: {
            type: String,
            default: ''
        },
        networkId: {
            type: Number,
            default: 0
        },
        user: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            token: '',
            step: 'attempt',
            loading: false,
            sending: false,
            email: '',
            emailMessage: ''
        }
    },
    watch: {
        visible: function (val, old) {
            if (val && val !== old) {
                this.step = 'attempt';
                this.token ='';
                this.loading = false;
                this.sending = false;
                this.email = '';
                this.emailMessage = '';
            }
        }
    },
    computed: {
        submitButtonLabel() {
            return this.step === 'result' ? 'Send' : 'Continue';
        },

        computedLink() {
            return this.token !== '' ? `${window.location.origin}/social-login?t=${this.token}` : '';
        }
    },
    methods: {
        nextStep() {
            if (this.step === 'attempt') {
                this.inviteToLoginWithPlatform()
            } else if(this.step === 'result') {
                this.submit();
            }
        },
        copyToClipboard(){
            this.$copyToClipboard(this.$notify, this.computedLink);
        },
        inviteToLoginWithPlatform() {
            this.loading = true;
            this.$http.post('/api/invite/intent', {
                roleId: 13, // creator
                targetEmails: this.user.email,
                targetId: this.user.fromPostsReport ? null : this.user.id,
                networkId: this.networkId
            }).then(async (res) => {
                if (res.data) {
                    this.token = res.data.token;
                    this.step = 'result';

                    this.email = res.data.target_emails;
                }
            }).catch((err) => {
                console.log('sendRequest error', err)
                if (err.response && err.response.data && err.response.data.message) {
                    this.$notify({ title: 'Warning', text: err.response.data.message, type: 'warn' });
                } else {
                    this.$notify({ title: 'Warning', text: 'Something went wrong', type: 'warn' });
                }
            }).finally(() => {
                this.loading = false;
            })  
        },
        async submit() {
            this.loading = true;
            try{
                await this.$http.post('/api/invite/social-login', { 
                    email: this.email,
                    message: this.emailMessage,
                    url: this.computedLink,
                    networkName: this.networkLabel
                });

                this.$notify({ title: 'Success', text: 'Invite sent successfully', type: 'success' });
                this.$emit('close');
            } catch(error) {
                this.$notify({ title: 'Failed to send email', text: 'The email was not sent. Try again later.', type: 'warn' });
                console.log(error);
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>
<template>
<main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="flex w-full py-10 sm:pl-0">
        <div class="w-full flex lg:gap-4 sm:gap-1 sm:items-center sm:justify-between">
            <base-table
                :columns="[
                    {
                        name: 'name',
                        label: 'Name',
                        type: 'link',
                        value: (report) => {
                            return report.name !== '' ? report.name : 'Report without name'
                        }
                    },
                    {
                        name: 'is_default',
                        label: '',
                        sort: false,
                        value: (report) => {
                            return report.is_default ? 'Default' : 'Regular';
                        },
                        filter: {
                            options: [{ label: 'Default', value: 1 }, { label: 'Regular', value: 0 }],
                            placeholder: 'Project type',
                            type: 'select',
                            allSelectedPlaceholder: 'All Project Type'
                        }
                    },
                    {
                        name: 'projects',
                        label: 'Projects',
                        type: 'string',
                        sort: false,
                        value: (report) => {
                            let names = report.projects.map(p => p.name);
                            return names.length ? names.join(', ') : '-';
                        }
                    },
                    {
                        name: 'type',
                        label: 'Type',
                        value: (report) => {
                            return report.type_string;
                        },
                        filter: {
                            options: [{ label: 'Creators', value: 1 }, { label: 'Content', value: 2 }, { label: 'Brands', value: 3 }],
                            placeholder: 'Type',
                            type: 'select',
                            allSelectedPlaceholder: 'All Types'
                        }
                    },
                    {
                        name: 'created_at',
                        label: 'Created at',
                        type: 'date',
                        value: (report) => {
                            return report.created_at;
                        }
                    }
                ]"
                :data="filteredReports"
                :rowActions="[
                    {
                        label: 'Edit',
                        icon: 'pencil-alt',
                        event:'edit',
                        handler: (report) => {
                            $router.push(`/reports/${report.guid}/edit`)
                        }
                    },
                    {
                        label: 'Copy Link',
                        icon: 'link',
                        event: 'copy',
                        handler: (report) => {
                            copyReportLink(report)
                        }
                    },
                    {
                        label: 'Delete',
                        icon: 'trash',
                        theme: 'cancel',
                        event: 'delete',
                        handler: (report) => {
                            deleteReport(report)
                        }
                    },
                ]"
                :onRowClick="(report) => {
                    this.$router.push(`/reports/${report.guid}`)
                }"
                :loading="loading"
                :reload="reload"
                @changePage="changePage"
                @set-sort="changeSort"
                @reload-filters="setFilters"
                :page="currentPage"
                :per-page="perPage"
                :total="total"
                table-id="reports-table"
                container-id="reports-table-container"
                container-styles="min-height: calc(100vh - 340px);max-height: calc(100vh - 340px);overflow-y: scroll;width: 95%;margin: 0 auto;overflow-x: hidden;"
                overflow
                search-class="mx-12 sm:mx-8"
            >
                <template v-slot:filters-footer>
                    <div class="my-2 py-0 mb-12 sm:mx-8 rounded-3xl">
                        <base-button
                            @action="onCreateNewReport()"
                            class="font-bold float-right"
                            size="auto"
                            :iconSize="4"
                            theme="dark"
                            type="secondary"
                            label="Create New Report"
                            icon="plus"
                        />
                    </div>
                </template>
                <div></div>

<!--                <template v-slot:tabs-section>-->
<!--                    <tabs :tabs="['in-projects', 'other']" v-model="currentTab" capitalize custom-class="ml-12 sm:ml-8"/>-->
<!--                </template>-->
            </base-table>
        </div>
    </div>

    <create-report-modal
        :visible="showCreateReportModal"
        @close="closeReport"
        @created-report="getReports"
        :projectId="null"
        :influencers="[]"
        :projects="projects"
        :type="null"
        :key="reportModalKey"
        @refresh="getData(false)"
    />

</main>
</template>

<script>
import CreateReportModal from '@/components/CreateReportModal';
import {strToQuery} from "@/lib/strings";

export default {
    components: {
        CreateReportModal
    },
    data() {
        return {
            reports: [],
            projects: [],
            loading: false,
            reload: false,
            currentTab: 'in-projects',
            reportModalKey: false,
            currentPage: 1,
            total: 35,
            perPage: 10,
            sort: {
              column: 'created_at',
              asc: false
            },
            filters: {
                search: '',
                type: null,
                is_default: null
            },
            showCreateReportModal: false,
        }
    },

    computed: {
        filteredReports() {
            return this.reports.map(report => {
                report.type_string = [1, '1'].includes(report.type) ? 'Creators' : ([2, '2'].includes(report.type) ? 'Content' : 'Brands');
                return report;
            });
        }
    },

    beforeMount() {
        this.$setTitle('Reports');
    },

    mounted() {
        this.getData();
    },

    methods: {
        onCreateNewReport(){
            this.getProjects();
            this.showCreateReportModal = true;
        },
        copyReportLink(report) {
            let text = `${window.location.origin}/reports/${report.guid}`;

            if (window.clipboardData && window.clipboardData.setData) {
                return window.clipboardData.setData('Text', text);
            }
            else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
                let textarea = document.createElement('textarea');
                textarea.textContent = text;
                textarea.style.position = 'fixed';
                document.body.appendChild(textarea);
                textarea.select();

                try {
                    document.execCommand('copy');
                    this.$notify({ title: 'Success', text: 'Link copied to clipboard', type: 'success' })
                } catch (ex) {
                    console.warn('Copy to clipboard failed.', ex);
                    return false;
                } finally {
                    document.body.removeChild(textarea);
                }
            }
        },

        getReports() {
            this.getData(false);
        },

        closeReport() {
            this.showCreateReportModal = false;
            this.reportModalKey = !this.reportModalKey;
        },

        async getProjects() {
            // [get-all-projects]
            const { data } = await this.$http.get('/api/projects', {
                params: {
                    view: 'report-list',
                    page: 1,
                    perPage: 10
                }
            });
            this.projects = data;
        },

        paginationSortingQuery(exceptions, start = '?') {
            return strToQuery(this, exceptions, start);
        },

        async changePage(page, reload = true) {
          this.currentPage = page;
          if (reload) {
            await this.getData(false);
          }
        },

        changeSort(sort, changeColumn = false) {
          if (changeColumn) {
            this.currentPage = 1;
          }
          this.sort = sort;
          this.getData(false, true);
        },

        setFilters(filters) {
            let search = filters.search ? filters.search : ''
            let type = filters.type ? filters.type : ''
            let is_default = filters.is_default !== undefined && filters.is_default !== null && filters.is_default !== '' ? filters.is_default : ''
            this.filters.search = search;
            this.filters.type = type;
            this.filters.is_default = is_default;

            if (search !== '' || type !== null || is_default !== null) {
                this.getData(false, true);
            }
        },

        async getData(showLoader = true, reload = false) {
            this.loading = showLoader;
            this.reload = reload;

            try {
                const { data } = await this.$http.get(`/api/reports?projects&${this.paginationSortingQuery([], '')}`);
                this.reports = data.data;
                this.total = data.total;
            } catch (error) {
                if ( error.response.status >= 400 ) {
                    // Handle the 400+ error

                    this.$notify({ title: 'Error', text: error.response.data.message, type: 'error' });
                    this.$router.push("/home");

                } else {
                    // Handle other errors
                    console.error(error);
                }
            }

            
            this.reload = false;
            this.loading = false;
        },

        async deleteReport(report) {
            this.$swal.fire({
                title: `Are you sure you want to delete the '${report.name}' report?`,
                text: '',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.processDelete(report);
                }
            });
        },

        async processDelete({guid}) {
            await this.$http.post(`/api/reports/${guid}/delete`);
            this.$notify({ title: 'Success', text: 'Report deleted successfully', type: 'success' });
            this.getData(false);
        },
    },
}
</script>

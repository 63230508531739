<template>
    <div class="avatar-list-wrapper">
        <!-- avatars -->
        <div class="avatar-list">
            <template v-if="computedUsers.length > 0">
                    <button
                        v-for="(user, i) in computedUsers.slice(0, computedLength).reverse()"
                        :src="user.avatar" 
                        :key="i"                                         
                        :class="`-ml-1 user-container focus:outline-none cursor-${cursor}`"
                    >                        
                        <avatar
                            class="max-w-none h-6 w-6 rounded-full text-white my-1"
                            style="box-shadow: 0 0 0 1px currentColor"
                            v-tooltip="tooltips ? { content: `${user.first_name} ${user.last_name}`, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } } : {}"
                            :user="user"
                            @callback="openAccount"
                            :size="size"
                        />                        
                    </button>
            </template>
        </div>
        <!-- text-container -->
        <div :class="`text-container ${theme}`">
            <div 
                v-if="computedLength == 1" 
                :class="`
                    name
                    ${fontBold ? `font-bold` : ``}
                    ${hasSingleLink ? `has-single-link` : ``}
                    ${nameClasses}
                `" 
                @click="openAccount"
            >
                {{ parseUnicodeCharacters(computedUsers[0].first_name + " " + computedUsers[0].last_name) }}
            </div>

            <div class="length" v-if="showExtra && computedUsers.length - computedLength > 0">
                +{{computedUsers.length - computedLength }}
            </div>

        </div>
    </div>
</template>

<script>
import { parseUnicodeCharacters } from '../lib/strings';
import { defaultUserObject } from '../lib/user';
export default {
    props: {
        users:{
            required: true
        },
        showAmount:{
            type: Number            
        },
        showExtra:{
            type: Boolean
        },
        projectId:{
            type: Number
        },
        theme: {
            type: String,
            default: 'light'
        },
        tooltips: {
            type: Boolean,
            default: true
        },
        fontBold: {
            type: Boolean,
            default: false
        },
        internal: {
            type: Boolean,
            default: false
        },
        cursor: {
            type: String,
            default: 'default'
        },
        size: {
            type: String,
            default: 'small'
        },
        nameClasses: {
            type: String,
            required: false
        }
    },

    components: {

    },

    data() {
        return {
            parseUnicodeCharacters
        }
    },

    computed: {
        
        computedUsers(){
            return this.users.map(user => {
                if(!user || user == null || user == undefined){
                    return defaultUserObject
                }
                return user;
            });
        },

        computedLength() {
            let amount = this.showAmount ? this.showAmount : this.computedUsers.length;
            return amount <= this.computedUsers.length ? amount : this.computedUsers.length
        },

        hasSingleLink() {
            let user = this.computedUsers[0];
            return user.account && Array.isArray(user.account) && user.account.length === 1;
        }
    },

    methods: {
        openAccount() {
            let link = '#';
            if (this.computedLength === 1) {
                let user = this.computedUsers[0];
                let account = user.account && Array.isArray(user.account) ? user.account[0] : null;
                if (account) {
                    if (user.direct || account.direct) {
                        link = user.social_url || account.social_url || account.handle || account.social_id;
                    } else {
                        link = `https://${account.network.name}.com/${this.getHandle(account)}`;
                    }
                    if (this.internal) {
                        let a = document.createElement('a');
                        a.href = link;
                        a.click();
                    } else {
                        window.open(link, '_blank');
                    }
                }
            }
        },

        getHandle(account) {
            let handle = account.network.name === 'tiktok' ? '@' : '';
            let username = account.handle ? account.handle : (account.social_id ? account.social_id : '');
            handle = handle + username;
            return handle;
        }
    }
}
</script>

<style scoped>
    .user-container:hover{
        z-index: 1;
    }
</style>
<style lang="scss" scoped>
    .avatar-list-wrapper{
        @apply h-8 flex;
        // space-x-2
        > * + * {
            margin-left: 0.5rem;
        }

        > .avatar-list{
            // -space-x-1
            > * + * {
                margin-left: -0.25rem;   
            }
            @apply flex flex-row-reverse flex-shrink-0 items-center ml-1;
        }
        > .text-container{
            @apply my-auto text-ps;
            &.light{
                @apply text-purple-m-secondary;
            }
            &.dark{
                @apply text-white;
            }
            > .name{
                @apply truncate text-left flex items-center;
                &.has-single-link{
                    @apply rounded pl-2 h-8 cursor-pointer;
                    &:hover{
                        @apply bg-white bg-opacity-10;
                    }
                }
            }
            > .length{
                @apply w-10 truncate text-left;
            }
        }
    }

</style>

<template>
    <div class="container text-left" :class="{'report-container': isReport}">
        <div class="header">
            <div class="flex justify-between items-center">
                <div class="flex items-center gap-x-3 relative" :class="isReport ? 'w-full' : ''">
                    <avatar size="medium" :user="postedBy" :class="{'w-12': isReport}"/>
                    <div>
                        <div class="flex flex-col">
                            <span class="font-bold">{{postedBy.name}}</span>
                            <span>@{{postedBy.handle}}</span>
                        </div>
                    </div>
                    <div class="absolute right-0 mr-4" v-if="isReport && postItem && postItem.profile_item">
                        <a :href="postItem.profile_item.uri || `https://www.twitter.com/${postItem.profile_item.username}`" target="_blank">
                            <base-icon :name="postItem.network.name" style="display: inline-block;"/>
                        </a>
                    </div>
                </div>
            </div>
            <div class="description">{{description}}</div>
        </div>
        <div class="content relative">
            <media-viewer :file="files[page]" v-if="files.length" :is-report="isReport" @set-fail="$emit('set-fail')"/>
            
            <div v-if="page > 0" class="navigation-container left">
                <div @click="$emit('navigate', 'prev')" class="button z-50">
                    <base-icon name="chevron-left" />
                </div>
            </div>
            <div v-if="page < files.length-1" class="navigation-container right">
                <div @click="$emit('navigate', 'next')" class="button z-50">
                    <base-icon name="chevron-right" />
                </div>
            </div>
            <div class="absolute bg-black text-white p-1 text-xs rounded bottom-4 left-4">{{ views | numeral('0a') }} view{{ views > 1 ? 's' : '' }}</div>
        </div>
        <div class="footer">
            <div class="flex items-center">
                <div class="text-pxs pr-1 my-2">{{postedAt}} · </div>
            </div>
            <div class="separator"/>
            <div class="stats">
                <div class="flex w-full items-center gap-x-2">
                    <div class="w-full text-pxs">
                        <span class="font-bold">{{ shares | numeral('0a') }}</span> Retweet{{ shares > 1 ? 's' : '' }}
                    </div>
                    <!-- <div class="w-full text-pxs">
                        <span class="font-bold">{{ saves | numeral('0a') }}</span> Saves{{ saves > 1 ? 's' : '' }}
                    </div> -->
                    <div class="w-full text-pxs">
                        <span class="font-bold">{{ comments | numeral('0a') }}</span> Comment{{ comments > 1 ? 's' : '' }}
                    </div>
                    <div class="w-full text-pxs">
                        <span class="font-bold">{{ likes | numeral('0a') }}</span> Like{{ likes > 1 ? 's' : '' }}
                    </div>
                </div>
            </div>
            <div class="separator"/>
            <div class="interaction">
                <div class="button" :class="{'cursor-not-allowed': isReport}">
                    <base-icon :size="5" name="tw-comment"/>
                </div>
                <div class="button" :class="{'cursor-not-allowed': isReport}">
                    <base-icon :size="5" name="tw-retweet"/>
                </div>
                <div class="button" :class="{'cursor-not-allowed': isReport}">
                    <base-icon :size="5" name="tw-like"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MediaViewer from '../MediaViewer.vue'
export default {
    props: ['files', 'description', 'postedBy', 'postedAt', 'page', 'isReport', 'postItem'],
    components: {
        MediaViewer
    },
    data(){
        return {
            isOpen: false
        }
    },
    computed: {
        shares() {
            return this.postItem && this.postItem.shares ? this.postItem.shares : 0;
        },
        // saves() {
        //     return this.postItem && this.postItem.saves ? this.postItem.saves : 0;
        // },
        comments() {
            return this.postItem && this.postItem.comments ? this.postItem.comments : 0;
        },
        likes() {
            return this.postItem && this.postItem.likes ? this.postItem.likes : 0;
        },
        views() {
            return this.postItem && this.postItem.views ? this.postItem.views : 0;
        }
    }
}
</script>

<style lang="scss" scoped>
    .container{
        @apply bg-white p-4 rounded-lg;
        width: 450px;
        &.report-container {
            max-width: 100% !important;
        }
    }
    .description{
        @apply my-2;
    }
    .stats{
        @apply flex justify-between items-center my-3;
    }
    .reactions{
        @apply flex items-center;
        & .reaction{
            @apply rounded-full flex justify-center items-center h-5 w-5;
            
            &:not(:first-child){
                margin-left: -2px;
            }    

            &.like{
                background-color: #0573e6
            }
            &.love{
                background-color: #ee3150
            }
        }
    }
    .interaction{
        @apply flex justify-between items-center mx-3 my-2;
        & .button{
            @apply flex items-center gap-x-2;
            & span{
                @apply text-h6 font-bold;
            }
        }
    }
    .separator{
        @apply w-full bg-gray-400;
        height: 1px;
    }
    .navigation-container{
        position: absolute;
        bottom: 0px;
        top: 0px;
        display: flex;
        align-items: center;            

        &.left{
            left: -40px;
        }

        &.right{
            right: -40px;
        }

        & .button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #bbbbbd;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            cursor: pointer;
            box-shadow: #a3a3a3 -1px 0px 13px 0px;
        }
    }
</style>
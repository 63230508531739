export const loader = {
    namespaced: true,
    state: {
        visible: false,
    },
    mutations: {
        setVisible(state, val) {
            state.visible = val;
        },
    },
    actions: {
        async show({commit}) {
            await commit('setVisible', true);
        },
        async hide({commit}) {
            await commit('setVisible', false);
        },
    },
    modules: {
    },
    getters : {
    }
};
<template>
    <!-- https://codepen.io/team/codepen/pen/axwMPo -->
    <div>
        <!-- overlay -->
        <transition name="fade" appear>
            <div 
                v-if="visible" 
                @click="$emit('close')" 
                class="modal-overlay"
            />
        </transition>

        <!-- modal -->
        <transition name="slide" appear>
            <div v-if="visible" class="modal" role="dialog">
                <div class="flex h-full">
                    <div class="w-full h-full flex flex-col justify-between">
                        <div v-if="!hideHeader" class="pt-10 pb-5 px-14 relative flex justify-between items-center">
                            <div v-if="centerTitle && !hideCloseButton" class="w-7"></div>
                            <div class="flex justify-start items-center">
                                <slot name="title-icon"></slot>
                                <h3 class="text-2xl leading-6 font-bold text-black flex items-center">
                                    {{ title }}
                                </h3>
                            </div>

                            <div v-if="!hideCloseButton" class="flex justify-end items-center gap-x-6 cursor-pointer">
                                <base-icon @action="$emit('close')" name="x" border background="bg-white" :size="7" :icon-size="3"/>
                            </div>
                        </div>

                        <div :class="{'flex-grow flex-shrink': true, 'pt-5 pb-10 px-14': !noPadding}">
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        props: {
            visible: {},
            title: {},
            hideHeader: {},
            hideCloseButton: {},

            mode: {
                type: String,
                default: 'dark'
            },
            noPadding: {
                type: Boolean,
                default: false
            },
            centerTitle: {
                type: Boolean,
                default: false
            },
            preventEscape: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            visible: function (val, old) {
                if (!this.preventEscape && val !== old) {
                    if (val) {
                        window.addEventListener('keyup', this.handleKeys);
                    } else {
                        window.removeEventListener('keyup', this.handleKeys);
                    }
                }
            }
        },
        methods: {
            handleKeys(event) {
                if (event.which === 27) { // esc
                    this.$emit('close');
                }
            },
        }
    }
</script>

<style scoped lang="scss">

    .modal {
        @apply absolute fixed right-0 top-0 bottom-0 rounded-l-2xl max-w-lg shadow-lg bg-tan-m z-100 transform-none w-full overflow-y-auto;
    }

    .modal h1 {
        margin: 0 0 1rem;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.2s linear;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .modal-overlay {
        @apply absolute fixed inset-0 z-100 cursor-pointer;
        content: '';
        background-color: rgb(14 9 44 / 90%);
        &.opaque{
            background-color: rgb(14 9 44 / 100%);
        }
    }

    .slide-enter-active, .slide-leave-active {
        transition: transform 0.2s cubic-bezier(0.5, 0, 0.5, 1);
    }
    .slide-enter, .slide-leave-to {
        transform: translateX(100%);
    }

</style>
const CryptoJS = require('crypto-js');
import { isValidString } from '../common'

const levenshtein = (a, b) => {
    const matrix = [];

    for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1,
                    matrix[i][j - 1] + 1,
                    matrix[i - 1][j] + 1
                    );
            }
        }
    }

    return matrix[b.length][a.length];
};

function bestCommonSubstring(s1, s2) {
    const s1Len = s1.length;
    const s2Len = s2.length;
    let bestSubstring = '';

    const lcsMatrix = Array.from({ length: s1Len + 1 }, () => new Array(s2Len + 1).fill(0));

    for (let i = 1; i <= s1Len; i++) {
        for (let j = 1; j <= s2Len; j++) {
            if (s1[i - 1] === s2[j - 1]) {
                lcsMatrix[i][j] = lcsMatrix[i - 1][j - 1] + 1;
                const currentSubstring = s1.slice(i - lcsMatrix[i][j], i);
                if (jaroWinkler(currentSubstring, s2) > jaroWinkler(bestSubstring, s2)) {
                    bestSubstring = currentSubstring;
                }
            } else {
                lcsMatrix[i][j] = 0;
            }
        }
    }

    return bestSubstring;
}

function hyphenToCamelCase(str) {
    return str.replace(/-([a-z])/g, function (match, letter) {
        return letter.toUpperCase();
    });
}


function jaroWinkler2(s1, s2, p = 0.1) {
    const s1Len = s1.length;
    const s2Len = s2.length;

    if (s1Len === 0 || s2Len === 0) return 0;

    let m = 0;
    const h = Math.floor(Math.max(s1Len, s2Len) / 2) - 1;

    const matches1 = new Array(s1Len).fill(false);
    const matches2 = new Array(s2Len).fill(false);

    for (let i = 0; i < s1Len; i++) {
        const start = Math.max(0, i - h);
        const end = Math.min(i + h + 1, s2Len);
        for (let j = start; j < end; j++) {
            if (matches2[j]) continue;
            if (s1[i] !== s2[j]) continue;
            matches1[i] = matches2[j] = true;
            m++;
            break;
        }
    }

    if (m === 0) return 0;

    let t = 0;
    let k = 0;
    for (let i = 0; i < s1Len; i++) {
        if (!matches1[i]) continue;
        while (!matches2[k]) k++;
        if (s1[i] !== s2[k]) t++;
        k++;
    }

    t /= 2;

    const jaro = (m / s1Len + m / s2Len + (m - t) / m) / 3;
    let l = 0;
    for (let i = 0; i < s1Len; i++) {
        if (s1[i] === s2[i]) l++;
        else break;
    }

    return jaro + Math.min(l, 4) * p * (1 - jaro);
}

function findBestMatch(target, query) {
    target = target.toLowerCase();
    query = query.toLowerCase();
    const words = target.split(/\s+/);

    let bestSimilarity = 0;
    for (const word of words) {
        const similarity = jaroWinkler2(word, query);
        if (similarity > bestSimilarity) {
            bestSimilarity = similarity;
        }
    }

    return bestSimilarity;
}

function jaroWinkler(s1, s2, p = 0.1) {
    let m = 0.0;
    const s1Len = s1.length;
    const s2Len = s2.length;
    const h = Math.floor(Math.max(s1Len, s2Len) / 2) - 1;

    let matches1 = new Array(s1Len);
    let matches2 = new Array(s2Len);

    for (let i = 0; i < s1Len; i++) matches1[i] = false;
        for (let i = 0; i < s2Len; i++) matches2[i] = false;

            for (let i = 0; i < s1Len; i++) {
                const start = Math.max(0, i - h);
                const end = Math.min(i + h + 1, s2Len);
                for (let j = start; j < end; j++) {
                    if (matches2[j]) continue;
                    if (s1[i] !== s2[j]) continue;
                    matches1[i] = matches2[j] = true;
                    m++;
                    break;
                }
            }

            if (m === 0) return 0;

            let t = 0;
            let k = 0;
            for (let i = 0; i < s1Len; i++) {
                if (!matches1[i]) continue;
                while (!matches2[k]) k++;
                if (s1[i] !== s2[k]) t++;
                k++;
            }

            t /= 2;

            const jaro = (m / s1Len + m / s2Len + (m - t) / m) / 3;
            let l = 0;
            for (let i = 0; i < s1Len; i++) {
                if (s1[i] === s2[i]) l++;
                else break;
            }

            return jaro + Math.min(l, 4) * p * (1 - jaro);
        }

        const snakeCaseToTitle = (string) => {
            let sentence = string.toLowerCase().split("_");

            for (let i = 0; i < sentence.length; i++) {
                sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
            }

            return sentence.join(" ");
        };

        const toSnakeCase = (string) => {
            return string.replace(/\W+/g, " ")
            .split(/ |\B(?=[A-Z])/)
            .map(word => word.toLowerCase())
            .join('_');
        };

        const timeToSeconds = (timeStr) => {
            const [hours, minutes, seconds] = timeStr.split(':');
            return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
        };

        const hexToRgbSum = (hex) => {
            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            if(result){
                let r = parseInt(result[1], 16);
                let g = parseInt(result[2], 16);
                let b = parseInt(result[3], 16);          
                return r+g+b;
                // 0 is black, 765 is white.
            } else
                return 0;
        };

        const hexToTextColor = (hex) => {
const threshold = 400 // 0 is black, 765 is white
return hexToRgbSum(hex) > threshold ? '#000000' : '#ffffff'
};

// (str) '12,31231,23.123' => '123,123,123.12'
// (number) 123123123.123 => '123,123,123.12' 
const formatBudget = (budget) => {

    if(!budget) return budget

        if(typeof budget == 'number') budget = budget.toString()

            // remove commas
            var formattedBudget = budget.replace(/,/g, '')

        // admit only numbers commas(auto) and dots
        formattedBudget = formattedBudget.replace(/[^0-9.]/g, '');

        // limit to 2 decimals
        if(formattedBudget.split('.')[1] && formattedBudget.split('.')[1].length >= 2){
            formattedBudget = parseFloat(formattedBudget).toFixed(2).toString()
        }

    // put commas
        formattedBudget = formattedBudget.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return formattedBudget
    }

    // (str) '12,31231,23.123' => '123,123,123'
    // (number) 123123123.123 => '123,123,123'
    function formatNumberString(str, addCurrencySymbol) {
        // Check if the input is a string
        if (typeof str !== 'string') {
            console.error('formatNumberString: Input is not a string');
            return str;
        }

        const suffixes = {
            'K': 1000,
            'M': 1000000,
            'B': 1000000000
        };

        let numberWithSuffix = str.toUpperCase().replace(/,/g, '').trim();
        let multiplier = 1;

        for (const suffix in suffixes) {
            if (numberWithSuffix.endsWith(suffix)) {
                numberWithSuffix = numberWithSuffix.replace(suffix, '');
                multiplier = suffixes[suffix];
                break;
            }
        }

        let number = parseFloat(numberWithSuffix) * multiplier;
        if (isNaN(number)) {
            return str;
        }

        if (addCurrencySymbol) {
            return '$' + number.toLocaleString();
        }

        return number.toLocaleString();
    }




// budget string to double
    const budgetToDouble = (str) => {
        if(!str) return str
// remove commas
            if(typeof str == 'number') return str
                var d = str.replace(/,/g, '')
            return parseFloat(d)
        }

        const strToQuery = (instance, exceptions = [], start = '?') => {
            const { currentPage = 1, perPage = 10, filters, sort } = instance;

            let appliedFilters = [];
            if (!exceptions.includes('page')) {
                appliedFilters.push(`page=${currentPage}`);
            }
            if (!exceptions.includes('perPage')) {
                appliedFilters.push(`perPage=${perPage}`);
            }

            for (let i in filters) {
                if (!exceptions.includes(i) && filters[i] !== null && filters[i] !== undefined && filters[i] !== '') {
                    appliedFilters.push(`${i}=${filters[i]}`);
                }
            }

            if (sort.column !== '') {
                appliedFilters.push(`sort=${sort.column}`);
                appliedFilters.push(`sort_asc=${sort.asc}`);
            }

            return start + appliedFilters.join('&');
        };

        const validateEmail = (email) => {
            let regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return String(email).toLowerCase().match(regexp);
        };

        const formatDateTimezone = (str) => {
            if(typeof str != 'string' || str.trim == '') return str;
            var dateArray = str.split("-");
            var year = dateArray[0];
            var month = parseInt(dateArray[1], 10) - 1;
            var d = dateArray[2];
            var _entryDate = new Date(year, month, d);
            return _entryDate;
        };

        const validateUrl = (string) => {
            let url;

            try {
                url = new URL(string);
            } catch (_) {
                return false;
            }

            return url.protocol === "http:" || url.protocol === "https:";
        };

        const validateObject = (object, properties = []) => {
            let errors = 0;
            for (let property of properties) {
                if (object[property] === undefined || object[property] === '') {
                    errors++;
                }
            }
            return !errors;
        };

        const validateNumbers = (object, properties = []) => {
            let errors = 0;
            for (let property of properties) {
                if (object[property] === undefined || object[property] === '' || object[property] < 0) {
                    errors++;
                }
            }
            return !errors;
        };

        function isNotNull(val){
            return val && val !== undefined && val !== null; 
        }

        const validString = (str) => {
            return isNotNull(str) && typeof str === 'string' && str.trim() !== '';
        }

        const formatNumber = (number) => {
            return number >= 0 && number <= 999 ? number.toString() :
            number >= 1000 && number <= 9999 ? (number / 1000).toFixed(1).toString() + 'k' :
            number >= 10000 && number <= 999999 ? Math.floor(number / 1000).toString() + 'k' :
            number >= 1000000 && number <= 9999999 ? (number / 1000000).toFixed(1).toString() + 'm' :
            number >= 10000000 ? Math.floor(number / 1000000).toString() + 'm' : ''
        };

        const isBase64 = (string) =>{
            let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
            return base64regex.test(string);
        };

        const prepareAsB64 = (string) => {
            if (string && string !== '' && isBase64(string)) {
                return 'data:image/png;base64, ' + string;
            }

            return string;
        };

        const decryptStr = (string, passphrase) => {
            const bytes = CryptoJS.AES.decrypt(string, passphrase);
            return bytes.toString(CryptoJS.enc.Utf8);
        };

        const jwtDecrypt = (string) => {
            let base64Url = string.split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        }

        const capitalize = (string) => {  
            if(!string || typeof string !== 'string') return ''        
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        const computedIndexStr = (number = 0) => {
            let id = number ? number.toString() : '0';
            if(id.length == 1) id = `${0}${id}` // force it to be two digits
            id = `${id[id.length-1]}${id[id.length-2]}` // grab last two digits
            id = parseInt(id)

            const ranges = [
                {from: 0, to: 7, i: 0},
                {from: 7, to: 14, i: 1},
                {from: 14, to: 21, i: 2},
                {from: 21, to: 28, i: 3},
                {from: 28, to: 35, i: 4},
                {from: 35, to: 42, i: 5},
                {from: 42, to: 50, i: 6},
                {from: 50, to: 57, i: 7},
                {from: 57, to: 64, i: 8},
                {from: 64, to: 71, i: 9},
                {from: 71, to: 78, i: 10},
                {from: 78, to: 85, i: 11},
                {from: 85, to: 92, i: 12},
                {from: 92, to: 99, i: 13}
            ]

            let index = 0
            for (const range of ranges) {
                if(range.from <= id && range.to > id){
                    index = range.i
                }
            }

            return index;
        }

        // converts unicode characters to the actual emoji
        const parseUnicodeCharacters = (str) => {
            const parser = new DOMParser();
            const decodedString = parser.parseFromString(`<!doctype html><body>${str}`, 'text/html').body.textContent;
            return decodedString;
        }

        const slugify = (str) => {
            return String(str)
              .normalize('NFKD') // split accented characters into their base characters and diacritical marks
              .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
              .trim() // trim leading or trailing whitespace
              .toLowerCase() // convert to lowercase
              .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
              .replace(/\s+/g, '-') // replace spaces with hyphens
              .replace(/-+/g, '-'); // remove consecutive hyphens
        }
    
        const stringHasCommas = (str) => {
            if(isValidString(str)){
                return str.includes(',');
            }
            return false;
        }

        const stringToArray = (str) => {
            let arr = [];
            if(isValidString(str)){
                str = str.trim(); // format str
                if(stringHasCommas(str)){
                    const strArr = str.split(',');
                    for (let item of strArr) {
                        // validate item
                        if(isValidString(item)){
                            // if its valid, trim it and add it to the array
                            arr.push(item.trim());
                        }
                        // if its not a valid string then dont push to array                     
                    }

                }else{
                    arr.push(str); // push whole str as array item
                }
            }
            return arr;
        }

        export {
            snakeCaseToTitle,
            isBase64,
            prepareAsB64,
            toSnakeCase,
            timeToSeconds,
            formatBudget,
            budgetToDouble,
            strToQuery,
            validateEmail,
            validateUrl,
            validateObject,
            validateNumbers,
            formatDateTimezone,
            validString,
            formatNumber,
            hexToTextColor,
            decryptStr,
            formatNumberString,
            jwtDecrypt,
            levenshtein,
            jaroWinkler,
            findBestMatch,
            hyphenToCamelCase,
            capitalize,
            computedIndexStr,
            parseUnicodeCharacters,
            slugify,
            stringHasCommas,
            stringToArray
        };

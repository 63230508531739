// [recruitment]
export const countries = [
    {
        name: "Afghanistan",
        id: "AF",
        value: "Afghanistan"
    },
    {
        name: "Albania",
        id: "AL",
        value: "Albania"
    },
    {
        name: "Algeria",
        id: "DZ",
        value: "Algeria"
    },
    {
        name: "American Samoa",
        id: "AS",
        value: "American Samoa"
    },
    {
        name: "Andorra",
        id: "AD",
        value: "Andorra"
    },
    {
        name: "Angola",
        id: "AO",
        value: "Angola"
    },
    {
        name: "Anguilla",
        id: "AI",
        value: "Anguilla"
    },
    {
        name: "Antarctica",
        id: "AQ",
        value: "Antarctica"
    },
    {
        name: "Antigua and Barbuda",
        id: "AG",
        value: "Antigua and Barbuda"
    },
    {
        name: "Argentina",
        id: "AR",
        value: "Argentina"
    },
    {
        name: "Armenia",
        id: "AM",
        value: "Armenia"
    },
    {
        name: "Aruba",
        id: "AW",
        value: "Aruba"
    },
    {
        name: "Australia",
        id: "AU",
        value: "Australia"
    },
    {
        name: "Austria",
        id: "AT",
        value: "Austria"
    },
    {
        name: "Azerbaijan",
        id: "AZ",
        value: "Azerbaijan"
    },
    {
        name: "Bahamas (the)",
        id: "BS",
        value: "Bahamas (the)"
    },
    {
        name: "Bahrain",
        id: "BH",
        value: "Bahrain"
    },
    {
        name: "Bangladesh",
        id: "BD",
        value: "Bangladesh"
    },
    {
        name: "Barbados",
        id: "BB",
        value: "Barbados"
    },
    {
        name: "Belarus",
        id: "BY",
        value: "Belarus"
    },
    {
        name: "Belgium",
        id: "BE",
        value: "Belgium"
    },
    {
        name: "Belize",
        id: "BZ",
        value: "Belize"
    },
    {
        name: "Benin",
        id: "BJ",
        value: "Benin"
    },
    {
        name: "Bermuda",
        id: "BM",
        value: "Bermuda"
    },
    {
        name: "Bhutan",
        id: "BT",
        value: "Bhutan"
    },
    {
        name: "Bolivia (Plurinational State of)",
        id: "BO",
        value: "Bolivia (Plurinational State of)"
    },
    {
        name: "Bonaire, Sint Eustatius and Saba",
        id: "BQ",
        value: "Bonaire, Sint Eustatius and Saba"
    },
    {
        name: "Bosnia and Herzegovina",
        id: "BA",
        value: "Bosnia and Herzegovina"
    },
    {
        name: "Botswana",
        id: "BW",
        value: "Botswana"
    },
    {
        name: "Bouvet Island",
        id: "BV",
        value: "Bouvet Island"
    },
    {
        name: "Brazil",
        id: "BR",
        value: "Brazil"
    },
    {
        name: "British Indian Ocean Territory (the)",
        id: "IO",
        value: "British Indian Ocean Territory (the)"
    },
    {
        name: "Brunei Darussalam",
        id: "BN",
        value: "Brunei Darussalam"
    },
    {
        name: "Bulgaria",
        id: "BG",
        value: "Bulgaria"
    },
    {
        name: "Burkina Faso",
        id: "BF",
        value: "Burkina Faso"
    },
    {
        name: "Burundi",
        id: "BI",
        value: "Burundi"
    },
    {
        name: "Cabo Verde",
        id: "CV",
        value: "Cabo Verde"
    },
    {
        name: "Cambodia",
        id: "KH",
        value: "Cambodia"
    },
    {
        name: "Cameroon",
        id: "CM",
        value: "Cameroon"
    },
    {
        name: "Canada",
        id: "CA",
        value: "Canada"
    },
    {
        name: "Cayman Islands (the)",
        id: "KY",
        value: "Cayman Islands (the)"
    },
    {
        name: "Central African Republic (the)",
        id: "CF",
        value: "Central African Republic (the)"
    },
    {
        name: "Chad",
        id: "TD",
        value: "Chad"
    },
    {
        name: "Chile",
        id: "CL",
        value: "Chile"
    },
    {
        name: "China",
        id: "CN",
        value: "China"
    },
    {
        name: "Christmas Island",
        id: "CX",
        value: "Christmas Island"
    },
    {
        name: "Cocos (Keeling) Islands (the)",
        id: "CC",
        value: "Cocos (Keeling) Islands (the)"
    },
    {
        name: "Colombia",
        id: "CO",
        value: "Colombia"
    },
    {
        name: "Comoros (the)",
        id: "KM",
        value: "Comoros (the)"
    },
    {
        name: "Congo (the Democratic Republic of the)",
        id: "CD",
        value: "Congo (the Democratic Republic of the)"
    },
    {
        name: "Congo (the)",
        id: "CG",
        value: "Congo (the)"
    },
    {
        name: "Cook Islands (the)",
        id: "CK",
        value: "Cook Islands (the)"
    },
    {
        name: "Costa Rica",
        id: "CR",
        value: "Costa Rica"
    },
    {
        name: "Croatia",
        id: "HR",
        value: "Croatia"
    },
    {
        name: "Cuba",
        id: "CU",
        value: "Cuba"
    },
    {
        name: "Curaçao",
        id: "CW",
        value: "Curaçao"
    },
    {
        name: "Cyprus",
        id: "CY",
        value: "Cyprus"
    },
    {
        name: "Czechia",
        id: "CZ",
        value: "Czechia"
    },
    {
        name: "Côte d'Ivoire",
        id: "CI",
        value: "Côte d'Ivoire"
    },
    {
        name: "Denmark",
        id: "DK",
        value: "Denmark"
    },
    {
        name: "Djibouti",
        id: "DJ",
        value: "Djibouti"
    },
    {
        name: "Dominica",
        id: "DM",
        value: "Dominica"
    },
    {
        name: "Dominican Republic (the)",
        id: "DO",
        value: "Dominican Republic (the)"
    },
    {
        name: "Ecuador",
        id: "EC",
        value: "Ecuador"
    },
    {
        name: "Egypt",
        id: "EG",
        value: "Egypt"
    },
    {
        name: "El Salvador",
        id: "SV",
        value: "El Salvador"
    },
    {
        name: "Equatorial Guinea",
        id: "GQ",
        value: "Equatorial Guinea"
    },
    {
        name: "Eritrea",
        id: "ER",
        value: "Eritrea"
    },
    {
        name: "Estonia",
        id: "EE",
        value: "Estonia"
    },
    {
        name: "Eswatini",
        id: "SZ",
        value: "Eswatini"
    },
    {
        name: "Ethiopia",
        id: "ET",
        value: "Ethiopia"
    },
    {
        name: "Falkland Islands (the) [Malvinas]",
        id: "FK",
        value: "Falkland Islands (the) [Malvinas]"
    },
    {
        name: "Faroe Islands (the)",
        id: "FO",
        value: "Faroe Islands (the)"
    },
    {
        name: "Fiji",
        id: "FJ",
        value: "Fiji"
    },
    {
        name: "Finland",
        id: "FI",
        value: "Finland"
    },
    {
        name: "France",
        id: "FR",
        value: "France"
    },
    {
        name: "French Guiana",
        id: "GF",
        value: "French Guiana"
    },
    {
        name: "French Polynesia",
        id: "PF",
        value: "French Polynesia"
    },
    {
        name: "French Southern Territories (the)",
        id: "TF",
        value: "French Southern Territories (the)"
    },
    {
        name: "Gabon",
        id: "GA",
        value: "Gabon"
    },
    {
        name: "Gambia (the)",
        id: "GM",
        value: "Gambia (the)"
    },
    {
        name: "Georgia",
        id: "GE",
        value: "Georgia"
    },
    {
        name: "Germany",
        id: "DE",
        value: "Germany"
    },
    {
        name: "Ghana",
        id: "GH",
        value: "Ghana"
    },
    {
        name: "Gibraltar",
        id: "GI",
        value: "Gibraltar"
    },
    {
        name: "Greece",
        id: "GR",
        value: "Greece"
    },
    {
        name: "Greenland",
        id: "GL",
        value: "Greenland"
    },
    {
        name: "Grenada",
        id: "GD",
        value: "Grenada"
    },
    {
        name: "Guadeloupe",
        id: "GP",
        value: "Guadeloupe"
    },
    {
        name: "Guam",
        id: "GU",
        value: "Guam"
    },
    {
        name: "Guatemala",
        id: "GT",
        value: "Guatemala"
    },
    {
        name: "Guernsey",
        id: "GG",
        value: "Guernsey"
    },
    {
        name: "Guinea",
        id: "GN",
        value: "Guinea"
    },
    {
        name: "Guinea-Bissau",
        id: "GW",
        value: "Guinea-Bissau"
    },
    {
        name: "Guyana",
        id: "GY",
        value: "Guyana"
    },
    {
        name: "Haiti",
        id: "HT",
        value: "Haiti"
    },
    {
        name: "Heard Island and McDonald Islands",
        id: "HM",
        value: "Heard Island and McDonald Islands"
    },
    {
        name: "Holy See (the)",
        id: "VA",
        value: "Holy See (the)"
    },
    {
        name: "Honduras",
        id: "HN",
        value: "Honduras"
    },
    {
        name: "Hong Kong",
        id: "HK",
        value: "Hong Kong"
    },
    {
        name: "Hungary",
        id: "HU",
        value: "Hungary"
    },
    {
        name: "Iceland",
        id: "IS",
        value: "Iceland"
    },
    {
        name: "India",
        id: "IN",
        value: "India"
    },
    {
        name: "Indonesia",
        id: "ID",
        value: "Indonesia"
    },
    {
        name: "Iran (Islamic Republic of)",
        id: "IR",
        value: "Iran (Islamic Republic of)"
    },
    {
        name: "Iraq",
        id: "IQ",
        value: "Iraq"
    },
    {
        name: "Ireland",
        id: "IE",
        value: "Ireland"
    },
    {
        name: "Isle of Man",
        id: "IM",
        value: "Isle of Man"
    },
    {
        name: "Israel",
        id: "IL",
        value: "Israel"
    },
    {
        name: "Italy",
        id: "IT",
        value: "Italy"
    },
    {
        name: "Jamaica",
        id: "JM",
        value: "Jamaica"
    },
    {
        name: "Japan",
        id: "JP",
        value: "Japan"
    },
    {
        name: "Jersey",
        id: "JE",
        value: "Jersey"
    },
    {
        name: "Jordan",
        id: "JO",
        value: "Jordan"
    },
    {
        name: "Kazakhstan",
        id: "KZ",
        value: "Kazakhstan"
    },
    {
        name: "Kenya",
        id: "KE",
        value: "Kenya"
    },
    {
        name: "Kiribati",
        id: "KI",
        value: "Kiribati"
    },
    {
        name: "Korea (the Democratic People's Republic of)",
        id: "KP",
        value: "Korea (the Democratic People's Republic of)"
    },
    {
        name: "Korea (the Republic of)",
        id: "KR",
        value: "Korea (the Republic of)"
    },
    {
        name: "Kuwait",
        id: "KW",
        value: "Kuwait"
    },
    {
        name: "Kyrgyzstan",
        id: "KG",
        value: "Kyrgyzstan"
    },
    {
        name: "Lao People's Democratic Republic (the)",
        id: "LA",
        value: "Lao People's Democratic Republic (the)"
    },
    {
        name: "Latvia",
        id: "LV",
        value: "Latvia"
    },
    {
        name: "Lebanon",
        id: "LB",
        value: "Lebanon"
    },
    {
        name: "Lesotho",
        id: "LS",
        value: "Lesotho"
    },
    {
        name: "Liberia",
        id: "LR",
        value: "Liberia"
    },
    {
        name: "Libya",
        id: "LY",
        value: "Libya"
    },
    {
        name: "Liechtenstein",
        id: "LI",
        value: "Liechtenstein"
    },
    {
        name: "Lithuania",
        id: "LT",
        value: "Lithuania"
    },
    {
        name: "Luxembourg",
        id: "LU",
        value: "Luxembourg"
    },
    {
        name: "Macao",
        id: "MO",
        value: "Macao"
    },
    {
        name: "Madagascar",
        id: "MG",
        value: "Madagascar"
    },
    {
        name: "Malawi",
        id: "MW",
        value: "Malawi"
    },
    {
        name: "Malaysia",
        id: "MY",
        value: "Malaysia"
    },
    {
        name: "Maldives",
        id: "MV",
        value: "Maldives"
    },
    {
        name: "Mali",
        id: "ML",
        value: "Mali"
    },
    {
        name: "Malta",
        id: "MT",
        value: "Malta"
    },
    {
        name: "Marshall Islands (the)",
        id: "MH",
        value: "Marshall Islands (the)"
    },
    {
        name: "Martinique",
        id: "MQ",
        value: "Martinique"
    },
    {
        name: "Mauritania",
        id: "MR",
        value: "Mauritania"
    },
    {
        name: "Mauritius",
        id: "MU",
        value: "Mauritius"
    },
    {
        name: "Mayotte",
        id: "YT",
        value: "Mayotte"
    },
    {
        name: "Mexico",
        id: "MX",
        value: "Mexico"
    },
    {
        name: "Micronesia (Federated States of)",
        id: "FM",
        value: "Micronesia (Federated States of)"
    },
    {
        name: "Moldova (the Republic of)",
        id: "MD",
        value: "Moldova (the Republic of)"
    },
    {
        name: "Monaco",
        id: "MC",
        value: "Monaco"
    },
    {
        name: "Mongolia",
        id: "MN",
        value: "Mongolia"
    },
    {
        name: "Montenegro",
        id: "ME",
        value: "Montenegro"
    },
    {
        name: "Montserrat",
        id: "MS",
        value: "Montserrat"
    },
    {
        name: "Morocco",
        id: "MA",
        value: "Morocco"
    },
    {
        name: "Mozambique",
        id: "MZ",
        value: "Mozambique"
    },
    {
        name: "Myanmar",
        id: "MM",
        value: "Myanmar"
    },
    {
        name: "Namibia",
        id: "NA",
        value: "Namibia"
    },
    {
        name: "Nauru",
        id: "NR",
        value: "Nauru"
    },
    {
        name: "Nepal",
        id: "NP",
        value: "Nepal"
    },
    {
        name: "Netherlands (the)",
        id: "NL",
        value: "Netherlands (the)"
    },
    {
        name: "New Caledonia",
        id: "NC",
        value: "New Caledonia"
    },
    {
        name: "New Zealand",
        id: "NZ",
        value: "New Zealand"
    },
    {
        name: "Nicaragua",
        id: "NI",
        value: "Nicaragua"
    },
    {
        name: "Niger (the)",
        id: "NE",
        value: "Niger (the)"
    },
    {
        name: "Nigeria",
        id: "NG",
        value: "Nigeria"
    },
    {
        name: "Niue",
        id: "NU",
        value: "Niue"
    },
    {
        name: "Norfolk Island",
        id: "NF",
        value: "Norfolk Island"
    },
    {
        name: "Northern Mariana Islands (the)",
        id: "MP",
        value: "Northern Mariana Islands (the)"
    },
    {
        name: "Norway",
        id: "NO",
        value: "Norway"
    },
    {
        name: "Oman",
        id: "OM",
        value: "Oman"
    },
    {
        name: "Pakistan",
        id: "PK",
        value: "Pakistan"
    },
    {
        name: "Palau",
        id: "PW",
        value: "Palau"
    },
    {
        name: "Palestine, State of",
        id: "PS",
        value: "Palestine, State of"
    },
    {
        name: "Panama",
        id: "PA",
        value: "Panama"
    },
    {
        name: "Papua New Guinea",
        id: "PG",
        value: "Papua New Guinea"
    },
    {
        name: "Paraguay",
        id: "PY",
        value: "Paraguay"
    },
    {
        name: "Peru",
        id: "PE",
        value: "Peru"
    },
    {
        name: "Philippines (the)",
        id: "PH",
        value: "Philippines (the)"
    },
    {
        name: "Pitcairn",
        id: "PN",
        value: "Pitcairn"
    },
    {
        name: "Poland",
        id: "PL",
        value: "Poland"
    },
    {
        name: "Portugal",
        id: "PT",
        value: "Portugal"
    },
    {
        name: "Puerto Rico",
        id: "PR",
        value: "Puerto Rico"
    },
    {
        name: "Qatar",
        id: "QA",
        value: "Qatar"
    },
    {
        name: "Republic of North Macedonia",
        id: "MK",
        value: "Republic of North Macedonia"
    },
    {
        name: "Romania",
        id: "RO",
        value: "Romania"
    },
    {
        name: "Russian Federation (the)",
        id: "RU",
        value: "Russian Federation (the)"
    },
    {
        name: "Rwanda",
        id: "RW",
        value: "Rwanda"
    },
    {
        name: "Réunion",
        id: "RE",
        value: "Réunion"
    },
    {
        name: "Saint Barthélemy",
        id: "BL",
        value: "Saint Barthélemy"
    },
    {
        name: "Saint Helena, Ascension and Tristan da Cunha",
        id: "SH",
        value: "Saint Helena, Ascension and Tristan da Cunha"
    },
    {
        name: "Saint Kitts and Nevis",
        id: "KN",
        value: "Saint Kitts and Nevis"
    },
    {
        name: "Saint Lucia",
        id: "LC",
        value: "Saint Lucia"
    },
    {
        name: "Saint Martin (French part)",
        id: "MF",
        value: "Saint Martin (French part)"
    },
    {
        name: "Saint Pierre and Miquelon",
        id: "PM",
        value: "Saint Pierre and Miquelon"
    },
    {
        name: "Saint Vincent and the Grenadines",
        id: "VC",
        value: "Saint Vincent and the Grenadines"
    },
    {
        name: "Samoa",
        id: "WS",
        value: "Samoa"
    },
    {
        name: "San Marino",
        id: "SM",
        value: "San Marino"
    },
    {
        name: "Sao Tome and Principe",
        id: "ST",
        value: "Sao Tome and Principe"
    },
    {
        name: "Saudi Arabia",
        id: "SA",
        value: "Saudi Arabia"
    },
    {
        name: "Senegal",
        id: "SN",
        value: "Senegal"
    },
    {
        name: "Serbia",
        id: "RS",
        value: "Serbia"
    },
    {
        name: "Seychelles",
        id: "SC",
        value: "Seychelles"
    },
    {
        name: "Sierra Leone",
        id: "SL",
        value: "Sierra Leone"
    },
    {
        name: "Singapore",
        id: "SG",
        value: "Singapore"
    },
    {
        name: "Sint Maarten (Dutch part)",
        id: "SX",
        value: "Sint Maarten (Dutch part)"
    },
    {
        name: "Slovakia",
        id: "SK",
        value: "Slovakia"
    },
    {
        name: "Slovenia",
        id: "SI",
        value: "Slovenia"
    },
    {
        name: "Solomon Islands",
        id: "SB",
        value: "Solomon Islands"
    },
    {
        name: "Somalia",
        id: "SO",
        value: "Somalia"
    },
    {
        name: "South Africa",
        id: "ZA",
        value: "South Africa"
    },
    {
        name: "South Georgia and the South Sandwich Islands",
        id: "GS",
        value: "South Georgia and the South Sandwich Islands"
    },
    {
        name: "South Sudan",
        id: "SS",
        value: "South Sudan"
    },
    {
        name: "Spain",
        id: "ES",
        value: "Spain"
    },
    {
        name: "Sri Lanka",
        id: "LK",
        value: "Sri Lanka"
    },
    {
        name: "Sudan (the)",
        id: "SD",
        value: "Sudan (the)"
    },
    {
        name: "Suriname",
        id: "SR",
        value: "Suriname"
    },
    {
        name: "Svalbard and Jan Mayen",
        id: "SJ",
        value: "Svalbard and Jan Mayen"
    },
    {
        name: "Sweden",
        id: "SE",
        value: "Sweden"
    },
    {
        name: "Switzerland",
        id: "CH",
        value: "Switzerland"
    },
    {
        name: "Syrian Arab Republic",
        id: "SY",
        value: "Syrian Arab Republic"
    },
    {
        name: "Taiwan (Province of China)",
        id: "TW",
        value: "Taiwan (Province of China)"
    },
    {
        name: "Tajikistan",
        id: "TJ",
        value: "Tajikistan"
    },
    {
        name: "Tanzania, United Republic of",
        id: "TZ",
        value: "Tanzania, United Republic of"
    },
    {
        name: "Thailand",
        id: "TH",
        value: "Thailand"
    },
    {
        name: "Timor-Leste",
        id: "TL",
        value: "Timor-Leste"
    },
    {
        name: "Togo",
        id: "TG",
        value: "Togo"
    },
    {
        name: "Tokelau",
        id: "TK",
        value: "Tokelau"
    },
    {
        name: "Tonga",
        id: "TO",
        value: "Tonga"
    },
    {
        name: "Trinidad and Tobago",
        id: "TT",
        value: "Trinidad and Tobago"
    },
    {
        name: "Tunisia",
        id: "TN",
        value: "Tunisia"
    },
    {
        name: "Turkey",
        id: "TR",
        value: "Turkey"
    },
    {
        name: "Turkmenistan",
        id: "TM",
        value: "Turkmenistan"
    },
    {
        name: "Turks and Caicos Islands (the)",
        id: "TC",
        value: "Turks and Caicos Islands (the)"
    },
    {
        name: "Tuvalu",
        id: "TV",
        value: "Tuvalu"
    },
    {
        name: "Uganda",
        id: "UG",
        value: "Uganda"
    },
    {
        name: "Ukraine",
        id: "UA",
        value: "Ukraine"
    },
    {
        name: "United Arab Emirates (the)",
        id: "AE",
        value: "United Arab Emirates (the)"
    },
    {
        name: "United Kingdom of Great Britain and Northern Ireland (the)",
        id: "GB",
        value: "United Kingdom of Great Britain and Northern Ireland (the)"
    },
    {
        name: "United States Minor Outlying Islands (the)",
        id: "UM",
        value: "United States Minor Outlying Islands (the)"
    },
    {
        name: "United States of America (the)",
        id: "US",
        value: "United States of America (the)"
    },
    {
        name: "Uruguay",
        id: "UY",
        value: "Uruguay"
    },
    {
        name: "Uzbekistan",
        id: "UZ",
        value: "Uzbekistan"
    },
    {
        name: "Vanuatu",
        id: "VU",
        value: "Vanuatu"
    },
    {
        name: "Venezuela (Bolivarian Republic of)",
        id: "VE",
        value: "Venezuela (Bolivarian Republic of)"
    },
    {
        name: "Viet Nam",
        id: "VN",
        value: "Viet Nam"
    },
    {
        name: "Virgin Islands (British)",
        id: "VG",
        value: "Virgin Islands (British)"
    },
    {
        name: "Virgin Islands (U.S.)",
        id: "VI",
        value: "Virgin Islands (U.S.)"
    },
    {
        name: "Wallis and Futuna",
        id: "WF",
        value: "Wallis and Futuna"
    },
    {
        name: "Western Sahara",
        id: "EH",
        value: "Western Sahara"
    },
    {
        name: "Yemen",
        id: "YE",
        value: "Yemen"
    },
    {
        name: "Zambia",
        id: "ZM",
        value: "Zambia"
    },
    {
        name: "Zimbabwe",
        id: "ZW",
        value: "Zimbabwe"
    },
    {
        name: "Åland Islands",
        id: "AX",
        value: "Åland Islands"
    }
]
import axios from 'axios';

export const company = {
    namespaced: true,
    state: {
        company: {},
        companyLoading: false,
        companyNotFound: false
    },
    mutations: {
        setCompany(state, val) {
            state.company = val;
        },
        setCompanyLoading(state, val){
            state.companyLoading = val
        },
        setCompanyNotFound(state, val){
            state.companyNotFound = val
        }
    },
    actions: {
        async getCompany({commit, state}){

            if (state.company && state.company.id) {
                return;
            }

            await commit('setCompanyLoading', true)
            try{
                const { data } = await axios.get('/api/companies');
                if(data){
                    await commit('setCompany', data)
                }else{
                    await commit('setCompanyNotFound', true)
                }
            }catch(err){
                await commit('setCompanyNotFound', true)
            }finally{
                await commit('setCompanyLoading', false)
            }
        }
    }
};
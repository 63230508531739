<template>
    <div @mouseenter="setOptions" @mouseleave="showOptions = false" class="relative" :class="{'hidden' : !visible}">

        <div v-if="showOptions && !editing && !disabled" class="absolute origin-top-right right-0 top-0 mt-0">
            <options-dropdown                                
                :options="[                                                
                    {label: 'Edit', icon: 'pencil-alt', event:'edit'},
                    {label: 'Delete', icon: 'trash', event:'delete'}
                ]"
                @edit="editing = true"
                @delete="deleteComment"
            >
            </options-dropdown>                            
        </div>
        <div class="flex gap-4 items-start">
            <avatar :user="user"/>
            <div class="flex flex-col items-start justify-end gap-1" v-if="!editing">
                <div class="text-pm text-purple-m-secondary">
                    {{ comment.comment }}
                    <span v-if="comment.created_at !== comment.updated_at" class="text-pxs font-normal text-gray-m-disable">(Edited)</span>
                </div>
                <template v-if="!editing && hasAttachment">
                    <img :src="`${baseUrl}/api/files/render/${comment.attachment.guid}?token=${comment.token}`"/>
                </template>
                <div class="text-pxs text-gray-m-disable">
                    <time datetime="2020-11-18">{{ comment.created_at | moment(timeStyle === 'from' ? 'from' : 'MMMM DD, h:mm A') }}</time>
                </div>
            </div>
            <div class="flex flex-col w-full items-start justify-end gap-4" v-else>
                <FormulateInput
                    type="textarea"
                    class="w-full"
                    placeholder="Write a comment..."
                    v-model="editedComment"
                    input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-12"
                    :rows="1"
                />
                <div class="w-full flex justify-end gap-2">
                    <base-button
                        @action="editing = false"
                        size="sm" 
                        type="secondary"
                        theme="dark"                                                          
                        label="Cancel"
                        class="font-bold"                                                     
                    />
                    <base-button
                        @action="editComment"
                        size="sm" 
                        theme="dark"                                                          
                        label="Save"
                        class="font-bold"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AvatarList from './AvatarList';

export default {
    props: {
        comment: {
            type: Object,
            required: true
        },
        currentUser: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false
        }
    },

    components: {
        AvatarList,
    },

    data() {
        return {
            showOptions: false,            
            editing: false,
            edited: false,
            editedComment: '',
            visible: true,
            timeStyle: 'from',  // 'from', 'date'
        }
    },

    computed: {
        user() {
            if(this.comment.user_id != null && !this.comment.user){
                return {
                    first_name: 'Deleted',
                    last_name: 'User'
                }
            } else {
                return this.comment.user;
            }
        },
        baseUrl() {
            return this.$http.defaults.baseURL;
        },
        hasAttachment() {
            return this.comment.attachment_id !== null && this.comment.attachment !== undefined && this.comment.attachment !== null;
        }
    },

    watch: {
        editing: function (val) {
            this.editedComment = val ? this.comment.comment : '';
        }
    },

    methods: {
        setOptions() {
            this.showOptions = this.user.id === this.currentUser.id;
        },
        async editComment() {
            if (this.editedComment !== '') {
                const {data} = await this.$http.post(`/api/comments/${this.comment.id}`, { comment: this.editedComment });
                if (data) {
                    this.editedComment = '';
                    this.$emit('updateComment', this.comment.id);
                    this.$notify({ title: 'Success', text: 'Comment edited successfully', type: 'success' });
                }
                else {
                    this.$notify({ title: 'Error', text: 'The comment can not be edited. Try again later', type: 'error' })
                }                
            }
            this.editing = false;
        },

        async deleteComment() {
            let id = this.comment.id;
            const {data} = await this.$http.post(`/api/comments/${id}/delete`);
            if (data) {
                this.visible = false;
                this.$store.dispatch('restorer/set', {
                    resourceLabel: 'Comment',
                    route: `/api/comments/${id}/restore`,
                    action: data.show_undo,
                    forceFn: true,
                    fn: (time = 6100) => {
                        setTimeout(() => {
                            this.$emit('updateComment', id);
                        }, time);
                    }
                });
            }
        }
    }
}
</script>

<template>
    <div class="header-container">
        <img class="logo" src="../../assets/images/logo.svg">

        <template v-if="invitation && invitation.company">
            <h2 class="title">
                You were invited to join <span class="text-green-m-main">{{invitation.company.name}}</span> as {{invitation.role.display_name}}
            </h2>
            <h3 class="description">
                Set up your account
            </h3>
        </template>

        <template v-if="isGuest">
            <h2 class="title">
                Client Register
            </h2>
            <h3 class="description">
                Sign up with <span class="text-green-m-main">{{company.name}}</span>
            </h3>
        </template>

        <template v-if="!$route.query.t">
            <h2 class="title">{{ title }}</h2>
            <h3 class="description">{{ description }} with <span class="text-green-m-main">{{company.name}}</span></h3>
        </template>

        <div class="host">
            <p>{{host}}</p>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        invitation: {},
        title: {
            default: 'Creator Register'
        },
        description: {
            default: 'Set up your account'
        }
    },
    computed: {
        ...mapGetters(['isGuest', 'company']),
        host(){
            return window.location.host
        }
    }
}
</script>
<style lang="scss" scoped>
    .header-container{
        @apply mt-20;
        @media (min-width: 640px) {
            @apply mx-auto w-full max-w-md;
        }
        .logo{
            @apply mx-auto h-12 w-auto;
        }
        .title{
            @apply mt-6 text-center text-2xl leading-9 font-extrabold text-white;
        }
        .description{
            @apply mt-1 text-center text-lg leading-9 font-extrabold text-white;
        }
        .host{
            @apply flex justify-center mt-2;
            > p{
                @apply text-sm text-gray-m;
            }
        }
    }
</style>
<template>
    <div class="ratings">

        <!-- header -->
        <div class="header">
            <div class="title">
                Ratings
                <span>({{ ratings ? ratings.length : 0 }})</span>
            </div>
            <base-button
                v-if="isAdminOrHigher || isProjectManager" 
                size="lg"
                theme="dark"
                secondaryIcon="pencil"
                label="Create Review"
                class="font-bold"
                @action="createUserRatingModalVisible = true"
                :disabled="loading || !!ownReview"
                v-tooltip="!!ownReview ? { content: 'You already wrote a review for this creator', classes: 'v-tooltip-black' } : {}"
            />
        </div>

        <!-- content -->
        <div class="content">

            <!-- ratings list -->
            <div class="ratings-list">
                
                <!-- loading state -->
                <base-icon v-if="loading" name="loader" />
                
                <template v-else>

                    <!-- empty state -->
                    <div v-if="!ratings.length">Currently there are no ratings for this creator</div>
                    
                    <!-- ratings list -->
                    <rating
                        v-for="(rating, index) in ratings" 
                        :key="index"
                        :rating="rating"
                        @edit="editRatingIntent(rating)"
                        @delete="deleteRatingIntent(rating)"
                    />

                </template>

            </div>
            
            <!-- see more button -->
            <div class="w-full flex justify-center mt-12">
                <base-button
                    v-if="moreResultsPending"
                    label="See more"
                    @action="onSeeMore()"
                />               
            </div>
            
        </div>

        <!-- create rating modal -->
        <create-user-rating-modal
            :visible="createUserRatingModalVisible"
            @close="createUserRatingModalVisible = false"
            @reload="getRatings(true, true)"
            :user="user"
        />

        <!-- edit rating modal -->
        <edit-user-rating-modal
            :visible="editUserRatingModalVisible"
            @close="editUserRatingModalVisible = false"
            @reload="getRatings(true, true)"
            :rating="editingRating"
            :user="user"
        />

        <!-- delete rating modal -->
        <delete-user-rating-modal
            :visible="deleteUserRatingModalVisible"
            @close="deleteUserRatingModalVisible = false"
            @reload="getRatings(true, true)"
            :rating="deletingRating"
        />
        
    </div>
</template>

<script>
import CreateUserRatingModal from './CreateUserRatingModal.vue';
import EditUserRatingModal from './EditUserRatingModal.vue';
import DeleteUserRatingModal from './DeleteUserRatingModal.vue'
import Rating from './Rating.vue'
import { notifyCatchError } from '@/common';
import { mapGetters } from 'vuex';
const pageSize = 15;
export default {
    props: ['user'],
    components: {
        Rating,
        CreateUserRatingModal,
        EditUserRatingModal,
        DeleteUserRatingModal
    },
    data(){
        return {
            ratings: [],
            loading: false,

            page: 1,
            pageSize,
            moreResultsPending: false,

            createUserRatingModalVisible: false,

            editUserRatingModalVisible: false,
            editingRating: null,

            deleteUserRatingModalVisible: false,
            deletingRating: null
        }
    },
    mounted(){
        this.getRatings(false)
    },
    computed: {
        ...mapGetters(['me', 'isAdminOrHigher', 'isProjectManager']),
        ownReview(){
            return this.ratings.find(element => element.created_by == this.me.id)
        }
    },
    methods: {
        getRatings(reloadUser, resetPaging = false){
            this.loading = true;
            if(resetPaging){
                this.page = 1; 
                this.ratings = [];
            }
            this.$http.get(`/api/user-ratings/${this.user.id}?page=${this.page}&pageSize=${this.pageSize}`).then(({data}) => {
                
                this.ratings = this.ratings.concat(data)
                
                // if we recieve exactly the page size, there are results pending
                if(data.length == this.pageSize){
                    this.moreResultsPending = true;
                }else{
                    this.moreResultsPending = false;
                }

                if(reloadUser){
                    // on create, edit or delete review, reload user's company scoped rating
                    this.$emit('reload')
                }
            }).catch(err => {
                notifyCatchError(err, this.$notify)
            }).finally(() => {
                this.loading = false
            })

        },
        editRatingIntent(rating){
            this.editingRating = rating; 
            this.editUserRatingModalVisible = true;
        },
        deleteRatingIntent(rating){
            this.deletingRating = rating;
            this.deleteUserRatingModalVisible = true;
        },
        onSeeMore(){
            this.page++;
            this.getRatings(false)
        }
    }
}
</script>
<style lang="scss" scoped>
    .ratings{
        >.header{
            @apply flex justify-between items-center px-12 py-10;
            >.title{
                @apply text-h2 font-bold text-purple-m-secondary;
                >span{
                    @apply text-gray-m-disable;
                }
            }
        }
        >.content{
            @apply flex flex-col justify-between;
            >.ratings-list{
                @apply flex flex-row flex-wrap gap-10 pb-10 px-12;
                >.rating{
                    @apply relative;
                    width: 500px;
                    .options-container{
                        @apply absolute origin-top-right right-0 top-0 mt-0;
                    }
                    .content{
                        @apply flex gap-4 items-start;
                        >.text-wrapper{
                            @apply flex flex-col items-start justify-end gap-1;
                            >.text{
                                @apply text-pm text-purple-m-secondary;
                                >span{
                                    @apply text-pxs font-normal text-gray-m-disable;
                                }
                            }
                            >.date{
                                @apply text-pxs text-gray-m-disable;
                            }
                        }
                        >.edit{
                            @apply flex flex-col w-full items-start justify-end gap-4;
                            >.actions-wrapper{
                                @apply w-full flex justify-end gap-2;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

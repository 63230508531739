const recommendationsAlert = (instance, title, messages = []) => {
    let text = '';
    if (messages && Array.isArray(messages) && messages.length) {
        text = text + '<ul>';
        for (let r = 0; r < messages.length; r++) {
            text = text +`<li> - ${messages[r]}</li>`;
        }
        text = text + '</ul>';
    }
    instance.$notify({ title, text, type: 'error' });
};

export {
    recommendationsAlert,
};

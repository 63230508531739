<template>
<div class="main">

    <div class="header">
        <div class="title">
           Profile Settings
        </div>    
        <div class="tabs-container">
            <!-- Pending Tabs: Notifications, Billing -->
            <tabs theme="light" :tabs="['Profile', 'Accounts']" v-model="viewTab" />
        </div>
    </div>
    
    <tab :visible="viewTab === 'Profile'">
        <profile />
    </tab>

    <tab :visible="viewTab === 'Accounts'">
        <linked-accounts />
    </tab>

    <tab :visible="viewTab === 'IG Ads'">
        <ig-ads />
    </tab>

</div>

</template>

<script>
import LinkedAccounts from './LinkedAccounts.vue';
import IgAds from './IgAds.vue'
import Profile from './Profile.vue'
export default {
    components: {
        LinkedAccounts,
        Profile,
        IgAds
    },
    data() {
        return {
            viewTab: 'Profile'
        }
    },
    watch: {
        viewTab(val){
            this.$router.push({ query: { ...this.$route.query, tab: val }})
        }
    },

    beforeMount() {
        this.$setTitle('Edit Profile');
    },

    mounted() {
        if (this.$route.query.tab) {
            this.viewTab = this.$route.query.tab;
        }
    }
}
</script>
<style lang="scss" scoped>
    .main{
        @apply flex-1 min-w-0 bg-white h-screen overflow-auto;
        .header{
            @apply flex flex-col py-10 px-12 pb-0 gap-10;
            .title{
                @apply text-h2 font-bold text-purple-m-secondary;
            }
            .tabs-container{
                @apply flex justify-between items-center;
            }
        }
    }
</style>

<template>
    <div class="ad-item">
        
        <div class="ad-left">

            <div class="id-container"> 
                <FormulateInput
                    v-if="editing"
                    type="text"
                    class="w-full"
                    placeholder="1987236918723"
                    label="Ad ID"
                    validation="required"
                    v-model="ad.ad_id"
                    :disabled="loading"
                />
                <div v-else class="id">Ad ID: {{ad.ad_id}}</div>
                <base-icon name="loader" v-if="loading" />
            </div>
            <div>Status: <b>{{ad.payload.configured_status}}</b></div>
            <!-- <div>Created at: <b>{{moment(ad.payload.created_time).format('LL')}}</b></div> -->
            <div class="dates">
                <div class="date" v-if="ad.insights && ad.insights.date_start">
                    <div class="label">Ad started on:</div>
                    <div class="value"><base-icon name="calendar"/>{{moment(ad.insights.date_start).format('LL')}}</div>
                </div>
                <div class="date" v-if="ad.insights && ad.insights.date_stop">
                    <div class="label">Ad ended on:</div>
                    <div class="value"><base-icon name="calendar"/>{{moment(ad.insights.date_stop).format('LL')}}</div>
                </div>
            </div>

            <div class="details-button-container">
                <base-button
                    :label="expanded ? 'Hide details' : 'View details'"
                    type="label"
                    :icon="expanded ? 'chevron-down' : 'chevron-right'"
                    @action="expanded = !expanded" 
                    size="lg"
                    bold
                />
            </div>
            <template v-if="expanded">

                <div class="detail-section" v-if="ad.creative">
                    <div class="title">Creative</div>
                    <!-- <div v-if="ad.creative.id">Id: <b>{{ad.creative.id}}</b></div> -->
                    <div v-if="ad.creative.instagram_permalink_url">Permalink url: <b>{{ad.creative.instagram_permalink_url}}</b></div>
                    <div v-if="ad.creative.source_instagram_media_id">Source Instagram media id: <b>{{ad.creative.source_instagram_media_id}}</b></div>
                    <div v-if="ad.creative.effective_instagram_story_id">Effective Instagram story id: <b>{{ad.creative.effective_instagram_story_id}}</b></div>
                </div>

                <div class="detail-section">
                    <div class="title">General Info</div>
                    <div>Reach: <b>{{ad.insights.reach | numeral('0,0a') }}</b></div>
                    <div>Impressions: <b>{{ad.insights.impressions | numeral('0,0a')}}</b></div>
                    <div>Inline post engagement: <b>{{ad.insights.inline_post_engagement | numeral('0,0a')}}</b></div>
                </div>
                
                <div class="detail-section">
                    <div class="title">Actions</div>
                    <div class="action-list">
                        <div 
                            class="item" 
                            v-for="(action, _index) in ad.insights.actions" 
                            :key="`item-${_index}`"
                        >
                            <!-- tooltip -->
                            <div class="tooltip">
                                <div>Action name: <b>{{action.action_type}}</b></div>
                                <div>Action description: <b>{{getAction(action.action_type, 'description') ? getAction(action.action_type, 'description') : 'Not found'}}</b></div>
                                <div>Raw value: <b>{{action.value}}</b></div>
                            </div>

                            <div>{{getAction(action.action_type, 'description') ? getAction(action.action_type, 'description') : action.action_type}}: <b>{{action.value | numeral('0,0a')}}</b></div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="ad-actions">
            <options-dropdown
                :options="[
                    { label: 'Refresh ad data', event: 'refresh' },
                    { label: 'Edit', event:'edit' },
                    { label: 'Delete', event: 'delete' }
                ]"
                @refresh="refresh()"
                @edit="editing = true"
                @delete="deleteIntent()"
            />
            <div class="flex gap-x-2" v-if="editing">
                <base-button
                    label="Cancel"
                    type="secondary"
                    bold
                    class="h-10 px-2"   
                    @action="editing = false" 
                    size="sm"
                    :disabled="loading"
                />
                <!-- TODO check how we can make it to know that the new ID is not the same as the old ID -->
                <!-- because if they are the same, this button should be disabled -->
                <base-button
                    label="Save"
                    :disabled="!validateId(ad.ad_id) || loading"
                    bold
                    class="h-10 px-2"   
                    @action="update()" 
                    size="sm"
                />
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { notifyCatchError, isString } from '../../../common';
import { getAction } from '@/lib/fbAdsActions'
export default {
    props: ['ad'],
    data(){
        return {
            editing: false,
            expanded: false,
            loading: false,

            getAction,
            moment
        }
    },
    methods: {
        validateId(val){
            return isString(val) && (!isNaN(parseInt(val)));
        },
        refresh(){
            this.loading = true;
            this.$http.put(`/api/boosted-posts/${this.ad.id}/refresh-payload`).then(({ data }) => {
                this.$notify({ title: 'Ad successfully refreshed', text: '', type: 'success' });
                this.$emit('done');
            }).catch(err => {
                notifyCatchError(err, this.$notify);
            }).finally(() => {
                this.loading = false;
            }); 
        },
        update(){
            this.loading = true;
            this.$http.put(`/api/boosted-posts/${this.ad.id}`, { 
                ad_id: this.ad.ad_id 
            }).then(({ data }) => {
                this.$notify({ title: 'Ad successfully updated', text: '', type: 'success' });
                this.$emit('done');
            }).catch(err => {
                notifyCatchError(err, this.$notify);
            }).finally(() => {
                this.loading = false;
            });
        },
        deleteIntent(){
            this.$swal.fire({
                title: 'Are you sure you want to unlink this ad from this post?',
                text: `Ad ID: ${this.ad.ad_id}`,
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$http.delete(`/api/boosted-posts/${this.ad.id}`).then(({ data }) => {
                        this.$notify({ title: 'Ad successfully unlinked from this post', text: '', type: 'success' });
                        this.$emit('done');
                    }).catch(err => {
                        notifyCatchError(err, this.$notify);
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .ad-item{
        @apply bg-white border border-black rounded-lg py-6 px-7;
        @apply flex justify-between;
        > .ad-left{
            @apply flex flex-col gap-y-4;
            > .id-container{
                @apply flex items-center gap-x-3;
                > .id{
                    @apply text-gray-700 font-bold;
                }
            }
            > .dates{
                @apply flex gap-x-10;
                > .date{
                    @apply flex flex-col gap-y-1;
                    > .label{
                        //
                    }
                    > .value{
                        @apply flex items-center gap-x-3 font-bold;
                    }
                }
            }
            > .details-button-container{
                //
            }
            > .detail-section{
                > .title{
                    @apply text-h5 font-bold mb-2; 
                }
                > .action-list{
                    @apply flex flex-col;
                    > .item{
                        @apply relative cursor-pointer;
                        > .tooltip{
                            @apply absolute left-20 bg-white border border-black rounded-lg z-50 px-4 py-3;
                            @apply flex flex-col;
                            bottom: 28px; 
                            > .section{
                                //
                                > .title{
                                    @apply text-pxs font-bold mb-1;
                                }
                                > .text{
                                    @apply text-pxs;
                                }
                            }
                        }
                        &:not(:hover) {
                            > .tooltip{
                                @apply hidden;
                            }
                        }
                    }
                }
            }
        }
        > .ad-actions{
            @apply flex flex-col justify-between items-end;
        }   
    }
</style>

<template>
    <base-modal
        title="Creator status"
        :visible="visible"
        styles="max-height: 90vh; max-width: 800px; width: 800px; overflow-y: auto;"
        no-padding
        @close="close()"
    >
        <div class="container">

            <div class="content">

                <tab :visible="step == 1">
                    <!-- empty state -->
                    <div v-if="!value.actions || !value.actions.length" class="empty-state">
                        No actions found
                    </div>
                    <div v-else class="action-list">
                        <div class="item" v-for="(action, index) in value.actions" :key="index">
                            <div class="left">
                                <div class="top">
                                    <div class="title">{{ action.title }}</div>
                                    <div class="slug">({{ action.slug }})</div>
                                </div>
                                <div class="description">
                                    {{ action.description }}
                                </div>
                            </div>
                            <div class="right">
                                <template v-if="isValidArray(action.solutions)">
                                    <div class="solution-list">
                                        <template v-for="(solution, index) in action.solutions">
                                            <base-button
                                                v-if="solution.slug == 'select-another-user' ? showChangeButton() : true"
                                                :key="`solution-${index}`"
                                                :label="solution.name"
                                                size="xl"
                                                type="secondary"
                                                @action="selectedSolution = solution; next();"
                                            />
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </tab>

                <tab :visible="step == 2">
                    <template v-if="isValidObject(selectedSolution)">
                        <div
                            class="user-matches-list" 
                            v-if="selectedSolution.slug == 'select-another-user'"
                        >
                            <template v-if="creator && isValidArray(creator.user_matches)">
                                <div
                                    class="user-match-item" 
                                    v-for="(user, index) in creator.user_matches" 
                                    :key="`user-match-${index}`"
                                >
                                    <!-- content -->
                                    <div class="content">
                                        <avatar :user="user" />
                                        <div class="names">
                                            <div class="name">
                                                {{ user.first_name }} {{ user.last_name }} <span class="text-pxs font-normal">(ID: {{user.id}})</span>
                                            </div>
                                            <div class="email">
                                                {{user.email}}
                                            </div>
                                            <div v-if="user.found_by" class="found-by">
                                                Found by {{user.found_by == 'both' ? 'email and handle' : user.found_by}}
                                            </div>
                                            <div class="handle-list" v-if="getInstagramHandles(user).length">
                                                <div 
                                                    class="handle-item"
                                                    v-for="(handle, index) in getInstagramHandles(user)" 
                                                    :key="`ig-handle-${index}`"
                                                >
                                                    <base-icon name="instagram" />
                                                    {{ handle }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- actions -->
                                    <div class="actions">
                                        <div v-if="isSelected(user)" class="font-bold text-center px-10">Selected</div>
                                        <base-button
                                            v-else 
                                            label="Select creator"
                                            bold
                                            @action="selectCreatorIntent(user)"
                                        />
                                    </div>
                                </div>
                            </template>
                        </div>

                        <base-table-column-user-importable-status-edit-user
                            v-if="selectedSolution.slug == 'edit-current-user'"
                            :creator="creator"
                            @close="selectedSolution = null; step = 1;"
                            @update:user="user => $emit('update:user', user)"     
                        />
                        
                    </template>
                </tab>

            </div>

            <div class="footer">
                <base-button
                    v-if="step == 2"
                    label="Back"
                    type="secondary"
                    @action="back()"
                />
                <base-button
                    v-if="step == 1"
                    label="Close"
                    type="secondary"
                    @action="close()"
                />
            </div>

        </div>
    </base-modal>
</template>
<script>
import { isValidArray, isValidObject } from '../common';
import BaseTableColumnUserImportableStatusEditUser from './BaseTableColumnUserImportableStatusEditUser.vue';
export default {
  components: { BaseTableColumnUserImportableStatusEditUser },
    props: {
        visible: {
            type: Boolean
        },
        value: {
            type: Object
        },
        creator: {
            type: Object
        }
    },
    data(){
        return {
            step: 1,
            selectedSolution: null,
            isValidObject,
            isValidArray
        }
    },
    methods: {
        close(){
            this.step = 1;
            this.selectedSolution = null;
            this.$emit('close');
        },
        next(){
            this.step++;
        },
        back(){
            this.step--;
        },
        selectCreatorIntent(user){
            this.$swal.fire({
                title: 'Are you sure you want to switch to this creator ?',
                text: 'This user will now be used to build the report',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Select creator',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$notify({ title: 'Success', text: 'User switched successfully', type: 'success' });
                    this.$emit('update:user', user);     
                }
            });
        },
        // If there is only one "user_match" and its the same as the selected user, we won't show the change button
        showChangeButton(){
            let show = true;
            const { creator } = this;
            if(creator && isValidArray(creator.user_matches) && isValidObject(creator.user)){
                if((creator.user_matches.length == 1) && (creator.user_matches[0].id == creator.user.id)){
                    show = false;
                }else{
                    show = true;
                }
            }else{
                show = false;
            }
            return show;
        },
        isSelected(user){
            const user_match_id = user.id;
            const selected_user_id = this.creator.user.id;
            if(user_match_id == selected_user_id){
                return true;
            }
            return false;
        },
        getInstagramHandles(user){
            const target_network_id = (this.creator && this.creator.target_network && this.creator.target_network.id) ? this.creator.target_network.id : null;
            if(target_network_id && isValidArray(user.social_accounts)){
                return user.social_accounts.filter(element => element.network_id == target_network_id).map(element => element.handle);
            }
            return [];
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply relative;
        > .content{
            @apply px-8 pt-4 pb-20;
            .action-list{
                @apply flex flex-col gap-y-8;
                > .item{
                    @apply flex items-center;
                    > .left{
                        @apply flex-grow flex-shrink;
                        > .top{
                            @apply flex items-center gap-x-2;
                            > .title{
                                @apply text-h5 font-bold;
                            }   
                            > .slug{
                                @apply text-pxs text-gray-400;
                            } 
                        }
                        > .description{
                            //
                        }
                    }
                    > .right{
                        @apply flex-grow-0 flex-shrink-0;
                        > .solution-list{
                            @apply flex flex-col gap-y-2;
                        }
                    }
                }
            }

            .user-matches-list{
                @apply flex flex-col gap-y-6; 
                > .user-match-item{
                    @apply flex items-center border border-gray-300 rounded-lg px-4 py-3;
                    > .content{
                        @apply flex-grow flex-shrink;
                        @apply flex items-center gap-x-4;
                        > .names{
                            @apply flex flex-col gap-y-1;
                            > .name{
                                @apply text-h5 font-bold;
                            }
                            > .email{
                                @apply text-pxs;
                            }
                            > .found-by{
                                @apply text-pxs;   
                            }
                            > .handle-list{
                                @apply flex flex-col;
                                > .handle-item{
                                    @apply flex items-center gap-x-2;
                                    @apply text-pxs;
                                }
                            }
                        }
                        
                
                    }
                    > .actions{
                        @apply flex-grow-0 flex-shrink-0;

                    }
                }
            }
        }

        .footer{
            @apply sticky bottom-0 border-t border-gray-200 bg-tan-m px-8 py-4 flex justify-between;
        }
    }
</style>

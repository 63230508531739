<template>
    <base-modal
        title="Edit User Roles"
        :visible="visible"
        @close="$emit('close')"
    >
        <div class="flex mb-2">
            <h2 class="font-medium">Choose a role for the new member:</h2>
        </div>
        <div class="flex flex-wrap gap-x-4 mt-3">
            <div>
                <div v-if="roles.length <= 0">
                    No roles found
                </div>
                <div v-else class="flex flex-col gap-y-1">
                    <div 
                        v-for="role in roles" 
                        :key="role.id" 
                        @click="()=>toggleRole(role)" 
                        class="hover:bg-cool-gray-100 cursor-pointer duration-300 flex gap-x-1.5 items-center px-4 py-3 rounded"
                    >
                        <input 
                            class="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 cursor-pointer" 
                            type="radio"
                            :checked="userRoles.includes(role.id) ? true : false"
                        >
                        <h2 class="font-medium text-sm cursor-pointer">{{role.display_name}}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-3 flex justify-end">
        
            <!-- <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                    :disabled="!roleReady() || loading || userRoles.length <= 0 || !userRoles"
                    :style="(!roleReady() || loading || userRoles.length <= 0 || !userRoles) ? 'opacity: 0.2' : ''"
                    @click="updateRoles()"
                    class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-500 focus:outline-none focus:border-purple-700 focus:shadow-outline-purple active:bg-purple-700 transition duration-150 ease-in-out"
                >
                    Edit
                </button>
            </span> -->
            <base-button @action="updateRoles()" size="sm" :disabled="!roleReady() || loading || userRoles.length <= 0 || !userRoles" label="Edit"/>

            <!-- <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <button
                    type="button"                            
                    class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5" 
                    @click="$emit('close')"
                >
                    Cancel
                </button>
            </span> -->
        </div>
    </base-modal>
</template>

<script>
export default {
    props: ['user', 'visible'],
    data() {
        return {
            loading : false,

            roles: [],
            rolesLoading: false,

            userRoles: []
        }
    },
    mounted() {
        this.getRoles()
    },
    methods: {
        async getRoles(){
            this.rolesLoading = true
            const { data } = await this.$http.get('/api/roles');
            this.rolesLoading = false
            // filter out creator role
            this.roles = data.filter(role => role.name != 'creator')
        },
        async updateRoles(){
            this.loading = true
            try{
                const { data } = await this.$http.put(`/api/users/${this.user.id}/roles`, { roles: this.userRoles });
                console.log(data)
                setTimeout( () => {
                    this.$notify({ title: 'Success', text: 'Roles updated successfully', type: 'success' })
                    this.$emit('done')
                }, 1500)
            }catch(error){
                console.log(error)
                this.$notify({ title: 'An error ocurred', text: `${error.response && error.response.data && error.response.data.message && (`: ${error.response.data.message}`)}`, type: 'error' });
            }finally{
                this.loading = false
            }
        },
        toggleRole(role){
            const found = this.userRoles.includes(role.id)
            if(found){
                this.userRoles = this.userRoles.filter(item => item !== role.id)
            }else{
                this.userRoles.push(role.id)
            }
        },
        roleReady(){
            return this.selectedRole != ''
        }
    },
    watch: { 
        user: function(newVal) {
            this.userRoles = newVal ? newVal.roles.map(element => element.id) : []
        }
    }
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-modal',{attrs:{"visible":_vm.visible,"title":_vm.title,"persistent":"","styles":"max-height: 90vh; min-width: 90vw; width: 90vw; overflow-y: auto; background-color: white;","noPadding":"","container-class":"flex justify-center"},on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"custom-test z-10 container pt-5 px-14 flex-grow flex-shrink"},[(_vm.step === 'list')?_c('div',[_c('base-table',{key:_vm.creatorsKey,attrs:{"columns":[
                    {
                        name: 'creator-name',
                        label: 'Creator Name',
                        type: 'avatar-list',
                        value: function (element) {
                            return [element]
                        }
                    },
                    {
                        name: 'creator-name-searchable',
                        label: 'Creator Name Searchable',
                        type: 'text',
                        value: function (element) {
                            return ((element.first_name) + " " + (element.last_name))
                        },
                        hidden: true
                    },
                    {
                        name: 'user_tags',
                        label: 'Tags',
                        type: 'user-tags',
                        searchBy: _vm.customUserTagsSearchBy,
                        filter: {
                            options: _vm.tags.map(function (tag) {return { label: tag.name, value: tag.slug }}),
                            placeholder: 'Tags',
                            type: 'select',
                            allSelectedPlaceholder: 'All Tags'
                        },
                    },
                    {
                        name: 'active-projects',
                        label: 'Active Projects',
                        type: 'text',
                        value: _vm.customActiveProjectsValue,
                    },
                    {
                        name: 'creator',
                        label: '',
                        type: 'button',
                        secondaryIcon: 'arrow-right',
                        onClick: _vm.handleViewCreatorClick,
                        value: function (element) {
                            return 'View Creator'
                        },
                        disabled: function (element) {
                            return !element.user_projects.length
                        },
                    }
                ],"data":_vm.currentCreators,"empty-text":'',"initialSelectedElements":_vm.computedInitialSelectedElements,"toggled-element":_vm.toggledUser,"bulkActions":_vm.bulkActions,"loading":_vm.loading,"page":_vm.currentTab === 'all' ? _vm.currentPage : undefined,"internal-pagination":_vm.currentTab === 'all',"hide-headers":_vm.currentTab === 'new' && !_vm.newItems.length,"total":_vm.currentCreators.length,"per-page":10},on:{"select-element":_vm.toggleElements,"changePage":_vm.changePage},scopedSlots:_vm._u([(!_vm.hideCreate)?{key:"tabs-section",fn:function(){return [_c('div',{staticClass:"flex justify-between"},[_c('tabs',{attrs:{"tabs":['all', 'new'],"capitalize":"","custom-class":"ml-12 sm:ml-8"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}}),(_vm.currentTab !== 'new')?_c('base-button',{attrs:{"label":"New Creator"},on:{"action":function($event){_vm.currentTab = 'new'}}}):_vm._e()],1)]},proxy:true}:null,(!_vm.hideCreate && _vm.currentTab === 'new')?{key:"table-footer",fn:function(){return [_c('div',{staticClass:"w-2/3 mt-6"},[_c('create-influencer-form',{attrs:{"tags":_vm.mappedTags,"inline":"","assigningToProject":"","projectId":_vm.projectSlug ? _vm.projectSlug : null},on:{"created-influencer":_vm.reloadInfluencers,"filling":_vm.fillingCreate,"cancel-form":_vm.cancelCreate,"toggle-existent":_vm.toggleExistent}})],1)]},proxy:true}:null],null,true)})],1):_vm._e(),(_vm.step === 'resume')?_c('div',[_c('div',[_vm._v("The following users will be assigned to the project.")]),_c('base-table',{key:_vm.creatorsKey,attrs:{"columns":[
                    {
                        name: 'creator-name',
                        label: 'Creator Name',
                        type: 'avatar-list',
                        value: function (element) {
                            return [element]
                        }
                    },
                    {
                        name: 'creator-name-searchable',
                        label: 'Creator Name Searchable',
                        type: 'text',
                        value: function (element) {
                            return ((element.first_name) + " " + (element.last_name))
                        },
                        hidden: true
                    },
                    {
                        name: 'creator',
                        label: '',
                        type: 'button',
                        secondaryIcon: 'arrow-right',
                        onClick: _vm.handleViewCreatorClick,
                        value: function (element) {
                            return 'View Creator'
                        },
                        disabled: function (element) {
                            return !element.user_projects.length
                        },
                    }
                ],"bulkActions":[],"data":_vm.selectedCreators,"initialSelectedElements":[],"empty-text":'There are no selected users',"loading":false,"search":false,"filtering":false,"hide-headers":true},on:{"changePage":_vm.changePage}})],1):_vm._e(),_c('div',{staticClass:"footer"},[_c('base-button',{staticClass:"bg-tan-m",attrs:{"type":"secondary","disabled":_vm.disabledActions,"size":"sm","label":_vm.step === 'resume' ? 'Back' : 'Cancel'},on:{"action":_vm.cancel}}),_c('base-button',{attrs:{"disabled":_vm.disabledActions || !_vm.selectedCreators.length,"label":_vm.step === 'resume' ? 'Confirm' : 'Continue'},on:{"action":_vm.confirm}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
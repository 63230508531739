<template>
    <div class="relative">
        <!-- searchbar -->
        <FormulateInput
            :input-class="['entity-select-searchbar']"
            v-if="showDropdown"
            type="text"
            label="Company"
            placeholder="Search companies..."
            v-model="term"
            v-debounce="(inputValue, e) => clearbitSearch(inputValue)"
        />

        <template v-else>
            <!-- input -->
            <FormulateInput
                type="text"
                label="Company"
                :input-class="['cursor-pointer']"
                placeholder="Search companies..."
                readonly
                :value="selectedEntity ? selectedEntity.name : ''"
                :key="inputKey"
                @click="onInputClick"
            />
            <template v-if="selectedEntity">
                
                <span v-if="selectedEntity.logo" class="absolute right-2.5 bottom-2">
                    <img :src="selectedEntity.logo" alt="" class="h-6 w-6 rounded-full">
                </span>
                
                <div v-if="selectedEntity.verified" class="absolute flex items-center" style="right: -75px; top: 25px;">
                    <base-icon :size="4" name="check" />
                    <div class="text-pxs ml-1">verified</div>
                </div>

            </template>
        </template>
        
        <span v-if="loading" class="absolute right-2.5 bottom-1">
            <base-icon name="loader" />
        </span>
        
        <!-- dropdown -->
        <div v-if="showDropdown && entities.length > 0" style="left: 0px; bottom: 70px" class="absolute w-full rounded-md bg-white shadow-lg border-2 border-gray-m-light">
            <ul 
                tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3"
                class="max-h-56 text-base leading-6 shadow-lg overflow-auto focus:outline-none sm:text-sm sm:leading-5"
            >
                <li 
                    v-for="(entity, i) in entities" 
                    :key="i"
                    @click="onCompanyClick(entity)"
                    class="text-black cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-m-light"
                    role="option"
                >
                    <div class="flex items-center space-x-3">
                        <img :src="entity.logo" alt="" class="flex-shrink-0 h-6 w-6 rounded-full">
                        <div class="flex flex-col truncate">
                            <span class="font-normal block truncate">{{ entity.name }}</span>
                            <span class="text-xs text-gray-600 pl-0">{{ entity.domain }}</span>
                        </div>
                    </div>

                    <span v-if="selectedEntity && selectedEntity.name === entity.name" class="absolute inset-y-0 right-0 flex items-center pr-3">
                        <base-icon name="check" />
                    </span>
                </li>
            </ul>
        </div> 
    </div>
</template>

<script>
export default {
    props: ['selectedEntity', 'overrideTerm'],
    data(){
        return {
            term: '',
            entities: [],
            loading: false,
            showDropdown: false,
            inputKey: 0,
            overrideTermTimer: 0
        }
    },
    watch: {
        selectedEntity(val){
            this.inputKey++;
        },
        overrideTerm(val){
            this.$emit('selected', null)
            this.entities = []
            this.showDropdown = true
            this.term = val
            this.inputKey++
            clearTimeout(this.overrideTermTimer) 
            this.overrideTermTimer = setTimeout(function(){
                this.clearbitSearch(val, true)
            }.bind(this), 1200);
        }
    },
    methods: {
        onInputClick(){
            this.$emit('selected', null)  
            this.showDropdown = true
            setTimeout(() => {
                document.getElementsByClassName('entity-select-searchbar')[0].focus();
            }, 100)
        },
        onCompanyClick(company){
            this.$emit('selected', company) 
            this.showDropdown = false; 
            this.term = ''; 
            this.inputKey = this.inputKey++;
        },
        async clearbitSearch(inputValue, hideDropdown = false){
            if(!inputValue || inputValue.length < 4){
                return false
            }
            this.loading = true
            try {
                let { data } = await this.$http.get(`/api/clearbit/companies?name=${inputValue}`);
                
                data = data.map(company => {
                    company.verified = true
                    return company
                })

                const internalEntities = await this.entitySearch(inputValue)

                // remove duplicates
                internalEntities.forEach((entity, index, object) => {
                    if (data.find(element => element.domain == entity.domain)) {
                        object.splice(index, 1);
                    }
                });

                data = data.concat(internalEntities)

                this.entities = data
                this.showDropdown = true

                // if a company matches perfectly select that automatically
                const found = this.entities.find(entity => (entity.name == this.term) || (entity.domain == this.term))
                if(found){
                    this.onCompanyClick(found)
                }

                // if company not found or there are NO results, that will be a custom company 
                if((!found && hideDropdown) || this.entities.length <= 0){
                    this.onCompanyClick({ name: inputValue, logo: '', domain: inputValue, verified: false })
                }

                if(hideDropdown){
                    this.entities = []
                }

            }catch(e) {
                console.log("error on clearbit search", e);
            }finally {
                this.loading = false
            }
        },
        async entitySearch(term){
            try {
                const { data } = await this.$http.get(`/api/entities/search?term=${term}`);
                return data
            }catch(e) {
                console.log("error on entity search", e);
                return []
            }
        }
    }
}
</script>
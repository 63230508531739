<template>
    <div v-if="visible">
        <!-- folders -->
        <div 
            class="folder"
            v-if="file.folderable_id"
            @click.prevent="$emit('openFolder', file)"
            @drop.prevent="onDrop($event, file)"
            @dragover.prevent="isFileOverFolder = !disabled"
            @dragleave.prevent="isFileOverFolder = false"
            :draggable="!disabled && !file.external_id"
            @dragstart="startDrag($event, file)"
            @dragend="endDrag($event, file)"
            :class="{ 
                'drag-started': dragStarted.type == 'file' || (dragStarted.type == 'folder' &&  dragStarted.id != file.id), 
                'file-is-over': isFileOverFolder 
            }"
        >
            <div v-if="file.isDrive" class="google-drive-icon">
                <base-icon
                    class="text-purple-m-main"   
                    name="drive"
                    size="8"
                    v-tooltip="{ 
                        content: 'Google Drive Folder',
                        classes: 'v-tooltip-black' 
                    }"
                />
            </div>
            <div 
                class="options" 
                :class="{'list-style': gridStyle === 'list'}" 
                @click.stop
            >

                <options-dropdown
                    v-if="(isAdminOrHigher || isProjectOwner || (file.external_id && file.user_id === me.id))"
                    :options="[
                        ...(!disabled && !file.external_id ? [{
                            label: 'Change name', 
                            icon: 'pencil-alt', 
                            event:'edit' 
                        }] : []),
                        ...(!disabled && file.external_id && file.user_id === me.id ? [{
                            label: 'Open in drive',
                            event:'open-folder-in-drive'
                        }] : []),
                        ...(!disabled && file.external_id && file.user_id === me.id ? [{
                            label: 'Sharing Settings',
                            icon: 'share',
                            event:'share'
                        }] : []),
                        ...(!disabled && isAdminOrHigher && !file.external_id ? [{
                            label: file.external_id ? 'Remove from Drive' : 'Delete',
                            icon: 'trash',
                            event: 'del',
                            divider: true
                        }] : []),
                        ...(!disabled && file.external_id && file.user_id === me.id ? [{
                            label: 'Remove',
                            icon: 'trash',
                            event: 'remove',
                            divider: true
                        }] : [])
                    ]"
                    @edit="$emit('edit-folder', file)"
                    @share="$emit('share-drive-folder', file)"
                    @open-folder-in-drive="openFolderInDrive"
                    @dropdown-status="setDropdownStatus"
                    @del="onFolderDelete"
                    @remove="onFolderRemove"
                />
            </div>
            <div class="content">
                <div class="icon">
                     <base-icon 
                        class="text-purple-m-main" 
                        :size="8" 
                        name="files-folder"
                    />
                </div>
                <div 
                    class="drop-text"
                    v-if="dragStarted.type == 'file' || (dragStarted.type == 'folder' &&  dragStarted.id != file.id)"
                >
                    Move to
                </div>
                <div 
                    class="name"
                    v-tooltip="{ content: file.name, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 1000, hide: 50 } }"
                >
                    {{ file.name }}
                </div>
            </div>
        </div>

        <!-- other files -->
        <div
            v-else
            class="file" 
            :class="{'list-style': gridStyle === 'list', 'grid-style': gridStyle === 'grid'}" 
            @click.prevent="openFileIntent"
            :draggable="!file.isDrive"
            @dragstart="startDrag($event, file)"
            @dragend="endDrag($event, file)"
        >
            <div 
                class="options" 
                :class="{'list-style': gridStyle === 'list'}" 
                @click.stop="(e)=> runPropagation"
            >
                <!-- 
                    the "file.project_id" condition makes sure that we only show the "Remove" option on  
                    files that are on our DB, and not in google drive sub files (files inside a google drive folder)
                 -->
                <options-dropdown
                    v-if="isProjectOwner || isAdminOrHigher || allowToEdit"
                    :options="file.isDrive ? [
                        {
                            label: 'Open in drive',
                            icon: 'file',
                            event: 'open-file-in-drive'
                        },
                        ...( file.project_id ? [
                            {
                                label: 'Remove',
                                icon: 'trash',
                                event: 'del',
                                divider: true
                            }
                        ] : [] )
                    ] : [
                        ...(this.isSuperAdmin && !isReport && !disabled ? [{
                            label: 'Permissions',
                            icon: 'locked',
                            event: 'permissions',
                        }] : []),
                        ...(!disabled || allowToEdit ? [{
                            label: 'Edit',
                            icon: 'pencil-alt',
                            event:'edit'
                        }] : []),
                        {
                            label: 'Copy Link',
                            icon: 'link',
                            event: 'copy',
                        },
                        ...(this.isAdminOrHigher ? [{
                            label: 'Remove',
                            icon: 'trash',
                            event: 'del',
                            divider: true
                        }] : [])
                    ]"
                    @permissions="$emit('share', { type: 'file', id: file.id })"
                    @edit="$emit('edit-file', file)"
                    @open-file-in-drive="openFileInDrive"
                    @dropdown-status="setDropdownStatus"
                    @copy="copyLink"
                    @del="
                        $swal.fire({
                            title: `Are you sure you want to remove ${file.name} ?`,
                            text: `You won't be able to revert this!`,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Remove',
                            buttonsStyling: false,
                            reverseButtons: true,
                            focusCancel: true
                        }).then((result) => {
                            if (result.isConfirmed) {
                                deleteFile()
                            }
                        });
                    "
                />
            </div>
            <div v-if="file.isDrive" class="google-drive-icon">
                <base-icon
                    class="text-purple-m-main"   
                    name="drive"
                    size="8"
                    v-tooltip="{ 
                        content: 'Google Drive File',
                        classes: 'v-tooltip-black' 
                    }"
                />
            </div>
            <div 
                class="content" 
                :class="{'grid-style': gridStyle === 'grid', 'list-style': gridStyle === 'list'}"
            >
                
                <!-- If its a google drive file -->
                <template v-if="file.isDrive">
                    
                    <!--                     
                        if there is a thumbnail, we will show an image with it, and
                        if there is also an icon we will position it in the center of the thumbnail 
                    -->
                    <template v-if="isValidString(file.thumbnailLink)">
                        <img  
                            class="image"
                            :src="file.thumbnailLink"
                            style="object-fit: contain"
                        />
                        <img 
                            v-if="isValidString(file.iconLink)" 
                            :src="file.iconLink"
                            style="margin-top: -72px;position: absolute;"
                        >
                    </template>

                    <!-- if not we will try to only show the icon, and lastly, the default drive icon -->
                    <div v-else class="icon">
                        <img 
                            v-if="isValidString(file.iconLink)" 
                            :src="file.iconLink" 
                        >
                        <base-icon
                            v-else
                            class="text-purple-m-main"   
                            name="drive"
                            size="8" 
                        />
                    </div>
                </template>

                <!-- other files -->

                <!-- If the file is an image we just show it here -->
                <img 
                    class="image"
                    draggable="false" 
                    v-else-if="isImage"
                    :src="`${baseUrl}/api/files/render/${file.guid}?token=${file.token}`"  
                />
                
                <!-- Otherwise we will show the default icons -->
                <div class="icon" v-else>
                    <base-icon 
                        class="text-purple-m-main"   
                        :name="file.uploading ? 'loader' : fileIcon" 
                        :size="file.uploading ? 8 : 5" 
                    />
                </div>

                <!-- file name section -->
                <div
                    class="name" 
                    :class="{'list-style': gridStyle === 'list', 'grid-style': gridStyle === 'grid'}"
                    v-tooltip="{ content: file.name, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 1000, hide: 50 } }"
                >
                    {{ file.name !== '' ? file.name : 'Untitled file' }}
                </div>

                <!-- file date section -->
                <div class="date" v-if="file.created_at && file.created_at !== null && !isReport">{{ file.created_at | moment('MMM D, Y') }}</div>
                
                <!-- report type section -->
                <div class="report-type" v-else-if="file.created_at && file.created_at !== null && isReport">
                    <div v-if="isDefaultReport">Default</div>
                    <div v-else>{{ reportType }}</div>
                </div>
                <div class="uploading-text" v-else>Uploading now...</div>
                <div class="private-text" v-if="file.permissions == 3">Private</div>
            </div>

        </div>
        <div class="loader" v-if="file.uploading"></div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isValidString } from '../../common';
import Dropdown from '../Dropdown';
import FilePasswordModal from '../FilePasswordModal.vue'
export default {
    props: {
        file: {},
        isProjectOwner: {},
        dragStarted: {},
        gridStyle: {
            type: String,
            default: 'grid'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        allowToEdit: {
            type: Boolean,
            default: false
        }
    },

    components: {
        Dropdown,
        FilePasswordModal
    },

    data(){
        return {
            visible: true,
            dropdownStatus: false,
            wasClosed: false,
            isFileOverFolder: false,
            isValidString
        }
    },

    watch: {
        dropdownStatus: function (val) {
            this.wasClosed = !val;
        }
    },

    computed: {
        ...mapGetters(['me', 'isSuperAdmin', 'isAdminOrHigher']),

        baseUrl() {
            return this.$http.defaults.baseURL;
        },

        fileIcon() {
            if (this.file.mimetype !== undefined) {
                let mimetype = this.file.mimetype.split('/');
                if (mimetype.length) {
                    switch (mimetype[0]) {
                        case 'link':
                            return 'link';
                        case 'image':
                            return 'image';
                        case 'audio':
                            return 'audio';
                        case 'application': {
                            switch (mimetype[1]) {
                                case 'pdf':
                                    return 'file';
                            }
                            return 'file';
                        }
                    }
                }
            }
            return 'file';
        },

        mimetype() {
            return this.file.mimetype ? this.file.mimetype.toLowerCase() : '';
        },

        isReport() {
            return this.mimetype === 'link' && this.file.url.toLowerCase().includes('/reports/');
        },

        isImage() {
            return this.mimetype.toLowerCase().includes('image');
        },

        extension() {
            return this.mimetype.split('/')[1] || this.mimetype;
        },

        computedLabel() {
            switch (this.file.permissions) {
                case 0:
                    return 'Public';
                case 1:
                    return 'Public';
                case 2:
                    return 'Owners Only';
                case 3:
                    return 'Private';
            }
            return null;
        },

        isDefaultReport() {
            return this.isReport && this.file && this.file.report && this.file.report.is_default;
        },

        reportType() {
            if (this.file.report !== undefined && this.file.report !== null) {
                if (this.file.report.type) {
                    switch (this.file.report.type) {
                        case 1:
                            return 'Creators';
                        case 2:
                            return 'Content';
                        case 3:
                            return 'Brand';
                    }
                }
            }
            return '-';
        }
    },

    methods: {
        copyLink() {
            let text = `${window.location.origin}/files/${this.file.guid}`;
            if (this.isReport) {
                text = `${window.location.origin}/reports/${this.file.guid}`;
            }

            this.$copyToClipboard(this.$notify, text);
        },

        setDropdownStatus(status) {
            this.dropdownStatus = status;
        },

        runPropagation(e) {
            if (this.dropdownStatus) {
                e.stopPropagation();
            }
        },

        async openFileIntent(){
            if (this.file.isDrive) {
            
                // Google drive subfolders have mimetype = 'application/vnd.google-apps.folder' and isDrive: true
                // for those subfolders we will trigger the openFolder event
                if(this.file.mimetype == 'application/vnd.google-apps.folder'){
                    this.$emit('openFolder', this.file);
                }else{
                    this.openFileInDrive();
                }

                // Not sure about this functionality              
                // if(this.file.webContentLink){
                    // let a = document.createElement('a');
                    // a.href = this.file.webContentLink;
                    // a.target = '_blank';
                    // a.click();
                // }
            }
            else if (!this.dropdownStatus && !this.wasClosed) {
                const hasPermission = await this.checkPermissions()
                if (hasPermission) {
                    return this.openFile()
                }
            }
            this.wasClosed = false;
        },

        openFileInDrive(){
            let a = document.createElement('a');
            a.href = this.file.url;
            a.target = '_blank';
            a.click();
        },

        openFolderInDrive(){
            let a = document.createElement('a');
            a.href = 'https://drive.google.com/drive/folders/' + this.file.external_id;
            a.target = '_blank';
            a.click();
        },

        openFile() {
            let url = `/files/${this.file.guid}`;
            if (this.isReport) {
                url = `/reports/${this.file.guid}`;
            }
            window.open(url)
        },

        async deleteFile(){
            try {
                const { data } = await this.$http.delete(`/api/files/${this.file.id}`);
                if (data) {
                    this.visible = false;
                    this.$store.dispatch('restorer/set', {
                        resourceLabel: 'File',
                        route: `/api/files/${this.file.id}/restore`,
                        action: data.show_undo,
                        forceFn: true,
                        fn: (time = 6100) => {
                            setTimeout(() => {
                                this.$emit('refresh-data');
                                this.visible = true
                            }, time);
                        }
                    });
                }
            } catch (err) {
                console.log(err);
                this.visible = true;
                this.$notify({ title: 'Error', text: 'Something went wrong', type: 'error' })
            }
        },

        async deleteFolder(undo = true){
            try {
                let id = this.file.id;
                const { data } = await this.$http.delete(`/api/folders/${id}`)
                if (data && undo) {
                    this.visible = false;
                    this.$store.dispatch('restorer/set', {
                        resourceLabel: 'Folder',
                        route: `/api/folders/restore?folders=${data.deleted.folders.join(',')}&attachments=${data.deleted.attachments.join(',')}`,
                        action: data.show_undo,
                        forceFn: true,
                        fn: (time = 6100) => {
                            setTimeout(() => {
                                this.$emit('refresh-data') 
                                this.visible = true
                            }, time);
                        }
                    });
                } else if (data) {
                    this.$notify({title: 'Success!', text: 'Folder removed successfully', type: 'success'});
                    this.$emit('refresh-data');
                }
            } catch (err) {
                console.log(err);
                this.visible = true;
                this.$notify({ title: 'Error', text: 'Something went wrong', type: 'error' })
            }
        },

        checkPermissions(){
            // public
            if(!this.file.permissions || this.file.permissions === 0 || this.file.permissions === 1){
                return true
            }

            // owners only
            if(this.file.permissions === 2){
                if(this.isProjectOwner || this.isAdminOrHigher){
                    return true
                }else{
                    this.$notify({ title: 'Warning', text: 'File for owners & admins only', type: 'warn' })
                    return false
                }
            }

            // private
            if(this.file.permissions === 3){
                return true
            }
        },

 
        startDrag(event, file){
            event.dataTransfer.setData('fileId', file.id)
            event.dataTransfer.setData('fileType', file.folderable_id ? 'folder' : 'file')
            event.dataTransfer.setData('fileName', file.name)
            event.dataTransfer.setData('fileMimetype', file.mimetype)
            this.$emit('dragStarted', {
                id: file.id,
                type: file.folderable_id ? 'folder' : 'file'
            })
        },

        endDrag(event, file){
            this.$emit('dragEnded')
        },

        onDrop(event, folder){
            if (this.disabled) {
               this.$notify({ title: 'Warning', text: 'Uploading files to this section is currently unavailable', type: 'warn' })
            } else {
                this.isFileOverFolder = false
                const fileId = event.dataTransfer.getData('fileId')
                const fileType = event.dataTransfer.getData('fileType')
                const fileName = event.dataTransfer.getData('fileName')
                let fileMimetype = event.dataTransfer.getData('fileMimetype')
                if (fileMimetype === 'undefined') {
                    fileMimetype = undefined;
                }
                // make sure we don't drop a folder in itself
                if (fileId != folder.id) {
                    this.$emit('fileDroppedInFolder', fileId, folder.id, fileType, fileName, folder.name, fileMimetype, folder.external_id, event.dataTransfer.files)
                }
            }
        },

        async onFolderRemove() {
            if (this.file.external_id) {
                this.$swal.fire({
                    title: `Are you sure you want to remove the following Google Drive Folder: ${this.file.name} ?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Remove',
                    buttonsStyling: false,
                    reverseButtons: true,
                    focusCancel: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteFolder(false);
                    }
                });
            }
        },

        async onFolderDelete() {
            if (this.file.external_id) {
                this.$emit('delete-drive', this.file);
            } else {
                try {
                    const {data} = await this.$http.get(`/api/folders/${this.file.id}/internal-files`)
                    if (data) {

                        const {folders, attachments} = data
                        const limit = 5
                        const totalFileCount = folders.length + attachments.length
                        const files = folders.concat(attachments);
                        let text = `<div>${folders.length} folder(s) and ${attachments.length} file(s) will be eliminated:</div>`
                        text += '<ul>'
                        files.forEach((file, index) => {
                            if (index + 1 <= limit) text += `<li>- ${file.name}</li>`
                        });
                        text += '</ul>'
                        totalFileCount > limit ? text += `and ${totalFileCount - limit} more...` : null

                        this.$swal.fire({
                            title: `Are you sure you want to delete ${this.file.name} ?`,
                            html: text,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Delete',
                            buttonsStyling: false,
                            reverseButtons: true,
                            focusCancel: true
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.deleteFolder()
                            }
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }
}

</script>
<style scoped lang="scss">
    .folder {
        @apply relative cursor-pointer w-56 h-56 flex justify-center items-center bg-tan-m;
        &.drag-started{
            @apply bg-tan-m-hover
        }
        &.file-is-over{
            @apply opacity-50;
            > .options{
                @apply pointer-events-none;	
            }
            > .content{
                @apply pointer-events-none;
            }
        }
        > .options{
            @apply absolute right-0 top-0 p-2 z-10;
            &.list-style{
                @apply top-0 bottom-0 flex items-center;
            }
        }
        > .google-drive-icon{
            @apply absolute left-0 top-0 p-2 z-10;
        }
        > .content{
            @apply flex flex-col items-center overflow-hidden p-4;
            > .icon{
                @apply mb-6 inline-flex items-center justify-center rounded-full bg-white w-15 h-15 border border-gray-m-light;
            }
            > .drop-text{
                @apply text-purple-m-secondary text-h5 font-bold text-center truncate w-full;
            }
            > .name{
                @apply text-purple-m-secondary text-h5 font-bold text-center truncate w-full;
            }

        }
    }
    .file{
        @apply relative cursor-pointer;
        &.list-style{
            @apply w-full h-24;
            &:hover{
                @apply bg-tan-m;
            }
        }
        &.grid-style{
            @apply bg-tan-m w-56 h-56;
        }
        > .options{
            @apply absolute right-0 p-2 z-10;
            &.list{
                @apply top-0 bottom-0 flex items-center;
            }
        }
        > .google-drive-icon{
            @apply absolute left-0 top-0 p-2 z-10;
        }
        > .content{
            @apply w-full h-full p-4 gap-2 justify-center items-center relative;
            &.grid-style{
                @apply flex flex-col;
            }
            &.list-style{
                @apply grid grid-cols-6;
            }
            > .icon{
                @apply inline-flex items-center justify-center rounded-full bg-white w-15 h-15 border border-gray-m-light;
                > .thumbnail{
                    @apply h-5 w-5;
                }
            }
            > .image{
                @apply w-full h-24 object-cover;
            }
            > .name{
                @apply text-purple-m-secondary text-h5 font-bold text-center truncate w-full;
                &.list-style{
                    @apply col-span-4;
                }
                &.grid-style{
                    @apply pt-4;
                }

            }
            > .date{
                @apply text-purple-m-secondary text-ps text-center;
            }
            > .report-type{
                @apply text-purple-m-secondary text-ps flex flex-col gap-2 items-center;
            }
            > .uploading-text{
                @apply text-purple-m-secondary text-ps;
            }
            > .private-text{
                @apply text-purple-m-secondary text-pxs absolute bottom-5;
            }

        }
    }
    .loader{
        @apply absolute bottom-0 animate-pulse h-1 bg-blue-100 w-full;
    }
</style>

<template>
    <div v-if="audios.length > 0" class="flex gap-4">
        <audio-item 
            v-for="(audio, index) in audios" 
            :key="`audio-item-${index}`"
            :id="`audio-${index}`"
            :audio="audio"
            :page="page"
            @removeAudio="(usedByFileIndex) => $emit('removeAudio', usedByFileIndex)"
        />
    </div>
</template>

<script>
import AudioItem from './AudioItem.vue'
export default {
    props: {
        audios: {
            type: Array,
            required: true
        },
        page: {
            type: Number,
            required: true
        }
    },
    components: { AudioItem }
}
</script>

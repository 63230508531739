var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-editor',{ref:"quillEditor",staticClass:"min-ql-h",attrs:{"value":_vm.value,"placeholder":"Brief","id":"customVueEditor","editorOptions":{
            modules: {
                toolbar: {
                    container: _vm.$customToolbar().concat( [['upload-brief-attachment']]),
                    handlers: {
                        'upload-brief-attachment': function () { return this$1.$refs.fileInput.click(); }
                    }
                }
            }
        }},on:{"input":function (val) { return _vm.$emit('input', val); }}}),_c('div',{staticClass:"brief-attachment"},[(_vm.briefAttachment)?_c('div',{staticClass:"item-container"},[_c('div',{staticClass:"w-14 h-14 flex justify-center items-center bg-tan-m rounded-lg"},[_c('div',{staticClass:"w-8 h-8 flex justify-center items-center border border-gray-300 rounded-full bg-white"},[_c('base-icon',{staticClass:"text-purple-m-main",attrs:{"size":3,"name":"file"}})],1)]),_c('div',[_vm._v(" "+_vm._s(_vm.briefAttachment.name)+" ")]),_c('base-button',{attrs:{"icon":"close","type":"label"},on:{"action":function($event){return _vm.$emit('remove-brief-attachment')}}})],1):_vm._e()]),_c('input',{ref:"fileInput",staticClass:"invisible",attrs:{"type":"file","accept":"application/pdf"},on:{"change":_vm.onFileInputChange}}),_c('div',{staticClass:"hidden"},[_c('base-icon',{ref:"icon",attrs:{"name":"pdf2","size":5}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <base-modal
        :visible="visible"
        @close="$emit('close')"
        title="Manual replace"
    >
        <div class="container">

            <!-- Step 1 - Post Data -->
            <tab :visible="step == 1">
                
                <div class="section">

                    <div class="field col-span-6">
                        <div class="label">Post URL</div>
                        <FormulateInput 
                            type="text"
                            validation="required"
                            placeholder="https://"
                            v-model="post_url"
                            :wrapper-class="inputWrapperClasses"
                            :element-class="['w-full']"
                            v-debounce:500="getUrlParams"
                        />                    
                    </div>


                    <!-- media url -->
                    <div class="file-field col-span-3">

                        <div class="header">
                            <div class="label">Media Url</div>
                            <base-button v-if="(typeof url == 'string' && url.trim() != '')" type="label" size="sm" class="text-pxs" label="View file" @action="openLink(url)"/>
                        </div>

                        <div class="help">The url of the image or video</div>

                        <div class="input-container">
                            <FormulateInput 
                                type="text" 
                                validation="required" 
                                placeholder="File url" 
                                v-model="url" 
                                :wrapper-class="inputWrapperClasses"
                                :element-class="['w-full']"
                            />

                            <div class="upload-button">
                                <base-button type="label" size="sm" icon="upload2" @action="$refs.mediaFile.click()" class="w-5 h-5 text-pxs"/>
                                <input class="hidden h-0" type="file" ref="mediaFile" :disabled="uploadingFile" @change="uploadMediaFile">
                            </div>

                        </div>
                    </div>

                    <!-- cover url -->
                    <div class="file-field col-span-3">

                        <div class="header">
                            <div class="label">Display Url</div>
                            <base-button  v-if="(typeof display_url == 'string' && display_url.trim() != '')" type="label" class="text-pxs" label="View file" @action="openLink(display_url)"/>
                        </div>
                        
                        <div class="help">The url of the cover image</div>
                        
                        <div class="input-container">
                            <FormulateInput 
                                type="text" 
                                validation="required" 
                                placeholder="Cover url" 
                                v-model="display_url" 
                                :wrapper-class="inputWrapperClasses"
                                :element-class="['w-full']"
                            />
                            <div class="upload-button">
                                <base-button type="label" size="sm" icon="upload2" @action="$refs.displayFile.click()"/>
                                <input class="hidden h-0" type="file" ref="displayFile" :disabled="uploadingFile" @change="uploadDisplayFile">
                            </div>
                        </div>

                    </div>
                    <div class="field col-span-2">
                        <template v-if="medium.id == 'video'">
                            <div class="label">Video Duration</div>
                            <FormulateInput type="text" validation="required" placeholder="Video duration" v-model="video_duration" :wrapper-class="inputWrapperClasses"/>
                        </template>
                    </div>

                    <div class="field col-span-2">
                        <div class="label">Caption</div>
                        <FormulateInput type="text" placeholder="Type the caption of the content" v-model="caption" :wrapper-class="inputWrapperClasses"/>
                    </div>

                    <div class="field col-span-2">
                        <div class="w-full flex justify-between relative">
                            <div class="label">Post Identifier</div>
                            <span class="absolute right-2 top-0 cursor-pointer">
                                <base-icon 
                                    name="info-circle"
                                    @action="() => $swal.fire({
                                        title: 'Post Identifier',
                                        html: `
                                            <div style='font-size: 14px'>
                                                <div>Unique Identifier of the post, usually included in the URL, for example:</div>
                                                <br>
                                                <div>For an Instagram URL:</div>
                                                <div>https://www.instagram.com/p/Cmsjhtytkhs_/</div>
                                                <div>The post identifier would be: <span style='font-weight: bold'>Cmsjhtytkhs_</span></div>
                                                <br>
                                                <div>For a TikTok URL:</div>
                                                <div>https://www.tiktok.com/@username/video/123456789</div>
                                                <div>The post identifier would be: <span style='font-weight: bold'>123456789</span></div>
                                                <br>
                                                <div>For a Facebook URL:</div>
                                                <div>https://www.facebook.com/photo/?fbid=123456789</div>
                                                <div>The post identifier would be: <span style='font-weight: bold'>123456789</span></div>
                                            </div>
                                        `,
                                        iconColor: '#0E092C',
                                        customClass: {
                                            popup: 'swal-wide'
                                        },
                                        showCancelButton: true,
                                        cancelButtonText: 'Close',
                                        confirmButtonText: 'Ok',
                                        reverseButtons: true
                                    })"
                                />
                            </span>
                        </div>
                        <FormulateInput 
                            type="text" 
                            validation="required" 
                            placeholder="Post Identifier" 
                            v-model="post_identifier"
                            :wrapper-class="inputWrapperClasses"
                        />
                    </div>
                    
                    <div class="field col-span-2">
                        <div class="label">Date</div>
                        <FormulateInput 
                            type="date" 
                            validation="required" 
                            placeholder="Type the content published at date" 
                            v-model="date"
                            :wrapper-class="inputWrapperClasses"
                        />
                    </div>

                    <div class="field col-span-2">
                        <div class="label">Likes</div>
                        <FormulateInput type="number" validation="required" placeholder="Post likes count" v-model="likes" :wrapper-class="inputWrapperClasses"/>
                    </div>
                    <div class="field col-span-2">
                        <div class="label">Dislikes</div>
                        <FormulateInput type="number" validation="required" placeholder="Post dislikes count" v-model="dislikes" :wrapper-class="inputWrapperClasses" />
                    </div>
                    <div class="field col-span-2">
                        <div class="label">Comments</div>
                        <FormulateInput type="number" validation="required" placeholder="Post comment count" v-model="comments" :wrapper-class="inputWrapperClasses"/>
                    </div>
                    <div class="field col-span-2">
                        <div class="label">Views</div>
                        <FormulateInput type="number" validation="required" placeholder="Type the views count" v-model="views" :wrapper-class="inputWrapperClasses"/>
                    </div>
                    <div class="field col-span-2">
                        <div class="label">Shares</div>
                        <FormulateInput type="number" validation="required" placeholder="Type the shares count" v-model="shares" :wrapper-class="inputWrapperClasses"/>
                    </div>
                    <div class="field col-span-2">
                        <div class="label">Saves</div>
                        <FormulateInput type="number" validation="required" placeholder="Type the saves count" v-model="saves" :wrapper-class="inputWrapperClasses"/>
                    </div>
                </div>

            </tab>
            
            <!-- Preview -->
            <tab :visible="step == 2">
                <div class="flex justify-center">
                    <report-post-card 
                        is-preview 
                        class="post-card mb-4" 
                        :post="computedManualPost"
                    />
                </div>
            </tab>
            
            <div class="footer">
                <base-button label="Cancel" v-if="step == 1" @action="$emit('close')" type="secondary" size="md" />
                <base-button label="Back" v-if="step >= 2" @action="back()" type="secondary" size="md" />
                <base-button label="Next" v-if="step <= 1" :disabled="nextDisabled()" @action="next()" />
                <base-button label="Submit" v-if="step == 2" :disabled="nextDisabled()" @action="submit()"/>
            </div>
           
        </div>
    </base-modal>
</template>
<script>
import ReportPostCard from '../../components/ReportPostCard';
import { getNetwork, removeUnnecessaryQueryParams, getParamsFromSocialPostUrl } from '@/lib/network';
import { notifyCatchError } from '../../common';
const inputWrapperClasses = 'border rounded-md py-2 px-3 bg-white h-12 flex items-center';
export default {
    props: ['visible', 'currentPost', 'reportId', 'newPostLink'],
    components: {
        ReportPostCard
    },
    data(){
        return {
            step: 1,
            loading: false,
            uploadingFile: false,

            // needed for manual replacing
            user_id: '',
            original_url: '',
            profile_item: {},

            // user data inherited from audience data
            username: '',
            full_name: '',
            profile_picture_url: '',
            total_followers: '', 
            total_following: '',

            // manual post data
            post_url: '',
            url: '',
            display_url: '',
            platform: {id: 'facebook', name: 'Facebook', value: 6}, // depends on prop
            video_duration: '00:00:00',
            caption: '',
            post_identifier: '',
            date: '',
            likes: 0,
            dislikes: 0,
            comments: 0,
            views: 0,
            shares: 0,
            saves: 0,

            // not editable
            description: '', // not used
            medium: {id: 'video', name: 'Video'}, // not editable for now, always video
            bio: '', // not editable
            social_id: '', // not editable
            profile_url: '',

            inputWrapperClasses
        }
    },
    watch: {
        visible(val){
            if(val){

                // set user vars
                this.social_id = this.currentPost.profile_item.social_id
                this.profile_url = this.currentPost.profile_item.uri
                this.username = this.currentPost.profile_item.username
                this.profile_picture_url = this.currentPost.profile_item.profile_picture_url
                this.full_name = this.currentPost.profile_item.full_name

                this.user_id = this.currentPost.user.id
                this.original_url = this.currentPost.original_url || this.currentPost.link
                this.profile_item = this.currentPost.profile_item

                // set platform
                const networks = [
                    {id: 'facebook', name: 'Facebook', label: 'Facebook', value: 6},
                    {id: 'instagram', name: 'Instagram', label: 'Instagram', value: 2},
                    {id: 'tiktok', name: 'TikTok', label: 'TikTok', value: 14},
                    {id: 'twitter', name: 'Twitter', label: 'Twitter', value: 7},
                    {id: 'pinterest', name: 'Pinterest', label: 'Pinterest', value: 4},
                    {id: 'linkedin', name: 'LinkedIn', label: 'LinkedIn', value: 16},
                ];
                const network_id = this.currentPost.network_id
                const found = networks.find(element => element.id == getNetwork(network_id, 'name'))
                if(found){
                    this.platform = found
                }

                if(this.post_url.trim() == '' && this.newPostLink.trim() != ''){
                    this.post_url = this.newPostLink
                    this.getUrlParams()
                }

            }
        }
    },
    methods: {

        async submit(){
            this.loading = true;
            try {
                // user vars
                const { username, full_name, total_followers, total_following, total_likes, profile_picture_url } = this
                // post vars
                const { description, platform, medium, url, display_url, date, caption, post_identifier, video_duration, likes, dislikes, comments, views, shares, saves} = this
                // other
                const { post_url, profile_url, bio, social_id } = this

                let { data } = await this.$http.post(`/api/reports/manual-replace-post`, { 
                    reportId: this.reportId,                    
                    userId: this.user_id,
                    originalUrl: this.original_url,
                    profileItem: this.profile_item,
                    manualPost: {
                        
                        // user vars
                        profile_picture_url,
                        full_name,
                        username,
                        total_followers,
                        total_following,
                        total_likes,

                        // post vars
                        post_url,
                        description,
                        platform,
                        medium,
                        url,
                        display_url,
                        date,
                        caption,
                        post_identifier,
                        video_duration,
                        likes,
                        dislikes,
                        comments,
                        views,
                        shares,
                        saves,

                        // other
                        profile_url,
                        bio,
                        social_id,
                        is_video: this.medium.id === 'video'

                    } 
                });

                this.$notify({ title: 'Success', text: `Post replaced successfully`, type: 'success' });
                
                // reset to initial state
                // TODO reset all data
                this.post_url = ''
                this.url = ''
                this.display_url = ''
                this.video_duration = '00:00:00'
                this.caption = ''
                this.post_identifier = ''
                this.date = ''
                this.likes = 0
                this.dislikes = 0
                this.comments = 0
                this.views = 0
                this.shares = 0
                this.saves = 0

                this.$emit('close');
                this.$emit('done');
            }
            catch(err) {
                console.log(err);
                notifyCatchError(err, this.$notify, 'Unable to save manual post. Please try again later')
            }
            finally {
                this.loading = false;
            }
        },

        next(){
            this.step++
        },
        back(){
            this.step--
        },

        // validation
        nextDisabled() {
            let requiredFields = [];
            switch (this.step) {
                case 1:
                    requiredFields = ['url', 'display_url', 'video_duration', 'caption', 'post_identifier', 'date', 'likes', 'dislikes', 'comments', 'views', 'shares', 'saves' ];
                    break;
            }
            // validation
            let errors = 0;
            for (let i in requiredFields) {
                let val = this[requiredFields[i]];
                if (val === undefined || val === null || val === '' || (Array.isArray(val) && !val.length)) {
                    errors++;
                }
            }
            return !!errors;
        },

        getUrlParams(){
            this.post_url = removeUnnecessaryQueryParams(this.post_url)
            const params = getParamsFromSocialPostUrl(this.post_url)
            if(params.userId) this.social_id = params.userId;
            if(params.postId) this.post_identifier = params.postId;
            if(params.userName) this.username = params.userName;
            if(params.videoId) this.post_identifier = params.videoId;
        },

        // file uploads
        uploadMediaFile(e){
            this.uploadFile(e, 'url');
        },
        uploadDisplayFile(e){
            this.uploadFile(e, 'display_url');
        },
        uploadFile(e, path) {
            this.uploadingFile = true;
            let fileData = new FormData();
            fileData.append('file', e.target.files[0]);
            this.$http.post(`/api/files/manual/posts`, fileData).then(async ({data}) => {
                this[path] = data.url;
            }).catch((err) => {
                this[path] = '';
                console.log('upload picture error', err.response);
                this.$notify({ title: 'Something went wrong while uploading media.', text: 'Try again later', type: 'warn' });
            }).finally(() => {
                this.uploadingFile = false;
            });
        },
        openLink(url) {
            if (url !== '') {
                let a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                a.click();
            }
        }
    },
    computed: {
        // for preview
        computedManualPost() {

            let {
                description,
                platform,
                medium,
                url,
                display_url,
                date,
                caption,
                profile_picture_url,
                bio,
                full_name,
                username,
                profile_url,
                social_id,
                post_identifier,
                video_duration,
                likes,
                dislikes,
                comments,
                views,
                shares,
                saves,
                total_followers,
                total_following,
                total_likes,
            } = this;

            platform.name = platform.name.toLowerCase();
            let is_video = medium.id === 'video';

            return {
                post_identifier,
                date,
                network: platform,
                content_type_id: 1,
                type: {id: 1, name: 'post'},
                is_video,
                url,
                caption,
                description,
                display_url,
                likes,
                dislikes,
                comments,
                views,
                shares,
                saves,
                media: [
                    {mimetype: is_video ? 'video/mp4' : 'image/png', video_duration, url, poster: display_url}
                ],
                profile_item: {
                    bio,
                    uri: profile_url,
                    username,
                    full_name,
                    social_id,
                    total_followers,
                    total_following,
                    total_likes,
                    profile_picture_url,
                    total_views: 0,
                    total_videos: 0
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    // global
    .field{
        @apply gap-y-1;
    }
    .label{
        @apply text-h6 font-bold;
    }
    .container{
        @apply flex flex-col justify-between;
        min-height: 260px;
        .section{
            @apply grid grid-cols-6 gap-4;
            > .file-field{
                @apply flex flex-col gap-y-1;
                > .header{
                    @apply w-full flex justify-between;
                }
                > .help{
                    @apply text-xs italic;
                }
                > .input-container{
                    @apply w-full relative;
                    > .upload-button{
                        @apply absolute right-0 top-0 h-12 w-12 border rounded-md bg-white flex justify-center items-center;
                    }
                }
            }
        }

        // .preview{
        //     @apply flex gap-2;
        //     > .container{
        //         @apply flex w-full justify-center;
        //     }
        // }
        .footer{
            @apply mt-4 flex justify-between;
        }
    }
</style>

<template>
    <base-modal 
        :visible="visible" 
        @close="$emit('close')" 
        title="Link not supported"
    >
        
        <div class="text-h5 font-bold">We weren't able to automatically get data from the link, because its not supported a supported link yet.</div>

        <div class="text-h5 font-bold mt-4">These are the current supported links:</div>

        <div class="flex flex-col mt-4">
            <div v-for="route in postRoutes" :key="route.sample" class="text-pxs">
                {{ route.sample }}
            </div>
        </div>

        <div class="w-full flex justify-between mt-12">
            <base-button
                label="Dismiss"
                class="font-bold"
                type="secondary"
                @action="$emit('close')"
            />
            <base-button
                label="Continue"
                class="font-bold"
                @action="$emit('close')"
            />
        </div>

    </base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue'
import { postRoutes } from '@/lib/network';
export default {
    props: ['visible'],
    components: {
        BaseModal
    },
    data(){
        return {
            postRoutes
        }
    }
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"flex gap-x-4"},[_c('div',{staticClass:"font-bold mb-2"},[_vm._v(_vm._s(_vm.data.name))]),(_vm.loading)?_c('base-icon',{attrs:{"name":"loader"}}):_vm._e()],1),_c('div',{staticClass:"flex items-center gap-x-2"},[_c('div',[_vm._v(_vm._s(_vm.selected.length)+" selected")]),_c('base-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ 
                            content: 'Clear Selection', 
                            classes: 'bg-black text-white rounded py-1 px-2 text-xs', 
                            delay: { 
                                show: 30,
                                hide: 50 
                            } 
                        }),expression:"{ \n                            content: 'Clear Selection', \n                            classes: 'bg-black text-white rounded py-1 px-2 text-xs', \n                            delay: { \n                                show: 30,\n                                hide: 50 \n                            } \n                        }"}],staticClass:"text-gray-500 cursor-pointer hover:opacity-50 transition-opacity duration-200",attrs:{"name":"minus-circle","size":6},on:{"action":function($event){_vm.selected = []}}}),_c('base-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ 
                            content: _vm.onlySelected ? 'Hide Selected' : ("View Selected " + ((!_vm.selected.length && !_vm.onlySelected) ? ", none selected yet" : "")),
                            classes: 'bg-black text-white rounded py-1 px-2 text-xs',
                            placement: 'left',
                            delay: { 
                                show: 30,
                                hide: 50 
                            } 
                        }),expression:"{ \n                            content: onlySelected ? 'Hide Selected' : `View Selected ${(!selected.length && !onlySelected) ? `, none selected yet` : ``}`,\n                            classes: 'bg-black text-white rounded py-1 px-2 text-xs',\n                            placement: 'left',\n                            delay: { \n                                show: 30,\n                                hide: 50 \n                            } \n                        }"}],staticClass:"text-gray-500 cursor-pointer hover:opacity-50 transition-opacity duration-200",attrs:{"name":_vm.onlySelected ? 'eye-closed' : 'eye',"size":6},on:{"action":function($event){(!_vm.selected.length && !_vm.onlySelected) ? null : _vm.onlySelected = !_vm.onlySelected}}})],1)]),_c('filter-select',{attrs:{"placeholder":"Search cities","loading":_vm.loading,"options":_vm.options,"onlySelected":_vm.onlySelected},on:{"toggle-only-selected":function (option) { return _vm.onlySelected = option; },"search":_vm.getOptions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var option = ref.option;
return _c('div',{staticClass:"flex gap-x-2 items-center"},[_c('div',{staticClass:"font-bold"},[_vm._v(_vm._s(option.label))]),_c('div',{staticClass:"text-pxs text-gray-600"},[_vm._v(_vm._s(("" + (option.region) + (option.country ? (", " + (option.country)) : ""))))])])}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('div',{staticClass:"footer"},[_c('base-button',{attrs:{"label":("" + (_vm.filters.city ? "Update Filter" : "Add Filter")),"icon":"filter","size":"lg"},on:{"action":_vm.addFilter}}),_c('base-button',{attrs:{"type":"secondary","label":"Cancel"},on:{"action":function($event){return _vm.$emit('close')}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
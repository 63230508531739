
<template>
<div>

    <div class="text-h1 mb-8">Quick Start</div>

</div>
</template>
<script>

export default {

}
</script>     
<template>
    <div class="wrapper" v-if="user && user.id !== 0">
        
        <div class="header">
            <tabs
                :tabs="[{ name: 'Instagram top posts', active: true }, { name: 'TikTok top posts', active: true }]"
                v-model="tab"
            />
        </div>

        <div class="content">
            <tab :visible="tab == 'Instagram top posts'">
                <div v-if="!hasTopPosts(2)">
                    No instagram top posts found
                </div>
                <div class="post-list" v-else>
                    <report-post-card
                        v-for="(post, index) in getTopPosts(2)" 
                        :key="index" 
                        is-preview 
                        class="post-card mb-4"
                        :post="post"
                    />
                </div>
            </tab>

            <tab :visible="tab == 'TikTok top posts'">
                <div v-if="!hasTopPosts(14)">
                    No TikTok top posts found
                </div>
                <div class="post-list" v-else>
                    <report-post-card
                        v-for="(post, index) in getTopPosts(14)" 
                        :key="index" 
                        is-preview 
                        class="post-card mb-4"
                        :post="post"
                    />
                </div>
            </tab>
        </div>

    </div>
</template>
<script>
import { getObjectValue, isValidArray, isValidObject, NETWORKS } from '../../../common.js'
import ReportPostCard from '../../../components/ReportPostCard.vue';
export default {
    props: ['user'],
    components: {
        ReportPostCard
    },
    data(){
        return {
            tab: 'Instagram top posts',
            key: false,
            NETWORKS
        }
    },
    computed: {
    },
    watch: {
        tab(val, old){
            if(val != old){
                setTimeout(() => {
                    this.key = !this.key
                }, 1000)
            }
        }
    },
    methods: {
        hasTopPosts(network_id){
            const top_posts = this.getTopPosts(network_id);
            if(isValidArray(top_posts)){
                return true;
            }
            return false;
        },
        getTopPosts(network_id){
            const { audience_data } = this.user
            if(isValidArray(audience_data)){
                 // only consider IG and TikTok
                const found_audience_data = audience_data.find(element => element.network_id == network_id);
                if(isValidObject(found_audience_data)){
                    const user_profile = getObjectValue(found_audience_data, 'payload.user_profile')
                    const top_posts = getObjectValue(found_audience_data, 'payload.user_profile.top_posts')
                    if(isValidObject(user_profile) && isValidArray(top_posts)){

                        // get basic profile info
                        let { fullname, avg_likes, description, avg_views, username, followers, url, picture, type, user_id } = user_profile;
                        
                        // set network & network_id
                        let network = {};
                        let network_id = 0;
                        
                        for (let i in NETWORKS) {
                            if (NETWORKS[i].name === type || NETWORKS[i].id === type) {
                                network_id = NETWORKS[i].id;
                                network = {
                                    display_name: NETWORKS[i].display_name,
                                    name: NETWORKS[i].name,
                                    id: network_id
                                }
                            }
                        }

                        // profile item object
                        let profile_item = {
                            bio: description,
                            uri: url,
                            username: username || fullname,
                            full_name: fullname,
                            social_id: user_id,
                            total_likes: avg_likes,
                            total_views: avg_views,
                            total_videos: 0,
                            total_followers: followers,
                            total_following: 0,
                            profile_picture_url: picture,
                            network_id,
                        };

                        return top_posts.map(post => {
                            let is_video = post.type === 'video';
                            let is_replaced = false || post.is_replaced;                            
                            let element = {
                                comments: post.stat.comments,
                                likes: post.stat.likes,
                                dislikes: 0,
                                views: post.stat.views || post.stat.plays,
                                shares: post.stat.shares,
                                saves: post.stat.saves,
                                caption: post.text || '',
                                display_url: post.image || post.thumbnail,
                                post_identifier: post.post_id,
                                is_copyrighted: !!post.is_copyrighted,
                                profile_item,
                                network_id,
                                network,
                                is_replaced,
                                is_featured: post.is_featured,
                                date: post.created,
                                media: [{
                                    id: post.user_id,
                                    mimetype: is_video ? 'video/mp4' : 'image/png',
                                    media_identifier: null,
                                    video_duration: "00:00:00",
                                    url: post.video || post.image || post.thumbnail,
                                    type: is_video ? 'video/mp4' : 'image/png'
                                }],
                                type: {
                                    name: 'post'
                                },
                                url: post.link,
                                original_url: is_replaced ? post.original_url : post.link,
                                is_video,
                                boosted_data: post.boosted_data || []
                            };
                            return element;
                        }).sort((a, b) => {
                            a.date = new Date(a.date);
                            b.date = new Date(b.date);
                            return b.date - a.date;
                        });

                    }
                }
            }
            return null;
        },
        
    }
}
</script>
<style lang="scss" scoped>
    .wrapper{
        @apply px-12 py-10;
        @apply flex flex-col gap-y-12;
        > .header{
            //
        }
        > .content{
            //
        }
    }
    .post-list{
        @apply w-full;
        @apply flex justify-center flex-wrap gap-12;
    }
</style>

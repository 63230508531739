<template>
    <div></div>
</template>

<script>
import { decryptStr } from '../lib/strings';

export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    
    data() {
        return {
            tempAttachments: [],
            attachments: [],

            pickerInited: false,
            gisInited: false,
            developerKey: '',
            clientId: '',
            scope: 'https://www.googleapis.com/auth/drive.readonly',
            oauthToken: null,

            picker: null,
            tokenClient: null,
            passphrase: 'google'
        }
    },

    beforeMount() {
        this.getCredentials();
    },

    watch: {
        visible: function (val) {
            if (val) {
                this.driveIconClicked();
            }
        }
    },

    methods: {
        async getCredentials() {
            const { data } = await this.$http.get(`/api/auth/get-auth-credentials/google`);
            this.developerKey = decryptStr(data.GOOGLE_DEVELOPER_KEY, this.passphrase);
            this.clientId = decryptStr(data.GOOGLE_CLIENT_ID, this.passphrase);
        },

        async driveIconClicked() {
            this.tokenClient = window.google.accounts.oauth2.initTokenClient({
                client_id: this.clientId,
                scope: this.scope,
                callback: '', // defined later
            });
            this.gisInited = true;
            window.gapi.load('picker', () => {
                this.pickerInited = true;
                this.createPicker();
            });
        },

        showPicker() {
            if (this.pickerInited && this.oauthToken) {
                this.picker = new window.google.picker.PickerBuilder()
                    .addView(window.google.picker.ViewId.DOCS)
                    .setOAuthToken(this.oauthToken)
                    .setDeveloperKey(this.developerKey)
                    .setCallback(this.pickerCallback)
                    .build();
                this.picker.setVisible(true);
            }
        },

        createPicker() {
            this.tokenClient.callback = async (response) => {
                if (response.error !== undefined) {
                    throw (response);
                }
                this.oauthToken = response.access_token;
                this.showPicker();
            };

            if (this.oauthToken === null) {
                this.tokenClient.requestAccessToken({prompt: 'consent'});
            } else {
                this.tokenClient.requestAccessToken({prompt: ''});
            }
        },

        async pickerCallback(data) {
            if (data[window.google.picker.Response.ACTION] === window.google.picker.Action.PICKED) {
                let attachments = [];
                let docs = data.docs;
                for (let i = 0; i < docs.length; i++) {
                    let attachment = {};
                    attachment._id = docs[i].id;
                    attachment.title = docs[i].name;
                    attachment.name = docs[i].name;
                    attachment.mimetype = docs[i].mimeType;
                    attachment.type = "gDrive";
                    attachment.description = "Shared with GDrive";
                    attachment.extension = "." + docs[i].mimeType.substring(docs[i].mimeType.lastIndexOf(".") + 1);
                    attachment.iconUrl = docs[i].iconUrl;
                    attachment.size = docs[i].sizeBytes;
                    attachment.url = docs[i].url;
                    attachments.push(attachment);
                }
                this.tempAttachments = [...attachments];
            } else {
                this.tempAttachments = [];
            }

            this.$emit('set-files', this.tempAttachments);
        }
    },

    computed: {
        getTempAttachments() {
            return this.tempAttachments;
        },
        getAttachments() {
            return this.attachments;
        }
    }
}
</script>
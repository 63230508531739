<template>

    <base-modal
        :visible="visible"
        title="Invite with email or link"
        @close="$emit('close')"
        with-picture
        modal-title="Invite Creator"
        modal-icon="user-inverted"
        no-padding
    >
        <div class="wrapper">
            <div class="content">
                <div class="link">
                    <FormulateInput 
                        :key="locationKey" 
                        :value="getLocation" 
                        readonly 
                        class="w-full" 
                        :disabled="!intentData.token"
                    />
                    <base-button 
                        @action="copyToClipboard()" 
                        :disabled="loading || !intentData.token" 
                        size="sm" 
                        label="Copy"
                    />
                </div>

                <FormulateInput
                    v-model="email"
                    class="w-full mt-2"
                    label="Invite with email"
                    placeholder="example@mail.com"
                    validation="email"
                />
            </div>

            <div class="footer">
                <base-button 
                    @action="inviteByEmail" 
                    :disabled="loading || email === '' || !validEmail" 
                    size="md" 
                    :icon-size="4" 
                    bold 
                    icon="arrow-right" 
                    label="Invite"
                />
            </div>
        </div>
    </base-modal>
</template>

<script>
import FormulateInput from "@braid/vue-formulate/src/FormulateInput";
import { validateEmail } from '../../lib/strings';
export default {
    components: {FormulateInput},
    props: ['visible'],
    data() {
        return {
            intentData: {},
            loading: false,

            locationKey: false,

            step: 1,
            email: '',

        }
    },
    watch: {
        visible: function (val) {
            if (val) {
               this.postInvitationIntent();
            }
        }
    },
    computed: {
        validEmail(){
            return validateEmail(this.email)
        },
        getLocation() {
            return this.intentData.token ? `${window.location.origin}/register?t=${this.intentData.token}` : ``
        }
    },
    methods: {
        async postInvitationIntent(){
            this.loading = true
            try{
                const { data } = await this.$http.post('/api/invite/intent', {
                    roleId: 13
                });
                this.intentData = data
            }catch(error){
                console.log(error)
                if(error.response.data.message){
                    this.$notify({ title: 'Error', text: `${error.response.data.message}`, type: 'error' })
                }
            }finally{
                this.loading = false
                this.locationKey = !this.locationKey;
            }
        },
        copyToClipboard(){
            this.$copyToClipboard(this.$notify, this.getLocation);
        },
        async inviteByEmail() {
            this.loading = true
            try{
                await this.$http.post('/api/invite', {
                    email: this.email,
                    url: this.getLocation
                });
                this.$notify({ title: 'Success', text: 'Email sent successfully', type: 'success' })
                this.$emit('done')
            }catch(error){
                console.log(error)
                this.$notify({ title: 'An error ocurred', text: `${error.response && error.response.data && error.response.data.message && (`:${error.response.data.message}`)}`, type: 'error' })
            }finally{
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .wrapper{
        @apply flex flex-col justify-between;
        .content{
            @apply pt-5 pb-20 px-14;
            .link{
                @apply flex justify-between gap-x-5 my-5;
            }
        }
        .footer{
            @apply flex justify-end gap-3 bg-white rounded-br-2xl py-3 px-14;
        }
    }
</style>
<template>        
    <div class="header">
        <div class="general">

            <div class="left">
                
                <template v-if="loading">
                    <skeleton type="circle" :width="32"/>
                    <div class="flex flex-col items-start justify-start gap-2">
                        <skeleton :height="8" :width="36"/>
                        <skeleton :height="4" :width="48"/>
                    </div>
                </template>

                <template v-else>
                    <avatar :user="user" size="xl2"/>
                    <div v-if="!loading" class="flex flex-col items-start justify-start gap-2">
                        <div class="text-h2 text-white font-bold">{{user.first_name}} {{user.last_name}}</div>
                    </div>
                </template>

            </div>
        </div>

        <div class="tabs">
            <tabs 
                theme="dark" 
                :tabs="loading ? ['About'] : ['About', 'Portfolio']" 
                :value="value"
                @input="val => {
                    $emit('input', val); 
                    $forceUpdate();
                }"
            />
        </div>
    </div>
</template>

<script>
    import Networks from '../../components/Networks.vue'
    export default {
        props: ['user', 'loading', 'value'],
        components: {
            Networks
        }
    }
</script>

<style lang="scss" scoped>
    .header{
        @apply px-12 py-10 pb-0 bg-gradient-to-b from-gradient-m-main-start to-gradient-m-main-end relative;
        .general{
            @apply flex justify-between items-center py-10;
            .left{
                @apply flex gap-8 items-center;
            }
        }
        .tabs{
            @apply flex justify-between items-center;
        }
    }
</style>
<template>
    <div>
        
        <Header title="System Users" :count="systemUsers.length" :loading="loading" />
        
        <div class="system-users-list">
            <div v-if="!systemUsers.length">No System Users were found</div>

            <div class="item" v-for="(systemUser, index) in systemUsers" :key="index">

                <div>ID: {{systemUser.id}}</div>
                <div>Name: {{systemUser.name}}</div>

            </div>
        </div>
 
    </div>
</template>

<script>
import Header from './Header.vue'
import { mapGetters } from 'vuex';
export default {
    components: {
        Header
    },
    data(){
        return {
            systemUsers: [],
            loading: false
        }
    },
    mounted() {
        this.getSystemUsers()
    },
    computed: {
        ...mapGetters(['company', 'fbLogin']),
    },
    methods: {
        async getSystemUsers(){
            this.loading = true
            this.$http.get('/api/facebook', {
                params: {
                    entity_id: this.company.fb_business_id,
                    edge: 'system_users',
                    user_access_token: true
                }
            }).then(async (res) => {
                if(res.data){
                    this.systemUsers = res.data
                }
            }).catch((err) => {
                console.log('getSystemUsers error', err)
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .system-users-list{
        @apply flex flex-col mt-6 gap-y-4 items-start;
        .item{
            @apply w-full;
            @apply px-6 py-2 border border-black rounded-xl;
        }
    }
</style>
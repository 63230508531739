<template>
    <base-modal 
        title="Password protected file"
        :visible="visible"
        @close="$emit('close')"
    >
        <FormulateForm name="form" @submit="submit()">
            <div>
                <div class="flex mb-2">
                    <h2 class="font-medium">Enter the file's password to view it</h2>
                </div>

                <div class="flex flex-wrap gap-x-4 mt-3">
                    <FormulateInput 
                        type="password" 
                        label="Password"
                        class="w-full"
                        placeholder="Enter password"
                        v-model="password" 
                    />
                </div>
            </div>
            <div class="pt-3 sm:flex sm:flex-row-reverse">

                <base-button @action="$formulate.submit('form')" submit label="Submit"/>

            </div>
        </FormulateForm>
    </base-modal>
</template>

<script>
export default {
    props: ['fileGuid', 'visible'],
    data() {
        return {
            password: '',
            loading: false
        }
    },
    methods: {
        async submit(){

            this.loading = true
            try{
                
                if(!this.fileGuid) return this.$notify({ title: 'Warning', text: 'No target file', type: 'warn' })
                if(!this.password || this.password == '') return this.$notify({ title: 'Warning', text: 'Password required', type: 'warn' })

                const { data } = await this.$http.post(`/api/files/${this.fileGuid}/unlock`, {
                    password: this.password
                })

                if(data){
                    this.$notify({ title: 'Success', text: 'Correct password', type: 'success' })
                    this.$emit('unlocked', this.password)
                    this.$emit('close')
                }

            }catch(err){
                console.log('unlock file error', err)
                this.$notify({ title: 'Error', text: `${(err.response && err.response.data) ? err.response.data.message : ``}`, type: 'warn' })
            }finally{
                this.loading = false
            }
        }
    }
}
</script>
<template>
    <div class="date-picker-main">
        
        <FormulateInput
            type="text"
            v-model="localStringValue"
            placeholder="MM-DD-YYYY"
            @click="datepickerVisible = !datepickerVisible"
            v-debounce="handleInput"
            :disabled="disabled"
            :input-class="`w-full outline-none cursor-pointer text-pm text-purple-m-secondary transition duration-300`"
            :wrapper-class="inputWrapperClass ? inputWrapperClass : null"
            :outer-class="inputOuterClass ? inputOuterClass : null"
            :label="label"
            :validation="validation ? `${validation}|date:MM-DD-YYYY` : `date:MM-DD-YYYY`"
            :validation-name="validationName ? validationName : label ? label : 'Date'"
            :validation-messages="{ date: 'Use the format MM-DD-YYYY' }"
            :error-class="['absolute']"
        />
        
        <template v-if="datepickerVisible">
          
          <div 
            class="date-picker-overlay"
            @click="datepickerVisible = false" 
          />

          <div 
            class="date-picker-wrapper"
            :style="`${positionStyles} ${wrapperStyles}`"
            @click.stop
          >
            <custom-datepicker
              @dateSelected="setDate($event)"
              :date="localValue || moment().format(pickerFormat)"
              primaryColor="#0E092C"
              :wrapperStyles="{ 
                width: '280px',
                border: '1px solid #d2d6dc',
                borderRadius: '0.5rem'
              }"
              :headerStyles="{
                paddingTop: '0px',
                paddingBottom: '0px',
                marginTop: '14px'
              }"
              :weekdayStyles="{
                paddingTop: '0px',
                paddingBottom: '0px'
              }"
              :bodyStyles="{
                paddingTop: '0px',
                paddingBottom: '0px'
              }"
            />
          </div>

        </template>

    </div>
</template>

<script>
import CustomDatepicker from 'vue-custom-datepicker'
import moment from 'moment'
import { isValidDate } from '../common'

export default {
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      required: true
    },
    position: {
      type: String,
      default: 'top'
    },
    wrapperStyles: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validation: {
      type: String,
      default: null
    },
    validationName: {
      type: String,
      default: null
    },
    inputWrapperClass: {
      type: String,
      default: ''
    },
    inputOuterClass: {
      type: String,
      default: ''
    }
  },
  components: {
    'custom-datepicker': CustomDatepicker
  },
  data () {
    return {
      pickerFormat: 'MM-DD-YYYY',
      inputFormat: 'MM-DD-YYYY',

      localValue: moment().format(this.pickerFormat),
      localStringValue: '',
      
      datepickerVisible: false
    }
  },
  mounted() {
      if(isValidDate(this.value, false)){
        this.localValue = moment(this.value).format(this.pickerFormat)
        this.localStringValue = moment(this.value).format(this.inputFormat)
      }
  },
  watch: {
    value(val){
      if(isValidDate(val, false)){
        this.localValue = moment(val).format(this.pickerFormat)
        this.localStringValue = moment(val).format(this.inputFormat)
      }
    }
  },
  methods: {
    handleInput(){
      // check that its a complete date
      const isFullDate = this.localStringValue.length == 10;
      if(isFullDate && isValidDate(this.localStringValue, false)){
        // same format as the datepicker returns
        const formatted = moment(this.localStringValue).format('YYYY-MM-DD')
        this.setDate(formatted)
      }
    },
    setDate(date) {
        this.localValue = moment(date).format(this.pickerFormat)
        this.localStringValue = moment(date).format(this.inputFormat)
        this.$emit('change', date)
        this.$emit('input', date)
        this.datepickerVisible = false
    }
  },
  computed: {
    positionStyles(){
      // important to add the semicolon as we may be appending
      // more styles with the wrapperStyles prop 
      var positions = {
          'top': 'top: -300px;',
          'bottom': ''
      };
      return positions[this.position]
    }
  }
}
</script>
<style>
  #cd-day-select > article{
    margin-bottom: -8px;
  }
</style>
<style lang="scss" scoped>
  .date-picker-main{
    @apply relative;
    .date-picker-overlay{
      @apply fixed inset-0 z-10 cursor-pointer;
    }
    .date-picker-wrapper{
      @apply absolute z-10 bg-white rounded-lg;
    }
  }
</style>
<template>
  <div class="posts-report-wrapper">

    <advanced-loader :visible="refreshing" :index="100" label="We are refreshing the report" :percentage="refreshingPercentage"/>
    
    <story-viewer
      v-if="storiesViewer && currentStories.length && hideStories"
      :current-stories="currentStories"
      @close="closeStories"
    />
    
    <div class="posts-report-container">
      
      <!-- report top (title, tags & actions) -->
      <report-top 
        :report="report" 
        :user="user" 
        @refresh="refresh" 
        @export-button-handler="exportButtonHandler"
      />
      
      <!-- wrapper, shows stats & creators(groups) list -->
      <div class="stats-groups" v-if="!reportData.settings.hide_resume">
        
        <!-- container -->
        <div class="stats-groups-container" :class="{'not-print-view': !printView}">
          
          <!-- this class is required to apply styles while printing -->
          <!-- id is being used for pdf generation -->
          <div id="stats" class="stats" :class="{'not-print-view': !printView}">

            <div class="header">

              <h2 class="title">Social Media Breakdown</h2>

              <div class="platform-select" v-if="!printView">
                <div 
                  @click="platform_selected = 'all'" 
                  :class="['all-item', platform_selected === 'all' ? 'selected' : 'not-selected']"
                >
                  All
                </div>
                <div 
                  v-for="platform in platforms" 
                  :key="platform.id" 
                  @click="platform_selected = platform.id" 
                  :class="['item', platform_selected === platform.id ? 'selected' : 'not-selected']"
                >
                  {{ platform.display_name }}
                </div>
              </div>
            </div>
            
            <!-- main stats -->
            <div class="main-stats">
              <stat-card
                :label="`${hasRepeatedCreators ? `${showUniqueReach ? 'Unique' : 'Total'} Reach` : `Reach` }`"
                :tooltip="`${
                  showUniqueReach ? 
                    `The unique sum of followers for each creator across all posts in this report. This metric accounts for each creator's reach only once, even if they have multiple posts.` : 
                    `Overall reach of the creator(s), the cumulative sum of the creator's followers for each of the posts in this report.`
                }`"
                :value="(showUniqueReach ? uniqueReach : reach) | numeral('0.0a')"
                :icon-size="7"
                icon="reach"
                container-class="col-span-2 md:col-span-1"
              >
                <template v-slot:label v-if="hasRepeatedCreators">
                  <!-- custom options dropdown -->
                  <div class="reach-type-dropdown-wrapper">
                    <base-icon
                      size="3"
                      @action="reachTypeDropdownVisible = !reachTypeDropdownVisible"
                      class="cursor-pointer ml-2"
                      :name="reachTypeDropdownVisible ? 'chevron-up' : 'chevron-down'"
                    />
                    <div class="dropdown" v-if="reachTypeDropdownVisible">
                      <div 
                        class="item" 
                        :class="{ 'active': showUniqueReach == false }"
                        v-tooltip="{ content: `The cumulative sum of the creator's followers for each of the posts in this report.`, classes: 'v-tooltip-black' }"
                        @click="showUniqueReach = false; reachTypeDropdownVisible = false;"
                      >
                        Total Reach
                      </div>
                      <div 
                        class="item"
                        :class="{ 'active': showUniqueReach == true }"
                        v-tooltip="{ content: `The unique sum of followers for each creator across all posts in this report.`, classes: 'v-tooltip-black' }"
                        @click="showUniqueReach = true; reachTypeDropdownVisible = false;"
                      >
                        Unique Reach
                      </div>
                    </div>
                  </div>
                </template>
              </stat-card>
              <stat-card 
                label="Creators"
                tooltip="Total number of creators in this report."
                :value="profiles" 
                :icon-size="7" 
                icon="post-user-group" 
                container-class="col-span-2 md:col-span-1"
              />
              <stat-card 
                label="Content" 
                tooltip="Total number of posts in this report."
                :value="filteredPosts.length" 
                :icon-size="7" 
                icon="post-image" 
                container-class="col-span-2 md:col-span-1"
              />
              <stat-card 
                label="Engagement Rate"
                tooltip="Total number of engagements (likes, comments, shares and saves) on all of this report's posts, divided by the number of followers."
                :value="(engagementsPercent / 100) | numeral('0.00%')" 
                :icon-size="7" 
                icon="user-group" 
                container-class="col-span-2 md:col-span-1"
              />
            </div>
            
            <!-- more stats -->
            <div class="secondary-stats">
              <!-- showMediaValues is always false right now, so we are not using that functionality -->
              <div :class="['container', showMediaValues ? 'show-media-values' : 'dont-show-media-values']">
                <detail-card label="Comments" left-icon="post-comments" :icon-size="8" icon-class="text-green-m-main" :value="computedComments | numeral('0,0')" :show-border="false" slot-position="label" :uppercase="false" label-class="text-sm font-bold" wrapper-class="pb-6" :container-class="''" :semi-bold="false" title-size="text-2xl"/>
                <detail-card v-if="showMediaValues" label="Media Value" :value="computedMediaComments | numeral('$0,0.0a')" :show-border="false" :hide-icon="true" wrapper-class="pb-6" :container-class="''" :semi-bold="false" title-size="text-3xl"/>
                <detail-card label="Likes" left-icon="post-like" :icon-size="8" icon-class="text-green-m-main" :value="engagements | numeral('0,0')" :show-border="false" slot-position="label" :uppercase="false" label-class="text-sm font-bold" wrapper-class="pb-6" :container-class="''" :semi-bold="false" title-size="text-2xl"/>
                <detail-card v-if="showMediaValues" label="Media Value" :value="computedMediaEngagements | numeral('$0,0.0a')" :show-border="false" :hide-icon="true" wrapper-class="pb-6" :container-class="''" :semi-bold="false" title-size="text-3xl"/>
                <detail-card label="Shares" :value="computedShares | numeral('0,0')" left-icon="post-share" :icon-size="8" icon-class="text-green-m-main" :show-border="false" slot-position="label" :uppercase="false" label-class="text-sm font-bold" wrapper-class="pb-6" :container-class="''" :semi-bold="false" title-size="text-2xl"/>
                <detail-card v-if="showMediaValues" label="Media Value" :value="computedMediaShares | numeral('$0,0.0a')" :show-border="false" :hide-icon="true" wrapper-class="pb-6" :container-class="''" :semi-bold="false" title-size="text-3xl"/>
                <detail-card label="Saves" :value="computedSaves | numeral('0,0')" left-icon="post-save" :icon-size="8" icon-class="text-green-m-main" :show-border="false" slot-position="label" :uppercase="false" label-class="text-sm font-bold" wrapper-class="pb-6" :container-class="''" :semi-bold="false" title-size="text-2xl"/>
                <detail-card v-if="showMediaValues" label="Media Value" :value="computedMediaSaves | numeral('$0,0.0a')" :show-border="false" :hide-icon="true" wrapper-class="pb-6" :container-class="''" :semi-bold="false" title-size="text-3xl"/>
                <detail-card label="Views" :value="computedViews | numeral('0,0')" left-icon="post-views" :icon-size="8" icon-class="text-green-m-main" :show-border="false" slot-position="label" :uppercase="false" label-class="text-sm font-bold" wrapper-class="pb-6" :container-class="''" :semi-bold="false" title-size="text-2xl"/>
                <detail-card v-if="showMediaValues" label="Media Value" :value="computedMediaViews | numeral('$0,0.0a')" :show-border="false" :hide-icon="true" wrapper-class="pb-6" :container-class="''" :semi-bold="false" title-size="text-3xl"/>
              </div>
            </div>
          </div>

          <!-- groups -->
          <!-- id is being used for pdf generation -->
          <div id="groups" class="groups" :class="{'not-print-view': !printView}">
          
            <div class="header">
              <h2 class="title">Creators</h2>
            </div>

            <!-- id required to apply styles while printing -->
            <div id="groups-content" class="content">
              <!-- loader -->
              <div v-if="loadingPosts">
                <div v-for="i in 3" :key="i + Math.random()">
                  <div class="flex flex gap-3 p-4 items-center">
                    <skeleton type="circle" :width="16" />
                    <div class="flex flex-col">
                      <skeleton :width="24"/>
                      <skeleton :width="20" :height="3"/>
                      <skeleton :width="20" :height="3"/>
                    </div>
                  </div>
                  <separator />
                </div>
              </div>

              <!-- list -->
              <report-groups
                v-else-if="loadedReport"
                :platform-selected="platform_selected"
                :initial-groups="report.settings.groups || []"
                :posts="report.posts"
                :hide-stories="hideStories"
                :allow-edit="allowConfig"
                :search="search"
                as-list
                @show-stories="showStories"
                @set-parent="setParent"
                @save="saveGroups"
              />
            </div>
          </div>
        </div>
      </div>
      
      <div class="posts-wrapper">
        
        <!-- header, present in all tabs -->
        <div 
          :class="[
            'header', 
            {
              'md:grid-cols-4': checkCols(4), 
              'md:grid-cols-5': checkCols(5), 
              'md:grid-cols-6': checkCols(6),  
              'md:grid-cols-7': checkCols(7), 
              'md:grid-cols-8': checkCols(8), 
              'md:grid-cols-9': checkCols(9),
              'md:grid-cols-10': checkCols(10),
              'md:grid-cols-11': checkCols(11),
              'md:grid-cols-12': checkCols(12), 
            }
          ]
          "
        >
          
          <h2 class="title" :class="{'is-grid-or-grouped' : isGrid || isGrouped}">Content</h2>
          
          <div class="list-search" v-if="isList">
            <input type="text" v-if="!printView" v-model="search" placeholder="Search by creator"/>
          </div>
          
          <div class="sort" @click="setSort(sort_option)" v-for="(sort_option, so_key) in sortingOptions" :key="so_key">
            {{ sort_option }}
            <sort :sort="sort" :col="sort_option" class="text-black"/>
          </div>

          <div class="grid-or-grouped-search" v-if="isGrid || isGrouped">
            <input type="text" v-if="!printView" v-model="search" placeholder="Search by creator"/>
          </div>

          <div class="tab-select" :class="{'is-list': isList}" v-if="!printView || isGrid || isGrouped">
              <base-button 
                v-if="!reportData.settings.hide_stats" 
                @action="view = 'list'" 
                icon="grid" 
                :border="false" 
                rounded="none" 
                :type="isList ? 'main' : 'secondary'" 
                size="" 
                :outline="false"
              />
              <base-button 
                v-if="!(reportData.settings.hide_stats && reportData.settings.hide_resume)" 
                @action="view = 'grid'; redrawPosts()" 
                icon="tiles" 
                :border="false" 
                rounded="none" 
                :type="isGrid ? 'main' : 'secondary'" 
                size="" 
                :outline="false" 
              />
              <base-button 
                v-if="!reportData.settings.hide_resume" 
                @action="view = 'grid-grouped'; redrawPosts()" 
                icon="user-group" 
                :border="false" 
                rounded="none" 
                :type="isGrouped ? 'main' : 'secondary'" 
                size="" 
                :outline="false" 
              />
          </div>
        </div>

        <!--  list tab -->
        <!-- id is being used for pdf generation -->
        <div id="list-tab" class="list-tab" :class="sortedSearchPosts.length ? 'has-length' : ''" v-if="isList && !loadingPosts">
          <ReportPostList 
            v-for="post in sortedSearchPosts" 
            :key="post.id" 
            :post="post" 
            :columns="sortingOptions" 
            :printView="printView"
          />
          <div class="empty-state" v-if="!sortedSearchPosts.length && !loadingPosts">
            {{ search !== '' ? 'No posts match this search' : 'There are no posts in this report yet' }}
          </div>
        </div>

        <!-- skeleton loader -->
        <div class="skeleton-loader" v-if="loadingPosts">
          <div class="wrapper">
            <div class="item" v-for="i in 6" :key="i + Math.random()">
              <skeleton rounded full-width :height="44"/>
              <div class="grid grid-cols-6">
                <skeleton type="circle" :width="12" no-margin/>
                <div class="px-2 flex col-span-4 flex-col justify-center">
                  <skeleton :width="24" no-margin/>
                </div>
                <div class="flex justify-center items-center">
                  <skeleton rounded :width="8" :height="8" no-margin/>
                </div>
              </div>
              <skeleton full-width :height="20"/>
              <div class="grid grid-cols-6 gap-3">
                  <skeleton rounded full-width v-for="i in 6" :key="i + Math.random()"/>
              </div>
            </div>
          </div>
        </div>

        <!-- grouped creators grid -->
        <template v-if="view === 'grid-grouped' && !loadingPosts">
          <div class="grid-grouped-tab">

            <!-- id is being used for pdf generation -->
            <div :id="`group-item-${ip_id}`" class="group-item" v-for="(influencer, ip_id) in influencers_list_items" :key="ip_id">

              <div class="general-info">

                <div class="user-info">
                  <img class="profile-picture" :src="prepareImage(influencer.profile_picture_url)"/>
                  <div class="name">
                    <base-button :link="influencer.uri" type="label" justify="start">
                      <p class="font-bold text-base capitalize">
                        {{ parseUnicodeCharacters(influencer.full_name ? influencer.full_name : influencer.username) }}
                      </p>
                    </base-button>
                  </div>
                </div>
                
                <div class="posts-by-user">
                  <base-icon :size="7" class="text-green-m-main" name="post-image"/>
                  {{ postsByUser(influencer).length > 4 ? '4+' : postsByUser(influencer).length }}
                </div>

                <div class="actions" v-if="!printView">
                  <button 
                    class="focus:outline-none rounded-full bg-tan-m hover:bg-green-m-main text-sm font-bold py-4 w-4/5" 
                    @click="openInfluencer(influencer)"
                  >
                    {{ influencer.open ? 'Hide' : 'Show' }} Content
                  </button>
                </div>

              </div>
              
              <!-- no masonry, currently not showing this -->
              <div 
                class="posts-no-masonry" 
                :class="postsByUser(influencer).length ? 'has-length' : ''" 
                v-if="(influencer.open || printView) && !showMasonry"
              >
                <component
                  :is="tilesView"
                  :allow-edit="allowConfig"
                  :post="post"
                  v-for="post in postsByUser(influencer)"
                  :key="`post_${post.id}_${loadingPosts}`"
                  :deliverables="deliverables"
                  :hide-stats="reportData.settings.hide_stats"
                  @link="linkPost(post)"
                  @edit-post="(p) => $emit('edit-post', p)"
                />
                <div class="empty-state" v-if="!postsByUser(influencer).length && !loadingPosts">There are no posts for this creator yet.</div>
              </div>
              
              <!-- masonry view -->
              <div 
                class="posts-masonry" 
                :class="postsByUser(influencer).length ? 'has-length' : ''" 
                v-if="(influencer.open || printView) && showMasonry"
              >
                <div 
                  v-if="postsByUser(influencer).length" 
                  :key="masonryItemsKey" 
                  v-masonry 
                  item-selector=".post-card" 
                  fit-width="true" 
                  transition-duration="0.0s" 
                  gutter="20" 
                  horizontal-order="true"
                >
                  <component
                    :is="tilesView"
                    v-masonry-tile
                    class="post-card mb-4"
                    :post="post"
                    v-for="post in postsByUser(influencer)"
                    :key="`post_${post.id}_${loadingPosts}`"
                    :printView="printView"
                    :allow-edit="allowConfig"
                    :deliverables="deliverables"
                    :hide-stats="reportData.settings.hide_stats"
                    @link="linkPost(post)"
                    @redraw="redrawPosts()"
                    @edit-post="(p) => $emit('edit-post', p)"
                  />
                </div>
                <div class="empty-state" v-if="!postsByUser(influencer).length && !loadingPosts">There are no posts for this creator yet.</div>
              </div>
            </div>
            <div class="empty-state" v-if="!influencers_list_items.length">
              {{ search === '' ? 'There are no creators yet' : 'No creators match this search' }}
            </div>
          </div>
        </template>
        
        <!-- default grid view -->
        <template v-if="view === 'grid' && !loadingPosts">
          <div class="grid-tab-no-masonry" :class="sortedSearchPosts.length ? 'has-length' : ''" v-if="isGrid && !showMasonry">
            <!-- <report-social-post-card/> or <report-post-card/> -->
            <component
              :is="tilesView"
              :post="post"
              :allow-edit="allowConfig"
              v-for="(post, index) in sortedSearchPosts"
              :index="index"
              :key="`post_${post.id}_${loadingPosts}`"
              :printView="printView"
              :deliverables="deliverables"
              :hide-stats="reportData.settings.hide_stats"
              :show-boosted-data="reportData.settings.show_boosted_data"
              @link="linkPost(post)"
              @edit-post="(p) => $emit('edit-post', p)"
            />
            <div class="empty-state" v-if="!sortedSearchPosts.length && !loadingPosts">There are no posts in this report yet.</div>
          </div>
          <div class="grid-tab-masonry" :class="sortedSearchPosts.length ? 'has-length' : ''" v-if="isGrid && showMasonry">
            <div v-if="sortedSearchPosts.length" :key="masonryItemsKey" v-masonry item-selector=".post-card" fit-width="true" transition-duration="0.0s" gutter="20" horizontal-order="true">
              
              <!-- <report-social-post-card/> or <report-post-card/> -->
              <component
                :is="tilesView"
                v-masonry-tile
                class="post-card mb-4"
                :post="post"
                is-report
                :allow-edit="allowConfig"
                :deliverables="deliverables"
                @link="linkPost(post)"
                v-for="(post, index) in sortedSearchPosts"
                :index="index"
                :hide-stats="reportData.settings.hide_stats"
                :show-boosted-data="reportData.settings.show_boosted_data"
                :key="`post_${post.id}_${loadingPosts}`"
                :printView="printView"
                @redraw="redrawPosts()"
                @edit-post="(p) => $emit('edit-post', p)"
              />
            </div>
            <div class="empty-state" v-if="!sortedSearchPosts.length && !loadingPosts">There are no posts in this report yet.</div>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>
<script>
import ReportTop from '../ReportView/ReportTop';
import ReportGroups from '../ReportCreate/ReportGroups';
import ReportSocialPostCard from '../../components/ReportSocialPostCard';
import ReportPostCard from '../../components/ReportPostCard';
import ReportPostList from '../../components/ReportPostList';
import StatCard from '../../components/StatCard';
import StoryViewer from './StoryViewer';
import { prepareAsB64 } from "../../lib/strings";
import { parseUnicodeCharacters } from '../../lib/strings';
import { isNumber } from '../../common';
import { getBoostedData } from '../../lib/fbBoostedData';
export default {
    components: {
        ReportGroups,
        ReportTop,
        ReportSocialPostCard,
        ReportPostCard,
        ReportPostList,
        StatCard,
        StoryViewer
    },

    props: ['reportData', 'mediaValues', 'user', 'printView', 'loadingPosts', 'deliverables'],

    data() {
        return {
            report: {
              posts: [],
              settings: {
                groups: []
              }
            },
            view: 'grid',
            sort: {
              column: 'date',
              asc: false
            },
            platform_selected: 'all',
            refreshing: false,
            refreshingPercentage: 0,
            showMediaValues: false,
            showMasonry: true,
            currentStories: [],
            influencers_list_items: [],
            storiesPosition: 0,
            storiesViewer: false,
            hideStories: true,
            masonryItemsKey: false,
            loadedReport: false,
            currentInfluencers: [],
            search: '',
            uniqueReach: 0, // the cumulative sum of the creators followers, but not by each of the posts
            reachTypeDropdownVisible: false,
            showUniqueReach: false,
            
            profiles: 0,
            parseUnicodeCharacters
        }
    },

    watch: {
      view: function (val) {
        if (val === 'grid' || val === 'grid-grouped') {
          this.sort = { column: 'date', asc: false };
        } else {
          this.sort = { column: 'likes', asc: false };
        }
        // emit to parent component, needed for pdf generation
        this.$emit('change-view', val); 
      },
      platform_selected: function () {
        if (this.view === 'grid' && this.showMasonry) {
          this.masonryItemsKey = !this.masonryItemsKey;
          this.redrawPosts();
        }
      },
      sort: {
        deep: true,
        handler: function () {
          if (this.view === 'grid' && this.showMasonry) {
            this.masonryItemsKey = !this.masonryItemsKey;
            this.redrawPosts();
          }
        }
      },
      loadingPosts: function (val) {
        if (!val) {
          this.$forceUpdate();
        }
      },
      printView: function (val) {
        if(this.view == 'grid-grouped'){
          // on printView we will open all groups
          if(val == true){
            this.influencers_list_items = this.influencers_list_items.map(influencer => {
              influencer.open = true;
              return influencer;
            });
          }
          // when its done, close all groups
          if(val == false){
            this.influencers_list_items = this.influencers_list_items.map(influencer => {
              influencer.open = false;
              return influencer;
            });
          }
        }
      }
    },

    computed: {
      tilesView() {
        return this.reportData.settings.platforms && !this.printView ? 'report-social-post-card' : 'report-post-card';
      },

      computedColumns() {
        if (this.view === 'list') {
          let number = 5;
          if (this.printView) {
            number--;
          }
          return number + this.sortingOptions.length;
        }
        return 8;
      },

      allowConfig() {
        return this.user ? (this.isAdmin || this.isCollaborator ) && this.isInCompany : false;
      },

      isCollaborator() {
          return this.user ? ( this.user.roles.includes('admin') || this.user.roles.includes('superAdmin') || this.user.roles.includes('collaborator') || this.user.roles.includes('projectManager')) : false;
      },

      isAdmin() {
        return this.user ? (this.user.roles.includes('admin') || this.user.roles.includes('superAdmin')) : false;
      },

      isInCompany() {
        return this.reportData ? this.user.companies.includes(this.reportData.company_id) : false;
      },
      
      // if we add more sorting options check that the " md:grid-cols-'n' " class exists in on the content header
      // and also on the ReportPostList component
      sortingOptions () {
        let options = ['date'];
        if (this.view === 'list') {
          if (this.engagements) {
            options.push('likes');
          }
          if (this.computedComments) {
            options.push('comments');
          }
          if (this.computedShares) {
            options.push('shares');
          }
          if (this.computedSaves) {
            options.push('saves');
          }
          if (this.computedViews) {
            options.push('views');
          }
        }
        return options;
      },

      isList() {
        return this.view === 'list';
      },

      isGrid() {
        return this.view === 'grid';
      },

      isGrouped() {
        return this.view === 'grid-grouped';
      },

      platforms() {
        let items = [];
        let map = new Map();
        for (let post of this.report.posts) {
          if (!map.has(post.network.id)) {
            map.set(post.network.id, true);
            items.push(post.network);
          }
        }
        return items;
      },

      uniqueInfluencers() {
        let items = [];
        let map = new Map();
        for (let post of this.report.posts) {
          let profile = post.profile_item;
          let socialId = profile.social_id;
          if (!map.has(socialId)) {
            map.set(socialId, true);
            profile.network_id = post.network_id;
            items.push(profile);
          }
        }

        return items;
      },

      influencers() {
        let items = this.uniqueInfluencers;

        items.map(u => {
          u.accounts = items.filter(el => {
            return (this.compareUser(el, u)) && el.social_id !== u.social_id;
          }).map(el => {
            return el.social_id;
          });
          return u;
        });

        return items.map(u => {
          let accounts = u.accounts;
          let has_stories = false;
          accounts.forEach(a => {
            if (this.influencersWithStories.includes(a)) {
              has_stories = true;
            }
          });
          u.has_stories = this.hideStories && this.influencersWithStories.includes(u.social_id);
          return u;
        });
      },

      influencersWithStories() {
        let items = [];
        let map = new Map();
        for (let post of this.report.posts) {
          let profile = post.profile_item;
          let socialId = profile.social_id;
          if (!map.has(socialId) && post.type.name === 'story') {
            map.set(socialId, true);
            items.push(socialId);
          }
        }
        return items;
      },

      includedPosts() {
        return this.report.posts.filter(post => {
          return this.itemInFilter(post);
        });
      },

      filteredPosts() {
        return this.includedPosts.filter(post => {
          let isStory = post.type.name === 'story';
          return !isStory || !this.hideStories;
        });
      },

      // used to show the toggle for "unique reach" or "total reach"
      // it only shows if the report has repeated creators
      hasRepeatedCreators() {
        let creators = [];
        for (const post of this.sortedSearchPosts) {
          if(post.profile_item && post.profile_item.username){
            creators.push(post.profile_item.username);
          }
        }
        return (new Set(creators)).size !== creators.length;
      },

      reach(){
        let sum = 0;
        for (const post of this.sortedSearchPosts) {
          if(post.profile_item && post.profile_item.total_followers){
            sum = sum + parseInt(post.profile_item.total_followers) 
          }
        }
        return sum
      },

      sortedSearchPosts() {
        return this.sortedPosts.filter(post => {
          if (this.search !== '') {
            let name = post.profile_item.full_name ? post.profile_item.full_name : post.profile_item.username;
            return name.toLowerCase().includes(this.search.toLowerCase());
          }
          return true;
        });
      },

      sortedPosts() {
        let posts = this.filteredPosts;
        posts = posts.sort((a, b) => {
          let column = this.sort.column;
          if (column === 'date') {
            a[column] = new Date(a[column]);
            b[column] = new Date(b[column]);
            // * when dates are null, the year 1969 is returned, in those cases we use created_at, or return null
            // if( a[column].getYear() == 69){
            //   a[column] = new Date(a.created_at) 
            // }
            // if( b[column].getYear() == 69){
            //   b[column] = new Date(b.created_at) 
            // }
          }

          if (this.sort.asc) {
            return a[column] - b[column];
          }
          return b[column] - a[column];
        });
        return posts;
      },

      engagements() {
        return this.getPostsTotals('likes');
      },

      computedMediaEngagements() {
        return this.getPostsMediaTotals('likes');
      },

      computedComments() {
        return this.getPostsTotals('comments');
      },

      computedMediaComments() {
        return this.getPostsMediaTotals('comments');
      },

      computedShares() {
        return this.getPostsTotals('shares');
      },

      computedSaves() {
        return this.getPostsTotals('saves');
      },

      computedMediaShares() {
        return this.getPostsMediaTotals('shares');
      },

      computedMediaSaves() {
        return this.getPostsMediaTotals('saves');
      },

      computedViews() {
        return this.getPostsTotals('views');
      },

      computedMediaViews() {
        return this.getPostsMediaTotals('views');
      },

      engagementsLeftPercent(){
        return 100 - this.engagementsPercent;
      },

      mediaValue() {
        return this.computedMediaComments + this.computedMediaEngagements + this.computedShares + this.computedSaves + this.computedViews;
      },

      averageEngagements() {
        return parseFloat(this.totalEngagements / this.reach).toFixed(2)
      },

      totalEngagements() {
        return this.computedComments + this.computedShares + this.computedSaves + this.engagements + this.computedViews;
      },

      engagementsPercent() {
        if (this.reach !== 0) {
          return parseFloat(this.totalEngagements * 100 / this.reach).toFixed(2);
        }
        return 0;
      },
    },

    mounted() {
        this.$parent.isLoggedIn = false;
        this.setData();
    },
    
    methods: {
        linkPost(post) {
          this.$emit('link', post);
        },
        prepareImage(url) {
            return prepareAsB64(url);
        },

        async saveGroups(groups) {
            if (this.allowConfig) {
                this.report.settings.groups = groups;

                let { name, description, id, settings } = this.report;
                let payload = { name, description, id, settings };

                await this.$http.post(`/api/reports/${this.report.id}/update`, payload);

                this.$notify({ title: 'Success', text: `Report was updated successfully`, type: 'success' });
            }
        },

        influencerProfile(influencer) {
          let { profile_picture_url, full_name, username, social_id } = influencer;
          return {
            id: social_id,
            name: full_name,
            avatar: profile_picture_url,
            handle: username
          }
        },

        openInfluencer(influencer) {
          let item = this.influencers_list_items.find(i => i.social_id === influencer.social_id);
          if (item) {
            item.open = !item.open;
            this.$forceUpdate();

            this.masonryItemsKey = !this.masonryItemsKey;
          }
        },

        postsByUser(influencer) {
          return this.sortedPosts.filter(post => {
            return post.profile_item.social_id === influencer.social_id;
          });
        },

        compareUser(el, u) {
          let u_full_name = u.full_name.toLowerCase();
          let u_username = u.username.toLowerCase();
          let el_full_name = el.full_name.toLowerCase();
          let el_username = el.username.toLowerCase();
          let sameName = el_full_name === u_full_name;
          let sameUsername = el_username === u_username;
          let notfn_empty = u_full_name !== '' && el_full_name !== '';
          let notun_empty = u_username !== '' && el_username !== '';
          let includesName = (el_full_name.includes(u_full_name) || u_full_name.includes(el_full_name)) && notfn_empty;
          let includesUsername = (el_username.includes(u_username) || u_username.includes(el_username)) && notun_empty;
          let includesInverted = (el_full_name.includes(u_username) || u_username.includes(el_full_name)) && notfn_empty && notun_empty;
          return sameName || sameUsername || includesName || includesUsername || includesInverted;
        },

        closeStories() {
          this.storiesViewer = false;
          this.currentStories = [];
        },

        // unique reach, profiles, influencers_list_items
        setParent(name, value) {
          if (this[name] !== undefined) {
            if(name == 'reach'){
              this.uniqueReach = value
            }else{
              this[name] = value;
            }
          }
        },

        showStories(profile) {
          if (this.hideStories) {
            this.storiesPosition = 0;
            if (profile.has_stories) {
              let accounts = [];
              if (profile.social_id !== undefined) {
                  accounts.push(profile.social_id);
              } else if (profile.users !== undefined && profile.users.length > 0) {
                  accounts = profile.users.map(u => u.social_id);
              }

              this.currentStories = this.report.posts.filter(post => {
                let hasStories = false;
                accounts.forEach(social_id => {
                  if (post.profile_item.social_id === social_id) {
                    hasStories = true;
                  }
                });
                return post.type.name === 'story' && hasStories;
              });

              this.storiesViewer = this.currentStories.length > 0;
            }
          }
        },

        exportButtonHandler(option) {
          switch (option.value) {
            case 'pdf':
              this.$emit('download-pdf');
              break;
            case 'csv':
              this.$emit('download-csv', 'posts');
              break;
          }
        },

        //
        getPostsTotals (property) {
          let total = 0;
          for (let post of this.filteredPosts) {
            let val = post[property];

            // Aggregate the boosted number if found, otherwise it returns 0
            val += getBoostedData(post.boosted_data, property);

            if(isNumber(val)){
              total += val;
            }

          }

          return total;
        },

        // [deprecated] this function is similar to getPostsTotals, but it will
        // get the values from the "mediaValues" if those are provided
        // showMediaValues var is always false so we are not using this
        getPostsMediaTotals (property) {
          let total = 0;
          for (let post of this.filteredPosts) {
            let val = this.getMediaValuesProperty(post, property);
            total = total + (post[property] * val);
          }
          return total;
        },

        // [deprecated] get values from the "mediaValues"
        // showMediaValues var is always false so we are not using this
        getMediaValuesProperty(post, property) {
          let postNetwork = post.network ? post.network.name : null;
          let values = this.mediaValues.find(value => value.type === postNetwork);
          if (values) {
            return values.values[property] ? values.values[property] : 0;
          }
          return 0;
        },

        redrawPosts(){
          setTimeout(() => {
            this.$redrawVueMasonry();
          }, 1250);
        },

        // [deprecated] this method does not get called
        // engagementsByInfluencer(influencer) {
        //   let total = 0;
        //   for (let post of this.filteredPosts) {
        //     if (post.profile_item.social_id === influencer) {
        //       total = total + post.likes;
        //     }
        //   }
        //   return total;
        // },
        itemInFilter(item) {
          if (this.platform_selected !== 'all') {
            return item.network_id === this.platform_selected;
          }
          return true;
        },

        setSort(col) {
          if (col === this.sort.column) {
            this.sort.asc = !this.sort.asc;
          } else {
            this.sort.asc = false;
            this.sort.column = col;
          }
        },

        refresh() {
          this.refreshing = true;
          if (this.isAdmin) {
            this.$notify({
              title: 'In order to get all of the posts stats, the creator(s) have to log in with us.',
              text: 'If we don\'t have their accounts connected, we are unable to get the number of views or likes on posts.',
              type: 'info',
              duration: 6200
            });
          }

          let urls = this.report.posts.map(p => {
            return `/api/reports/posts/${this.$route.params.id}/refresh/${p.id}`;
          });

          let total = urls.length;
          let completed = 0;

          this.refreshingPercentage = 0;

          const requests = urls.map(url => this.$http.get(url).then(res => {
            completed++;
            if (total > 0) {
              let percentage = Math.ceil(completed * 100 / total);
              this.refreshingPercentage = percentage <= 100 ? percentage : 100;
            }
          }));

          Promise.allSettled(requests).then(responses => {
            this.$notify({ title: 'Success', text: `Report was updated successfully`, type: 'success' });
          }).catch(error => {
            this.$notify({ title: 'Error while refreshing', text: `Some posts couldn't be refreshed. Try again later.`, type: 'warn' });
          }).finally(() => {
            this.getData();
            this.refreshing = false;
          });
        },

        async getData() {
            const { data } = await this.$http.get(`/api/reports/posts/${this.$route.params.id}`);
            this.report = data;
        },

        async setData() {
          this.report = this.reportData;

          let items = [];
          let map = new Map();
          for (let post of this.reportData.posts) {
            let profile = post.profile_item;
            let socialId = profile.social_id;
            if (!map.has(socialId)) {
              map.set(socialId, true);
              profile.network_id = post.network_id;
              items.push(profile);
            }
          }
          this.loadedReport = true;

          this.currentInfluencers = items.map(u => {
            u.accounts = items.filter(el => {
              return (this.compareUser(el, u)) && el.social_id !== u.social_id;
            }).map(el => {
              return el.social_id;
            });
            u.open = false;
            return u;
          });
        },

        customLabelRenderer(tooltipItem, data){
          let label = data['labels'][tooltipItem.index]
          let percent = data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem.index] + '%'
          let space = ' '

          return (percent) ? space + label + ' ' + percent : null
        },

        checkCols(cols) {
          return this.computedColumns === cols;
        }
    }
}
</script>

<style lang="scss">
  // global
  .img-story:before {
    @apply bg-black opacity-80 w-screen h-screen flex fixed inset-0 z-50;
    content: " ";
  }
  .profile-user-story {
    background: linear-gradient(to right, red, purple);
  }
</style>

<style lang="scss" scoped>
  .posts-report-wrapper{
    @apply mx-auto pt-6 mx-auto w-11/12;
    // md
    @media (min-width: 768px) {
      @apply pt-10 w-4/5;
    }
    > .posts-report-container{
      @apply mx-auto text-center;
      // md
      @media (min-width: 768px) {
        @apply text-left;
      }
      > .stats-groups{
        @apply mt-10;
        > .stats-groups-container{
          @apply flex flex-col gap-6;
          &.not-print-view{
            // lg
            @media (min-width: 1024px) {
              @apply flex-row;
            }
          }

          > .stats{
            @apply flex flex-col gap-6 p-6 w-full rounded-2xl justify-between bg-white;
            // lg
            @media (min-width: 1024px) {
              @apply w-3/4;
            }
            > .header{
              @apply w-full flex flex-col items-center justify-between;
              @media (min-width: 768px) {
                @apply flex-row;
              }
              > .title{
                @apply text-xl font-semibold text-black;
              }
              > .platform-select{
                @apply flex gap-2 justify-center flex-wrap;
                // "all" item has different styles
                .all-item{
                  @apply capitalize border px-3 py-1 cursor-pointer rounded-full text-center font-bold;
                  &.selected{
                    @apply bg-black text-green-m-main border-black;
                    &:hover{
                      @apply bg-gray-m-dark;
                    }
                  }
                  &.not-selected{
                    @apply border-purple-m-main-light border-opacity-20 text-purple-m-main-light;
                    &:hover{
                      @apply bg-black bg-opacity-40 text-white border-none;
                    }
                  }
                }
                .item{
                  @apply capitalize border px-2 py-1 cursor-pointer rounded-full text-center font-bold;
                  &.selected{
                    @apply bg-black text-green-m-main border-black;
                    &:hover{
                      @apply bg-gray-m-dark;
                    }
                  }
                  &.not-selected{
                    @apply border-purple-m-main-light border-opacity-20 text-purple-m-main-light;
                    &:hover{
                      @apply bg-black bg-opacity-40 text-white border-none;
                    }
                  }
                }
              }
            }
            > .main-stats{
              @apply grid gap-4 grid-cols-2;
              // xl
              @media (min-width: 1280px) {
                @apply grid-cols-4;
              }
              .reach-type-dropdown-wrapper{
                @apply relative;
                > .dropdown{
                  @apply absolute top-6 right-0 w-36 bg-white border border-gray-300 rounded-lg overflow-hidden;
                  > .item{
                    @apply p-2 cursor-pointer transition-colors duration-200;
                    &.active{
                      @apply bg-gray-200;
                    }
                    &:hover{
                      @apply opacity-80;
                    }
                  }
                }
              }
            }
            > .secondary-stats{
              @apply grid gap-4 grid-cols-6;
              > .container{
                @apply col-span-6 grid;
                &.show-media-values{
                  @apply grid-cols-2;
                  // md
                  @media (min-width: 768px) {
                    @apply col-span-3;
                  }
                }
                &.dont-show-media-values{
                  @apply grid-cols-2;
                  // xl
                  @media (min-width: 1280px) {
                    @apply grid-cols-4;
                  }
                }
              }
            }

          }
          > .groups{
            @apply flex flex-col gap-6 p-6 w-full rounded-2xl bg-white;
            // lg
            @media (min-width: 1024px) {
              @apply w-1/4;
            }
            > .header{
              @apply w-full flex items-center justify-between;
              > .title{ 
                @apply text-xl font-semibold text-black;
              }
            }
            > .content{
              @apply max-h-72 overflow-y-auto;
            }
          }
        }
      }
      > .posts-wrapper{
        @apply mt-8 mb-12;
        > .header{
          @apply flex justify-between text-gray-m-disable text-center items-center;
          // md
          @media (min-width: 768px) {
            @apply grid;
          }
          > .title{
            @apply text-xl font-semibold text-black text-left;
            &.is-grid-or-grouped{
              @apply col-span-3;
            }
          }
          > .list-search{
            @apply col-span-2 h-full flex items-center;
            > input{
              @apply w-11/12 px-3 mx-auto h-13;
            }
          }
          > .sort{
            @apply flex gap-1 items-center cursor-pointer uppercase hidden justify-center;
            // md
            @media (min-width: 768px) {
              @apply inline-flex;
            }
          }
          > .grid-or-grouped-search{
            @apply col-span-3 h-full flex items-center;
            > input{
              @apply w-3/4 px-3 mx-auto h-13;
            }
          }
          > .tab-select{
            @apply flex flex-row-reverse;
            &.is-list{
              @apply col-span-2;
            }
          }
        }
        > .list-tab{
          @apply w-full flex flex-col gap-3;
          &.has-length{
            @apply pt-10;
          }
          > .empty-state{
            @apply my-6 text-center;
          }
        }
        > .skeleton-loader{
          @apply w-full my-6;
          > .wrapper{
            @apply grid grid-cols-3 gap-x-6 gap-y-12;
            > .item{
              @apply flex flex-col gap-2;
            }
          }
        }
        > .grid-grouped-tab{
          @apply w-full flex flex-col gap-4 mt-6;

          > .group-item{
            @apply flex flex-col gap-5;

            > .general-info{
              @apply bg-white rounded-2xl grid grid-cols-6 text-center py-3 items-center;
              > .user-info{
                @apply text-left ml-4 mb-4 w-full col-span-4 flex;
                // md
                @media (min-width: 768px) {
                  @apply mb-0;
                }
                > .profile-picture{
                  @apply rounded-full w-12 h-12 rounded-full;
                }
                > .name{
                  @apply px-2 flex flex-col justify-center;
                }
              }
              > .posts-by-user{
                @apply flex justify-center gap-2 items-center font-bold;
              }
              > .actions{
                @apply flex justify-center items-center;
              }
            }

            > .posts-no-masonry{
              @apply w-full grid grid-cols-1 gap-5;
              // sm
              @media (min-width: 640px) {
                @apply grid-cols-1;
              }
              // md
              @media (min-width: 768px) {
                @apply grid-cols-6;
              }
              &.has-length{
                @apply py-10;
              }
              > .empty-state{
                @apply my-6 text-center;
              }
            }

            > .posts-masonry{
              @apply flex w-full justify-center;
              &.has-length{
                @apply pt-10;
              }
              > .empty-state{
                @apply my-6 text-center;
              }
            }
          }
          > .empty-state{
            @apply text-center pt-4;
          }
        }
        > .grid-tab-no-masonry{
          @apply w-full grid grid-cols-1 gap-5;
          // sm
          @media (min-width: 640px) {
            @apply grid-cols-1;
          }
          // md
          @media (min-width: 768px) {
            @apply grid-cols-6;
          }
          &.has-length{
            @apply py-10;
          }
          > .empty-state{
            @apply my-6 text-center;
          }
        }
        > .grid-tab-masonry{
          @apply flex w-full justify-center;
          &.has-length{
            @apply pt-10;
          }
          > .empty-state{
            @apply my-6 text-center;
          }
        }

      }
    }
  }
</style>


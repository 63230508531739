<template>
    <div class="gender">
        <gender-select
            :value="val"
            @input="onInput"
        />
        <div class="actions">
            <base-button label="Next" size="xl" class="font-bold" @action="$emit('next')" />
            <base-button label="Back" type="label" @action="$emit('back')" />
        </div>
    </div>
</template>
<script>
import GenderSelect from '../GenderSelect.vue';
export default {
    props: ['value'],
    components: { 
        GenderSelect 
    },
    data(){
        return {
            val: this.value
        }
    },
    methods: {
        onInput(val){
            this.val = val
            this.$emit('input', this.val)
        }
    }
}
</script>
<style lang="scss" scoped>
    .gender{
        @apply h-full flex flex-col justify-start items-center;
        .actions{
            @apply mt-10 flex flex-col items-center gap-y-6;
        }
    }
</style>

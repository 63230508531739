<template>
<div :class="['relative block border-2 border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out relative', { 'has-upload-error bg-red-100': file.error, 'hidden' : !visible }]">
    <div @click.prevent="openFileIntent" class="cursor-pointer p-4 w-full flex justify-between items-start gap-x-4">
      
        <!-- icon -->
        <div class="flex items-center justify-center flex-grow-0 flex-shrink-0 rounded-full bg-white w-10 h-10 border border-gray-m-light">
            <base-icon class="text-purple-m-main" :name="fileIcon" :size="5" />
        </div>
    
        <!-- content -->
        <div class="flex flex-col flex-grow flex-shrink">
            <div class="flex flex-row justify-between">
                <!-- file name -->
                <div class="flex-grow flex-shrink w-80">
                    <div class="truncate font-semibold text-lg text-black" >
                        {{ file.name }}
                    </div>
                    <div class="text-ps text-gray-m-disable font-normal">
                        {{ computedFileSize }} {{ file.permissions === 3 ? 'Private' : '' }}
                    </div>
                    <div v-if="file.error">
                        ERROR UPLOADING
                    </div>
                </div>                        
                <div class="flex-grow-0 flex-shrink-0" @click="(e)=>e.stopPropagation()">
                    <options-dropdown
                        v-if="isProjectOwner || isAdminOrHigher"
                        :options="[
                            ...(this.isSuperAdmin ? [{
                                label: 'Permissions',
                                icon: 'locked',
                                event: 'permissions',
                            }] : []),
                            ...([{
                                label: 'Edit',
                                icon: 'pencil-alt',
                                event:'edit'
                            }]),
                            ...(this.isAdminOrHigher ? [{
                                label: 'Delete',
                                icon: 'trash',
                                event: 'del',
                                divider: true
                            }] : [])
                        ]"
                        @permissions="$emit('share', { type: 'file', id: file.id })"
                        @edit="$emit('edit-file', file)"
                        @del="
                            $swal.fire({
                                title: `Are you sure you want to delete ${file.name} ?`,
                                text: `You won't be able to revert this!`,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Delete',
                                buttonsStyling: false,
                                reverseButtons: true,
                                focusCancel: true
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    deleteFile()
                                }
                            });
                        "
                    />
                </div>
            </div>

            <div v-if="file.uploading" class="progress-container">
                <div class="progress-bar" :style="`width: ${file.uploadProgress}%`"/>
            </div>

        </div>
       
    </div>

    <!-- private -->
    <div v-if="file.permissions == 3" class="absolute inset-0 flex justify-center items-center pointer-events-none">
        <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
        </svg>
    </div>
 
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Dropdown from './Dropdown';
import formatBytes from '../lib/files';
export default {
    props: ['file', 'projectId', 'userId', 'isProjectOwner'],

    components: {
        Dropdown
    },

    data(){
        return {
            visible: true
        }
    },

    computed: {
        ...mapGetters(['isSuperAdmin', 'isAdminOrHigher']),

        fileIcon() {
            if (this.file.mimetype !== undefined) {
                let mimetype = this.file.mimetype.split('/');
                if (mimetype.length) {
                    switch (mimetype[0]) {
                        case 'link':
                            return 'link';
                        case 'image':
                            return 'image';
                        case 'audio':
                            return 'audio';
                        case 'application': {
                            switch (mimetype[1]) {
                                case 'pdf':
                                    return 'file';
                            }
                            return 'file';
                        }
                    }
                }
            }
            return 'file';
        },

        computedFileSize(){
            return this.file.size ? formatBytes(this.file.size) : '-'
        }
    },

    methods: {

        async openFileIntent(){
            const hasPermission = await this.checkPermissions()
            if(hasPermission){
                return this.openFile()
            }
        },

        openFile() {
            // if we pass onClick to this component, then the click will be handled externally
            if(this.$listeners.onClick){
                this.$emit('onClick', this.file)                
            }else{
                window.open(`/files/${this.file.guid}`)
            }
        },

        checkPermissions(){

            // public
            if(!this.file.permissions || this.file.permissions === 0 || this.file.permissions === 1){
                return true
            }

            // owners only
            if(this.file.permissions === 2){
                if(this.isProjectOwner || this.isAdminOrHigher){
                    return true
                }else{
                    this.$notify({ title: 'Warning', text: 'File for owners & admins only', type: 'warn' })
                    return false
                }
            }

            // private
            if(this.file.permissions === 3){
                return true
            }

        },

        async deleteFile(){
            try {
                const { data } = await this.$http.delete(`/api/files/${this.file.id}`);
                if (data) {
                    this.visible = false;
                    this.$store.dispatch('restorer/set', {
                        resourceLabel: 'File',
                        route: `/api/files/${this.file.id}/restore`,
                        action: data.show_undo,
                        forceFn: true,
                        fn: (time = 6100) => {
                            setTimeout(() => {
                                this.$emit('refresh-data');
                            }, time);
                        }
                    });
                }
            } catch (err) {
                console.log(err);
                this.visible = true;
                this.$notify({ title: 'Error', text: 'Something went wrong', type: 'error' })
            }
        }

    }
}

</script>
<style lang="scss" scoped>
    .progress-container{
        @apply h-2 w-full mt-2 bg-purple-m-main rounded-full;
    }

    .progress-bar {
        transition: width 0.5s;
        @apply h-2 bg-green-m-main rounded-full;
    }

</style>
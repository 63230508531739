<template>
<div class="option-list">
    <div 
        class="option"
        :class="{ 'disabled': option.disabled }" 
        v-for="(option, index) in options" 
        :key="index" 
        @click="() => !option.disabled ? $emit('select', option.value) : null"
    >
        <div class="flex justify-between items-center">
            <div>
                <div class="text-h5 font-bold">{{ option.label }}</div>
                <div v-if="option.description" class="text-h5 mt-1">{{ option.description }}</div>
            </div>
            <base-icon
                name="arrow-right"
                :size="6"
            />       
        </div>
    </div>
</div>
</template>
<script>
export default {
    props: ['options']
}
</script>
<style lang="scss" scoped>
    .option-list{
        @apply w-full flex flex-col gap-y-4;
        .option{
            @apply relative px-6 py-6 bg-white border border-black rounded-xl cursor-pointer transition-opacity duration-500;
            &:hover{
                @apply opacity-50;
            }
            &.disabled{
                @apply opacity-20;
            }
        }
    }
</style>
<template>
    <base-modal 
        :title="!column ? 'Add Column' : 'Edit Column'"
        :visible="visible"
        @close="$emit('close')"
    >
        <FormulateForm name="form" @submit="submit()">
            
            <div class="flex flex-col gap-y-2">

                <FormulateInput
                    type="text"
                    placeholder="Name"
                    validation="required"
                    label="Column name"
                    v-model="newName"
                />        

                <custom-select v-if="!column"
                    placeholder="Type"
                    :options="dataTypes"
                    label="Data Type"
                    :required="true"                    
                    v-model="dataType"                                        
                />       

            </div>

            <div class="pt-3 sm:flex sm:flex-row-reverse">

                <base-button @action="$formulate.submit('form')" :disabled="loading || !newName || (!dataType && !column)" submit>
                    {{!column ? 'Add' : 'Save'}}
                </base-button>

            </div>

        </FormulateForm>
    </base-modal>
</template>

<script>
export default {
    props: ['visible', 'project', 'projectUser', 'column', 'dataTypes'],
    data() {
        return {            
            newName: '',               
            dataType: null,
            loading: false,
        }
    },
    mounted(){
        this.getData();
    },
    watch: {
        'column': {
            deep: true,
            handler: function (val, old) {

                console.log('val', val);
                if (val && val !== old) {
                    this.newName = val.name;
                    this.dataType = val.column_type_id;
                }
                else if(!val){
                    this.newName = '';
                    this.dataType = null;
                }                            
            }
        }
    },
    methods: {
        async getData(){
        },
        async submit() {

            const parentObj = ( this.projectUser != null ) ? this.projectUser : this.project;
            const parentObjName = ( this.projectUser != null ) ? 'project_user' : 'project';

            this.loading = true
            try{              
                let newColumn = null;
                if(!this.column){
                    newColumn = { 
                        [`${parentObjName}_id`]: parentObj.id,
                        column_type_id: this.dataType.id,
                        name: this.newName,                        
                    };
                    this.$emit('save-new-column', newColumn, parentObj);  

                }
                else {
                    newColumn = { 
                        [`${parentObjName}_id`]: this.column[`${parentObjName}_id`],
                        column_type_id: this.column.column_type_id,
                        name: this.newName,
                        id: this.column.id
                    };

                    this.$emit('edit-column', newColumn);
                }           
                this.newName = '';
                this.$emit('close');                
            }catch(error){
                console.error(error);
            }finally{
                this.loading = false
            }
        }
    }
}
</script>
<template>
    <div class="container text-left" :class="{'report-container': isReport}">
        <div class="content relative">
            <media-viewer :file="files[page]" v-if="files.length" :is-report="isReport" @set-fail="$emit('set-fail')"/>
            
            <div v-if="page > 0" class="navigation-container left">
                <div @click="$emit('navigate', 'prev')" class="button z-50">
                    <base-icon name="chevron-left" />
                </div>
            </div>
            <div v-if="page < files.length-1" class="navigation-container right">
                <div @click="$emit('navigate', 'next')" class="button z-50">
                    <base-icon name="chevron-right" />
                </div>
            </div>
        </div>
        <div class="header p-4">
            <div class="flex justify-between items-center">
                <div class="flex items-center gap-x-3 w-full relative">
                    <avatar size="medium" :user="postedBy" :class="{'w-12': isReport}"/>
                    <div>
                        <div class="flex flex-col">
                            <div class="font-bold">
                                <a :href="postItem.profile_item.uri" target="_blank" v-if="isReport && postItem && postItem.profile_item">
                                    {{postedBy.name}}
                                </a>
                                <span v-else>{{postedBy.name}}</span>
                            </div>
                            <span>{{followers | numeral('0,0.0a')}} followers</span>
                        </div>
                    </div>
                    <div class="absolute right-0 mr-4" v-if="isReport && postItem && postItem.profile_item">
                        <a :href="postItem.profile_item.uri" target="_blank">
                            <base-icon :name="postItem.network.name" style="display: inline-block;"/>
                        </a>
                    </div>
                </div>

                <base-icon :size="7" name="fb-options" v-if="!isReport"/>

            </div>
            <div class="description text-center">{{description}}</div>
        </div>
    </div>
</template>

<script>
import MediaViewer from '../MediaViewer.vue'
export default {
    props: ['files', 'description', 'postedBy', 'postedAt', 'page', 'isReport', 'postItem'],
    components: {
        MediaViewer
    },
    computed: {
        shares() {
            return this.postItem && this.postItem.shares ? this.postItem.shares : 21;
        },
        // saves() {
        //     return this.postItem && this.postItem.saves ? this.postItem.saves : 21;
        // },
        comments() {
            return this.postItem && this.postItem.comments ? this.postItem.comments : 103;
        },
        followers() {
            return this.postItem && this.postItem.profile_item ? this.postItem.profile_item.total_followers : 103;
        },
        likes() {
            return this.postItem && this.postItem.likes ? this.postItem.likes : 1600;
        }
    }
}
</script>

<style lang="scss" scoped>
    .container{
        @apply bg-white rounded-lg;
        width: 450px;
        &.report-container {
            max-width: 100% !important;
        }
    }
    .description{
        @apply my-2;
    }
    .stats{
        @apply flex justify-between items-center my-3;
    }
    .reactions{
        @apply flex items-center;
        & .reaction{
            @apply rounded-full flex justify-center items-center h-5 w-5;
            
            &:not(:first-child){
                margin-left: -2px;
            }    

            &.like{
                background-color: #0573e6
            }
            &.love{
                background-color: #ee3150
            }
        }
    }
    .interaction{
        @apply flex justify-between items-center mx-3 my-2;
        & .button{
            @apply flex items-center gap-x-2;
            & span{
                @apply text-h6 font-bold;
            }
        }
    }
    .separator{
        @apply w-full bg-gray-400;
        height: 1px;
    }
    .navigation-container{
        position: absolute;
        bottom: 0px;
        top: 0px;
        display: flex;
        align-items: center;            

        &.left{
            left: -40px;
        }

        &.right{
            right: -40px;
        }

        & .button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #bbbbbd;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            cursor: pointer;
            box-shadow: #a3a3a3 -1px 0px 13px 0px;
        }
    }
</style>
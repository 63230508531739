var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reports-tab"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v(" Reports "),_c('span',[_vm._v("("+_vm._s(_vm.reports.length)+")")])])]),_c('base-table',{staticClass:"px-12 pb-10",attrs:{"columns":[
            {
                name: 'name',
                label: 'Report name'
            },
            {
                name: 'description',
                label: 'Description',
                type: 'html'
            },
            {
                name: 'type',
                label: 'Report type',
                type: 'report-type'
            },
            {
                name: 'is-default',
                label: 'Default',
                value: function (element) {
                    return element.is_default ? 'Default report' : '-'
                }
            },
            {
                name: 'created',
                label: 'Created at',
                type: 'date',
                value: function (element) {
                    return element.created_at
                }
            }
        ],"data":_vm.reports,"onRowClick":_vm.onRowClick,"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
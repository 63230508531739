<template>
    <div class="dropdown-wrapper relative">
        <span :class="['rounded-md relative', { 'shadow-sm': !noShadow }]" @click="show = !show">
            <template v-if="$slots.default">
                <slot />
            </template>
            <template v-else>
                <button type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150" aria-haspopup="true" aria-expanded="false">
                    {{ label }}
                    <!-- Heroicon name: chevron-down -->
                    <svg class="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400 absolute right-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </button>
            </template>
        </span>
        <div :class="['dropdown-menu origin-top-right z-100 absolute mt-2 w-56 rounded-md shadow-lg', alignment, {'bottom-14': onTop}]" v-if="show">
            <div class="rounded-md bg-white shadow-xs">
                <template v-if="$slots.options">
                    <slot name="options" />
                </template>
                <template v-else>
                    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="sort-menu">
                        <div
                            class="flex gap-2 cursor-pointer block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                            :class="{ 'border-t border-gray-200': option.divider, 'disabled cursor-not-allowed': option.disabled }"
                            role="menuitem"
                            v-for="(option, i) in options"
                            :key="i"
                            @click.prevent="selectOption(option)"
                            :disabled="option.disabled"
                            v-show="showOption(option)"
                        >
                            <base-icon :name="option.icon" v-if="option.icon !== undefined && icons"/>
                            <div>{{ option.label }}</div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        options: Array,
        align: String,
        noShadow: {
            type: Boolean,
            default: false
        },
        onTop: {
            type: Boolean,
            default: false
        },
        icons: {
            type: Boolean,
            default: false
        }
    },

    components: {
        
    },

    data() {
        return {
            show: false,
        }
    },

    computed: {
        alignment() {
            let out = 'right-0';
            if (this.align && this.align === 'left') {
                out = 'right-100';
            }

            return out;
        }
    },

    mounted() {
        document.addEventListener('click', this.close)
    },

    beforeDestroy() {
        document.removeEventListener('click', this.close)
    },

    methods: {
        selectOption(option) {
            if (option.disabled) {
                return false;
            }
            this.$emit('changed', option);
            this.show = false;
        },

        close(e) {
            if (!this.$el.contains(e.target)) {
                this.show = false;
            }
        },

        showOption(option) {
            if (option && option.hidden) {
                return false;
            }

            return true;
        },
    }
}
</script>
<style lang="scss">
.dropdown-wrapper {
    .dropdown-menu {
        max-height: 14em;
        overflow-y: auto;

        .disabled {
            @apply text-gray-300 bg-gray-50 cursor-not-allowed;
        }
    }
}
</style>
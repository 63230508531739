<template>
    <div class="container">
        <loader label="Preparing Google Drive" visible/>
    </div>
</template>

<script>
import { validString } from '../../lib/strings';
export default {
    data() {
        return {
            data: ''
        }
    },

    beforeMount() {
        this.checkStateAndCode();
        // try to get the google token with the hard_code, 
        // this should happen when the user was redirected 
        // from one origin/subdomain to another one
        this.getTokenFromGoogle();
    },

    methods: {
        // First step
        async checkStateAndCode() {
            let state = this.$route.query.state;
            let code = this.$route.query.code;
            console.log('(checkStateAndCode) => state:', state);
            console.log('(checkStateAndCode) => code:', code);
            // if state & code exist
            if (validString(state) && validString(code)) {
                // get the url from the state query
                const url = decodeURI(state);
                if(validString(url)){
                    console.log('(checkStateAndCode) => query url:', url);
                    // if its NOT the same origin/subdomain as the current session, redirect the user to it, but with the hard_code
                    if (!url.includes(window.location.origin)) {
                        let queryCharacter = url.includes('?') ? '&' : '?';
                        let redirect = `${url}${queryCharacter}hard_code=${code}`;
                        // [!] Redirecting
                        this.$swal.fire({
                            title: 'Redirecting to',
                            text: redirect,
                            icon: 'success',
                            confirmButtonText: 'Continue',
                            customClass: {
                                actions: 'flex justify-center',
                                confirmButton: 'mx-auto',
                                cancelButton: 'hidden' 
                            } 
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = redirect;     
                            }else{
                                window.close();
                            }
                        });                        
                    } else {
                        console.log('(checkStateAndCode) => is on the same origin/domain as current session');
                        // if its the same origin, get the token from google
                        this.getTokenFromGoogle(code);
                    }
                }
            }
        },

        // Second step
        async getTokenFromGoogle(code = null) {
            // if a hard_code exists, overwrite the code
            let hard_code = this.$route.query.hard_code;
            if (validString(hard_code)) {
                console.log('(getTokenFromGoogle) => overwriting code with hard_code')
                code = hard_code;
            }
            if (validString(code)) {
                this.loading = true
                console.log('(getTokenFromGoogle) => final code', code)
                try {
                    const { data } = await this.$http.post(`/api/drive/auth/token`, { code });
                    console.log('(getTokenFromGoogle) => response from "/api/drive/auth/token"', data)
                    if (data) {
                        this.handleGoogleToken(data);
                    } else {
                        this.onTokenError();
                    }
                } catch (e) {
                    this.onTokenError(e);
                }
            }
        },
        
        // Third step
        async handleGoogleToken(response) {
            this.$emit('loading', true);
            this.loading = true;
            try {
                let accessToken = response.credentials;
                if (accessToken) {
                    let token = JSON.parse(JSON.stringify(accessToken));
                    let date = new Date();
                    date.setSeconds(date.getSeconds() + 3600);
                    token.expiration = date;
                    await this.$store.dispatch('auth/setDriveAuth', token);
                }
                // [!] Closing window
                this.$swal.fire({
                    title: 'Done',
                    text: 'Close this window',
                    icon: 'success',
                    confirmButtonText: 'Close',
                    customClass: {
                        actions: 'flex justify-center',
                        confirmButton: 'mx-auto',
                        cancelButton: 'hidden' 
                    } 
                }).then(() => {
                    window.close();
                });
            } catch (e) {
                console.log(e);
            } finally {
                this.$emit('loading', false);
                this.loading = false;
            }
        },

        onTokenError(e = null) {
            this.$notify({ title: 'Warning', text: 'There was a problem authenticating with Google. Try again later.', type: 'warn' });
            if (e !== null) {
                this.$emit('error', e);
            }
            this.$emit('loading', false);
            this.loading = false;
            // window.close();
        },
    }
}
</script>

<style lang="scss" scoped>
    .container{
        @apply py-16 px-24;
    }
</style>
<template>
    <base-modal 
        title="Edit Folder"
        :visible="visible"
        @close="$emit('close')"
    >
        <FormulateForm name="form" @submit="submit()">
            
            <div class="flex flex-col gap-y-2" v-if="folder">

                <FormulateInput
                    type="text"
                    placeholder="Folder Name"
                    validation="required"
                    label="Folder name"
                    v-model="name"
                />             

            </div>

            <div class="pt-3 sm:flex sm:flex-row-reverse">

                <base-button 
                    @action="$formulate.submit('form')" 
                    :disabled="loading || name == ''" 
                    submit 
                    label="Submit"
                />

            </div>

        </FormulateForm>
    </base-modal>
</template>

<script>
export default {
    props: ['visible', 'folder'],
    data() {
        return {
            name: '',
            loading: false,
        }
    },
    watch: {
        'folder': {
            deep: true,
            handler: function (val, old) {
                if (val && val !== old) {
                    this.name = val.name;
                }
            }
        }
    },
    methods: {
        async submit() {
            this.loading = true
            try{
                const { data } = await this.$http.put(`/api/folders/${this.folder.id}`, { 
                    name: this.name                                        
                });
                this.$notify({ title: 'Success', text: 'Folder edited successfully', type: 'success' });
                this.$emit('done');  
                this.$emit('close');                
            }catch(error){
                console.log(error)
            }finally{
                this.loading = false
            }
        }
    }
}
</script>
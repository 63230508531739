<template>
    <div class="container">
        <div class="content">
            <div class="header">
                <div class="flex gap-x-4">
                    <div class="font-bold mb-2">{{data.name}}</div>
                    <base-icon v-if="loading" name="loader" />
                </div>
                <div class="flex items-center gap-x-2">
                    <div>{{selected.length}} selected</div>
                        <base-icon 
                            name="minus-circle" 
                            :size="6" 
                            class="text-gray-500 cursor-pointer hover:opacity-50 transition-opacity duration-200" 
                            @action="selected = []"
                            v-tooltip="{ 
                                content: 'Clear Selection', 
                                classes: 'bg-black text-white rounded py-1 px-2 text-xs', 
                                delay: { 
                                    show: 30,
                                    hide: 50 
                                } 
                            }"
                        /> 
                        <base-icon 
                            :name="onlySelected ? 'eye-closed' : 'eye'" 
                            :size="6" 
                            class="text-gray-500 cursor-pointer hover:opacity-50 transition-opacity duration-200"
                            @action="(!selected.length && !onlySelected) ? null : onlySelected = !onlySelected"
                            v-tooltip="{ 
                                content: onlySelected ? 'Hide Selected' : `View Selected ${(!selected.length && !onlySelected) ? `, none selected yet` : ``}`,
                                classes: 'bg-black text-white rounded py-1 px-2 text-xs',
                                placement: 'left',
                                delay: { 
                                    show: 30,
                                    hide: 50 
                                } 
                            }"
                        />
                        <base-icon 
                            :name="showArchivedProjects ? 'archive-box-x' : 'archive-box'" 
                            :size="6" 
                            class="text-gray-500 cursor-pointer hover:opacity-50 transition-opacity duration-200"
                            @action="showArchivedProjects = !showArchivedProjects"
                            v-tooltip="{
                                content: showArchivedProjects ? 'Hide archived projects' : 'Show archived projects',
                                classes: 'bg-black text-white rounded py-1 px-2 text-xs', 
                                placement: 'left',
                                delay: { 
                                    show: 30,
                                    hide: 50 
                                } 
                            }"
                        />
                </div>
            </div>

            <filter-select
                placeholder="Search by name, brand, brief..."
                :loading="loading"
                :options="computedOptions"
                v-model="selected"
                :onlySelected="onlySelected"
                @toggle-only-selected="(option) => onlySelected = option"
                @search="term => searchTerm = term"
            >
                <div slot-scope="{ option }">
                    <div class="flex gap-x-2 items-center">
                        <div class="font-bold">{{option.name}}</div>
                        <div class="text-gray-400 text-pxs">{{option.brand}}</div>
                    </div>
                    <div
                        class="text-pxs" 
                        style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden;" 
                        v-html="option.brief"
                    />
                    <div class="flex items-center text-pxs" v-if="option.archived"> <base-icon :size="4" name="archive-box"/> Archived Project</div>
                </div>   
            </filter-select>

        </div>
        <div class="footer">
            <base-button
                :label="`${filters.projects ? `Update Filter` : `Add Filter`}`"
                icon="filter"
                size="lg"
                @action="addFilter"
            />

            <base-button
                type="secondary"
                label="Cancel"
                @action="$emit('close')"
            />
        </div>
    </div>
</template>

<script>
import FilterSelect from "../FilterSelect.vue"
export default {
    props: ['data', 'filters'],
    components: { 
        FilterSelect 
    },
    data(){
        return {
            options: [],
            selected: [],
            onlySelected: false,
            searchTerm: '',
            showArchivedProjects: false,
            loading: false
        }
    },
    mounted(){
        this.getOptions()
    },
    computed: {
        computedOptions(){
            return this.options.filter(element => this.showArchivedProjects ? true : !element.archived).filter(element => {
                if(!this.searchTerm || this.searchTerm.trim() == '') return true
                const fields = ['name', 'brand', 'brief']
                let passes = false
                for (const field of fields) {
                    const value = element[field]
                    if(value && value.toLowerCase().includes(this.searchTerm.toLowerCase())) passes = true
                }
                return passes;
            })
        }
    },
    methods: {
        async getOptions(){
            this.loading = true
            try {
                // [get-all-projects]
                const { data } = await this.$http.get('/api/projects', {
                    params: {
                        only: true,
                        page: 1,
                        perPage: 10,
                        view: 'creator-list'
                    }
                });
                this.options = data.map(project => {
                    return {
                        value: project.id,
                        name: project.name,
                        brand: project.brand,
                        brief: project.brief,
                        archived: project.deleted_at ? true : false
                    }
                })
                if(this.filters.projects){
                    this.options.push(...this.filters.projects.active, ...this.filters.projects.archived)
                    this.selected.push(...this.filters.projects.active, ...this.filters.projects.archived)
                    if(!this.filters.projects.archived.length) this.showArchivedProjects = true
                    this.$forceUpdate()
                }
            } catch(e) {
                console.log(e)
            } finally{
                this.loading = false
            }
        },
        clear(){
            this.selected = []
        },
        addFilter(){
            this.$emit('addFilter', 'projects', this.selected );
            this.$emit('close');
        }
    }
}
</script>
<style lang="scss" scoped>

    .container{
        @apply relative h-full flex flex-col;
        .content{
            @apply flex-grow flex-shrink;
            .header{
                @apply flex justify-between items-center;
            }
        }
        .footer{
            @apply sticky bottom-0 left-0 right-0 py-5 bg-tan-m flex justify-between items-center;
        }
    }


    .option-list{
        @apply absolute z-20 w-full top-full left-0 right-0 bg-white overflow-y-auto flex flex-col gap-y-2;
        height: 300px;
        .option{
            @apply px-4 py-2 cursor-pointer transition-opacity duration-200;
            &:hover{
                @apply opacity-50;
            }
            &.active{
                @apply bg-purple-m-main text-white font-bold;
            }
        }
    }
</style>
<template>
    <div>
        
        <Header title="Info" :loading="loading" />
         
        <div class="w-full rounded-lg bg-tan-m p-6 mt-6">
            
            <div class="flex items-center gap-x-4 mb-4">
                <img class="w-10 h-10 rounded-full" :src="info.profile_picture_uri" />
                <div class="font-bold text-h4">{{info.name}}</div>
            </div>

            <div class="mb-4">{{info.link}}</div>

            <div v-if="info.primary_page" class="">
                <div class="font-bold">Primary Page:</div>
                <div>{{info.primary_page.name}}</div>
                <div>{{info.primary_page.id}}</div>
            </div>
            
        </div>
 
    </div>
</template>

<script>
import Header from './Header.vue'
import { mapGetters } from 'vuex';
export default {
    components: {
        Header
    },
    data(){
        return {
            info: {},
            loading: false
        }
    },
    mounted() {
        this.getInfo()
    },
    computed: {
        ...mapGetters(['company', 'fbLogin']),
    },
    methods: {
        async getInfo(){
            this.loading = true
            this.$http.get('/api/facebook', {
                params: {
                    entity_id: this.company.fb_business_id,
                    fields: ['id', 'name', 'link', 'primary_page', 'profile_picture_uri', 'picture'],
                    user_access_token: true
                }
            }).then(async (res) => {
                if(res.data){
                    this.info = res.data
                }
            }).catch((err) => {
                console.log('getInfo error', err)
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    
</style>
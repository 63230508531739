<template>
    <div class="create-report-post-list">
        <div
            v-for="post in posts"
            :key="post.id"
            class="post-item break-inside"
            :class="selectedPosts.includes(post.id) ? 'active' : 'filter grayscale'"
        >
            <!--<div class="absolute top-0 right-0 w-5 h-5 rounded flex justify-center items-center bg-white cursor-pointer" @click="togglePost(post.id)">
                <base-icon name="check" v-if="selectedPosts.includes(post.id)"/>
            </div>-->

            <div class="post-item-header" v-if="selectedPosts.includes(post.id)">
                <base-icon name="check" class="font-bold"/>
            </div>
            <!-- image -->
            <div @click="togglePost(post.id)">
                <img-selector
                    :is-video="post.is_video"
                    :images="[post.display_url]"
                    prop-class="object-cover animate-fade-in post-report-modal-img"
                    width="100%"
                    height="120px"
                />
            </div>

            <div class="post-item-footer">
                <div class="top">
                    <div class="info" :class="{'w-full': !post.pending}" @click="togglePost(post.id)">
                        <div class="profile-picture">
                            <img :src="post.profile_item.profile_picture_url"/>
                        </div>
                        <div class="name-network" :class="post.pending ? 'pending' : 'not-pending'">
                            <div class="name">{{ parseUnicodeCharacters(influencerName(post.profile_item)) }}</div>
                            <p class="network" v-if="post.network">{{ post.network ? post.network.display_name : '' }}</p>
                        </div>
                    </div>
                    <div
                        v-if="attentionRequired(post)"
                        @click.prevent.stop="$emit('edit-post', post)"
                        class="attention-required"
                    >
                        <base-icon
                            name="exclamation"
                            class="text-custom-yellow cursor-pointer"
                            v-tooltip="{ content: attentionRequiredMessage(post), classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } }"
                        />
                    </div>
                </div>
                <div class="copyright-text" v-if="!post.media || (post.media != undefined && Array.isArray(post.media) && !post.media.length)">
                    This post has been flagged for a copyright violation
                </div>
                <div class="caption-wrapper" :class="selectedPosts.includes(post.id) ? 'active' : 'not-active'" @click="togglePost(post.id)">
                    {{ parseUnicodeCharacters(post.caption) }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ImgSelector from './ImgSelector.vue';
import { parseUnicodeCharacters } from '../lib/strings';
export default {
    components: {
        ImgSelector,
    },
    props: ['posts', 'selectedPosts', 'select', 'remove'],
    data(){
        return {
            parseUnicodeCharacters
        }
    },
    methods: {
        selectPost(id) {
            if (this.select) {
                this.$emit('select-post', id);
            }
        },

        removePost(id) {
            if (this.remove) {
                this.$emit('remove-post', id);
            }
        },

        togglePost(id) {
            if (this.remove) {
                this.$emit('remove-post', id);
            } else if (this.select) {
                this.$emit('select-post', id);
            }
        },

        attentionRequired(post){
            return post.pending || (post.is_video && post.views <= 0)
        },

        attentionRequiredMessage(post){
            if(post.is_video){
                if(!post.views || post.views <= 0){
                    return 'This post is a video but views are not defined. Click here to add the views.';
                }
            }
            if(!post.media.length){
                return 'This post does not have media, Click here to add the media.';
            }
            return 'There are pending fields for this post. Click here to edit the stats.';
        },

        influencerName(profile) {
            return profile.full_name !== '' ? profile.full_name : profile.username;
        },
    }
}
</script>

<style lang="scss" scoped>
    .create-report-post-list{
        @apply w-full h-full grid grid-cols-3 gap-4;
        > .post-item{
            @apply w-full gap-1 cursor-pointer flex flex-col border-transparent flex-no-wrap rounded overflow-hidden bg-white relative;
            
            &.active{
                @apply text-white bg-gradient-to-b from-gradient-m-main-start to-gradient-m-main-end;
            }
            > .post-item-header{
                @apply absolute top-0 right-0 bg-green-m-main rounded-bl rounded-tr w-8 h-8 flex items-center justify-center text-gradient-m-main-start;
            }
            > .post-item-footer{
                @apply px-3 flex flex-col gap-1;
                > .top{
                    @apply flex justify-between;
                    > .info{
                        @apply flex items-center gap-2;
                        > .profile-picture{
                            @apply w-10;
                            > img{
                                @apply rounded-full w-10 h-10;
                            }
                        }
                        > .name-network{
                            &.pending{
                                @apply w-24;
                            }
                            &.not-pending{
                                @apply w-full;
                            }
                            > .name{
                                @apply font-bold capitalize overflow-hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            > .network{
                                @apply text-xs;
                            }
                        }
                    }
                    > .attention-required{
                        @apply w-8 h-8 text-xs flex justify-center items-center cursor-pointer;
                    }
                }
                > .copyright-text{
                    @apply italic text-xs text-red-400;
                }
                > .caption-wrapper{
                    @apply text-xs;
                    &.active{
                        @apply text-white;
                    }
                    &.not-active{
                        @apply text-gray-m-disable;
                    }
                }
            }
        }
    }


    .post-report-modal-img {
        min-height: 100px;
        max-height: 100px;
    }
</style>
import { render, staticRenderFns } from "./AssignCreatorModal.vue?vue&type=template&id=f01e38fa&scoped=true&"
import script from "./AssignCreatorModal.vue?vue&type=script&lang=js&"
export * from "./AssignCreatorModal.vue?vue&type=script&lang=js&"
import style0 from "./AssignCreatorModal.vue?vue&type=style&index=0&id=f01e38fa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f01e38fa",
  null
  
)

export default component.exports
<template>
<div class="w-full h-full">
    <!-- dropzone -->
    <ul v-if="!hideDropzone && show" class="file-list-wrapper w-full relative" @dragover.prevent="draggedOver = true" @drop.stop.prevent="dropHandler">
        <div class="drop-overlay absolute w-full h-full z-10" v-if="draggedOver" @dragover.prevent="draggedOver = true" @dragleave="draggedOver = false">
            <div class="flex flex-col justify-center items-center h-full">
                <div class="rounded-full flex flex-col items-center justify-center bg-purple-500 text-white py-8 px-12">
                    <base-icon size="16" name="upload3" />
                    <div class="text-xs">Drop Files<br>To Upload</div>
                </div>
            </div>
        </div>
        <li>
            <div id="dropzone-wrapper" class="flex items-center justify-center">
                <FormulateInput
                    type="file"
                    name="file"
                    multiple
                    :key="inputKey"
                    :uploader="uploadHandler"
                    :validation="`mime:${allowedMimetypes.join(',')}`"
                    :validation-messages="{
                        mime: 'Incorrect file type',
                    }"
                    @validation="onValidation"
                >
                    <template #prefix="{ id, errors }">
                        <label :for="id">
                            <div class="w-full h-full border-2 border-gray-300 border-dashed bg-tan-m cursor-pointer hover:bg-gray-m-light transition-colors duration-500">
                                <div class="flex flex-col justify-center items-center gap-y-4 my-20">
                                    
                                    <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M38 73.625V45.125" stroke="#0E092C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M26.125 57L38 45.125L49.875 57" stroke="#0E092C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M54.6253 49.8752H58.1878C60.6098 49.8793 62.9988 49.3135 65.1617 48.2234C67.3245 47.1334 69.2006 45.5497 70.6381 43.6005C72.0757 41.6512 73.0344 39.391 73.4369 37.0027C73.8393 34.6143 73.6741 32.1648 72.9547 29.8521C72.2352 27.5394 70.9817 25.4284 69.2954 23.6898C67.6092 21.9512 65.5375 20.6338 63.2478 19.844C60.9582 19.0542 58.5149 18.8143 56.1153 19.1435C53.7158 19.4728 51.4274 20.362 49.4351 21.7394C48.6399 17.5054 46.708 13.5675 43.8464 10.3473C40.9848 7.12714 37.3012 4.74591 33.1901 3.45863C29.0789 2.17136 24.6951 2.02652 20.508 3.03963C16.3208 4.05274 12.4881 6.18563 9.42016 9.20993C6.35224 12.2342 4.16467 16.036 3.09171 20.2082C2.01874 24.3804 2.10078 28.7659 3.32905 32.895C4.55732 37.0242 6.88555 40.7415 10.0644 43.6489C13.2433 46.5564 17.1532 48.5444 21.3753 49.4002" stroke="#0E092C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                    <div>
                                        <div class="text-h6">Drag or <span class="font-bold">upload</span> files here</div>
                                        <div class="w-full text-center">(.pdf, .png, .jpg, .docx)</div>
                                        <label :for="errors"/>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </template>
                </FormulateInput>
            </div>
        </li>
    </ul>
    <!-- add link -->
    <ul class="w-full">
        <separator v-if="hideDropzone"/>
        <li v-if="!hideLinkInput" class="pt-8">
            <div class="text-h6 font-bold pb-4">Links</div>
            <base-button
                v-if="!addingLink"
                @action="addingLink = true"
                class="font-bold"
                size="md"
                label="Add link"
                icon="plus"
                :iconSize="4"
                padding="p-2"
            />
            <div class="flex flex-col gap-y-6" v-if="addingLink">
                <div class="flex flex-col gap-y-3">
                    <div class="text-h6 font-bold">Link Name</div>
                    <FormulateInput
                        type="text"
                        name="file"
                        placeholder="Link name"
                        v-model="external_link_name"
                        class="mb-2"
                    />
                </div>
                <div class="flex flex-col gap-y-3">
                    <div class="text-h6 font-bold">External File Link</div>
                    <FormulateInput
                        type="text"
                        name="file"
                        placeholder="https://"
                        v-model="external_link"
                    />
                </div>
                <div class="py-3 flex justify-end">
                    <base-button @action="submitLink" :disabled="!external_link || external_link == ''" size="sm" label="Save"/>
                </div>
            </div>
        </li>

        <section-empty-state v-if="!hideEmptyState && hideDropzone && filteredFiles && filteredFiles.length <= 0"
            icon="chart-bar"
            message="No reports created"
            action="Add Report"
            @action="$emit('create-report')"
        />

    </ul>
    <!-- file list -->
    <ul class="file-list-wrapper w-full pt-6 flex flex-col gap-y-2">
        <li v-for="(file, i) in filteredFiles" :key="i" class="file-wrapper">
            <file-list-item
                :file="file"
                :project-id="projectId"
                :user-id="userId"
                :is-project-owner="isProjectOwner"
                @share="showShareModal"
                @edit-file="showEditFile"
                @refresh-data="getData()"
                :key="filesKey"                
            />
        </li>
    </ul>
    <share-modal
        :visible="sharing.show"
        @close="sharing.show = false"
        @saved="shareModalSaved"
        :type="sharing.type"
        :typeId="sharing.typeId"
    />
    <edit-file-modal
        :visible="showEditFileModal"
        :file="editFile"
        @close="showEditFileModal = false; editFile = null"
        @file-edited="updateFiles"
    />
</div>
</template>

<script>
import FileListItem from './FileListItem';
import ShareModal from './ShareModal';
import SectionEmptyState from './SectionEmptyState.vue';
import { fileUpload, linkUpload } from '../common';
import EditFileModal from './EditFileModal.vue';

export default {
    props: {
        show: {
            type: Boolean,
            default: true,
        },
        projectId: {
            type: Number,
            default: null,
        },
        isProjectOwner: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: Number,
            default: null,
        },
        mimetype: {
            type: String,
            default: null
        },
        exclude: {
            type: String,
            default: null
        },
        hideDropzone: {
            type: Boolean,
            default: false
        },
        hideLinkInput: {
            type: Boolean,
            default: false
        },
        hideEmptyState: {
            type: Boolean,
            default: false
        },
        preventDataLoad: {
            type: Boolean,
            default: false
        }
    },

    components: {
        FileListItem,
        ShareModal,
        SectionEmptyState,
        EditFileModal
    },

    data() {
        return {
            files: [],
            uploadedFiles: [],
            draggedOver: false,
            addingFile: false,
            
            sharing: {
                show: false,
                type: '',
                typeId: null
            },
            external_link: '',
            external_link_name: '',

            addingLink: false,

            showEditFileModal: false,
            editFile: null,
            filesKey: false,
            inputKey: false,
            allowedMimetypes: ['application/pdf','image/png','image/jpg','application/octet-stream']
        }
    },

    mounted() {
        if(!this.preventDataLoad){
            this.getData();
        }
    },

    created () {    
        window.onbeforeunload = (e) => {
            return this.addingFile ? 'File upload in progress' : null
        };
    },

    methods: {

        async getData() {
            const { projectId, userId } = this;
            const { data } = await this.$http.get('/api/files', { params: { projectId, userId }});
            
            this.files = data.reverse();
            this.filesKey = !this.filesKey;
        },

        showEditFile(file) {
            if (this.mimetype === 'reports') {
                this.$router.push(file.url + '/edit');
            } else {
                this.showEditFileModal = true;
                this.editFile = file;
            }
        },

        async updateFiles() {
            await new Promise(resolve => setTimeout(resolve, 500));
            await this.getData();
        },

        async dropHandler(event) {
            console.log('File(s) dropped');
            const droppedFiles = event.dataTransfer.files;

            if (!droppedFiles) {
                return;
            }

            for (let file of [...droppedFiles]) {
                await this.addFile(file);
            }

            this.draggedOver = false;
            this.getData();
            this.inputKey = !this.inputKey;
        },

        async addFile(file) {
            console.log("adding file", file);
            this.addingFile = true
            this.files.unshift({
                id: null,
                name: file.name,
                created: 'just now',
                visibility: 'private',
                uploading: true,
                uploadProgress: 0
            });

            const fileIndex = this.files.findIndex(u => u.name === file.name);

            const [uploadedFile] = await fileUpload(file, {projectId: this.projectId, userId: this.userId}, {
                onUploadProgress: (progressEvent) => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    this.files[fileIndex].uploadProgress = percentCompleted
                }
            });
            
            if (uploadedFile.error) {
                this.files[fileIndex].uploading = false;
                this.files[fileIndex].error = true;
                this.addingFile = false
                return;
            }
            
            this.files[fileIndex].id = uploadedFile.id;
            this.files[fileIndex].created_at = uploadedFile.created_at;
            this.files[fileIndex].uploading = false;
            this.$notify({ title: 'Success', text: 'File uploaded successfully', type: 'success' })
            this.addingFile = false
            // this.uploadedFiles[fileIndex] = uploadedFile;
        },

        async submitLink () {
            console.log("adding link", this.external_link);
            this.files.unshift({
                id: null,
                name: this.external_link,
                created: 'just now',
                visibility: 'private',
                uploading: true
            });

            await linkUpload({ name: this.external_link_name, file: this.external_link, projectId: this.projectId, userId: this.userId });
            this.external_link_name = '';
            this.external_link = '';
            this.$notify({ title: 'Success', text: 'Link saved successfully', type: 'success' })
            this.getData();
        },

        async uploadHandler(file, progress, error, option) {
            await this.addFile(file);
            this.getData();
            this.inputKey = !this.inputKey;
        },

        shareModalSaved(){
            this.sharing.show = false
            this.getData()
        },

        showShareModal({type, id }) {
            this.sharing.show = true;
            this.sharing.type = type;
            this.sharing.typeId = id;
        },

        onValidation(validation){
            if(validation.hasErrors && validation.errors.includes('Incorrect file type')){
                let msg = ``
                this.allowedMimetypes.forEach(mimetype => {
                    msg += `<div>• ${mimetype}</div>`
                })
                this.$swal.fire({
                    title: `The file must be one of the following:`,
                    html: msg,
                    icon: 'warning',
                    iconColor: '#0E092C',
                    confirmButtonText: 'Ok',
                }).then((result) => {
                    setTimeout(()=>{ this.inputKey++ }, 500)
                })
            }
        }
    },

    computed: {
        filteredFiles: function () {
            return this.files.filter(elem => {
                if (this.mimetype) {
                    if (this.mimetype === 'reports' && elem.mimetype === 'link') {
                        return elem.url.indexOf('/reports/') > -1;
                    }
                    return elem.mimetype === this.mimetype
                }

                if (this.exclude) {
                    if (this.exclude === 'reports' && elem.mimetype === 'link') {
                        return elem.url.indexOf('/reports/') === -1;
                    }
                    return elem.mimetype !== this.exclude
                }
                return elem;
            });
        }
    }
}
</script>


<style lang="scss">
#dropzone-wrapper .formulate-input div .formulate-input-element .formulate-input-upload-area{
    display: none;
}
</style>
<style lang="scss" scoped>
.formulate-input[data-classification=file] .formulate-input-upload-area {
    display: none;
}
div[data-classification = 'file']{
    width: 100%;
    height: 100%;
}
.file-list-wrapper {
    min-height: 250px;
}

.file-list-wrapper .drop-overlay {
    background: rgba(255, 255, 255, .8);
}

.file-list-wrapper {
    .file-wrapper {
        /* @apply border-t border-gray-200;

        &:first-child {
            border: none;
        } */

        .has-upload-error {
            &:hover {
                @apply bg-red-100;
            }
        }
    }
}

.file-wrapper:last-child .separator {
    display: none;
}
</style>
<template>

    <base-modal
        :visible="visible"
        title="Password protected report"
        @close="()=>null"
    >
        <template v-slot:title-icon>
            <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 h-10 w-10 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
            </svg>
        </template>
        
        <div class="flex mb-2">
            <h2 class="font-medium">Enter the report password to view it</h2>
        </div>

        <FormulateForm name="password-form" @submit="submit">

            <div class="flex flex-wrap gap-x-4 mt-3">
                <FormulateInput 
                    type="password" 
                    label="Password"
                    class="w-full"
                    placeholder="Enter password"
                    v-model="password" 
                />
            </div>
        
            <div class="py-3 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md sm:ml-3 sm:w-auto">
                    <base-button
                        @action="$formulate.submit('password-form')" 
                        :disabled="loading || password == ''" 
                        size="sm"
                        submit
                        label="Submit"
                    />
                </span>

                <span class="flex w-full rounded-md sm:ml-3 sm:w-auto">
                    <base-button 
                        @action="$router.back()" 
                        size="sm" 
                        type="secondary"
                        label="Back"
                    />
                </span>

            </div>

        </FormulateForm>

    </base-modal>
</template>

<script>
export default {
    props: ['visible', 'reportId'],
    data() {
        return {
            password: '',
            loading: false
        }
    },
    methods: {
        async submit(){
            this.loading = true
            try{
                
                if(!this.reportId) return this.$notify({ title: 'Warning', text: 'Report not found', type: 'warn' })
                if(!this.password || this.password == '') return this.$notify({ title: 'Warning', text: 'Password required', type: 'warn' })

                const { data } = await this.$http.post(`/api/reports/${this.reportId}/unlock`, {
                    password: this.password
                })

                if(data){
                    this.$notify({ title: 'Correct password', text: 'Report unlocked', type: 'success' })
                    this.$emit('unlocked')
                    this.$emit('close')
                }

            }catch(err){
                console.log('unlock report error', err)
                this.$notify({ title: 'Error', text: `${(err.response && err.response.data) ? err.response.data.message : ``}`, type: 'warn' })
            }finally{
                this.loading = false
            }
        }
    }
}
</script>
<template>
    <base-modal
        :visible="visible"
        @close="$emit('close')"
        :title="title"
    >
        <div class="flex flex-col justify-between" :key="!visible">
            <div class="grid grid-cols-6 gap-4">
                <div class="col-span-6">
                    <div class="field">
                        <div class="text-h6 font-bold">{{ modelName }}</div>
                        <FormulateInput type="text" validation="required" :placeholder="`Type the ${modelName} name`" v-model="name"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-between gap-3 mt-6 rounded-br-2xl">
            <base-button @action="$emit('close')" :disabled="saving" type="secondary" size="md" label="Cancel"/>
            <base-button @action="saveItem" :disabled="saving" size="md" :icon-size="4" label="Create" bold/>
        </div>
    </base-modal>
</template>

<script>
    export default {
        name: 'ItemCreatorModal',
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            },
            modelName: {
                type: String,
                default: ''
            },
            createBody: {
                type: Object,
                default: function () {
                    return {};
                }
            },
            route: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                saving: false,
                name: ''
            }
        },
        computed: {
        },
        methods: {
            async saveItem() {
                this.saving = true;
                try {
                    let body = this.createBody;
                    body.name = this.name;
                    await this.$http.post(this.route, body);
                    this.$notify({ title: 'Success', text: `${this.modelName} created successfully`, type: 'success' });
                    this.name = '';
                    this.$emit('finish-create');
                } catch(e) {
                    this.$notify({ title: 'Error', text: `Error saving! Please try again!`, type: 'warn' });
                    console.log(e);
                } finally {
                    this.saving = false;
                }
            },
        }
    }
</script>

<style scoped>

</style>
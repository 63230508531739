<template>
<div>

    <Title type="main">Custom Select</Title>

    <Title>Usage</Title>
    <Code title="Simple use" :code='`
        <custom-select
            :options="[{ label: "Option 1", value: 1 }, { label: "Option 2", value: 2 }]"
            v-model="selection"
        />
    `'/>

    <Title>Props</Title>
    <Table
        title="These are the props:"
        :columns="['Name', 'Type', 'Default', 'Required', 'Description']"
        :data="[
            ['options', 'Array', 'empty array', 'true', 'An array of object representing the options. Required format: { label: label, value: value }'],
            ['label', 'String', '-', 'false', 'Label to be displayed'],
            ['placeholder', 'String', 'false', 'false', 'Placeholder to be displayed'],
            ['value', '-', 'null', 'false', 'Value to be selected by default when the component mounts'],
            ['search', 'Boolean', '-', 'false', 'Adds a searchbar that filters the options that are already on the component data'],
            ['customSearch', 'Boolean', '-', 'false', 'Adds a searchbar that emits an @search event so you can change the options prop from the parent component'],
            ['multiple', 'Boolean', '-', 'false', 'Allows multiple selection'],
            ['required', 'Boolean', '-', 'false', 'Shows required label if no option is selected'],
            ['size', 'String', 'base', 'false', 'Size of multiple items in the whole component. Allows: base, sm, auto'],
            ['disabled', 'Boolean', 'false', 'false', 'Disabled state for the component'],
            ['bold', 'Boolean', 'false', 'false', 'Makes options font bold'],
            ['type', 'String', 'select', 'false', 'Changes style of the options: Accepts: pill, select'],
            ['showDesc', 'String', 'select', 'false', 'Shows the options help property (option.help)'],
            ['minBase', 'Boolean', 'false', 'false', 'Whether to apply the class min-h-base to the dropdown'],
            ['preventMountedEmit', 'Boolean', 'false', 'false', 'Prevents triggering the @change event when mounting the component']
        ]"
    />

    <Title>Events</Title>
    <Table
        title="These are the events:"
        :columns="['Name', 'Description']"
        :data="[
            ['input', 'Fires when a user selects an option'],
            ['change', 'Fires when a user selects an option'],
            ['interact', 'Fires only the first time that the user clicks on the component (when `open` data is changed)']
        ]"
    />

</div>
</template>
<script>
import Code from '../Code.vue'
import Table from '../Table.vue'
import Title from '../Title.vue'
import Paragraph from '../Paragraph.vue'
export default {
    components: {
        Code,
        Table,
        Title,
        Paragraph
    }
}
</script>
import axios from 'axios';

export const notifications = {
    namespaced: true,
    state: {
        pendingNotificationsCount: 0,
        pendingNotificationsCountLoading: false
    },
    mutations: {
        updatePendingNotificationsCount(state, val){
            if(val == 'sum') state.pendingNotificationsCount++
            if(val == 'sub') state.pendingNotificationsCount--
        },
        setPendingNotificationsCount(state, val){
            state.pendingNotificationsCount = val
        },
        setPendingNotificationsCountLoading(state, val){
            state.pendingNotificationsCountLoading = val
        }
    },
    actions: {
        async updatePendingNotificationsCount({commit}, option){
            await commit('updatePendingNotificationsCount', option)
        },
        async getPendingNotificationsCount({commit}){
            await commit('setPendingNotificationsCountLoading', true)
            try{
                const { data } = await axios.get('/api/user-notification/pending-count');
                await commit('setPendingNotificationsCount', data)
            }catch(err){
                console.log('getPendingNotificationsCount error')
            }finally{
                await commit('setPendingNotificationsCountLoading', false)
            }
        }
    }
};
<template>
    <div class="container">

        <div class="header">
            <div class="title">
                General Information               
            </div>                   
        </div> 

        <FormulateForm name="form" @submit="updateCompany()">

            <div class="fields">
                                                 
                <div class="logo-input">                       
                    <base-icon name="loader" v-if="uploadingLogo"/>
                    <span class="logo-container" @click="$refs.file.click()">
                        <img v-if="logo" :src="logo" class="logo"/>
                        <div v-else class="placeholder" />
                    </span>
                    <input class="hidden" type="file" ref="file" accept="image/*" @change="uploadCompanyLogo">
                </div>

                <FormulateInput
                    type="text"
                    validation="required"
                    v-model="name"
                    label="Company name"
                    placeholder="Company name"
                    validation-name="Company name"
                />
                <FormulateInput
                    type="text"
                    v-model="emailDomain"
                    label="Email domain"
                    placeholder="Email domain"
                    validation-name="Email domain"
                />
            </div>
            <base-button
                :disabled="loading" 
                @action="$formulate.submit('form')"
                size="md"
                :iconSize="4"
                theme="dark"
                type="secondary"
                submit
            >
                Save 
            </base-button>                  
        </FormulateForm>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {

    data() {
        return {
            name: '',
            logo: '',
            emailDomain: '',
            loading: false,
            uploadingLogo: false
        }
    },

    mounted() {
        this.name = this.company.name;
        this.logo = this.company.logo;
        this.emailDomain = this.company.email_domain;
    },

    computed: {
        ...mapGetters(['company']),
    },

    methods: {
        async updateCompany(){
            this.loading = true
            try {
                const { data } = await this.$http.put('/api/companies', { 
                    name: this.name,
                    email_domain: this.emailDomain 
                })
                if(data){
                    this.$notify({ title: 'Success', text: 'Company updated successfully', type: 'success' })
                    this.$store.dispatch('company/getCompany');
                }
            } catch(e) {
                console.log(e);
            } finally {
                this.loading = false
            }
        },
        async uploadCompanyLogo(e) {
            this.uploadingLogo = true
            try {
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);

                let formData = new FormData();
                formData.append('image', image);

                const { data } = await this.$http.post('/api/files/upload-company-logo', formData)
                this.logo = data.company.logo;
                this.$notify({ title: 'Success', text: 'Company logo updated successfully', type: 'success' })
            }
            catch(e) {
                console.log(e);
            }
            finally {
                this.uploadingLogo = false
            }
        }
    }
    
}
</script>

<style lang="scss" scoped>
    .container{
        @apply px-12 py-10;
        .header{
            @apply flex justify-between;
            .title{
                @apply text-h2 font-bold text-purple-m-secondary mb-10;
            }
        }
    }
    .fields{
        @apply flex flex-col w-1/3 gap-y-8 mb-10;
    }
    .logo-input{
        @apply flex items-center justify-center flex-shrink-0 cursor-pointer;
        .logo-container{
            @apply flex-grow;
            .logo{
                @apply h-auto w-32;
            }
            .placeholder{
                @apply w-32 h-32 rounded-full bg-tan-m;
            }
        }
    }
</style>

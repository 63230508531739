<template>
    <div class="container">

        <section>
            <h1 class="main-title">Terms of Service</h1>

            <p>Welcome to Creatorsaurus! We are excited to have you as a user of our app. Please read the following terms of service carefully before using Creatorsaurus.</p>
        </section>

        <section>
            <h3 class="title">Collection of Information</h3>

            <p>The App allows for third-party app connections and may collect the following types of information from users:</p>

            <ul class="list">
                <li>Personal information, such as name and email address, that may be provided by the user when creating an account or connecting to a third-party app.</li>
                <li>Data from connected third-party apps, such as activity or usage data.</li>
                <li>Device information, such as IP address and device type.</li>
            </ul>

        </section>

        <section>
            <h3 class="title">Acceptance of Terms</h3>

            <p>By using Creatorsaurus, you agree to these terms of service. If you do not agree to these terms, you should not use Creatorsaurus.</p>
        </section>

        <section>
            <h3 class="title">Description of Creatorsaurus</h3>

            <p>Creatorsaurus is an app that allows users to create, edit, and share their own creative content.</p>
        </section>

        <section>
            <h3 class="title">User Conduct</h3>

            <p>You agree to use Creatorsaurus only for lawful purposes and in accordance with these terms of service. You will not use Creatorsaurus to:</p>

            <ul class="list">
                <li>Upload, post, or transmit any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable.</li>
                <li>Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity.</li>
                <li>Interfere with or disrupt Creatorsaurus or servers or networks connected to Creatorsaurus, or disobey any requirements, procedures, policies or regulations of networks connected to Creatorsaurus.</li>
                <li>Intentionally or unintentionally violate any applicable local, state, national or international law.</li>
            </ul>
        </section>

        <section>
            <h3 class="title">Intellectual Property</h3>

            <p>Creatorsaurus and its original content, features and functionality are owned by Creatorsaurus and are protected by international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.</p>
        </section>

        <section>
            <h3 class="title">User Content</h3>

            <p>You retain ownership of any content that you create and upload to Creatorsaurus. However, by uploading content to Creatorsaurus, you grant Creatorsaurus a non-exclusive, royalty-free, worldwide, perpetual, irrevocable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content in connection with Creatorsaurus.</p>
        </section>

        <section>
            <h3 class="title">Disclaimer of Warranties</h3>

            <p>Creatorsaurus is provided "as is" and without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose.</p>
        </section>

        <section>
        <h3 class="title">Limitation of Liability</h3>

        <p>In no event shall Creatorsaurus or its affiliates be liable for any direct, indirect, punitive, incidental, special, or consequential damages arising out of or in any way connected with the use of Creatorsaurus or with the delay or inability to use Creatorsaurus, even if Creatorsaurus has been advised of the possibility of such damages.</p>
    </section>

    <section>
        <h3 class="title">Indemnification</h3>

        <p>You agree to indemnify and hold Creatorsaurus and its affiliates harmless from any claims, damages, losses, liabilities, costs or expenses (including reasonable attorneys' fees) arising out of your use of Creatorsaurus, your violation of these terms of service, or your violation of any rights of another.</p>
    </section>

    <section>
        <h3 class="title">Termination</h3>

        <p>Creatorsaurus may terminate your access to all or any part of Creatorsaurus at any time, with or without cause, with or without notice, effective immediately. If you wish to terminate this agreement, you may simply discontinue using Creatorsaurus.</p>
    </section>

    <section>
        <h3 class="title">Changes to Terms of Service</h3>

        <p>Creatorsaurus reserves the right to modify these terms of service at any time. Your continued use of Creatorsaurus following any such modification constitutes your agreement to be bound by the modified terms of service.</p>
    </section>

    <section>
        <h3 class="title">Governing Law and Jurisdiction</h3>

        <p>These terms of service shall be governed by and construed in accordance with the laws of the state of Texas, without giving effect to any principles of conflicts of law. You agree that any action at law or in equity arising out of or relating to these terms of service shall be filed only in the state or federal courts located in Texas, and you hereby consent and submit to the personal jurisdiction of such courts for the purposes of litigating any such action.</p>
    </section>

    <section>
        <h3 class="title">Entire Agreement</h3>

        <p>These terms of service constitute the entire agreement between you and Creatorsaurus with respect to the use of Creatorsaurus, and supersede all prior or contemporaneous communications and proposals, whether oral or written, between you and Creatorsaurus.</p>
    </section>

    <section>
        <h3 class="title">Waiver and Severability</h3>

        <p>The failure of Creatorsaurus to enforce any right or provision of these terms of service shall not be deemed a waiver of such right or provision. If any provision of these terms of service is held to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.</p>
    </section>

    <section>
        <h3 class="title">Third-Party Services</h3>

        <p>Creatorsaurus may contain links to third-party websites, services, or resources that are not owned or controlled by Creatorsaurus. Creatorsaurus has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that Creatorsaurus shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such third-party websites or services.</p>
    </section>
    
    <section>
        <h3 class="title">Assignment</h3>
        <p>You may not assign or transfer these terms of service, in whole or in part, without the prior written consent of Creatorsaurus. Creatorsaurus may assign or transfer these terms of service, in whole or in part, without restriction.</p>
    </section>

    <section>
        <h3 class="title">Contact Us</h3>
        <p>If you have any questions about these terms of service, please contact us at support@creatorsaurus.com.</p>
    </section>

    </div>
</template>

<script>
export default {
    data() {
        return {
            data: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    .container{
        @apply py-16 px-24;
    }
    .main-title{
        @apply font-bold text-h1 mb-4;
    }
    .title{
        @apply font-bold text-h3 mb-4;
    }
    section{
        @apply mb-6;
    }
    .list{
        @apply mb-4;
        list-style: disc;
    }
</style>
<template>
    <div class="relative">
        <div aria-has-popup="true" @click="showStatusDropdown = !showStatusDropdown" class="w-28 flex justify-center items-center m-1 font-medium text-xs py-1 px-4 rounded-full text-gray-600 bg-gray-300 cursor-pointer hover:shadow">
            <div class="text-xs font-medium text-gray-600 leading-none max-w-full flex-initial truncate">
                {{ statusName }}
            </div>
            <div class="flex flex-auto flex-row-reverse">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </div>
            </div>
        </div>
        <div class="-ml-14 w-56 mt-1 absolute rounded-md shadow-lg">
            <div 
                v-if="showStatusDropdown"
                class="z-10 rounded-md bg-white shadow-xs relative z-20" 
                role="menu" 
                aria-orientation="vertical" 
                aria-labelledby="project-options-menu-0" 
            >
                <div class="py-1 relative" v-for="status in statuses" :key="status.id" @mouseenter="status.showActions = true" @mouseleave="status.showActions = false">
                    <!-- Editing Mode -->
                    <div v-if="status.editing" class="h-9 w-full flex justify-center items-center px-2">
                        <FormulateInput
                            type="text"
                            v-model="status.name"
                            placeholder="Enter status name"
                            input-class="border border-gray rounded-md w-full flex-grow flex-shrink px-2 py-1 text-sm leading-5 text-gray-700"
                            @keyup.enter="editStatus(status)"
                        />
                        <button class="focus:outline-none" @click.stop="cancelStatusEditing(status)">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                            </svg>
                        </button>
                    </div>
                    <!-- Normal Mode  -->
                    <div v-else>
                        <!-- board name / button  -->
                        <a 
                            @click.prevent="changeStatus(status)" 
                            href="#" 
                            class="mx-2 mt-1 font-medium text-xs py-1 px-4 group flex leading-5 rounded-full text-gray-600 bg-gray-300"
                            :class="{ 'bg-gray-400 text-gray-100' : status.showActions }"
                            role="menuitem"
                        >                        
                            <span class="">
                                {{ status.name }}
                            </span>
                        </a>
                        <!-- actions -->
                        <div class="absolute px-2 top-1 right-1 bottom-0 bg-transparent rounded-full flex items-center gap-x-2">
                            <template v-if="status.showActions">
                                <!-- edit -->
                                <button class="focus:outline-none hover:bg-gray-200 rounded-full h-6 w-6" @click.stop="status.editing = true">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 m-auto" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                    </svg>
                                </button>

                                <!-- delete -->
                                <button class="focus:outline-none text-red-500 hover:bg-red-100 rounded-full h-6 w-6" @click.stop="confirmDeleteStatus(status)">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 m-auto" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="pt-1 flex justify-center">
                    <div v-if="creatingNewStatus" class=" p-2 flex justify-center items-center">
                        <FormulateInput
                            type="text"
                            v-model="newStatus"
                            :input-class="inputClass"
                            @keyup.enter="createStatusAndAssign"
                        />
                        <button class="focus:outline-none" @click="creatingNewStatus = false">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                            </svg>
                        </button>
                    </div>
               
                    <button v-else @click="creatingNewStatus = true"  class="p-2 w-full hover:bg-gray-100 flex justify-center items-center font-medium text-xs leading-7 text-gray-600 focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
                        </svg>
                        <span>New Status</span>
                    </button>
                </div>
            </div>
            <div 
                v-if="showStatusDropdown"
                @click.prevent="hideDropdown"
                class="fixed max-w-full max-h-full inset-0 z-10 bg-transparent" 
            />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        project: {
            type: Object,
            required: true
        },
        statuses: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            showStatusDropdown: false,
            creatingNewStatus: false,
            newStatus: 'New Status',
            loading: false
        }
    },
    watch: {
        creatingNewStatus (val) {
            if (val) {
                setTimeout(() => {
                    let inputs = document.getElementsByClassName(`new-status-input-${this.project.id}`);
                    if (inputs.length) {
                        inputs[0].select();
                    }
                }, 150);
            }
        }
    },
    computed: {
        statusName() {
            const found = this.statuses.find(element => element.id === this.project.status);
            return found ? found.name : 'w/o status';
        },
        inputClass() {
            return `new-status-input-${this.project.id} w-full px-3 py-1 flex-grow flex-shrink font-medium text-xs leading-5 text-gray-600`;
        }
    },
    methods: {
        hideDropdown() {
            if (!this.creatingNewStatus) {
                this.showStatusDropdown = false;
            }
        },
        changeStatus(status) {
            if (this.project.status !== status.id) {
                if (status.id !== 'new') {
                    this.assignProjectStatus(status.id)
                } else {
                    this.creatingNewStatus = true;
                }
            } else {
                this.showStatusDropdown = false;
            }
        },
        async assignProjectStatus(statusId) {
            const { data } = await this.$http.post(`/api/projects/${this.project.id}/status`, { statusId });
            if (data) {
                this.$notify({ title: 'Success', text: 'Status updated successfully', type: 'success' })
                this.showStatusDropdown = false
                this.$emit('done')
            }
        },
        async createStatusAndAssign() {
            this.loading = true
            const newStatus = this.newStatus.trim()
            if(newStatus && newStatus !== '') {
                this.$http.post('/api/statuses', { name: newStatus }).then((response) => {
                    this.$notify({ title: 'Success', text: 'Status created successfully', type: 'success' })
                    this.assignProjectStatus(response.data.id);
                    this.newStatus = 'New Status';
                }).catch((e) => {
                    console.log(e);
                    this.$notify({ title: 'Error', text: 'Failed to create new status', type: 'error' })
                }).finally(() => {
                    this.creatingNewStatus = false;
                    this.loading = false;
                });
            } else {
                this.$notify({ title: 'Warning', text: 'Empty new status', type: 'warn' })
            }
        },

        async editStatus(status) {
            if(!status || status.name === '') return this.$notify({ title: 'Warning', text: 'Status name required', type: 'warn' })
            try {
                const { data } = await this.$http.put(`/api/statuses/${status.id}`, { name: status.name });
                if (data) {
                    this.$notify({ title: 'Success', text: 'Status updated successfully', type: 'success' })
                    this.$emit('done')
                }
            } catch (err) {
                console.log(err);
                this.$notify({ title: 'Error', text: 'Something went wrong', type: 'error' })
            }
        },

        async cancelStatusEditing(status) {
            status.editing = false;
            this.$emit('done')
        },

        confirmDeleteStatus(status) {
            this.$swal.fire({
                title: `Are you sure you want to delete the '${status.name}' status?`,
                text: "You won't be able to revert this!",
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteStatus(status)
                }
            });
        },

        async deleteStatus(status) {
            try {
                const { data } = await this.$http.delete(`/api/statuses/${status.id}`);
                if (data) {
                    this.$notify({ title: 'Success', text: 'Status deleted successfully', type: 'success' })
                    this.$emit('done')
                }
            } catch (err) {
                console.log(err);
                this.$notify({ title: 'Error', text: 'Something went wrong', type: 'error' })
            }
        },
    }
}
</script>
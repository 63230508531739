<template>
    <div class="container">

        <section>
            <h1 class="main-title">Privacy Policy</h1>

            <p>This privacy policy applies to Creatorsaurus (the "App") and governs data collection and usage. By using the App, you consent to the data practices described in this policy.</p>
        </section>

        <section>
            <h3 class="title">Collection of Information</h3>

            <p>The App allows for third-party app connections and may collect the following types of information from users:</p>

            <ul class="list">
                <li>Personal information, such as name and email address, that may be provided by the user when creating an account or connecting to a third-party app.</li>
                <li>Data from connected third-party apps, such as activity or usage data.</li>
                <li>Device information, such as IP address and device type.</li>
            </ul>

        </section>

        <section>
            <h3 class="title">Use of Information</h3>

            <p>The App may use the collected information for the following purposes:</p>

            <ul class="list">
                <li>To provide and improve the App's services and features.</li>
                <li>To personalize content and experiences within the App.</li>
                <li>To communicate with users, such as for account verification or customer support.</li>
            </ul>
        </section>

        <section>
            <h3 class="title">Sharing of Information</h3>

            <p>The App may share user information with the following parties:</p>

            <p>Third-party service providers, such as payment processors or data analytics companies, to assist with the operation and improvement of the App.
            As required by law or in the interest of protecting or exercising the App's legal rights.</p>
        </section>

        <section>
            <h3 class="title">Data Request and Deletion Policy</h3>

            <p>At Markerly, we value the privacy of our users and understand that you may have concerns about your personal data. If you would like to request that your Platform Data be modified or deleted, please contact us at data@markerly.com. We will respond to your request as soon as possible and take the necessary actions to address your concerns. Please note that some data may be retained for legal or operational reasons.</p>
        </section>

        <section>
            <h3 class="title">Security of Information</h3>

            <p>The App takes reasonable precautions to protect user information from loss, misuse, and unauthorized access, disclosure, alteration, and destruction. However, no internet or email transmission is ever fully secure or error-free.</p>
        </section>

        <section>
            <h3 class="title">Changes to This Privacy Policy</h3>

            <p>This Privacy Policy may be updated from time to time for any reason. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.</p>
        </section>

        <section>
            <h3 class="title">Contact Us</h3>

            <p>If you have any questions about this Privacy Policy, please contact us at support@markerly.com.</p>
            <p>Address:</p>
            <p>166 Hargraves Dr</p>
            <p>Suite C-400-225</p>
            <p>Austin, TX 78737</p>
        </section>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    .container{
        @apply py-16 px-24;
    }
    .main-title{
        @apply font-bold text-h1 mb-4;
    }
    .title{
        @apply font-bold text-h3 mb-4;
    }
    section{
        @apply mb-6;
    }
    .list{
        @apply mb-4;
        list-style: disc;
    }
</style>

<template>
    <base-modal
        title="Copyright Detail"
        :visible="visible"
        @close="$emit('close')"
    >
        <div class="container">

            <div class="status">
                <div class="title">Status: </div>
                <div :class="`name ${statusSlug}`">{{statusDisplayName}}</div>
                <div class="message">{{statusMessage}}</div>
            </div>

            <div class="results" v-if="statusSlug == 'complete'">
                <div class="title">Result: </div>
                <div :class="`result ${slugify(result)}`">{{result}}</div>
                <div class="message">{{resultMessage}}</div>
            </div>

            <div class="matches" v-if="isArray(value.copyright_matches)">
                <div class="title">Matches:</div>
                
                <div class="list">
                    <div class="match" v-for="(match, index) in value.copyright_matches" :key="`match-${index}`">

                        <div><b>Author:</b> {{match.author}}</div>
                        <div><b>Content title:</b> {{match.content_title}}</div>

                        <div class="segments" v-if="isArray(match.matched_segments)">
                            <div class="title">Matched segments:</div>
                            <div class="list">
                                <div class="segment" v-for="(segment, i) in match.matched_segments" :key="`segment-${i}`">
                                    <base-icon :name="segment.segment_type == 'AUDIO' ? 'audio' : 'video'"/>
                                    <div class="mt-1">{{segment.segment_type}}</div>
                                    <div><b>Start time:</b> {{segment.start_time_in_seconds}}s</div>
                                    <div><b>Duration:</b> {{segment.duration_in_seconds}}s</div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="owner-copyright-policy" v-if="isObject(match.owner_copyright_policy)">
                            <div><b>Copyright policy owner:</b> {{match.owner_copyright_policy.name}}</div>
                            <div class="actions" v-if="isArray(match.owner_copyright_policy.actions)">
                                <div class="title">Actions:</div>
                                <div class="list">
                                    <div class="action" v-for="(action, j) in match.owner_copyright_policy.actions" :key="`action-${j}`">
                                        <div class="name">{{action.action}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </base-modal>
</template>

<script>
import { slugify } from '../lib/strings'
import { isArray, isObject } from '../common'
export default {
    props: {
        visible: {
            type: Boolean
        },
        value: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            // This is just to test different combinations 
            // value: {
            //     status: "complete",
            //     matches_found: true,
            //     copyright_matches: [
            //         {
            //             author: "John Doe",
            //             content_title: "The title of the content",
            //             matched_segments: [
            //                 {
            //                     duration_in_seconds: 10,
            //                     segment_type: "AUDIO",
            //                     start_time_in_seconds: 10 
            //                 }
            //             ],
            //             owner_copyright_policy: {
            //                 name: "John Doe",
            //                 actions: [
            //                     { action: "BLOCK" }
            //                 ]
            //             }
            //         }
            //     ]
            // },

            // This is an example of all the variants for the copyright status object
            copyright_status_example: {
                
                status: 
                    // the detection process has finished
                    "complete" || 
                    // an error occurred during the detection process
                    "error" ||
                    // the detection process is ongoing
                    "in_progress" ||
                    // the detection process has not started
                    "not_started",

                matches_found: 
                    // true if the video does violate copyright
                    true || 
                    //  false if the video does not violate copyright,
                    false,

                // If a video is violating copyright, the copyright_matches is returned with an array of objects
                copyright_matches: [
                    {
                        // the author of the copyrighted video
                        author: "",
                        // the name of the copyrighted video
                        content_title: "",
                        matched_segments: [
                            {
                                // the number of seconds the content violates copyright
                                duration_in_seconds: Number,
                                segment_type: "AUDIO" || "VIDEO",
                                // set to the start time of the video
                                start_time_in_seconds: Number 
                            }
                        ],
                        owner_copyright_policy: {
                            // The name for the copyright owners' policy
                            name: "",
                            // An array of action objects with the mitigations steps taken defined by the copyright owner's policy. 
                            // May include different mitigations steps for different locations.
                            actions: [
                                {
                                    // The mitigation action taken against the video violating copyright. 
                                    // Different mitigation steps can be taken for different countries. 
                                    // Can be one of the following values
                                    action:
                                        // The video is blocked from the audiences listed in the geos array 
                                        "BLOCK" ||
                                        // The video is muted for audiences listed in the geos array
                                        "MUTE"
                                }
                            ]
                        }
                    }
                ] 
            },
            slugify,
            isArray,
            isObject
        }
    },
    computed: {
        
        statusSlug(){
            return this.value.status
        },
        statusDisplayName(){
             const options = {
                'complete': 'Complete',
                'error': 'Error',
                'in_progress': 'In progress',
                'not_started': 'Not started'
            };
            return options[this.statusSlug];
        },
        statusMessage(){
            const options = {
                'complete': 'The detection process has finished',
                'error': 'An error occurred during the detection process',
                'in_progress': 'The detection process is ongoing',
                'not_started': 'The detection process has not started'
            };
            return options[this.statusSlug];
        },

        result(){
            return this.value.matches_found == true ? 'Copyrighted' : 'Not copyrighted'
        },
        resultMessage(){
            return this.value.matches_found == true ? 'The video does violate copyright' : 'The video does not violate copyright'
        }

    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply flex flex-col justify-start items-start gap-y-4;
        > .status{
            @apply flex items-center gap-x-4;
            > .title{
                @apply font-bold;
            }
            > .name{
                @apply w-auto rounded-full py-2 px-4 capitalize font-bold;
                &.complete{
                    @apply bg-green-400;
                }
                &.error{
                    @apply bg-red-400;
                }
                &.in_progress{
                    @apply bg-yellow-400;
                }
                &.not_started{
                    @apply bg-gray-400;
                }
            }
            > .message{
                //
            }
        }
        > .results{
            @apply flex items-center gap-x-4;
            > .title{
                @apply font-bold;
            }
            > .result{
                @apply w-auto rounded-full py-2 px-4 capitalize font-bold;
                &.copyrighted{
                    @apply bg-red-400;
                }
                &.not-copyrighted{
                    @apply bg-green-400;
                }
            }
            > .message{
                //
            }
        }
        .matches{
            @apply w-full;
            > .title{
                @apply font-bold;
            }
            > .list{
                @apply w-full mt-4;
                > .match{
                    @apply p-4 w-full border border-black rounded-lg;
                    > .data{
                        
                    }
                    > .segments{
                        > .title{
                            @apply font-bold;
                        }
                        > .list{
                            @apply flex my-2;
                            > .segment{
                                @apply p-4 rounded-lg border border-gray-200 flex flex-col items-center;
                            }
                        }
                    }
                    > .owner-copyright-policy{
                        > .actions{
                            > .title{
                                @apply font-bold;
                            }
                            > .list{
                                > .action{
                                    > .name{

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>

<template>
    <div class="new-company-register-container">
    
        <Header
            title="New Account Creation"
            description=""
        />
    
        <div class="loader-container">
            <base-icon v-if="loading" name="loader" class="text-white" />
        </div>
    
        <div class="form-wrapper">
    
            <div class="form-container">
                <alert class="mb-8" type="danger" v-if="error">
                    <template v-slot:icon>
                        <base-icon name="exclamation-2" />
                    </template>
                    {{ error }}
                    <div v-if="error.includes('email already exists') || error.includes('You have already linked this facebook account')">
                        You can try <a @click.prevent="$router.push(loginRoute)" href="#" class="link">Logging In</a>
                    </div>
                </alert>
                <FormulateForm 
                    class="flex flex-col company-register" 
                    name="register-form"
                    @submit="register"
                    v-model="form"
                >
                    
                    <p class="text-md text-left w-full mb-4">Step {{ (currentStep + 1) }} of {{ steps.length }}</p>
                    <div v-for="(step, i) in steps" :key="i" v-show="currentStep === i">
                        <h1 class="text-5xl">{{ step.header }}</h1>
                        <p class="mb-3">{{ step.subHeader }}</p>
                    </div>

                    <div v-show="currentStep === 0">
                        <FormulateInput
                            class="w-full"
                            type="text"
                            name="company_name"
                            placeholder="Ex: Super Awesome Marketing Co"
                            validation=""
                        />

                        <div class="mt-4 mb-4" v-if="workspaceUrl !== ''">Your workspace will be: <strong>{{ workspaceUrl }}.creatorsaurus.com</strong></div>
                    </div>

                    <div v-show="currentStep === 1">
                        <FormulateInput
                            class="w-full"
                            type="text"
                            name="name"
                            placeholder="John Anderson"
                            validation=""
                        />
                        <p class="text-md mt-10 mb-2"><strong>Your Profile Photo</strong> <span class="text-gray-500">(optional)</span></p>
                        <FormulateInput
                            class="w-1/2"
                            type="image"
                            name="avatar"
                            label="Help your Teammates know they're talking to the right person."
                            help="Select a png, jpg or gif to upload."
                            validation="mime:image/jpeg,image/png,image/gif"
                        />
                    </div>

                    <FormulateInput
                        type="textarea"
                        v-show="currentStep === 2"
                        rows="6"
                        name="users"
                        placeholder="john@company.com, cindy@company.com"
                        v-model="form.users"
                    />

                    <div class="flex items-center mt-6">
                        <base-button
                            @action="currentStep--" 
                            not-disabled="loading || !formValidated"
                            bold
                            label="Back"
                            type="secondary"
                            class="mr-4"
                            v-if="currentStep > 0"
                        />

                        <base-button
                            @action="nextStep" 
                            not-disabled="loading || !formValidated"
                            bold
                            label="Next"
                        />

                        <base-button
                            @action="nextStep" 
                            not-disabled="loading || !formValidated"
                            bold
                            label="Skip this step"
                            type="secondary"
                            class="mr-4 ml-6"
                            v-if="steps[currentStep].skippable"
                        />
                    </div>

                    <!-- <base-button
                        @action="$formulate.submit('register-form')" 
                        :disabled="loading || !formValidated"
                        class="mt-6 w-full"
                        submit
                        bold
                        label="Register"
                    /> -->
    
                    <!-- <div class="flex justify-center mt-6">
                        <a @click.prevent="$router.push(loginRoute)" href="#" class="link">
                            Already have an account? Log in
                        </a>
                    </div> -->
                    
                </FormulateForm>
    
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import Alert from '../../components/Alert';
    import Header from './Header.vue'
    import { mapGetters } from 'vuex';
    export default {
        components: {
            Alert,
            Header
        },
        data() {
            return {
                invitation: {},
                form: {
                    company_name: '',
                    name: '',
                    users: '',
                    avatar: ''
                },

                email: '',
                firstName: '',
                lastName: '',
                password: '',
    
                loading: false,
                error: null,

                currentStep: 0,

                steps: [
                    {
                        header: 'What\'s the name of your company or team?',
                        subHeader: 'This will be the name of your Creatorsaurus workspace -- choose something that your team will recognize.'
                    },
                    {
                        header: 'What\'s your name?',
                        subHeader: 'So that all of your creators and co-workers can recognize you.'
                    },
                    {
                        header: 'Who are your creators?',
                        subHeader: 'Add creator by email so they can register for accounts',
                        skippable: true,
                    }
                ]
            }
        },
        mounted(){
            if (this.user) {
                this.form.name = [this.user.first_name, this.user.last_name].join(' ');
            }
        },
        computed: {
            ...mapGetters(['isGuest', 'user']),
            loginRoute(){
                const t = this.$route.query.t
                return `/login${t ? `?t=${t}` : ``}`
            },
            formValidated(){
                let err = 0
                if(!this.firstName || this.firstName == '') err++
                if(!this.lastName || this.lastName == '') err++
                if(!this.email || this.email == '') err++
                if(!this.password || this.password == '') err++
                return !err
            },

            workspaceUrl() {
                if (this.form.company_name.trim() === '') {
                    return '';
                }
                return this.form.company_name
                    .toLowerCase()
                    .trim()
                    .replace(/[^\w\s-]/g, '')
                    .replace(/[\s_-]+/g, '-')
                    .replace(/^-+|-+$/g, '');
            }
        },
        methods: {

            nextStep() {
                if (this.currentStep >= this.steps.length - 1) {
                    return this.register();
                }

                this.currentStep++;
            },

            async register() {
                
                try {
                    this.error = null;
                    this.loading = true;
                    const payload = {...this.form};
                    payload.company_slug = this.workspaceUrl;
                    const { data } = await this.$http.post(`/api/auth/register/company`, payload);

                    if (data && data.access_token) {
                        return window.location.href = `${location.protocol}//${this.workspaceUrl}.${location.host}/company-redirect?access_token=${this.user.access_token}`;
                        // await this.$store.dispatch("auth/login", data);
                        // await this.$store.dispatch('auth/getMe')
                        // await this.$store.dispatch('colors/getColors')
                        // await this.$store.dispatch('statuses/getStatuses')
                        // await this.$store.dispatch('boards/getBoards')
                        // await this.$store.dispatch('company/getCompany');
                        // await this.$store.dispatch('fb/getLoginStatus');
    
                        // if (this.redirect && this.redirect !== '') {
                        //     const redirect = this.redirect;
                        //     this.$router.push(redirect);
                        //     this.$store.dispatch("auth/setRedirect", null);
                        // }
                        // this.$router.push('/home');
                    }
                } catch(e) {
                    console.log(e);
                    if (e.response && e.response.data) {
                        return this.error = e.response.data.message;
                    }
                    this.error = 'An unknown error occurred. Please try again.';
                } finally {
                    this.loading = false;
                }
            }
        }
    }
    </script>
    <style lang="scss" scoped>
        .new-company-register-container {
            @apply min-h-screen bg-purple-m-main flex flex-col overflow-y-auto;
            @media (min-width: 640px) {
                @apply px-6;
            }
            @media (min-width: 1024px) {
                @apply px-8;
            }
            .loader-container {
               @apply h-8 w-full flex justify-center items-center;
               min-height: 2rem;
            }
            .form-wrapper {
                @apply mb-12;
                @media (min-width: 640px) {
                    @apply mx-auto w-2/3;
                }
                .form-container {
                    @apply bg-white py-8 px-4 shadow;
                    @media (min-width: 640px) {
                        @apply rounded-lg px-10;
                    }
                }

                h1 {
                    @apply leading-tight mb-4;
                }
            }
        }
        .separator{
            @apply flex w-full mt-4 items-center gap-2 justify-between;
            .center{
                @apply text-gray-headers;
            }
            .left, .right{
                @apply w-full border-t;
            }
        }
        .link{
            @apply text-sm leading-5 font-medium text-gray-m-dark; 
            &:hover{
                @apply opacity-75 transition-opacity;
            } 
            &:focus{
                @apply outline-none underline;   
            }
        }
    </style>
import { render, staticRenderFns } from "./BaseTableColumnFeaturedPostsModalItem.vue?vue&type=template&id=4cfb1cd0&scoped=true&"
import script from "./BaseTableColumnFeaturedPostsModalItem.vue?vue&type=script&lang=js&"
export * from "./BaseTableColumnFeaturedPostsModalItem.vue?vue&type=script&lang=js&"
import style0 from "./BaseTableColumnFeaturedPostsModalItem.vue?vue&type=style&index=0&id=4cfb1cd0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cfb1cd0",
  null
  
)

export default component.exports
<template>
    <div class="container">
        
        <div class="title">Report created successfully</div>
        
        <div class="actions">
            <base-button 
                label="View Report"
                @action="openReportLink()" 
                type="label"
                bold
                icon="eye" 
            />
            <base-button 
                label="Copy Link"
                @action="copyLink()" 
                type="label" 
                icon="link" 
                bold
                justify="start" 
            />
        </div>
        
    </div>
</template>
<script>
export default {
    props: ['guid'],
    methods: {
        openReportLink() {
            let href = `/reports/${this.guid}`
            let a = document.createElement('a');
            a.href = href;
            a.target = '_blank';
            a.click();
        },
        copyLink() {
            this.$copyToClipboard(this.$notify, `${window.location.origin}/reports/${this.guid}`);
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        > .title{
            @apply text-h3 font-bold;
        }
        > .actions{
            @apply mt-4 flex flex-col items-start gap-y-4;
        }
    }
</style>
<template>
    <base-button
        @action="login"
        v-if="available"
        icon="twitter"
        bold
        class="mt-6 w-full"
        :label="label"
        :disabled="disabled"
        type="secondary"
        theme="dark"
        border
    />
</template>
<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'Sign in with Twitter'
        }
    },
    data() {
        return {
            available: false
        }
    },

    beforeMount() {
        this.getIsAvailable();
    },

    mounted() {
        const { oauth_token, oauth_verifier } = this.$route.query
        if(oauth_token && oauth_verifier){
            this.sendCredentials()
        }
    },

    methods: {
        async getIsAvailable() {
            const { data } = await this.$http.get(`/api/auth/get-auth-credentials/twitter?has=true`);
            this.available = data;
        },
        async login(){
            const { data } = await this.$http.get(`/api/auth/twitter/login`, {
                params: {
                    route: window.location.href
                }
            })
            localStorage.setItem('twitter_token', data.token)
            window.open(data.url, "_self")
        },
        async sendCredentials(){
            const { oauth_token, oauth_verifier } = this.$route.query 
            const { data } = await this.$http.get(`/api/auth/twitter/callback`, {
                params: {
                    route: window.location.href,
                    oauth_token: oauth_token,
                    oauth_verifier: oauth_verifier,
                    token: localStorage.getItem('twitter_token')
                }
            })
            localStorage.removeItem('twitter_token')
            if(data){
                let [first_name, ...last_name] = data.name.split(" ")
                last_name = last_name.join(" ")
                this.$emit('success', {
                    email: data.email,
                    first_name: first_name,
                    last_name: last_name,
                    picture: data.profile_image_url,
                    userId: data.id,
                    networkId: 7
                })
            }
        }
    }
}
</script>

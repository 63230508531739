<template>
    <base-modal
        :visible="visible"
        :title="'Update Concept and Draft Dates'"
        with-picture
        modal-title="Update Dates"
        modal-icon="deliverable"
        no-padding
        @close="$emit('close')"
    >
        <div class="flex flex-col justify-between">
            <div class="pt-5 pb-20 px-14 form-modal-container">

                <div class="grid grid-cols-2 gap-4 mt-4">
                    <div v-for="(deliverable, i) in deliverables_options" :key="i">
                        <template v-if="deliverable.value && userDeliverable[deliverable.value] !== undefined">
                            <date-picker
                            :label="`${deliverable.label} Date`"
                            :value="userDeliverable[deliverable.value]"
                            @change="(date) => userDeliverable[deliverable.value] = date"
                            />
                        </template>
                    </div>
                </div>

            </div>

            <div class="flex sm:flex justify-end gap-3 bg-white rounded-br-2xl py-3 px-14">
                <base-button @action="saveDates()" :size="'lg'" label="Save"/>
            </div>
        </div>
    </base-modal>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: ['userDeliverable', 'visible', 'fixedAmount'],
    components: {
    
    },
    computed: {
        ...mapGetters(['isAdminOrHigher']),
        
        title () {
            switch (this.step) {
                case 1:
                    return 'Select Deliverable Type';
                case 2:
                    return 'Select Post Type';
                case 4:
                    return 'Final Steps';
            }
            return !this.currentUserDeliverable ? 'Add Deliverable' : 'Edit Deliverable';
        },
        availableDeliverables () {
            if(this.userDeliverable.network){
                return this.deliverables.filter(deliverable => {
                    return deliverable.network_id === this.userDeliverable.network.id;
                }).map(deliverable => {
                    return {
                        value: deliverable.id,
                        id: deliverable.slug,
                        help: deliverable.description,
                        label: deliverable.name,
                    };
                });
            } else {
                return [];
            }
        },
    },
    data() {
        return {
            step: 1,
            submitting: false,
            deliverablesTypes: [],
            deliverables: [],
            hasPayment: true,
            deliverables_options: [
                {
                    value: 'concept_approval',
                    label: 'Concept',
                    id: 'concept_approval',
                    help: 'Concept needs to be approved'
                },
                {
                    value: 'post_approval',
                    label: 'Draft',
                    id: 'post_approval',
                    help: 'Draft needs to be approved'
                }
            ],
            deliverableCustomSelectKey: 0, // for custom select re-render
            hasDeliverables: true
        }
    },
    watch: {

    },
    mounted () {
        // console.log(this.userDeliverable);
        // console.log(this.userDeliverable['concept_approval']);
    },
    methods: {
        async saveDates() {
            
            if ( this.userDeliverable.concept_approval_date != '' && this.userDeliverable.post_approval_date != '' ) {
                const concept_approval = this.userDeliverable['concept_approval'];
                const post_approval = this.userDeliverable['post_approval'];

                const payload = { concept_approval: concept_approval, post_approval: post_approval };
                let endpoint = `/api/userDeliverables/update-dates/${this.userDeliverable.id}`;
                const { data } = await this.$http.post(endpoint, payload);
                if ( data ) {
                    this.$notify({ title: 'Success', text: 'Deliverable dates updated successfully', type: 'success' });
                    this.$emit('deliverable-dates-updated', { id: data.id });
                    this.$emit('close');
                } else {
                    this.$notify({ title: 'Warning', text: 'Something went wrong when trying to update.', type: 'warn' });   
                }
            } else {
                this.$notify({ title: 'Warning', text: 'Something went wrong when trying to update.', type: 'warn' });   
            }


            //this.$emit('deliverable-dates-updated', { id: data.id });

            // // Call the async function to update the backend with the selected dates and retrieve the response
            // const response = await this.updateBackend(this.userDeliverable);
            
            // // Use the response to show a success or error message to the user
            // if (response.success) {
            //   this.$swal.fire({
            //     title: 'Success!',
            //     text: 'Backend updated successfully',
            //     icon: 'success',
            //     confirmButtonText: 'Ok'
            //   });
            // } else {
            //   this.$swal.fire({
            //     title: 'Error!',
            //     text: 'Failed to update backend',
            //     icon: 'error',
            //     confirmButtonText: 'Ok'
            //   });
            // }
        }
    }
}
</script>

<style lang="scss">
    .dt-item {
        @apply bg-white text-purple-m-main;

        .dt-icon {
            @apply text-gradient-m-main-start;
        }

        &.dt-item-selected, &:hover {
            @apply text-white bg-gradient-to-b from-gradient-m-main-start to-gradient-m-main-end;
            .dt-icon {
                @apply text-green-m-main;
            }
        }
    }
</style>
import moment from 'moment';

// todays date for reference
let today = moment().startOf('day');

// post update frequencies
const frequencies = [
    {
        name: 'future',
        refresh_frequency: "daily",
        match: function (published_at) {
            const start = today.clone().startOf("day");
            return published_at.isAfter(start);
        },
        refreshed_at: today.clone().subtract(1, "days").startOf("day")
    },
    {
        name: "1_week_old",
        refresh_frequency: "daily",
        match: function (published_at) {
            const start = today.clone().subtract(7, "days").startOf("day");
            const end =   today.clone().startOf("day");
            return published_at.isAfter(start) && published_at.isBefore(end);
        },
        refreshed_at: today.clone().subtract(1, "days").startOf("day")
    },
    {
        name: "2_weeks_old",
        refresh_frequency: "daily",
        match: function (published_at) {
            const start = today.clone().subtract(14, "days");
            const end = today.clone().subtract(7, "days");
            return published_at.isAfter(start) && published_at.isBefore(end);
        },
        refreshed_at: today.clone().subtract(1, "days")
    }, 
    {
        name: "1_month_old",
        refresh_frequency: "weekly",
        match: function (published_at) {
            const start = today.clone().subtract(1, "months");
            const end = today.clone().subtract(14, "days");
            return published_at.isAfter(start) && published_at.isBefore(end);
        },
        refreshed_at: today.clone().subtract(7, "days")
    },
    {
        name: "3_months_old",
        refresh_frequency: "monthly",
        match: function (published_at) {
            const start = today.clone().subtract(3, "months");
            const end = today.clone().subtract(1, "months");
            return published_at.isAfter(start) && published_at.isBefore(end);
        },
        refreshed_at: today.clone().subtract(1, "months")
    }, 
    {
        name: "6_months_old",
        refresh_frequency: "monthly",
        match: function (published_at) {
            const start = today.clone().subtract(6, "months");
            const end = today.clone().subtract(3, "months");
            return published_at.isAfter(start) && published_at.isBefore(end);
        },
        refreshed_at: today.clone().subtract(1, "months")
    },
    {
        name: "1_year_old",
        refresh_frequency: "monthly",
        match: function (published_at) {
            const start = today.clone().subtract(1, "years");
            const end = today.clone().subtract(6, "months");
            return published_at.isAfter(start) && published_at.isBefore(end);
        },
        refreshed_at: today.clone().subtract(1, "months")
    },   
    {
        name: "older_than_1_year",
        refresh_frequency: "monthly",
        match: function (published_at) {
            const end = today.clone().subtract(1, "years");
            return published_at.isBefore(end);
        },
        refreshed_at: today.clone().subtract(1, "months")
    }
];

// given a date, return the frequency it belongs to
export function getFrequency(published_at) {
    const _published_at = moment(published_at);
    for (const frequency of frequencies) {
        if(frequency.match(_published_at)){
            return frequency;
        }
    }
    return null;
}

// given a post, return true or false
// depending on if it has been refreshed correctly
// according to the post update frequency variable
export function isRefreshed(post) {

    // dont consider posts that are not ig or tt
    if(![2, 14].includes(post.network_id)) return true;
    let published_at = post.date;
    let refreshed_at = post.refreshed_at;

    // If there is no published at, there is no reference, we dont consider this post, return true.
    if(!published_at) return true;
    // If there is no refreshed at, there is no reference either, we dont consider this post, return true.
    if(!refreshed_at) return true;
    
    // convert to moment
    published_at = moment(published_at);
    refreshed_at = moment(refreshed_at);

    // get frequency
    const frequency = getFrequency(published_at);
    
    if(refreshed_at.isAfter(frequency.refreshed_at)){
        return true;
    }
    return false;

}
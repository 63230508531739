
// find the fb action name by providing a post property like "views", "shares", "likes" etc...
// returns the action name (ex. "onsite_conversion.post_save")
export function findActionNameByPostProperty(post_property){
    const actions = getActions();
    const found = actions.find(element => element.post_property == post_property);
    if(found && found.name){
        return found.name;
    }
    return null;
}

export function getAction(name, property = null){   
    const actions = getActions(false);
    const found = actions.find(element => element.name == name);
    if(found){
        return property ? found[property] : found
    }
    return null;     
}

// by default we only return the actions that have the "post_property" set
export function getActions(filtered = true){
    return filtered ? mappedActions.filter(element => element.post_property) : mappedActions;
}

// mapped fb ads actions so we can link them to our posts stats,
// if we want link them to a post stat we just add the property "post_property"
// and set it to "views", "comments", "shares", etc.
const mappedActions = [
    {
        "name": "app_custom_event.fb_mobile_achievement_unlocked",
        "description": "Mobile App Feature Unlocks"
    },
    {
        "name": "app_custom_event.fb_mobile_activate_app",
        "description": "Mobile App Starts"
    },
    {
        "name": "app_custom_event.fb_mobile_add_payment_info",
        "description": "Mobile App Payment Details"
    },
    {
        "name": "app_custom_event.fb_mobile_add_to_cart", 
        "description": "Mobile App Adds To Cart"
    },
    { 
        "name": "app_custom_event.fb_mobile_add_to_wishlist", 
        "description": "Mobile App Adds to Wishlist" 
    },
    { 
        "name": "app_custom_event.fb_mobile_complete_registration", 
        "description": "Mobile App Registrations" 
    },
    { 
        "name": "app_custom_event.fb_mobile_content_view", 
        "description": "Mobile App Content Views" 
    },
    { 
        "name": "app_custom_event.fb_mobile_initiated_checkout", 
        "description": "Mobile App Checkouts" 
    },
    { 
        "name": "app_custom_event.fb_mobile_level_achieved", 
        "description": "Mobile App Achievements" 
    },
    { 
        "name": "app_custom_event.fb_mobile_purchase", 
        "description": "Mobile App Purchases" 
    },
    { 
        "name": "app_custom_event.fb_mobile_rate", 
        "description": "Mobile App Ratings" 
    },
    { 
        "name": "app_custom_event.fb_mobile_search", 
        "description": "Mobile App Searches" 
    },
    { 
        "name": "app_custom_event.fb_mobile_spent_credits", 
        "description": "Mobile App Credit Spends" 
    },
    { 
        "name": "app_custom_event.fb_mobile_tutorial_completion", 
        "description": "Mobile App Tutorial Completions" 
    },
    { 
        "name": "app_custom_event.other", 
        "description": "Other Mobile App Actions" 
    },
    { 
        "name": "app_install", 
        "description": "App Installs" 
    },
    { 
        "name": "app_use", 
        "description": "App Uses" 
    },
    { 
        "name": "checkin", 
        "description": "Check-ins" 
    },
    { 
        //
        "name": "comment", 
        "description": "Post Comments",
        "post_property": "comments"
    },
    { 
        "name": "credit_spent", 
        "description": "Credit Spends" 
    },
    { 
        "name": "games.plays", 
        "description": "Game Plays" 
    },
    { 
        "name": "landing_page_view", 
        "description": "Landing Page Views" 
    },
    { 
        //
        "name": "like", 
        "description": "Page Likes",
        "post_property": "likes"
    },
    { 
        "name": "link_click", 
        "description": "Link Clicks" 
    },
    { 
        "name": "mobile_app_install", 
        "description": "Mobile App Installs" 
    },
    { 
        "name": "offsite_conversion.custom.<custom_conv_id>", 
        "description": "Custom Conversions defined by the advertiser" 
    },
    { 
        "name": "offsite_conversion.fb_pixel_add_payment_info", 
        "description": "Adds Payment Info" 
    },
    { 
        "name": "offsite_conversion.fb_pixel_add_to_cart", 
        "description": "Adds To Cart" 
    },
    { 
        "name": "offsite_conversion.fb_pixel_add_to_wishlist", 
        "description": "Adds To Wishlist" 
    },
    { 
        "name": "offsite_conversion.fb_pixel_complete_registration", 
        "description": "Completed Registration" 
    },
    { 
        "name": "offsite_conversion.fb_pixel_custom", 
        "description": "Custom pixel events defined by the advertiser" 
    },
    { 
        "name": "offsite_conversion.fb_pixel_initiate_checkout", 
        "description": "Initiates Checkout" 
    },
    { 
        "name": "offsite_conversion.fb_pixel_lead", 
        "description": "Leads" 
    },
    { 
        "name": "offsite_conversion.fb_pixel_purchase", 
        "description": "Purchases" 
    },
    { 
        "name": "offsite_conversion.fb_pixel_search", 
        "description": "Searches" 
    },
    { 
        "name": "offsite_conversion.fb_pixel_view_content", 
        "description": "Views Content" 
    },
    { 
        "name": "onsite_conversion.flow_complete", 
        "description": "On-Facebook Workflow Completions" 
    },
    { 
        "name": "onsite_conversion.messaging_block", 
        "description": "Blocked Messaging Conversations" 
    },
    { 
        "name": "onsite_conversion.messaging_conversation_started_7d", 
        "description": "Messaging Conversations Started" 
    },
    { 
        "name": "onsite_conversion.messaging_first_reply", 
        "description": "New Messaging Conversations" 
    },
    { 
        "name": "onsite_conversion.messaging_user_subscribed", 
        "description": "Messaging Subscriptions" 
    },
    { 
        //
        "name": "onsite_conversion.post_save", 
        "description": "Post Saves", 
        "post_property": "saves"
    },
    { 
        "name": "onsite_conversion.purchase", 
        "description": "On-Facebook Purchases" 
    },
    { 
        "name": "outbound_click", 
        "description": "Outbound Clicks" 
    },
    { 
        "name": "photo_view", 
        "description": "Page Photo Views" 
    },
    { 
        //
        "name": "post", 
        "description": "Post Shares",
        "post_property": "shares"
    },
    { 
        //
        "name": "post_reaction", 
        "description": "Post Reactions", 
        "post_property": "likes"
    },
    { 
        "name": "rsvp", 
        "description": "Event Responses" 
    },
    { 
        //
        "name": "video_view", 
        "description": "3-Second Video Views",
        "post_property": "views"
    },
    { 
        "name": "contact_total", 
        "description": "Contacts" 
    },
    { 
        "name": "contact_website", 
        "description": "Website Contacts" 
    },
    { 
        "name": "contact_mobile_app", 
        "description": "Mobile App Contacts" 
    },
    { 
        "name": "contact_offline", 
        "description": "Offline Contacts" 
    },
    { 
        "name": "customize_product_total", 
        "description": "Products Customized" 
    },
    { 
        "name": "customize_product_website", 
        "description": "Website Products Customized" 
    },
    { 
        "name": "customize_product_mobile_app", 
        "description": "Mobile App Products Customized" 
    },
    { 
        "name": "customize_product_offline", 
        "description": "Offline Products Customized" 
    },
    { 
        "name": "donate_total", 
        "description": "Donations" 
    },
    { 
        "name": "donate_website", 
        "description": "Website Donations" 
    },
    { 
        "name": "donate_on_facebook", 
        "description": "On Facebook Donations" 
    },
    { 
        "name": "donate_mobile_app", 
        "description": "Mobile App Donations" 
    },
    { 
        "name": "donate_offline", 
        "description": "Offline Donations" 
    },
    { 
        "name": "find_location_total", 
        "description": "Location Searches" 
    },
    { 
        "name": "find_location_website", 
        "description": "Website Location Searches" 
    },
    { 
        "name": "find_location_mobile_app", 
        "description": "Mobile App Location Searches" 
    },
    { 
        "name": "find_location_offline", 
        "description": "Offline App Location Searches" 
    },
    { 
        "name": "schedule_total", 
        "description": "Appointments Scheduled" 
    },
    { 
        "name": "schedule_website", 
        "description": "Website Appointments Scheduled" 
    },
    { 
        "name": "schedule_mobile_app", 
        "description": "Mobile App Appointments Scheduled" 
    },
    { 
        "name": "schedule_offline", 
        "description": "Offline App Appointments Scheduled" 
    },
    { 
        "name": "start_trial_total", 
        "description": "Trials Started" 
    },
    { 
        "name": "start_trial_website", 
        "description": "Website Trials Started" 
    },
    { 
        "name": "start_trial_mobile_app", 
        "description": "Mobile App Trials Started" 
    },
    { 
        "name": "start_trial_offline", 
        "description": "Offline Trials Started" 
    },
    { 
        "name": "submit_application_total", 
        "description": "Applications Submitted" 
    },
    { 
        "name": "submit_application_website", 
        "description": "Website Applications Submitted" 
    },
    { 
        "name": "submit_application_mobile_app", 
        "description": "Mobile App Applications Submitted" 
    },
    { 
        "name": "submit_application_offline", 
        "description": "Offline Applications Submitted" 
    },
    { 
        "name": "submit_application_on_facebook", 
        "description": "On Facebook Applications Submitted" 
    },
    { 
        "name": "subscribe_total", 
        "description": "Subscriptions" 
    },
    { 
        "name": "subscribe_website", 
        "description": "Website Subscriptions" 
    },
    { 
        "name": "subscribe_mobile_app", 
        "description": "Mobile App Subscriptions" 
    },
    { 
        "name": "subscribe_offline", 
        "description": "Offline Subscriptions" 
    },
    { 
        "name": "recurring_subscription_payment_total", 
        "description": "Recurring Subscription Payments" 
    },
    { 
        "name": "recurring_subscription_payment_website", 
        "description": "Website Recurring Subscription Payments" 
    },
    { 
        "name": "recurring_subscription_payment_mobile_app", 
        "description": "Mobile App Recurring Subscription Payments" 
    },
    { 
        "name": "recurring_subscription_payment_offline", 
        "description": "Offline Recurring Subscription Payments" 
    },
    { 
        "name": "cancel_subscription_total", 
        "description": "Canceled Subscriptions" 
    },
    { 
        "name": "cancel_subscription_website", 
        "description": "Website Canceled Subscriptions" 
    },
    { 
        "name": "cancel_subscription_mobile_app", 
        "description": "Mobile App Canceled Subscriptions" 
    },
    { 
        "name": "cancel_subscription_offline", 
        "description": "Offline Canceled Subscriptions" 
    },
    { 
        "name": "ad_click_mobile_app", 
        "description": "In-App Ad Clicks" 
    },
    { 
        //
        "name": "ad_impression_mobile_app", 
        "description": "In-App Ad Impressions" 
    },
    { 
        "name": "click_to_call_call_confirm", 
        "description": "Estimated Call Confirmation Clicks" 
    },
    { 
        "name": "click_to_call_native_call_placed", 
        "description": "Calls Placed (Only available in select countries)" 
    },
    { 
        "name": "click_to_call_native_20s_call_connect", 
        "description": "20s Calls Placed (Only available in select countries)" 
    },
    { 
        "name": "click_to_call_native_60s_call_connect", 
        "description": "60s Calls Placed (Only available in select countries)" 
    },
    { 
        //
        "name": "page_engagement", 
        "description": "Page Engagement" 
    },
    { 
        //
        "name": "post_engagement", 
        "description": "Post Engagement" 
    },
    { 
        "name": "onsite_conversion.lead_grouped", 
        "description": "All On-Facebook Leads" 
    },
    { 
        "name": "lead", 
        "description": "All offsite leads plus all On-Facebook leads" 
    },
    { 
        "name": "leadgen_grouped", 
        "description": "On-Facebook leads coming from Messenger and Instant Forms" 
    },
    { 
        "name": "omni_app_install", 
        "description": "App Installs" 
    },
    { 
        "name": "omni_purchase", 
        "description": "Purchases" 
    },
    { 
        "name": "omni_add_to_cart", 
        "description": "Adds to Cart" 
    },
    { 
        "name": "omni_complete_registration", 
        "description": "Registrations Completed" 
    },
    { 
        "name": "omni_view_content", 
        "description": "Content Views" 
    },
    { 
        "name": "omni_search", 
        "description": "Searches" 
    },
    { 
        "name": "omni_initiated_checkout", 
        "description": "Checkouts Initiated" 
    },
    { 
        "name": "omni_achievement_unlocked", 
        "description": "Achievements Unlocked" 
    },
    { 
        "name": "omni_activate_app", 
        "description": "App Activations" 
    },
    { 
        "name": "omni_level_achieved", 
        "description": "Levels Achieved" 
    },
    { 
        "name": "omni_rate", 
        "description": "Ratings Submitted" 
    },
    { 
        "name": "omni_spend_credits", 
        "description": "Credit Spends" 
    },
    { 
        "name": "omni_tutorial_completion", 
        "description": "Tutorials Completed" 
    },
    { 
        "name": "omni_custom", 
        "description": "Custom Events" 
    }
];
<template>
    <base-modal 
        title="Edit Columns"
        :visible="visible"
        @close="$emit('close')"
    >
        <Checkbox
            v-if="columns && columns.length"
            label="Select columns to show for creators"
            :options="columns"
            v-model="selectedColumns"
        />

        <span v-else class="text-purple-m-secondary text-h6">Error loading options</span>

        <div class="pt-3 flex justify-end">
            <base-button @action="submit" :disabled="loading || !creatorColumns" :label="loading ? 'Saving...' : 'Save'"/>
        </div>
    </base-modal>
</template>

<script>
import Checkbox from './Checkbox.vue'
export default {
    props: {
        visible: {
            type: Boolean,
            required: false
        }, 
        passedCreatorColumns: {
            type: Array,
            default: function() {
                return [];
            },
            required: false
        }, 
        passedSelectedCreatorColumns: {
            type: Array,
            default: function() {
                return [];
            },
            required: false
        }, 
        projectId: {
            type: Number,
            required: true
        }
    },
    components: {
        Checkbox
    },
    data() {
        return {
            loading: false,
            columns: [],
            creatorColumns: [],
            selectedColumns: [],
        }
    },
    watch: {
        passedCreatorColumns: {
            deep: true,
            immediate: true,
            handler(newValue) {
                if (newValue == null) {
                    this.getData();
                } else {
                    this.columns = newValue;
                    this.formatColumns();
                }
            },
        },
        passedSelectedCreatorColumns: {
            deep: true,
            immediate: true,
            handler(newValue) {
                if (newValue == null || newValue.length === 0) {
                    this.getSelectedData();
                } else {
                    this.selectedColumns = newValue
                        ? newValue.map((col) => col.creator_column_id)
                        : [];
                }
            },
        },
    },

    mounted(){
        this.getData();
        this.getSelectedData();
    },

    methods: {
        formatColumns() {
            this.columns.forEach((c) => {
                c.value = c.id;
                c.label = c.name;
            });
        },
        async getData(showLoader = true) {

            if ( this.passedCreatorColumns == null ) {
                this.loading = showLoader ? true : false;

                // get all columns
                const { data } = await this.$http.get('/api/companies/creator-columns');            
                this.columns = data;
            } else {
                this.columns = this.passedCreatorColumns;
            }

            // format data            
            this.columns.forEach(c => {
                c.value = c.id,
                c.label = c.name
            });

            this.loading = false;
        },

        async getSelectedData() {
            if ( this.passedSelectedCreatorColumns == null ) {
                const { data } = await this.$http.post(`/api/projects/creator-columns/`, {ids: [this.projectId]});
                this.selectedColumns = data ? data.map(col => col.creator_column_id) : [];

            } else {
                this.selectedColumns = this.passedSelectedCreatorColumns ? this.passedSelectedCreatorColumns.map(col => col.creator_column_id) : [];
            }
        },

        async submit() {
            this.loading = true;
            try {
                let route = !this.projectId ? '/api/companies/creator-columns' : `/api/projects/${this.projectId}/creator-columns`;
                console.log(this.selectedColumns);
                await this.$http.put(route, {                     
                    selectedColumns: this.selectedColumns
                });

                this.$notify({ title: 'Success', text: 'Columns edited successfully', type: 'success' });
                this.$emit('edited-columns', this.selectedColumns);  
                this.$emit('close');
                this.loading = false;

            } catch(error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>
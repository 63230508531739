<template>        
    <div>

        <stats :user="user"/>

        <div class="about-content">
            
            <div class="text-h3 font-bold">About</div>
            
            <FormulateForm
                name="edit-profile-form"
                @submit="submit()"
                class="form"
            >

                <FormulateInput
                    type="email"
                    validation="required"
                    v-model="email"
                    label="Email"
                    placeholder="Email"
                    :disabled="true"
                    validation-name="Email"
                />

                <FormulateInput
                    type="text"
                    validation="required"
                    label="First Name"
                    placeholder="First Name"
                    validation-name="First name"
                    v-model="firstName"
                />

                <FormulateInput
                    type="text"
                    validation="required"
                    v-model="lastName"
                    label="Last Name"
                    placeholder="Last Name"
                    validation-name="Last name"
                />

                <FormulateInput
                    type="text"
                    label="Phone"
                    class="w-full"
                    placeholder="123-456-7890"
                    v-model="phone"
                    @input="handlePhone"
                    label-class="font-bold"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    validation="max:15,length|min:0"
                    error-behavior="live"
                    :validation-messages="{max: 'Phone must be less than 15 numbers.'}"
                />

                <hr class="my-4"/>
                
                <div>
                    <div class="font-bold">What kind of content do you like to make ?</div>
                    <FormulateInput
                        type="textarea"
                        placeholder="What kind of content do you like to make ?"
                        v-model="bio"
                        input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-20"
                        :rows="5"
                    />
                </div>

                <div class="relative gap-y-1">  
                    <div class="font-bold">Birthdate</div>
                    <div class="absolute left-5 top-11">
                        <base-icon name="calendar" :size="5"/>
                    </div>
                    <date-picker
                        v-model="birthdate"
                        inputWrapperClass="border rounded-md py-4 pr-3 pl-16"
                    />
                </div>
                
                <div class="mt-4">
                    <div class="font-bold">Gender</div>
                    <gender-select
                        v-model="gender"
                    />
                </div>

                <base-button
                    :disabled="loading || !validateFields" 
                    @action="$formulate.submit('edit-profile-form')"
                    class="mt-4"
                    submit
                    label="Submit"
                />

            </FormulateForm>

        </div>
    </div>
</template>

<script>
import { isValidDate } from '../../common'
import GenderSelect from '../../components/GenderSelect.vue'
import { validString } from '../../lib/strings'
import Stats from './Stats.vue'
export default {
    props: ['user'],
    components: {
        Stats,
        GenderSelect
    },
    data(){
        return {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            bio: '',
            birthdate: '',
            gender: null,

            loading: false
        }
    },
    mounted(){
        this.setData()
    },
    computed: {
        validateFields(){
            let err = false;
            if(!validString(this.firstName)) err = true;
            if(!validString(this.lastName)) err = true;
            if(typeof this.phone !== 'string') err = true;
            if(typeof this.bio !== 'string') err = true;
            if(!isValidDate(this.birthdate, false)) err = true;
            return !err;
        },
    },
    methods: {
        setData(){
            const { email, first_name, last_name, phone, bio, birthdate, gender } = this.user
            this.email = email
            this.firstName = first_name
            this.lastName = last_name
            this.phone = phone
            this.bio = bio
            this.birthdate = birthdate
            this.gender = gender
        },
        handlePhone() {
            if (this.phone && this.phone !== '') {
                this.phone = this.phone.replace(/\D/g, '').replace(/(\d{3})(\d{1,3})(\d{1,4})/, '$1-$2-$3').replace(/(-\d{4})\d+?$/, '$1')
            }
        },
        async submit(){
            this.loading = true
            try{
                const { data } = await this.$http.put('/api/me', {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    phone: this.phone,
                    bio: this.bio,
                    ...(isValidDate(this.birthdate, false) ? { birthdate: this.birthdate } : {}),
                    birthdate: this.birthdate,
                    gender: this.gender
                });
                if(data){
                    this.$notify({title: 'Success', text: 'Profile updated successfully', type: 'success'});
                    this.$emit('refresh');
                    this.$store.dispatch('auth/getProfileCompletion');
                }
            }catch(err){
                console.log('submit error', err)
                this.$notify({title: 'Something went wrong', text: 'try again later', type: 'error'});
            }finally{
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .about-content{
        @apply px-12 py-10 text-purple-m-main;
        .form{
            @apply mt-10 flex flex-col gap-y-4;
            width: 520px;
        }
    }
</style>
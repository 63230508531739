<template>
    <div>
        <vue-editor 
            :value="value"
            @input="val => $emit('input', val)" 
            placeholder="Brief"
            ref="quillEditor"
            class="min-ql-h"
            id="customVueEditor"
            :editorOptions="{
                modules: {
                    toolbar: {
                        container: [...$customToolbar(), ['upload-brief-attachment']],
                        handlers: {
                            'upload-brief-attachment': () => this.$refs.fileInput.click()
                        }
                    }
                }
            }" 
        />
        <div class="brief-attachment">
            <div v-if="briefAttachment" class="item-container">
                <div class="w-14 h-14 flex justify-center items-center bg-tan-m rounded-lg">
                    <div class="w-8 h-8 flex justify-center items-center border border-gray-300 rounded-full bg-white">
                        <base-icon class="text-purple-m-main" :size="3" name="file" />
                    </div>
                </div>
                <div>
                    {{briefAttachment.name}}
                </div>
                <base-button 
                    @action="$emit('remove-brief-attachment')" 
                    icon="close"
                    type="label" 
                />
            </div>
        </div>

        <!-- invisible input to handle upload brief attachment -->
        <input 
            class="invisible" 
            type="file" 
            ref="fileInput" 
            accept="application/pdf" 
            @change="onFileInputChange"
        >
        <!-- invisible icon to be copied and pasted on the toolbar -->
        <div class="hidden">
            <base-icon 
                ref="icon" 
                name="pdf2"
                :size="5"
            />
        </div>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
    props: ['briefAttachment', 'value'],
    components: { VueEditor },
    mounted(){
        // add svg icon to custom editor toolbar item 
        const icon = this.$refs.icon
        const element = document.querySelector('.ql-upload-brief-attachment')
        if(icon && element){
            element.innerHTML = icon.$el.outerHTML
        }
    },
    methods: {
        onFileInputChange(event){
            this.$emit('upload', event.target.files[0])
        }
    }
}
</script>
<style lang="scss">
    #customVueEditor{
        border-bottom: none !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important; 
    }

    .brief-attachment{
        border: 1px solid #ccc;
        @apply w-full flex items-center rounded-bl-md rounded-br-md border-t-0 px-3 py-4;
        .item-container{
            @apply flex gap-x-4 items-center cursor-pointer;
            &:hover{
                @apply opacity-80;
            }
        }
    }
</style>
<template>
    <FormulateInput type="text" placeholder="Description" v-model="content" @input="emitDescription" />
</template>
<script>
export default {
    props: ['value'],

    data () {
        return {
            content: this.value
        }
    },
    
    methods: {
        emitDescription(e) {
            this.$emit('input', this.content);
        }
    }
}
</script>
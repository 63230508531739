<template>
    <div class="breakdown-tooltip">

      <div class="section">
        <div class="title capitalize">{{property}} breakdown</div>
        <div class="text">Organic {{property}}: <b>{{organic}}</b></div>
        <div class="text">Boosted {{property}}: <span class="green"><b>{{boosted}}</b></span></div>
        <div class="text">Total {{property}}: <b>{{total}}</b></div>              
      </div>

      <div class="section" v-if="adCount">
        <div class="title">{{adCount}} ad{{ adCount !== 1 ? 's' : '' }} analyzed</div>
      </div>

      <div class="section" v-if="interactionsPercentage">
        <div class="text"><span class="capitalize">{{property}}</span> are <b>{{interactionsPercentage}}%</b> of total interactions</div>
      </div>
    
    </div>
</template>

<script>
import { convertNumeral } from '../common'
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    property(){
        return this.value.property
    },
    organic(){
        return convertNumeral(this.value.organic, '0.0a');
    },
    boosted(){
        return convertNumeral(this.value.boosted, '0.0a');
    },
    total(){
        return convertNumeral(this.value.total, '0.0a');
    },
    adCount(){
        return this.value.adCount
    },
    interactionsPercentage(){
        return this.value.interactionsPercentage
    }
  }
}
</script>
<style lang="scss" scoped>
    .breakdown-tooltip{
      @apply absolute left-0 right-0 bg-white border border-black rounded-lg z-50 p-1 py-3;
      @apply flex flex-col gap-y-2;
      bottom: 28px; 
      width: 136px;
      > .section{
        //
        > .title{
          @apply text-pxs font-bold mb-1;
        }
        > .text{
          @apply text-pxs;
        }
      }
    }

    .green{
        @apply text-green-500;
    }
</style>

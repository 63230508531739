import Vue from 'vue';
import axios from 'axios';

export const auth = {
    namespaced: true,
    state: {
        user: JSON.parse(localStorage.getItem('user')) || {},
        token: localStorage.getItem('access_token') || null,
        redirect: null,
        me: {},
        drive: JSON.parse(localStorage.getItem('drive_auth')) || null,
        google: JSON.parse(localStorage.getItem('google_auth')) || null,
        tiktok: JSON.parse(localStorage.getItem('tiktok_auth')) || null,
        isSessionLoaded: false,
        profileCompletion: null
    },
    mutations: {
        setUser(state, val) {
            state.user = val;
            state.token = (val && typeof val.access_token !== 'undefined') ? val.access_token : localStorage.getItem('access_token') || null;
            localStorage.setItem('user', JSON.stringify(val));
            localStorage.setItem('access_token', state.user.access_token);
            Vue.prototype.$http.defaults.headers.common['x-access-token'] = state.user.access_token
        },
        setRedirect(state, val) {
            state.redirect = val;
        },
        setIsSessionLoaded(state, val) {
            state.isSessionLoaded = val;
        },
        setDriveAuth(state, val) {
            localStorage.setItem('drive_auth', JSON.stringify(val));
            state.drive = val;
        },
        setGoogleAuth(state, val) {
            localStorage.setItem('google_auth', JSON.stringify(val));
            state.google = val;
        },
        setTikTokAuth(state, val) {
            localStorage.setItem('tiktok_auth', JSON.stringify(val));
            state.tiktok = val;
        },
        logoutTikTok(state) {
            localStorage.removeItem('tiktok_auth');
            state.tiktok = null;
        },
        logoutDrive(state) {
            localStorage.removeItem('drive_auth');
            state.drive = null;
        },
        logoutGoogle(state) {
            localStorage.removeItem('google_auth');
            state.google = null;
        },
        logout(state) {
            state.user = '';
            state.token = '';
            sessionStorage.clear();
        },
        setMe(state, val){
            state.me = val
        },
        setProfileCompletion(state, val) {
            state.profileCompletion = val;
        },
    },
    actions: {
        async login({ commit }, user) {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('access_token', user.access_token);

            localStorage.removeItem('guest_access_token')
            delete axios.defaults.headers.common['x-guest-access-token'];
            await commit('setUser', user);
        },
        async setRedirect({commit}, path) {
            await commit('setRedirect', path);
        },
        async setUser({commit}, payload) {
            await commit('setUser', payload);
        },
        async setDriveAuth({commit}, payload) {
            await commit('setDriveAuth', payload);
        },
        async setGoogleAuth({commit}, payload) {
            await commit('setGoogleAuth', payload);
        },
        async setTikTokAuth({commit}, payload) {
            await commit('setTikTokAuth', payload);
        },
        async logoutDrive({commit}) {
            await commit('logoutDrive');
        },
        async logoutGoogle({commit}) {
            await commit('logoutGoogle');
        },
        async logoutTikTok({commit}) {
            await commit('logoutTikTok');
        },
        async logout({commit}){
            commit('logout');
            localStorage.removeItem('access_token');
            localStorage.removeItem('user');
            await commit('setMe', {});
            delete axios.defaults.headers.common['x-access-token'];
        },
        async getMe({commit, state}){

            if (state.me && state.me.id) {
                commit('setMe', state.me);
                return;
            }

            return axios.get('/api/me').then(response => {
                if(response.data){
                    commit('setMe', response.data)
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                commit('setIsSessionLoaded', true)
            })
        },
        async getGuestMe({commit}){
            return await axios.get('/api/me/guest').then(response => {
                if(response.data){
                    commit('setMe', response.data)
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                commit('setIsSessionLoaded', true)
            })
        },
        async getProfileCompletion({commit}){
            return await axios.get('/api/me/profile-completion').then(({data}) => {
                if(data){
                    commit('setProfileCompletion', data)
                }
            }).catch(err => {
                console.log('getProfileCompletion error', err)
            })
        }
    },
    modules: {
    },
    getters : {
        isLoggedIn: state => !!state.token
    }
}
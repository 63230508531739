<template>
    <base-modal
        :visible="visible"
        :title="title"
        with-picture
        :modal-title="`${currentUserDeliverable ? 'Edit' : 'Add'} Deliverable`"
        modal-icon="deliverable"
        no-padding
        persistent
        @close="$emit('close')"
    >
        <!-- container -->
        <div class="container">
            
            <!-- content -->
            <div class="content">
                
                <!-- step 1 -->
                <div class="step-1 grid-list" v-if="step === 1">
                    <div
                        class="item py-10"
                        :class="{'selected': isTypeSelected(deliverableType)}"
                        v-for="deliverableType in deliverablesTypes"
                        :key="deliverableType.id"
                        @click="userDeliverable.type = deliverableType"
                    >

                        <div class="check-container" v-if="isTypeSelected(deliverableType)">
                            <base-icon name="check" class="font-bold"/>
                        </div>
                        
                        <base-icon class="icon" :name="deliverableType.slug" :size="10"/>
                        {{ deliverableType.label }}

                        <div class="details-container" v-if="isTypeSelected(deliverableType)">
                            <base-icon name="checkbox-check"/>
                            <div class="text">{{ deliverableType.help }}</div>
                        </div>

                    </div>
                </div>
                
                <!-- step 2 -->
                <div class="step-2" v-if="step === 2">

                    <!-- whether we add a network or not -->
                    <div class="section">
                        <div class="title">¿Is this for a social post?</div>
                        <div class="grid-list grid-cols-2">
                            <div
                                class="item py-10"
                                v-for="(option, index) in [ 
                                    { 
                                        label: 'Social post',
                                        description: 'Platform selection required', 
                                        value: true 
                                    }, 
                                    { 

                                        label: 'UGC / Non-social post', 
                                        description: 'Platform selection not required',
                                        value: false 
                                    } 
                                ]"
                                :key="index"
                                :class="{'selected': userDeliverable.is_social_post == option.value}"
                                @click="onIsSocialPostInput(option.value)"
                            >

                                <div class="check-container" v-if="userDeliverable.is_social_post == option.value">
                                    <base-icon name="check" class="font-bold"/>
                                </div>
                                
                                <base-icon class="icon" name="blog-post" :size="10"/>
                                
                                <div>{{ option.label }}</div>
                                
                                <div class="font-normal text-pxs">{{ option.description }}</div>

                            </div>
                        </div>
                    </div>

                    <!-- influences deliverable completion -->
                    <div class="section" v-if="userDeliverable.is_social_post">
                        <div class="title">¿Is the creator required to post to their social to mark the deliverable as complete?</div>
                        <div class="grid-list grid-cols-2">
                            <div
                                class="item py-10"
                                v-for="(option, index) in [ 
                                    { 
                                        label: 'Social post required',
                                        description: 'Posting required for deliverable completion', 
                                        value: true 
                                    }, 
                                    { 
                                        label: 'Social post not required',
                                        description: 'Posting not required for deliverable completion', 
                                        value: false 
                                    } 
                                ]"
                                :key="index"
                                :class="{'selected': userDeliverable.is_social_post_required == option.value}"
                                @click="userDeliverable.is_social_post_required = option.value"
                            >

                                <div class="check-container" v-if="userDeliverable.is_social_post_required == option.value">
                                    <base-icon name="check" class="font-bold"/>
                                </div>
                                
                                <base-icon class="icon" name="blog-post" :size="10"/>
                                
                                <div>{{ option.label }}</div>

                                <div class="font-normal text-pxs">{{ option.description }}</div>

                            </div>
                        </div>
                    </div>
                    
                </div>

                <!-- step 3 -->
                <div class="step-3" v-if="step === 3">
                    <div class="social-platform">
                        <label>Social platform</label>
                        <custom-select
                            placeholder="Platform"
                            :options="networks"
                            @input="changeNetwork"
                            v-model="userDeliverable.network"
                            :required="true"
                        />
                    </div>

                    <div class="deliverable-select-container" v-if="userDeliverable.network">

                        <div class="label" v-if="deliverablesOptions.length > 1">Please select one the following options:</div>
                        <div class="grid-list" :class="deliverablesOptions.length > 3 ? 'grid-cols-4' : 'grid-cols-3'">
                            <div
                                class="item"
                                :class="[{'selected': isDeliverableSelected(availableDeliverable)},
                                    deliverablesOptions.length > 3 ? 'py-1' : 'py-10'
                                ]"
                                v-for="availableDeliverable in deliverablesOptions"
                                :key="availableDeliverable.id"
                                @click="userDeliverable.deliverable = availableDeliverable"
                            >
                                <div class="check-container" v-if="isDeliverableSelected(availableDeliverable)" >
                                    <base-icon name="check" class="font-bold" v-if="isDeliverableSelected(availableDeliverable)"/>
                                </div>

                                <base-icon :name="availableDeliverable.id" :size="10" class="icon"/>
                                {{ availableDeliverable.label }}
                            
                            </div>
                        </div>

                        <p class="empty-state" v-if="!settingOptions && userDeliverable.network && !deliverablesOptions.length">There are no deliverables available for this platform</p>
                    </div>
                </div>

                <!-- step 4 -->
                <div v-if="step === 4" class="step-4">
                    <FormulateInput
                        type="text"
                        class="col-span-2 w-full"
                        placeholder="Name"
                        label="Activity Name"
                        validation="required"
                        v-model="userDeliverable.name"
                    />

                    <FormulateInput
                        type="textarea"
                        class="col-span-2 w-full"
                        placeholder="Description"
                        label="Description"
                        v-model="userDeliverable.description"
                    />

                    <date-picker
                        label="Start date"
                        :value="userDeliverable.start_date"
                        @change="(date)=>userDeliverable.start_date = date"
                    />

                    <date-picker
                        label="End date"
                        :value="userDeliverable.end_date"
                        @change="(date)=>userDeliverable.end_date = date"
                    />

                    <custom-select
                        :disabled="!userDeliverable.is_social_post_required"
                        :options="[
                            {id: 0, label: '0'},
                            {id: 1, label: '1'},
                            {id: 2, label: '2'},
                            {id: 3, label: '3'},
                            {id: 4, label: '4'},
                        ]"
                        label="Posts Needed"
                        @changed="setPostsNeeded"
                        v-model="userDeliverable.posts_needed_obj"
                    />
                </div>
                
                <!-- step 5 -->
                <div class="step-5" v-if="step === 5">

                    <div class="payment" v-if="!fixedAmount && isUserAdminOrHigher">
                        <FormulateInput
                            type="checkbox"
                            v-model="hasPayment"
                            label="Include payment"
                            class="col-span-2 w-full"
                        />

                        <FormulateInput
                            v-if="hasPayment"
                            type="text"
                            class="w-full"
                            placeholder="0.00"
                            :options="{ prefix: true }"
                            label="Creator Fee"
                            @input="onBudgetInput"
                            v-model="userDeliverable.budget"
                        />
                        <!-- 
                        <FormulateInput
                            v-if="hasPayment"
                            type="text"
                            class="w-full"
                            placeholder="0.00"
                            :options="{ prefix: true }"
                            label="Client Budget"
                            @input="onClientBudgetInput"
                            v-model="userDeliverable.client_budget"
                        /> -->
                    </div>

                    <div class="concept-draft">
                        <FormulateInput
                            type="checkbox"
                            v-model="hasDeliverables"
                            label="Require a Concept and Draft"
                            help="We always recommend requiring concept and draft approval to ensure that they're on the right track as as they go through their content creation journey."
                            help-position="after"
                            class="w-full col-span-2"
                        />
                        <!--<div class="flex items-center w-full" v-for="option in deliverables_options" :key="option.id">
                            <FormulateInput
                                type="checkbox"
                                v-model="userDeliverable.deliverables"
                                :value="option.value"
                                :label="option.label"
                                :help="option.help"
                                help-position="after"
                                class="w-full"
                            />
                        </div>-->
                        <div v-for="(deliverable, i) in deliverables_options" :key="i">
                            <template v-if="Array.isArray(userDeliverable.deliverables) && userDeliverable.deliverables.includes(deliverable.value)">
                                <date-picker
                                    :label="`${deliverable.label} Date`"
                                    :value="userDeliverable[`${deliverable.value}_date`]"
                                    @change="(date)=>userDeliverable[`${deliverable.value}_date`] = date"
                                />
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer">
                <base-button 
                    label="Back" 
                    v-if="step > 1" 
                    @action="back" 
                    type="secondary" 
                    size="sm"
                />
                <base-button 
                    :label="nextLabel" 
                    @action="next" 
                    :disabled="!allowContinue(false) || submitting" 
                    :size="btnSize"
                />
            </div>
        </div>
    </base-modal>
</template>

<script>

import moment from 'moment'
import { formatBudget, budgetToDouble } from '../lib/strings'
import { mapGetters } from 'vuex'
import { notifyCatchError } from '../common'
export default {
    props: ['networks', 'projectUserId', 'currentUserDeliverable', 'visible', 'fixedAmount', 'initialStep'],
    components: {

    },
    computed: {
        ...mapGetters(['isAdminOrHigher', 'isUserAdminOrHigher']),
        nextLabel () {
            switch (this.step) {
                case 1:
                case 2:
                case 3:
                case 4:
                    return 'Next';
                case 5:
                    if (this.submitting) {
                        return 'Saving Deliverable...';
                    } else if (!this.currentUserDeliverable) {
                        return 'Add Deliverable';
                    } else {
                        return 'Save';
                    }
            }

            return 'Next';
        },
        btnSize () {
            return this.step === 5 && (this.submitting || !this.currentUserDeliverable) ? 'lg' : 'sm';
        },
        title () {
            switch (this.step) {
                case 1:
                    return 'Select Deliverable Type';
                case 2:
                    return 'Social Post'
                case 3:
                    return 'Select Post Type';
                case 5:
                    return 'Final Steps';
            }
            return !this.currentUserDeliverable ? 'Add Deliverable' : 'Edit Deliverable';
        },
        availableDeliverables () {
            if(this.userDeliverable.network){
                return this.deliverables.filter(deliverable => {
                    return deliverable.network_id === this.userDeliverable.network.id;
                }).map(deliverable => {
                    return {
                        value: deliverable.id,
                        id: deliverable.slug,
                        help: deliverable.description,
                        label: deliverable.name,
                    };
                });
            } else {
                return [];
            }
        },
    },
    data() {
        return {
            step: 1,
            submitting: false,
            deliverablesTypes: [],
            deliverables: [],
            hasPayment: true,
            deliverablesOptions: [],
            deliverables_options: [
                {
                    value: 'concept_approval',
                    label: 'Concept',
                    id: 'concept_approval',
                    help: 'Concept needs to be approved'
                },
                {
                    value: 'post_approval',
                    label: 'Draft',
                    id: 'post_approval',
                    help: 'Draft needs to be approved'
                }
            ],
            userDeliverable: {
                name: '',
                type: null,
                description: '',
                budget: '',
                client_budget: '',
                start_date: '',
                end_date: '',
                deliverables: ['concept_approval', 'post_approval'],
                network: null,
                deliverable: null,
                concept_approval_date: '',
                post_approval_date: '',
                posts_needed: 1,
                posts_needed_obj: {
                    id: 1, label: 1
                },
                is_social_post: false,
                is_social_post_required: false
            },
            deliverableCustomSelectKey: 0, // for custom select re-render
            hasDeliverables: true,
            settingOptions: true
        }
    },
    watch: {

        'userDeliverable.network': {
            deep: true,
            handler: function (val, old) {
                if (val !== old) {
                    this.changeNetwork(val);
                }
            }
        },
        'userDeliverable.name': {
            deep: true,
            handler: function (val, old) {
                if(this.userDeliverable.name) return; 

                let replace = !old ? true : this.userDeliverable.name === old;

                if (val && replace) {
                    this.userDeliverable.name = val;
                }
            }
        },
        'userDeliverable.deliverable': {
            deep: true,
            handler: function (val, old) {
                if(this.userDeliverable.name) return;
                let replace = !old ? true : this.userDeliverable.name === old.label;

                if (val && replace) {
                    this.userDeliverable.name = val.label;
                }
            }
        },
        'currentUserDeliverable': {
            handler: function (val) {
                this.clearForm();
                if(val != null) {
                    this.prepareUserDeliverable(val);
                }
            }
        },
        hasDeliverables: function (val) {
            let values = ['concept_approval', 'post_approval'];
            if (!val) {
                values = [];
            }
            this.userDeliverable.deliverables = values;
        }
    },
    async mounted () {
        this.getDeliverablesTypes();
        await this.getDeliverables();

        if (this.currentUserDeliverable) {
            this.prepareUserDeliverable(this.currentUserDeliverable);
        }

        // users/collaborators are not able to see budget info
        if(!this.isAdminOrHigher){
            this.hasPayment = false
        }
    },
    methods: {
        changeNetwork(val) {
            let options = [];
            this.settingOptions = true;
            if (val) {
                options = this.deliverables.filter(deliverable => {
                    return deliverable.network_id === val.id;
                }).map(deliverable => {
                    return {
                        value: deliverable.id,
                        id: deliverable.slug,
                        help: deliverable.description,
                        label: deliverable.name,
                    };
                });

                this.deliverablesOptions = options;
            }

            if(!this.userDeliverable.deliverable){
                this.userDeliverable.deliverable = options.length === 1 ? options[0] : null;
            }
            if (this.currentUserDeliverable) {
                let currentOption = options.find(op => op.value == this.currentUserDeliverable.deliverable.id);
                if (currentOption) {
                    this.userDeliverable.deliverable = currentOption;
                }
            }
            this.deliverableCustomSelectKey += 1;
            this.settingOptions = false;
            this.$forceUpdate();
        },
        setPostsNeeded() {
            this.userDeliverable.posts_needed = parseInt(this.userDeliverable.posts_needed_obj.id);
        },
        isTypeSelected(deliverableType) {
            if (this.userDeliverable.type) {
                let { id, value } = this.userDeliverable.type;
                return id === deliverableType.id || value === deliverableType.value;
            }
            return false;
        },
        isDeliverableSelected(availableDeliverable) {
            return this.userDeliverable.deliverable && this.userDeliverable.deliverable.id === availableDeliverable.id;
        },
        async getDeliverablesTypes() {
            const { data } = await this.$http.get('/api/userDeliverables/deliverablesTypes');
            this.deliverablesTypes = data.map(type => {
                type.value = type.id;
                type.id = type.slug;
                return type;
            });
        },
        async getDeliverables() {
            const { data } = await this.$http.get('/api/userDeliverables/deliverables');
            this.deliverables = data;
        },
        // only when editing
        prepareUserDeliverable(deliverable) {
            this.userDeliverable = JSON.parse(JSON.stringify(deliverable));
            this.hasDeliverables = this.userDeliverable.deliverables.length > 0;
            this.hasPayment = this.isAdminOrHigher && deliverable.budget > 0;
            
            this.userDeliverable.posts_needed_obj = {id: this.userDeliverable.posts_needed, label: this.userDeliverable.posts_needed.toString()};
            this.userDeliverable.is_social_post = deliverable.is_social_post;
            this.userDeliverable.is_social_post_required = deliverable.is_social_post_required;

            // handle null network
            let network = this.networks.find(network => {
                return network.id === deliverable.network_id;
            });

            this.userDeliverable.network = network ? network : null;
            this.userDeliverable.concept_approval_date = deliverable.concept_approval;
            this.userDeliverable.post_approval_date = deliverable.post_approval;
            this.userDeliverable.deliverable = this.deliverables.find(element => {
                return element.value === deliverable.deliverable.id;
            });

            this.userDeliverable.help = this.userDeliverable.description;

            if (this.initialStep) {
                this.step = this.initialStep;
            }
        },
        back () {
            if (this.step > 1) {
                if(this.step == 4 && !this.userDeliverable.is_social_post){
                    this.step = 2
                }else{
                    this.step--;
                }
            }
        },
        async next() {
            if (await this.allowContinue()) {
                if (this.step === 2) {
                    
                    if(!this.userDeliverable.is_social_post_required){
                        // if social posting is not required, then posts_needed has to be 0
                        this.userDeliverable.posts_needed_obj = { id: 0, label: '0' };
                        this.userDeliverable.posts_needed = 0;
                    }
                    if(!this.userDeliverable.is_social_post){

                        // auto select user generated content deliverable and custom network
                        const deliverable = this.deliverables.find(element => element.slug == 'user-generated-content')
                        const network = this.networks.find(element => element.name == 'custom')
                        if(deliverable){
                            // use the same format as other deliverables
                            this.userDeliverable.deliverable = { 
                                value: deliverable.id,
                                id: deliverable.slug,
                                help: deliverable.description,
                                label: deliverable.name,
                            }
                            this.userDeliverable.network = network
                            
                            // if deliverable name is not set yet, set it
                            if(!this.userDeliverable.name || this.userDeliverable.name == ''){
                                this.userDeliverable.name = `Non social ${this.userDeliverable.type.label}`
                            }
                        }
                        this.step = 4
                    }else{
                        this.step++
                    }
                } else if (this.step === 5) {
                    this.submitForm();
                } else {
                    if(await this.validate()){
                        this.step++;
                    }
                }
            }
        },
        async submitForm() {
            this.submitting = true;
            let payload = {...this.userDeliverable};
            if (this.userDeliverable.deliverable.id) {
                let payment = this.isAdminOrHigher && !this.hasPayment ? 0 : payload.budget;
                payload.type = payload.type.value;
                payload.network_id = (payload.network && payload.network.id) ? payload.network.id : null;
                payload.deliverable_id = payload.deliverable.value;
                payload.projectUserId = this.projectUserId;
                payload.budget = budgetToDouble(payment);
                payload.client_budget = budgetToDouble(payload.client_budget)
                payload.concept_approval_date = payload.concept_approval_date != '' ? payload.concept_approval_date : null
                payload.post_approval_date = payload.post_approval_date != '' ? payload.post_approval_date : null
                let endpoint = '/api/userDeliverables/create';
                let creating = true;
                if (this.currentUserDeliverable && this.currentUserDeliverable.id) {
                    endpoint = `/api/userDeliverables/update/${this.currentUserDeliverable.id}`;
                    creating = false;
                }
                try{
                    const { data } = await this.$http.post(endpoint, payload);
                    if (data) {
                        if(creating){
                            this.$notify({ title: 'Success', text: 'User Deliverable created successfully', type: 'success' })
                        }else{
                            this.$notify({ title: 'Success', text: 'User Deliverable updated successfully', type: 'success' })
                        }
                        this.$emit('user-deliverable-created', data);
                        this.clearForm();
                        this.$emit('close');
                    } else {
                        this.$notify({ title: 'There was a problem.', text: 'Try again later', type: 'error' })
                    }
                }catch(err){
                    notifyCatchError(err, this.$notify);
                }finally{
                    this.submitting = false;
                }
            } else {
                this.$notify({ title: 'Warning', text: 'Please, select a deliverable', type: 'warn' })
                this.submitting = false;
            }
        },
        onBudgetInput(val){
            this.userDeliverable.budget = formatBudget(val)
        },
        onClientBudgetInput(val){
            this.userDeliverable.client_budget = formatBudget(val)
        },
        clearForm() {
            this.step = 1;

            this.userDeliverable.name= '';
            this.userDeliverable.type= null;
            this.userDeliverable.description= '';
            this.userDeliverable.budget= '';
            this.userDeliverable.client_budget= '';
            this.userDeliverable.start_date= '';
            this.userDeliverable.end_date= '';
            this.userDeliverable.deliverables= ['concept_approval', 'post_approval'];
            this.userDeliverable.network= null;
            this.userDeliverable.deliverable= null;
            this.userDeliverable.concept_approval_date= '';
            this.userDeliverable.post_approval_date= '';
            this.hasDeliverables = true;

        },
        allowContinue(showAlert = true) {
            let required = [];
            let mistakes = 0;
            if (this.step === 1) {
                if (!this.userDeliverable.type) {
                    mistakes++;
                }
            else if (this.step === 2) {
                //
            }  
            } else if (this.step === 3) {
                // if you selected is_social_post and you are on step 3 you will be required to add a network
                required = ['type', 'network', 'deliverable']; // { index: 'deliverable_id', type: 'array' }];
            } else if (this.step === 4) {
                required = ['name'];
            } else if (this.step === 5) {
                if (this.hasPayment && !this.fixedAmount) {
                    required = required.concat(['budget']);
                }

                for (let i in this.deliverables_options) {
                    if (Array.isArray(this.userDeliverable.deliverables) && this.userDeliverable.deliverables.includes(this.deliverables_options[i].id)) {
                        // required.push(this.deliverables_options[i].id + '_date');
                    }
                }
            }
            for (let i in required) {
                if (typeof required[i] === 'object' && required[i] !== null) {
                    if (required[i].type === 'array') {
                        if (!this.userDeliverable[required[i].index].length) {
                            mistakes++;
                        }
                    }
                } else {
                    let item = this.userDeliverable[required[i]];
                    if (item === undefined || item === null || item === '') {
                        mistakes++;
                    }
                }
            }
            if (mistakes && showAlert) {
                this.$notify({ title: 'Warning', text: 'Please, review the data', type: 'warn' })
            }
            return !mistakes;
        },
        onIsSocialPostInput(val){
            this.userDeliverable.is_social_post = val
            if(!val){
                // uncheck isSocialPostRequired
                this.userDeliverable.is_social_post_required = false
                // return to default
                this.userDeliverable.network = null
            }
        },
        async validate(){
            let msg = null
            if (this.step === 4 && moment(this.userDeliverable.start_date) >= moment(this.userDeliverable.end_date)) msg = 'End date cannot be before start date'
            if(msg){
                this.$notify({ title: 'Warning', text: msg, type: 'warn' })
                return false;
            }
            return true;
        },
    }
}
</script>

<style lang="scss" scoped>
    .container{
        @apply flex flex-col justify-between;
        .content{
            @apply pt-5 pb-20 px-14;
            .step-1{
                @apply grid-cols-3;
            }
            .step-2{
                @apply flex flex-col gap-y-8;
                min-height: 360px;
                .section{
                    .title{
                        @apply font-bold mb-2;
                    }
                }
            }
            .step-3{
                @apply flex flex-col gap-6;
                .social-platform{
                    @apply w-full flex flex-col gap-2 rounded-sm;
                    & label{
                        @apply font-bold;
                    }
                }
                .deliverable-select-container{
                    .label{
                        @apply mb-4 text-sm;
                    }
                    .empty-state{
                        @apply text-center text-red-600;
                    }
                }
            }
            .step-4{
                @apply grid grid-cols-2 gap-4;
            }
            .step-5{
                @apply flex flex-col gap-6;
                .payment{
                    @apply grid grid-cols-2 gap-4;
                }
                .concept-draft{
                    @apply grid grid-cols-2 gap-4 mt-4;
                }
            }
        }
        .footer{
            @apply flex justify-end gap-3 bg-white rounded-br-2xl py-3 px-14;
            @media (min-width: 640px) {
                @apply flex;
            }
        }
    }

    // applies to step 1 and 3
    .grid-list{
        @apply grid gap-6;
        > .item {
            @apply bg-white text-purple-m-main w-full text-center justify-center items-center relative px-4 font-bold rounded cursor-pointer flex flex-col gap-2;
            > .check-container{
                @apply absolute top-0 right-0 bg-green-m-main rounded-bl rounded-tr w-8 h-8 flex items-center justify-center text-gradient-m-main-start;
            }
            > .icon {
                @apply text-gradient-m-main-start;
            }
            > .details-container{
                @apply absolute px-2 pb-2 bottom-0 left-0 flex gap-1 w-full font-normal text-xs;
                > .text{
                    @apply text-left leading-none;
                }
            }

            &.selected, &:hover {
                @apply text-white bg-gradient-to-b from-gradient-m-main-start to-gradient-m-main-end;
                .icon {
                    @apply text-green-m-main;
                }
            }
        }
    }
</style>
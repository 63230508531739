<template>
    <div class="container" :class="isReport ? 'w-full' : ''">

        <div class="content relative" :class="{'rounded-xl' : isReport}">
            <div class="flex px-4 absolute top-1 inset-x-0 gap-1 z-80 bg-transparent justify-center items-center" v-if="isReport && files.length > 1">
                <div v-for="(media, i) in files" class="bg-gray-400 bg-opacity-60 h-1 pt-1 mt-0.5 rounded relative" :style="'width: ' + (100 / files.length) + '%'" :key="'post_' + i">
                    <div class="bg-white h-1 rounded absolute top-0 inset-x-0" v-if="page >= i"></div>
                </div>
            </div>
            <media-viewer
                :classes="{'inherit': true}" 
                :file="files[page]"
                :is-report="isReport"
                :viewOnly="viewOnly"
                @set-fail="$emit('set-fail')"
            />
            <!-- navigation buttons -->
            <div v-if="page > 0" class="navigation-container left">
                <div @click="$emit('navigate', 'prev')" class="button">
                    <base-icon name="chevron-left" />
                </div>
            </div>
            <div v-if="page < files.length-1" class="navigation-container right">
                <div @click="$emit('navigate', 'next')" class="button">
                    <base-icon name="chevron-right" />
                </div>
            </div>
        </div>

        <!-- posted by posted at -->
        <div class="flex gap-x-2 items-center" v-if="!isReport">
            <avatar
                size="medium"
                :user="postedBy"
            />
            <div class="flex flex-col gap-y-1">
                <div class="text-h6 font-bold">{{postedBy.name}}</div>
                <div class="text-pxs text-gray-m-dark">{{postedAt}}</div>
            </div>
        </div>
        <div class="text-ps text-gray-m-dark">{{description}}</div>

        <div class="flex items-center stat-wrapper w-full" v-if="isReport && postItem">
            <report-post-card-detail inline v-if="postItem.type.name !== 'story' && postItem.likes > 0" :value="postItem.likes | numeral('0.0a')" :icon="postItem.network.name === 'youtube' ? 'youtube-like' : 'post-like'" tooltip="Likes" :bold="false"/>
            <report-post-card-detail inline v-if="postItem.network.name === 'youtube' && postItem.dislikes > 0" :value="postItem.dislikes | numeral('0.0a')" icon="dislike" tooltip="Dislikes" :bold="false"/>
            <report-post-card-detail inline v-if="postItem.type.name !== 'story' && postItem.comments > 0" :value="postItem.comments | numeral('0.0a')" icon="post-comments" tooltip="Comments" :bold="false"/>
            <report-post-card-detail inline v-if="postItem.profile_item.total_followers > 0" :value="postItem.profile_item.total_followers | numeral('0.0a')" icon="post-reach" tooltip="Followers" :bold="false"/>
            <report-post-card-detail inline v-if="postItem.views > 0" :value="postItem.views | numeral('0.0a')" icon="post-views" tooltip="Views" :bold="false"/>
        </div>

        <!-- edit button -->
        <base-button
            v-if="!isReport"
            theme="dark" 
            size="sm"
            secondary-icon="pencil" 
            label="Edit"
            @action="$emit('edit')" 
            class="absolute top-2 right-2 text-pxs font-bold gap-x-1" 
        />
    </div>

</template>

<script>
import Avatar from '../../../../components/Avatar.vue'
import MediaViewer from '../MediaViewer.vue'
import ReportPostCardDetail from '../../../../components/ReportPostCardDetail';
export default {
    components: { 
        Avatar,
        MediaViewer,
        ReportPostCardDetail
    },
    props: ['files', 'description', 'postedBy', 'postedAt', 'page', 'viewOnly', 'isReport', 'postItem', 'printView'],
}
</script>
<style lang="scss" scoped>
    .container{
        @apply relative flex flex-col gap-y-4 items-start justify-start;
        width: 274px;
    }
    .container.w-full {
        width: 100%;
    }
    .content{
        height:300px;
        align-items: stretch;
        flex-direction: column;
        position: relative;
        background-color: rgba(239,239,239,1);
        display: block;
        width: 100%;
    }
    .navigation-container{
        position: absolute;
        bottom: 0px;
        top: 0px;
        display: flex;
        align-items: center;            

        &.left{
            left: 16px;
        }

        &.right{
            right: 16px;
        }

        & .button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #bbbbbd;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            cursor: pointer;
            box-shadow: #a3a3a3 -1px 0px 13px 0px;
        }
    }
</style>
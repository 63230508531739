var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"flex-1 relative z-0 overflow-y-auto focus:outline-none",attrs:{"tabindex":"0"}},[_c('div',{staticClass:"flex flex-col gap-y-6 px-12 pt-10 bg-tan-m"},[_c('div',{staticClass:"flex items-center gap-x-4 "},[_c('div',{staticClass:"inline-flex items-center justify-center rounded-full bg-purple-m-main w-15 h-15"},[_c('base-icon',{staticClass:"text-green-m-main",attrs:{"name":"deliverable-check","size":6}})],1),_c('div',{staticClass:"text-h2 font-bold text-purple-m-secondary"},[_vm._v("New Deliverables")])]),_c('tabs',{attrs:{"tabs":['New', 'Copy-from-existing']},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}})],1),_c(_vm.tab.toLowerCase(),{tag:"component"}),_vm._m(0),_c('div',{staticClass:"creators-assigned"},[_c('base-table',{key:_vm.creatorsKey,staticClass:"px-12 py-10",attrs:{"columns":[
                {
                    name: 'name',
                    label: 'Creator',
                    type: 'avatar-list',
                    value: function (element) {
                        return [element]
                    }
                },
                {
                    name: 'projects',
                    label: 'Active Projects',
                    value: function (element) {
                        return element.activeProjects;
                    }
                },
                _vm.company.company_creator_columns.find(function (col) { return col.creator_column_id === 6; }) ? {
                    name: 'user_tags',
                    label: 'Tags',
                    type: 'user-tags',
                    value: function (element) {
                        return element.user_tags
                    },
                    searchBy: _vm.customUserTagsSearchBy,
                    filter: {
                        options: _vm.tags.map(function (tag) {return { label: tag.name, value: tag.slug }}),
                        placeholder: 'Tags',
                        type: 'select',
                        allSelectedPlaceholder: 'All Tags'
                    },
                } : {},
                _vm.company.company_creator_columns.find(function (col) { return col.creator_column_id === 5; }) ? {
                    name: 'email',
                    label: 'Email',
                    type: 'text',
                    value: function (element) {
                        return element.email ? element.email : '-'
                    }
                } : {},
                _vm.company.company_creator_columns.find(function (col) { return col.creator_column_id === 4; }) ? {
                    name: 'phone',
                    label: 'Phone',
                    type: 'text',
                    value: function (element) {
                        return element.phone ? element.phone : '-'
                    }
                } : {},
                _vm.company.company_creator_columns.find(function (col) { return col.creator_column_id === 7; }) ? {
                    name: 'links',
                    label: 'Links',
                    type: 'networks',
                    value: function (element) {
                        return element.social_accounts
                    },
                    options: {
                        stopPropagation: true
                    }
                } : {},
                _vm.company.company_creator_columns.find(function (col) { return col.creator_column_id === 8; }) ? {
                    name: 'intagram-followers',
                    label: 'Instagram Followers',
                    type: 'number',
                    value: function (element) {
                        return _vm.instagramFollowersValue(element)
                    }
                } : {},
                _vm.company.company_creator_columns.find(function (col) { return col.creator_column_id === 9; }) ? {
                    name: 'tiktok-followers',
                    label: 'TikTok Followers',
                    type: 'number',
                    value: function (element) {
                        return _vm.tiktokFollowersValue(element)
                    }
                } : {},
                _vm.company.company_creator_columns.find(function (col) { return col.creator_column_id === 1; }) ? {
                    name: 'city',
                    label: 'City',
                    type: 'text',
                    value: function (element) {
                        return element.audience_data && element.audience_data[0] && element.audience_data[0].location && element.audience_data[0].location.city ? element.audience_data[0].location.city.name : '-';
                    }
                } : {},
                _vm.company.company_creator_columns.find(function (col) { return col.creator_column_id === 2; }) ? {
                    name: 'state',
                    label: 'State',
                    type: 'text',
                    value: function (element) {
                        return element.audience_data && element.audience_data[0] && element.audience_data[0].location && element.audience_data[0].location.state ? element.audience_data[0].location.state.name : '-';
                    }
                } : {},
                _vm.company.company_creator_columns.find(function (col) { return col.creator_column_id === 3; }) ? {
                    name: 'country',
                    label: 'Country',
                    type: 'text',
                    value: function (element) {
                        return element.audience_data && element.audience_data[0] && element.audience_data[0].location && element.audience_data[0].location.country ? element.audience_data[0].location.country.name : '-';
                    }
                } : {} ].filter(
                function (obj) { return !(obj && Object.keys(obj).length === 0 && obj.constructor === Object); }
            ),"data":_vm.creators,"onRowClick":function (element) {
                if(element.user_projects.length){
                    var found = element.user_projects.find(function (element) {
                        return (element.project && element.project.slug)
                    })
                    if(found){
                        this$1.$router.push(("/projects/" + (found.project.slug) + "/creator/" + (element.id)));
                    }
                }
                else {
                    this$1.$router.push(("/creator/" + (element.id)));
                }
            },"loading":_vm.loading,"page":_vm.currentPage,"per-page":_vm.perPage,"total":_vm.total,"default-filters":_vm.filters,"overflow":""},on:{"changePage":_vm.changePage,"reload-filters":_vm.changeFilters}})],1),_c('div',{staticClass:"footer"},[_c('base-button',{staticClass:"bg-tan-m",attrs:{"type":"secondary","label":"Discard"},on:{"action":function($event){return _vm.$router.back()}}}),_c('base-button',{attrs:{"submit":"","label":"Save"},on:{"action":function($event){return _vm.submit()}}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-h2 font-bold text-purple-m-secondary px-12 py-10"},[_vm._v(" Creators Assigned "),_c('span',{staticClass:"text-gray-m-disable"},[_vm._v("(0)")])])}]

export { render, staticRenderFns }
<template>
    <div class="brands">

        <div class="title">What brands do you like ?</div>

        <div class="header">
            <FormulateInput
                type="text"
                placeholder="Search brands..."
                v-model="term"
                style="width: 320px"
                v-debounce="(inputValue, e) => clearbitSearch(inputValue)"
            />
            <div class="count">
                <div>({{val.length}}) brands selected</div>
            </div>
            <div class="buttons">
                <base-button 
                    label="Clear all" 
                    type="label" 
                    icon="trash" 
                    class="text-pxs gap-x-1" 
                    icon-size="4"
                    :disabled="!val.length"
                    @action="clearAll()" 
                />
                <base-button 
                    label="View selected" 
                    type="label" 
                    icon="eye" 
                    class="text-pxs gap-x-1" 
                    icon-size="4" 
                    :disabled="!val.length"
                    @action="selectedBrandsModalVisible = true" 
                />
            </div>
        </div>

        <div class="list">
            <div class="item" :class="{ 'selected': isSelected(brand) }" v-for="(brand, index) in brands" :key="index" @click="toggleBrand(brand)">
                <img class="logo" :src="brand.logo" />
                <div class="name">{{brand.name}}</div>
                <div class="domain">{{brand.domain}}</div>
            </div>
        </div>

        <div class="actions">
            <base-button label="Next" size="xl" class="font-bold" @action="$emit('next')" />
            <base-button label="Back" type="label" @action="$emit('back')" />
        </div>

        <selected-brands-modal
            :visible="selectedBrandsModalVisible"
            :value="val"
            @close="selectedBrandsModalVisible = false"
            @remove-brand="brand => toggleBrand(brand)"
        />

    </div>
</template>
<script>
import SelectedBrandsModal from './SelectedBrandsModal.vue'
export default {
    components: {
        SelectedBrandsModal
    },
    props: ['value'],
    data(){
        return {
            val: this.value,
            term: '',
            brands: [],
            selectedBrandsModalVisible: false,
            loading: false
        }
    },
    methods: {
        async clearbitSearch(inputValue){
            if(!inputValue || inputValue.length < 4){
                return false
            }
            this.loading = true
            this.$http.get(`/api/clearbit/companies?name=${inputValue}`).then(({data}) => {
                this.brands = data
            }).catch(err => {
                console.log("error on clearbit search", err);
            }).finally(() => {
                this.loading = false
            });
        },
        toggleBrand(brand){
            if(this.isSelected(brand)){
                this.val = this.val.filter(element => element.domain !== brand.domain)
            }else{
                this.val.push(brand)
            }
            this.$emit('input', this.val)
        },
        isSelected(brand){
            const found = this.val.find(element => element.domain === brand.domain)
            if(found){
                return true
            }else{
                return false 
            }
        },
        clearAll(){
            this.val = []
            this.$emit('input', this.val)
        },
    }
}
</script>
<style lang="scss" scoped>
    .brands{
        @apply h-full flex flex-col justify-start items-center;
        .title{
            @apply text-h3 font-bold mb-6;
        }
        .header{
            width: 320px;
            .count{
                @apply w-full text-right;
            }
            .buttons{
                @apply mt-2 flex justify-between items-center;
            }
        }
        .list{
            @apply flex justify-start items-start flex-wrap gap-6 mt-6;
            .item{
                width: 150px;
                height: 150px;
                @apply bg-white border border-2 border-gray-300 rounded-lg p-2;
                @apply flex flex-col justify-center items-center;
                @apply cursor-pointer transition-colors duration-500;
                &:hover{
                    @apply bg-gray-200;
                }
                &.selected{
                    @apply border-purple-m-main;
                }
                .logo{
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }
                .name{
                    @apply font-bold text-h5 text-center mt-2;
                    width: 100%;
                    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
                }
                .domain{
                    @apply text-pxs text-center;
                    width: 100%;
                    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
                }
            }
        }
        .actions{
            @apply mt-10 flex flex-col items-center gap-y-6;
        }
    }
</style>

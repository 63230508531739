<template>
    <div>
        
        <Header title="Instagram Accounts" :count="accounts.length" :loading="loading" />
        
        <div class="accounts-list">
            <div v-if="!accounts.length">No accounts were found</div>
            <div class="item" v-for="(account, index) in accounts" :key="index">
                <div class="left">
                    <div class="account">
                        <img class="profile-picture" :src="account.profile_pic" />
                        <div class="info">
                            <div>ID: {{account.id}}</div>
                            <div>Username: {{account.username}}</div>
                        </div>
                    </div>
                    <div class="linked-user" v-if="account.user">
                        <base-icon name="link"/>
                        <div class="user">
                            <avatar-list :users="[account.user]" />
                        </div>
                    </div>
                </div>
                <div class="right">
                    <base-button
                        label="View permissions"
                        @action="businessManagerRedirect(account)"
                        size="lg"
                    />
                </div>
            </div>
        </div>
 
    </div>
</template>

<script>
import Header from './Header.vue'
import AvatarList from '../../../components/AvatarList.vue'
import { mapGetters } from 'vuex';
export default {
    components: {
        Header,
        AvatarList
    },
    data(){
        return {
            accounts: [],
            loading: false
        }
    },
    mounted() {
        this.getAccounts()
    },
    computed: {
        ...mapGetters(['company', 'fbLogin']),
    },
    methods: {
        async getAccounts(){
            this.loading = true
            this.$http.get('/api/facebook/business-ig-accounts', {
                params: {
                    fb_business_id: this.company.fb_business_id
                }
            }).then(async (res) => {
                if(res.data){
                    this.accounts = res.data
                }
            }).catch((err) => {
                console.log('getAccounts error', err)
            }).finally(() => {
                this.loading = false
            })
        },
        businessManagerRedirect(account){
            window.open(`https://business.facebook.com/settings/instagram-account-v2s/${account.id}?business_id=${this.company.fb_business_id}`, '_blank')
        }
    }
}
</script>

<style lang="scss" scoped>
    .accounts-list{
        @apply flex flex-col mt-6 gap-y-4 items-start;
        .item{
            @apply w-full flex justify-between px-6 py-2 bg-tan-m rounded-xl;
            .left{
                @apply flex gap-x-4;
                .account{
                    @apply flex items-center gap-x-4;
                    .profile-picture{
                        @apply rounded-full h-10 w-10;
                    }
                    .info{
                        @apply flex flex-col justify-center;
                    }
                }
                .linked-user{
                    @apply border-l-2 border-black pl-4;
                    @apply flex items-center gap-x-4;
                }
            }

        }
    }
</style>
import { instagramFollowers, tiktokFollowers, audienceDataLocation } from '../../lib/user'

const columns = [
    {
        id: null,
        label: 'Creator',
        value: (creator) => {
            return creator.email
        }
    },
    {
        name: null,
        label: 'Active Projects',
        value: (creator) => {
            return creator.activeProjects
        }
    },
    {
        id: null,
        label: 'Completed Deliverables',
        value: (creator) => {
            return creator.completedDeliverables
        }
    },
    {
        id: 6,
        label: 'Tags',
        value: (creator) => {
            return tagsValue(creator)
        }
    },
    {
        id: 5,
        label: 'Email',
        value: (creator) => {
            return creator.email ? creator.email : '-'
        }
    },
    {
        id: 4,
        label: 'Phone',
        value: (creator) => {
            return creator.phone ? creator.phone : '-'
        }
    },
    {
        id: 7,
        label: 'Links',
        value: (creator) => {                        
            return linksValue(creator)
        }
    },
    {
        id: 8,
        label: 'Instagram Followers',       
        value: (creator) => {
            return instagramFollowersValue(creator)
        }
    },
    {
        id: 9,
        label: 'TikTok Followers',
        value: (creator) => {                        
            return tiktokFollowersValue(creator)
        }
    },
    {
        id: 1,
        label: 'City',
        value: (creator) => {
            return cityValue(creator)
        }
    },
    {
        id: 2,
        label: 'State',
        value: (creator) => {
            return stateValue(creator)
        }
    },
    {
        id: 3,
        label: 'Country',
         value: (creator) => {
            return countryValue(creator)
        }
    },
    {
        id: 11,
        label: 'Agent',
        value: (creator) => {
            return `"${creator.parent_users.length ? creator.parent_users.map(user => `${user.first_name} ${user.last_name}`).join(', ') : '-'}"`
        }
    },
    {
        id: 12,
        label: 'Agent Email',
        value: (creator) => {                        
            return `"${creator.parent_users.length ? creator.parent_users.map(user => user.email).join(', ') : '-'}"`
        }
    },
    {
        id: 13,
        label: 'Agent Phone',
        value: (creator) => {
            return `"${creator.parent_users.length ? creator.parent_users.map(user => user.phone).join(', ') : '-'}"`
        }
    }
]

function tagsValue(creator){
    return `"${creator.user_tags.length ? creator.user_tags.map(user_tag => user_tag.tag.name).join(', ') : '-'}"`
}

function linksValue(creator){
    return `"${creator.social_accounts.length ? creator.social_accounts.map(socialAccount => {
        return `https://${socialAccount.network.name}.com/${socialAccount.handle}`
    }).join(', ') : '-'}"`
}

function instagramFollowersValue(creator){
    return instagramFollowers(creator)
}

function tiktokFollowersValue(creator){
    return tiktokFollowers(creator)
}

function cityValue(creator){
    let arr = []
    const igValue = audienceDataLocation(creator, 'instagram', 'city')
    const tiktokValue = audienceDataLocation(creator, 'tiktok', 'city')
    if(igValue) arr.push({ network: 'Instagram', value: igValue })
    if(tiktokValue) arr.push({ network: 'Tiktok', value: tiktokValue })
    return `"${arr.length ? arr.map(element => `${element.network}: ${element.value}`).join() : '-'}"`
}

function stateValue(creator){
    let arr = []
    const igValue = audienceDataLocation(creator, 'instagram', 'state')
    const tiktokValue = audienceDataLocation(creator, 'tiktok', 'state')
    if(igValue) arr.push({ network: 'Instagram', value: igValue })
    if(tiktokValue) arr.push({ network: 'Tiktok', value: tiktokValue })
    return `"${arr.length ? arr.map(element => `${element.network}: ${element.value}`).join() : '-'}"`
}

function countryValue(creator){
    let arr = []
    const igValue = audienceDataLocation(creator, 'instagram', 'country')
    const tiktokValue = audienceDataLocation(creator, 'tiktok', 'country')
    if(igValue) arr.push({ network: 'Instagram', value: igValue })
    if(tiktokValue) arr.push({ network: 'Tiktok', value: tiktokValue })
    return `"${arr.length ? arr.map(element => `${element.network}: ${element.value}`).join() : '-'}"`  
}

export default columns
<template>
<div :class="['alert-wrapper', type || 'warning']">
    <div class="flex">
        <div class="flex-shrink-0 icon-wrapper">
            <slot name="icon" />
        </div>
        <div class="ml-3">
            <p class="text-sm text-wrapper">
                <slot />
            </p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['type']
}
</script>

<style lang="scss">
.alert-wrapper {
    @apply border-l-4 p-4;

    svg {
        @apply h-6 w-6;
    }

    &.warning {
        @apply border-yellow-400 bg-yellow-50;

        .icon-wrapper {
            @apply text-yellow-400;
        }

        .text-wrapper {
            @apply text-yellow-700;
        }
    }

    &.danger {
        @apply border-red-400 bg-red-50;

        .icon-wrapper {
            @apply text-red-400;
        }

        .text-wrapper {
            @apply text-red-700;
        }
    }

    &.info {
        @apply border-blue-400 bg-blue-50;

        .icon-wrapper {
            @apply text-blue-400;
        }

        .text-wrapper {
            @apply text-blue-700;
        }
    }

    &.success {
        @apply border-green-400 bg-green-50;

        .icon-wrapper {
            @apply text-green-400;
        }

        .text-wrapper {
            @apply text-green-700;
        }
    }
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"network-selector"},[_c('div',{staticClass:"content"},[(_vm.connectionStatus)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ 
                content: _vm.connectionStatus.connected_message ? _vm.connectionStatus.connected_message : ("Not connected" + (!_vm.readonly ? ", click to invite" : "")), 
                classes: 'v-tooltip-black', 
                delay: { show: 100, hide: 50 } 
            }),expression:"{ \n                content: connectionStatus.connected_message ? connectionStatus.connected_message : `Not connected${!readonly ? `, click to invite` : ``}`, \n                classes: 'v-tooltip-black', \n                delay: { show: 100, hide: 50 } \n            }"}],staticClass:"connection-status",class:{
                'readonly': _vm.readonly,
                'connected': _vm.connectionStatus.is_connected, 
                'not-connected': !_vm.connectionStatus.is_connected
            }},[_c('div',{staticClass:"invite-button",on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.sendInviteModalVisible = true; })($event)}}},[_vm._v(" Invite "),_c('base-icon',{attrs:{"name":"mail","size":3}})],1),_c('base-icon',{staticClass:"exclamation-icon",attrs:{"name":_vm.connectionStatus.is_connected ? 'check' : 'exclamation',"size":3}})],1):_vm._e(),(!_vm.readonly)?_c('div',{class:['edit-button-container', { 'readonly': _vm.readonly }],on:{"click":function($event){_vm.readonly ? null : _vm.toggleDropdown()}}},[_c('base-icon',{attrs:{"name":"pencil","size":3},on:{"action":function (){ return null; }}})],1):_vm._e(),(_vm.isValidObject(_vm.value))?_c('div',{class:['icon-container', { 'readonly': _vm.readonly }],on:{"click":function($event){_vm.readonly ? null : _vm.toggleDropdown()}}},[_c('base-icon',{attrs:{"name":_vm.value.name}})],1):_c('div',[_vm._v("No network")]),(_vm.dropdownVisible)?_c('div',{staticClass:"dropdown"},_vm._l((_vm.networkOptions),function(network,index){return _c('div',{key:index,class:['item', { 'selected': _vm.isSelected(network) }],on:{"click":function($event){return _vm.onItemClick(network)}}},[_c('base-icon',{attrs:{"name":network.name}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(network.display_name))])],1)}),0):_vm._e(),(_vm.user && _vm.value)?_c('send-invite-modal',{attrs:{"visible":_vm.sendInviteModalVisible,"networkLabel":_vm.value.display_name == 'Instagram' ? 'Facebook' : _vm.value.display_name,"networkId":_vm.value.id == 2 ? 6 : _vm.value.id,"user":_vm.user},on:{"close":function($event){_vm.sendInviteModalVisible = false}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
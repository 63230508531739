<template>
    <div v-if="followerAudience[index]">
        <!-- header -->
        <div class="header">
            <div class="title" v-if="!hideTitle">{{ titles[index] }}</div>
            <div class="w-full"></div>
            <!-- "all" tab -->
            <div 
                class="tab" 
                :class="{'font-bold' : tab === 'all'}" 
                v-if="!hideOptionsAll.includes(index)" 
                @click="selectTab('all')"
            >
                All
            </div>
            <!-- other tabs tabs -->
            <div 
                class="tab" 
                :class="{'font-bold' : tab === op_index}" 
                v-for="(op, op_index) in customTabs" 
                :key="op_index" 
                @click="selectTab(op_index)"
            >
                <template v-if="category[op_index]">{{ titles[op_index] }}</template>
            </div>
        </div>

        <!-- content -->
        <div 
            class="content"
            :class="{'show-all-items' : showAllItems}"
        >
            <!-- other tab content -->
            <div 
                class="item" 
                :class="op_index" v-for="(op, op_index) in customTabs" 
                :key="op_index"
            >
                <div class="space-y-2 A" v-if="category[op_index] && tab === op_index && tab !== 'all'">
                    <!-- Length {{ computedValues[op_index].length}} -->
                    <bar-chart-item 
                        v-for="(item, i) in computedValues[op_index]"
                        :show-all-items="showAllItems" 
                        :item="item" 
                        :key="i" 
                    />
                </div>
            </div>

            <!-- all tab content -->
            <div 
                class="item" 
                v-if="tab === 'all'"
            >
                <div class="space-y-2 B" >
                    <!-- Length {{getFollowerAudience(index, null).length}} -->
                    <bar-chart-item 
                        v-for="(item, i) in getFollowerAudience(index, null)"
                        :item="item" 
                        :key="i" 
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { sumArray, sortArray, copyObject } from '../common'
    export default {
        props: {
            titles: {}, 
            index: {},
            hideOptionsAll: {},
            category: {},
            followerAudience: {},
            hideTitle: {
                type: Boolean,
                default: false
            },
            limit: {
                type: Number,
                default: 7
            }
        },

        data () {
            return {
                tab: 'all',
            }
        },

        beforeMount() {
            this.setFirstOption();
        },

        computed: {
            // limit is set to 0 in just 1 instance of this component (ReportUserCardStats.vue)
            // all other instances dont have the "limit" prop defined,
            // resulting in a default limit of 7 for most of them
            showAllItems () {
                return !this.limit;
            },
            customTabs () {
                let tabs = {};
                for (let cat in this.category) {
                    let hasItems = false;
                    if (this.index === 'audience_genders_per_age') {
                        hasItems = this.followerAudience[this.index] != undefined;
                    } else {
                        hasItems = this.followerAudience[this.index] ? this.followerAudience[this.index][cat] != undefined : null;
                    }
                    if (hasItems) {
                        tabs[cat] = this.category[cat];
                    }
                }
                return tabs;
            },
            computedItems() {
                let elements = {};
                for (let op_index in this.customTabs) {
                    let data = this.followerAudience[this.index] ? this.followerAudience[this.index][op_index] : null;

                    let items = [];
                    for(let item of data) {
                        let index = items.findIndex(i => i.name == item.name);
                        if (index > -1) {
                            let total = items[index].weight + item.weight;
                            items[index] = {name: item.name, weight: total};
                        } else {
                            items.push(item);
                        }
                    }

                    let arrItems = sortArray(items, 'weight', 'desc');
                    elements[op_index] = arrItems;
                    
                }

                return elements;
            },
            computedModified() {
                let elements = {};
                for (let op_index in this.computedItems) {
                    let data = this.computedItems[op_index];
                    // This code is commented because we are counting the entire array before slice;
                    /* let total = sumArray(data.map(element => element.weight)); // sum total weight
                    data = data.map(element => {
                        element.weight = element.weight / total;
                        return element;
                    }); */

                    elements[op_index] = data;
                }

                return elements;
            },
            computedValues() {
                let dataItems = {};
                for (let op_index in this.customTabs) {
                    if (this.computedModified[op_index] !== undefined) {
                        let arrItems = copyObject(this.computedModified[op_index]);
                        if (this.limit) {
                            arrItems = arrItems.slice(0, this.limit) // get first limit by prop
                        }

                        dataItems[op_index] = arrItems;
                    }
                }
                return dataItems;
            }
        },

        methods: {
            setFirstOption() {
                if (this.hideOptionsAll.includes(this.index)) {
                    for (let cat in this.category) {
                        if (this.category[cat] && this.getFollowerAudience(this.index, cat) !== undefined) {
                            this.tab = cat;
                            return;
                        }
                    }
                }
            },
            selectTab(tab) {
                this.tab = tab;
                this.$forceUpdate();
            },
            getFollowerAudience(index, op_index) {
                if (index === 'audience_genders_per_age') {
                    return this.followerAudience[index].map((item) => {
                        item.weight = this.tab === 'all' ? (item.male + item.female) : (this.tab === 'male' ? item.male : item.female);
                        return item;
                    });
                }
                let arr = this.followerAudience[index] ? copyObject(this.followerAudience[index][op_index]) : null
                if (arr) {
                    arr = sortArray(arr, 'weight', 'desc'); // sort by weight
                    if (this.limit) {
                        arr = arr.slice(0, this.limit) // get first limit by prop
                    }
                    let total = sumArray(arr.map(element => element.weight)); // sum total weight
                    // re calculate weights with only these 7 elements
                    arr = arr.map(element => {
                        element.weight = element.weight / total 
                        return element
                    });
                    return arr;
                }
                return null
            }
        }
    };
</script>
<style lang="scss" scoped>
    .header{
        @apply flex gap-x-1 mb-3;
        .title{
            @apply text-sm font-bold capitalize;
        }
        .tab{
            @apply cursor-pointer;
        }
    }
    .content{
        @apply mb-5;
        &.show-all-items{
            @apply max-h-96 overflow-y-auto pr-4;
        }
        > .item{
            @apply mt-3;
            @media (min-width: 768px) { 
                @apply w-full;
            }

        }
    }
</style>

<template>
  <div class="post-report-wrapper mx-auto md:w-4/5 md:pt-10 pt-6 min-h-full">
    <story-viewer
      v-if="currentStories.length"
      :current-stories="currentStories"
      :start="start"
      full-screen
      @close="closeStories"
    />
    <div class="mx-auto text-left">
      <div class="md:flex justify-between">
        <div class="text-center md:flex">
          <div class="md:text-left text-center flex flex-col px-4 space-y-3">
            <p class="text-2xl font-bold text-black" v-if="!report.brand">
              {{ report.name }}
            </p>
            <p class="text-2xl font-bold text-black" v-else>
              <img :src="report.brand.logo" class="w-24"/>
            </p>
            <div class="text-sm font-medium text-gray-m-disable description-list md:flex">
              <div class="description-item">
                <div class="flex items-center mt-2 mb-4 mr-4">
                  <div class="label md:text-left text-center enriched-format" v-html="report.description"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="px-4 font-bold">Our Ambassadors</div>
      <div class="m-4 flex flex-shrink flex-grow flex-wrap content-center p-4 sm:p-0 overflow-x-scroll transparent-track">
        <div class="flex flex-nowrap gap-2">
          <div v-for="(user, u_key) in report.influencers" :key="u_key" class="flex w-20">
            <div class="cursor-pointer">
              <div class="w-22 h-22 rounded-full border-4" :style="userReportStyle">
                <img :src="userPicture(user)" class="w-15 border-2 rounded-full" alt="Profile Picture"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="px-4 font-bold">Explore more</div>
      <div class="p-4 mb-6">
        <div class="flex w-full justify-center" :class="report.posts.length ? 'pt-10' : ''" v-if="showMasonry">
          <div v-if="report.posts.length" :key="masonryItemsKey" v-masonry item-selector=".post-card" fit-width="true" transition-duration="0.3s" gutter="16" horizontal-order="true">
            <BrandPostCard
              v-masonry-tile
              class="post-card mb-4"
              :post="post"
              v-for="(post, post_key) in report.posts" :key="post.id"
              @open="openMedia(post_key)"
              @redraw="redrawPosts()"
            />
          </div>
          <div v-if="!report.posts.length" class="my-6 text-center">There are no content for this report yet.</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const defaultProfile = require('../../assets/images/default-profile.jpg');
import BrandPostCard from '../../components/BrandPostCard';
import StoryViewer from './StoryViewer';

export default {
  components: {
    BrandPostCard,
    StoryViewer
  },

  props: ['reportData', 'user', 'mediaValues'],

  data() {
    return {
      report: {
        posts: []
      },
      showMasonry: true,
      currentStories: [],
      start: 0,
      storyInterval: null,
      hideStories: true,
      masonryItemsKey: false
    }
  },

  computed: {
    userReportStyle() {
      return {borderColor: this.report.brand ? this.report.brand.emphasis_color : 'unser'};
    },
  },

  mounted() {
    this.$parent.isLoggedIn = false;
    this.setData();
  },

  methods: {
    closeStories() {
      this.currentStories = [];
    },

    userPicture(user) {
      let payload = this.userPayload(user);
      let src = user ? payload.user_profile.picture : null;
      let isTiktok = src.includes('tiktokcdn');
      if (this.printView && isTiktok) {
        return defaultProfile;
      }
      return src;
    },

    userPayload(user) {
      let igAccount = user.audience_data.find(audience => {
        return audience.network_id === 2;
      });

      return igAccount ? igAccount.payload : user.audience_data[0].payload;
    },

    redrawPosts(){
      setTimeout(() => {
        this.$redrawVueMasonry();
      }, 500);
    },

    openMedia(index) {
      this.start = index;
      this.currentStories = this.report.posts;
    },

    async getData() {
        const { data } = await this.$http.get(`/api/reports/posts/${this.$route.params.id}`);
        this.report = data;
    },

    async setData() {
      this.report = this.reportData;
    },
  }
}
</script>

<style lang="scss">
  .influencers .influencers-container {
    max-height: 37rem;
  }

  .img-story:before {
    @apply bg-black opacity-80 w-screen h-screen flex fixed inset-0 z-50;
    content: " ";
  }

  .profile-user-story {
    background: linear-gradient(to right, red, purple);
  }
</style>

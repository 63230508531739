<template>
    <base-button
        v-if="ready && route !== ''"
        icon="tiktok"
        bold
        class="mt-6 w-full"
        type="secondary"
        theme="dark"
        border
        :disabled="disabled || loading"
        label="Sign in with TikTok"
        @action="login"
    />
</template>
<script>
import { decryptStr } from '@/lib/strings';
import { mapGetters } from 'vuex';

export default {
    props: {
        targetUserId: {
            type: Number,
            required: false,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'Sign in with Tiktok'
        }
    },

    data() {
        return {
            passphrase: 'tiktok',
            loading: false,
            ready: false,
            route: '',
            appId: '',
            appRedirectUri: ''
        }
    },

    computed: {
        ...mapGetters(['tiktokAuth']),
    },

    beforeMount() {
        this.getCredentials();
    },

    methods: {
        // get the credentials and set them for when the user clicks the button
        async getCredentials() {
            const { data } = await this.$http.get(`/api/auth/get-auth-credentials/tiktok`);

            this.appId = decryptStr(data.TIKTOK_CLIENT_KEY, this.passphrase);
            this.appRedirectUri = decryptStr(data.TIKTOK_REDIRECT_URI, this.passphrase);

            if (this.appId !== '' && this.appRedirectUri) {
                // let encodedRedirection = encodeURI(this.appRedirectUri);
                let scope = 'user.info.basic,user.info.profile,user.info.stats,video.list';
                this.route = `https://www.tiktok.com/v2/auth/authorize/?client_key=${this.appId}&scope=${scope}&response_type=code&redirect_uri=${this.appRedirectUri}&state=${window.location.origin}`;
                this.ready = true;
            }
        },

        async login() {
            let win = window.open(this.route, "", "width=600,height=800");
            // wait for the window to close, if it does, get the token from localstorage
            var timer = setInterval(async () => { 
                if (win.closed) {
                    clearInterval(timer);
                    await this.setTokenFromLocal();
                }
            }, 1000);
        },

        // save the token on the vuex store
        async setTokenFromLocal() {
            let token = JSON.parse(localStorage.getItem('tiktok_auth')) || null;
            if (token) {
                await this.$store.dispatch('auth/setTikTokAuth', token);
                this.checkTiktok(token);
            }
        },

        // if the code exists, make a request that will generate the access token,
        // and the needed info to create a social login for tiktok
        async checkTiktok(code) {
            if (code) {
                this.$emit('loading', true);
                this.loading = true
                try {
                    const {data} = await this.$http.post(`/api/auth/tiktok/login`, {code, target_user_id: this.targetUserId});
                    let {email, first_name, last_name, picture, user_id, access_token} = data;
                    if (data) {
                        this.$emit('success', {
                            email,
                            first_name,
                            last_name,
                            picture,
                            userId: user_id,
                            accessToken: access_token,
                            networkId: 14
                        })
                    }
                } catch (e) {
                    if (e && e.response && e.response.data && e.response.data.message) {
                        this.$notify({ type: 'error', title: e.response.data.message });
                    }
                    this.$emit('error', e);
                } finally {
                    this.loading = false;
                    this.$emit('loading', false);
                }
            }
        }

    }
}
</script>

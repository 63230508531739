<template>
    <main class="project-detail-main">

        <div class="project-detail-wrapper">

            <Header 
                v-model="viewTab"
                :project="project"
                :loading="loading"
                :isProjectOwner="isProjectOwner()"
                :isDeleted="isDeleted"
                :totalReach="totalReach"
                :budgetUsed="budgetUsed"
                :endsAt="endsAt"
                :totalCost="totalCost"
                :totalCreators="totalCreators"
                :total-ad-spend="totalAdSpend"
                :total-ads="totalAds"
                @shareProject="shareProjectModalVisible = true"
                @resetData="resetData()"
            />

            <tab :visible="viewTab == 'Overview'">
                <div class="overview-wrapper space-y-10">
                    <project-deliverables
                        :expanded="expanded"
                        :isStandAlone="true"
                        :project="projectUserDeliverables"
                        :rowData="projectTableRows(projectUserDeliverables)"
                        :loading="loading"
                        :border="false"
                        :passedColumnDataTypes="columnDataTypes"
                        :passedCreatorColumns="creatorColumns"
                        :passedSelectedCreatorColumns="selectedCreatorColumns[projectUserDeliverables.id]"
                        :passedTags="tags"
                        :passedNetworks="networks"
                        @update-deliverable="updateDeliverable"
                        @update-stats="getStats"
                        @refresh-creators="getProjectDeliverables(false)"
                    />
                </div>
            </tab>

            <tab :visible="viewTab === 'Documents'">
                <div class="documents-wrapper">
                    
                    <!-- documents section header -->
                    <div class="header">
                        <div class="actions" v-if="!isOnlyCreator">

                            <!-- google drive buttons -->
                            <template v-if="driveLoaded && !isDriveFolder">
                                <base-button
                                    label="Logout Drive"
                                    v-if="driveLoggedIn"
                                    class="font-bold"
                                    size="lg"
                                    :iconSize="4"
                                    theme="dark"
                                    type="secondary"
                                    icon="drive"
                                    @action="googleDriveLogoutIntent()"
                                />
                                <base-button
                                    v-else
                                    label="Drive Sign In"
                                    class="font-bold"
                                    size="lg"
                                    :iconSize="4"
                                    theme="dark"
                                    type="secondary"
                                    icon="drive"
                                    @action="forceDriveLoginState = true"
                                />
                            </template>

                            <!-- create folder -->
                            <base-button
                                label="Create Folder"
                                v-if="!isDeleted && !isDriveFolder"
                                class="font-bold"
                                size="lg"
                                :iconSize="4"
                                theme="dark"
                                type="secondary"
                                icon="plus"
                                @action="createFolderModalVisible = true"
                            />

                            <!-- upload -->
                            <base-button
                                label="Upload"
                                v-if="!isDeleted"
                                class="font-bold"
                                size="lg"
                                :iconSize="4"
                                theme="dark"
                                type="secondary"
                                icon="plus"
                                @action="handleFileUpload()"
                            />
                        </div>
                    </div>

                    <!-- documents section -->
                    <div class="content-wrapper">
                        <div class="content">
                            <file-grid
                                title="Documents"
                                :key="reportsListContainer"
                                :mimetype="null"
                                exclude-file-type="reports"
                                :project-id="project.id"
                                :is-project-owner="isProjectOwner()"
                                :resource="{ type: 'project', id: project.id }"
                                :disabled="!isAdminOrHigher && (isDeleted || isOnlyCreator)"
                                :folder-id="currentFolderId"
                                @filesChange="onFilesChange"
                                @navigationChange="onNavigationChange"
                                @drive-handle="driveHandle"
                                :force-drive-login-state="forceDriveLoginState"
                                :force-open-file-explorer="forceOpenFileExplorer"
                                :force-file-grid-refresh="forceFileGridRefresh"
                            />
                        </div>
                    </div>
                </div>
            </tab>

            <tab :visible="viewTab === 'Reports'">
                
                <div class="reports-wrapper">
                    
                    <!-- container -->
                    <div class="reports-container" v-if="isUserAdminOrHigher && !isOnlyCreator">

                        <div class="header">
                            <!-- <div></div> -->
                            <div class="actions">
                                <base-button
                                    v-if="!isDeleted"
                                    class="font-bold"
                                    size="lg"
                                    :iconSize="4"
                                    theme="dark"
                                    type="secondary"
                                    icon="plus"
                                    label="Create Folder"
                                    @action="createFolderModalVisible = true"
                                />
                                <base-button
                                    class="font-bold"
                                    :disabled="!isUserAdminOrHigher"
                                    size="lg"
                                    :iconSize="4"
                                    v-if="!isDeleted"
                                    type="secondary"
                                    theme="dark"
                                    icon="plus"
                                    label="Create Report"
                                    v-tooltip="!isUserAdminOrHigher ? { 
                                        content: 'Not allowed to perform this action.', 
                                        classes: 'bg-black text-white rounded py-1 mb-2 mr-16 px-2 text-xs', 
                                        delay: { show: 100, hide: 50 } 
                                    } : null"
                                    @action="selectReportCreationTypeModalVisible = true"
                                />
                            </div>
                        </div>

                        <!-- reports & files section -->
                        <div class="content-wrapper">
                            <div class="content">
                                <file-grid
                                    title="Reports"
                                    mimetype="reports"
                                    exclude-file-type="files"
                                    :allow-to-edit="isUserAdminOrHigher"
                                    :key="reportsListContainer"
                                    :project-id="project.id"
                                    :resource="{ type: 'project', id: project.id }"
                                    :disabled="isDeleted"
                                    :is-project-owner="isProjectOwner()"
                                    @filesChange="onFilesChange"
                                    @navigationChange="onNavigationChange"
                                    :force-file-grid-refresh="forceFileGridRefresh"
                                />
                            </div>
                        </div>
                    </div>
                    <template v-else>
                        <div class="unauthorized-wrapper">
                            <div class="unauthorized-container">
                                <p>You are not authorized to view this page.</p>
                            </div>
                        </div>
                    </template>

                </div>

            </tab>
            
            <tab :visible="viewTab === 'Notes'">
                <comments-tab
                    :project="project"
                />  
            </tab>

            <tab :visible="isOnlyCreator && project.id && viewTab === 'Deliverables'">
                <deliverables-tab
                    :project="project"
                    :deliverables="creatorUserDeliverables"
                />
            </tab>
            
            <template v-if="!isDriveFolder">
                <create-report-modal 
                    :visible="showCreateReportModal"
                    @close="closeReport"
                    @created-report="reportCreated"
                    :projectId="project.id"
                    :folderId="currentFolderId"
                    :type="sharing.type"
                    :key="reportModalKey"
                    @refresh="getData"
                    @update-user="updateUser"
                />

                <create-folder-modal
                    :visible="createFolderModalVisible"
                    :resource="{ type: 'project', id: project.id }"
                    :folderId="currentFolderId"
                    :attachmentType="viewTab == 'Reports' ? 'reports' : null"
                    @close="createFolderModalVisible = false"
                    @created="forceFileGridRefresh = !forceFileGridRefresh"
                />
            </template>

            <share-project-modal
                :visible="shareProjectModalVisible"
                :project="project"
                @close="shareProjectModalVisible = false"
                @success="getData()"
            />

            <select-report-creation-type-modal
                :visible="selectReportCreationTypeModalVisible"
                @close="selectReportCreationTypeModalVisible = false"
                @open-create-report-modal="showCreateReportModal = true"
                @redirect-to-report-import="$router.push(`/reports/import?project=${project.slug}`)"
            />

        </div>
    </main>
</template>

<script>
import CreateReportModal from '@/components/CreateReportModal';
import FileList from '../../components/FileList';
import Dropdown from '../../components/Dropdown.vue';
import OwnerDropdown from '../../components/OwnerDropdown';
import Doughnut from '../../components/_charts/Doughnut';
import AddOwnerDropdown from './AddOwnerDropdown';
import ProjectStatusDropdown from '../../components/ProjectStatusDropdown';
import SectionEmptyState from '../../components/SectionEmptyState.vue';
import OwnersDropdown from '../../components/OwnersDropdown.vue';
import { mapGetters } from 'vuex';
import FileGrid from '../../components/FileGrid/index.vue';
import CreateVersionModal from '../VersionCreate/CreateVersionModal';
import DeliverablesTable from '../../components/DeliverablesTable.vue';
import DeliverablesTableColumnModal from '../../components/DeliverablesTableColumnModal.vue';
import AddInfluencerDeliverableModal from '../../components/AddInfluencerDeliverableModal.vue';
import CreateFolderModal from '../../components/CreateFolderModal.vue';
import AssignCreatorModal from '../../components/AssignCreatorModal.vue';
import EditCreatorsColumnsModal from '../../components/EditCreatorsColumnsModal.vue';
import ShareProjectModal from './ShareProjectModal.vue';
import CommentsTab from './CommentsTab.vue';
import { instagramFollowers, tiktokFollowers } from '../../lib/user'
import LinkPostUserDeliverableModal from '../../components/LinkPostUserDeliverableModal';
import Header from './Header.vue';
import DeliverablesTab from './DeliverablesTab.vue';
import VueScrollTo from 'vue-scrollto';
import ProjectDeliverables from '../../components/ProjectDeliverables.vue';
import { findBestMatch } from '../../lib/strings';
import SelectReportCreationTypeModal from './SelectReportCreationTypeModal.vue';
// import { myGlobalFunction, eventBus } from '../lib/global';

export default {
    components: {
        LinkPostUserDeliverableModal,
        EditCreatorsColumnsModal,
        FileList,
        CreateReportModal,
        Dropdown,
        OwnerDropdown,
        Doughnut,
        AddOwnerDropdown,
        ProjectStatusDropdown,
        SectionEmptyState,
        OwnersDropdown,
        FileGrid,
        DeliverablesTable,
        DeliverablesTableColumnModal,
        AddInfluencerDeliverableModal,
        CreateFolderModal,
        AssignCreatorModal,
        ShareProjectModal,
        CommentsTab,
        Header,
        DeliverablesTab,
        CreateVersionModal,
        ProjectDeliverables,
        SelectReportCreationTypeModal
    },
    data() {
        return {

            expanded: true,

            viewTab: 'Overview',
            showPinnedProjectDropdown: false,
            showCreateReportModal: false,
            selectReportCreationTypeModalVisible: false,
            showProjectSettingsDropdown: false,
            showAddOwnerDropdown: false,
            company: {
                company_creator_columns: [],
            },
            reportsListContainer: 0,
            reportModalKey: false,
            sharing: {
                type: 'influencer',
                enabled: false,
                window: false,
                complete: false,
                options: false,
                reportId: '',
                selected: [],
            },

            creatorUserDeliverables: [],

            project: {
                project_users: [],
                columns: [],
                budget: 0,
                starts_at: null,
                ends_at: null,
                refresh: false
            },
            projectUserDeliverables: {},
            columnDataTypes: [], 
            userColumns: [],
            userColumnsReady: false,
            networks: [],
            creators: [],
            tags: [],                             

            activeTab: 'Files',
            loading: true,

            driveLoaded: false,
            driveLoggedIn: false,
            forceDriveLoginState: null,

            influencersKey: false,
            pinnedUserSelected: null,

            createFolderModalVisible: false,

            forceOpenFileExplorer: false,
            fileGridNavigation: [],
            forceFileGridRefresh: false,
            fileCount: {
                files: 0,
                reports: 0
            },
            deliverableModalKey: false,
            shareProjectModalVisible: false,
            activeSubmissionType: 0,
            selectedCreatorColumns: {},
            creatorColumns: [],
            totalReach: 0,
            totalCost: 0,
            budgetUsed: 0,
            budgetUsedCap: 0,
            totalCreators: 0,
            endsAt: "",
            totalAdSpend: 0,
            totalAds: 0
        }
    },

    computed: {
        ...mapGetters(['me', 'statuses', 'colors', 'isOnlyCreator', 'isUserAdminOrHigher', 'isSuperAdmin', 'isAdmin', 'isAdminOrHigher', 'fbLogin']),

        isDeleted() {
            return this.project.deleted_at !== null && this.project.deleted_at !== undefined;
        },

        tabs() {
            return [
                {
                    name: "Creators",
                    href: "#",
                    selected: true,
                },
                {
                    name: "Facebook Ads",
                    href: "#",
                    selected: false,
                },
                {
                    name: "Logistics",
                    href: "#",
                    selected: false,
                }
            ]
        },        

        users() {
            let users = this.project.project_users;
            return users.map(cu => cu.user);
        },

        computedActiveTab() {
            return this.activeTab.includes('Reports') ? 'reports' : this.activeTab.includes('Files') ? 'files' : null
        },
        
        currentFolderId() {
            let nav = this.fileGridNavigation
            return nav.length > 0 ? nav[nav.length-1].id : null
        },
        currentFolder() {
            let nav = this.fileGridNavigation;
            return nav.length > 0 ? nav[nav.length-1] : null;
        },
        isDriveFolder() {
            return this.currentFolder && this.currentFolder.external_id;
        }
    },

    beforeMount() {
        this.$setTitle('Project Detail');
        this.getTags();
    },

    mounted() {
        if (this.isOnlyCreator) {
            this.viewTab = 'Deliverables';
        }

        if (!this.project.id) {
            this.getData();
        }

        if (this.$route.query.tab) {
            this.viewTab = this.$route.query.tab
        }

        this.getCompany();

        this.getNetworks();

    },

    watch: {
        viewTab(val) {
            if(val == 'Documents' || val == 'Reports'){
                this.fileGridNavigation = []
            }
            this.$router.push({query: {tab: val}})
        }
    },
    
    methods: {

        async getStats() {

            const { data } = await this.$http.get(`/api/projects/get-stats/${this.project.id}`);
            this.totalReach = data.totalReach;
            this.totalCost = data.totalCost;
            this.budgetUsed = data.budgetUsed;
            this.totalCreators = data.totalCreators;
            this.endsAt = data.endsAt;
            this.totalAdSpend = data.totalAdSpend;
            this.totalAds = data.totalAds;

        },

        updateDeliverable(deliverable) {

            const projectUser = this.project.project_users.find(u => u.id === deliverable.project_user_id);

            if (projectUser) {
                const foundDeliverable = projectUser.project_user_deliverables.find(d => d.id === deliverable.id);
                const updatedVal = deliverable.editable_cost.data;

                foundDeliverable.budget = updatedVal;
            }

            this.getStats();
        },

        async getCreatorColumns() {
            const { data } = await this.$http.get(`/api/companies/creator-columns/`);
            for ( const column of data ) {
                this.creatorColumns.push(column);
            }
        },

        async getSelectedCreatorColumns(projects) {

            const ids = projects.map( project => project.id);

            const { data } = await this.$http.post(`/api/projects/creator-columns/`, {ids});
            for ( const column of data ) {
                if ( typeof this.selectedCreatorColumns[column.project_id] == "undefined" ) {
                    this.$set(this.selectedCreatorColumns, [column.project_id], []);
                }

                this.selectedCreatorColumns[column.project_id].push(column);
            }
        },

        async getProjectDeliverables(showLoader = true) {
            this.loading = showLoader;

            if ( this.isOnlyCreator ) {

                this.$http.get(`/api/userDeliverables/creator/${this.project.id}`).then(({data}) => {
                    this.creatorUserDeliverables = data;
                }).catch(err => {
                    console.log('get deliverables error', err)
                });
                
            } else {
                const { data } = await this.$http.get(`/api/projects/new-deliverables?expanded=${this.expanded}&projectId=${this.$route.params.projectId}`);
                this.getCreatorColumns(data);
                this.getSelectedCreatorColumns(data);
                if (data.length) {
                    this.processUserDeliverables(data[0]);
                }
            }

            this.loading = false;
        },

        processUserDeliverables(data) {
            if (this.projectUserDeliverables && this.projectUserDeliverables.rows && this.projectUserDeliverables.rows.length) {
                data.rows.forEach(row => {
                    let previous = this.projectUserDeliverables.rows.find(pud => pud.project_user_id === row.project_user_id);
                    if (!previous) {
                        this.projectUserDeliverables.rows.push(row);
                    }
                });
            } else {
                this.projectUserDeliverables = data;
            }
        },

        projectTableRows(project) {
            return project?.rows || [];
        },
        // Utility function to get a nested field by its path (e.g. "user.first_name")
        getFieldByPath(object, path) {
            const parts = path.split('.');
            let value = object;

            for (let part of parts) {
                if (value === null || value === undefined) {
                    return null;
                }
                value = value[part];
            }

            return value;
        },
        filterRows(rows, query) {
            if (!query) {
                return rows;
            }

            const trimmedQuery = query.trim();

            if (trimmedQuery === '') {
                return rows;
            }

            return rows.filter((row) => {
                const searchFields = [
                    'user.first_name',
                    'user.last_name',
                    'column_values',
                    'project_name',
                    'brand'
                    ];

                return searchFields.some((field) => {
                    const fieldValue = this.getFieldByPath(row, field);

                    if (Array.isArray(fieldValue)) {
                        return fieldValue.some((nestedData) => {
                            if (!nestedData.data) {
                                return false;
                            }

                            const lowerCaseData = nestedData.data.toLowerCase();
                            const lowerCaseQuery = trimmedQuery.toLowerCase();

                            return (
                                lowerCaseData.includes(lowerCaseQuery) ||
                                findBestMatch(lowerCaseData, lowerCaseQuery) > 0.8
                                );
                        });
                    } else {
                        if (!fieldValue) {
                            return false;
                        }

                        const lowerCaseValue = fieldValue.toLowerCase();
                        const lowerCaseQuery = trimmedQuery.toLowerCase();

                        return (
                            lowerCaseValue.includes(lowerCaseQuery) ||
                            findBestMatch(lowerCaseValue, lowerCaseQuery) > 0.8
                            );
                    }
                });
            });
        },

        calcCompletedDeliverables(element) {
            var the_return = '0/0';
            if(element.project_user_deliverables){
                the_return = `${element.project_user_deliverables.reduce((acc, cur) => {
                    if(cur.posts_needed != null && cur.posts_needed > 0 && cur.project_user_deliverable_posts != null) {

                        if (cur.project_user_deliverable_posts && cur.project_user_deliverable_posts.length < cur.posts_needed) {
                            return acc;
                        } else {
                            return acc + 1;
                        }
                    }
                    return acc + 1;
                        
                }, 0)}/${element.project_user_deliverables.length}`;
                
            }

            return the_return;
        },

        async toggleDeliverableStatus(userDeliverable) {
            let projectUser = this.project.project_users.find(u => u.id === userDeliverable.project_user.id);
            if (projectUser) {
                let deliverable = projectUser.userDeliverables.find(d => d.id === userDeliverable.id);
                if (deliverable) {
                    let body = {
                        is_completed: !deliverable.is_completed
                    };
                    try {
                        await this.$http.post(`/api/userDeliverables/${deliverable.id}/toggleManualCompleted`, body);
                        this.$notify({ title: 'Success', text: 'Status updated successfully.', type: 'success' });

                        this.getData();
                    } catch (e) {
                        console.error(e);
                    }
                }
            }    
        },

        driveHandle(auth) {
            this.driveLoaded = true;
            this.driveLoggedIn = auth;
        },

        googleDriveLogoutIntent(){
            this.$swal.fire({
                title: "Are you sure you want to log out of Google Drive ?",
                text: "You will no longer be able to view google drive files in this page",
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Log out',
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.forceDriveLoginState = false;     
                }
            });
        },

        deliverablesExpanded(){
            let expanded = 0
            for (const user of this.project.project_users) {
                if(user.showUserDeliverables || user.showUserDeliverables == true) expanded++ 
            }
            return expanded > 0 ? true : false
        },

        async toggleExpandCollapseDeliverables() {
            let option = this.deliverablesExpanded() ? false : true
            await Promise.all(this.project.project_users.map(async (user) => {
                user.showUserDeliverables = option
            }))
            this.$forceUpdate()
        },

        rendered(){

            const latestViewedProjectUser = localStorage.getItem('latest_viewed_project_user')
            if(latestViewedProjectUser){

                VueScrollTo.scrollTo(`#home-table-container`, 500, {
                    container: 'main',
                    offset: -60
                })

                setTimeout(() => {
                    VueScrollTo.scrollTo(`#tr-${latestViewedProjectUser}`, 500, {
                        container: '#home-table-container',
                        offset: -60,
                        onDone: function(element) {
                            // add focus styles
                            let el = document.getElementById(`tr-${latestViewedProjectUser}`)
                            const classes = 'bg-gray-200'
                            el.classList.add(classes)
                            setTimeout(() => {
                                el.classList.remove(classes)
                            }, 2000)
                            // remove creator from localstorage
                            localStorage.removeItem('latest_viewed_project_user')
                        }
                    })
                }, 500)
            }
        },

        onRowClick(element){
            if(!this.isDeleted){
                localStorage.setItem('latest_viewed_project_user', element.id)
                this.$router.push(`/creator/${element.user.id}?project=${this.project.slug}`)
            }else{
                this.$notify({ title: 'Warning', text: 'Creator is deleted', type: 'warn' });
            }
        },

        onNavigationChange(navigation){
            this.fileGridNavigation = navigation
        },

        async getCompany(){
            await new Promise(resolve => setTimeout(resolve, 500));
            const { data } = await this.$http.get(`/api/companies`)
            this.company = data;
            /* await new Promise(resolve => setTimeout(resolve, 500));
            this.$forceUpdate();    */
        },

        /* setPinnedUserDeliverable(user) {
            this.viewTab = 'Deliverables';
            this.project.refresh = !this.project.refresh;
            this.pinnedUserSelected = user.user.id;
            this.$forceUpdate();
        }, */

        handleFileUpload(){
            this.forceOpenFileExplorer = !this.forceOpenFileExplorer;
        },

        onFilesChange(files, isRoot) {
            let filesCount = 0
            let reportsCount = 0
            let mimetype = this.computedActiveTab === 'reports' ? 'reports' : null

            files.forEach(file => {
                if((file.mimetype == 'link' && file.is_external == 0 ) || file.attachment_type == 'reports'){
                    reportsCount++
                }else{
                    filesCount++
                }
            })
            if(isRoot || mimetype != 'reports'){
                this.fileCount.files = filesCount
            }
            if(isRoot || mimetype == 'reports'){
                this.fileCount.reports = reportsCount
            }
        },

        resetData(load = true) {
            this.getData(load);
            if (load) {
                this.$store.dispatch('statuses/getStatuses');
            }
        },

        async getColumnTypes() {
            // get column types
            const columnTypes = await this.$http.get('/api/userDeliverables/columnTypes');
            this.columnDataTypes = columnTypes.data.map(type => {
                type.value = type.id
                return type;
            });
        },

        async getData(load = true) {
            try {
                
                let {data} = await this.$http.get(`/api/projects/${this.$route.params.projectId}`);
                this.project = data;
                this.project.refresh = false;
                this.getStats();
                this.$forceUpdate();
                this.influencersKey = !this.influencersKey;
                this.$setTitle(`${data.brand} - ${data.name}`);
                this.getColumnTypes();

                await this.getProjectDeliverables(load);
                
            } catch (e) {
                console.error(10);
                console.error(e);
                this.loading = false;
                if (e.response && e.response.data) {
                    this.$notify({title: 'Error', text: e.response.data.message, type: 'error'});
                }
                return null;
            }
        },

        async getNetworks() {
            const { data } = await this.$http.get('/api/userDeliverables/networks');
            this.networks = data.map(network => {
                network.value = network.id;
                network.label = network.display_name;
                return network;
            });
        },

        async getTags(){
            const { data } = await this.$http.get(`/api/tags`)
            this.tags = data
        },
        
        closeReport() {
            this.showCreateReportModal = false;
            this.reportModalKey = !this.reportModalKey;
        },

        updateUser(id, accounts, audiences) {
            let user = this.project.project_users.find(u => u.user_id === id);
            if (user) {
                user.user.social_accounts = accounts;

                for (let audience of audiences) {
                    user.user.audience_data.push(audience);
                }
            }
        },

        reportCreated() {
            this.reportsListContainer = !this.reportsListContainer;
        },

        async duplicateUserDeliverables(payload) {
            payload.projectId = this.project.id;
            await this.$http.post('/api/userDeliverables/duplicate', payload);
        },

        enableSharing(type) {
            this.sharing.enabled = true; 
            this.sharing.options = false;
            this.sharing.type =type;
        },

        isProjectOwner(){
            return !!(this.project.project_owners && this.project.project_owners.find(element => element.user.id === this.me.id));
        },

        shareItemSelected({value}) {
            if (value === 'curate-influencers') {
                this.sharing.enabled = true;
            }
            else if (value === 'curate-posts') {
                this.$router.push(`/projects/${this.project.slug}/reports/create`);
            }
        },

        customUserTagsSearchBy(element) {
            const {user_tags = []} = element;
            let str = ``;
            user_tags.forEach(user_tag => {
                str = str + user_tag.tag.slug + ' '
            });
            return str
        },

        instagramFollowersValue(element) {
            return instagramFollowers(element);
        },

        tiktokFollowersValue(element) {
            return tiktokFollowers(element);
        },

        editUser(projectUser) {
            this.$router.push(`/projects/${this.project.slug}/creator/${projectUser.user.id}/edit`);
        },

        removeCreatorFromProject(projectUser){
            this.$swal.fire({
                title: `Are you sure you want to remove this creator?`,
                text: '',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {                        
                        const { data } = await this.$http.post(`/api/projects/${projectUser.project_id}/remove-user`, {
                            userId: projectUser.user_id
                        });
                        if (data) {
                            this.$store.dispatch('restorer/set', {
                                data: { userId: projectUser.user_id },
                                message: 'Creator removed from project successfully',
                                success: 'Creator restored successfully',
                                failed: 'Creator cannot be restored',
                                route: `/api/projects/${projectUser.project_id}/restore-user`,
                                action: data.show_undo,
                                forceFn: true,
                                fn: (time = 6100) => {
                                    setTimeout(() => {
                                        this.getData()
                                    }, time);
                                }
                            });
                        }
                    } catch(e) {
                        this.$notify({ title: 'Error', text: 'Something went wrong', type: 'error' });
                        console.error(e);
                    }
                }
            });

        }
    }
}
</script>
<style lang="scss" scoped>
    
    .project-detail-main{
        @apply flex-1 relative z-0;
        &:focus{
            @apply outline-none;
        }
        .project-detail-wrapper{
            @apply flex-1 min-w-0 bg-white;
            .overview-wrapper{
                @apply px-11 pb-10 overflow-x-auto;
            }
            .documents-wrapper{
                > .header{
                    @apply flex justify-end px-12 pt-7;
                    > .actions{
                        @apply flex items-center gap-x-4;
                    }
                }
                > .content-wrapper{
                    @apply px-12;
                    > .content{
                        @apply w-full h-full pt-0 overflow-y-auto;
                        max-height: calc(100vh - 340px);
                    }
                }
            }
            .reports-wrapper{
                //
                .reports-container{
                    //
                    > .header{
                        @apply flex justify-end px-12 pt-7;
                        > .actions{
                            @apply flex gap-x-4;
                        }
                    }
                    > .content-wrapper{
                        @apply px-12;
                        > .content{
                            @apply w-full h-full pt-0 overflow-y-auto;
                            max-height: calc(100vh - 340px);
                        }
                    }

                }
                .unauthorized-wrapper{
                    @apply flex;
                    height: calc(100vh - 300px);
                    .unauthorized-container{
                        @apply m-auto flex flex-col items-center;
                        > p{
                            @apply my-auto text-center;
                        }
                    }
                }
            }
        }
    }

</style>
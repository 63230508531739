var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"flex-1 relative z-0 overflow-y-auto focus:outline-none",attrs:{"tabindex":"0"}},[_c('div',{staticClass:"flex w-full py-10 sm:pl-0"},[_c('div',{staticClass:"w-full flex lg:gap-4 sm:gap-1 sm:items-center sm:justify-between"},[_c('base-table',{attrs:{"columns":[
                    {
                        name: 'name',
                        label: 'Name',
                        type: 'link',
                        value: function (report) {
                            return report.name !== '' ? report.name : 'Report without name'
                        }
                    },
                    {
                        name: 'is_default',
                        label: '',
                        sort: false,
                        value: function (report) {
                            return report.is_default ? 'Default' : 'Regular';
                        },
                        filter: {
                            options: [{ label: 'Default', value: 1 }, { label: 'Regular', value: 0 }],
                            placeholder: 'Project type',
                            type: 'select',
                            allSelectedPlaceholder: 'All Project Type'
                        }
                    },
                    {
                        name: 'projects',
                        label: 'Projects',
                        type: 'string',
                        sort: false,
                        value: function (report) {
                            var names = report.projects.map(function (p) { return p.name; });
                            return names.length ? names.join(', ') : '-';
                        }
                    },
                    {
                        name: 'type',
                        label: 'Type',
                        value: function (report) {
                            return report.type_string;
                        },
                        filter: {
                            options: [{ label: 'Creators', value: 1 }, { label: 'Content', value: 2 }, { label: 'Brands', value: 3 }],
                            placeholder: 'Type',
                            type: 'select',
                            allSelectedPlaceholder: 'All Types'
                        }
                    },
                    {
                        name: 'created_at',
                        label: 'Created at',
                        type: 'date',
                        value: function (report) {
                            return report.created_at;
                        }
                    }
                ],"data":_vm.filteredReports,"rowActions":[
                    {
                        label: 'Edit',
                        icon: 'pencil-alt',
                        event:'edit',
                        handler: function (report) {
                            _vm.$router.push(("/reports/" + (report.guid) + "/edit"))
                        }
                    },
                    {
                        label: 'Copy Link',
                        icon: 'link',
                        event: 'copy',
                        handler: function (report) {
                            _vm.copyReportLink(report)
                        }
                    },
                    {
                        label: 'Delete',
                        icon: 'trash',
                        theme: 'cancel',
                        event: 'delete',
                        handler: function (report) {
                            _vm.deleteReport(report)
                        }
                    } ],"onRowClick":function (report) {
                    this$1.$router.push(("/reports/" + (report.guid)))
                },"loading":_vm.loading,"reload":_vm.reload,"page":_vm.currentPage,"per-page":_vm.perPage,"total":_vm.total,"table-id":"reports-table","container-id":"reports-table-container","container-styles":"min-height: calc(100vh - 340px);max-height: calc(100vh - 340px);overflow-y: scroll;width: 95%;margin: 0 auto;overflow-x: hidden;","overflow":"","search-class":"mx-12 sm:mx-8"},on:{"changePage":_vm.changePage,"set-sort":_vm.changeSort,"reload-filters":_vm.setFilters},scopedSlots:_vm._u([{key:"filters-footer",fn:function(){return [_c('div',{staticClass:"my-2 py-0 mb-12 sm:mx-8 rounded-3xl"},[_c('base-button',{staticClass:"font-bold float-right",attrs:{"size":"auto","iconSize":4,"theme":"dark","type":"secondary","label":"Create New Report","icon":"plus"},on:{"action":function($event){return _vm.onCreateNewReport()}}})],1)]},proxy:true}])},[_c('div')])],1)]),_c('create-report-modal',{key:_vm.reportModalKey,attrs:{"visible":_vm.showCreateReportModal,"projectId":null,"influencers":[],"projects":_vm.projects,"type":null},on:{"close":_vm.closeReport,"created-report":_vm.getReports,"refresh":function($event){return _vm.getData(false)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue';
import numeral from 'numeral';
import vueMoment from 'vue-moment';
import vueDebounce from 'vue-debounce';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';

import {copyToClipBoard, editorToolbar, setTitle} from './common';

import Default from "./layouts/Default.vue";
import NoSidebar from "./layouts/NoSidebar.vue";
import Landing from "./layouts/Landing.vue";
import store from "./store";
import axios from "axios";
import VueFormulate from '@braid/vue-formulate';
import FormulateVSelectPlugin from '@cone2875/vue-formulate-select'
import VTooltip from 'v-tooltip';
import Notifications from 'vue-notification'
import Loading from 'vue-loading-overlay';
import VueSweetalert2 from 'vue-sweetalert2';
import VueCarousel from 'vue-carousel';
import {VueMasonryPlugin} from 'vue-masonry';
import BackButton from './components/BackButton';
import BarChartItem from './components/BarChartItem';
import Avatar from './components/Avatar';
import BaseButton from './components/BaseButton';
import BaseIcon from './components/BaseIcon';
import BaseModal from './components/BaseModal';
import BaseSideModal from './components/BaseSideModal';
import BaseTable from './components/BaseTable';
import BaseTableNew from './components/BaseTableNew';
import Comment from './components/Comment';
import Comments from './components/Comments';
import ChartOptions from './components/ChartOptions';
import CrudDropdown from './components/CrudDropdown';
import CustomPills from './components/CustomPills';
import CustomSelect from './components/CustomSelect';
import DetailCard from './components/DetailCard';
import Doughnut from './components/_charts/Doughnut';
import Dropdown from './components/Dropdown';
import FileGrid from './components/FileGrid';
import FixedLoader from './components/FixedLoader';
import OptionsDropdown from './components/OptionsDropdown';
import Loader from './components/Loader';
import AdvancedLoader from './components/AdvancedLoader';
import SectionEmptyState from './components/SectionEmptyState';
import Separator from './components/Separator';
import Skeleton from './components/SkeletonItem';
import Sort from './components/Sort';
import Tabs from './components/Tabs';
import Tab from './components/Tab';
import ToggleButton from './components/ToggleButton';
import DatePicker from './components/DatePicker';
import PasswordModal from './components/PasswordModal';
import VueScreen from 'vue-screen';
import VueScrollTo from 'vue-scrollto'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { getSubDomain, devSubDomain } from './lib/domains'
import { VueEditor } from 'vue2-editor';
import { initFacebookSdk } from './lib/facebook'
import VueHighlightJS from 'vue-highlightjs'
import 'highlight.js/styles/dark.css'


// styles for vue-formulate
// import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss';

Vue.prototype.$http = axios;

const urlParams = new URLSearchParams(window.location.search);

if (urlParams.get('access_token')) {
    localStorage.setItem('access_token', urlParams.get('access_token'));
}

const token = localStorage.getItem('access_token');

if (token) {
    Vue.prototype.$http.defaults.headers.common['x-access-token'] = token;
}
const guestToken = localStorage.getItem('guest_access_token')
if(guestToken){
    Vue.prototype.$http.defaults.headers.common['x-guest-access-token'] = guestToken;
}
let subDomain = getSubDomain()
if (process.env.NODE_ENV === 'production') {
    if(window.location.host.includes("development") || window.location.host.includes("uat")) {
        subDomain = devSubDomain();
        const environment = window.location.host.includes("development") ? 'development' : 'uat';
        Vue.prototype.$http.defaults.baseURL = `https://${(subDomain && subDomain != '') ? `${subDomain}-dot-` : ``}${environment}-dot-platform-api-dot-glassy-tube-622.appspot.com`;
        console.log("SETTING DEV/UAT BASE ENV", `https://${(subDomain && subDomain != '') ? `${subDomain}-dot-` : ``}${environment}-dot-platform-api-dot-glassy-tube-622.appspot.com`);
    }
    else{
        Vue.prototype.$http.defaults.baseURL = `https://${subDomain ? `${subDomain}-dot-` : ``}platform-api-dot-glassy-tube-622.appspot.com`
    }
}else{
    Vue.prototype.$http.defaults.baseURL = `http://${subDomain ? `${subDomain}.` : ``}localhost:3000`;
}

// if(window.location.hostname.toLowerCase().includes('markerly.com')){
//     window.location.replace(`https://creatorsaurus.com${window.location.pathname}`)
// }

Vue.component("default-layout", Default);
Vue.component("no-sidebar-layout", NoSidebar);
Vue.component("landing-layout", Landing);

Vue.component('avatar', Avatar);
Vue.component('back-button', BackButton);
Vue.component('bar-chart-item', BarChartItem);
Vue.component('base-button', BaseButton);
Vue.component('base-icon', BaseIcon);
Vue.component('base-modal', BaseModal);
Vue.component('base-side-modal', BaseSideModal);
Vue.component('base-table', BaseTable);
Vue.component('base-table-new', BaseTableNew);
Vue.component('comment', Comment);
Vue.component('comments', Comments);
Vue.component('chart-options', ChartOptions);
Vue.component('crud-dropdown', CrudDropdown);
Vue.component('custom-pills', CustomPills);
Vue.component('custom-select', CustomSelect);
Vue.component('detail-card', DetailCard);
Vue.component('doughnut', Doughnut);
Vue.component('dropdown', Dropdown);
Vue.component('file-grid', FileGrid);
Vue.component('fixed-loader', FixedLoader);
Vue.component('options-dropdown', OptionsDropdown);
Vue.component('loader', Loader);
Vue.component('advanced-loader', AdvancedLoader);
Vue.component('section-empty-state', SectionEmptyState);
Vue.component('separator', Separator);
Vue.component('skeleton', Skeleton);
Vue.component('sort', Sort);
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);
Vue.component('toggle-button', ToggleButton);
Vue.component('date-picker', DatePicker);
Vue.component('password-modal', PasswordModal);
Vue.component('vue-editor', VueEditor);

Vue.config.productionTip = false

// Formats number with numeraljs
// http://numeraljs.com/
Vue.filter('numeral', (val, format = '0a') => {
    // currency
    if (!val && ['$0,0.00', '$0,0', '$0,0a', '$0,0.0a'].includes(format)) {
        return '$0.00';
    }

    // percentages
    if (!val && ['0.0%', '0.00%'].includes(format)) {
        return '0%';
    }

    // numbers
    if (val < 1000 && ['0,0', '0,0a', '0.0a', '0.0a'].includes(format)) {
        return val;
    }

    // null val, and none of the above formats
    if (!val) {
        return val;
    }

    return numeral(val).format(format);
});

Vue.use(vueDebounce, {
    defaultTime: '500ms'
});

Vue.use(VueFormulate, {
    plugins: [ FormulateVSelectPlugin ],
    classes: {
        /* outer (context) {
            switch (context.classification) {
                case 'text':
                    return 'w-full rounded-md shadow-none'
            }
        }, */
        wrapper (context, baseClasses) {
            let bgWrapper = 'bg-white';
            
            if (context.attrs.disabled !== undefined && context.attrs.disabled) {
                bgWrapper = 'bg-input-disabled';
            }
            
            if(context.type == 'password'){
                return `border rounded-md py-2 px-3 ${bgWrapper} ${context.label == false ? `h-14 flex items-center` : `h-16`}`
            }

            switch (context.type) {
                case 'number':
                case 'date':
                case 'text':
                case 'email':
                case 'password':
                case 'url':
                    return `border rounded-md py-2 px-3 ${bgWrapper} h-16`;
                case 'textarea':
                    return `border rounded-md py-2 px-3 ${bgWrapper}`;
            }

            switch (context.classification) {
                case 'box':
                    return 'flex justify-start items-center'
            }

        },
        element (context, baseClasses) {
            if(context.type == 'text' && context.label == false){
                return 'h-full'
            }
            if(context.type == 'text' && context.options.prefix && baseClasses){
                return `${baseClasses.join(' ')} relative formulate-input-element-prefixed`
            }
            if(context.type == 'password' && context.label == false){
                return `${baseClasses.join(' ')} w-full`
            }
            if(baseClasses){
                return baseClasses.join(' ')
            }
            return ''
        },
        input (context) {
            let bgInput = 'bg-transparent';
            let heightInput = '';
            context.attrs.autocomplete = "off";
            if (context.attrs.disabled !== undefined && context.attrs.disabled) {
                bgInput = 'bg-input-disabled';
            }
            if(context.type == 'text' && context.label == false){
                heightInput = 'h-full'
            }
            switch (context.type) {
                case 'text':
                case 'email':
                case 'password':
                case 'number':
                case 'date':
                case 'url':
                case 'textarea':
                    return `w-full focus:outline-none text-pm text-purple-m-secondary resize-none ${bgInput} ${heightInput} ${context.options.prefix ? `pl-4` : ``}`
            }     
            switch (context.classification) {
                case 'button':
                    return 'inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-500 focus:outline-none focus:border-purple-700 focus:shadow-outline-purple active:bg-purple-700 transition duration-150 ease-in-out'
                case 'select':
                    return 'form-input w-full text-sm py-4'
            }
        },
        label (context) {
            
            switch(context.type) {
                case 'text':
                case 'email':
                case 'password':
                case 'number':
                case 'url':
                case 'date':
                    return 'text-pxs font-bold'
            }

            switch(context.classification) {
                case 'box':
                    return 'ml-4 text-ps text-purple-m-main'
                default: 
                    return 'text-pxs font-bold text-purple-m-main'
            }
        },        
        help (context) {
            switch (context.classification) {
                case 'box':
                    return 'text-xs text-gray-600 pl-0'
                default:
                    return ''//'text-xs mb-1 text-gray-600'
            }
        },
        error: 'text-red-500 text-xs mb-1'
    }
});
Vue.use(vueMoment);
Vue.use(VTooltip);
VTooltip.options.defaultHideOnTargetClick = false;
Vue.use(Notifications);

const swalOptions = {
    focusConfirm: true
};
Vue.use(VueSweetalert2, swalOptions);
Vue.use(Loading, {
    color: '#000000',
    width: 64,
    height: 64,
    loader: 'spinner',
    backgroundColor: '#ffffff',
    opacity: 0.5,
    zIndex: 999
});
Vue.use(VueCarousel);
Vue.use(VueMasonryPlugin)
Vue.use(VueScreen);
Vue.use(VueScrollTo)
Vue.use(VueHighlightJS)
Vue.prototype.$http.interceptors.request.use((config) => {
    return config;
}, (error) => {
    return Promise.reject(error);
});

Vue.prototype.$http.interceptors.response.use((response) => {
    return response;
}, (error) => {
    let show = true;
    let reportSentry = true;
    let isLogin = window.location.pathname === '/login';

    let message = 'There was a mistake. Try again later';
    if (error.response && error.response.status) {
        switch (error.response.status) {
            case 403:
            case 404:
                reportSentry = false;
                message = error.response.statusText;
                break;
        }
    }
    if (isLogin) {
        show = false;
    }
    if (show) {
        // We hid the notify cause it was triggering everywhere,
        // we just reject the promise and handle elsewhere
        // Vue.$notify({ title: 'Error', text: message, type: 'error' })
        console.log(message)
        return Promise.reject(error);
    }
    if (reportSentry) {
        return Promise.reject(error);
    }
    return Promise.resolve(error);
});

Vue.prototype.$setTitle = setTitle;
Vue.prototype.$customToolbar = editorToolbar;
Vue.prototype.$copyToClipboard = copyToClipBoard;
Vue.prototype.$log = console.log;

if(process.env.NODE_ENV === 'production'){
    Sentry.init({
        Vue,
        dsn: "https://fdb70fd6f5624d5c844b4377f0ccc85d@o515190.ingest.sentry.io/5976019",
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: [
                // "localhost",
                "https://development-dot-glassy-tube-622.appspot.com", 
                "http://projects.markerly.com/", 
                /^\//
            ],
          }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.4 : 1.0,
        environment: process.env.NODE_ENV === 'production' ? 'production' : 'development'
    });
}

initFacebookSdk(Vue.prototype.$http)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

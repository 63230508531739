<template>
<!-- [recruitment] -->
<main class="job-post-create-wrapper" tabindex="0">

    <div class="header">
        <base-button 
            label="Back"
            icon="arrow-left" 
            @action="$router.back()" 
            bold
            class="mb-4"
            style="padding: 10px 2px; width: 130px;"
        />
        <h1 class="title">New Job Post</h1>
        <div class="project">{{$route.params.projectId}}</div>
    </div>

    <div class="job-post-create-container">

        <!-- steps-index -->
        <div class="steps-index">
            <div 
                v-for="(step, index) in steps" 
                :key="index" 
                :class="currentStep == step.step ? `font-bold` : currentStep >= step.step ? `cursor-pointer` : ``"
                @click="currentStep >= step.step ? currentStep = step.step : null"
            >
                {{step.name}}
            </div>
        </div>

        <!-- content -->
        <div class="content">

            <FormulateForm class="form" name="form" @submit="submit">

                <!-- step 1 -->
                <div v-if="currentStep == 1" class="step step-1">
                    <div class="box">
                        <div class="title">Project Title</div>
                        <div class="description">Describe in a short sentence what you need. (For example: Designer needed for a new logo)</div>
                        <FormulateInput
                            type="text"
                            class="w-full"
                            placeholder="Enter the Project Title"
                            validation="required"
                            validation-name="Project Title"
                            v-model="title"
                        />
                    </div>

                    <div class="box">
                        <div class="title">Project Category</div>
                        <div class="description">Try to select the categories that fit the best with what you are looking for.</div>
                        <FormulateInput
                            id="job-post-create-vue-select"
                            type="vue-select"
                            v-model="selectedMainCategory"
                            :options="mainCategories"
                            placeholder="Select the main category"
                            @input="selectedSubCategories = []"
                        />
                    </div>

                    <div class="box" v-if="filteredSubCategories.length">
                        <div class="title">Project Subcategories</div>
                        <div class="description">Select the subcategories that fit the best with what you are looking for.</div>
                        <div class="sub-categories-list">
                            <div 
                                v-for="(category, index) in filteredSubCategories" 
                                :key="index"
                                class="sub-category-item"
                                :class="{ 'selected': isInSelectedSubCategories(category) }"
                                @click="isInSelectedSubCategories(category) ? selectedSubCategories = selectedSubCategories.filter(element => element.id != category.id) : selectedSubCategories.push(category)"
                            >
                                {{category.name}}
                            </div>
                        </div>
                    </div>

                </div>

                <!-- step 2 -->
                <div v-if="currentStep == 2" class="step step-2">

                    <div class="box">
                        <div class="title">Project Description</div>
                        <div class="description">Explain your project and deliverable(s), as well as problems to be solved and describe the type of expertise you’re looking for (i.e. skill level, type of experience).</div>
                        <FormulateInput
                            type="textarea"
                            class="w-full"
                            placeholder="Enter"
                            v-model="description"
                        />
                    </div>

                    <div class="box">
                        <div class="title">Projects Details</div>
                        <div class="description">What type of project you need.</div>
                        <div class="project-type-list">
                            <div 
                                class="project-type-item"
                                :class="{ 'selected': selectedProjectType.id == projectType.id }"
                                v-for="(projectType, index) in projectTypes" :key="index"
                                @click="selectedProjectType = projectType"
                            >
                                <div class="title">{{projectType.title}}</div>
                                <div class="description">{{projectType.description}}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- step 3 -->
                <div v-if="currentStep == 3" class="step step-3">
                    <div class="box">
                        <div class="title">Creator Skills</div>
                        <div class="description">Select all the skills needed for the project.</div>
                        <div class="skill-list">
                            <div 
                                v-for="(skill, index) in skills"
                                :key="index"
                                class="skill-item"
                                :class="{ 'selected': selectedSkills.find(element => element.id == skill.id) }"
                                @click="selectedSkills.find(element => element.id == skill.id) ? selectedSkills = selectedSkills.filter(element => element.id != skill.id) : selectedSkills.push(skill)"
                            >
                                {{ skill.name }}
                            </div>

                        </div>
                    </div>

                    <div class="box">
                        <div class="title">Language</div>
                        <div class="description">Select the prefered languages for the creator.</div>
                        <div class="selected-languages-list">
                            <div 
                                v-for="(selectedLanguage, index) in selectedLanguages" 
                                :key="selectedLanguage.language.id"
                                class="selected-language-item"
                            >   
                                <div class="field">
                                    <custom-select
                                        placeholder="Language"
                                        :options="languages"
                                        :required="true"
                                        v-model="selectedLanguages[index].language"                                     
                                    />
                                </div>
                                <div class="field">
                                    <custom-select
                                        placeholder="Language Level"
                                        :options="languageLevels"
                                        :required="true"
                                        v-model="selectedLanguages[index].level"               
                                    />
                                </div>
                                <base-button 
                                    class="ml-auto mr-2"
                                    :disabled="selectedLanguages.length <= 1"
                                    @action="selectedLanguages = selectedLanguages.filter(element => element.language != selectedLanguage.language)" 
                                    type="label" 
                                    icon="trash"  
                                />
                            </div>
                            <div class="actions">
                                <base-button 
                                    @action="selectedLanguages.push({language: {id: null, value: null, name:'Language'}, level: null})" 
                                    :disabled="selectedLanguages.length >= 5" 
                                    type="main" 
                                    label="Add Language"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="box">
                        <div class="title">Location</div>
                        <div class="description">Select the prefered location for the creator.</div>
                        <div class="country-select">
                            <custom-select
                                placeholder="Location"
                                :options="countries"
                                class="pr-4"
                                :required="true"
                                v-model="selectedCountry"               
                            />
                        </div>
                    </div>
                </div>

                <!-- step 4 -->
                <div v-if="currentStep == 4" class="step step-4">
                    <div class="box">
                        <div class="title">Project Budget</div>
                        <div class="description">How would you like to pay the creator.</div>
                        <div class="project-budget-type-list">
                            <div 
                                class="project-budget-type-item"
                                :class="{ 'selected': selectedProjectBudgetType.id == projectBudgetType.id }"
                                v-for="(projectBudgetType, index) in projectBudgetTypes" :key="index"
                                @click="selectedProjectBudgetType = projectBudgetType"
                            >
                                <div class="title">{{projectBudgetType.title}}</div>
                                <div class="description">{{projectBudgetType.description}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="box">
                        <div class="title">Set Project Budget Range</div>
                        <div class="description">The avergage budget range for projects like this is between {{selectedProjectBudgetType.id == 1 ? `$20 - $25/hr` : `$200 - $400`}}</div>
                        <div class="project-budget-type-form">
                            <template v-if="selectedProjectBudgetType.id == 1">
                                <FormulateInput
                                    v-model="budgetRangeMin"
                                    class="block w-1/2 sm:leading-5"
                                    type="number"
                                    label="Minimum"
                                    placeholder="0.00"
                                    min="0"
                                    max="1000000"
                                    :element-class="['flex items-center']"	
                                    :input-class="['w-min-content']"
                                >
                                    <template #prefix>$</template>
                                    <template #suffix>/hr</template>
                                </FormulateInput>
                                <FormulateInput
                                    v-model="budgetRangeMax"
                                    class="block w-1/2 sm:leading-5"
                                    type="number"
                                    label="Maximum"
                                    placeholder="0.00"
                                    min="0"
                                    max="1000000"
                                    :element-class="['flex items-center']"	
                                    :input-class="['w-min-content']"
                                >
                                    <template #prefix>$</template>
                                    <template #suffix>/hr</template>
                                </FormulateInput>
                            </template>
                            <FormulateInput
                                v-else
                                v-model="budget"
                                class="block w-1/2 sm:leading-5"
                                type="number"
                                label="Budget"
                                placeholder="0.00"
                                min="0"
                                max="1000000"
                                :element-class="['flex items-center']"	
                                :input-class="['w-min-content']"
                            >
                                <template #prefix>$</template>
                                <template #suffix>/hr</template>
                            </FormulateInput>
                        </div>
                    </div>
                </div>

                <!-- step 5 -->
                <div v-if="currentStep == 5" class="step step-5">
                    <div class="field mb-4">
                        <div class="title">Post Project</div>
                        <div class="description">You are posting a project with the following characteristics.</div>
                    </div>
                    <div class="field">
                        <div class="title">Title</div>
                        <div>{{title}}</div>
                    </div>
                    <div class="field">
                        <div class="title">Category</div>
                        <div>{{selectedMainCategory.name}}: <span v-for="(category, index) in selectedSubCategories" :key="category.id"> {{category.name}}{{index+1 == selectedSubCategories.length ? `.` : `,`}}</span></div>
                    </div>
                    <div class="field">
                        <div class="title">Description</div>
                        <div>{{description}}</div>
                    </div>
                    <div class="field">
                        <div class="title">Details</div>
                        <div>{{selectedProjectType.title}}</div>
                    </div>
                    <div class="field">
                        <div class="title">Skills</div>
                        <div v-if="selectedSkills.length > 0">
                            <span v-for="(skill, index) in selectedSkills" :key="skill.id"> 
                                {{skill.name}}{{index+1 == selectedSkills.length ? `.` : `,`}}
                            </span>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Language</div>
                        <div v-if="selectedLanguages.length > 0">
                            <div v-for="(selectedLanguage, index) in selectedLanguages" :key="index">
                                {{ selectedLanguage.language.name }} {{ selectedLanguage.level.label }} ({{selectedLanguage.level.alias}})
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Location</div>
                        <div v-if="selectedCountry">{{selectedCountry.name}}</div>
                    </div>
                    <div class="field">
                        <div class="title">Budget</div>
                        <div>{{selectedProjectBudgetType.title}} ({{selectedProjectBudgetType.id == 1 ? `$${budgetRangeMin} - $${budgetRangeMax}` : `$${budget}`}})</div>
                    </div>
                </div>

            </FormulateForm>

            <div class="footer">
                <base-button 
                    v-if="currentStep < 5"
                    @action="currentStep == currentStep++" 
                    :disabled="!nextButtonEnabled" 
                    type="main" 
                    label="Next"
                />
                <base-button
                    v-if="currentStep == 5" 
                    @action="submit" 
                    :disabled="false" 
                    type="main" 
                    label="Create Job Post"
                />
            </div>
        </div>

    </div>
    <base-modal
        :visible="successModalVisible"
        title="Job Posted Successfully"
        @close="()=>null"
    >
        <div>We will look for the best creators that fit your project needs and we will let you know when we have the results.</div>
        <base-button 
            @action="$router.push(`/projects/${$route.params.projectId}/activation/influencer`)" 
            type="secondary" 
            size="sm" 
            label="Done" 
        />
    </base-modal>
</main>
</template>

<script>
import { languages, languageLevels }  from '../../lib/languages.js'
import { countries }  from '../../lib/countries.js'
import Separator from '../../components/Separator.vue'
export default {
    components: {
        Separator
    },
    data() {
        return {
            steps: [
                { step: 1, name: 'Category and Title' },
                { step: 2, name: 'Description' },
                { step: 3, name: 'Creator Skills and Preferences' },
                { step: 4, name: 'Budget' },
                { step: 5, name: 'Post' }
            ],
            projectTypes: [
                { id: 1, title: 'New Project', description: 'You need someone to start from scratch', default: true},
                { id: 2, title: 'Ongoing Project', description: 'You need some to work with existing files.' }
            ],
            projectBudgetTypes: [
                { id: 1, title: 'Pay for the hour', description: 'Pay the creator only for the hours worked.', default: true },
                { id: 2, title: 'Fixed Price', description: 'Define a single payment for everthing that you need.' }
            ],
            
            mainCategories: [],
            subCategories: [],
            skills: [],
            languages: languages,
            languageLevels: languageLevels,
            countries: countries,
            currentStep: 1,
            title: '',
            selectedMainCategory: null,
            selectedSubCategories: [],
            description: '',
            selectedProjectType: {},
            selectedSkills: [],
            selectedLanguages: [{language: {id: null, value: null, name:'Language'}, level: null}],
            selectedCountry: null,
            selectedProjectBudgetType: {},
            budgetRangeMin: null,
            budgetRangeMax: null,
            budget: null,
            successModalVisible: false
        }
    },
    mounted() {
        this.getCategories()
        this.getSkills()
        this.selectDefaults()
    },
    methods: {
        getCategories(){
            this.$http.get('/api/jobPosts/categories').then(res => {
                this.mainCategories = res.data.mainCategories.map(category => {
                    return { ...category, value: category, label: category.name }
                })
                this.subCategories = res.data.subCategories.map(category => {
                    return { ...category, value: category, label: category.name }
                })
            }).catch(err => {
                console.log(err)
            })
        },
        getSkills(){
            this.$http.get('/api/jobPosts/skills').then(res => {
                this.skills = res.data
            }).catch(err => {
                console.log(err)
            })
        },
        selectDefaults(){
            this.selectedProjectType = this.projectTypes.find(element => element.default)
            this.selectedProjectBudgetType = this.projectBudgetTypes.find(element => element.default)
        },
        isInSelectedSubCategories(category){
            return this.selectedSubCategories.find(element => element.id == category.id)
        },
        submit(){
            this.$http.post('/api/jobPosts', {
                project_slug: this.$route.params.projectId, 
                title: this.title, 
                description: this.description, 
                project_type: this.selectedProjectType.id, 
                project_budget_type: this.selectedProjectBudgetType.id, 
                budget: this.budget, 
                budget_range_min: this.budgetRangeMin, 
                budget_range_max: this.budgetRangeMax, 
                language: this.selectedLanguages.map(element => `${element.language.name} ${element.level.label} (${element.level.alias})`).toString(), 
                location: this.selectedCountry.name, 
                categories: [ { id: this.selectedMainCategory.id }, ...this.selectedSubCategories.map(category => { return { id: category.id }}) ], 
                skills: [ ...this.selectedSkills.map(skill => { return { id: skill.id }}) ] 
            }).then(res => {
                console.log(res)
                this.successModalVisible = true
            }).catch(err => {
                console.log(err)
                this.$notify({ title: 'Error', text: err.response.data.message ? err.response.data.message : 'Something went wrong', type: 'error' });
            })
        }
    },
    computed: {
        nextButtonEnabled(){
            if(this.currentStep == 1){
                let titleReady = this.title && this.title != ''
                let categoriesReady = this.selectedMainCategory && (this.selectedSubCategories && this.selectedSubCategories.length > 0)
                if(titleReady && categoriesReady) return true
            }
            if(this.currentStep == 2){
                let descriptionReady = this.description && this.description != ''
                let projectTypeReady = this.selectedProjectType && this.selectedProjectType.id != null
                if(descriptionReady && projectTypeReady) return true
            }
            if(this.currentStep == 3){
                let skillsReady = this.selectedSkills && this.selectedSkills.length > 0
                let languagesReady = this.selectedLanguages && this.selectedLanguages.length > 0
                let invalidLanguage = this.selectedLanguages.find(element => element.language.id == null || element.level.id == null)
                let locationReady = this.selectedCountry && this.selectedCountry.name != null
                if(skillsReady && languagesReady && !invalidLanguage && locationReady) return true
            }
            if(this.currentStep == 4){
                let projectBudgetTypeReady = this.selectedProjectBudgetType && this.selectedProjectBudgetType.id != null
                let budgetReady = false
                if(this.selectedProjectBudgetType.id == 1){
                    if((this.budgetRangeMin && this.budgetRangeMin != '') && (this.budgetRangeMax && this.budgetRangeMax != '')) budgetReady = true
                }
                if(this.selectedProjectBudgetType.id == 2){
                    if(this.budget && this.budget != '') budgetReady = true
                }
                if(projectBudgetTypeReady && budgetReady) return true
            }
            if(this.currentStep == 5){
                return true
            }
            return false
        },
        filteredSubCategories(){
            return this.subCategories.filter(category => {
                return this.selectedMainCategory ? category.parent_id == parseInt(this.selectedMainCategory.id) : false
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    main.job-post-create-wrapper{
        @apply flex-1 relative z-0 overflow-y-auto;
        &:focus{
            @apply outline-none;
        }
        > .header{
            @apply p-10 bg-purple-m-main;
            > .title{
                @apply text-white text-3xl font-bold;
            }
            > .project{
                @apply text-white;
            }
        }
        > .job-post-create-container{
            @apply p-10 flex flex-row gap-x-8;
            // header height
            min-height: calc(100vh - 207px);
            > .steps-index{
                @apply flex flex-col gap-y-8 flex-grow-0 flex-shrink-0;
            }
            > .content{
                @apply flex flex-col flex-grow flex-shrink;
                > .form{
                    @apply flex-grow flex-shrink;
                    > .step{
                        @apply flex flex-col gap-y-4;
                    }
                    // step 1
                    > .step-1{
                        @apply flex flex-col gap-y-10;
                        > .box{
                            > .title{
                                @apply font-bold;
                            }
                            > .description{
                                @apply mb-2;
                            }
                            > .sub-categories-list{
                                @apply mt-4 flex flex-row flex-wrap gap-x-4 gap-y-4;
                                > .sub-category-item{
                                    @apply px-5 py-3 border border-gray-400 rounded-full cursor-pointer text-gray-600;
                                    @apply transition-colors duration-200;
                                    &:hover{
                                        @apply bg-gray-200; 
                                    }
                                    &.selected{
                                        @apply bg-purple-m-main border border-white text-white;
                                    }
                                }
                            }
                        }
                    }
                    // step 2
                    > .step-2{
                        @apply flex flex-col gap-y-10;
                        > .box{
                            > .title{
                                @apply font-bold;
                            }
                            > .description{
                                @apply mb-2;
                            }
                            > .project-type-list{
                                @apply mt-4 flex flex-row gap-x-8;
                                > .project-type-item{
                                    @apply flex flex-col;
                                    @apply px-6 py-4 border border-gray-400 rounded-xl cursor-pointer text-gray-600;
                                    @apply transition-colors duration-200;
                                    &:hover{
                                        @apply bg-gray-200; 
                                    }
                                    &.selected{
                                        @apply bg-purple-m-main border border-white text-white;
                                    }
                                    .title{
                                        @apply font-bold;
                                    }
                                    .description{
                                        //
                                    }
                                }
                            }
                        }
                    }
                    // step 3
                    > .step-3{
                        @apply flex flex-col gap-y-10;
                        > .box{
                            > .title{
                                @apply font-bold;
                            }
                            > .description{
                                @apply mb-2;
                            }
                            > .skill-list{
                                @apply mt-4 flex flex-row flex-wrap gap-x-4 gap-y-4;
                                > .skill-item{
                                    @apply px-5 py-3 border border-gray-400 rounded-full cursor-pointer text-gray-600;
                                    @apply transition-colors duration-200;
                                    &:hover{
                                        @apply bg-gray-200; 
                                    }
                                    &.selected{
                                        @apply bg-purple-m-main border border-white text-white;
                                    }
                                }
                            }
                            > .selected-languages-list{
                                @apply flex flex-col gap-y-4;
                                > .selected-language-item{
                                    @apply flex flex-row gap-x-4;
                                    > .field{
                                        @apply w-1/2;
                                    }
                                }
                                > .actions{
                                    @apply mt-4 w-full flex justify-end;
                                }
                            }
                            > .coutry-select{
                                @apply w-1/2 pr-4;
                            }
                        }
                    }
                    // step 4
                    > .step-4{
                        @apply flex flex-col gap-y-10;
                        > .box{
                            > .title{
                                @apply font-bold;
                            }
                            > .description{
                                @apply mb-2;
                            }
                            > .project-budget-type-list{
                                @apply mt-4 flex flex-row gap-x-8;
                                > .project-budget-type-item{
                                    @apply flex flex-col;
                                    @apply px-6 py-4 border border-gray-400 rounded-xl cursor-pointer text-gray-600;
                                    @apply transition-colors duration-200;
                                    &:hover{
                                        @apply bg-gray-200; 
                                    }
                                    &.selected{
                                        @apply bg-purple-m-main border border-white text-white;
                                    }
                                    .title{
                                        @apply font-bold;
                                    }
                                    .description{
                                        //
                                    }
                                }
                            }
                            
                            > .project-type-list{
                                @apply mt-4 flex flex-row gap-x-8;
                                > .project-type-item{
                                    @apply flex flex-col;
                                    @apply px-6 py-4 border border-gray-400 rounded-xl cursor-pointer text-gray-600;
                                    @apply transition-colors duration-200;
                                    &:hover{
                                        @apply bg-gray-200; 
                                    }
                                    &.selected{
                                        @apply bg-purple-m-main border border-white text-white;
                                    }
                                    .title{
                                        @apply font-bold;
                                    }
                                    .description{
                                        //
                                    }
                                }
                            }

                            > .project-budget-type-form{
                                @apply mt-6 flex flex-row gap-x-4;
                            }

                        }
                    }
                    // step 5
                    > .step-5{
                        > .field{
                            > .title{
                                @apply font-bold;
                            }
                        }
                    }
                }
                > .footer{
                    @apply flex flex-row justify-end;
                }
            }
        }
    }
</style>
<style lang="scss">
    #job-post-create-vue-select{
        @apply h-16;
        > .vs__dropdown-toggle{
            @apply h-16;
            > .vs__selected-options{
                > .vs__search{
                    @apply text-gray-400;
                }
            }
        }
    }
</style>
<template>
<div class="w-60 h-60 bg-tan-m p-6 rounded-md flex flex-col justify-between items-center relative">
    
    <div class="h-28 flex-grow-0 flex-shrink-0 flex justify-center items-center">
        
        <div class="relative">
            <div class="w-16 h-16 bg-white rounded-full border border-gray-400 flex justify-center items-center">
                <base-icon :size="10" name="video" />
            </div>
            
            <div 
                class="w-10 h-10 bg-black rounded-full absolute flex justify-center items-center" 
                style="right: -14px;bottom: -14px;"
            >
                <base-icon class="text-white" name="instagram" />
            </div>
        </div>
    </div>
    <div class="flex-grow flex-shrink flex flex-col items-center gap-y-1">
        <div class="text-h5 font-bold">{{data.parentDeliverable}}</div>
        <div v-if="data.network.name && data.label">{{data.network.name}} • {{data.label}}</div>
        <div>{{data.budget}}</div>
    </div>
    <div @click.stop class="absolute right-4 top-4">
        <options-dropdown
            :options="[
                {
                    label: 'Edit', 
                    icon: 'pencil-alt', 
                    event:'edit'
                }
            ]"
            @edit="()=>null"
        />
    </div>
    
</div>
</template>
<script>
export default {
    props: ['data'],
    data(){
        return {
        }
    },
    methods: {
    
    }
}
</script>
<style lang="scss" scoped>

</style>
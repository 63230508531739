<template>
    <div class="content-pane"> 
        <div class="flex gap-x-4 px-12 py-10">

            <div class="w-1/2 flex flex-col gap-y-6">
                <div>
                    <skeleton :height="6" :width="40" />
                    <skeleton :height="16" fullWidth />
                </div>
                <div>
                    <skeleton :height="6" :width="40" />
                    <skeleton :height="40" fullWidth />
                </div>
                <div>
                    <skeleton :height="6" :width="40" />
                    <skeleton :height="16" fullWidth />
                </div>
                <div>
                    <skeleton :height="6" :width="40" />
                    <skeleton :height="16" fullWidth />
                </div>
            </div>

            <div class="w-1/2 flex flex-col gap-y-6"> 
                <div>
                    <skeleton :height="6" :width="40" />
                    <skeleton :height="16" fullWidth />
                </div>
                <div>
                    <skeleton :height="6" :width="40" />
                    <skeleton :height="16" fullWidth />
                </div>
                <div>
                    <skeleton :height="6" :width="40" />
                    <skeleton :height="60" fullWidth />
                </div>
                <div>
                    <skeleton :height="6" :width="16" />
                    <skeleton :height="6" :width="40" />
                </div>
            </div>

        </div>

        <div v-if="isEditView" class="px-12 py-10">
            
            <div class="flex flex-row justify-between">
                <skeleton :height="10" :width="60" />
                <div class="flex flex-row gap-x-4">
                    <skeleton :height="10" :width="40" fullRounded />
                    <skeleton :height="10" :width="40" fullRounded />
                </div>
            </div>
            <div class="flex flex-row gap-x-4">
                <skeleton :height="10" :width="40" />
                <skeleton :height="10" :width="40" />
            </div>
            <table class="w-full">
                <tbody>
                    <tr v-for="i in 10" :key="i" class="h-15 border-b border-gray-m-light">
                        <td>
                            <div class="grid justify-items-center">
                                <skeleton :width="5"/>
                            </div>
                        </td>
                        <td class="px-6 py-6">
                            <div class="flex items-center space-x-3 lg:pl-2">
                                <skeleton :full-width="true"/>
                            </div>
                        </td>
                        <td class="px-6 py-6">
                            <div class="flex items-center space-x-3 lg:pl-2">
                                <skeleton :full-width="true"/>
                            </div>
                        </td>
                        <td class="px-6 py-6">
                            <div class="flex items-center space-x-3 lg:pl-2">
                                <skeleton :full-width="true"/>
                            </div>
                        </td>
                        <td class="px-6 py-6">
                            <div class="flex items-center space-x-3 lg:pl-2">
                                <skeleton :full-width="true"/>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            
        </div>

    </div>
</template>

<script>
export default {
    props: ['isEditView']
}
</script>
<style lang="scss" scoped>
    .content-pane{
        overflow: auto;
        max-height: calc(100vh - 140px);
        position: relative;
    }
</style>
import { render, staticRenderFns } from "./DeliverablesTab.vue?vue&type=template&id=d6a82e16&scoped=true&"
import script from "./DeliverablesTab.vue?vue&type=script&lang=js&"
export * from "./DeliverablesTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6a82e16",
  null
  
)

export default component.exports
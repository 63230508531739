<template>    
    <div :class="['bg-gray-m-light', {'w-full h-px': !vertical}, {'h-full w-px': vertical}]"></div>
</template>
<script>
export default {
    props: {
        vertical: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<template>
    <div v-if="visible" :class="[absolute ? 'absolute' : 'fixed w-screen', `opacity-${opacity}`, `z-${index}`]" class="flex gap-4 cursor-wait flex-col justify-center items-center inset-0 h-25 bg-black text-white">
        <img :src="spinner" class="w-8 h-8"/>
        {{ label }}
    </div>
</template>
<script>
import spinner from './../assets/images/spinner.gif';
export default {
    props: {
        label: {
            type: String,
            default: 'Refreshing'
        },
        visible: {
            type: Boolean,
            default: false
        },
        absolute: {
            type: Boolean,
            default: false
        },
        opacity: {
            type: Number,
            default: 80
        },
        index: {
            type: Number,
            default: 70
        }
    },
    data() {
        return {
            spinner: spinner
        }
    }
}
</script>
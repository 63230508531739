<template>
    <div class="wrapper" v-if="computedData">
        <!-- <div class="title">Ages</div> -->

        <div class="container">
            <Bar :data="computedData" :options="options" :styles="{ height: '100%', width: '100%' }" />
        </div>

        <div class="legend" v-if="!ageOnly">
            <div class="item">
                <div class="marker female" />
                <div class="label">Female</div>
            </div>

            <div class="item">
                <div class="marker male" />
                <div class="label">Male</div>
            </div>

            <!-- <div class="item">
                <div class="marker unknown"/>
                <div class="label">Unknown</div>
            </div> -->
        </div>
    </div>
</template>
<script>
import Bar from "./Bar.vue";
const options = {
    scales: {
        xAxes: [
            {
                stacked: true,
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontSize: 14,
                    fontStyle: "bold",
                    fontFamily: "Helvetica",
                    fontColor: "#000000",
                },
            },
        ],
        yAxes: [
            {
                stacked: true,
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontSize: 14,
                    fontStyle: "bold",
                    fontFamily: "Helvetica",
                    fontColor: "#000000",
                    callback: function(value) {
                        return `${value}%`; // convert it to percentage
                    },
                },
            },
        ],
    },
    responsive: true,
    legend: { display: false },
    tooltips: {
        enabled: true,
        callbacks: {
            label: (tooltipItem, data) => {
                let percent = data["datasets"][tooltipItem.datasetIndex]["data"][tooltipItem["index"]].toFixed(2) + "%";
                let label = data["datasets"][tooltipItem.datasetIndex]["label"];
                let space = " ";
                return label && percent ? space + label + " " + percent : null;
            },
        },
    },
};

export default {
    components: {
        Bar,
    },
    props: ["data", "ageOnly"],
    data() {
        return {
            computedData: null,
            options,
        };
    },
    computed: {
        labels() {
            return ["13-17", "18-24", "25-34", "35-44", "45-54", "55-64", "65+"];
        },
    },
    mounted() {
        if (this.ageOnly) {
            return this.compileAgeData();
        }

        // copy data so we can change its values
        let data = JSON.parse(JSON.stringify(this.data));

        if (data.length === 0) {
            return;
        }

        const key = data[0].code ? "code" : "name";

        // we are no longer user unknown data, so we have to re calculate the 100% with the values that we have
        const hasUnknown = data.find((element) => element[key].includes("U."));
        if (hasUnknown) {
            data = data.filter((element) => element[key].includes("M.") || element[key].includes("F."));
            const totalWeight = data.map((e) => e.weight).reduce((acc, cur) => acc + cur, 0);
            data = data.map((element) => {
                const percentage = (element.weight * 100) / totalWeight;
                const value = percentage / 100;
                element.weight = value;
                return element;
            });
        }

        let femaleArray = [];
        let maleArray = [];

        if (data[0] && data[0].female) {
            femaleArray = data.map((d) => d.female * 100);
        } else {
            for (const value of data) {
                if (value[key].includes("F.")) {
                    femaleArray.push(value.weight * 100);
                }
            }
        }

        if (data[0] && data[0].male) {
            maleArray = data.map((d) => d.male * 100);
        } else {
            for (const value of data) {
                if (value[key].includes("M.")) {
                    maleArray.push(value.weight * 100);
                }
            }
        }

        // let unknownArray = []
        // for (const value of data) {
        //     if(value.code.includes('U.')){
        //         unknownArray.push( value.weight * 100 )
        //     }
        // }

        const datasets = [
            {
                label: "Female",
                data: femaleArray,
                backgroundColor: "#70F880",
                stack: "Stack 0",
            },
            {
                label: "Male",
                data: maleArray,
                backgroundColor: "#0E092C",
                stack: "Stack 0",
            },
            // {
            //     label: 'Unknown',
            //     data: unknownArray,
            //     backgroundColor: '#D8D8D8',
            //     stack: 'Stack 0',
            // }
        ];

        this.computedData = { labels: this.labels, datasets };
    },
    methods: {
        compileAgeData() {
            const datasets = [
                {
                    label: "",
                    data: this.data.map((d) => d.weight * 100),
                    backgroundColor: "#0E092C",
                    stack: "Stack 0",
                },
            ];

            this.computedData = { labels: this.labels, datasets };
        },
    },
};
</script>
<style lang="scss" scoped>
.wrapper {
    @apply flex flex-col items-center;
}
.title {
    font-weight: 600;
    text-align: center;
    font-size: 16px;
}
.container {
    width: 340px;
    height: 340px;
}
.legend {
    @apply mt-6 flex justify-center items-center gap-x-4;

    .item {
        @apply flex gap-x-2 items-center;

        .marker {
            @apply w-3 h-3;
            display: inline-block;
            &.female {
                background-color: #70f880;
            }
            &.male {
                background-color: #0e092c;
            }
            &.unknown {
                background-color: #d8d8d8;
            }
        }

        .label {
            @apply font-semibold;
            text-align: center;
            font-size: 16px;
        }
    }
}
</style>

import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import html2pdf from 'html2pdf.js/src';

export default function generatePdf(options) {
    let { query, fileName, isUser, view } = options;
    let element = document.getElementById(query);

    // creator report
    if (isUser) {
        let opt = {
            margin: 0,
            filename: fileName + '.pdf',
            image: { type: 'jpg', quality: 0.98 },
            html2canvas: { scale: 1, dpi: 300, letterRendering: true, useCORS: true },
            jsPDF: { unit: 'in', format: 'A1', orientation: 'portrait' }
        };
        html2pdf().set(opt).from(element).save().then(() => {
            options.onSuccess()
        }).catch((error) => {
            options.onError(error);
            console.error("oops, something went wrong!", error);
        });
    } else {

        // content report

        // (info) Previously we were using the element '.posts-wrapper' to display all of the report posts.
        //        * Now, depending on the view ('grid', 'grid-grouped', 'list') we will grab different elements
        //          and print them, however, the elements 'report-top' and 'stats-groups' will always be shown as they
        //          are always the same.
        //        * Ideally instead of cutting the page at 15 posts or 1 group, it should check how much space is left.
        //          but for that we would need to calculate the height of the whole line or group that its going to be added.
        //        * Its important that the elements that you want to print are single elements with no multiple children, that
        //          may lead to jsPDF generating borders that should not exist, so if that happens try to select diferent elements
        //          to render.
        
        let elements = [];

        // For "grid" view we are getting each post individually and adding them to the pdf in batches of 15 so 
        // we can handle multiple pages.
        if(view == 'grid') elements = allPostsIds();

        // For "grid-grouped" view we are getting each group individually and adding them to the pdf, 1 per page
        if(view == 'grid-grouped') elements = allGroupItemIds();

        // For "list" view we will only grab the #list-tab element and add that whole element
        if(view == 'list') elements = ['list-tab'];
        
        elementIdToPng(['report-top', 'stats', 'groups', ...elements]).then(imageObjects => {
        
            // constant 1850 width (instead of using getDocumentWidth()) so we always show 5 posts per row and so that it does not overflow
            // constant 2800 height (instead of using getDocumentHeight()) so we can start using multiple pages, putting images and elements on it and so it is consistent 
            const doc = new jsPDF({
                orientation: "portrait",
                format: [1850, 2800],
                pagesplit: true
            });

            // will start with this height so it has some top padding
            let topPadding = 70; 
            let leftPadding = 100;
            
            let pagePostsCount = 0;
            let linePostsCount = 0;
            let firstPageAdded = false;
            let lineMaxHeight = 0;

            function cutPage(){
                doc.addPage();
                // reset all values:
                leftPadding = 100; // start from the left with some padding
                topPadding = 70; // start from the top with some padding
                linePostsCount = 0; // reset number of posts in line
                lineMaxHeight = 0; // reset line max height

                pagePostsCount = 0; // reset page posts count
            }

            function cutLine(){
                leftPadding = 100; // start from the left again, with some padding 
                topPadding = topPadding + lineMaxHeight + 50; // get the previous line max height and add it to the top padding, also add 50px so we have vertical space between posts
                linePostsCount = 0; // reset number of posts in line
                lineMaxHeight = 0; // reset line max height
            }

            for (const imageObject of imageObjects) {
                const { image, height, width, query } = imageObject;
                
                if(query == 'report-top'){
                    doc.addImage(image, 'PNG', leftPadding, topPadding, width, height);
                    topPadding = topPadding + height + 30;
                }

                if(query == 'stats'){
                    doc.addImage(image, 'PNG', leftPadding, topPadding, width, height);
                    topPadding = topPadding + height + 10;
                }

                if(query == 'groups'){
                    doc.addImage(image, 'PNG', leftPadding, topPadding, width, height);
                    topPadding = topPadding + height + 100;
                }

                // "list" view
                if(query.includes('list-tab')){
                    doc.addImage(image, 'PNG', leftPadding, topPadding, width, height);
                }

                // "grid" view, if its a post
                if(query.includes('report-post-card-container')){
                    
                    // save the post height
                    if(height > lineMaxHeight){
                        lineMaxHeight = height;
                    }
                    linePostsCount++;
                    pagePostsCount++;

                    doc.addImage(image, 'PNG', leftPadding, topPadding, width, height);
                    leftPadding = leftPadding + width + 30; // 30px is the space between posts

                    // if line has 5 posts, cut line
                    if(linePostsCount == 5){
                        if(firstPageAdded == false){
                            cutPage();
                            firstPageAdded = true;
                        }else{
                            cutLine();
                        }
                    }

                    // if page has 15 posts, cut the page
                    if(pagePostsCount == 15){
                        cutPage();
                    }

                }

                // "grid grouped" view, if its a group
                if(query.includes('group-item')){
                    doc.addImage(image, 'PNG', leftPadding, topPadding, width, height);
                    cutPage();
                }

            }

            const filename = fileName ? `${fileName}.pdf` : 'report.pdf'
            doc.save(filename)
            options.onSuccess()
        });
        

        // TODO: this should be the case for mosts reports that have less than 14000 user unit height
        // convert the html element to a PNG
        // domtoimage.toPng(document.querySelector('#' + query), { cacheBust: true }).then((dataUrl) => {

        //     var img = new Image();
        //     img.src = dataUrl;

        //     const documentWidth = Math.max(
        //         document.documentElement["clientWidth"],
        //         document.body["scrollWidth"],
        //         document.documentElement["scrollWidth"],
        //         document.body["offsetWidth"],
        //         document.documentElement["offsetWidth"]
        //     )

        //     const documentHeight = Math.max(
        //         document.documentElement["clientHeight"],
        //         document.body["scrollHeight"],
        //         document.documentElement["scrollHeight"],
        //         document.body["offsetHeight"],
        //         document.documentElement["offsetHeight"]
        //     )
            
        //     // convert the image to pdf
        //     const doc = new jsPDF({
        //         orientation: "portrait",
        //         format: [documentWidth, documentHeight],
        //         pagesplit: true
        //     });


        //     // Maybe here we can add multiple images
        //     doc.addImage(img, 'JPEG', 0, 0, documentWidth, documentHeight)
        //     doc.addImage(img, 'JPEG', 0, 100, documentWidth, 100)
        //     doc.addImage(img, 'JPEG', 0, 200, documentWidth, 100)
        //     doc.addImage(img, 'JPEG', 0, 300, documentWidth, 100)

        //     const filename = fileName ? `${fileName}.pdf` : 'report.pdf'
        //     doc.save(filename)
        //     options.onSuccess()
        // }).catch((error) => {
        //     options.onError(error)
        //     console.error("oops, something went wrong!", error);
        // });
    }
}

// when we are on "grid" view, we will get every post individually with the class
// "report-post-card-container", from there we can get their ids with the property "element.id"
function allPostsIds(){
    const elements = document.getElementsByClassName('report-post-card-container');
    let arr = [];
    for (const element of elements) {
        if(element.id){
            arr.push(element.id)
        }
    }
    return arr;
}

// when we are on "grid-grouped" view, we will get every group item element individually
// width the class "group-item", from there we can get their ids with the property "element.id"
function allGroupItemIds(){
    const elements = document.getElementsByClassName('group-item');
    let arr = [];
    for (const element of elements) {
        if(element.id){
            arr.push(element.id)
        }
    }
    return arr;
}

// given an HTML element, generates and returns a png image
async function generateImage(element) {
    return new Promise((resolve, reject) => {
        domtoimage.toPng(element, { cacheBust: true }).then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl;
            resolve(img);
        })
        .catch(function (error) {
            resolve(null);
        });
    })
}

// given an array of element ids, returns an array of objects
// with an image and its height and width.

// * Important: MUST use element ids because domtoimage requires it 
//   https://github.com/tsayen/dom-to-image/issues/109
async function elementIdToPng(elementIds){
    if(Array.isArray(elementIds) && typeof elementIds == 'string') elementIds = [elementIds];
    let results = await Promise.all(elementIds.map(async (query) => {
        var element = document.getElementById(query);
        if(element){
            let image = await generateImage(element);
            if(image){
                let height = getElementHeight(element);
                let width = getElementWidth(element);
                return { query, image, height, width }
            }
        }
        return null;
    }));
    // remove results that are null (elements that were not found, or images that could not be generated)
    results = results.filter(element => element != null);
    return results;
}

// gets the max height for the document element
function getDocumentHeight(){
    return Math.max(
        document.documentElement["clientHeight"],
        document.body["scrollHeight"],
        document.documentElement["scrollHeight"],
        document.body["offsetHeight"],
        document.documentElement["offsetHeight"]
    )
}

// gets the max width for the document element
function getDocumentWidth(){
    return Math.max(
        document.documentElement["clientWidth"],
        document.body["scrollWidth"],
        document.documentElement["scrollWidth"],
        document.body["offsetWidth"],
        document.documentElement["offsetWidth"]
    )
}

// given an HTML element, returns its max height 
function getElementHeight(element){
    const height = Math.max(
        element["clientHeight"],
        element["scrollHeight"],
        element["scrollHeight"],
        element["offsetHeight"],
        element["offsetHeight"]
    )
    return height;
}

// given an HTML element, returns its max width 
function getElementWidth(element){
    const width = Math.max(
        element["clientWidth"],
        element["scrollWidth"],
        element["scrollWidth"],
        element["offsetWidth"],
        element["offsetWidth"]
    );
    return width;
}
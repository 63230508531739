<template>
    <base-modal 
        title="Manual Posts" 
        :visible="visible"
        @close="$emit('close')"
    >
        <div class="container">

            <!-- step 0 - Advice -->
            <tab :visible="step == 0">
                <div class="">
                    <div class="text-h4 font-bold">¿ Are you sure you want to manually add this creator's profile and posts ?</div>
                    <div class="text-h6 mt-4">You can invite the creator to authenticate with the platform to automatically get their profile and post data.</div> 
                </div>
            </tab>

            <!-- step 1 - User Info -->
            <tab :visible="step == 1">
                
                <div class="section">
                    
                    <!-- profile picture url -->
                    <div class="file-field col-span-6">
                        
                        <div class="header">
                            <div class="label">Profile Picture Url</div>
                            <base-button v-if="(typeof profile_picture_url == 'string' && profile_picture_url.trim() != '')" type="label" class="text-pxs" label="View file" @action="openLink(profile_picture_url)"/>
                        </div>
                        
                        <div class="help">The url of the user profile image</div>

                        <div class="input-container">
                            <FormulateInput 
                                type="text" 
                                validation="required" 
                                placeholder="Profile picture url" 
                                v-model="profile_picture_url" 
                                :wrapper-class="inputWrapperClasses"
                                :element-class="['w-full']"
                            /> 

                            <div class="upload-button">
                                <base-button type="label" size="sm" icon="upload2" @action="$refs.profileFile.click()"/>
                                <input class="hidden h-0" type="file" ref="profileFile" :disabled="uploadingFile" @change="uploadProfilePicture">
                            </div> 
                        </div>

                    </div>
                    
                    <div class="field col-span-3">
                        <div class="label">Username</div>
                        <FormulateInput type="text" validation="required" placeholder="Username" v-model="username" :wrapper-class="inputWrapperClasses"/>
                    </div>
                    <div class="field col-span-3">
                        <div class="label">Full name</div>
                        <FormulateInput type="text" validation="required" placeholder="Full name" v-model="full_name" :wrapper-class="inputWrapperClasses"/>
                    </div>

                    <div class="field col-span-2">
                        <div class="label">Followers</div>
                        <FormulateInput type="number" validation="required" placeholder="Followers count" v-model="total_followers" :wrapper-class="inputWrapperClasses"/>
                    </div>
                    <div class="field col-span-2">
                        <div class="label">Following</div>
                        <FormulateInput type="number" validation="required" placeholder="Following count" v-model="total_following" :wrapper-class="inputWrapperClasses"/>
                    </div>
                    <div class="field col-span-2">
                        <div class="label">User Likes</div>
                        <FormulateInput type="number" validation="required" placeholder="User likes count" v-model="total_likes" :wrapper-class="inputWrapperClasses"/>
                    </div>

                </div>

            </tab>

            <!-- Step 2 - Post Data -->
            <tab :visible="step == 2">
                
                <div class="section">

                    <div class="field col-span-6">
                        <div class="label">Post URL</div>
                        <FormulateInput 
                            type="text"
                            validation="required"
                            placeholder="https://"
                            v-model="post_url"
                            v-debounce:500="getInfoFromPostUrl"
                            :wrapper-class="inputWrapperClasses"
                            :element-class="['w-full']"
                        />                    
                    </div>


                    <!-- media url -->
                    <div class="file-field col-span-3">

                        <div class="header">
                            <div class="label">Media Url</div>
                            <base-button v-if="(typeof url == 'string' && url.trim() != '')" type="label" size="sm" class="text-pxs" label="View file" @action="openLink(url)"/>
                        </div>

                        <div class="help">The url of the image or video</div>

                        <div class="input-container">
                            <FormulateInput 
                                type="text" 
                                validation="required" 
                                placeholder="File url" 
                                v-model="url" 
                                :wrapper-class="inputWrapperClasses"
                                :element-class="['w-full']"
                            />

                            <div class="upload-button">
                                <base-button type="label" size="sm" icon="upload2" @action="$refs.mediaFile.click()" class="w-5 h-5 text-pxs"/>
                                <input class="hidden h-0" type="file" ref="mediaFile" :disabled="uploadingFile" @change="uploadMediaFile">
                            </div>

                        </div>
                    </div>

                    <!-- cover url -->
                    <div class="file-field col-span-3">

                        <div class="header">
                            <div class="label">Display Url</div>
                            <base-button  v-if="(typeof display_url == 'string' && display_url.trim() != '')" type="label" class="text-pxs" label="View file" @action="openLink(display_url)"/>
                        </div>
                        
                        <div class="help">The url of the cover image</div>
                        
                        <div class="input-container">
                            <FormulateInput 
                                type="text" 
                                validation="required" 
                                placeholder="Cover url" 
                                v-model="display_url" 
                                :wrapper-class="inputWrapperClasses"
                                :element-class="['w-full']"
                            />
                            <div class="upload-button">
                                <base-button type="label" size="sm" icon="upload2" @action="$refs.displayFile.click()"/>
                                <input class="hidden h-0" type="file" ref="displayFile" :disabled="uploadingFile" @change="uploadDisplayFile">
                            </div>
                        </div>

                    </div>
                    <div class="field col-span-2">
                        <template v-if="medium.id == 'video'">
                            <div class="label">Video Duration</div>
                            <FormulateInput type="text" validation="required" placeholder="Video duration" v-model="video_duration" :wrapper-class="inputWrapperClasses"/>
                        </template>
                    </div>

                    <div class="field col-span-2">
                        <div class="label">Caption</div>
                        <FormulateInput type="text" placeholder="Type the caption of the content" v-model="caption" :wrapper-class="inputWrapperClasses"/>
                    </div>

                    <div class="field col-span-2">
                        <div class="w-full flex justify-between relative">
                            <div class="label">Post Identifier</div>
                            <span class="absolute right-2 top-0 cursor-pointer">
                                <base-icon 
                                    name="info-circle"
                                    @action="() => $swal.fire({
                                        title: 'Post Identifier',
                                        html: `
                                            <div style='font-size: 14px'>
                                                <div>Unique Identifier of the post, usually included in the URL, for example:</div>
                                                <br>
                                                <div>For an Instagram URL:</div>
                                                <div>https://www.instagram.com/p/Cmsjhtytkhs_/</div>
                                                <div>The post identifier would be: <span style='font-weight: bold'>Cmsjhtytkhs_</span></div>
                                                <br>
                                                <div>For a TikTok URL:</div>
                                                <div>https://www.tiktok.com/@username/video/123456789</div>
                                                <div>The post identifier would be: <span style='font-weight: bold'>123456789</span></div>
                                                <br>
                                                <div>For a Facebook URL:</div>
                                                <div>https://www.facebook.com/photo/?fbid=123456789</div>
                                                <div>The post identifier would be: <span style='font-weight: bold'>123456789</span></div>
                                            </div>
                                        `,
                                        iconColor: '#0E092C',
                                        customClass: {
                                            popup: 'swal-wide'
                                        },
                                        showCancelButton: true,
                                        cancelButtonText: 'Close',
                                        confirmButtonText: 'Ok',
                                        reverseButtons: true
                                    })"
                                />
                            </span>
                        </div>
                        <FormulateInput 
                            type="text" 
                            validation="required" 
                            placeholder="Post Identifier" 
                            v-model="post_identifier"
                            :wrapper-class="inputWrapperClasses"
                        />
                    </div>
                    
                    <div class="field col-span-2">
                        <div class="label">Date</div>
                        <FormulateInput 
                            type="date" 
                            validation="required" 
                            placeholder="Type the content published at date" 
                            v-model="date"
                            :wrapper-class="inputWrapperClasses"
                        />
                    </div>

                    <div class="field col-span-2">
                        <div class="label">Likes</div>
                        <FormulateInput type="number" validation="required" placeholder="Post likes count" v-model="likes" :wrapper-class="inputWrapperClasses"/>
                    </div>
                    <div class="field col-span-2">
                        <div class="label">Dislikes</div>
                        <FormulateInput type="number" validation="required" placeholder="Post dislikes count" v-model="dislikes" :wrapper-class="inputWrapperClasses" />
                    </div>
                    <div class="field col-span-2">
                        <div class="label">Comments</div>
                        <FormulateInput type="number" validation="required" placeholder="Post comment count" v-model="comments" :wrapper-class="inputWrapperClasses"/>
                    </div>
                    <div class="field col-span-2">
                        <div class="label">Views</div>
                        <FormulateInput type="number" validation="required" placeholder="Type the views count" v-model="views" :wrapper-class="inputWrapperClasses"/>
                    </div>
                    <div class="field col-span-2">
                        <div class="label">Shares</div>
                        <FormulateInput type="number" validation="required" placeholder="Type the shares count" v-model="shares" :wrapper-class="inputWrapperClasses"/>
                    </div>
                    <div class="field col-span-2">
                        <div class="label">Saves</div>
                        <FormulateInput type="number" validation="required" placeholder="Type the saves count" v-model="saves" :wrapper-class="inputWrapperClasses"/>
                    </div>

                </div>

            </tab>
            
            <!-- Preview -->
            <tab :visible="step == 3">
                <div class="flex justify-center">
                    <report-post-card 
                        is-preview 
                        class="post-card mb-4" 
                        :post="computedManualPost"
                    />
                </div>
            </tab>
            
            <div class="footer">
                <base-button label="Cancel" v-if="step == 0" @action="$emit('close')" type="secondary" size="md" />
                <base-button label="Back" v-if="step >= 1" @action="back()" type="secondary" size="md" />
                <base-button label="Next" v-if="step <= 2" :disabled="nextDisabled()" @action="next()" />
                <base-button label="Submit" v-if="step == 3" :disabled="nextDisabled()" @action="submit()"/>
            </div>
           
        </div>
    </base-modal>
</template>

<script>
    const inputWrapperClasses = 'border rounded-md py-2 px-3 bg-white h-12 flex items-center';
    import { getNetwork, removeUnnecessaryQueryParams, getParamsFromSocialPostUrl } from '@/lib/network';
    import ReportPostCard from '../../components/ReportPostCard';
    import { notifyCatchError } from '../../common'; 
    export default {
        name: 'ManualPostReplaceModal',
        props: ['visible', 'reportId', 'user', 'networkId'],
        components: {
            ReportPostCard
        },
        data() {
            return {
                step: 0,
                loading: false,
                payloadExists: false,
                 
                // the audience_data payload (for a specific network id)
                username: "",
                full_name: "",
                total_followers: 0,
                total_following: 0,
                total_likes: 0,
                profile_picture_url: "",

                uploadingFile: false,

                // manual post data
                post_url: '',
                url: '',
                display_url: '',
                platform: {id: 'facebook', name: 'Facebook', value: 6}, // depends on prop
                video_duration: '00:00:00',
                caption: '',
                post_identifier: '',
                date: '',
                likes: 0,
                dislikes: 0,
                comments: 0,
                views: 0,
                shares: 0,
                saves: 0,

                // not editable
                description: '', // not used
                medium: {id: 'video', name: 'Video'}, // not editable for now
                bio: '', // not editable
                social_id: '', // not editable
                profile_url: '',

                // constants
                inputWrapperClasses
            }
        },
        watch: {
            visible(val){
                if(val){
                    this.setData()
                }
            }
        },
        methods: {
            setData(){
                // setting platform
                const networks = [
                    {id: 'facebook', name: 'Facebook', label: 'Facebook', value: 6},
                    {id: 'instagram', name: 'Instagram', label: 'Instagram', value: 2},
                    {id: 'tiktok', name: 'TikTok', label: 'TikTok', value: 14},
                    {id: 'twitter', name: 'Twitter', label: 'Twitter', value: 7},
                    {id: 'pinterest', name: 'Pinterest', label: 'Pinterest', value: 4},
                    {id: 'linkedin', name: 'LinkedIn', label: 'LinkedIn', value: 16},
                ];
                const found = networks.find(element => element.id == getNetwork(this.networkId, 'name'))
                if(found){
                    this.platform = found
                }
            },
            next(){
                this.step++
            },
            back(){
                this.step--
            },

            async submit(){
                this.loading = true;
                try {
                    // user vars
                    const { username, full_name, total_followers, total_following, total_likes, profile_picture_url } = this
                    // post vars
                    const { description, platform, medium, url, display_url, date, caption, post_identifier, video_duration, likes, dislikes, comments, views, shares, saves} = this
                    // other
                    const { post_url, profile_url, bio, social_id } = this

                    let { data } = await this.$http.post(`/api/users/manual-audience-data`, { 
                        userId: this.user.id,
                        networkId: this.networkId,
                        reportId: this.reportId,
                        ...( this.payloadExists ? [] : {
                            manualAudienceData: {
                                username,
                                full_name,
                                total_followers,
                                total_following,
                                total_likes,
                                profile_picture_url
                            }
                        }),
                        manualPost: {
                            
                            // user vars
                            profile_picture_url,
                            full_name,
                            username,
                            total_followers,
                            total_following,
                            total_likes,

                            // post vars
                            post_url,
                            description,
                            platform,
                            medium,
                            url,
                            display_url,
                            date,
                            caption,
                            post_identifier,
                            video_duration,
                            likes,
                            dislikes,
                            comments,
                            views,
                            shares,
                            saves,

                            // other
                            profile_url,
                            bio,
                            social_id

                        } 
                    });

                    this.$notify({ title: 'Saved Successfully', text: `You can manually add more posts for this creator`, type: 'success' });
                    
                    // reset to initial state
                    this.post_url = ''
                    this.url = ''
                    this.display_url = ''
                    this.video_duration = '00:00:00'
                    this.caption = ''
                    this.post_identifier = ''
                    this.date = ''
                    this.likes = 0
                    this.dislikes = 0
                    this.comments = 0
                    this.views = 0
                    this.shares = 0
                    this.saves = 0

                    this.payloadExists = true;
                    this.step = 2;

                }
                catch(err) {
                    console.log(err);
                    notifyCatchError(err, this.$notify, 'Unable to save manual post. Please try again later')
                }
                finally {
                    this.loading = false;
                }
            },
            
            // validation
            nextDisabled() {
                let requiredFields = [];
                switch (this.step) {
                    case 1:
                        requiredFields = ['username', 'full_name', 'total_followers', 'total_following', 'total_likes', 'profile_picture_url' ];
                        break;
                    case 2:
                        requiredFields = ['url', 'display_url', 'video_duration', 'caption', 'post_identifier', 'date', 'likes', 'dislikes', 'comments', 'views', 'shares', 'saves' ];
                        break;
                }
                // validation
                let errors = 0;
                for (let i in requiredFields) {
                    let val = this[requiredFields[i]];
                    if (val === undefined || val === null || val === '' || (Array.isArray(val) && !val.length)) {
                        errors++;
                    }
                }
                return !!errors;
            },

            async getInfoFromPostUrl() {
                this.post_url = removeUnnecessaryQueryParams(this.post_url)
                const params = getParamsFromSocialPostUrl(this.post_url)
                if(params.postId) this.post_identifier = params.postId;
                if(params.videoId) this.post_identifier = params.videoId;
            },

            // file uploads
            uploadProfilePicture(e){
                this.uploadFile(e, 'profile_picture_url');
            },
            uploadMediaFile(e){
              this.uploadFile(e, 'url');
            },
            uploadDisplayFile(e){
              this.uploadFile(e, 'display_url');
            },
            uploadFile(e, path) {
              this.uploadingFile = true;
              let fileData = new FormData();
              fileData.append('file', e.target.files[0]);
              this.$http.post(`/api/files/manual/posts`, fileData).then(async ({data}) => {
                this[path] = data.url;
              }).catch((err) => {
                this[path] = '';
                console.log('upload picture error', err.response);
                this.$notify({ title: 'Something went wrong while uploading media.', text: 'Try again later', type: 'warn' });
              }).finally(() => {
                this.uploadingFile = false;
              });
            },
            openLink(url) {
              if (url !== '') {
                let a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                a.click();
              }
            }
        },
        computed: {
            computedManualPost() {

                let {
                    description,
                    platform,
                    medium,
                    url,
                    display_url,
                    date,
                    caption,
                    profile_picture_url,
                    bio,
                    full_name,
                    username,
                    profile_url,
                    social_id,
                    post_identifier,
                    video_duration,
                    likes,
                    dislikes,
                    comments,
                    views,
                    shares,
                    saves,
                    total_followers,
                    total_following,
                    total_likes,
                } = this;

                platform.name = platform.name.toLowerCase();
                let is_video = medium.id === 'video';

                return {
                    post_identifier,
                    date,
                    network: platform,
                    content_type_id: 1,
                    type: {id: 1, name: 'post'},
                    is_video,
                    url,
                    caption,
                    description,
                    display_url,
                    likes,
                    dislikes,
                    comments,
                    views,
                    shares,
                    saves,
                    media: [
                        {mimetype: is_video ? 'video/mp4' : 'image/png', video_duration, url, poster: display_url}
                    ],
                    profile_item: {
                        bio,
                        uri: profile_url,
                        username,
                        full_name,
                        social_id,
                        total_followers,
                        total_following,
                        total_likes,
                        profile_picture_url,
                        total_views: 0,
                        total_videos: 0
                    }
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    // global
    .field{
        @apply gap-y-1;
    }
    .label{
        @apply text-h6 font-bold;
    }
    .container{
        @apply flex flex-col justify-between;
        min-height: 260px;
        .section{
            @apply grid grid-cols-6 gap-4;
            > .file-field{
                @apply flex flex-col gap-y-1;
                > .header{
                    @apply w-full flex justify-between;
                }
                > .help{
                    @apply text-xs italic;
                }
                > .input-container{
                    @apply w-full relative;
                    > .upload-button{
                        @apply absolute right-0 top-0 h-12 w-12 border rounded-md bg-white flex justify-center items-center;
                    }
                }
            }
        }

        // .preview{
        //     @apply flex gap-2;
        //     > .container{
        //         @apply flex w-full justify-center;
        //     }
        // }
        .footer{
            @apply mt-4 flex justify-between;
        }
    }
</style>

<template>
    <div class="sidebar">
        <div class="section" v-for="(section, index) in sections" :key="index">
            <div 
                class="title" 
                :class="{ 'active': isSectionActive(section) }"
                @click="$router.push(`/docs/${itemRoute(section.items[0])}`)"
            >
                {{section.name}}
            </div>
            <div 
                v-for="(item, i) in section.items" 
                :key="i" 
                @click="$router.push(`/docs/${itemRoute(item)}`)" 
                class="item"
                :class="{ 'active': itemRoute(item) == $route.params.component }"
            >
                {{item.name}}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            sections: [
                {
                    name: 'Getting Started',
                    items: [
                        { name: 'Introduction' },
                        { name: 'Quick Start', route: 'QuickStart' }
                    ]
                },
                {
                    name: 'Components',
                    items: []
                }
            ],
            exclude: ['NotFound'],
            loading: false
        }
    },
    mounted(){
        this.getComponents()
    }, 
    methods: {
        itemRoute(item){
            return item.route ? item.route : item.name
        },
        isSectionActive(section){
            return section.items.map(element => element.route).includes(this.$route.params.component)  
        },
        getComponents(){
            // get all directory components
            const res = require.context('./components', true, /^.*\.vue$/)
            let components = res.keys()

            // get all excluded components from the "Components" section
            const filteredSections = this.sections.filter(section => section.name != 'Components').map(section => {
                return section.items.map(item => item.route || item.name)
            })
            const excludedComponents = [].concat.apply([], filteredSections).concat(this.exclude);
            
            // set the "Components" section items (exclude excluded components)
            const componentsSection = this.sections.find(section => section.name == 'Components')
            const index = this.sections.indexOf(componentsSection)
            this.sections[index].items = components.filter(component => {
                let name = component.replace('./','').replace('.vue','')
                return !excludedComponents.includes(name)
            }).map(component => {
                let name = component.replace('./','').replace('.vue','')
                return { name: name }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .sidebar{
        @apply w-72 flex flex-col gap-y-8 flex-shrink-0 flex-grow-0 border-r border-white py-10 pb-4 px-12 text-white;
        @apply sticky left-0 bottom-0 h-screen overflow-y-auto z-10;
        height: calc(100vh - 73px); // header height
        top: 73px;

        .section{
            @apply flex flex-col gap-y-2;
            .title{
                @apply text-h4 font-bold cursor-pointer;
                &.active{
                    @apply text-green-m-main;
                }
            }
            .item{
                @apply text-h6 cursor-pointer;
                &:hover{
                    @apply opacity-50;
                }
                &.active{
                    @apply font-bold text-green-m-main;
                }
            }
        }
    }
</style>
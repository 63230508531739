<template>
    <div>

        <!-- no option selected -->
        <div v-if="!selectedOption">

            <div class="option-list">
                <div
                    class="option"
                    v-for="(option, index) in options" 
                    :key="index"
                    @click="selectedOption = option.id"
                >
                    <div class="flex justify-between items-center">

                        <div>
                            <div class="text-h5 font-bold">{{ option.label }}</div>
                            <div class="text-h5">{{ option.description }}</div>
                        </div>
                        
                        <base-icon name="arrow-right" :size="6" />       
                    
                    </div>
                </div>
            </div>

        </div>

        <!-- search option -->
        <div v-if="selectedOption == 'search'">
            <div class="mb-6">This will make a call to facebook to update the user facebook pages and tokens.</div>
        </div>

        <!-- manual option -->
        <div v-if="selectedOption == 'manual'">
            <FormulateInput label="Facebook page ID" v-model="pageId" />
        </div>

        <!-- request option -->
        <div v-if="selectedOption == 'request'" class="font-bold">
            <div>A request will be sent to the user, when they login on the platform they will see a notification indicating that they need to input their facebook page ID</div>
            <FormulateInput
                type="checkbox"
                label="Send email"
                v-model="sendEmail"
                class="mt-4"
            />
        </div>

        <div class="footer">
            <base-button
                type="secondary"
                icon="arrow-left"
                @action="selectedOption ? selectedOption = null : $emit('back')"
                label="Back"
            />
            <base-button
                v-if="selectedOption == 'search'"
                label="Search"
                icon="search"
                @action="search()"
            />
            <base-button
                v-if="selectedOption == 'manual'"
                :disabled="!pageId || pageId.trim() == ''"
                @action="$emit('setPageId', pageId); $emit('submit', 'permission-request', null);"
                label="Submit"
            />
            <base-button
                v-if="selectedOption == 'request'"
                @action="sendRequest()"
                label="Send"
            />
        </div>
    </div>
</template>
<script>
export default {
    props: ['user'],
    data(){
        return {
            options: [
                { id: 'search', label: 'Search', description: `Make a call to facebook to search for user's facebook pages` },
                { id: 'manual', label: 'Manual', description: `Manually enter the user's facebook page ID` },
                { id: 'request', label: 'Request', description: `Send a request to the user to input their facebook page ID` }
            ],
            selectedOption: null,
            
            pageId: '',
            sendEmail: false,
            loading: false,
        }
    },
    methods: {
        search(){
            this.loading = true
            this.$http.post(`/api/facebook/refresh-pages`, {
                user_id: this.user.id
            }).then(async (res) => {
                if(res.data && res.data.message){
                    this.$notify({ title: res.data.message, text: '', type: 'success' })
                }
            }).catch((err) => {
                console.log('search error', err)
                if(err.response && err.response.data && err.response.data.message){
                    this.$notify({ title: 'Warning', text: err.response.data.message, type: 'warn' })
                }else{
                    this.$notify({ title: 'Warning', text: 'Something went wrong', type: 'warn' })
                }
            }).finally(() => {
                this.loading = false
            })  
        },
        // manual request
        sendRequest(){
            this.loading = true
            this.$http.post('/api/user-fb-page', {
                user_id: this.user.id,
                send_email: this.sendEmail,
                location: `${window.location.origin}/fb-page-request`,
            }).then(async (res) => {
                if(res.data){
                    this.$notify({ title: 'Request sent successfully', text: '', type: 'success' })
                    this.selectedOption = null
                    this.$emit('close')
                }
            }).catch((err) => {
                console.log('sendRequest error', err)
                if(err.response && err.response.data && err.response.data.message){
                    this.$notify({ title: 'Warning', text: err.response.data.message, type: 'warn' })
                }else{
                    this.$notify({ title: 'Warning', text: 'Something went wrong', type: 'warn' })
                }
            }).finally(() => {
                this.loading = false
            })    
        }
    }
}
</script>
<style lang="scss" scoped>
    .option-list{
        @apply flex flex-col gap-y-4;
        .option{
            @apply relative px-6 py-6 bg-white rounded-xl cursor-pointer transition-opacity duration-500;
            &:hover{
                @apply opacity-50;
            }
        }
    }
    .footer{
        @apply flex justify-between mt-10;
    }
</style>

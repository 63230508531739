import axios from 'axios';

export const statuses = {
    namespaced: true,
    state: {
        statuses: [],
        filterableStatuses: []
    },
    mutations: {
        setStatuses(state, val) {
            state.statuses = val;
        },
        setFilterableStatuses(state, val){
            state.filterableStatuses = val
        }
    },
    actions: {
        async getStatuses({commit, state}){

            if (state.statuses.length > 0) {
                return;
            }

            const { data } = await axios.get('/api/statuses');
        
            let value, label;
            let s = []
            if (data) {
                for (let i in data) {
                    value = label = data[i].name;
                    if (!s.find(v => v.value === value)) {
                        s.push({value, label})
                    }
                }
            }
            await commit('setFilterableStatuses', s)
            
            await commit('setStatuses', data ? data.map(status => {
                status.showActions = false;
                status.editing = false;
                status.editName = status.name;
                status.editColor = status.color;
                status.deleting = false;
                return status
            }) : [])
        
        }
    }
};
<template>
    <base-button
        @action="login"
        v-if="clientId !== '' && developerKey !== '' && redirect_uri !== ''"
        icon="google"
        bold
        class="mt-6 w-full"
        :label="label"
        :disabled="disabled || loading"
        type="secondary"
        theme="dark"
        border
    />
</template>
<script>
import { decryptStr, jwtDecrypt, validString } from '@/lib/strings';
import { buildGoogleAuthUrl, getUrlFromStateQuery } from '@/lib/google'
import { mapGetters } from "vuex";
// https://developers.google.com/identity/protocols/oauth2/web-server?hl=es-419
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'Sign in with Gmail'
        },
        // not using this
        // internalToken: {
        //     type: String,
        //     default: ''
        // },
        isLogged: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            passphrase: 'google',
            developerKey: '',
            clientId: '',
            loading: false,
            ready: false,
            redirect_uri: '',
        }
    },

    computed: {
        ...mapGetters(['googleAuth']),
    },

    beforeMount() {
        this.checkStateAndCode();
        // try to get the google token with the hard_code, this should happen when the user was redirected from one origin/subdomain to another one
        this.getTokenFromGoogle();
    },

    mounted() {
        this.getCredentials();
    },

    methods: {
        async checkStateAndCode() {
            let state = this.$route.query.state;
            let code = this.$route.query.code;

            // if state & code exist
            if (validString(state) && validString(code)) {
                // console.log('(checkStateAndCode) => state and code exist');
                // get the url from the state query, this url should be where the authObject should be saved
                let url = getUrlFromStateQuery(state);
                if(validString(url)){
                    // console.log('(checkStateAndCode) => query url:', url);
                    // if its NOT the same origin/subdomain as the current session, redirect the user to it, but with the hard_code
                    if (!url.includes(window.location.origin)) {
                        url = url + '?hard_code=' + code;
                        // [!] Redirecting
                        this.$swal.fire({
                            title: 'Redirecting to',
                            text: url,
                            icon: 'success',
                            confirmButtonText: 'Continue',
                            customClass: {
                                actions: 'flex justify-center',
                                confirmButton: 'mx-auto',
                                cancelButton: 'hidden' 
                            } 
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = url;     
                            }else{
                                window.close();
                            }
                        });
                    } else {
                        console.log('(checkStateAndCode) => is on the same origin/domain as current session');
                        // if its the same origin, get the token from google
                        this.getTokenFromGoogle(code);
                    }
                }
            }else{
                console.log('(checkStateAndCode) => state or code query not valid')
            }
        },

        async getTokenFromGoogle(code = null) {
            
            // if a hard_code exists, overwrite the code
            let hard_code = this.$route.query.hard_code;
            if (validString(hard_code)) {
                // console.log('(getTokenFromGoogle) => overwriting code with hard_code')
                code = hard_code;
            }

            if (validString(code)) {
                this.loading = true
                // console.log('(getTokenFromGoogle) => final code', code)
                try {
                    let isRegister = this.$route.name == 'register';
                    let isLogged = this.isLogged;
                    const { data } = await this.$http.post(`/api/auth/google/token`, { 
                        code, 
                        isRegister, 
                        isLogged 
                    });
                    // console.log('response from /api/auth/google/token', data)
                    if (data) {
                        this.handleGoogleToken(data);
                    } else {
                        this.onTokenError();
                    }
                } catch (e) {
                    this.onTokenError(e);
                }
            }else{
                console.log('(getTokenFromGoogle) => code is not defined')
            }
        },


        async getCredentials() {
            const { data } = await this.$http.get(`/api/auth/get-auth-credentials/google`);
            // console.log('(getCredentials) => got credentials')
            this.developerKey = decryptStr(data.GOOGLE_DEVELOPER_KEY, this.passphrase);
            this.clientId = decryptStr(data.GOOGLE_CLIENT_ID, this.passphrase);

            // this.redirect_uri HAS to be one of the authorized urls on google console
            // this flow just makes sure that we are hitting the /setAccount endpoint
            let redirect_uri = decryptStr(data.GOOGLE_REDIRECT_URI, this.passphrase);
            if (this.$route.name == 'register') {
                redirect_uri = redirect_uri.replace('/login', '/register');
            }
            redirect_uri = redirect_uri.replace('/login', '/setAccount').replace('/register', '/setAccount');
            this.redirect_uri = redirect_uri;
            console.log('redirect URI', this.redirect_uri);
        },

        async login() {
            
            // this url is where the authObject will be saved, it may have "/login" in it 
            let url = window.location.href
            // we replace the /login or /register endpoints with /setAccount
            url = url.replace('/login', '/setAccount').replace('/register', '/setAccount');

            // not using this
            // let t = this.internalToken && this.internalToken !== '' ? `&t=${this.internalToken}` : '';
            // Appended at the end of the state value ("state": `....${t}`)
            
            const uri = buildGoogleAuthUrl({
                "response_type": "code",
                "client_id": this.clientId,
                "scope": "openid email",
                // this has to be one of the redirect urls on GCP
                "redirect_uri": this.redirect_uri,
                // url should be the url where the authObject will be saved
                "state": `security_token=token&url=${url}`, // here we could save additional data if we want: "&john=doe""
                "nonce": "encodednonce"
            });

            // console.log('(handleLogged) => uri', uri)

            // open the window
            let win = window.open(uri, "", "width=600,height=800");
            
            // Listen for the window closed event
            var timer = setInterval(async () => { 
                if (win.closed) {
                    // once the window is closed, search localstorage for the token
                    // that was saved on the opened window
                    clearInterval(timer);
                    // console.log('(handleLogged) => window closed, executing this.searchAuthObjectInLocalStorage()') 
                    await this.searchAuthObjectInLocalStorage();
                }else{
                    console.log('(handleLogged) => window not closed')
                }
            }, 1000);
        },

        async searchAuthObjectInLocalStorage() {
            let authObject = JSON.parse(localStorage.getItem('google_auth')) || null;
            if (authObject) {
                // console.log('(searchAuthObjectInLocalStorage) => authObject', authObject)
                await this.$store.dispatch('auth/setGoogleAuth', authObject);
                this.$emit('success', authObject, true);
            } else{
                console.log('(searchAuthObjectInLocalStorage) => authObject is not defined')
            }
        },

        async handleGoogleToken(response) {
            this.$emit('gettingToken', true);
            this.gettingToken = true;
            try {
                let data = jwtDecrypt(response.id_token);
                const { email, sub } = data;
                let authObject = {
                    email,
                    userId: sub,
                    networkId: 10
                };
                // console.log('(handleGoogleToken) => authObject:', authObject)
                if (this.isLogged) {
                    // console.log('(handleGoogleToken) => this.isLogged is true, executing this.saveAuthObjectInLocalStorage()')
                    this.saveAuthObjectInLocalStorage(authObject);
                } else {
                    // console.log('(handleGoogleToken) => this.isLogged is false, only emitting success')
                    this.$emit('success', authObject, true);
                }
            } catch (e) {
                console.log(e);
                console.log('(handleGoogleToken) => error thrown, executing this.saveAuthObjectInLocalStorage() with error')
                this.saveAuthObjectInLocalStorage();
            } finally {
                this.$emit('gettingToken', false);
                // [!] Closing window
                this.$swal.fire({
                    title: 'Done',
                    text: 'Close this window',
                    icon: 'success',
                    confirmButtonText: 'Close',
                    customClass: {
                        actions: 'flex justify-center',
                        confirmButton: 'mx-auto',
                        cancelButton: 'hidden' 
                    } 
                }).then(() => {
                    window.close();
                });
                this.gettingToken = false;
            }
        },
        
        // save the auth object that we got from google, this will happen on the opened window
        async saveAuthObjectInLocalStorage(authObject = null) {
            authObject = authObject !== null ? authObject : { error: true };
            // console.log('(saveAuthObjectInLocalStorage) => authObject', authObject)
            await this.$store.dispatch('auth/setGoogleAuth', authObject, { root: true });
            // [!] Closing window
            this.$swal.fire({
                title: 'Done',
                text: 'Close this window',
                icon: 'success',
                confirmButtonText: 'Close',
                customClass: {
                    actions: 'flex justify-center',
                    confirmButton: 'mx-auto',
                    cancelButton: 'hidden' 
                } 
            }).then(() => {
                window.close();
            });
        },

        onTokenError(e = null) {
            this.$notify({ title: 'Warning', text: 'There was a problem authenticating with Google. Try again later.', type: 'warn' });
            if (e !== null) {
                this.$emit('error', e);
            }
            this.$emit('loading', false);
            this.loading = false;
        },
        
        // we are not using this 
        // async prepareGAuth() {
        //     window.google.accounts.id.initialize({
        //         client_id: this.clientId,
        //         callback: this.login
        //     });
        //     window.google.accounts.id.renderButton(
        //         document.getElementById('google-auth-btn'),
        //         { theme: "outline", size: "large", shape: "pill", width: 368 }  // customization attributes
        //     );
        // }
    }
}
</script>
<template>
    <div class="min-h-screen bg-purple-m-main flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-tan-m py-10 px-4 shadow sm:rounded-lg sm:px-10">
                <div class="flex justify-center">
                    <base-icon class="text-purple-m-main" :size="14" name="exclamation-circle"/>
                </div>
                <div class="mt-4 text-center text-3xl leading-9 font-extrabold">Something went wrong…</div>
                <div class="mt-2 text-center">The company you are looking for could not be found. You can go back, or go to our homepage.</div>
                <div class="w-full flex justify-between mt-8">
                    <base-button 
                        @action="$router.back()"         
                        icon="arrow-narrow-left"
                    >
                        Go back
                    </base-button>
                    <base-button 
                        @action="redirectHome()"         
                    >
                        Go home
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        redirectHome(){
            return window.location.href = "http://creatorsaurus.com/"
        }
    }
}
</script>
import axios from 'axios';
import store from './store'
import numeral from 'numeral';
import moment from 'moment';

export const NETWORKS = Object.freeze({
    2: {
        id: 2,
        name: 'instagram',
        display_name: 'Instagram'
    },
    3: {
        id: 3,
        name: 'youtube',
        display_name: 'Youtube'
    },
    4: {
        id: 4,
        name: 'pinterest',
        display_name: 'Pinterest'
    },
    6: {
        id: 6,
        name: 'facebook',
        display_name: 'Facebook'
    },
    7: {
        id: 7,
        name: 'twitter',
        display_name: 'Twitter'
    },
    14: {
        id: 14,
        name: 'tiktok',
        display_name: 'TikTok'
    },
    16: {
        id: 16,
        name: 'linkedin',
        display_name: 'LinkedIn'
    },
    17: {
        id: 17,
        name: 'custom',
        display_name: 'Other Network'
    }
});

export function formatMoney(value) {
    if (typeof value !== 'number') {
        value = parseFloat(value);
        if (isNaN(value)) {
            return '';
        }
    }

    const cleanValue = value.toString().replace(/[^0-9.]/g, '');
    const parts = cleanValue.split('.');
    const dollars = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let cents = '';

    if (parts.length > 1) {
        cents = parts[1].slice(0, 2);
        while (cents.length < 2) {
            cents += '0';
        }
        cents = '.' + cents;
    }

    return `$${dollars}${cents}`;
}

export function cleanMoney(value) {
  const cleanedValue = value.replace(/[^0-9.,]/g, '').replace(/,/g, '');
  const floatValue = parseFloat(cleanedValue);
  return isNaN(floatValue) ? 0 : Math.round(floatValue);
}

export async function fileUpload(file, opts, config = {}) {
    const payload = new FormData();

    payload.append("file", file);
    
    try {
        const { data } = await axios.post('/api/upload-file', payload, {params: opts, ...config});
        return data;
    }
    catch(e) {
        console.log("error uploading file");
        return { error: true, message: e.message }
    }
}

export async function briefAttachmentUpload(file, opts) {
    try {

        // Generate the signed url:
        const getSignedUrlResponse = await axios.get(`/api/files/signed-url?filename=${file.name}`);
        const signedUrl = getSignedUrlResponse.data
        console.log('Signed url successfully generated for file: ', file.name, signedUrl)

        // Upload file directly to google bucket:
        // We cant make the request with axios nor this.$http, but it works with fetch.
        // https://github.com/googleapis/nodejs-storage/issues/347#issuecomment-799701925
        const uploadFileResponse = await fetch(signedUrl, { body: file, method: 'PUT', headers: { 'Content-Type': 'application/octet-stream' }})
        const { url } = uploadFileResponse
        const fileUrl = url.split("?")[0] // remove query params
        console.log('File successfully uploaded to google bucket: ', fileUrl)

        // Create attachment record on the DB:
        const uploadBriefAttachmentResponse = await axios.post('/api/files/brief-attachment', {
            name: file.name,
            url: fileUrl,
            size: file.size,
            mimetype: file.type
        }, { params: opts });
        console.log('DB attachment created!')
        
        return uploadBriefAttachmentResponse.data
    }
    catch(e) {
        console.log("error on briefAttachmentUpload");
        return { error: true, message: e.message }
    }
}

export async function draftUpload(file, opts) {

    try {

        // Generate the signed url:
        const getSignedUrlResponse = await axios.get(`/api/files/signed-url?filename=${file.name}`);
        const signedUrl = getSignedUrlResponse.data
        console.log('Signed url successfully generated for file: ', file.name, signedUrl)

        // Upload file directly to google bucket:
        // We cant make the request with axios nor this.$http, but it works with fetch.
        // https://github.com/googleapis/nodejs-storage/issues/347#issuecomment-799701925
        const uploadFileResponse = await fetch(signedUrl, { body: file, method: 'PUT', headers: { 'Content-Type': 'application/octet-stream' }})
        const { url } = uploadFileResponse
        const fileUrl = url.split("?")[0] // remove query params
        console.log('File successfully uploaded to google bucket: ', fileUrl)

        // Create attachment record on the DB:
        const uploadDraftResponse = await axios.post('/api/files/draft', {
            name: file.name,
            url: fileUrl,
            size: file.size,
            mimetype: file.type
        }, { params: opts });
        console.log('DB attachment created!')
        
        return uploadDraftResponse.data
    }
    catch(e) {
        console.log("error on draftUpload");
        return { error: true, message: e.message }
    }
}

export async function linkUpload(opts) {
    try {
        const { data } = await axios.post('/api/upload-file/link', opts);
        return data;
    }
    catch(e) {
        console.log("error uploading file");
        return { error: true, message: e.message }
    }
}

export function setTitle(title) {
    let companyName = store.getters.company ? store.getters.company.name : null;
    let titleString = companyName ? ' - ' + companyName : '';
    window.document.title = title + titleString;
}

export function customNetwork(network_id = null, fillZero = true) {
    return {
        network_id: network_id !== null ? { id: network_id, name: '' } : {},
        social_url: '',
        // user_id: this.newInfluencer.id,
        is_custom: true,
        payload: {
            audience_comments: {},
            audience_followers: {},
            audience_likers: {},
            extra: {},
            user_profile: {
                geo: {
                    city: {
                        id: 0,
                        name: '',
                        coords: {
                            lat: null,
                            lon: null
                        }
                    }
                },
                account_type: 2,
                avg_comments: 0,
                avg_likes: fillZero ? 0 : '',
                avg_reels_plays: 0,
                avg_views: 0,
                brand_affinity: [],
                commercial_posts: [],
                contacts: [],
                description: '',
                engagements_rate: 0,
                engagements: '',
                followers: fillZero ? 0 : '',
                fullname: '',
                gender: '',
                interests: [],
                is_business: false,
                is_hidden: false,
                is_verified: false,
                language: {},
                paid_post_performance: 0,
                picture: '',
                posts_count: 0,
                posts_with_hidden_likes_percentage: 0,
                recent_posts: [],
                recent_reels: [],
                relevant_tags: [],
                similar_users: [],
                stat_history: [],
                top_hashtags: [],
                top_mentions: [],
                top_posts: [],
                top_reels: [],
                type: '',
                url: '',
                user_id: '',
                username: ''
            }
        }
    };
}

export function editorToolbar() {
    return [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
        ],
        ["blockquote", "code-block"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { list: "check" }
        ],
        [
            { indent: "-1" },
            { indent: "+1" }
        ],
        [
            { color: [] },
            { background: [] }
        ],
        ["link"],
        ["clean"]
    ];
}

export function copyToClipBoard(notify, text, message = 'Link copied to clipboard') {
    let successFn = () => { notify({ title: 'Success', text: message, type: 'success' }); };
    let failFn = (e) => { console.warn('Copy to clipboard failed.', e); };
    let catchedFn = () => {
        if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
            let textarea = document.createElement('textarea');
            textarea.textContent = text;
            textarea.style.position = 'fixed';
            document.body.appendChild(textarea);
            textarea.select();

            try {
                document.execCommand('copy');
                successFn();
            } catch (e) {
                failFn(e);
            } finally {
                document.body.removeChild(textarea);
            }
        }
    };
    if (navigator.clipboard && window.isSecureContext) {
        try {
            navigator.clipboard.writeText(text);
            successFn();
        } catch (e) {
            console.warn('Failed to copy by navigator', e);
            catchedFn();
        }
    } else {
        catchedFn();
    }
}

export function getObjectValue(object, path, fallbackValue = null) {
    const res = path.
        replace(/\[/g, '.').
        replace(/\]/g, '').
        split('.').
        reduce((o, k) => (o || {})[k], object);
    return res || fallbackValue
}

export function errorMessage(errorObject, defaultMessage = 'An error occurred, try again later.'){
    let foundErrorMessage = getObjectValue(errorObject, 'response.data.message')
    return foundErrorMessage ? foundErrorMessage : defaultMessage  
}

// logs, finds the message and notifies
export function notifyCatchError(errorObject, notify, defaultMessage = 'An error occurred, try again later.'){
    console.log('an error ocurred', errorObject)
    const message = errorMessage(errorObject, defaultMessage)
    notify({ title: 'Error', text: message, type: 'warn' })
}

export function sumArray(arr){
    return arr.reduce((partialSum, a) => partialSum + a, 0);
}

// assuming its an array of objects
export function sortArray(arr, key, order = 'desc'){
    let copy = arr.slice()
    copy.sort(function(a, b) {
        return order == 'desc' ? (b[key] - a[key]) : (a[key] - b[key]);
    });
    return copy;
}

export function isNotNull(val){
    return val && val !== undefined && val !== null; 
}
export function isEmpty(obj){
    return !Object.keys(obj).length;
}
export function isObject(obj){
    return isNotNull(obj) && typeof obj == 'object' && Object.keys(obj).length;
}
export function isValidObject(obj){
    return isNotNull(obj) && typeof obj == 'object' && Object.keys(obj).length;
}
export function isNumber(val){
    return isNotNull(val) && typeof val == 'number';
}
export function isNumberString(str) {
    if (typeof str != "string") return false;  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}
export function isArray(arr){
    return isNotNull(arr) && Array.isArray(arr) && arr.length;
}
export function isValidArray(arr){
    return isNotNull(arr) && Array.isArray(arr) && arr.length;
}
export function isString(val){
    return isNotNull(val) && typeof val == 'string';
}
export function isValidString(val){
    return isString(val) && val.trim() !== '';
}
export function copyObject(object){
    return JSON.parse(JSON.stringify(object));
}
export function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
}
// by default we will assume the date is for a post
// in those cases we will use the dateIsTenYearsOld() function
// other dates like deliverable due dates or birthdates dont need this validation
export function isValidDate(val, isPostDate = true){
    if(isNotNull(val) && (typeof val == 'string' || typeof val == 'object')){
        let res = moment(val);
        const isValid = res.isValid();
        if(isValid){
            if(isPostDate){
                return !dateIsTenYearsOld(val);
            }else{
                return true;
            }
        }
    }
    return false;
}
export function dateIsTenYearsOld(val){
    let date = new Date(val);
    let tenYearsAgo = new Date();
    tenYearsAgo.setFullYear(tenYearsAgo.getFullYear() - 10);
    if (date < tenYearsAgo) {
        return true;
    }
    return false;
}

// Formats number with numeraljs
// http://numeraljs.com/
export function convertNumeral(val, format = '0a'){
    // currency
    if (!val && ['$0,0.00', '$0,0', '$0,0a', '$0,0.0a'].includes(format)) {
        return '$0.00';
    }

    // percentages
    if (!val && ['0.0%', '0.00%'].includes(format)) {
        return '0%';
    }

    // numbers
    if (val < 1000 && ['0,0', '0,0a', '0.0a', '0.0a'].includes(format)) {
        return val;
    }

    // null val, and none of the above formats
    if (!val) {
        return val;
    }

    return numeral(val).format(format);
}
<template>
    <div :class="{'bg-tan-m': !isBrandReport}" :style="reportStyle">
        <!-- id required to apply styles while printing -->
        <div 
            id="report-wrapper"
            class="curated-report-wrapper min-h-screen"
            :class="{'flex justify-center overflow-visible' : printView}"
        >
            <template v-if="!locked">
                <div class="px-2 py-1 text-center bg-gray-500 text-white" v-if="reportData.refreshing">
                    This report is currently being updated.
                    <span @click="refreshPage" class="bg-white rounded text-sm text-gray-500 font-bold px-2 py-0.5 cursor-pointer">Refresh page</span>
                </div>
            
                <template v-if="reportData.constructor === Object && !Object.keys(reportData).length">
                    <user-report-skeleton  v-if="isUserReport"/>
                    <posts-report-skeleton v-if="isPostReport"/>   
                    <brand-report-skeleton v-if="isBrandReport"/>
                </template>
                <!-- 'user-report' 'posts-report' 'brand-report' -->
                <component 
                    v-else 
                    :is="componentName" 
                    :report-data="reportData" 
                    :media-values="mediaValues"
                    :printView="printView"
                    :deliverables="deliverables"
                    @download-pdf="downloadPdf()"
                    @download-csv="downloadCsv"
                    @refresh="getData"
                    @edit-post="editPost"
                    @link="linkPost"
                    @reload-user="reloadUser"
                    @change-view="val => view = val"
                    :key="reportKey"
                    :loading-posts="loadingPosts"
                    :show-preview="reportData.settings.preview"
                    :user="user"
                />
            </template>
        
            <report-password-modal
                :visible="reportPasswordModalVisible"
                :reportId="id"
                @close="reportPasswordModalVisible = false"
                @unlocked="unlocked"
            />
        </div>
        
        <fixed-loader :visible="reloadingInfluencer" label="Getting data" :index="80"/>

        <advanced-loader 
            :opacity="90"
            :index="100" 
            :visible="printing || loadingPosts" 
            :label="loadingPosts ? 'We are generating the content' : 'Generating file,'" 
            :percentage="completedConvertedPost" 
            :spinner="!loadingPosts"
        />

        <update-post 
            :open="editingPost && (currentPost.id !== undefined || editingCreatorReport)" 
            :current-post="currentPost" 
            @close="cancelEditing" 
            @finish-update="finishUpdate"
        />

        <link-post 
            :open="currentLinkPost.id !== undefined" 
            :deliverables="deliverables" 
            :current-post="currentLinkPost" 
            @close="currentLinkPost = {}" 
            @finish-update="finishUpdate"
        />
    </div>
</template>
<script>
import UserReport from './UserReport';
import UserReportSkeleton from './UserReportSkeleton';
import PostsReport from './PostsReport';
import PostsReportSkeleton from './PostsReportSkeleton';
import ReportPasswordModal from '../../components/ReportPasswordModal.vue';
import generatePdf from '../../lib/pdf'
import generateCsv from '../../lib/csv'
import { addStyles } from '../../lib/dom'
import moment from 'moment'
import BrandReport from './BrandReport';
import BrandReportSkeleton from './BrandReportSkeleton';
import UpdatePost from '../ReportCreate/UpdatePost';
import LinkPost from '../ReportCreate/LinkPost';
import { isNumber, isString, isValidDate } from '../../common';
export default {
    components: {
        UpdatePost,
        LinkPost,
        UserReport,
        UserReportSkeleton,
        PostsReport,
        PostsReportSkeleton,
        BrandReport,
        BrandReportSkeleton,
        ReportPasswordModal
    },

    data() {
        return {
            reportData: {},
            mediaValues: [],

            id: null,
            type: null,
            locked: false,
            reportPasswordModalVisible: false,

            printing: false,
            printView: false,
            reportKey: false,
            editingCreatorReport: false,
            deliverables: [],

            user: null,

            currentPost: {
                profile_item: {}
            },
            currentLinkPost: {},
            editingPost: false,
            loadingPosts: false,
            postsLoaded: 0,

            reloadingInfluencer: false,

            downloadPdfTries: 0,

            // child component's view, needed for pdf generation
            view: 'grid'
        }
    },

    computed: {
        isUserReport() {
            return this.type === 1;
        },
        isPostReport() {
            return this.type === 2;
        },
        isBrandReport() {
            return this.type === 3;
        },
        posts() {
            if (this.reportData.posts && Array.isArray(this.reportData.posts) && this.reportData.posts.length) {
                return this.reportData.posts;
            }

            return [];
        },
        componentName() {
            return this.isUserReport ? 'user-report' : this.isPostReport ? 'posts-report' : 'brand-report';
        },
        reportStyle() {
            if (this.isBrandReport) {
                return {
                    backgroundColor: this.reportData.brand ? this.reportData.brand.bg_color : '#FFF'
                }
            }
            return {};
        },
        completedConvertedPost() {
            if (this.posts.length) {
                return parseInt(this.postsLoaded * 100 / this.posts.length);
            }
            return 0;
        }
    },

    mounted() {
        this.getUser();
        this.getIntent();
        this.getValues();
    },
    
    methods: {
        refreshPage() {
            this.getData();
        },

        async reloadUser(user) {
            try {
                this.reloadingInfluencer = true;
                let {data} = await this.$http.post(`/api/reports/${this.$route.params.id}/get-account`, {id: user.id});

                if (data.message) {
                    this.$notify({title: 'Success', text: data.message, type: 'success'});
                    await this.getData();
                } else {
                    this.$notify({ title: 'Error', text: 'We can not get the audience data. Try again alter', type: 'warn' });
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.reloadingInfluencer = false;
            }
            this.$forceUpdate();
        },

        linkPost(post) {
            this.currentLinkPost = post;
        },

        editPost(post, isCreator = false) {
            this.currentPost = post;
            this.editingPost = true;
            this.editingCreatorReport = isCreator;
        },

        cancelEditing() {
            this.currentPost = {profile_item: {}};
            this.currentLinkPost = {};
            this.editingPost = false;
        },

        finishUpdate(post) {

            this.cancelEditing();
            
            try{
                // to prevent the whole grid from refreshing (and sending the user to the top everytime we update a post) now 
                // we only update on the frontend, we will find the updated post in this.reportData and set the new values
                const username = post.profile_item.username;
                const network_id = post.profile_item.network_id;
                const post_id = post.id;

                let updated_at = new Date();
                
                // set the object properties that we will update
                const obj = {
                    ...( isString(post.display_url) ? post.is_video ? { video: post.display_url } : { image: post.display_url } : {} ),
                    ...( isString(post.display_url) ? { display_url: post.display_url } : {} ),
                    ...( isString(post.profile_item.profile_picture_url) ? { user_picture: post.profile_item.profile_picture_url } : {} ),
                    ...( isString(post.caption) ? { text: post.caption,  } : {} ),
                    ...( isValidDate(post.date) ? { created: post.date } : {} ),
                    ...( isNumber(post.likes) || isString(post.likes) ? { likes: parseInt(post.likes) } : {} ),
                    ...( isNumber(post.comments) || isString(post.comments) ? { comments: parseInt(post.comments) } : {} ),
                    ...( isNumber(post.views) || isString(post.views) ? { views: parseInt(post.views) } : {} ),
                    ...( isNumber(post.shares) || isString(post.shares) ? { shares: parseInt(post.shares) } : {} ),
                    ...( isNumber(post.saves) || isString(post.saves) ? { saves: parseInt(post.saves) } : {} ),

                    // content report specific properties
                    ...( this.isPostReport && isValidDate(post.date) ? { date: post.date } : {} ),
                    ...( this.isPostReport && (isNumber(post.profile_item.total_followers) || isString(post.profile_item.total_followers)) ? { followers: parseInt(post.profile_item.total_followers) } : {} ),
                    ...( this.isPostReport && isString(post.caption) ? { caption: post.caption } : {} ),

                    // updated at
                    updated_at
                }

                for (const key in obj) {
                    if (Object.hasOwnProperty.call(obj, key)) {
                        const value = obj[key];

                        if(this.isPostReport){

                            // get the array index to navigate and set the object properties
                            const postIndex = this.reportData.posts.findIndex(element => {
                                return element.id == post_id
                            });

                            if(key == 'followers'){
                                this.reportData.posts[postIndex].profile_item.total_followers = value;
                            }
                            if(key == 'user_picture'){
                                this.reportData.posts[postIndex].profile_item.profile_picture_url = value;
                            }
                                                        
                            this.reportData.posts[postIndex][key] = value;

                        }else{

                            // this could be "top_posts" or "featured_posts"
                            let found_on_top_posts = false;
                            let found_on_featured_posts = false;

                            // get the array indexes to navigate and set the object properties
                            const influencersIndex = this.reportData.influencers.findIndex(element => {
                                return element.social_accounts.map(element => element.handle).includes(username)
                            });
                            
                            // First try to find it on creators's "audience_data -> payload -> user_profile -> top_posts"
                            // -------------------------------------------------------------------------------------------
                            const audienceDataIndex = this.reportData.influencers[influencersIndex].audience_data.findIndex(element => {
                                return element.network_id == network_id
                            });
                            const postIndex = this.reportData.influencers[influencersIndex].audience_data[audienceDataIndex].payload.user_profile.top_posts.findIndex(element => {
                                return element.link == post.url;
                            });
                            if(audienceDataIndex !== -1 && postIndex !== -1){
                                found_on_top_posts = true;
                                // for creator reports, stats are in a nested stat:{} object
                                const stats = ["likes", "saves", "views", "shares", "comments"];
                                if(stats.includes(key)){
                                    this.reportData.influencers[influencersIndex].audience_data[audienceDataIndex].payload.user_profile.top_posts[postIndex].stat[key] = value;
                                }else{
                                    this.reportData.influencers[influencersIndex].audience_data[audienceDataIndex].payload.user_profile.top_posts[postIndex][key] = value;
                                }
                            }

                            // Then try to find it on creators's "report_user -> featured_posts"
                            // -------------------------------------------------------------------------------------------
                            if(this.reportData.influencers[influencersIndex].report_user && this.reportData.influencers[influencersIndex].report_user.featured_posts){
                                const postIndex = this.reportData.influencers[influencersIndex].report_user.featured_posts.findIndex(element => {
                                    return element.link == post.url;
                                });
                                if(postIndex !== -1){
                                    found_on_featured_posts = true;
                                    // for creator reports, stats are in a nested stat:{} object
                                    const stats = ["likes", "saves", "views", "shares", "comments"];
                                    if(stats.includes(key)){
                                        this.reportData.influencers[influencersIndex].report_user.featured_posts[postIndex].stat[key] = value;
                                    }else{
                                        this.reportData.influencers[influencersIndex].report_user.featured_posts[postIndex][key] = value;
                                    }
                                }
                            }
                            
                            // If it wasn't found on neither of them, throw the error
                            if(!found_on_featured_posts && !found_on_top_posts){
                                throw `The updated post was not found, neither in "featured_posts" nor in "top_posts"`
                            }
                        }
                    }
                }
                
                // refresh component only on content report
                if(this.isPostReport){
                    setTimeout(() => {
                        this.reportKey = !this.reportKey;
                    }, 1000);
                }

            }catch(err){
                console.log(err);
                this.$notify({ title: 'Unable to show changes', text: 'Refresh the page to view the updated post data', type: 'warn', duration: 8000 });
            }

        },

        async getUser() {
            try {
                const { data } = await this.$http.get('/api/me');
                this.user = data;
            } catch (e) {
                console.log(e);
            }
        },

        async getIntent() {
            const { data } = await this.$http.get(`/api/reports/${this.$route.params.id}/intent`);
            if (data) {
                this.id = data.id
                this.type = data.type
                this.locked = data.locked
                if (data.locked) {
                    this.reportPasswordModalVisible = true
                } else {
                    this.getData()
                }
            } else {
                this.$router.push('/report-not-found');
            }
        },

        unlocked(){
            this.locked = false
            this.getData()
        },

        async fillUsersData(data) {
            let replacedPosts = data.replaced_posts || [];
            data.replaced_posts = [];
            let replacedUsersIds = replacedPosts.map(r => r.user_id);
            let replacedPostsUrls = replacedPosts.map(r => r.original_url);
            if (data.influencers && Array.isArray(data.influencers) && data.influencers.length) {
                for(let i = 0; i < data.influencers.length; i++) {
                    if (data.influencers[i] !== undefined) {
                        let influencer = data.influencers[i];
                        if (replacedUsersIds.includes(influencer.id)) {
                            if (influencer.audience_data && Array.isArray(influencer.audience_data) && influencer.audience_data.length) {
                                for (let j = 0; j < influencer.audience_data.length; j++) {
                                    let audience = influencer.audience_data[j];
                                    if (audience !== undefined && audience.payload && audience.payload.user_profile && audience.payload.user_profile.top_posts) {
                                        let posts = audience.payload.user_profile.top_posts;
                                        for (let k = 0; k < posts.length; k++) {
                                            if (replacedPostsUrls.includes(posts[k].link)) {
                                                let currentPost = replacedPosts.find(p => {
                                                    return p.user_id == influencer.id && p.original_url == posts[k].link;
                                                });

                                                if (currentPost) {
                                                    let post = currentPost.post;
                                                    let is_copyrighted = !post.media.length;
                                                    let is_video = post.is_video && post.media && post.media.length;
                                                    let returnPost = {
                                                        created: post.created_at,
                                                        image: post.display_url,
                                                        link: post.url,
                                                        post_id: post.post_identifier,
                                                        stat: {
                                                            comments: post.comments,
                                                            likes: post.likes,
                                                            views: post.views,
                                                            shares: post.shares,
                                                            saves: post.saves
                                                        },
                                                        text: post.caption,
                                                        is_copyrighted,
                                                        thumbnail: post.display_url,
                                                        type: is_video ? 'video' : 'photo',
                                                        video: is_video ? post.media[0].url : null,
                                                        user_id: post.profile_item.social_id,
                                                        user_url: post.profile_item.uri,
                                                        username: post.profile_item.username,
                                                        user_picture: post.profile_item.profile_picture_url,
                                                        original_url: posts[k].link,
                                                        is_replaced: true
                                                    }

                                                    data.influencers[i].audience_data[j].payload.user_profile.top_posts[k] = returnPost;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            return data;
        },

        async getData() {

            try {
                // v2 endpoint now builds the payload on the fly, currently only supporst user report
                // const apiUrl = this.isUserReport ? `/api/reports/v2/${this.$route.params.id}` : `/api/reports/${this.$route.params.id}?onlyId=true`
                const apiUrl = `/api/reports/v2/${this.$route.params.id}`;
                const { data } = await this.$http.get(apiUrl);

                let name = data.name;
                if (data.type === 3 && data.brand) {
                    name = data.brand.name;
                }
                this.$setTitle(name);

                let posts = data.posts && Array.isArray(data.posts) ? [...data.posts] : [];
                delete data.posts;
                this.reportData = await this.fillUsersData(data);
                this.reportData.posts = posts;

                if (this.user && this.user.roles && Array.isArray(this.user.roles) && (this.user.roles.includes('admin') || this.user.roles.includes('superAdmin')) && data.type === 2) {
                    this.getDeliverables(data.guid);
                }

                this.reportData.allow_feedback = data.settings?.allow_feedback !== undefined ? data.settings.allow_feedback : true;

                this.reportKey = !this.reportKey;

            } catch (error) {
                if (error && error.response && error.response.status && error.response.status >= 400) {
                    // Handle the 401 error

                    this.$notify({ title: 'Error', text: error.response.data.message, type: 'error' });
                    this.$router.push("/home");

                } else {
                    // Handle other errors
                    console.error(error);
                }
            }
        },

        async getDeliverables(guid) {
            const {data} = await this.$http.get(`/api/userDeliverables/report/${guid}`);
            if (data) {
                this.deliverables = data;
            }
        },

        async getValues() {
            const { data } = await this.$http.get(`/api/reports/mediaValues`);
            this.mediaValues = data;
        },

        downloadCsv(type){
            
            var csv = ''
            var data = []

            if(type == 'influencers'){
                
                csv = 'Name,Email,Total Reach, Avg Engagements, IG Followers, Tiktok Followers, Description\n';

                data = this.reportData.influencers.map(influencer => {

                    let igData = influencer.audience_data.find(element => element.network_id == 2)
                    let tiktokData = influencer.audience_data.find(element => element.network_id == 14)
                    
                    return [
                        `${influencer.first_name} ${influencer.last_name}`,
                        influencer.email,
                        igData ? igData.payload.user_profile.followers : null,
                        igData ? igData.payload.user_profile.avg_likes : null,
                        igData ? igData.payload.user_profile.followers : null,
                        tiktokData ? tiktokData.payload.user_profile.followers : null,
                        `"${influencer.report_user.description}"`
                    ]
                })

            }
            if(type == 'posts'){

                csv = 'Network, Creator, Date, Caption, Likes, Dislikes, Comments, Views, Shares, Saves, Total Followers, Url\n'

                let regex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;

                data = this.reportData.posts.map(post => {
                    return [
                        post.network.display_name,
                        `"${post.profile_item.full_name}"`,
                        moment(post.date).format('MMMM Do YYYY'),
                        `"${post.caption.replace(/\n/g, '').replace(regex, '')}"`,
                        post.likes,
                        post.dislikes,
                        post.comments,
                        post.views,
                        post.shares,
                        post.saves,
                        post.profile_item.total_followers,
                        post.url
                    ]
                })

            }

            generateCsv(csv, data, 'report')
        },

        downloadPdf() {

            let fn = (time) => {
                this.printing = true;
                this.changeDocumentStyles('apply');
                setTimeout(() => {
                    this.renderPdf();
                }, time);
            };

            if (this.isPostReport) {
                let time = 3250;

                // I think this condition will always be met the first time you click the download pdf button.
                if (this.postsLoaded < this.posts.length) {
                    // posts are not loaded yet
                    this.getConvertedPosts(fn, time);
                } else {
                    // all posts loaded
                    fn(time);
                }
            } else {
                fn(0);
            }
        },

        changeDocumentStyles(option){
            let apply = option == 'apply';

            this.printView = apply;

            // TODO: might want to check nextTick to make sure
            // that the dom is fully updated after changing 
            // the printView var:

            this.$nextTick(() => {
                
                // wait 5000ms for printView changes to render
                setTimeout(() => {
                    let items = [
                        {
                            target: 'body', 
                            styles: [
                                { width: apply ? '1850px' : 'revert-layer'},
                                { overflow: apply ? 'hidden' : 'revert-layer' }
                            ]
                        },
                        {
                            target: '#report-wrapper', 
                            styles: [
                                {height: apply ? 'max-content' : ''},
                                {'max-height': apply ? 'max-content' : ''},
                                {background: apply ? '#f4f5f7' : ''}
                            ]
                        },
                        {
                            target: '#groups-content', 
                            styles: [
                                {'max-height': apply ? 'max-content' : ''},
                            ]
                        },
                        {
                            target: '.report-groups-container', 
                            styles: [
                                {display: apply ? 'flex' : 'block'},
                                {'flex-direction': apply ? 'row' : ''},
                                {'flex-wrap': apply ? 'wrap' : ''}
                            ]
                        },
                        {
                            target: '.stats', 
                            styles: [
                                { width: apply ? '100%' : 'revert-layer' },
                                { 'border-radius': apply ? '0px' : 'revert-layer' },
                            ]
                        },
                        {
                            target: '.groups', 
                            styles: [
                                { width: apply ? '100%' : 'revert-layer' },
                                { 'border-radius': apply ? '0px' : 'revert-layer' },
                            ]
                        },
                        {
                            target: '.actions', 
                            styles: [
                                { display: apply ? 'none' : 'revert-layer' }
                            ]  
                        },
                        {
                            target: '.reach-type-dropdown-wrapper', 
                            styles: [
                                { display: apply ? 'none' : 'revert-layer' }
                            ]  
                        },
                        ...( this.view == 'grid' ? [ {
                            target: '.group-item, .available-user-item', 
                            styles: [
                                { 'border-bottom': apply ? 'none' : 'revert-layer' },
                                { 'width': apply ? '250px' : 'revert-layer' },
                            ]
                        } ] : [] ),
                        {
                            target: '.report-post-bottom', 
                            styles: [
                                { display: apply ? 'none' : 'revert-layer' }
                            ]
                        },
                        // reset posts positions so we grab them individually and render
                        // them one by one, only on grid view
                        ...( this.view == 'grid' ? [ {
                            target: '.report-post-card-container',
                            styles: [
                                { position: apply ? 'flex' : 'revert-layer' },
                                { top: apply ? 'unset' : 'revert-layer' },
                                { left: apply ? 'unset' : 'revert-layer' }
                            ]
                        } ] : [] ),
                        // Invite to login buttons
                        {
                            target: '.invite-text', 
                            styles: [
                                { display: apply ? 'none' : 'revert-layer' }
                            ]
                        },
                        // For some reason all h3 text scapes the line so we add white space no wrap
                        { 
                            target: 'h3', 
                            styles: [ 
                                {'white-space': apply ? 'nowrap' : ''} 
                            ] 
                        },
                        { 
                            target: 'a', 
                            styles: [ 
                                {'white-space': apply ? 'nowrap' : ''} 
                            ] 
                        }
                    ];

                    for (let item of items) {
                        for (let style of item.styles) {
                            let property = Object.keys(style)[0];
                            let value = style[property];
                            addStyles(item.target, property, value)
                        }
                    }

                }, 5000);
            });
        
        },
        
        // we are not using this anymore as we are using already existing thumbnails for the videos and also using canvases
        async getConvertedPosts(fn = null, time = 0) {
            // if there are posts
            if (this.posts.length) {
                
                // loading state
                this.loadingPosts = true;
                // counter
                this.postsLoaded = 0;

                await Promise.all(this.reportData.posts.map(async (post) => {
                    
                    // converts images to base64, so we can replace
                    // let { data } = await this.$http.get(`/api/reports/${this.$route.params.id}/posts/${post.id}?convert=true`);

                    // post.original_display_url = data.original_display_url;
                    // post.display_url = data.display_url;

                    // post.profile_item.original_profile_picture_url = data.profile_item.original_profile_picture_url;
                    // post.profile_item.profile_picture_url = data.profile_item.profile_picture_url;

                    this.postsLoaded++;
                    return post;
                }));

                this.loadingPosts = false;

                if (fn !== null) {
                    fn(time);
                }
            }
        },

        renderPdf() {
            let revert = () => {
                this.changeDocumentStyles('revert');
                // wait for reverted css changes to apply
                setTimeout(() => {
                    this.printing = false;
                    setTimeout(() => {
                        this.$redrawVueMasonry();
                    }, 1500);
                }, 5000);
            };
            
            this.$nextTick(() => {
                // wait 5000 ms for changeDocumentStyles changes to apply
                setTimeout(() => {
                    this.downloadPdfTries++;
                    generatePdf({
                        query: 'report-wrapper',
                        view: this.view,
                        fileName: this.reportData.name,
                        isUser: this.isUserReport,
                        onSuccess: revert,
                        onError: (err) => {
                            // For some reason, it fails on the first time when there are too may posts, so we try only a second time, reverting and applying
                            // the styles again, and it works.
                            if(this.downloadPdfTries <= 1 && err && err.isTrusted == true && err.type == "error"){
                                revert();
                                // wait for changes
                                setTimeout(() => {
                                    this.downloadPdf();
                                }, 1000);
                            }else{
                                revert();
                                this.$notify({ title: 'Error', text: 'There was an error while trying to generate the PDF. Try again later', type: 'warn' });
                            }
                        }
                    });
                }, 5000)
            });
        }

    }
}
</script>

<style lang="scss">
    .printer-separator{
        page-break-after: always;
        clear: both;
    }

    .report-item:last-child .printer-separator {
        page-break-after: unset;
        clear: unset;
        display: none;
    }
</style>

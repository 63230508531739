<template>
    <div class="wrapper" v-if="computedData">
        <div class="title">Ages</div>

        <div class="container">
            <Bar 
                :data="computedData" 
                :options="options" 
                :styles="{ height: '100%', width: '100%' }" 
            />
        </div>

    </div>
</template>
<script>
import Bar from "./Bar.vue";
const options = {
    scales: {
        xAxes: [
            {
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontSize: 14,
                    fontStyle: "bold",
                    fontFamily: "Helvetica",
                    fontColor: "#000000",
                },
            },
        ],
        yAxes: [
            {
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontSize: 14,
                    fontStyle: "bold",
                    fontFamily: "Helvetica",
                    fontColor: "#000000",
                    callback: function(value) {
                        return `${value}%`; // convert it to percentage
                    },
                },
            },
        ],
    },
    responsive: true,
    legend: { display: false },
    tooltips: {
        enabled: true,
        callbacks: {
            label: (tooltipItem, data) => {
                let percent = data["datasets"][tooltipItem.datasetIndex]["data"][tooltipItem["index"]].toFixed(2) + "%";
                let space = " ";
                return percent ? space + percent : null;
            },
        },
    },
};

export default {
    components: {
        Bar,
    },
    props: ["data"],
    data() {
        return {
            computedData: null,
            options,
        };
    },
    mounted() {

        // copy data so we can change its values
        let data = JSON.parse(JSON.stringify(this.data));

        if (data.length === 0) {
            return;
        }
        
        // ["13-17", "18-24", ...]
        const labels = data.map(element => element.code);
        // should be 1
        const total = data.map(element => element.weight).reduce((acc, curr) => acc + curr, 0);
        // [20, 30, 40, ...]
        const percentages = data.map(element => {
            return (element.weight * 100) / total;
        })

        const datasets = [{
            data: percentages,
            backgroundColor: '#70f880'
        }]

        this.computedData = { labels, datasets };
    },
};
</script>
<style lang="scss" scoped>
.wrapper {
    @apply flex flex-col items-center;
}
.title {
    font-weight: 600;
    text-align: center;
    font-size: 16px;
}
.container {
    width: 340px;
    height: 340px;
}
.legend {
    @apply mt-6 flex justify-center items-center gap-x-4;

    .item {
        @apply flex gap-x-2 items-center;

        .marker {
            @apply w-3 h-3;
            display: inline-block;
            &.female {
                background-color: #70f880;
            }
            &.male {
                background-color: #0e092c;
            }
            &.unknown {
                background-color: #d8d8d8;
            }
        }

        .label {
            @apply font-semibold;
            text-align: center;
            font-size: 16px;
        }
    }
}
</style>

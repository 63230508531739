<template>
    <!-- report-post-bottom class is used when printing -->
    <!-- for some reason, setting bg-transparent helps to actually not show this component when we are printing, so dont remove that -->
    <div v-if="!printView && !isPreview" class="report-post-bottom bg-transparent	flex flex-col items-center w-full gap-3" :class="{'mt-3': !isSocial}">
        <template v-if="(isAdminOrHigher || allowReplace) && post.is_replaced">
          <div class="text-xs italic text-blue-800">This post was replaced</div>
        </template>
        <template v-if="isAdminOrHigher || allowEdit || allowReplace">
          <div
            class="text-xs mt-2 text-center text-red-600 italic"
            v-if="isMetaPost && (!post.media || (post.media != undefined && Array.isArray(post.media) && !post.media.length))"
          >
            This post has been flagged for a copyright violation
          </div>
          <template v-if="datesAreSameDay">
            <div class="text-xs text-center mt-2" v-if="lastUpdated">Last updated on <span class="font-bold">{{ lastUpdated }}</span></div>
          </template>
          <template v-else>
            <div class="text-xs text-center mt-2" v-if="lastUpdated">Manually updated on <span class="font-bold">{{ lastUpdated }}</span></div>
            <div class="text-xs text-center" v-if="refreshedAt">Last updated on <span class="font-bold">{{ refreshedAt }}</span></div>
          </template>
        </template>
        <div class="flex items-center w-full gap-3" v-if="allowEdit || allowReplace">
          <base-button @action="$emit('edit-post', post)" icon="cog" class="w-full" type="secondary" :outline="false" v-if="allowEdit || allowReplace"/>
          <base-button 
            @action="$emit('replace-post', post)" 
            icon="edit-post" 
            class="w-full" 
            type="secondary" 
            :outline="false" 
            v-if="allowReplace && !post.is_featured"
          />
        </div>
        <div class="flex flex-wrap items-center w-full gap-3">
            <base-button 
              label="View copyright detail" 
              v-if="isNotNull(post.copyright_status) && isObject(post.copyright_status)" 
              type="secondary" 
              :outline="false" 
              class="w-full" 
              bold 
              @action="copyrightDetailModalVisible = true"
            />
            <base-button 
              label="View boosted data" 
              v-if="showBoostedData && isNotNull(post.boosted_data) && isArray(post.boosted_data)" 
              type="secondary" 
              :outline="false" 
              class="w-full" 
              bold 
              @action="boostedDataModalVisible = true"
            />
            <base-button @action="openLink(post.url)" label="View content" type="secondary" :outline="false" class="w-full" bold/>
            <template v-if="(isAdminOrHigher || allowReplace) && post.is_replaced && post.original_url">
              <base-button @action="openLink(post.original_url)" label="View original content" type="secondary" :outline="false" class="w-full" bold/>
            </template>
            <template v-if="isAdminOrHigher && !!post.project_user_deliverable_posts && post.project_user_deliverable_posts.length">
                <base-button
                  v-if="isAdminOrHigher && !!post.project_user_deliverable_posts && post.project_user_deliverable_posts.length === 1"
                  @action="$router.push(`/deliverables/${post.project_user_deliverable_posts[0].project_user_deliverable_id}`)"
                  label="Deliverable" type="secondary" class="w-full" bold :outline="false"
                />
                <dropdown :options="viewOptions" class="w-full" @changed="openDeliverable" on-top v-else>
                    <base-button label="Deliverables" bold type="secondary" :outline="false" class="w-full"/>
                </dropdown>
            </template>
            <base-button
                v-if="isAdminOrHigher && !!post.project_user_deliverble_posts && !post.project_user_deliverable_posts.length && deliverables.length"
                @action="$emit('link')"
                label="Link content"
                type="secondary"
                :outline="false"
                class="w-full"
                bold
            />
            <base-button
                v-if="isAdminOrHigher && !attachedToDefault && currentReports.length"
                @action="$emit('attach', currentReports[0].id, post.id)"
                label="Attach to default report"
                type="secondary"
                :outline="false"
                class="w-full"
                bold
            />
            <base-button
                v-if="allowUnlink"
                @action="$emit('unlink', post.id)"
                label="Unlink content"
                type="secondary"
                :outline="false"
                class="w-full"
                bold
            />
        </div>
        <copyright-detail-modal
          v-if="isNotNull(post.copyright_status) && isObject(post.copyright_status)"
          :visible="copyrightDetailModalVisible"
          :value="post.copyright_status"
          @close="copyrightDetailModalVisible = false"
        />
        <boosted-data-modal
          v-if="isNotNull(post.boosted_data) && isArray(post.boosted_data)"
          :visible="boostedDataModalVisible"
          :value="post.boosted_data"
          @close="boostedDataModalVisible = false"
        />
    </div>
</template>

<script> 
import { mapGetters } from 'vuex';
import CopyrightDetailModal from './CopyrightDetailModal.vue';
import BoostedDataModal from './BoostedDataModal.vue';
import { isNotNull, isObject, isArray } from '../common';
import moment from 'moment';
export default {
    components: { 
      CopyrightDetailModal,
      BoostedDataModal
    },
    props: {
      post: {},
      printView: {},
      hideStats: {
        type: Boolean,
        default: false
      },
      showBoostedData: {
        type: Boolean,
        default: true
      },
      allowEdit: {
        type: Boolean,
        default: false
      },
      allowReplace: {
        type: Boolean,
        default: false
      },
      allowUnlink: {
        type: Boolean,
        default: false
      },
      isPreview: {
        type: Boolean,
        default: false
      },
      isSocial: {
        type: Boolean,
        default: true
      },
      deliverables: {
        type: Array,
        default: function () {
          return [];
        }
      },
      updatedAt: {
        type: String,
        default: null,
      },
      currentReports: {
        type: Array,
        default: function () {
          return []
        }
      }
    },

    data(){
      return {
        copyrightDetailModalVisible: false,
        boostedDataModalVisible: false,
        isNotNull,
        isObject,
        isArray
      }
    },

    computed: {
      ...mapGetters(['isAdminOrHigher']),
      isMetaPost() {
        let platforms = ['instagram', 'facebook'];
        if (this.post.network.name) {
          return platforms.includes(this.post.network.name);
        }

        for (let platform of platforms) {
          if (this.post.url.includes(platform)) {
            return true;
          }
        }

        return false;
      },
      viewOptions() {
        return this.post.project_user_deliverable_posts ? this.post.project_user_deliverable_posts.map(post => {
          return { value: post.project_user_deliverable_id, label: post.project_user_deliverable.name };
        }) : [];
      },
      // determines if updated_at and refreshed_at are not null and are the same day.
      datesAreSameDay(){
        const updatedAt = this.lastUpdated;
        const refreshedAt = this.post.refreshed_at;
        if(!updatedAt || !refreshedAt) return true;
        return moment(updatedAt).isSame(refreshedAt, 'day');
      },
      refreshedAt() {
        if (this.post.refreshed_at) {
          let date = new Date(this.post.refreshed_at);
          let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
          return date.toLocaleDateString('en-US', options);
        }
        return null;
      },
      lastUpdated() {
        const updatedAt = this.updatedAt || this.post.updated_at;
        if (updatedAt) {
          let date = new Date(updatedAt);
          let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
          return date.toLocaleDateString('en-US', options);
        }
        return null;
      },
      attachedToDefault() {
        if (this.currentReports.length && this.post.reports.length) {
          let reports = this.currentReports.map(r => r.id);
          for (let report of this.post.reports) {
            if (reports.includes(report.report_id)) {
              return true;
            }
          }
        }
        return false;
      }
    },

    methods: {
      openLink(url) {
        window.open(url);
      },
      openDeliverable(option) {
        this.$router.push(`/deliverables/${option.value}`);
      }
    }
}
</script>


export const prevent = {
    namespaced: true,
    state: {
        ttPostBookmarker: JSON.parse(localStorage.getItem('ttPostBookmarker')) || false
    },
    mutations: {
        setPrevent(state, {index, op}) {
            localStorage.setItem(index, JSON.stringify(op));
            state[index] = op;
        }
    },
    actions: {
        async setPrevent({commit}, data) {
            await commit('setPrevent', data);
        },
    },
};
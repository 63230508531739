<template>
    <div class="title">
        <div class="anchor" :id="anchorLink" />
        <div 
            class="icon-container"
            @click="$router.push({hash: anchorLink})"
            v-scroll-to="{ el: `#${anchorLink}` }"
        >
            <base-icon name="link" />
        </div>

        <div :class="`title ${type}`">
            {{text}}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: false,
            default: 'section'
        }
    },
    computed: {
        text(){
            return this.$slots.default[0].text
        },
        anchorLink(){
            let text = this.text.toLowerCase().trim()
            let done = false
            let count = 0
            while(!done){
                count++
                if(text.includes(' ')){
                    text = text.replace(' ', '-')
                }else{
                    done = true
                }
                if(count >= 10) done = true
            }
            return text
        }
    }
}
</script>
<style lang="scss" scoped>
    .title{
        @apply relative;
        &:hover .icon-container{
            @apply opacity-100;
        }
        .anchor{
            @apply w-1 h-1 absolute left-0;
            top: -90px;
        }
        .icon-container{
            @apply absolute -left-8 top-0 bottom-0 flex items-center opacity-0 pr-8 duration-200 cursor-pointer;
            &:hover{
                @apply opacity-50;
            }
        }
        .title{
            &.main{
                @apply text-h1 mb-8;
            }
            &.section{
                @apply text-h2 mb-3;
            }
            &.subtitle{
                @apply text-h3 mb-3;
            }
        }
    }
</style>

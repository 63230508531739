<template>
    <div class="container" :class="{'report-container': isReport}">
        <div class="header">
            <div class="flex justify-between items-center">
                <div class="flex items-center gap-x-3">
                    <div class="w-14">
                        <avatar size="medium" :user="postedBy" :class="{'w-12': isReport}"/>
                    </div>
                    <div>
                        <div><span class="font-bold">{{postedBy.name}}</span> posted a new picture on <base-icon name="facebook" class="inline-block"/></div>
                        <div class="flex items-center">
                            <div class="text-pxs pr-1">{{postedAt}} · </div>
                            <base-icon :size="3" name="fb-public"/>
                        </div>
                    </div>
                </div>

                <base-icon :size="7" name="fb-options" v-if="!isReport"/>
            </div>
            <div class="description" :class="isOpen ? 'line-clamp-none' : 'line-clamp-3'">{{description}}</div>
            <div class="description cursor-pointer" @click="isOpen = !isOpen" v-if="!isPreview && description.length > 120">{{ isOpen ? 'Hide' : 'More' }}</div>
        </div>
        <div class="content" :class="{'relative': !isPreview}">
            <media-viewer :is-preview="isPreview" :file="files[page]" v-if="files.length" :is-report="isReport" :viewOnly="viewOnly" @set-fail="$emit('set-fail')"/>

            <!-- navigation buttons -->
            <div v-if="page > 0" class="navigation-container left" :class="isReport ? 'report-navigator' : ''">
                <div @click="$emit('navigate', 'prev')" class="button">
                    <base-icon name="chevron-left" />
                </div>
            </div>
            <div v-if="page < files.length-1" class="navigation-container right" :class="isReport ? 'report-navigator' : ''">
                <div @click="$emit('navigate', 'next')" class="button">
                    <base-icon name="chevron-right" />
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="stats">
                <div class="flex items-center gap-x-2">
                    <div class="reactions">
                        <div class="reaction like">
                            <base-icon iconClass="text-white" :size="3" name="fb-like-filled"/>
                        </div>
                    
                        <div class="reaction love" v-if="!isReport">
                            <base-icon iconClass="text-white" :size="3" name="fb-heart"/>
                        </div>

                        <div class="reaction laugh" v-if="!isReport">
                            <base-icon :size="5" name="fb-laugh"/>
                        </div>
                    </div>

                    <div class="text-pxs">{{ likes | numeral('0a') }}</div>
                </div>
                <div class="flex items-center gap-x-2">
                    <div class="text-pxs">{{ comments | numeral('0a') }} comment{{ comments !== 1 ? 's' : ''}}</div>
                    <div class="text-pxs">{{ shares | numeral('0a') }} share{{ comments !== 1 ? 's' : ''}}</div>
                    <!-- <div class="text-pxs">{{ saves | numeral('0a') }} save{{ comments !== 1 ? 's' : ''}}</div> -->
                </div>

            </div>
            <div class="separator"/>
            <div class="interaction">
                <div class="button" :class="{'cursor-not-allowed': isReport}">
                    <base-icon :size="5" name="fb-like"/>
                    <span>Like</span>
                </div>
                <div class="button" :class="{'cursor-not-allowed': isReport}">
                    <base-icon :size="5" name="fb-comments"/>
                    <span>Comment</span>
                </div>
                <div class="button" :class="{'cursor-not-allowed': isReport}">
                    <base-icon :size="5" name="fb-share"/>
                    <span>Share</span>
                </div>
                <!-- <div class="button" :class="{'cursor-not-allowed': isReport}">
                    <base-icon :size="5" name="fb-save"/>
                    <span>Save</span>
                </div> -->
                  
            </div>
            <div class="separator"/>
        </div>
    </div>
</template>

<script>
import MediaViewer from '../MediaViewer.vue'
export default {
    props: ['files', 'description', 'postedBy', 'postedAt', 'page', 'viewOnly', 'isReport', 'postItem', 'isPreview'],
    components: {
        MediaViewer
    },
    watch: {
        isOpen: function () {
            this.$emit('resize');
        }
    },
    data(){
        return {
            isOpen: false
        }
    },
    computed: {
        shares() {
            return this.postItem && this.postItem.shares !== undefined ? this.postItem.shares : this.postedBy.shares;
        },
        // saves() {
        //     return this.postItem && this.postItem.saves !== undefined ? this.postItem.saves : this.postedBy.saves;
        // },
        comments() {
            return this.postItem && this.postItem.comments !== undefined ? this.postItem.comments : this.postedBy.comments;
        },
        likes() {
            return this.postItem && this.postItem.likes !== undefined ? this.postItem.likes : this.postedBy.likes;
        }
    }
}
</script>

<style lang="scss" scoped>
    .container{
        @apply bg-white p-4 rounded-lg;
        width: 450px;
        &.report-container {
            max-width: 100% !important;
        }
    }
    .description{
        @apply my-2;
    }
    .stats{
        @apply flex justify-between items-center mx-3 my-2;
    }
    .reactions{
        @apply flex items-center;
        & .reaction{
            @apply rounded-full flex justify-center items-center h-5 w-5;
            
            &:not(:first-child){
                margin-left: -2px;
            }    

            &.like{
                background-color: #0573e6
            }
            &.love{
                background-color: #ee3150
            }
        }
    }
    .interaction{
        @apply flex justify-between items-center mx-3 my-2;
        & .button{
            @apply flex items-center gap-x-2;
            & span{
                @apply text-h6 font-bold;
            }
        }
    }
    .separator{
        @apply w-full bg-gray-400;
        height: 1px;
    }
    .navigation-container{
        position: absolute;
        bottom: 0px;
        top: 0px;
        display: flex;
        align-items: center;

        &.left {
            left: -40px;

            &.report-navigator {
                left: 1rem !important;
            }
        }

        &.right{
            right: -40px;

            &.report-navigator {
                right: 1rem !important;
            }
        }

        & .button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #bbbbbd;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            cursor: pointer;
            box-shadow: #a3a3a3 -1px 0px 13px 0px;
        }
    }
</style>
<template>
    <div class="container" :class="isReport ? 'w-full' : ''">

        <!-- header -->
        <div class="header flex-row-reverse">
            <a :href="postItem.profile_item.uri" target="_blank" v-if="isReport && postItem && postItem.profile_item">
                <base-icon iconClass="text-white text-shadow" name="instagram" style="display: inline-block;"/>
            </a>
            <base-icon iconClass="text-white text-shadow" name="instagram" v-else/>
            <div class="title text-shadow" v-if="!isReport">Reels</div>
        </div>

        <!-- content -->
        <div class="content">
            <media-viewer :file="files[page]" :is-report="isReport" :viewOnly="viewOnly" @set-fail="$emit('set-fail')"/>
        </div>

        <!-- navigation buttons -->
        <div v-if="page > 0" class="navigation-container left" :class="isReport ? 'report-navigator' : ''">
            <div @click="$emit('navigate', 'prev')" class="button z-40">
                <base-icon name="chevron-left" />
            </div>
        </div>
        <div v-if="page < files.length-1" class="navigation-container right" :class="isReport ? 'report-navigator' : ''">
            <div @click="$emit('navigate', 'next')" class="button z-40">
                <base-icon name="chevron-right" />
            </div>
        </div>

        <!-- footer -->
        <div class="footer items-end footer-gradient">

            <div class="info">
                
                <div class="user-info">
                    <div class="user">
                        <avatar size="small" :user="postedBy"/>
                        <div>
                            <a :href="postItem.profile_item.uri" target="_blank" v-if="isReport && postItem && postItem.profile_item">
                                {{postedBy.handle}}
                            </a>
                            <span v-else>{{postedBy.handle}}</span>
                        </div>
                    </div>

                    <div class="follow-button" v-if="!isReport">Follow</div>
                </div>
                <div class="description text-shadow" v-if="isReport">Followers - {{ postItem.profile_item.total_followers | numeral('0a') }}</div>
                <div class="description text-shadow" :class="isOpen ? 'line-clamp-none' : 'line-clamp-3'">{{description}}</div>
                <div class="description text-shadow cursor-pointer" @click="isOpen = !isOpen" v-if="description.length > 120">{{ isOpen ? 'Hide' : 'More' }}</div>
                <div class="sound-name text-shadow">Original Sound - {{postedBy.name}} <base-icon iconClass="drop-shadow" :size="3" name="user"/> {{postedBy.handle}} </div>
            </div>

            <div class="interaction">
                <div class="icon-container" v-for="(icon, index) in computedIcons" :key="index">
                    <base-icon iconClass="text-white text-shadow" :size="icon.size ? icon.size : 6" :name="icon.name"/>
                    <div class="count text-shadow" v-if="postItem && postItem[icon.reportMeta] !== undefined">
                        {{ (postItem[icon.reportMeta] > 0 ? postItem[icon.reportMeta] : 0) | numeral('0a') }}
                    </div>
                    <div class="count text-shadow" v-else-if="icon.count">{{ icon.count }}</div>
                </div>
                <div class="sound-image box-shadow"/>
            </div>
        
        </div>

        <!-- audio dropzone -->
        <audio-dropzone
            v-if="!isReport"
            :file="files[page]"
            :key="key"
            :viewOnly="viewOnly"
            @removeAudio="$emit('removeAudio')"
            @audioDrop="(audio)=>$emit('audioDrop', audio)"
        />

    </div>
</template>

<script>
import Avatar from '../../../../components/Avatar.vue'
import MediaViewer from '../MediaViewer.vue'
import AudioDropzone from '../../AudioDropzone.vue'
export default {
    props: ['files', 'description', 'postedBy', 'postedAt', 'page', 'viewOnly', 'isReport', 'postItem'],
    components: {
        MediaViewer,
        Avatar,
        AudioDropzone
    },
    computed: {
        computedIcons() {
            return this.icons.filter(i => {
                if (this.isReport) {
                    return i.name !== 'dots-vertical';
                }
                return true;
            })
        }
    },
    data(){
        return {
            isOpen: false,
            key: 0,
            icons: [ 
                { name: 'ig-like', count: this.postedBy.likes, reportMeta: 'likes' },
                { name: 'ig-comments', count: this.postedBy.comments, reportMeta: 'comments' },
                { name: 'ig-share', reportMeta: 'shares' },
                // { name: 'ig-save', reportMeta: 'saves' },
                { name: 'dots-vertical', size: 3 },
            ]
        }
    },
    watch: {
        files: {
            immediate: true,
            deep: true,
            handler () {
                this.key = !this.key
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .container{
        width: 300px;
        height: 532px;
        position: relative;
        background-color: black;
    }
    .container.w-full {
        width: 100%;
    }
    .header{
        @apply absolute z-10 left-0 right-0 top-0 p-4 flex justify-between;
        & .title {
            @apply text-h5 font-bold text-white;
        }
    }
    .navigation-container{
        position: absolute;
        bottom: 0px;
        top: 0px;
        display: flex;
        align-items: center;

        &.report-navigator {
            height: 90%;
        }

        &.left {
            left: -40px;

            &.report-navigator {
                left: 1rem !important;
            }
        }

        &.right{
            right: -40px;

            &.report-navigator {
                right: 1rem !important;
            }
        }

        & .button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #bbbbbd;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            cursor: pointer;
            box-shadow: #a3a3a3 -1px 0px 13px 0px;
        }
    }
    .footer{
        @apply absolute left-0 right-0 bottom-0 p-4 flex justify-between;
        
        & .info{
            @apply flex flex-col justify-end flex-shrink flex-grow gap-y-3 overflow-hidden;
            & .user-info{
                @apply flex gap-x-4 items-center;
                & .user{
                    @apply text-pxs text-white font-bold flex items-center gap-x-2;
                }
                & .follow-button{
                    @apply text-pxs text-white font-bold border border-white rounded-md py-0.5 px-1.5;
                }
            }
            .description{
                @apply text-pxs text-white;
            }
            .sound-name{
                @apply text-pxs text-white truncate;
            }
        }
        & .interaction{
            @apply flex flex-col flex-shrink-0 flex-grow-0 items-center gap-y-5;
            & .icon-container{
                @apply flex flex-col items-center gap-y-2;
                & .count{
                    @apply text-pxs text-white;
                }
            }
            & .sound-image{
                @apply h-8 w-8 border border-white border-2 rounded-md;
                background-image: url('../../../../assets/images/dinosaur-modal.png');
                @apply bg-cover bg-center bg-no-repeat;
            }
        }
    }
</style>
<template>
    <div  
        :class="`audio-item ${audio.usedByFileIndex == page ? 'active' : ''} `"
        
        @click.prevent="playPause()"
        
        @mouseenter="hover = true"
        @mouseleave="hover = false"

        :draggable="audio.used ? false : true"
        @dragstart="startDrag($event)"
        @dragend="endDrag($event)"
    >
        
        <!-- progress bar -->
        <div 
            v-if="playing && loadedMetadata" 
            class="progress-bar" 
            :style="`width: ${progressPercentage}`" 
        />

        <div :class="`icon-wrapper ${!hover && !playing ? 'bg-white' : 'bg-black'}`">
            <base-icon
                :class="`${!hover && !playing ? 'text-black' : 'text-white'}`"
                :size="5"
                :name="!hover && !playing ? 'audio-hollow' : playing ? 'pause' : 'play'" 
            />
        </div>

        <div class="flex-grow flex-shrink">
            <div class="font-semibold text-h5 text-purple-m-secondary">{{name}}</div>
            <div class="text-sm">{{extension}}</div>
        </div>

        <div
            v-if="audio.used"
            class="text-pxs bg-black text-white px-2 py-1 hover:bg-gray-m transition duration-200 rounded-full" 
            @click.stop="$emit('removeAudio', audio.usedByFileIndex)"
        >
            <base-icon class="mr-1" :size="2" name="link"/> unlink
        </div>

        <!-- autoplay -->
        <audio 
            controls
            :id="id"
            class="hidden"
            @timeupdate="onTimeUpdate"
            @ended="onEnded"
            @loadedmetadata="loadedMetadata = true"
        >
            <source :src="url" :type="audio.type">
            Your browser does not support the audio element.
        </audio>

    </div>
</template>

<script>
export default {
    props: ['audio', 'id', 'page'],
    data(){
        return {
            playing: false,
            progress: 0,
            hover: false,
            loadedMetadata: false
        }
    },
    mounted(){
        if(this.audio.usedByFileIndex == this.page){
            setTimeout( () => { this.audioElement.play() }, 150);
            this.playing = true
        }
    },
    methods: {
        onTimeUpdate(){
            this.progress = this.audioElement.currentTime
        },
        onEnded(){
            this.playing = false
        },
        playPause(){
            if(this.playing){
                this.audioElement.pause()
                return this.playing = false
            }
            this.audioElement.play()
            this.playing = true
        },
        startDrag(event){
            event.dataTransfer.setData('audioName', this.audio.name)
            event.dataTransfer.setData('audioElementId', this.id)
        },
        endDrag(){
            this.audioElement.pause()
            this.audioElement.currentTime = 0
        }
    },
    computed: {
        audioElement(){
            return document.getElementById(this.id)
        },
        duration(){
            var audio = document.getElementById(this.id)
            return audio?.duration || 0
        },
        progressPercentage(){
            return `${(this.progress * 100) / this.duration}%`
        },
        name(){
            let arr = this.audio.name.split('.')
            const lastElement = arr[arr.length-1]
            arr = arr.filter(element => element != lastElement)
            return arr.join()
        },
        extension(){
            const arr = this.audio.name.split('.')
            const lastElement = arr[arr.length-1] 
            return `.${lastElement}`
        },
        url(){
            return URL.createObjectURL(this.audio);
        }
    }
}
</script>
<style lang="scss" scoped>
    .audio-item{
        @apply w-60 cursor-pointer bg-white rounded-md p-4 relative flex items-center gap-x-3 transition duration-300;
        height: 68px;
        &.active{
            @apply border border-black border-2;
        }
        &:hover{
            @apply opacity-80;
        }
        & .progress-bar{
            @apply bg-black h-1 absolute left-0 rounded-md;
            top: -1px;
            border-radius: 5px;
        }
        & .icon-wrapper{
            @apply h-12 w-12 rounded-full border border-gray-300 flex-grow-0 flex-shrink-0 flex justify-center items-center;
        }
    }
</style>
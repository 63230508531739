<template>
<div class="wrapper">
    
    <div v-if="step == 1" class="step-1">

        <div class="loader-container" v-if="loading" >
            <base-icon name="loader" class="text-white"/>
        </div>

        <div class="container" v-if="request">
            
            <div class="deactivated-request" v-if="!request.active">
                <div class="content">
                    <div class="title">This request is no longer active.</div>
                    <div class="actions">
                        <base-button
                            label="Go home"
                            @action="$router.push('/')"
                        />
                    </div>
                </div>
            </div>

            <template v-else>
                
                <div class="header">
                    <img class="logo" src="../../assets/images/logo.svg" />
                    <div class="title">Boosted posts</div>
                    <div class="description">Connecting accounts for boosted posts with company: {{ request.company.name }}</div>
                </div>

                <div class="additional-info">
                    <div class="flex gap-x-4 items-center">
                        <div class="text-white">Requested by:</div>
                        <avatar-list nameClasses="text-white" :users="[request.requested_by_user]" />
                    </div>
                    <div class="flex gap-x-2 items-center">
                        <div class="text-white">For user:</div>
                        <avatar-list nameClasses="text-white" :users="[request.for_user]" />
                    </div>
                </div>

                <div class="content">
                    
                    <div class="step">
                        <div class="text">Enter <a class="link" href="https://business.facebook.com" target="_blank">https://business.facebook.com</a>.  Make sure its the facebook business linked to "{{ request.data.instagram_account }}" </div> 
                    </div>

                    <div class="step">
                        <div class="text">Scroll down and click "Business Settings"</div>
                        <img :src="images.step1" />
                    </div>

                    <div class="step">
                        <div class="text">On the left side of the window, under “Accounts”, click “Pages"</div>
                        <img style="width: 235px;" :src="images.step2" />
                    </div>

                    <div class="step">
                        <div class="text">Click "Assign Partners" under name</div>
                        <img :src="images.step3" />
                    </div>

                    <div class="step">
                        <div class="text">Click "Business ID"</div>
                        <img style="width: 550px;" :src="images.step4" />
                    </div>

                    <div class="step">
                        <div class="text">Enter "{{ request.company.fb_business_id }}" in "Enter Business Partner ID"</div>
                        <div class="step-5-image-container">
                            <img :src="images.step5" />
                            <div class="business-id">{{ request.company.fb_business_id }}</div>
                        </div>
                    </div>

                    <div class="step">
                        <div class="text">Switch "Create Ads" on, then go next at the bottom</div>
                        <div class="step-6-image-container">
                            <img :src="images.step6" />
                            <div class="business-id">{{ request.company.fb_business_id }}</div>
                            <img :src="isPermittedTaskRequired('content') ? images.toggleOn : images.toggleOff" class="toggle" style="top: 255px;" />
                            <img :src="isPermittedTaskRequired('community-activity') ? images.toggleOn : images.toggleOff" class="toggle" style="top: 309px;" />
                            <img :src="isPermittedTaskRequired('messages') ? images.toggleOn : images.toggleOff" class="toggle" style="top: 365px;" />
                            <img :src="isPermittedTaskRequired('ads') ? images.toggleOn : images.toggleOff" class="toggle" style="top: 420px;" />
                            <img :src="isPermittedTaskRequired('insights') ? images.toggleOn : images.toggleOff" class="toggle" style="top: 475px;" />
                        </div>
                    </div>

                    <div class="step">
                        <div class="text">Same process for Instagram, under "Accounts" click "Instagram Accounts"</div>
                        <img :src="images.step7" />
                    </div>

                    <div class="step">
                        <div class="text">Click "Assign Partners" again</div>
                        <img :src="images.step8" />
                    </div>
                    
                    <div class="step">
                        <div class="text">Insert same code under Business ID</div>
                        <div class="step-9-image-container">
                            <img :src="images.step9" />
                            <div class="business-id">{{ request.company.fb_business_id }}</div>
                            <img :src="isPermittedTaskRequired('content') ? images.toggleOn : images.toggleOff" class="toggle" style="top: 265px;" />
                            <img :src="isPermittedTaskRequired('community-activity') ? images.toggleOn : images.toggleOff" class="toggle" style="top: 331px;" />
                            <img :src="isPermittedTaskRequired('messages') ? images.toggleOn : images.toggleOff" class="toggle" style="top: 386px;" />
                            <img :src="isPermittedTaskRequired('ads') ? images.toggleOn : images.toggleOff" class="toggle" style="top: 442px;" />
                            <img :src="isPermittedTaskRequired('insights') ? images.toggleOn : images.toggleOff" class="toggle" style="top: 495px;" />
                        </div>
                    </div>

                    <div class="step">
                        <div class="text">Click "Next" then "Done"</div>                
                    </div>
                
                    <base-button
                        label="Done"
                        @action="step++"
                    />
                
                </div>
            </template>

        </div>
    </div>

    <div v-if="step == 2" class="step-2">
        <div class="content">
            <div class="title">
                Use this verify button to check that the connection was made successfully.
            </div>
            <base-icon class="text-white" name="loader" v-if="loading" />
            <div class="actions">
                <base-button
                    label="Back"
                    icon="arrow-left"
                    type="secondary"
                    @action="step--"
                />

                <base-button
                    label="Verify"
                    @action="verify()"
                />
            </div>
        </div>
    </div>

    <div v-if="step == 3" class="step-3">
        <div class="content">
            <div class="title">
                The connection was successfull.
            </div>
            <base-icon class="text-white" name="loader" v-if="loading" />
            <div class="actions">
                <base-button
                    label="Go home"
                    @action="$router.push('/')"
                />
            </div>
        </div>
    </div>

</div>
</template>
<script>
import { step1, step2, step3, step4, step5, step6, step7, step8, step9, toggleOn, toggleOff } from './images';
import { notifyCatchError } from '../../common'
import BaseIcon from '../../components/BaseIcon.vue';
import AvatarList from '../../components/AvatarList.vue';
export default {
  components: { BaseIcon, AvatarList },

    data(){
        return {
            step: 1,
            request: null,
            images: {step1, step2, step3, step4, step5, step6, step7, step8, step9, toggleOn, toggleOff},
            loading: false
        }
    },

    mounted(){
        let token = this.$route.query.t;
        if(token){
            this.getRequest();
        }
    },

    methods: {
        getRequest() {
            this.loading = true;
            this.$http.get(`/api/ig-connection-request/${this.$route.query.t}`).then(({ data }) => {
                if(data){
                    this.request = data;
                }
            }).catch(err => {
                notifyCatchError(err, this.$notify);
            }).finally(() => {
                this.loading = false;
            });
        },
        verify(){
            this.loading = true;
            this.$http.post('/api/ig-connection-request/verify', {
                token: this.$route.query.t
            }).then(({ data }) => {
                if(data){
                    this.$notify({ title: 'Success', text: 'The connection was successfull', type: 'success' });
                    this.step++;
                }
            }).catch(err => {
                notifyCatchError(err, this.$notify);
            }).finally(() => {
                this.loading = false;
            });
        },
        isPermittedTaskRequired(slug){
            return this.request.data.permitted_tasks.includes(slug)
        }
    }
}
</script>
<style lang="scss" scoped>
    .wrapper {
        @apply min-h-screen bg-purple-m-main overflow-y-auto pb-12;
        > .step-1{
            @apply w-full flex flex-col items-center;
            > .loader-container{
                @apple w-full flex items-center justify-center;
                padding-top: 150px;
            }
            > .container{
                @apply w-2/3 h-full flex flex-col py-12 px-12;
                > .deactivated-request{
                    @apply w-full flex flex-col justify-center items-center;
                    > .content{
                        @apply flex flex-col items-center;
                        width: 600px;
                        > .title{
                            @apply text-white text-xl font-bold text-center;
                        }
                        > .actions{
                            @apply w-full flex mt-16 justify-center;
                        }
                    }
                }
                > .header {
                    @apply w-full border-b-2 border-white;
                    > .logo {
                        @apply mx-auto h-12 w-auto;
                    }
                    > .title {
                        @apply mt-6 text-3xl font-extrabold text-white;
                    }
                    > .description{
                        @apply text-xl text-white;
                    }
                }
                > .content{
                    @apply mt-8 flex flex-col gap-y-10;
                    padding-bottom: 100px;
                    > .step{
                        .text{
                            @apply text-lg text-white;
                            &::before{
                                content: "\a";
                                width: 11px;
                                height: 11px;
                                border-radius: 50%;
                                background: #70f880;
                                display: block;
                                position: relative;
                                top: 18px;
                                right: 21px;
                            }
                        }
                        .link{
                            @apply underline
                        }
                        img{
                            @apply mt-2;
                        }

                        .step-5-image-container{
                            @apply relative;
                            > img{
                                width: 746px;
                            }
                            > .business-id{
                                @apply absolute;
                                top: 162px;
                                left: 27px;
                            }
                        }

                        .step-6-image-container{
                            @apply relative;
                            > img{
                                width: 746px; 
                                height: 748px;
                            }
                            > .business-id{
                                @apply absolute;
                                top: 162px;
                                left: 27px;
                            }
                            > .toggle{
                                @apply absolute;
                                left: 15px;
                                width: 48px;
                                height: auto;
                            }
                        }


                        .step-9-image-container{
                            @apply relative;
                            > img {
                                width: 743px; 
                                height: 614px;
                            }
                            > .business-id{
                                @apply absolute;
                                top: 164px;
                                left: 27px;
                            }
                            > .toggle{
                                @apply absolute;
                                left: 15px;
                                width: 48px;
                                height: auto;
                            }
                        }

            
                    }
                }       
            }
        }
        > .step-2, .step-3{
            @apply h-screen w-full flex flex-col justify-center items-center;
            > .content{
                @apply flex flex-col items-center;
                width: 600px;
                > .title{
                    @apply text-white text-xl font-bold text-center;
                }
                > .actions{
                    @apply w-full flex mt-24;
                }
            }
        }
        > .step-2 > .content > .actions{ @apply justify-between; }
        > .step-3 > .content > .actions{ @apply justify-center; }
    }
</style>
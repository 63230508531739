var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-importable-status"},[(!_vm.isImportable)?_c('base-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ 
            content: 'This user requires your attention, click to view details', 
            classes: 'v-tooltip-black ml-60', 
            delay: { 
                show: 100, 
                hide: 50 
            } 
        }),expression:"{ \n            content: 'This user requires your attention, click to view details', \n            classes: 'v-tooltip-black ml-60', \n            delay: { \n                show: 100, \n                hide: 50 \n            } \n        }"}],staticClass:"cursor-pointer text-yellow-300",attrs:{"name":"exclamation-2"},on:{"action":function($event){_vm.hasActions ? _vm.modalVisible = true : null}}}):_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ 
            content: ("The user is importable" + (_vm.hasActions ? ", but there are details that require your attention, click to view" : "")), 
            classes: ("v-tooltip-black" + (_vm.hasActions ? " ml-72 mb-4" : "")), 
            delay: { 
                show: 100, 
                hide: 50 
            }
        }),expression:"{ \n            content: `The user is importable${hasActions ? `, but there are details that require your attention, click to view` : ``}`, \n            classes: `v-tooltip-black${hasActions ? ` ml-72 mb-4` : `` }`, \n            delay: { \n                show: 100, \n                hide: 50 \n            }\n        }"}],staticClass:"icon-wrapper",on:{"click":function($event){_vm.hasActions ? _vm.modalVisible = true : null}}},[_c('base-icon',{staticClass:"text-green-500",attrs:{"name":"check-circle"}}),(_vm.hasActions)?_c('div',{staticClass:"actions-container"},[_c('base-icon',{staticClass:"text-yellow-300",attrs:{"name":"exclamation-circle","size":4}})],1):_vm._e()],1),_c('base-table-column-user-importable-status-modal',{attrs:{"visible":_vm.modalVisible,"value":_vm.value,"creator":_vm.creator},on:{"update:user":function (user) { return _vm.$emit('update:user', user); },"close":function($event){_vm.modalVisible = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div
        class="stat-card-wrapper"
        :style="`${wrapperStyles}`"
        v-tooltip="tooltip ? { 
            content: tooltip, 
            classes: 'bg-black text-white rounded py-1 px-2 text-xs mb-4 -ml-24 w-72', 
            delay: { delay: 1000, show: 100, hide: 50 } 
        } : {}"
    >
        <!-- collapsed, dont know where this is used -->
        <div class="collapsed-content" v-if="collapsed">
            <div class="icon">
                <base-icon class="text-green-m-main" :name="icon" :size="iconSize" />
            </div>
            <div class="box">
                <div class="label">{{label}}</div>
                <div class="value" :class="valueClass">{{value}}</div>
            </div>
        </div>

        <!-- mini type, as shown on the individual posts stats of a report -->
        <div 
            class="mini-content" 
            :class="[`gap-${gap}`, containerClass]" 
            v-if="type === 'mini'"
        >
            <div 
                :class="`icon w-${cardSize} h-${cardSize} ${link !== '' ? 'cursor-pointer' : ''}`" 
                @click="action" 
                v-if="!loading"
            >
                <base-icon class="text-green-m-main" :name="icon" :size="iconSize"/>
            </div>
            <!-- skeleton -->
            <skeleton v-else full-rounded :height="15" :width="15"/>
            <div class="info">
                <!-- skeleton -->
                <template v-if="loading">
                    <skeleton :height="6" :width="16"/>
                    <skeleton :height="8" :width="32"/>
                </template>
                <template v-else>
                    <div class="box">
                        <span v-if="!compareFirst" class="value" :class="[{'font-bold': bold}, valueClass]">{{value}}</span>
                        <span class="label" :class="{'font-bold': bold}">{{label}}</span>
                        <span v-if="compareFirst" class="compare">{{compareFirst}}  of  {{compareSecond}}</span>
                    </div>
                </template>
            </div>
        </div>
        <!-- regular, as shown on report top -->
        <div v-else class="content" :class="containerClass">
            <div :class="`icon w-${cardSize} h-${cardSize} ${link !== '' ? 'cursor-pointer' : ''}`" @click="action" v-if="!loading">
                <base-icon class="text-green-m-main" :name="icon" :size="iconSize"/>
            </div>
            <!-- skeleton -->
            <skeleton v-else full-rounded :height="15" :width="15"/>
            <div class="info">
                <!-- skeleton -->
                <template v-if="loading">
                    <skeleton :height="6" :width="16"/>
                    <skeleton :height="8" :width="32"/>
                </template>
                <template v-else>
                    <div class="label">{{label}}<slot name="label"/></div>
                    <div class="value" :class="valueClass">{{value}} <span v-if="percent">({{ percent }})</span></div>
                    <div class="compare" v-if="compareFirst">{{compareFirst}} of {{compareSecond}}</div>
                    <div v-if="visibleSpan && span !== undefined && span !== ''" :class="spanClass" @click="$emit('span-action')">{{ span }}</div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: null
        },
        valueClass: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Number],
            default: ''
        },
        iconSize: {
            type: Number,
            default: 5
        },
        cardSize: {
            type: Number,
            default: 15
        },
        bold: {
            type: Boolean,
            default: true
        },
        gap: {
            type: Number,
            default: 4
        },
        icon: {
            type: String,
            default: ''
        },
        compareFirst: {
            type: String,
            default: ''
        },
        percent: {
            type: String,
            default: ''
        },
        compareSecond: {
            type: String,
            default: ''
        },
        collapsed: {
            type: Boolean,
            default: false
        },
        containerClass: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        },
        visibleSpan: {
            type: Boolean,
            default: true
        },
        link: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "",
        },
        span: {
            type: String,
            default: ''
        },
        spanClass: {
            type: String,
            default: ''
        },
        wrapperStyles: {
            type: String,
            default: ''
        }
    },
    methods: {
        action() {
            if(this.link) {
                window.open(this.link, '_blank').focus();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .stat-card-wrapper{
        //
        > .collapsed-content{
            > .icon{
                @apply inline-flex items-center justify-center rounded-full bg-purple-m-main flex-shrink-0 flex-grow-0 w-10 h-10;
            }
            > .box{
                @apply flex flex-col items-start justify-start;
                .label{
                    @apply text-pxs text-gray-m-disable font-bold;
                }
                .value{
                    @apply text-h6 text-purple-m-main font-bold;
                }
            }
        }
        > .mini-content{
            @apply inline-flex gap-x-1 items-center justify-center;
            > .icon{
                @apply inline-flex items-center justify-center rounded-full bg-purple-m-main flex-shrink-0 flex-grow-0;
            }
            > .info{
                //
                > .box{
                    @apply flex items-center justify-end;
                    > .value{
                        @apply text-purple-m-main;
                    }
                    > .label{
                        @apply ml-1 text-sm text-gray-m-disable;
                    }
                    > .compare{
                        @apply ml-1 text-pxs text-purple-m-main font-bold;
                    }
                }
            }
        }
        > .content{
            @apply flex gap-4 items-start;
            > .icon{
                @apply inline-flex items-center justify-center rounded-full bg-purple-m-main flex-shrink-0 flex-grow-0;
            }
            > .info{
                @apply flex flex-col items-start justify-start gap-2;
                > .label{
                    @apply flex items-center text-h6 text-gray-m-disable font-bold text-left;
                }
                > .value{
                    @apply text-h3 text-purple-m-main font-bold;
                    > span{
                        @apply text-gray-headers text-sm;
                    }
                }
                > .compare{
                    @apply text-pxs text-gray-m-disable;
                }
            }
        }


    }
</style>
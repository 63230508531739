<template>
    <div>
        <div class="file-view-wrapper" v-if="file.guid">
            <pdf-preview
                :url="filePath"
                :fixed-pagination="true"
                :banner="banner"
                v-if="isPdf"
            />
            <div v-if="isImage" class="mx-auto">
                <img :src="filePath" />
            </div>
        </div>
        <div v-else-if="!loadingFile" class="p-12">
            <div class="text-lg font-bold">Permission denied</div>
            <div>You don't have the right permissions to view this file</div>
        </div>
        <file-password-modal
            :visible="filePasswordModalVisible"
            :file-guid="$route.params.id"
            @unlocked="onUnlock"
            @close="filePasswordModalVisible = false"
        />
    </div>
</template>

<script>
import PdfPreview from '../../components/PdfPreview';
import FilePasswordModal from '../../components/FilePasswordModal.vue'
import axios from 'axios'
export default {
    components: {
        PdfPreview,
        FilePasswordModal
    },

    data() {
        return {
            file: {},
            loadingFile: true,
            token: null,
            correctPassword: null,
            filePasswordModalVisible: false
        }
    },
    

    computed: {        
        banner() {

            if (!this.$route.query.acknowledge) {
                return null;
            }

            return {
                text: 'Please read through entire document.',
                action: this.markAsRead
            }
        },

        mimetype() {
            return this.file.mimetype || '';
        },

        extension() {
            return this.mimetype.split('/')[1] || this.mimetype;
        },

        isPdf() {
            return this.mimetype.toLowerCase().includes('pdf');
        },

        isImage() {
            return this.mimetype.toLowerCase().includes('image')
        },

        filePath() {
            return `${axios.defaults.baseURL}/api/files/render/${this.file.guid}${this.token ? `?token=${this.token}` : ``}`;
        }
    },

    mounted() {
        this.fileRequiresPassword()
    },

    methods: {
        async loadFile() {
            try {
                const { data } = await this.$http.post(`/api/files/get/${this.$route.params.id}`, {
                    ...(this.correctPassword ? { password: this.correctPassword } : {})
                });

                // if its link
                if((data.mimetype && data.mimetype == 'link')){
                    return window.open(data.url, "_self");
                }
                this.file = data;

                // image or pdf
                if(data.token){
                    this.token = data.token
                }

            }catch(err) {
                console.log("error loading file...");
            } finally {
              setTimeout(() => {
                this.loadingFile = false;
              }, 500);
            }
        },

        async fileRequiresPassword(){
            try {
                await this.$http.get(`/api/files/${this.$route.params.id}/requires-password`);
                console.log("File does not require password")
                await this.loadFile()
            }catch(err) {
                if(err.response.data && err.response.data.requiresPassword){
                    this.filePasswordModalVisible = true
                    console.log('File requires password')
                }else{
                    console.log("error loading file...");
                }
            }
        },

        async onUnlock(correctPassword){
            this.correctPassword = correctPassword
            this.loadFile()
        },

        markAsRead() {
            console.log("doc is read");
        }

    }
}
</script>
<template>
    <div class="mx-auto md:w-4/5 md:pt-10 pt-6 min-h-screen report-item mx-auto pb-8 px-10 md:px-0">
        <div class="report-user-card-wrapper">
            <div class="w-full mx-auto">
                <div class="md:flex justify-between">
                    <div class="text-center md:flex">
                        <div class="text-left">
                            <skeleton :width="56" :height="10" />
                        </div>
                    </div>
                </div>
                <skeleton type="line"/>
            </div>
        </div>
        <div class="mt-12 flex flex-col gap-4">
            <skeleton :width="40" :height="5"/>
            <div class="flex gap-4">
                <skeleton type="circle" full-rounded v-for="i in 3" :key="i"/>
            </div>
        </div>
        <div class="mt-12 flex flex-col gap-4">
            <skeleton :width="40" :height="5"/>
            <div class="grid gap-4 grid-cols-2 md:grid-cols-4">
                <skeleton rounded full-width :height="96" v-for="j in 8" :key="j"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
}
</script>

<template>
    <div class="fixed h-full inset-0 w-screen h-screen z-80 flex text-white justify-center items-center img-story">
        <div class="absolute top-2 left-2 h-7 w-7 z-100 cursor-pointer" @click="close">
            <base-icon name="close" :size="7"/>
        </div>
        <div @click="changeStory('prev')" v-if="!fullScreen && currentStories.length > 1 && storiesPosition > 0" class="absolute z-50 left-2 cursor-pointer bg-white h-10 w-10 flex justify-center items-center bg-opacity-20 hover:bg-opacity-40 rounded-full">
            <base-icon name="arrow-left" :size="5"/>
        </div>
        <div @click="changeStory('next')" v-if="!fullScreen && currentStories.length > 1 && storiesPosition < currentStories.length - 1" class="absolute z-50 right-2 cursor-pointer bg-white h-10 w-10 flex justify-center items-center bg-opacity-20 hover:bg-opacity-40 rounded-full">
            <base-icon name="arrow-right" :size="5"/>
        </div>
        <div class="h-full relative w-full md:w-3/4 lg:w-1/2 bg-center bg-no-repeat z-85">
            <div v-if="!fullScreen" class="flex absolute top-0 inset-x-0 gap-1 z-80 bg-transparent justify-center items-center">
                <div v-for="story in computedStories" class="bg-gray-500 h-1 pt-1 mt-0.5 rounded relative" :style="'width: ' + (100 / currentStories.length) + '%'" :key="'story_' + story.id">
                    <div class="bg-white h-1 rounded absolute top-0 inset-x-0" :style="'width: ' + story.progress + '%'"></div>
                </div>
            </div>
            <div class="h-full flex items-center">
                <video v-if="storyIsVideo" :key="storySrc" autoplay loop class="w-full">
                    <source :src="storySrc" type="video/mp4"/>
                </video>
                <img v-else :src="storySrc" class="w-full"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {prepareAsB64, timeToSeconds} from '../../lib/strings';
    export default {
        name: 'StoryViewer',

        props: {
            currentStories: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            start: {
                type: Number,
                default: 0
            },
            fullScreen: {
                type: Boolean,
                default: false
            }
        },

        watch: {
            currentProgress (val) {
                if (val >= 100) {
                    this.changeStory();
                }
            }
        },

        data() {
            return {
                storiesPosition: this.start,
                currentProgress: 0,
                storyInterval: null,
                lastChange: null,
                ts: null
            }
        },

        computed: {
            computedStories() {
                return this.currentStories.map((story, index) => {
                    if (index < this.storiesPosition) {
                        story.progress = 100;
                    } else if (this.storiesPosition === index) {
                        story.progress = this.currentProgress;
                    } else {
                        story.progress = 0;
                    }
                    return story;
                });
            },

            storySelected() {
                if (this.currentStories[this.storiesPosition] !== undefined) {
                    if (this.currentStories[this.storiesPosition].media.length) {
                        return this.currentStories[this.storiesPosition];
                    }
                }
                return null;
            },

            storyIsVideo() {
                if (this.storySelected) {
                    let media = this.storySelected.media[0];
                    return media.mimetype.includes('video');
                }
                return false;
            },

            storySrc() {
                return this.storySelected ? prepareAsB64(this.storySelected.media[0].url) : '';
            }
        },

        mounted() {
            window.addEventListener('keyup', this.handleStoriesViewer);
            window.addEventListener('touchstart', this.handleTouchStart);
            window.addEventListener('touchend', this.handleTouchEnd);
            this.runProgress(0);
        },

        beforeDestroy() {
            window.removeEventListener('keyup', this.handleStoriesViewer);
            window.removeEventListener('touchstart', this.handleTouchStart);
            window.removeEventListener('touchend', this.handleTouchEnd);
        },

        methods: {
            handleTouchStart(e) {
                this.ts = e.changedTouches[0].clientY;
                this.$forceUpdate();
            },

            handleTouchEnd(e) {
                let end = e.changedTouches[0].clientY;
                let diff = end - this.ts;
                diff = Math.abs(diff);
                if (diff >= 400 && this.fullScreen) {
                    if (end > this.ts) {
                        if (this.storiesPosition > 0) {
                            this.changeStory('prev');
                        }
                    } else {
                        this.changeStory('next');
                    }
                }
                this.ts = null;
                this.$forceUpdate();
            },

            close(clear = true) {
                this.storiesPosition = 0;
                if (clear) {
                    this.clearStoryInterval();
                }
                this.$emit('close');
            },

            clearStoryInterval() {
                if (this.storyInterval !== null) {
                    clearInterval(this.storyInterval);
                }
                this.storyInterval = null;
            },

            handleStoriesViewer(event) {
                if (event.which === 27) { // esc
                    this.close();
                }

                if (event.which === 37) { // left
                    this.changeStory('prev');
                }

                if (event.which === 39) { // right
                    this.changeStory();
                }
            },

            changeStory(move = 'next') {
                this.currentProgress = move === 'next' ? 100 : 0;
                let now = new Date();
                if (this.lastChange === null) {
                    this.lastChange = now;
                } else {
                    if ((now - this.lastChange) < 450) {
                        return;
                    } else {
                        this.lastChange = now;
                    }
                }
                
                let pos = parseInt(this.storiesPosition);
                if (move === 'next') {
                    pos++;
                } else {
                    if (pos > 0) {
                        pos--;
                    }
                }

                if (this.currentStories[pos] !== undefined) {
                    this.storiesPosition = pos;
                    this.runProgress(pos);
                } else {
                    this.close();
                }
            },

            runProgress(pos) {
                this.clearStoryInterval();
                this.currentProgress = 0;
                setTimeout(() => {
                    if (this.currentStories.length && this.currentStories[pos] !== undefined) {
                        let seconds = 10;
                        let stories = this.currentStories;
                        if (stories[pos] !== undefined && stories[pos].media.length) {
                            let media = stories[pos].media[0];
                            if (media.mimetype.includes('video') && media.video_duration !== undefined) {
                                seconds = timeToSeconds(media.video_duration);
                            }
                        }
                        this.handleProgress(pos, seconds);
                    } else {
                        this.close();
                    }
                }, 250);
            },

            handleProgress(pos, seconds = 10) {
                let frame = () => {
                    this.currentProgress++;
                };

                this.storyInterval = setInterval(frame, seconds * 10);
            },
        }
    }
</script>

<style scoped>

</style>
<template>
    <base-modal
        :visible="open"
        @close="$emit('close')"
        title="Link Content to a Deliverable"
    >
        <div class="flex flex-col justify-between" :key="!open">
            <custom-select
                placeholder="Select a deliverable"
                :options="options"
                v-model="selected_deliverable"
                :required="true"
            />
        </div>
        <div class="flex justify-between gap-3 mt-6 rounded-br-2xl">
            <base-button @action="$emit('close')" :disabled="saving" type="secondary" size="md" label="Cancel"/>
            <base-button @action="linkPost" :disabled="selected_deliverable === null || saving" size="md" :icon-size="4" label="Link Content" bold/>
        </div>
    </base-modal>
</template>

<script>
    export default {
        name: 'LinkPost',
        props: {
            open: {
                type: Boolean,
                default: false
            },
            currentPost: {
                type: Object
            },
            deliverables: {
                type: Array,
                default: function () {
                    return [];
                }
            }
        },
        data() {
            return {
                saving: false,
                selected_deliverable: null,
                post: {},
            }
        },
        watch: {
            open: function (val, old) {
                if (val && val !== old) {
                    this.post = JSON.parse(JSON.stringify(this.currentPost));
                    this.selected_deliverable = null;
                }
            }
        },
        computed: {
            options() {
                return this.deliverables.map(deliverable => {
                    deliverable.name = deliverable.name || deliverable.deliverable.name;
                    return deliverable;
                });
            }
        },
        methods: {
            async linkPost() {
                this.saving = true;
                try {
                    let post = { post_id: this.post.id, deliverable_id: this.selected_deliverable.id };
                    await this.$http.post(`/api/userDeliverables/report/link`, post);

                    this.$notify({ title: 'Success', text: `Content linked successfully`, type: 'success' });

                    this.$emit('finish-update');

                    this.post = {};
                    this.selected_deliverable = null;
                } catch(e) {
                    this.$notify({ title: 'Error', text: `Error saving! Please try again!`, type: 'warn' });
                    console.log(e);
                } finally {
                    this.saving = false;
                }
            },
        }
    }
</script>

<style scoped>

</style>
<template>
<div class="walkthrough">
        
    <div class="step">
        <div class="title">Step 1</div>
        <div>Enter <a href="https://www.facebook.com/" target="_blank" class="link">https://www.facebook.com</a>, and from your Feed, click Pages in the left menu.</div>
        <img class="image" :src="imgs.step1" @click="openImage = imgs.step1" />
    </div>

    <div class="step">
        <div class="title">Step 2</div>
        <div>Click your Page name to go to your Page.</div>
        <img class="image" :src="imgs.step2" @click="openImage = imgs.step2" />
    </div>

    <div class="step">
        <div class="title">Step 3</div>
        <div>Click About at the top of your Page. If you don't see it, click More.</div>
        <img class="image" :src="imgs.step3" @click="openImage = imgs.step3" />
    </div>

    <div class="step">
        <div class="title">Step 4</div>
        <div>Scroll down to find your Page ID below MORE INFO. </div>
        <img class="image" :src="imgs.step4" @click="openImage = imgs.step4" />
    </div>

    <lightbox :image="openImage" @close="openImage = null" />

</div>
</template>
<script>
import { step1, step2, step3, step4 } from './images';
import Lightbox from '../../components/Lightbox.vue'
export default {
    components: {
        Lightbox
    },
    data(){
        return {
            imgs: {step1, step2, step3, step4},
            openImage: null
        }
    }
}
</script>
<style lang="scss" scoped>
    .walkthrough{
        @apply flex flex-col gap-y-6 p-12;
    }
    .image{
        @apply mt-4 cursor-pointer transition-opacity duration-200;
        &:hover{
            @apply opacity-50;
        }
    }
    .title{
        @apply text-h4 font-bold;
    }
    .link{
        @apply text-blue-600 font-bold;
    }
</style>
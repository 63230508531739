<template>

    <base-modal
        :visible="visible"
        :hideHeader="true"
        @close="$emit('close')"
    >
        <div class="w-full flex justify-center">
            <base-icon
                class="w-24 h-24 text-green-m" 
                name="check-circle" 
            />
        </div>

        <h1 class="font-semibold text-2xl text-black text-center"> 
            Done!
        </h1>

        <div class="font-medium text-sm text-center">
            You will receive an email with the instructions to reset your password
        </div>

        <div class="mt-4 w-full flex justify-center">
            <base-button
                @action="$router.back()" 
                class="mt-4"
                label="Go Back"
            />
        </div>

    </base-modal>
</template>

<script>
export default {
    props: ['visible']
}
</script>
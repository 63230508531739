<template>
    <div>
        <div>
            <base-icon v-if="loading" name="loader"/>
            <facebook-token-info
                v-if="user"
                :user-id="user.id"
                :user="user"
                @showInviteToLoginWithFacebook="showInviteToLoginWithFacebook"
            />
            <div v-else>No token info</div>
        </div>
        <div class="footer">
            <base-button
                type="secondary"
                icon="arrow-left"
                @action="$emit('close')"
                label="Back"
            />
        </div>
    </div>
</template>
<script>
import FacebookTokenInfo from '@/components/FacebookTokenInfo.vue'
export default {
    props: ['user'],
    components: {
        FacebookTokenInfo
    },
    data(){
        return {
            loading: false
        }
    },
    methods: {
        showInviteToLoginWithFacebook(){
            this.$swal.fire({
                title: 'Invite this creator to login with facebook?',
                text: 'This will send an email to the creator to login in on the platform with facebook',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Send Email',
                reverseButtons: true,
            }).then((result) => {
                if(result.isConfirmed){
                    this.inviteToLoginWithFacebook()
                }
            })
        },

        inviteToLoginWithFacebook(){
            this.loading = true
            this.$http.post('/api/invite/intent', {
                roleId: 13, // creator
                targetEmails: this.user.email,
                targetId: this.user.id,
                networkId: 6
            }).then(async (res) => {
                
                if(res.data){
                    
                    await this.$http.post('/api/invite/social-login', { 
                        email: this.user.email,
                        url: `${window.location.origin}/social-login?t=${res.data.token}` ,
                        networkName: 'facebook'
                    })

                    this.$notify({ title: 'Success', text: 'Invite sent successfully', type: 'success' })
                    
                }

            }).catch((err) => {
                console.log('sendRequest error', err)
                if(err.response && err.response.data && err.response.data.message){
                    this.$notify({ title: 'Warning', text: err.response.data.message, type: 'warn' })
                }else{
                    this.$notify({ title: 'Warning', text: 'Something went wrong', type: 'warn' })
                }
            }).finally(() => {
                this.loading = false
            })  
        },
    }
}
</script>
<style lang="scss" scoped>
    .footer{
        @apply flex justify-between mt-10;
    }
</style>

export default [
    { label: 'Limit to 10 creators', value: 10 }, 
    { label: 'Limit to 50 creators', value: 50 },
    { label: 'Limit to 100 creators', value: 100 },
    { label: 'Limit to 200 creators', value: 200 },
    { label: 'Limit to 300 creators', value: 300 },
    { label: 'Limit to 400 creators', value: 400 },
    { label: 'Limit to 500 creators', value: 500 },
    { label: 'Limit to 1000 creators', value: 1000 },
    { label: 'Limit to 2000 creators', value: 2000 },
    { label: 'Limit to 5000 creators', value: 5000 },
    { label: 'Limit to 10000 creators', value: 10000 }
]
<template>
<main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
    <navbar></navbar>    
    <div class="p-4 text-4xl font-semibold">Creator Home</div>
</main>
</template>

<script>
import Navbar from '../../components/Navbar.vue'
export default {
    components: {
        Navbar
    }
}
</script>

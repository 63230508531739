import { validString } from '@/lib/strings'

// given an state query, return the url so the user can be redirected
// if its not found, returns null
const getUrlFromStateQuery = (str) => {
    const stateArray = str.split('&');
    // stateArray = ['security_token=token', 'url=https://return-url.com']
    if (stateArray.length) {
        for (let stateItem of stateArray) {
            // stateItem = 'security_token=token'
            // stateItem = 'url=https://return-url.com'

            const fragments = stateItem.split('=');
            // fragments = [ security_token, token ]

            if (fragments.length && fragments[0] === 'url' && validString(fragments[1])) {
                return fragments[1];
            }
        }
    }
    return null;
}

const buildGoogleAuthUrl = (params) => {
    let url = `https://accounts.google.com/o/oauth2/v2/auth?`;
    for (const key in params) {
        if (Object.hasOwnProperty.call(params, key)) {
            let value = params[key];
            if(key == "scope" || key == "state") value = encodeURIComponent(value);
            url += `${key}=${value}`;
            if(key != "nonce") url += `&`
        }
    }
    return url;
}

export {
    buildGoogleAuthUrl,
    getUrlFromStateQuery
};

export default function generateCsv (csv, data, title) {
    data.forEach(function(row) {
        csv += row.join(',');
        csv += "\n";
    });

    let csvData = new Blob([csv], { type: 'text/csv' });
    let hiddenElement = document.createElement('a');
    hiddenElement.href = URL.createObjectURL(csvData);
    hiddenElement.target = '_blank';
    hiddenElement.download = title ? `${title}.csv` : 'report.csv';
    hiddenElement.click();
}
<template>
    <div class="container">
        <div class="text">You can also</div> 
        <div
            class="download-button"
            @click="optionsDropdownVisible = !optionsDropdownVisible"
        >
            <div>download</div>
            <div
                class="options-dropdown" 
                v-if="optionsDropdownVisible"
            >
                <div class="content">
                    <a 
                        v-for="(option, index) in options"
                        :key="index"
                        :href="option.href"
                        download
                    >
                        <div>{{ option.name }}</div>
                        <base-icon name="download" />
                    </a>
                </div>
                <div class="overlay"/>
            </div>
        </div>
        <div class="text">
            this template, add the creator information, and then import it.
        </div> 
    </div>
</template>
<script>
export default {
    data(){
        return {
            optionsDropdownVisible: false,
            options: [
                {
                    name: 'CSV',
                    href: '/import-report-template-example.csv'
                },
                // {
                //     name: 'XLS',
                //     href: '/template.xls'
                // },
                {
                    name: 'XLSX',
                    href: '/import-report-template-example.xlsx'
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply flex items-center gap-x-1;
        > .text{
            @apply;
        }
        > .download-button{
            @apply relative font-bold cursor-pointer;
            
            .options-dropdown{
                //
                .content{
                    @apply absolute z-20 border border-gray-400 rounded-lg bg-white flex flex-col;
                    @apply left-0 right-0;
                    top: 30px;
                    width: 200px;
                    > a{
                        @apply font-bold p-4 transition-opacity duration-200 flex justify-between items-center;
                        &:hover{
                            @apply opacity-50;
                        }
                    }
                }
                .overlay{
                    @apply fixed inset-0 z-10 cursor-pointer;
                    content: '';
                }
            }
        }
    }
</style>
<template>
    <div class="edit-current-user">

        <div class="user" v-if="creator && creator.user">
            
            <!-- content -->
            <div class="content">
                <avatar :user="creator.user" />
                <div class="names">
                    <div class="name">
                        {{ creator.user.first_name }} {{ creator.user.last_name }} <span class="text-pxs font-normal">(ID: {{creator.user.id}})</span>
                    </div>
                    
                    <div class="email">
                        {{creator.user.email}}
                    </div>
                    <div v-if="selectedAction == 'change-email'">
                        <FormulateInput
                            type="email"
                            validation="required"
                            v-model="email"
                            :disabled="loading"
                            label="New email"
                            style="width: 370px;"
                            placeholder="Email"
                            validation-name="Email"
                        />
                    </div>

                    <div v-if="creator.user.found_by" class="found-by">
                        Found by {{creator.user.found_by == 'both' ? 'email and handle' : creator.user.found_by}}
                    </div>
                    <div class="handle-list" v-if="getInstagramHandles(creator.user).length">
                        <div 
                            class="handle-item"
                            v-for="(handle, index) in getInstagramHandles(creator.user)" 
                            :key="`ig-handle-${index}`"
                        >
                            <base-icon name="instagram" />
                            {{ handle }}
                        </div>
                    </div>
                    <div v-if="selectedAction == 'change-handle'">
                        <FormulateInput
                            type="text"
                            :label="`New ${targetNetworkName} handle`"
                            :placeholder="`New ${targetNetworkName} handle`"
                            style="width: 370px;"
                            v-model="handle"
                            :disabled="loading"
                            v-debounce="(inputValue, e) => checkHandle(inputValue)"
                        />
                        <div class="error" v-if="isValidString(errorMessage)">{{errorMessage}}</div>
                    </div>
                </div>
            </div>

            <!-- actions -->
            <div class="actions">
                <template v-if="!selectedAction">
                    <base-button
                        label="Change email"
                        bold
                        @action="selectedAction = 'change-email'"
                    />
                    <base-button
                        label="Change handle"
                        bold
                        @action="selectedAction = 'change-handle'"
                    />
                </template>
                <template v-if="selectedAction">
                    <base-button
                        v-if="selectedAction == 'change-email'"
                        label="Save email"
                        bold
                        :disabled="loading || !submitButtonEnabled || !isValidString(email) || !validateEmail(email)"
                        @action="changeEmail()"
                    />
                    <base-button
                        v-if="selectedAction == 'change-handle'"
                        label="Save handle"
                        bold
                        :disabled="loading || !submitButtonEnabled || !isValidString(handle)"
                        @action="changeHandleIntent()"
                    />
                    <base-button
                        label="Cancel"
                        type="secondary"
                        bold
                        @action="selectedAction = null"
                    />
                </template>
            </div>

        </div>


    </div>
</template>
<script>
import { validateEmail } from '../lib/strings';
import { copyObject, isValidArray, isValidObject, isValidString, notifyCatchError } from '../common';
export default {
    props: {
        creator: {
            type: Object
        }
    },
    data(){
        return {
            selectedAction: null,

            email: '',
            handle: '',
            loading: false,
            
            errorMessage: '',
            submitButtonEnabled: true,

            isValidString,
            validateEmail
        }
    },
    computed: {
        targetNetworkName(){
            return this.creator && this.creator.target_network ? this.creator.target_network.name : ''
        },
        targetNetworkId(){
            return this.creator && this.creator.target_network ? this.creator.target_network.id : ''
        }
    },
    methods: {
        changeEmail(){
            if(this.creator && this.creator.user && this.creator.user.id && isValidString(this.email) && validateEmail(this.email)){
                this.loading = true;
                const { first_name, last_name, phone, tags } = this.creator.user;
                this.$http.put(`/api/users/${this.creator.user.id}/profile`, {
                    email: this.email,
                    first_name,
                    last_name,
                    phone,
                    tags
                }).then(({ data }) => {
                    if(data){
                        this.$notify({ title: 'Success', text: 'Email changed successfully', type: 'success' })
                        let user = copyObject(this.creator.user);
                        // change email
                        user.email = this.email;
                        this.$emit('update:user', user);
                    }
                }).catch(err => {
                    notifyCatchError(err, this.$notify);
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        changeHandleIntent(){
            this.$swal.fire({
                title: `Are you sure you want to change this user's handle ?`,
                text: 'The previous user handle and audience data will be removed',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Change handle',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.changeHandle();
                }
            });
        },
        changeHandle(){
            if(this.creator && this.creator.user && this.creator.user.id && this.targetNetworkId && isValidString(this.handle)){
                this.loading = true;
                this.$http.put(`/api/users/${this.creator.user.id}/change-handle`, {
                    network_id: this.targetNetworkId,
                    handle: this.handle
                }).then(({ data }) => {
                    if(data){
                        this.$notify({ title: 'Success', text: 'Handle changed successfully', type: 'success' })
                        let user = copyObject(this.creator.user);
                        // remove all target network social accounts                        
                        user.social_accounts = user.social_accounts.filter(element => {
                            return element.network_id !== this.targetNetworkId
                        });
                        // only add the new social account to the user
                        user.social_accounts.push(data);
                        this.$emit('update:user', user);
                    }
                }).catch(err => {
                    notifyCatchError(err, this.$notify);
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        getInstagramHandles(user){
            const target_network_id = (this.creator && this.creator.target_network && this.creator.target_network.id) ? this.creator.target_network.id : null;
            if(target_network_id && isValidArray(user.social_accounts)){
                return user.social_accounts.filter(element => element.network_id == target_network_id).map(element => element.handle);
            }
            return [];
        },
        checkHandle(handle){
            if(isValidString(handle) && isValidString(this.targetNetworkName)){
                
                this.loading = true;
                
                // reset vars
                this.errorMessage = '';
                this.submitButtonEnabled = true;
                
                this.$http.get(`/api/social-api-service/user-lookup?handle=${handle}&platform=${this.targetNetworkName}`).then(({ data }) => {
                    
                    const { exists, results, users } = data;
                    // exists: if the handle exists on our db
                    // results: the scraped results for the handle
                    // users: users that already have that handle
                    
                    // does not exist on database but there are no scraped results either 
                    if(!exists && !users.length && !isValidObject(results)){
                        this.errorMessage = 'No lookup results for this user, you can still add the handle.';
                        this.submitButtonEnabled = true;
                    }

                    // user does not exist on the database, we will show the scraped results
                    if (!exists || (exists && users && Array.isArray(users) && !users.length)) {
                        if(isValidObject(results)){
                            this.errorMessage = '';
                            this.submitButtonEnabled = true;
                        }
                    }

                    // if there are users with that handle already, show a message
                    if(exists && users && Array.isArray(users) && users.length){
                        this.errorMessage = 'A creator in this company is already using this handle.';
                        this.submitButtonEnabled = false;
                    }

                }).catch(err => {
                    notifyCatchError(err, this.$notify);
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .edit-current-user{
        // 
        > .user{
            @apply flex items-center border border-gray-300 rounded-lg px-4 py-3;
            > .content{
                @apply flex-grow flex-shrink;
                @apply flex items-center gap-x-4;
                > .names{
                    @apply flex flex-col gap-y-1;
                    > .name{
                        @apply text-h5 font-bold;
                    }
                    > .email{
                        @apply text-pxs;
                    }
                    > .found-by{
                        @apply text-pxs;   
                    }
                    > .handle-list{
                        @apply flex flex-col;
                        > .handle-item{
                            @apply flex items-center gap-x-2;
                            @apply text-pxs;
                        }
                    }
                    .error{
                        @apply text-pxs text-red-500;
                    }
                }
            }
            > .actions{
                @apply flex-grow-0 flex-shrink-0;
                @apply flex flex-col gap-y-2;
            }
        }
    }
</style>

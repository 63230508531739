<template>
    <base-modal 
        :title="`Request a ${type}`"
        :visible="visible"
        classes="max-w-4xl"
        noPadding
        @close="$emit('close')"
    >
        <div class="flex justify-between gap-x-6 pt-5 pb-10 px-7">

            <div class="flex flex-col flex-grow flex-shrink gap-y-6">
                
                <div class="field">
                    
                    <div class="input-label">Creator Email</div>
                    <FormulateInput
                        v-model="email"
                        type="text"
                        placeholder="Enter Creator Email"
                        validation="required|email"
                        validation-name="Creator Email"
                        class="w-full"
                    />

                    <FormulateInput
                        v-model="ccMyself"
                        type="checkbox"
                        label="CC to myself"
                        @change="checkEmails"
                    />
                </div>
                
                <div class="field">
                    <div class="flex justify-between">
                        <div class="input-label">CC other people</div>
                        <div class="text-pxs">Separate multiple emails by comma.</div>
                    </div>
                    <FormulateInput
                        v-model="emailString"
                        type="text"
                        placeholder="Enter emails"
                        @change="checkEmails"
                    />
                </div>
                
                <div class="field">

                    <div class="input-label">
                        Message
                    </div>

                    <FormulateInput
                        type="textarea"
                        class="w-full"
                        placeholder="Enter message..."
                        v-model="emailBody"
                        input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-32"
                        :rows="1"
                    />
                </div>
            </div>
            <div class="flex-grow-0 flex-shrink-0 w-64">
    
                <div class="bg-white rounded-lg p-4 flex flex-col gap-y-4">
                    <div class="text-h6 font-bold">Collection Link</div>
                    <div class="truncate">{{getLocation}}</div>
                    <base-button 
                        @action="copyToClipboard" 
                        theme="dark" 
                        secondaryIcon="link" 
                        label="Copy Link"
                        class="w-full"
                    />
                </div>
            </div>
        </div>

        <div class="w-full bg-white rounded-xl py-4 px-6 flex justify-end">
            <base-button 
                @action="submit" 
                secondary-icon="arrow-right" 
                class="font-bold"
                label="Send email"
                size="lg"
            />
        </div>

    </base-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateEmail } from '../lib/strings';
import moment from 'moment'
export default {
    props: ['visible', 'type', 'deliverableToken', 'creatorEmail', 'creatorName', 'submitBy'],
    data() {
        return { 
            email: '',
            loading: false,
            ccMyself: false,
            emailString: '',
            emailArray: [],
            emailBody: ''
        }
    },
    
    watch: {
        visible(val){
            if(val == true){
                let submitBy = this.submitBy ? moment(this.submitBy).format('MM/DD') : '*mm/dd*'
                this.email = this.creatorEmail
                this.emailBody = `Hi ${this.creatorName},
Please use the link below to submit your post ${this.type} by ${submitBy} and let me know if you have any questions!
Thanks,
${this.me.first_name} ${this.me.last_name}`
            }
        }
    },
    computed: {
        ...mapGetters(['me']),

        getLocation(){
            return `${window.location.origin}/collection?t=${this.deliverableToken}&type=${this.type}`
        }
    },
    methods: {
        checkEmails() {
            // clear
            this.emailArray = [];
            // logged user
            if(this.ccMyself)
                this.emailArray.push(this.me.email);
            // additionals
            if(this.emailString){
                this.emailArray = this.emailArray.concat(this.emailString.split(',')).filter(email => {
                    return email !== '';
                });
            }
        },
        copyToClipboard() {
            this.$copyToClipboard(this.$notify, this.getLocation);
        },
        validMails() {
            let fails = 0;
            for (let i in this.emailArray) {
                if (!validateEmail(this.emailArray[i])) {
                    fails++;
                }
            }

            return !fails;
        },
        async submit() {

            if(this.emailBody.includes('*mm/dd*')){
                return this.$notify({ title: 'Warning', text: "You didn't put a date in the message, enter a proper date", type: 'warn'})
            }

            let payload = {
                email: this.email,
                emails: this.emailArray,
                link: this.getLocation,
                type: this.type,
                body: this.emailBody
            };

            if (this.validMails()) {
                const { data } = await this.$http.post(`/api/userDeliverableVersions/request`, payload);
                if (data) {
                    this.$notify({ title: 'Success', text: data.message, type: 'success' });
                }
            } else {
                this.$notify({ title: 'Error', text: 'One or more emails are not valid', type: 'error' })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .field {
        @apply w-full flex flex-col gap-y-2;
    } 
    .input-label{
        @apply text-h6 font-bold;
    }
</style>
<template>
    <div class="pdf-wrapper">
        <pdf-preview-banner v-if="banner">
            {{ banner.text }}
            <template v-slot:action>
                <a href="#" @click.prevent="banner.action" class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-purple-600 bg-white hover:bg-purple-50">
                    Acknowledge
                </a>
            </template>
        </pdf-preview-banner>
        <div :class="['pdf-shell', { 'has-banner': banner }]">
            <pdf :src="fullUrl"
                class="relative z-0"
                @num-pages="totalPages = $event"
                @page-loaded="currentPage = $event"
                :page="currentPage"
            ></pdf>
            <div :class="['page-wrapper', { 'fixed-mode': fixedPagination }]">
                <nav class="relative z-10 inline-flex shadow-sm -space-x-px" aria-label="Pagination">
                    <a :class="['page-button', {'active': i === currentPage}]"
                        v-for="i in totalPages"
                        :key="i"
                        @click="currentPage = i"
                    >{{i}}</a>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import pdf from 'vue-pdf';
import PdfPreviewBanner from './PdfPreviewBanner';
import store from '../store';

export default {
    props: {
        url: {},
        banner: {},
        fixedPagination: {
            type: Boolean,
            default: false
        }
    },
    components: {
        pdf,
        PdfPreviewBanner
    },

    data() {
        return {
            totalPages: 0,
            currentPage: 1,
        }
    },

    computed: {
        urlWithToken() {
            return `${this.url}?token=${store.getters.accessToken}`
        },

        fullUrl() {
            return this.withToken ? this.urlWithToken : this.url;
        }
    },

    methods: {
        setPages(num) {
            this.totalPages = num;
        }
    }
}
</script>

<style lang="scss">
.pdf-wrapper {
    .inner-banner-wrapper {
        @apply fixed top-0 w-full z-10;
    }
}

.pdf-shell {
    @apply relative;

    &.has-banner {
        margin-top: 70px;
        height: calc(100% - 70px);
        overflow: auto;
    }
}

.page-wrapper {
    @apply absolute bottom-5 right-5;

    &.fixed-mode {
        @apply fixed;
    }

    .page-button {
        @apply cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700;

        &:hover {
            @apply bg-gray-50;
        }

        &.active {
            @apply bg-gray-100 text-gray-700;
        }
    }
}
</style>
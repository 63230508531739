<template>    
    <div class="select-wrapper relative flex w-full h-full">
      <base-button @action="toggleDropdown(!open)" type="label" :rounded="colors ? 'full' : rounded"
        :disabled="disabled"
        :theme="getLabelColor ? (hexToRgbSum(getLabelColor) > themeThreshold ? 'light' : 'dark') : theme"
        :hover="getLabelColor ? false : true"
        :class="[`w-full h-full text-pxs py-1 ${!collapsed ? 'pr-3 pl-4' : ''} focus:outline-none transition ease-in-out duration-150`,
                `rounded-${rounded} border border-transparent bg-tan-m`,
                {'rounded-b-none': open && !colors},
                {'bg-white hover:bg-gray-m-light': theme === 'light'},
                {'bg-gray-m-light': open && theme === 'light'},
                {'bg-black hover:bg-gray-m-dark': theme === 'dark'},
                {'bg-gray-m-dark': open && theme === 'dark'},
                {'bg-transparent': disabled},
                {'hover:opacity-75': getLabelColor}]"
        :style="getLabelColor ? `background-color: ${getLabelColor}` : ''">
        <div v-if="!collapsed" class="w-full h-full flex gap-x-2 justify-start items-center">
            <div class="line-clamp-1 text-left">{{ getLabel }}</div>
            <base-icon class="ml-auto" name="chevron-down" v-if="!disabled"/>
        </div>
        <div v-else>
          <base-icon class="m-auto" name="chevron-down" />
        </div>
      </base-button>

      <div v-if="open"
        :class="[`select-menu absolute z-10 top-full ${!colors ? '-mt-px' : ''} w-72 shadow-lg rounded-lg`,
                {'rounded-t-none': !colors},
                `${collapsed ? 'left-0 rounded-t-md rounded-tl-none' : 'left-1/2 -ml-28'}`,                
                {'bg-white': theme === 'light'}, {'bg-black': theme === 'dark'}]"
        :style="`${selectMenuStyle ? selectMenuStyle : ``}`"
      >  
        <div class="">             
          <div :class="colors ? 'space-y-2 p-3 pb-2' : ''" v-if="options.length || defaultOption || addingOption">
            <div @click="(e)=>e.stopPropagation()" class="h-6 flex items-center justify-between">
              <base-button v-if="addingOption" @action="cancelAddOption()" type="label" icon="arrow-left" rounded="none" theme="neutral"              
                :label="addLabel"
              />
              <base-button v-else-if="editingOption" @action="cancelEditOption()" type="label" icon="arrow-left" rounded="none" theme="neutral"              
                label="Edit Status"
              />
              <span v-else class="text-h6 text-purple-m-secondary">Statuses</span>
              <base-button v-if="!addingOption && !editingOption" icon="plus" type="label" @action="startAddOption"/>
            </div>

            <div v-if="addingOption" class="">
              <div :class="['w-full space-y-2', {'py-1 px-2': !colors},
                          {'hover:bg-gray-m-light': theme === 'light' && !colors},
                          {'hover:bg-gray-m-dark': theme === 'dark' && !colors}]">
                <input v-model="newOption" type="text" placeholder="Label"
                  :class="['w-full text-left text-pxs', `${colors ? 'py-3 px-4' : 'py-1 px-2'}`,
                          `rounded-${colors ? 'lg' : rounded}`, 'border border-gray-m focus:outline-none',
                          {'text-purple-m-secondary bg-white': theme === 'light'},
                          {'text-white bg-black': theme === 'dark'}]"
                >
                <div class="grid grid-cols-4 gap-2" v-if="colors">
                  <span
                    v-for="(color, i) in colors" 
                    :key="i"
                    @click.prevent="newOptionColor = color"
                    class="relative cursor-pointer h-10 rounded-lg border border-transparent" :style="`background-color: ${color.name}`">
                      <base-icon v-if="newOptionColor === color" :class="`absolute top-2 left-4 text-${(hexToRgbSum(color.name) > themeThreshold ? 'black' : 'white')}`" name="check"/>
                  </span>
                </div>
              </div>
            </div>  

            <div v-else-if="editingOption" class="">
              <div :class="['w-full space-y-2', {'py-1 px-2': !colors},
                          {'hover:bg-gray-m-light': theme === 'light' && !colors},
                          {'hover:bg-gray-m-dark': theme === 'dark' && !colors}]">
                <input v-model="options[changedOption].editName" type="text" placeholder="Label"
                  :class="['w-full text-left text-pxs', `${colors ? 'py-3 px-4' : 'py-1 px-2'}`,
                          `rounded-${colors ? 'lg' : rounded}`, 'border border-gray-m focus:outline-none',
                          {'text-purple-m-secondary bg-white': theme === 'light'},
                          {'text-white bg-black': theme === 'dark'}]"
                >
                <div class="grid grid-cols-4 gap-2" v-if="colors">
                  <span
                    v-for="(color, i) in colors" 
                    :key="i"
                    @click.prevent="options[changedOption].editColor = color"
                    class="relative cursor-pointer h-10 rounded-lg border border-transparent" :style="`background-color: ${color.name}`">
                      <base-icon v-if="options[changedOption].editColor && options[changedOption].editColor.id === color.id" :class="`absolute top-2 left-4 text-${(hexToRgbSum(color.name) > themeThreshold ? 'black' : 'white')}`" name="check"/>
                  </span>
                </div>
              </div>              
            </div>

            <div v-else class="flex flex-col gap-y-2">
              <template v-if="defaultOption">
                <div :class="[{'hover:bg-gray-m-light': theme === 'light' && !colors},
                              {'hover:bg-gray-m-dark': theme === 'dark' && !colors}]">
                  <base-button @action="selectDefault()" type="label" :rounded="rounded" justify="start" :theme="theme"
                    :class="['w-full px-4', `${colors ? 'py-2' : 'py-2'}`,
                            {'hover:bg-gray-m-light': theme === 'light' && colors},
                            {'hover:bg-gray-m-dark': theme === 'dark' && colors}]"
                    :label="defaultOption"/>
                </div>
              </template>   

              <template v-if="options.length">
                <div v-for="(option, i) in options" :key="i" @mouseenter="option.showActions = true" @mouseleave="option.showActions = false">
                  <div :class="['flex justify-between items-center gap-x-1',
                              {'hover:bg-gray-m-light': theme === 'light' && !colors},
                              {'hover:bg-gray-m-dark': theme === 'dark' && !colors}]">

                    <div class="w-5/6 max-w-5/6 overflow-x-hidden">
                      <base-button v-if="!option.editing"
                        @action="selectOption(option)" type="label" :rounded="colors ? 'lg' : rounded" justify="start"
                        :theme="option.color ? (hexToRgbSum(option.color.name) > themeThreshold ? 'light' : 'dark') : theme"
                        :hover="option.color ? false : true"
                        :class="['w-full max-w-full truncate px-4 text-pxs bg-tan-m', `${colors ? 'py-3' : 'py-2'}`,
                                {'border border-transparent': colors},
                                {'hover:bg-gray-m-light': theme === 'light' && colors},
                                {'hover:bg-gray-m-dark': theme === 'dark' && colors},
                                {'hover:opacity-75': option.color}]"
                        :style="option.color ? `background-color: ${option.color.name}` : ''"
                        :label="option.name"
                      />
                      <!-- <div v-else class="space-y-1">
                        <div :class="{'pl-2': !colors}">
                          <input v-model="option.editName" type="text"
                            :class="['text-left text-sm font-medium', `${colors ? 'w-full py-1 px-4' : 'w-full py-1 my-1 px-2'}`,
                                    `rounded-${rounded}`, 'border border-gray-m focus:outline-none',
                                    {'text-black bg-white ': theme === 'light'},
                                    {'text-white bg-black': theme === 'dark'}]"                        
                          >
                        </div>
                        <div class="flex flex-wrap gap-1" v-if="colors">
                          <span
                            v-for="(color, i) in colors" 
                            :key="i"
                            @click.prevent="option.editColor = color"
                            :class="['inline-flex justify-center items-center cursor-pointer bg-transparent w-8 h-8 rounded-full transition ease-in-out duration-150',
                                    {'hover:bg-gray-m-light': !option.editColor || (option.editColor && option.editColor.id !== color.id) },
                                    {'bg-gray-m-light': option.editColor && option.editColor.id === color.id }]">
                            <span class="w-6 h-6 rounded-full border border-gray-m-light"
                              :style="`background-color: ${color.name}; border-width: 1px; border-color: ${hexToModRgb(color.name, colorMod)};`">
                            </span>
                          </span>
                        </div>
                      </div> -->
                    </div>
                    
                    <div :class="['h-8 w-1/6 flex justify-center items-center', {'my-auto mr-2': !colors}]" @click="(e)=>e.stopPropagation()">
                      <base-button 
                        v-if="!option.editing" 
                        @action="startEditOption(option)" 
                        :disabled="editingOption || addingOption || option.is_default"
                        v-tooltip="option.is_default ? { content: `Default status`, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } } : {}"
                        :theme="option.is_default ? 'light' : 'neutral'"
                        icon="pencil"
                        type="label"
                        rounded="full"                
                        :class="['w-full h-full']"
                      />
                      <!-- <base-button v-else-if="editingOption && option.editing"
                        @action="startDeleteOption(option)" rounded="full" type="label" icon="trash" theme="cancel"                
                        :class="['w-full h-full']"
                      />  -->                   
                    </div>

                  </div>
                </div>
                
              </template>
            </div>   


            
          </div>

          <template v-else>
            <div class="px-6 leading-10 py-4 text-sm text-gray-m-disable cursor-not-allowed text-center">
              There are no options available.
            </div>
          </template>

          <!-- <separator /> -->

          <div @click="(e)=>e.stopPropagation()" class="px-3 pb-3 pt-0">
            <template v-if="addingOption">
              <div class="flex justify-between items-center">
                <!-- <base-button @action="cancelAddOption()" type="label" rounded="none" theme="cancel"                
                  :class="['w-full px-4 py-2',
                          {'hover:bg-gray-m-light': theme === 'light'},
                          {'hover:bg-gray-m-dark': theme === 'dark'}]"
                  label="Cancel"
                /> -->
                <base-button @action="addOption()" type="label" rounded="lg" theme="neutral-dark"                
                  :class="['w-full px-4 py-3 bg-purple-m-main text-pxs',
                          {'hover:bg-purple-m-secondary': theme === 'light'},
                          {'hover:bg-gray-m-dark': theme === 'dark'}]"
                  label="Create"
                />
              </div>
            </template>
            <template v-else-if="editingOption">
              <div class="flex justify-between gap-x-2 items-center">                
                <base-button @action="editOption()" type="label" rounded="lg" theme="neutral-dark"                
                  :class="['w-full px-4 py-3 bg-purple-m-main text-pxs',
                          {'hover:bg-purple-m-secondary': theme === 'light'},
                          {'hover:bg-gray-m-dark': theme === 'dark'}]"
                  label="Save"
                />
                <base-button @action="startDeleteOption(options[changedOption])" type="label" rounded="lg" theme="neutral"               
                  :class="['w-full px-4 py-3 bg-tan-m text-pxs',
                        {'hover:bg-gray-m-light': theme === 'light'},
                        {'hover:bg-gray-m-dark': theme === 'dark'}]"
                  label="Delete"
                />
              </div>
            </template>
          </div>

        </div>      
      </div>      
    </div>    
</template>

<script>
export default {
  props:{
    add: {
      type: Object
    },
    select: {
      type: Object
    },
    edit: {
      type: Object
    },
    delete: {
      type: Object
    },
    restore: {
      type: Object,
      default: function () {
        return null;
      }
    },
    options:{
      type: Array,
      default: function () {
        return [];
      }
    },
    colors: {
      type: Array      
    },
    defaultOption: {
      type: String
    },
    label:{
        type: Object,        
        default: function () {
          return {
            name: 'Option'
          }
        }
    },
    addLabel:{
        type: String,
        default: 'New Option'
    },
    theme: {
      type: String,
      default: 'light'
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: String,
      default: 'md'
    },
    selectMenuStyle: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      open: false,
      addingOption: false,            
      newOption: "",
      newOptionColor: null,
      editingOption: false,
      changedOption: null,
      themeThreshold: 400, // 0 is black, 765 is white.
      colorMod: -51, // 20% darker.
    };
  },

  computed: {
    getLabel() {
      if(!this.label.id)
        return this.label.name;
      else{
        const found = this.options.find(el => el.id === this.label.id);        
        return found ? found.name : this.label.name;
      }
    },

    getLabelColor() {
      if(this.label.id)
      {
        const found = this.options.find(el => el.id === this.label.id);        
        return (found && found.color) ? found.color.name : '';      
      }
      else
      return '';
    }
  },

  mounted() {
    document.addEventListener('click', this.close);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.close)
  },

  methods: {
    toggleDropdown(state) {
      this.open = state;
      this.$emit('toggle-dropdown', state);
    },

    close(e) {
      if (!this.$el.contains(e.target) && this.open) {
          this.toggleDropdown(false);
      }
    },

    startAddOption() {
      this.addingOption = true;
    },    

    async addOption() {
      let option = this.newOption;      

      option = option.trim();
      if(option && option !== '') {        
          this.$http.post(this.add.route,
            this.newOptionColor ? { name: option, color_id: this.newOptionColor.id } : { name: option }).then((response) => {
              this.$notify({ title: 'Success', text: this.add.success, type: 'success' })
          }).catch((e) => {
              console.log(e);
              this.$notify({ title: 'Error', text: this.add.error, type: 'error' })
          }).finally(() => {              
              this.$emit('done');
          });
      } else {
          this.$notify({ title: 'Warning', text: this.add.warning, type: 'warn' })
      }

      this.cancelAddOption();
    },

    cancelAddOption() {
      this.addingOption = false;
      this.newOption = '';
      this.newOptionColor = null;
    },

    async selectDefault() {
      this.open = false;
      this.$emit('default');
    },

    async selectOption(option) {
      if(this.select) {
        let optionId = option.id;
        const { data } = await this.$http.post(this.select.route, { optionId });
        if (data) {
          this.open = false;
          this.$notify({ title: 'Success', text: this.select.success, type: 'success' })
          this.$emit('done');
        }
      }
      else {
        this.open = false;
        this.$emit('select', option);
      }
    },

    startEditOption(option) {
      this.editingOption = true;
      this.changedOption = this.options.indexOf(option);
      option.editing = true;
    },

    async editOption() {
      const option = this.options[this.changedOption];
      if(!option || option.editName === '') return this.$notify({ title: 'Warning', text: this.edit.warning, type: 'warn' });
      try {
          const { data } = await this.$http.put(this.edit.route.concat(option.id),
            option.editColor ? { name: option.editName, color_id: option.editColor.id } : { name: option.editName });
          if (data) {
              this.$notify({ title: 'Success', text: this.edit.success, type: 'success' })
              if(this.edit.overrideEmit)
                this.$emit('edit', option);
              else
                this.$emit('done');
          }

      } catch (err) {
          console.log(err);
          this.$notify({ title: 'Error', text: this.edit.error, type: 'error' });
      }
      this.cancelEditOption();
    },

    cancelEditOption() {
      this.editingOption = false; 
      let option = this.options[this.changedOption];
      option.editing = false;
      option.editName = option.name;
      if(option.editColor)
        option.editColor = option.color;
    },

    startDeleteOption(option) {
      this.$swal.fire({
          title: `Are you sure you want to delete the '${option.name}' ${this.delete.deleteLabel}?`,
          text: "You won't be able to revert this!",
          icon: 'warning',
          iconColor: '#0E092C',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          reverseButtons: true,
      }).then((result) => {
          if (result.isConfirmed) {
              this.deleteOption(option);  
              this.cancelEditOption();            
          }
          else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.cancelEditOption();
          }
      });
    },

    async deleteOption(option) {
      try {
          const { data } = await this.$http.delete(this.delete.route.concat(option.id));
          if (data) {
            if (data.show_undo) {
              this.$store.dispatch('restorer/set', {
                message: this.delete.success,
                route: this.restore ? this.restore.route.concat(option.id) : null,
                success: this.restore ? this.restore.success : '',
                fail: this.restore ? this.restore.error : '',
                action: true,
                fn: () => {
                  if (this.delete.overrideEmit) {
                    this.$emit('delete', option);
                  } else {
                    this.$emit('done');
                  }
                }
              });
            } else {
              this.$notify({ title: 'Success', text: this.delete.success, type: 'success' });
              if (this.delete.overrideEmit) {
                this.$emit('delete', option);
              } else {
                this.$emit('done');
              }
            }
          }
      } catch (err) {
          console.log(err);
          this.$notify({ title: 'Error', text: this.delete.error, type: 'error' });
      }
    },

    hexToRgbSum(hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if(result){
          let r = parseInt(result[1], 16);
          let g = parseInt(result[2], 16);
          let b = parseInt(result[3], 16);          
          return r+g+b;
          // 0 is black, 765 is white.
      } 
      else
        return 0;
    },

    hexToModRgb(hex, mod) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if(result){
          let r = parseInt(result[1], 16);
          let g = parseInt(result[2], 16);
          let b = parseInt(result[3], 16);                    
          return `rgba(${r+mod}, ${g+mod}, ${b+mod})`;
      } 
      else
        return '';
    }

  }
};
</script>

<style lang="scss">
.select-wrapper {
  .select-menu {
    max-height: 25em !important;
    overflow-y: auto;
  }
}
</style>
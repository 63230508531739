<template>
<div>

    <tab :visible="step === 1">
         
        <div class="title">Instagram boosted posts</div>

        <div v-if="invalidAccessToken" class="warning my-4" role="alert">
            <p>You dont have the right permissions to get information about the company's instagram users.</p>
        </div>

        <div class="list">
            <base-icon name="loader" v-if="loading" />

            <div v-if="!instagramAccounts(user).length">User has no instagram account linked.</div>
            
            <div v-for="(account, index) in instagramAccounts(user)" :key="index" class="item">
                <div class="header">
                    <base-icon name="instagram"/>
                    <div class="handle">{{ account.handle }}</div>
                </div>
                
                <div class="middle">
                    <div class="connection-status">
                        <div class="dot" :class="{ 'connected': isConnected(account.handle) }" />
                        <div>{{ isConnected(account.handle) ? 'Connected' : 'Not connected' }}</div>
                    </div>

                    <div v-if="!!requestAlreadySent(account.handle)">
                        <div class="flex gap-x-4">
                            <div>Request already sent</div>
                            <base-icon name="check" />
                        </div>
                    </div>
                </div>

                <base-button
                    v-if="isConnected(account.handle)"
                    label="View permissions"
                    :disabled="invalidAccessToken"
                    size="lg"
                    @action="businessManagerRedirect(account)"
                />

                <base-button
                    v-else
                    label="Invite to connect"
                    size="lg"
                    @action="selectedInstagramAccount = account.handle; step++"
                />

            </div>
        </div>
    </tab>

    <tab :visible="step === 2">
        <div class="font-bold mb-4">
            ¿Invite {{ selectedInstagramAccount }} to connect their instagram account for boosted posts?
        </div>
        <div>
            An invite link will be created, with a step by step on how to connect their instagram account to this business (ID: {{this.company.fb_business_id}}) and grant the permitted tasks that you want. You can copy it or send it by email.
        </div>
    </tab>

    <tab :visible="step === 3">
        <div class="flex flex-col gap-y-4 mb-16">

            <!-- permitted tasks -->
            <div>
                <div class="font-bold">Permitted tasks</div>
                <div class="permitted-tasks-list">
                    <div v-for="({ name, slug, description }, index) in permittedTasks" :key="index" class="task">
                        <toggle-button 
                            :value="selectedPermittedTasks.includes(slug)"  
                            @toggled="val => onTogglePermittedTask(val, slug)" 
                        />
                        <div class="right">
                            <div class="name">{{ name }}</div>
                            <div class="description">{{ description }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- notifications -->
            <div>   
                <div class="font-bold">Notifications</div>
                <FormulateInput
                    type="checkbox"
                    v-model="notifyCreator"
                    label="Notify creator through creatorsaurus"
                    class="w-full mt-2"
                />
            </div>
        </div>
    
    </tab>

    <tab :visible="step === 4">

        <div class="font-bold">Share request link</div>

        <div class="flex justify-between gap-x-5 my-5">
            <FormulateInput :value="url" readonly class="w-full" />
            <base-button label="Copy" @action="$copyToClipboard($notify, url);" :disabled="loading || requestToken == ''" size="sm" />
        </div>

        <FormulateInput
            v-if="user"
            :readonly="user.email && user.email !== ''"
            v-model="user.email"
            class="w-full mt-2"
            label="Share by email"
        />

        <FormulateInput
            type="textarea"
            class="w-full mt-2"
            placeholder="Enter message..."
            v-model="emailMessage"
            input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-32"
            :rows="1"
        />

    </tab>

    <div class="footer">

        <base-button
            v-if="step === 1 || step === 2 || step === 3"
            type="secondary"
            icon="arrow-left"
            @action="step == 1 ? $emit('back') : step--"
            :disabled="loading"
            label="Back"
        />

        <base-button
            v-if="step === 2"
            secondary-icon="arrow-right"
            @action="step++"
            :disabled="loading"
            label="Next"
        />

        <base-button
            v-if="step === 3"
            secondary-icon="arrow-right"
            @action="createIgConnectionRequest()"
            :disabled="loading"
            label="Create"
        />

        <base-button
            v-if="step === 4"
            type="secondary"
            icon="close"
            label="Close"
            :disabled="loading"
            @action="$emit('close')"
        />

        <base-button
            v-if="step === 4"
            icon="mail"
            label="Send email"
            size="lg"
            :disabled="loading"
            @action="sendEmail()"
        />

    </div>


</div>
</template>
<script>
import { instagramAccounts } from '../../../lib/user'
import { mapGetters } from 'vuex';
import { getObjectValue, notifyCatchError } from '../../../common';

export default {
    props: ['user'],
    data(){
        return {
            instagramAccounts,
            step: 1,
            businessInstagramAccounts: [],
            selectedInstagramAccount: null,
            permittedTasks: [
                { 'name': 'Content', slug: 'content', 'description': 'Create, manage or delete posts, stories and more as the Page.' },
                { 'name': 'Community activity', slug: 'community-activity', 'description': 'Review and respond to comments, remove unwanted content and report activity.' },
                { 'name': 'Messages', slug: 'messages', 'description': 'Send and respond to messages as the Page.' },
                { 'name': 'Ads', slug: 'ads', 'description': 'Create, manage and delete ads for the Page.' },
                { 'name': 'Insights', slug: 'insights', 'description': 'See how the Page, content and ads perform.' }
            ],
            igConnectionRequests: [],
            selectedPermittedTasks: ['ads', 'insights'], // defaults
            notifyCreator: false,
            requestToken: '',
            emailMessage: '',
            loading: false,
            invalidAccessToken: false // the admin's access token does not have the right permissions to get the business instagram accounts
        }
    },
    mounted(){
        this.getBusinessInstagramAccounts()
        this.getIgConnectionRequests();
    },
    computed: {
        ...mapGetters(['company']),
        url(){
            return `${window.location.origin}/ig-connection-wizard?t=${this.requestToken}`
        }
    },
    methods: {
        getBusinessInstagramAccounts(){
            this.loading = true
            this.$http.get('/api/facebook/business-ig-accounts').then(({ data }) => {
                if(data){
                    this.businessInstagramAccounts = data.map(element => element.username)
                }
            }).catch((err) => {
                const error = getObjectValue(err, 'response.data.error')
                if(error && error == 'permissions-error'){
                    this.invalidAccessToken = true
                }else{
                    console.log('getBusinessInstagramAccounts error', err)
                }
            }).finally(() => {
                this.loading = false
            })
        },
        getIgConnectionRequests(){
            this.loading = true
            this.$http.get(`/api/ig-connection-request?user_id=${this.user.id}`).then(({ data }) => {
                if(data){
                    this.igConnectionRequests = data
                }
            }).catch((err) => {
                console.log('getIgConnectionRequests error', err)
            }).finally(() => {
                this.loading = false
            })
        },
        requestAlreadySent(handle){
            const found = this.igConnectionRequests.find(element => element.data?.instagram_account == handle)
            return found
        },
        // if business has access to the account
        isConnected(handle){
            return this.businessInstagramAccounts.includes(handle)
        },
        onTogglePermittedTask(val, slug){
            if(val) return this.selectedPermittedTasks.push(slug)
            this.selectedPermittedTasks = this.selectedPermittedTasks.filter(element => element !== slug)
        },
        createIgConnectionRequest(){
            this.loading = true
            this.$http.post('/api/ig-connection-request', {
                user_id: this.user.id,
                instagram_account: this.selectedInstagramAccount,
                permitted_tasks: this.selectedPermittedTasks,
                notify_creator: this.notifyCreator
            }).then(({ data }) => {
                if(data){
                    this.$notify({ title: 'Link created successfully', type: 'success' });
                    this.requestToken = data.token 
                    this.step++
                }
            }).catch((err) => {
                notifyCatchError(err, this.$notify)
            }).finally(() => {
                this.loading = false
            })
        },
        sendEmail(){
            this.loading = true
            this.$http.post('/api/ig-connection-request/email', {
                email: this.user.email,
                url: this.url,
                instagram_account: this.selectedInstagramAccount,
                message: this.emailMessage
            }).then(({ data }) => {
                if(data){
                    this.$notify({ title: 'Email sent successfully', type: 'success' });
                }
            }).catch((err) => {
                notifyCatchError(err, this.$notify)
            }).finally(() => {
                this.loading = false
            })
        },
        businessManagerRedirect(account){
            window.open(`https://business.facebook.com/settings/instagram-account-v2s/${account.id}?business_id=${this.company.fb_business_id}`, '_blank')
        }
    }
}
</script>
<style lang="scss" scoped>
    .title{
        @apply text-h5 font-bold mb-4;
    }
    .list{
        @apply flex flex-col gap-y-4;
        > .item{
            @apply relative px-6 py-6 bg-white rounded-xl;
            @apply flex justify-between items-center;
            > .header{
                @apply flex items-center gap-x-2;
                > .handle{
                    @apply font-bold;
                }
            }
            > .middle{
                > .connection-status{
                    @apply flex items-center gap-x-2 cursor-pointer;
                    > .dot{
                        width: 10px;
                        height: 10px;
                        border-radius: 100%;
                        background-color: yellow;
                        &.connected{
                            background-color: green;
                        }
                    }
                }
            }
            
        }
    }
    .permitted-tasks-list{
        @apply flex flex-col gap-x-2 mt-4;
        > .task{
            @apply flex items-center gap-x-4;
            > .left{

            }
            > .right{
                @apply flex flex-col justify-center;
                .name{
                    @apply font-bold;
                }
                .description{
                    @apply text-sm;
                }
            }
        }
    }
    .footer{
        @apply flex justify-between mt-10;
    }
    .warning{
        @apply bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4;
    }
</style>
<template>
    <div class="container">
        <div class="item gap">
            <div class="title">
                Existing creators
            </div>
            <p class="subtitle">What would you like to do to the existing creators data ?</p>
            <FormulateInput
                name="type"
                :options="existingDataOptions"
                :value="selectedExistingDataOption"
                @input="val => $emit('update:selected-existing-data-option', val)"
                help-position="after"
                type="radio"  
            />
        </div>

        <div class="item gap">
            <div class="title">
                Social connection invitations
            </div>
            <p class="subtitle">Send email invitations to all creators so they can authenticate with the social platforms and Creatorsaurus can automatically retrieve their posts and profile data ?</p>
            <FormulateInput
                type="radio"
                :options="socialInviteOptions"
                :value="selectedSocialInviteOption"
                @input="val => $emit('update:selected-social-invite-option', val)"
            />
        </div>
    </div>
</template>
<script>
const existingDataOptions = [
    { value: 'keep', label: 'Keep existing data' },
    { value: 'replace', label: 'Replace existing data' }
];
const socialInviteOptions = [
    { value: 'false', label: "Don't send invitations" },
    { value: 'true', label: 'Send invitations' }
];
export default { 
    props: [
        'selectedExistingDataOption',
        'selectedSocialInviteOption'
    ],
    data(){
        return {
            existingDataOptions,
            socialInviteOptions
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply py-6 flex flex-col gap-16;
        .item{
            &.gap{
                @apply flex flex-col gap-1;
            }
            .title{
                @apply text-h3 font-bold text-purple-m-secondary;
            }
            .subtitle{
                @apply text-h6 font-bold text-purple-m-secondary mb-1;
            }
        }
    }
</style>
<template>
<div class="rating" :class="{ 'own-rating': wasCreatedByYou }">

    <div v-if="wasCreatedByYou" class="options-container">
        <options-dropdown                       
            :options="[                                              
                {label: 'Edit', icon: 'pencil-alt', event:'edit'},
                {label: 'Delete', icon: 'trash', event:'delete'}
            ]"
            @edit="$emit('edit')"
            @delete="$emit('delete')"
        />
    </div>

    <div class="content">

        <!-- left -->
        <div class="left">
            <avatar :user="rating.created_by_user"/>
        </div>

        <!-- right -->
        <div class="right">
            <div class="header">
                <div class="user-name" v-if="rating.created_by_user">{{`${rating.created_by_user.first_name} ${rating.created_by_user.last_name}`}}</div>
                <star-rating
                    v-model="rating.value" 
                    :read-only="true" 
                    :increment="0.5"
                    :star-size="18"
                    text-class="font-bold leading-4"
                />
            </div>
            <div v-if="rating.project">
                <span class="font-bold">Project: {{ rating.project.name }}</span>
            </div>
            <div class="text">
                {{ rating.comment }}
                <span v-if="rating.created_at !== rating.updated_at">(Edited)</span>
            </div>
            <div class="date">{{ rating.created_at | moment('MMM D, Y') }}</div>
        </div>
        
    </div>
</div>
</template>

<script>
import StarRating from 'vue-star-rating';
import { mapGetters } from 'vuex';
export default {
    props: ['rating'],
    components: {
        StarRating
    },
    computed: {
        ...mapGetters(['me']),
        wasCreatedByYou(){
            return this.rating.created_by == this.me.id
        }
    }
}
</script>
<style lang="scss" scoped>
    .rating{
        @apply relative rounded-lg bg-white px-4 py-4;
        width: 500px;
        &.own-rating{
            @apply bg-tan-m;
        }
        .options-container{
            @apply absolute origin-top-right top-3 right-2 mt-0;
        }
        .content{
            @apply flex gap-4 items-start;
            >.left{
                @apply flex-grow-0 flex-shrink-0;
            }
            >.right{
                @apply flex flex-col items-start justify-end gap-1;
                @apply flex-grow flex-shrink;
                >.header{
                    @apply flex gap-x-2;
                    >.user-name{
                        @apply font-bold;
                    }
                }
                >.text{
                    @apply text-pm text-purple-m-secondary;
                    >span{
                        @apply text-pxs font-normal text-gray-m-disable;
                    }
                }
                >.date{
                    @apply text-pxs text-gray-m-disable;
                }
            }
            >.edit{
                @apply flex flex-col w-full items-start justify-end gap-4;
                >.actions-wrapper{
                    @apply w-full flex justify-end gap-2;
                }
            }
        }
    }
</style>

<template>
    <base-modal
        :visible="visible"
        @close="$emit('close')"
        title="Select a sheet"
        styles="max-height: 90vh; overflow-y: auto;"
    >
        <div>
            <base-icon v-if="loading" name="loader" />
            <div v-else>
                <div v-if="!sheets.length">No sheets found</div>
                
                <template v-else>

                    <div class="list-header">
                        <!-- <div>{{selected.length}} selected</div> -->
                        <!-- <base-button
                            v-if="allSelected"
                            size="md"
                            @action="selected = []"
                            label="Deselect all"
                        />
                        <base-button
                            v-else
                            size="md"
                            @action="selected = sheets"
                            label="Select all"
                        /> -->
                    </div>
                    <div class="sheet-list">
                        <div
                            v-for="(sheet, index) in sheets" 
                            :key="index"
                            class="option"
                            :class="{ 'active': active(sheet) }"
                            @click="toggle(sheet)"
                        >
                            {{sheet}}
                        </div>
                        
                    </div>

                </template>
            </div>
        </div>

        <div class="footer">
            <base-button
                @action="$emit('close', 'cancel')" 
                type="secondary" 
                size="md"
                label="Cancel"
            />
            <base-button
                :disabled="!sheets.length || !selected.length"
                @action="submit"  
                size="md" 
                :icon-size="4" 
                label="Done" 
                bold
            />
        </div>
    </base-modal>
</template>

<script>
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            file: {
               type: File,
               required: false
            }
        },
        watch: {
            visible(val, old){
                if(val != old && val == true){
                    this.sheets = []
                    this.selected = []
                    this.getSheetNames()
                }
            }
        },
        data() {
            return {
                loading: false,
                sheets: [],
                selected: []
            }
        },
        computed: {
            allSelected(){
                return this.selected.length == this.sheets.length
            }
        },
        methods: {
            async getSheetNames() {
                let fileData = new FormData()
                fileData.append('file', this.file)
                this.loading = true
                this.$http.post(`/api/upload-file/import/file/sheets`, fileData).then(async ({data}) => {
                    this.sheets = data.sheets;
                    this.path = data.path;
                }).catch((err) => {
                    console.log('got sheets error', err.response)
                    const text = err.response.data?.message ? err.response.data.message : 'Try again later'
                    this.$notify({ title: 'Something went wrong while uploading the file.', text: text, type: 'warn' })
                }).finally(() => {
                    this.loading = false;
                })
            },
            active(sheet){
                return this.selected.includes(sheet)
            },
            toggle(sheet){
                if(this.active(sheet)){
                    this.selected = this.selected.filter(element => element != sheet)
                } else{
                    this.selected = [];
                    this.selected.push(sheet)
                }
            },
            async submit(){
                this.$emit('done', this.selected, this.path)
                this.sheets = []
                this.selected = []
                this.$emit('close')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .list-header{
        @apply flex justify-between items-center mb-6;
    }
    .sheet-list{
        @apply flex flex-col gap-y-4;
        .option{
            @apply bg-white p-4 border border-black rounded-lg cursor-pointer transition-opacity;
            &:hover{
                @apply opacity-50;
            }
            &.active{
                @apply bg-purple-m-main text-white;
            }
        }
    }
    .footer{
        @apply flex justify-between gap-3 mt-6 rounded-br-2xl;
    }
</style> 
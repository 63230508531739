<template>
    <div v-if="render">
        <div v-show="show">
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        visible: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    watch: {
        visible(val){
            if(val === true){
                this.render = true
            }
            this.show = val
        }
    },
    beforeMount(){
        if(this.visible === true) {
            this.render = true
            this.show = true
        }
    },
    data() {
        return {
            render: null,
            show: false
        }
    }
}
</script>

<template>
    <div class="flex flex-col justify-between pr-12 py-10">
        <div class="text-h2 font-bold text-purple-m-secondary">
            {{userDeliverableStatus}}
        </div>
        <!-- <div v-if="isAdminOrHigher">
            <FormulateInput
                type="checkbox"
                v-model="manualCompleted"
                @change="setManualCompleted"
                input-class="cursor-pointer border-1 border-white"
                label-class="ml-3 cursor-pointer"
                label="Set deliverable as completed"
                class="w-full text-purple-m-secondary text-sm"
            />
        </div>    -->        
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            manualCompleted: false,
        }
    },

    props: {
        loading: {
            type: Boolean,
            default: false
        },
        userDeliverable: {
            type: Object,
            required: true
        }
    },

    beforeMount() {
        if (this.userDeliverable.is_completed || (this.userDeliverable.phases_progress && !(this.userDeliverable.project_user_deliverable_posts.length < this.userDeliverable.posts_needed))) {
            this.manualCompleted = true;
        }
    },

    computed: {
        ...mapGetters(['me', 'isAdminOrHigher', 'isGuest', 'isClient']),

        allowEditing(){
            return this.userDeliverable.project_user.project.share_info?.allow_editing
        },

        guestOrHigherPermission() {
            return this.guestPermission || !this.isGuest;
        },

        // allow editing project permissions
        guestPermission(){
            let { share_info } = this.userDeliverable.project_user.project;
            const allow_editing = share_info && !!share_info.allow_editing;
            return this.isGuest && allow_editing
        },

        checkPermission() {
            return this.isProjectOwner || this.isAdminOrHigher || this.allowEditing
        },

        isProjectOwner() {
            let projectOwners = this.userDeliverable.project_user && this.userDeliverable.project_user.project ? this.userDeliverable.project_user.project.project_owners : []
            return !!(projectOwners && projectOwners.find(element => element.user.id === this.me.id))
        },

        project() {
            if (Object.keys(this.userDeliverable).length){
                return this.userDeliverable.project_user && this.userDeliverable.project_user.project ? this.userDeliverable.project_user.project : {};
            }
            return {};
        },

        userDeliverableStatus() {
            if (this.loading) {
                return 'Getting status';
            }
            
            if(Object.keys(this.userDeliverable).length){
                if (this.userDeliverable.is_completed) {
                    return 'Completed';
                } else {
                    switch(this.userDeliverable.phases_progress) {
                        case 0:
                            return 'Waiting for Concept Approval';
                        case 10:
                            return 'Waiting for Draft Approval';
                        case 20:
                            if(this.userDeliverable.project_user_deliverable_posts.length < this.userDeliverable.posts_needed)
                                return 'Waiting for Linked Posts';                    
                            else
                                return 'Completed'
                        default:
                            return 'Default Phase';
                    }
                }
            }
            else{
                return 'Phases Progress'
            }            
        }
    },

    methods: {  
        async setManualCompleted() {
            let body = {
                is_completed: this.manualCompleted
            };
            try {
                await this.$http.post(`/api/userDeliverables/${this.$route.params.id}/toggleManualCompleted`, body);
                this.$notify({ title: 'Success', text: 'Status updated successfully.', type: 'success' });
                this.$emit('done', false);
            } catch (e) {
                console.log(e);
            }
        } 
    }
}
</script>
<template>
<main class="project-settings flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="px-12 py-10">
        <base-table
            :columns="[                
                {
                    name: 'avatar',
                    label: 'Member',
                    type: 'avatar-list',
                    value: (user) => {
                        return [user]
                    },
                    searchBy: nameSearchBy
                },
                {
                    name: 'email',
                    label: 'Email'
                },
                {
                    name: 'roles',
                    label: 'Role',
                    type: 'single-select',
                    options: roles,
                    style: 'pill',
                    disabled: !isAdminOrHigher,
                    disabledTooltip: 'Only admins can change roles',
                    searchBy: rolesSearchBy,
                }
            ]"
            :rowActions="[
                ...( isAdminOrHigher ? [
                    {
                        label: 'Edit',
                        icon: 'pencil-alt',
                        event: 'edit',
                        handler: (user) => $router.push(`/edit-user/${user.id}`)
                    },
                    {
                        label: 'Remove from company',
                        icon: 'trash',
                        event: 'remove',
                        handler: removeUserFromCompanyIntent
                    },
                    // disabled because user deletion is not thouroughly tested
                    // also this option should only be available for super admins
                    /* {
                        label: 'Delete',
                        icon: 'trash',
                        theme: 'cancel',
                        event: 'delete',
                        handler: deleteUserIntent
                    } */
                ] : [])
            ]"
            :key="tableKey"
            :data="users"
            :loading="loading"
            @refresh-data="getTeamMembers()"
            @change-single-select="changeSingleSelect"
            container-styles="min-height: calc(100vh - 300px);max-height: calc(100vh - 300px);overflow-y: auto;overflow-x: hidden;margin-right: -20px;"
            sticky-header
        >
            <template v-slot:filters-footer>
                <div class="flex justify-end items-center my-2 py-4 pt-2 rounded-3xl">
                    <base-button
                        v-if="isAdminOrHigher"
                        @action="showInviteMembersModal = true"                        
                        class="font-bold"
                        size="auto"
                        :iconSize="4"
                        theme="dark"
                        type="secondary"
                        label="Add Team Member" 
                        icon="plus"
                    />
                </div>
            </template>
        </base-table>
    </div>

    <edit-user-roles-modal
        :visible="showEditUserRolesModal"
        :user="selectedUser"
        @close="showEditUserRolesModal = false"
        @done="onEditUserRolesDone()"
    />

    <invite-members-modal
        :visible="showInviteMembersModal"
        :key="showInviteMembersModal"
        @close="showInviteMembersModal = false"
        @done="showInviteMembersModal = false"
    />

    <base-modal
        :visible="selectedUser !== null & selectedRole !== null"
        :title="`Are you sure you want to change the ${selectedUser ? selectedUser.first_name : ''} ${selectedUser ? selectedUser.last_name : ''} role`"
        @close="cancelChange"
    >
        <div class="flex flex-col gap-4">
            <p v-if="selectedRole !== null">The new role will be "{{ selectedRole.display_name }}"</p>
            <div class="flex justify-between w-full">
                <base-button @action="cancelChange" type="secondary" size="md" bold label="Cancel"/>
                <base-button @action="acceptChange" size="md" label="Confirm" bold/>
            </div>
        </div>
    </base-modal>
</main>
</template>

<script>
import InviteMembersModal from './InviteMembersModal.vue'
import EditUserRolesModal from './EditUserRolesModal'
import Navbar from '../../components/Navbar'
import { mapGetters } from 'vuex';
import { notifyCatchError } from '../../common';

export default {
    components: {
        InviteMembersModal,
        EditUserRolesModal,
        Navbar,
    },
    data() {
        return {
            users: [],
            allRoles: null,
            roles: [],
            loading: false,
            tableKey: false,
            selectedUser: null,
            selectedRole: null,
            runFn: null,
            showEditUserRolesModal: false,
            showInviteMembersModal: false,
        }
    },

    beforeMount() {
        this.$setTitle('Team Settings');
        this.getRoles();
        this.getTeamMembers()
    },

    computed: {
        ...mapGetters(['isAdminOrHigher']),
    },

    methods: {
        async changeSingleSelect(element, column, val) {
            switch(column.name) {
                case 'roles': {
                    let current = element.roles[0];
                    if (current.id !== val.id) {
                        this.selectedUser = element;
                        this.selectedRole = val;
                        this.runFn = async () => {
                            try {
                                const {data} = await this.$http.put(`/api/users/${this.selectedUser.id}/roles`, {roles: [this.selectedRole.id]});
                                if (data) {
                                    this.getTeamMembers(false);
                                    this.cancelChange(false);
                                    this.$notify({ title: 'Success', text: 'The role has been updated', type: 'success' });
                                } else {
                                    this.$notify({ title: 'Error', text: 'Something went wrong', type: 'error' })
                                }
                            } catch (e) {
                                this.$notify({ title: 'Error', text: e, type: 'error' })
                            }
                        }
                    }
                    break;
                }
            }
        },

        cancelChange(reload = true) {
            this.selectedUser = null;
            this.selectedRole = null;
            this.runFn = null;
            if (reload) {
                this.tableKey = !this.tableKey;
            }
        },

        acceptChange() {
            if (this.runFn !== null) {
                this.runFn();
            }
        },

        async getRoles(){
            const { data } = await this.$http.get('/api/roles');
            const excludedRoles = ['creator', 'agent']
            this.roles = data.filter(role => !excludedRoles.includes(role.name)).map(role => {
                role.label = role.display_name;
                return role;
            });
        },

        async getTeamMembers(loading = true) {
            
            this.loading = loading;

            try {

                const { data } = await this.$http.get('/api/users');
                
                this.users = data.map(user => {
                    user.roles = user.roles.map(role => {
                        role.label = role.display_name;
                        return role;
                    });
                    return user;
                });
                
                /** Extract roles to show in select */
                let a = [];
                for (let i in data) {
                    for (let j in data[i].roles) {
                        let label = data[i].roles[j].display_name;
                        let value = data[i].roles[j].display_name;
                        if (!a.find(elem => elem.value === value)) {
                            a.push({ value, label });
                        }
                    }
                }

                this.allRoles = a;

            } catch (error) {
                if ( error.response.status >= 400 ) {
                    // Handle the 400+ error
                    this.$notify({ title: 'Error', text: error.response.data.message, type: 'error' });
                    this.$router.push("/home");

                } else {
                    // Handle other errors
                    console.error(error);
                }
            }

            this.loading = false
        },
        removeUserFromCompanyIntent(user){
            this.$swal.fire({
                title: `Are you sure you want to remove ${user.first_name} ${user.last_name} from the company?`,
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Remove',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.removeUserFromCompany(user)
                }
            }); 
        },
        async removeUserFromCompany(user){
            try {
                const { data } = await this.$http.delete(`/api/companies/user/${user.id}`);
                if (data) {
                    this.$notify({ title: 'Success', text: 'User removed from company', type: 'success' });
                    this.getTeamMembers();
                }
            } catch (err) {
                notifyCatchError(err, this.$notify);
            }
        },
        deleteUserIntent(user){
            this.$swal.fire({
                title: `Are you sure you want to delete ${user.first_name} ${user.last_name} ?`,
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteUser(user)
                }
            });
        },
        async deleteUser(user){
            try {
                const { data } = await this.$http.delete(`/api/users/${user.id}`);
                if (data) {
                    this.$store.dispatch('restorer/set', {
                        message: 'User deleted successfully',
                        success: 'User restored successfully',
                        fail: 'User cannot be restored',
                        route: `/api/users/${user.id}/restore`,
                        action: data.show_undo,
                        forceFn: true,
                        fn: () => {
                            this.getTeamMembers()
                        }
                    });
                }
            } catch (err) {
                console.log(err);
                this.$notify({ title: 'Error', text: 'Something went wrong', type: 'error' })
            }
        },
        handleManageRolesClick(user){
            this.selectedUser = user
            this.showEditUserRolesModal = true
        },
        async onEditUserRolesDone(){
            this.showEditUserRolesModal = false
            this.getTeamMembers()
        },
        rolesSearchBy(element){
            return element.roles.map(role => role.display_name).toString().toLowerCase()
        },
        nameSearchBy(element) {
            return (element.first_name + ' ' + element.last_name).toLowerCase();
        }
    }
}
</script>

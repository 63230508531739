var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"project-settings flex-1 relative z-0 overflow-y-auto focus:outline-none",attrs:{"tabindex":"0"}},[_c('div',{staticClass:"px-12 py-10"},[_c('base-table',{key:_vm.tableKey,attrs:{"columns":[                
                {
                    name: 'avatar',
                    label: 'Member',
                    type: 'avatar-list',
                    value: function (user) {
                        return [user]
                    },
                    searchBy: _vm.nameSearchBy
                },
                {
                    name: 'email',
                    label: 'Email'
                },
                {
                    name: 'roles',
                    label: 'Role',
                    type: 'single-select',
                    options: _vm.roles,
                    style: 'pill',
                    disabled: !_vm.isAdminOrHigher,
                    disabledTooltip: 'Only admins can change roles',
                    searchBy: _vm.rolesSearchBy,
                }
            ],"rowActions":[].concat( ( _vm.isAdminOrHigher ? [
                    {
                        label: 'Edit',
                        icon: 'pencil-alt',
                        event: 'edit',
                        handler: function (user) { return _vm.$router.push(("/edit-user/" + (user.id))); }
                    },
                    {
                        label: 'Remove from company',
                        icon: 'trash',
                        event: 'remove',
                        handler: _vm.removeUserFromCompanyIntent
                    } ] : []) ),"data":_vm.users,"loading":_vm.loading,"container-styles":"min-height: calc(100vh - 300px);max-height: calc(100vh - 300px);overflow-y: auto;overflow-x: hidden;margin-right: -20px;","sticky-header":""},on:{"refresh-data":function($event){return _vm.getTeamMembers()},"change-single-select":_vm.changeSingleSelect},scopedSlots:_vm._u([{key:"filters-footer",fn:function(){return [_c('div',{staticClass:"flex justify-end items-center my-2 py-4 pt-2 rounded-3xl"},[(_vm.isAdminOrHigher)?_c('base-button',{staticClass:"font-bold",attrs:{"size":"auto","iconSize":4,"theme":"dark","type":"secondary","label":"Add Team Member","icon":"plus"},on:{"action":function($event){_vm.showInviteMembersModal = true}}}):_vm._e()],1)]},proxy:true}])})],1),_c('edit-user-roles-modal',{attrs:{"visible":_vm.showEditUserRolesModal,"user":_vm.selectedUser},on:{"close":function($event){_vm.showEditUserRolesModal = false},"done":function($event){return _vm.onEditUserRolesDone()}}}),_c('invite-members-modal',{key:_vm.showInviteMembersModal,attrs:{"visible":_vm.showInviteMembersModal},on:{"close":function($event){_vm.showInviteMembersModal = false},"done":function($event){_vm.showInviteMembersModal = false}}}),_c('base-modal',{attrs:{"visible":_vm.selectedUser !== null & _vm.selectedRole !== null,"title":("Are you sure you want to change the " + (_vm.selectedUser ? _vm.selectedUser.first_name : '') + " " + (_vm.selectedUser ? _vm.selectedUser.last_name : '') + " role")},on:{"close":_vm.cancelChange}},[_c('div',{staticClass:"flex flex-col gap-4"},[(_vm.selectedRole !== null)?_c('p',[_vm._v("The new role will be \""+_vm._s(_vm.selectedRole.display_name)+"\"")]):_vm._e(),_c('div',{staticClass:"flex justify-between w-full"},[_c('base-button',{attrs:{"type":"secondary","size":"md","bold":"","label":"Cancel"},on:{"action":_vm.cancelChange}}),_c('base-button',{attrs:{"size":"md","label":"Confirm","bold":""},on:{"action":_vm.acceptChange}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
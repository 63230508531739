<template>
    <base-modal 
        :title="`Invite this creator to complete their profile?`"
        :visible="visible"
        @close="$emit('close')"
        no-padding
    >
        <div class="pt-3 px-14 pb-20">

            <div>

                <div class="flex justify-between gap-x-5 my-5">
                    <FormulateInput 
                        :value="url" 
                        readonly
                        class="w-full" 
                    />
                    <base-button 
                        @action="copyToClipboard()" 
                        :disabled="loading"
                        size="sm" 
                        label="Copy"
                    />
                </div>

                <div class="mb-4 pb-4 border-b flex items-center gap-x-8">
                    You can send a notification to the creator.
                    <base-button
                        @action="submit('notification')" 
                        :disabled="loading"
                        size="lg" 
                        label="Send Notification"
                    />
                </div>

                <div class="">

                    <div>Or send an email to the following address:</div>

                    <FormulateInput
                        v-if="user"
                        v-model="email"
                        :readonly="!!user.email && user.email != ''"
                        class="w-full mt-2"
                        label="Invite with email"
                        placeholder="example@mail.com"
                        validation="email"
                    />

                    <FormulateInput
                        type="textarea"
                        class="w-full mt-2"
                        placeholder="Enter message..."
                        v-model="message"
                        input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-32"
                        :rows="1"
                    />
                </div>
            
            </div>
        </div>

        <div>
            <div class="flex justify-end gap-3 bg-white rounded-2xl py-3 px-14">
                <base-button 
                    @action="$emit('close')" 
                    :disabled="loading" 
                    type="secondary" 
                    size="md" 
                    bold 
                    label="Cancel"
                />
                <base-button 
                    @action="submit('email')" 
                    :disabled="loading" 
                    size="md" 
                    label="Send Email" 
                    bold
                />
                 
            </div>
        </div>
    </base-modal>
</template>

<script>
export default {
    name: 'SendInviteModal',
    props: {
        visible: { 
            type: Boolean, 
            default: false
        },
        user: {
            type: Object,
            default: function () {
                return {};
            }
        },
        profileCompletion: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            email: '',
            message: ''
        }
    },
    watch: {
        // reset values 
        visible: function (val, old) {
            if(val !== old){
                if(val){
                    this.email = this.user.email
                }else{
                    this.loading = false;
                    this.email = '';
                    this.message = '';
                }
            }
        }
    },
    computed: {
        url() {
            return `${window.location.origin}/me`
        }
    },
    methods: {
        copyToClipboard(){
            this.$copyToClipboard(this.$notify, this.url);
        },
        async submit(type) {
            this.loading = true;
            try{
                await this.$http.post(`/api/users/${this.user.id}/profile-completion-invite`, { 
                    type,
                    email: this.email,
                    message: this.message,
                    url: this.url,
                    percentage: this.profileCompletion ? this.profileCompletion.percentage : null
                });
                this.$notify({ title: 'Success', text: type + ' sent successfully', type: 'success' });
                this.$emit('close');
            } catch(error) {
                this.$notify({ title: 'Failed to send ' + type, text: `The ${type} was not sent. Try again later.`, type: 'warn' });
                console.log(error);
            } finally {
                this.loading = false;
            }
        }

    }
}
</script>
import { findActionNameByPostProperty } from './fbAdsActions';
import { isString, isArray, isNumber } from '../common';

// get boosted data for a property
export function getBoostedData(boostedDataObjects, post_property) {

    if(!boostedDataObjects || !isArray(boostedDataObjects)) return 0;
    if(!post_property || !isString(post_property)) return 0;

    // The boosted data object looks something like this: 
    // {
    //     "reach": "326362",
    //     "actions": [],
    //     "date_stop": "2024-04-28",
    //     "date_start": "2024-03-30",
    //     "impressions": "347786",
    //     "inline_post_engagement": "523"
    // }
    // 

    // The "post_property" that we are looking for could be inside the boostedData object or inside
    // the "actions" array, so we will handle both cases.
    
    // We call the postPropertyExistsInMappedObject function to see if the "post_property" exists in the 
    // boosted data object and not in the actions array
    const isInMappedObject = postPropertyExistsInMappedObject(post_property);

    let number = 0;

    // if its true, then it does exist in the object
    if(isInMappedObject){

        // get the object key
        const objectKey = findObjectKeyByPostProperty(post_property);
        for (const boostedDataObject of boostedDataObjects) {
            if(boostedDataObject.insights){
                const value = boostedDataObject.insights[objectKey];
                // boosted object values are string numbers
                if(isString(value)){
                    number += parseInt(value) || 0;
                }
            }
        }

    }else{

        // this will return the name of the action inside the boosted data object
        const actionName = findActionNameByPostProperty(post_property);
        if(!actionName) return null;
        for (const boostedDataObject of boostedDataObjects) {
            if(boostedDataObject.insights && boostedDataObject.insights.actions){
                const found = boostedDataObject.insights.actions.find(element => element.action_type == actionName)
                if(found){
                    const value = found.value;
                    // boosted object values are string numbers
                    if(isString(value)){
                        number += parseInt(value) || 0;
                    }
                }
            }
        }
    
    }

    return isNumber(number) ? number : 0;

}

// very similar to getBoostedData() but instead of returning a number 
// this returns true or false depending on whether its boosted or not 
export function isPostPropertyBoosted(boostedDataObjects, post_property){
    if(!boostedDataObjects || !isArray(boostedDataObjects)) return false;
    if(!post_property || !isString(post_property)) return false;
    const isInMappedObject = postPropertyExistsInMappedObject(post_property);
    if(isInMappedObject){
        // get the object key
        const objectKey = findObjectKeyByPostProperty(post_property);
        for (const boostedDataObject of boostedDataObjects) {
            if(boostedDataObject.insights){
                const value = boostedDataObject.insights[objectKey];
                // boosted object values are string numbers
                if(isString(value)){
                    return true;
                }
            }
        }
    }else{
        const actionName = findActionNameByPostProperty(post_property);
        if(!actionName) return false;
        for (const boostedDataObject of boostedDataObjects) {
            if(boostedDataObject.insights && boostedDataObject.insights.actions){
                const found = boostedDataObject.insights.actions.find(element => element.action_type == actionName)
                if(found){
                    return true;
                }
            }
        }
    }
    return false;
}

// also very similar to the two above functions but instead of returning a number or a boolean
// it returns an array of all the ads that have that post_property
export function getPostPropertyAds(boostedDataObjects, post_property){
    if(!boostedDataObjects || !isArray(boostedDataObjects)) return [];
    if(!post_property || !isString(post_property)) return [];
    let ads = [];
    const isInMappedObject = postPropertyExistsInMappedObject(post_property);
    if(isInMappedObject){
        // get the object key
        const objectKey = findObjectKeyByPostProperty(post_property);
        for (const boostedDataObject of boostedDataObjects) {
            if(boostedDataObject.insights){
                const value = boostedDataObject.insights[objectKey];
                // boosted object values are string numbers
                if(isString(value)){
                    ads.push(boostedDataObject);
                }
            }
        }
    }else{
        const actionName = findActionNameByPostProperty(post_property);
        if(!actionName) return [];
        for (const boostedDataObject of boostedDataObjects) {
            if(boostedDataObject.insights && boostedDataObject.insights.actions){
                const found = boostedDataObject.insights.actions.find(element => element.action_type == actionName)
                if(found){
                    ads.push(boostedDataObject);
                }
            }
        }
    }
    return ads;
}

// given a post and a post_property, generate the boosted stats breakdown
// it will get the post's organic stats and boosted stats and generate an object
// it will also show the property, total and adCount 
export function generateBreakdown(post, post_property){
    const organic = post[post_property];
    const boosted = getBoostedData(post.boosted_data, post_property);
    const total = organic + boosted;
    return { property: post_property, organic, boosted, total, adCount: getPostPropertyAds(post.boosted_data, post_property).length };
}

// checks if the post property exists in the boosted data object
// this helps to check if its on the object or the actions array
// returns true or false
function postPropertyExistsInMappedObject(post_property){
    const properties = getMappedProperties();
    const found = properties.find(element => element.post_property == post_property);
    if(found && found.name){
        return true;
    }
    return false;
}

// find the fb action name by providing a "post_property" like "views", "shares", "likes" etc...
// returns the action name (ex. "onsite_conversion.post_save")
function findObjectKeyByPostProperty(post_property){
    const properties = getMappedProperties();
    const found = properties.find(element => element.post_property == post_property);
    if(found && found.name){
        return found.name;
    }
    return null;
}

// by default we only return the properties that have the "post_property" set
function getMappedProperties(filtered = true){
    return filtered ? mappedProperties.filter(element => element.post_property) : mappedProperties;
}

// mapped boosted data object so we can link it to our posts stats
// if we want to link a post stat we just add the property "post_property"
const mappedProperties = [
    {
        "name": "reach"
    },
    {
        "name": "date_stop"
    },
    {
        "name": "date_start"
    },
    {
        "name": "impressions",
        "post_property": "views"
    },
    {
        "name": "inline_post_engagement"
    }
];
<template>

    <button
        @mouseenter="hovered = true"
        @mouseleave="hovered = false"
        @click.prevent="action()"
        :type="submit ? 'submit' : 'button'"
        :class="['button', `justify-${justify}`, 'items-center gap-3', `rounded-${rounded}`, textClass, 'font-base border focus:outline-none transition duration-150 ease-in-out', display,
                {'font-bold': bold},
                {'w-12': size === 'xs'}, {'w-24': size === 'sm'}, {'w-36': size === 'md'}, {'w-44': size === 'lg'}, {'w-60': size === 'xl'}, {'w-auto': needsAutoWidth},                
                `${type !== 'label' ? padding : ''}`,
                {'border-transparent': type !== 'secondary' || !border},
                {'focus:shadow-outline-gray': type !== 'label' && !disabled && outline},
                {'cursor-not-allowed': disabled},                

                {'text-purple-m-main': type === 'main' && !disabled && theme === 'light'},
                {'bg-green-m-main hover:bg-green-m-main-dark focus:bg-green-m-dark': type === 'main' && !disabled && theme === 'light'},
                {'text-white': type === 'main' && !disabled && theme === 'dark'},
                {'bg-purple-m-main hover:opacity-90 focus:opacity-90': type === 'main' && !disabled && theme === 'dark'},
                {'bg-purple-m-secondary hover:bg-purple-m-secondary-light focus:bg-purple-m-main-light': type === 'main' && !disabled && theme === 'dark-mode'},
                {'text-gray-m-dark bg-gray-m': type === 'main' && disabled},
                {'bg-tan-m hover:bg-tan-m-hover': type === 'main' && theme === 'back'},
                {'bg-red-600 hover:bg-red-800 text-white': type === 'main' && theme === 'danger'},

                {'text-purple-m-main bg-white': type === 'secondary' && !disabled && !isWhite},
                {'border-transparent' : type === 'secondary' && !disabled && border && theme === 'light'},
                {'border-gray-m' : type === 'secondary' && !disabled && border && theme === 'dark'},
                {'hover:bg-green-m-main-dark focus:bg-green-m-main-dark': type === 'secondary' && !disabled && theme === 'light'},
                {'hover:bg-gray-m-light focus:bg-gray-m-light': type === 'secondary' && !disabled && theme === 'dark'},
                {'text-gray-m border-gray-m': type === 'secondary' && disabled},

                {'text-gray-m-dark bg-white': isWhite},
                
                

                {'text-purple-m-main hover:text-purple-m-secondary focus:text-purple-m-secondary': type === 'label' && !disabled && theme === 'light' && hover && !icon},
                {'text-purple-m-main': type === 'label' && !disabled && theme === 'light' && hover && icon},
                {'text-purple-m-main-light hover:text-purple-m-main focus:text-purple-m-main': type === 'label' && !disabled && theme === 'cancel' && hover},
                {'text-white hover:text-gray-m-light focus:text-gray-m-light': type === 'label' && !disabled && theme === 'dark' && hover && !icon},
                {'text-white': type === 'label' && !disabled && theme === 'dark' && hover && icon},

                {'text-purple-m-main': type === 'label' && !disabled && theme === 'light' && !hover},
                {'text-white': type === 'label' && !disabled && theme === 'dark' && !hover},
                {'text-gray-m': type === 'label' && disabled},
                {'text-purple-m-secondary': type === 'label' && !disabled && theme === 'neutral'},
                {'text-white': type === 'label' && theme === 'neutral-dark'}]">
        <base-icon v-if="icon && !img" :name="icon" :fill="iconFill" :size="computedIconSize"
            :class="[{'text-green-m-main-dark': type === 'label' && !disabled && theme === 'light' && hover && icon && hovered},
                    {'text-green-m-main': type === 'label' && !disabled && theme === 'dark' && hover && icon && (hovered || active)}]"
           :style="{color: iconColor ? iconColor : undefined}"
        />
        <img :src="img" v-if="img" :class="['border border-purple-m-main-light rounded-full',
                   {'w-7 h-7': size === 'sm'},
                   {'w-12 h-12': size !== 'sm'}
                   ]"/>
        <template v-if="label">
            <div class="truncate" v-if="label">{{ label }} <small v-if="labelCompletion">({{labelCompletion}})</small></div>
        </template>
        <template v-if="$slots.default">
            <!-- <div class="truncate"> -->
                <slot />
            <!-- </div> -->
        </template>
        <base-icon v-if="secondaryIcon" :name="secondaryIcon" :fill="iconFill" :size="computedIconSize" />
    </button>

</template>
<script>
    export default {
        props: {
            type: {
                type: String,
                default: 'main'
            },
            size: {
                type: String,
                default: 'md'
            },
            label: {
                type: String                
            },
            labelCompletion: {
                type: String
            },
            icon: {
                type: String
            },
            circle: {
                type: Boolean,
                default: false
            },
            textClass: {
                type: String,
                default: 'text-h6'
            },
            img: {
                type: String
            },
            secondaryIcon: {
                type: String
            },
            disabled: {
                type: Boolean,
                default: false
            },
            isWhite: {
                type: Boolean,
                default: false
            },
            display: {
                type: String,
                default: 'flex'
            },
            justify: {
                type: String,
                default: 'center'
            },
            rounded: {
                type: String,
                default: 'full'
            },
            iconColor: {
                type: String           
            },
            iconSize: {
                type: [String, Number],
                default: 5
            },
            theme: {
                type: String,
                default: 'light'
            },
            iconFill: {
                type: Boolean,
                default: true
            },
            border: {
                type: Boolean,
                default: true,
            },
            hover: {
                type: Boolean,
                default: true
            },
            link: {
                type: String,
                default: "",                
            },
            outline: {
                type: Boolean,
                default: true
            },
            submit: {
                type: Boolean,
                default: false
            },
            active: {
                type: Boolean,
                default: false
            },
            bold: {
                type: Boolean,
                default: false
            },
            padding: {
                type: String,
                default: 'px-4 py-4'
            }
        },

        data() {
            return {
                hovered: false,
            }
        },

        methods: {
            action() {
                if(!this.disabled && !this.link)
                    this.$emit('action')
                else if(!this.disables && this.link)
                    window.open(this.link, '_blank').focus();
            }
        },

        computed: {
            needsAutoWidth() {
                return this.size === 'auto' || this.circle || this.type === 'label';
            },
            computedIconSize(){
                const size = this.iconSize
                if(typeof size === 'string') return parseInt(size)
                return size
            }
        }
    }
</script>
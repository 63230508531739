<template>
    <div :class="{'container': container}">
        <div class="flex-grow flex-shrink w-full bg-white">

            <div class="video-container relative w-full">
                <media-viewer containerClasses="padding-0" :file="files[page]" :is-report="isReport" :viewOnly="viewOnly" @set-fail="$emit('set-fail')"/>

                <!-- navigation buttons -->
                <div v-if="page > 0" class="navigation-container left">
                    <div @click="$emit('navigate', 'prev')" class="button">
                        <base-icon name="chevron-left" />
                    </div>
                </div>
                <div v-if="page < files.length-1" class="navigation-container right">
                    <div @click="$emit('navigate', 'next')" class="button">
                        <base-icon name="chevron-right" />
                    </div>
                </div>
            </div>
            
            <div class="hashtags" v-if="!isReport">#trending</div>
            <div class="title px-4 pt-2" :class="isOpenTitle ? 'line-clamp-none' : 'line-clamp-3'">{{ postItem ? postItem.caption : 'Video Title' }}</div>
            <div class="px-4 cursor-pointer text-sm" @click="isOpenTitle = !isOpenTitle" v-if="postItem && postItem.caption && postItem.caption.length > 120">
                {{ isOpenTitle ? 'Hide' : 'More' }}
            </div>
            
            <div class="views-interaction px-4">
                <div class="views">{{ views | numeral('0,0')}} views</div>
                <div class="interaction">
                    <div class="item" v-for="(item, index) in computedItems" :key="index">
                        <base-icon :size="7" :name="item.icon"/>
                        <div class="label" v-if="postItem && postItem[item.reportMeta] !== undefined">
                            {{ (postItem[item.reportMeta] > 0 ? postItem[item.reportMeta] : 0) | numeral('0a') }}
                        </div>
                        <div class="label" v-else-if="item.label">{{ item.label }}</div>
                    </div>
                </div>
            </div>

            <div class="info px-4">
                <!-- left column -->
                <div class="flex-shrink flex-grow">
                    <div class="flex items-center gap-x-2 w-full relative">

                        <avatar :user="postedBy"/>

                        <div>
                            <div class="channel-name">
                                <a :href="postItem.profile_item.uri" target="_blank" v-if="isReport && postItem && postItem.profile_item">
                                    {{postedBy.name}}
                                </a>
                                <span v-else>{{postedBy.name}}</span>
                            </div>
                            <div class="subscribers">{{ followers | numeral('0.0a') }} subscribers</div>
                        </div>

                        <div class="absolute right-0 mr-4" v-if="isReport && postItem && postItem.profile_item">
                            <a :href="postItem.profile_item.uri" target="_blank">
                                <base-icon :name="postItem.network.name" style="display: inline-block;"/>
                            </a>
                        </div>
                    
                    </div>

                    <div class="description" :class="isOpen ? 'line-clamp-none' : 'line-clamp-3'">{{description}}</div>
                    <div class="description cursor-pointer font-bold" @click="isOpen = !isOpen" v-if="description.length > 120">{{ isOpen ? 'Hide' : 'More' }}</div>
                </div>
                <!-- right column -->
                <div class="flex-shrink-0 flex-grow-0" v-if="!isReport">
                    <div class="subscribe-button">SUBSCRIBE</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Avatar from '../../../../components/Avatar.vue'
import MediaViewer from '../MediaViewer.vue'
export default {
    props: ['files', 'description', 'postedBy', 'postedAt', 'page', 'viewOnly', 'isReport', 'postItem', 'container'],
    components: {
        MediaViewer,
        Avatar
    },
    data(){
        return {
            interactionItems: [
                { icon: 'youtube-like-2', label: this.postedBy.likes, reportMeta: 'likes' },
                { icon: 'youtube-dislike', label: 'DISLIKE', reportMeta: 'dislikes' },
                { icon: 'youtube-share', label: 'SHARE', reportMeta: 'shares' },
                { icon: 'youtube-save', label: 'SAVE', reportMeta: '', hideOnReport: true },
                { icon: 'youtube-options', label: '', reportMeta: '', hideOnReport: true }
            ],
            isOpenTitle: false,
            isOpen: false
        }
    },
    watch: {
        isOpenTitle: function () {
            this.$emit('resize');
        },
        isOpen: function () {
            this.$emit('resize');
        }
    },
    computed: {
        computedItems() {
            return this.interactionItems.filter(item => {
                if (this.isReport) {
                    return !item.hideOnReport;
                }
                return true;
            });
        },

        followers() {
            if (this.isReport) {
                return this.postItem.profile_item.total_followers;
            }
            return 203000;
        },

        shares() {
            return this.postItem && this.postItem.shares !== undefined ? this.postItem.shares : 21;
        },

        saves() {
            return this.postItem && this.postItem.saves !== undefined ? this.postItem.saves : 21;
        },

        comments() {
            return this.postItem && this.postItem.comments !== undefined ? this.postItem.comments : 103;
        },

        likes() {
            return this.postItem && this.postItem.likes !== undefined ? this.postItem.likes : 130000;
        },

        views() {
            return this.postItem && this.postItem.views !== undefined ? this.postItem.views : 6481375;
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply flex justify-between gap-x-4; 
    }
    .video-container{
        height: 360px;
        @apply relative bg-black;
    }
    .hashtags{
        @apply text-pxs mt-4;
        color: #065fd4;
    }
    .title{
        @apply text-h4 mt-1;
    }
    .views-interaction{
        @apply flex flex-col mt-2;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 20px;
        
        .views{
            color: #606060;
            @apply text-pxs;
        }
        .interaction{
            @apply flex items-center gap-x-4;
            & .item{
                @apply flex items-center gap-x-2;
                & .label{
                    @apply text-ps font-bold;
                }
            }
        }
    }
    .info{
        @apply flex py-4;
        .channel-name{
            @apply text-ps font-semibold;
        }
        .subscribers{
            @apply text-pxs;
            color: #606060;
        }
        .description{
            @apply text-ps mt-4 break-all;
            margin-left: calc(48px + 0.5rem);
        }
        .subscribe-button{
            @apply text-white text-ps py-2 px-4 mt-1 font-bold flex justify-center items-center;
            background-color: #c00;
            border-radius: 2px;
        }
    }
    // .recommendations{
    //     @apply flex flex-col flex-grow-0 flex-shrink-0 gap-y-2;
    //     & .recommended-video {
    //         @apply flex gap-x-2;
    //         & .thumbnail{
    //             @apply relative;
    //             width: 168px;
    //             height: 94px;
    //         }
    //     }
    // }
    .navigation-container{
        position: absolute;
        bottom: 0px;
        top: 0px;
        display: flex;
        align-items: center;            

        &.left{
            left: -40px;
        }

        &.right{
            right: -40px;
        }

        & .button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #bbbbbd;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            cursor: pointer;
            box-shadow: #a3a3a3 -1px 0px 13px 0px;
        }
    }
</style>
<template>
    <base-modal
        :visible="visible"
        :title="`${editing ? 'Edit' : 'Add'} additional info field`"
        @close="close()"
    >
        <div class="container">
            
            <div class="section">
                <div class="field flex-shrink flex-grow">
                    <div class="label">Title</div>
                    <FormulateInput
                        type="text"
                        v-model="name"
                        :disabled="loading"
                        placeholder="New Custom Field"
                    />
                </div>

                <div class="field flex-shrink flex-grow">
                    <div class="label">Field type</div>
                    <div class="">
                        <custom-select
                            :options="types"
                            :key="key"
                            :disabled="loading"
                            v-model="selectedType"
                        />
                    </div>
                </div>
            </div>
            
            <div class="section">
                <div  
                    class="field flex-shrink flex-grow" 
                    :class="{ 'w-1/2': selectedType.slug == 'number' || selectedType.slug == 'date' }"
                    v-if="selectedType"
                >
                    <div class="label">Value</div>
                    <template>
                        <FormulateInput
                            type="textarea"
                            v-if="selectedType.slug == 'text'"
                            class="w-full mt-2"
                            v-model="value"
                            :disabled="loading"
                            input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-32"
                            :rows="1"
                        />
                        <div v-if="selectedType.slug == 'number'">
                            <FormulateInput
                                type="text"
                                class="col-span-1"
                                placeholder="123-456-7890"
                                v-model="value"
                                @input="formatPhone()"
                                :label-class="['font-bold']"
                                inputmode="numeric"
                                :disabled="loading"
                                pattern="[0-9]*"
                                validation="max:15,length|min:0"
                                error-behavior="live"
                                :validation-messages="{max: 'Phone must be less than 15 numbers.'}"
                            />
                        </div>
                        <template v-if="selectedType.slug == 'date'">
                            <div class="calendar-container">
                                <base-icon name="calendar" :size="5"/>
                            </div>
                            <date-picker
                                v-model="value"
                                :disabled="loading"
                                inputWrapperClass="border rounded-md py-4 pr-3 pl-16 bg-white"
                            />
                        </template>
                        <template v-if="selectedType.slug == 'budget'">
                            <div class="dollar-container">
                                <base-icon name="dollar-sign" :size="5"/>
                            </div>
                            <FormulateInput
                                v-model="value"
                                class="block w-1/2 sm:leading-5"
                                type="number"
                                placeholder="0.00"
                                min="0"
                                :wrapper-class="['pl-14 flex items-center']"
                            />
                        </template>
                        <template v-if="selectedType.slug == 'link'">
                            <FormulateInput
                                type="text"
                                label="Display Name"
                                class="mb-4"
                                v-model="linkDisplayName"
                                :disabled="loading"
                            />
                            <FormulateInput
                                type="text"
                                label="Link"
                                v-model="value"
                                :disabled="loading"
                                placeholder="https://"
                            />
                        </template>
                    </template>
                </div>
            </div>
            
            <div class="section" v-if="validateCurrentAdditionalInfo">
                <div
                    class="exclusive"
                    v-if="currentAdditionalInfo.report_user_additional_info.filter(e => e.is_exclusive).length"
                >
                    <div class="content">
                        <base-icon name="locked" />
                        <div>This custom field is only shown on this report.</div>
                    </div>
                    <base-button
                        label="Show on any report" 
                        icon="world"
                        @action="changeIsExclusive(false)"
                        size="xl" 
                        bold
                    />
                </div>
                <div
                    class="exclusive" 
                    v-else
                >
                    <div class="content">
                        <base-icon name="world" />
                        <div>This custom field can be shown on any report.</div>
                    </div>
                    <base-button 
                        label="Show only on this report" 
                        icon="locked"
                        @action="changeIsExclusive(true)"
                        size="xl"
                        bold
                    />
                </div>
            </div>

            <div class="actions">
                <base-button
                    label="Save info"
                    @action="submit()"
                    bold
                />
            </div>

        </div>
    </base-modal>
</template>

<script>
import CustomSelect from '@/components/CustomSelect.vue';
import DatePicker from '@/components/DatePicker.vue';
import { isArray, notifyCatchError, isObject, isValidString } from '@/common';
export default {
    components: { 
        CustomSelect,
        DatePicker 
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        userId: {
            type: Number,
            required: true
        },
        currentAdditionalInfo: {
            type: Object,
            required: false
        },
        reportUserId: {
            type: Number,
            required: false
        }
    },

    watch: {
        // if the selected type changes, we will reset the value
        selectedType(){
            this.value = '';
            this.linkDisplayName = '';
        },
        visible(val) {
            if(!isArray(this.types) && val == true){
                this.getTypes();
            }else{
                this.setData();
            }
        }
    },

    data(){
        return {
            loading: false,
            
            name: '',
            selectedType: null,
            value: '',

            // only for link type
            linkDisplayName: '',
        
            types: [],

            key: false
        }
    },

    computed: {
        validateCurrentAdditionalInfo(){
            return isObject(this.currentAdditionalInfo);
        },
        creating(){
            return !this.validateCurrentAdditionalInfo;
        },
        editing(){
            return this.validateCurrentAdditionalInfo;
        }
    },

    methods: {
        async getTypes(){
            this.loading = true;
            this.$http.get('/user-additional-info/types').then(({ data }) => {
                this.types = data.map(type => {
                    return {
                        value: type.id,
                        label: type.display_name,
                        slug: type.slug
                    }
                });
                this.setData();
            }).catch(err => {
                console.log('getTypes error', err);
            }).finally(() => {
                this.loading = false;
            });
            return;
        },
        setData(){
            if(this.creating){
                this.selectedType = this.types[0];
                this.key = !this.key;
            }else if(this.editing){
                this.name = this.currentAdditionalInfo.name;
                const { id, display_name, slug } = this.currentAdditionalInfo.type_detail;
                this.selectedType = {
                    value: id,
                    label: display_name,
                    slug: slug
                };
                setTimeout(() => {
                    // if its a link type we will split the "linkDisplayName" from the "value"
                    if(slug == 'link'){
                        this.linkDisplayName = this.currentAdditionalInfo.value.split('|')[0];
                        this.value = this.currentAdditionalInfo.value.split('|')[1];
                    }else{
                        this.value = this.currentAdditionalInfo.value;
                    }
                }, 200);
                this.key = !this.key;
            }
        },
        submit(){
            let { name, value, linkDisplayName } = this;
            const type_id = this.selectedType.value;

            // only for link type, we will join the "linkDisplayName" with the "value"
            if(this.selectedType.slug == 'link'){
                value = `${linkDisplayName}|${value}`
            }

            // validation
            if(this.selectedType.slug == 'link'){
                const linkDisplayName = value.split('|')[0];
                const link = value.split('|')[1];
                if(!isValidString(linkDisplayName) || !isValidString(link)){
                    return this.$notify({ title: 'Invalid Link', type: 'warn' });
                }
            }

            if(this.creating){
                this.loading = true;
                this.$http.post('/user-additional-info', {
                    user_id: this.userId,
                    name,
                    type_id,
                    value                   
                }).then(({ data }) => {
                    this.$notify({ title: 'Success', text: 'Info created successfully', type: 'success' });
                    if(this.reportUserId){
                        this.createReportUserAdditionalInfo(data);
                    }else{
                        this.$emit('reload');
                        this.close();
                    }
                }).catch(err => {
                    notifyCatchError(err, this.$notify);
                }).finally(() => {
                    this.loading = false;
                });
            }
            if(this.editing){
                this.loading = true;
                this.$http.put(`/user-additional-info/${this.currentAdditionalInfo.id}`, {
                    name,
                    type_id,
                    value
                }).then(({ data }) => {
                    this.$notify({ title: 'Success', text: 'Info saved successfully', type: 'success' });
                    this.$emit('reload');
                    this.close();
                }).catch(err => {
                    notifyCatchError(err, this.$notify);
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        createReportUserAdditionalInfo(userAdditionalInfo){
            this.loading = true;
            this.$http.post('/report-user-additional-info', {
                report_user_id: this.reportUserId,
                user_additional_info_id: userAdditionalInfo.id,
                // by default, additional infos created in this view will be exclusive to that report, so we set "is_exclusive" to "true"
                is_exclusive: true
            }).then(({ data }) => {
                this.$notify({ title: 'Success', text: 'Info added to report successfully', type: 'success' });
                this.close();
                this.$emit('reload');
            }).catch(err => {
                notifyCatchError(err, this.$notify);
            }).finally(() => {
                this.loading = false;
            });
        },
        changeIsExclusive(is_exclusive){
            this.loading = true;
            this.$http.put(`/user-additional-info/${this.currentAdditionalInfo.id}/change-exclusive`, {
                is_exclusive: is_exclusive,
                report_user_id: this.reportUserId
            }).then(({ data }) => {
                this.$notify({ title: 'Success', text: 'Info updated successfully', type: 'success' });
                this.$emit('reload');
                this.close();
            }).catch(err => {
                notifyCatchError(err, this.$notify);
            }).finally(() => {
                this.loading = false;
            });
        },
        close(){
            this.loading = false;
            this.name = '';
            this.selectedType = null;
            this.value = '';
            this.$emit('close');
        },
        formatPhone() {
            let str = this.value;
            let formatted = str.replace(/\D/g, '').replace(/(\d{3})(\d{1,3})(\d{1,4})/, '$1-$2-$3').replace(/(-\d{4})\d+?$/, '$1');
            this.value = formatted;
        }
    }

}
</script>
<style lang="scss" scoped>
    .container{
        @apply flex flex-col gap-y-6;
        > .section{
            @apply w-full flex gap-x-6;
            > .field{
                @apply relative;
                > .label{
                    @apply font-bold text-h5 mb-1;
                }
                > .calendar-container{
                    @apply absolute left-5 bottom-3.5 z-10;
                }
            }
            > .exclusive{
                @apply flex flex-col gap-y-3;
                > .content{
                    @apply flex items-center gap-x-2;
                }
            }
        }
        > .actions{
            @apply w-full flex justify-end mt-8;
        }
    }
</style>

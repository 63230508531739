<template>
    <main class="creator-edit-wrapper" tabindex="0">

    <div class="creator-edit-container">

        <profile-completion-external :user="user" />

        <fixed-loader label="Loading" :visible="loading"/>

        <div class="header">
            <div class="title">
                General Information
            </div>
            <base-button 
                v-if="userReady" 
                :label="user.hasPassword ? 'Remove Access' : 'Send Invite to Create Account'" 
                @action="triggerInvite" 
                bold 
                :size="user.hasPassword ? 'lg' : '2xl'"
            />
        </div>

        <div class="general-form-wrapper">            
            <div class="general-form">                                
                <FormulateForm
                    name="form"
                    @submit="submit()"
                    class="flex flex-col gap-y-4"
                >

                    <avatar
                        class="max-w-none h-6 w-6 rounded-full text-white shadow-solid"
                        :user="user"
                        size="x-large"
                    />

                    <FormulateInput
                        type="text"
                        v-model="user.avatar"
                        label="Avatar"
                        placeholder="Avatar"
                        :disabled="loading || !userFound"
                    />

                    <FormulateInput
                        type="text"
                        v-model="user.email"
                        label="Email"
                        placeholder="Email"
                        :disabled="loading || !userFound"
                    />

                    <FormulateInput
                        type="text"
                        validation="required"
                        v-model="user.first_name"
                        name="first_name"
                        label="First Name"
                        placeholder="First Name"
                        validation-name="First name"
                        :disabled="loading || !userFound"
                    />

                    <FormulateInput
                        type="text"
                        validation="required"
                        v-model="user.last_name"
                        name="last_name"
                        label="Last Name"
                        placeholder="Last Name"
                        validation-name="Last name"
                        :disabled="loading || !userFound"
                    />  

                    <FormulateInput
                        type="text"
                        label="Phone"
                        placeholder="123-456-7890"
                        v-model="user.phone"
                        @input="formatPhone()"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        validation="max:15,length|min:0"
                        error-behavior="live"
                        :validation-messages="{max: 'Phone must be less than 15 numbers.'}"
                        :disabled="loading || !userFound"
                    />

                    <custom-pills
                        v-if="userReady"
                        label="Tags"
                        placeholder="add tags"
                        :options="tags"
                        allow-create
                        create-label="Create new tag"
                        model-name="Tag"
                        :create-route="`/api/tags`"
                        :multiple="true"
                        size="auto"
                        v-model="selectedTags"
                        @finish-create="getTags"
                    />

                    <base-button
                        @action="$formulate.submit('form')"
                        class="mt-4 font-bold"
                        submit
                        label="Save"
                        :disabled="loading || !userFound"                         
                    />
                
                </FormulateForm>
            </div>
        </div>
        
        <div class="connections" v-if="userReady && userFound">
            <div class="header">
                <div class="title">
                    Connections
                    <span>({{ user.social_logins.length }})</span>
                </div>
            </div>

            <div class="content">
                <div v-if="!user.social_logins.length">
                    No connections yet
                </div>

                <div
                    class="connection" 
                    v-for="(socialLogin, index) in user.social_logins" 
                    :key="index"
                >
                    <!-- header -->
                    <div class="header">
                        <div class="network">
                            <base-icon :name="getObjectValue(NETWORKS, `${socialLogin.network_id}.name`)" />
                            <div>{{  getObjectValue(NETWORKS, `${socialLogin.network_id}.display_name`) }}</div>
                        </div>
                    </div>

                    <!-- content -->
                    <div class="content">
                        <div class="id">ID: {{socialLogin.profile_id}}</div>
                        
                        <!-- instagram info -->
                        <div class="profile" v-if="socialLogin.ig_account">
                            <div class="left">
                                <avatar size="large" :user="{ first_name: '', last_name: '', avatar: socialLogin.ig_account.profile_picture_url }" />
                                <div class="names">
                                    <div class="display-name">{{socialLogin.ig_account.name}}</div>
                                    <div class="username">{{socialLogin.ig_account.username}}</div>
                                </div>
                            </div>
                            <div class="right">
                                <base-button label="View profile" v-if="socialLogin.ig_account.username" @action="viewInstagramProfile(socialLogin.ig_account.username)" />
                            </div>
                        </div>

                        <div v-if="socialLogin.fb_business_token" class="py-3">
                            Connected through FB Business Manager
                        </div>

                        <!-- tiktok info -->
                        <div class="profile" v-if="socialLogin.tiktok_account">
                            <div class="left">
                                <avatar size="large" :user="{ first_name: '', last_name: '', avatar: socialLogin.tiktok_account.avatar_url }" />
                                <div class="names">
                                    <div class="display-name">{{socialLogin.tiktok_account.display_name}}</div>
                                    <div class="username">{{socialLogin.tiktok_account.username}}</div>
                                </div>
                            </div>
                            <div class="right">
                                <base-button label="View profile" v-if="socialLogin.tiktok_account.username" @action="viewTikTokProfile(socialLogin.tiktok_account.username)" />
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>
        
        <div class="social-accounts" v-if="userReady && userFound">
            
            <div class="header">
                <div class="title">
                    Social Accounts
                    <span>({{ user.social_accounts.length }})</span>
                </div>
                <base-button label="Add Social Account" type="label" icon="plus-circle" @action="addSocialAccountModalVisible = true" :disabled="disabledAddNetwork" />         
            </div>

            <div class="content">
                <!-- empty state -->
                <div v-if="!computedAccounts.length">
                    No social accounts yet
                </div>
                <!-- list -->
                <div 
                    v-for="(social_account, index) in computedAccounts"
                    :key="index"
                    class="social-account"
                    :class="{ 'has-audience-data': social_account.audience_data }"
                >
                    <div class="actions">
                        <base-icon name="trash" @action="removeNetwork(social_account)"/>
                    </div>
                    
                    <div
                        class="network" 
                        @click="social_account.audience_data ? selectedAudience = social_account.audience_data : null"
                    >
                        <base-icon :name="social_account.network.name" />
                        <div>{{social_account.network.display_name}}</div>
                    </div>

                    <div 
                        class="user" 
                        @click="social_account.audience_data ? selectedAudience = social_account.audience_data : null"
                    >
                        <avatar
                            class="rounded-full text-white shadow-solid"
                            :user="social_account"
                            size="medium"
                        />
                        <div>
                            @{{social_account.handle}}
                        </div>
                    </div>

                </div>
            </div>
        </div>
        
        <div class="notes" v-if="userReady && userFound">
            <div class="header">
                <div class="title">
                    Notes <span>({{ user.notes ? user.notes.length : 0 }})</span>
                </div>
            </div>

            <div class="content">
                <div class="list" v-for="note in user.notes" :key="note.id">
                    <div class="item">
                        <avatar :user="note.creator"/>
                        <div class="content">                    
                            <div class="value">{{ note.value }}</div>
                            <div class="date">{{ note.created_at | moment('MMM D, Y') }}</div>
                        </div>
                    </div>
                </div>
                <div class="form">
                    <FormulateInput
                        type="textarea"
                        class="w-full"
                        placeholder="Write a note..."
                        input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-20"
                        v-model="note"
                        :rows="1"
                    />           
                </div>
                <base-button 
                    size="lg" 
                    theme="dark" 
                    secondaryIcon="pencil" 
                    label="Add Note" 
                    :disabled="loading || note === ''" 
                    @action="addNote" 
                    class="font-bold" 
                />
            </div>  
        </div>  
        
        <!-- modals section -->
        <base-modal 
            title="Add Social Account"
            :visible="addSocialAccountModalVisible"
            @close="addSocialAccountModalVisible = false"
        >

            <!-- network select -->
            <network-select
                v-if="user && user.social_accounts"
                :values="networkValues"
                :excludedNetworks="excludedNetworks"
                show-custom
                cols="grid-cols-1"
                container-class="overflow-y-auto h-96 max-h-96"
                custom-label
                @users-exists="handleExistingHandle"
            />

            <div class="flex justify-end">
                <base-button 
                    @action="addSocialAccountIntent"
                    size="lg"
                    :disabled="loading || existingHandle"
                    label="Add Social Account"
                />
            </div>
        </base-modal>

        <update-audience 
            @finish-update="finishUpdateAudience" 
            @close="selectedAudience = null" 
            :open="selectedAudience !== null" 
            :current-audience="selectedAudience"
        />

        <send-invite-modal
            :visible="invite !== null"
            :invite="invite"
            @close="invite = null"
        />
    </div>
    </main>
</template>

<script>
import NetworkSelect from '../../components/NetworkSelect.vue'
import SendInviteModal from './SendInviteModal.vue'
import {customNetwork} from '@/common';
import UpdateAudience from '@/views/CreatorEdit/UpdateAudience'
import { NETWORKS, getObjectValue, notifyCatchError } from '../../common';
import ProfileCompletionExternal from './ProfileCompletionExternal.vue';
import Loader from '../../components/Loader.vue';
import FixedLoader from '../../components/FixedLoader.vue';
export default {
    components: {
        NetworkSelect,
        UpdateAudience,
        SendInviteModal,
        ProfileCompletionExternal,
        Loader,
        FixedLoader
    },

    data(){
        return {
            user: {
                user_companies: [
                    {
                        first_name: '',
                        last_name: '',
                        phone: ''
                    }
                ],
                social_accounts: []
            },
            project: {},
            selectedAudience: null,
            tags: [],
            selectedTags: [],
            noProject: false,
            loading: false,
            userReady: false,

            note: '',
            addNoteModalVisible: false,
            
            networkValues: {
                instagram_handle: '',
                tiktok_handle: '',
                youtube_handle: '',
                pinterest_handle: {...customNetwork(4, false)},
                facebook_handle: {...customNetwork(6, false)},
                twitter_handle: {...customNetwork(7, false)},
                linkedin_handle: {...customNetwork(16, false)},
                custom_handle: {...customNetwork(17, false)}
            },
            existingHandle: false,
            invite: null,
            body: '',
            addSocialAccountModalVisible: false,
            NETWORKS,
            getObjectValue
        }
    },

    beforeMount() {
        this.$setTitle('Edit Creator');
        this.getTags();
    },

    mounted() {
        this.getUser();
    },

    computed: {
        userFound(){
            return !!this.user.id
        },

        // uses company user and defaults to user scope
        computedUser() {
            if(this.user.user_companies[0] && this.user.user_companies[0].id){
                return this.user.user_companies[0]
            }
            return this.user
        },

        computedAccounts() {
            let accounts = this.user.social_accounts ? this.user.social_accounts : [];
            for (const account of accounts) {
                const audience_data = this.user.audience_data.find(element => element.network.id == account.network_id)
                if(audience_data && audience_data.profile){
                    account.audience_data = {
                        direct: true,
                        id: audience_data.id,
                        social_url: audience_data.profile.url,
                        handle: audience_data.profile.username,
                        network_id: audience_data.network.id,
                        profile: audience_data.profile,
                        audience: audience_data.audience_followers,
                        network: audience_data.network,
                        avatar: audience_data.profile.picture
                    }

                }
            }
            return accounts
        },

        excludedNetworks() {
            return this.user.social_accounts.map(social_account => social_account.network);
        },

        disabledAddNetwork() {
            let options = Object.keys(this.networkValues).length;
            let filled = this.excludedNetworks.length;

            return options - filled <= 0;
        },
    },

    methods: {
        removeNetwork(network) {
            this.$swal.fire({
                title: `Are you sure you want to remove '${network.handle}' from ${network.network.display_name} for this creator?`,
                text: 'This action is irreversible',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Remove account',
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let { handle, network_id, user_id } = network;
                    let data = { handle, network_id, user_id };
                    await this.$http.post(`/api/users/${this.$route.params.userId}/remove-handle`, data);
                    this.$notify({ title: 'Success', text: 'The handle has been removed', type: 'success' });
                    this.getUser();
                }
            });
        },
        finishUpdateAudience() {
          this.selectedAudience = null;
          this.getUser();
        },
        async getTags() {
          const { data } = await this.$http.get('/api/tags')
          this.tags = data.map(tag => {
            return {
              value: tag.id,
              label: tag.name
            }
          });
        },
        async submit() {
            try{
                const { data } = await this.$http.put(`/api/users/${this.$route.params.userId}/profile`, {
                    avatar: this.user.avatar,
                    email: this.user.email,
                    first_name: this.user.first_name,
                    last_name: this.user.last_name,
                    phone: this.user.phone,
                    tags: this.selectedTags.map(st => {return st.value || st.id}),
                });
                if(data){
                    this.$notify({ title: 'Success', text: 'User Updated', type: 'success' });
                    this.selectedTags = [];
                    this.getUser();
                }
            }catch(e){
                console.log('submit error', e)
                if(e.response && e.response.data && e.response.data.message){
                    return this.$notify({ title: 'Warning', text: e.response.data.message, type: 'warn' });
                }
                this.$notify({ title: 'Warning', text: 'Something went wrong', type: 'warn' });
            }
        },
        completedInvite(text = '') {
            this.invite = null;
            if (text !== '') {
                this.$notify({ title: 'Success', text, type: 'success' });
            }
            this.getUser();
        },
        async triggerInvite() {
            let title = `Invite this creator to create an account ?`;
            let text = 'An invite link will be created, you can copy it or send it by email to the creator to login in on the platform';
            let confirmButtonText = 'Create invite';
            let fn = null;

            let id = this.user.id;

            if (this.user.hasPassword) {
                title = `Are you sure you want to remove access for this creator?`;
                text = 'This action is irreversible';
                confirmButtonText = 'Remove access';
                fn = async () => {
                    await this.$http.post(`/api/users/${id}/remove-access`, { id });
                    this.completed('The access has been removed for this user');
                };
            } else {
                fn = async () => {
                    let { data } = await this.$http.post(`/api/users/${id}/grant-access/preview`, { id, url: window.location.origin });
                    if (data && data.success) {
                        this.invite = {...data.invite, user: data.user, url: data.url};
                    }
                };
            }

            this.$swal.fire({
                title,
                text,
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText,
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    fn();
                }
            });
        },
        async addNote() {
            const { data } = await this.$http.post(`/api/users/${this.$route.params.userId}/notes`, {
                id: this.user.id,
                projectId: this.project.id,
                notes: this.note
            });
            if(data){
                this.note = '';
                this.$notify({ title: 'Success', text: 'Note Added', type: 'success' })
                this.getUser()
            }
        },
        addSocialAccountIntent() {
            // check if instagram handle differs from the creators facebook login connected ig account
            if(this.networkValues.instagram_handle.trim() !== "" && this.user.social_logins && this.user.social_logins.length){
                const facebook_login = this.user.social_logins.find(element => element.network_id == 6)
                if(facebook_login && facebook_login.ig_account && facebook_login.ig_account.username){
                    if(facebook_login.ig_account.username.trim() !== this.networkValues.instagram_handle.trim()){
                        return this.$swal.fire({
                            title: 'The instagram handle differs from the creators connected instagram account',
                            text: `Connected instagram account: ${facebook_login.ig_account.username.trim()}`,
                            icon: 'warning',
                            iconColor: '#0E092C',
                            showCancelButton: true,
                            confirmButtonText: 'Continue',
                            reverseButtons: true,
                        }).then((result) => {
                            if(result.isConfirmed){
                                this.addSocialAccount()
                            }
                        })
                    }
                }
            }
            this.addSocialAccount()
        },
        async addSocialAccount() {    
            let networkValues = {};
            let customNetworks = [];
            for (let n in this.networkValues) {
              if (typeof this.networkValues[n] === 'string' && this.networkValues[n] !== '') {
                networkValues[n] = this.networkValues[n];
              } else {
                if (this.networkValues[n].is_custom) {
                  let { avg_likes, followers, username } = this.networkValues[n].payload.user_profile;
                  if (this.networkValues[n].social_url !== '' && avg_likes !== '' && followers !== '' && username !== '') {
                    customNetworks.push(this.networkValues[n]);
                  }
                }
              }
            }
            this.loading = true;
            this.$http.post(`/api/projects/${this.$route.params.projectId ? this.$route.params.projectId : 0}/influencer/${this.$route.params.userId}/network`, {
                ...networkValues,
                customs: customNetworks,
            }).then(({data}) => {
                if(data){
                    this.addSocialAccountModalVisible = false;
                    this.$notify({ title: 'Success', text: 'Social Account Added', type: 'success' });
                    // wait for "finally"s this.loading = false;
                    // so when executing getUser it can toggle loading to true again
                    setTimeout(() => {
                        this.getUser();
                    }, 500);
                }
            }).catch(err => {
                notifyCatchError(this.$notify, err);
            }).finally(() => {
                this.loading = false;
            });
        },
        async getUser() {
            try{

                this.loading = true;
                // TODO: check if we are still using this
                if(this.$route.params.projectId){

                    this.selectedTags = [];
                    const { data } = await this.$http.get(`/api/projects/${this.$route.params.projectId}/influencer/${this.$route.params.userId}`)

                    this.user = {...data.user,  user_companies: [ { first_name: data.user.first_name, last_name: data.user.last_name, phone: data.user.phone } ]}

                    this.selectedTags = data.user.user_tags.map(user_tag => {
                        return {
                            value: user_tag.tag.id,
                            label: user_tag.tag.name
                        }
                    })
                    this.project = data.project;

                } else {

                    this.noProject = true;
                    const { data } = await this.$http.get(`/api/users/${this.$route.params.userId}/details?ig_account=true&tiktok_account=true&has_password=true`);
                    
                    this.user = data;
                    this.selectedTags = data.user_tags.map(user_tag => {
                        return {
                            value: user_tag.tag.id,
                            label: user_tag.tag.name
                        }
                    });

                }
                this.userReady = true;
                this.$emit('update-title', `${this.user.user_companies[0].first_name} ${this.user.user_companies[0].last_name}`);

            }catch(err){
                console.log('getUser error', err);
                notifyCatchError(err, this.$notify);
            }finally{
                this.loading = false
            }
        },
        handleExistingHandle(users) {
            if (users && Array.isArray(users) && users.length) {
                this.existingHandle = true;
            } else {
                this.existingHandle = false;
            }
        },
        formatPhone() {
            if(!this.user.phone) return;
            this.user.phone = this.user.phone.replace(/\D/g, '').replace(/(\d{3})(\d{1,3})(\d{1,4})/, '$1-$2-$3').replace(/(-\d{4})\d+?$/, '$1');
        },
        viewTikTokProfile(username){
            window.open(`https://www.tiktok.com/@${username}`, '_blank'); 
        },
        viewInstagramProfile(username){
            window.open(`https://www.instagram.com/${username}`, '_blank'); 
        }
    }
}
</script>
<style lang="scss" scoped>
    main.creator-edit-wrapper{
        @apply flex-1 overflow-y-auto;
        &:focus{
            @apply outline-none;
        }
        > .creator-edit-container{
            @apply flex-1 min-w-0 bg-white;
            > .header{
                @apply flex justify-between items-center px-12 py-10;
                > .title{
                    @apply text-h2 font-bold text-purple-m-secondary;
                }
            }
            > .general-form-wrapper{
                @apply px-12 pb-10 flex justify-between gap-8;
                > .general-form{
                    @apply w-1/2;
                }
            }
            .connections{
                > .header{
                    @apply flex justify-between px-12 py-10;
                    > .title{
                        @apply text-h2 font-bold text-purple-m-secondary;
                        > span{
                            @apply text-gray-m-disable;
                        }
                    }
                }
                > .content{
                    @apply px-12 pb-10;
                    > .connection{
                        @apply bg-tan-m rounded-lg p-4 mb-4 flex flex-col gap-y-4;
                        width: fit-content;
                        min-width: 500px;
                        > .header{
                            > .network{
                                @apply flex items-center gap-x-2;
                            }
                        }
                        > .content{
                            > .id{
                                @apply font-bold text-pxs;
                            }
                            > .profile{
                                @apply flex justify-between items-center mt-4;
                                .left{
                                    @apply flex gap-x-4 items-center;
                                    .names{
                                        @apply flex flex-col justify-center;
                                        .display-name{
                                            @apply font-bold;
                                        }
                                        .username{
                                            @apply font-bold flex items-center gap-x-1;
                                        }
                                    }
                                }
                                .right{
                                    @apply flex items-center;
                                }
                            }

                        }
                    }
                }
            }
            .social-accounts{
                > .header{
                    @apply flex justify-between px-12 py-10;
                    > .title{
                        @apply text-h2 font-bold text-purple-m-secondary;
                        > span{
                            @apply text-gray-m-disable;
                        }
                    }
                }
                > .content{
                    @apply px-12 pb-10;
                    > .social-account{
                        @apply bg-tan-m rounded-lg p-4 mb-4 flex items-center gap-x-10;
                        width: fit-content;
                        > .actions{
                            @apply flex items-center gap-x-2;
                        }
                        > .network{
                            @apply flex items-center gap-x-2;
                        }
                        > .user{
                            @apply flex items-center gap-x-2;
                        }
                        &.has-audience-data{
                            @apply cursor-pointer;
                            &:hover{
                                @apply opacity-50;
                            }
                        }
                    }
                }
            }
            .notes{
                > .header{
                    @apply flex justify-between px-12 py-10;
                    > .title{
                        @apply text-h2 font-bold text-purple-m-secondary;
                        > span{
                            @apply text-gray-m-disable;
                        }
                    }
                }
                > .content{
                    @apply flex flex-col gap-10 px-12 pb-10;
                    > .list{
                        > .item{
                            @apply flex gap-4 items-end;
                            > .content{
                                @apply flex flex-col items-start justify-end gap-1;
                                > .value{
                                    @apply text-pm text-purple-m-secondary;
                                }
                                > .date{
                                    @apply text-pxs text-gray-m-disable;
                                }
                            }
                        }
                    }
                    // > .form{}
                }
            }
        }
    }
</style>

<script>
// Load speperate modules
import { Doughnut } from 'vue-chartjs';

export default {
    extends: Doughnut,
    // mixins: [mixins.reactiveProp],
    props: ["chartData", "options"],

    mounted () {
        this.renderChart(this.chartData, this.options)
    }
};
</script>
<style lang="sass">

</style>
<template>
    <div>
        
        <Header title="Apps" :count="apps.length" :loading="loading" />
        
        <div class="apps-list">
            <div v-if="!apps.length">No apps were found</div>

            <template v-for="(app, index) in apps">
                <div class="item" :key="index" @click="onItemClick(app)">
                    <div>ID: {{app.id}}</div>
                    <div>Name: {{app.name}}</div>
                    <div>Category: {{app.category}}</div>
                    <div>URL: {{app.link}}</div>
                </div>
            </template>

        </div>
 
    </div>
</template>

<script>
import Header from './Header.vue'
import { mapGetters } from 'vuex';
export default {
    components: {
        Header
    },
    data(){
        return {
            apps: [],
            loading: false
        }
    },
    mounted() {
        this.getApps()
    },
    computed: {
        ...mapGetters(['company', 'fbLogin']),
    },
    methods: {
        onItemClick(app){
            window.open(app.link, '_blank')
        },
        async getApps(){
            this.loading = true
            this.$http.get('/api/facebook', {
                params: {
                    entity_id: this.company.fb_business_id,
                    edge: 'owned_apps',
                    user_access_token: true
                }
            }).then(async (res) => {
                if(res.data){
                    this.apps = res.data
                }
            }).catch((err) => {
                console.log('getApps error', err)
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .apps-list{
        @apply flex flex-col mt-6 gap-y-4 items-start;
        .item{
            @apply w-full;
            @apply px-6 py-2 bg-tan-m rounded-xl cursor-pointer transition-opacity duration-200;
            &:hover{
                @apply opacity-50;
            }
        }
    }
</style>
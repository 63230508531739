<template>
    <div :key="key">
        
        <div 
            v-for="(option, index) in computedOptions" 
            :key="`option-${index}`"
        >
            <!-- parent options -->
            <div v-if="option.options.length > 0">
                
                <div class="flex items-center">
                    <!-- hidden -->
                    <input 
                        type="checkbox"
                        class="custom-checkbox-input"
                        :id="option.id"
                    />
                    <label 
                        :class="`custom-checkbox-label ${isParentChecked(option) ? 'active' : ''}`" 
                        :for="option.id"
                        @click="toggle(option.options, isParentChecked(option) ? 'push' : 'filter')" 
                    />
                    <div class="custom-checkbox-text">{{option.label}}</div>
                    <base-button
                        type="label" 
                        :iconSize="4"
                        class="ml-2"
                        :icon="option.open ? 'chevron-down' : 'chevron-right'" 
                        @action="option.open = !option.open; updateComponent()" 
                    />
                </div>

                <!-- show its child options -->
                <template v-if="option.open">
                    <div 
                        v-for="(childOption, i) in option.options" 
                        :key="`child-option-${i}`"
                        class="flex items-center ml-6"
                    >
                        <input
                            class="custom-checkbox-input" 
                            type="checkbox"
                        />
                        <label 
                            :class="`custom-checkbox-label ${value.includes(childOption.id) ? 'active' : ''}`" 
                            :for="childOption.id"
                            @click="toggle(childOption)"
                        />
                        <div class="custom-checkbox-text">{{childOption.label}}</div>
                    </div>
                </template>

            </div>
            <!-- childless options -->
            <div class="flex items-center" v-else>
                <input
                    class="custom-checkbox-input" 
                    type="checkbox" 
                />
                <label
                    :class="`custom-checkbox-label ${value.includes(option.id) ? 'active' : ''}`" 
                    :for="option.id" 
                    @click="toggle(option)"
                />
                <div class="custom-checkbox-text">{{option.label}}</div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['label', 'options', 'value'],
    data(){
        return {
            key: 1
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        computedOptions(){
            let tree = []            
            let arr = this.options

            arr.forEach(option => {
                if(option.parent_id == null){
                    tree.push(option)
                    arr = arr.filter(element => element != option)
                }
            })

            tree = tree.map(element => {
                element.options = []
                element.open = false
                return element
            })

            arr.forEach((option) => {
                const foundParent = tree.find(element => element.id == option.parent_id) 
                
                if(foundParent){
                    const index = tree.indexOf(foundParent)
                    tree[index].options.push(option)
                }

            })

            return tree;
        }
    },
    methods: {
        toggle(options, prevent = null){
            
            let arr = options instanceof Array ?  options : [options]
            arr = arr.map(element => element.id)
            let value = this.value
            
            arr.forEach(option => {
                if(!this.value.includes(option)){
                    prevent == 'push' ? null : value.push(option) 
                }else{
                    prevent == 'filter' ? null : value = value.filter(element => element != option)
                }
            })
            this.$emit('change', value)
            this.$emit('input', value)
        },
        isParentChecked(option){
            const mapped = option.options.map(element => element.id)
            return mapped.every(asd => {
                return this.value.includes(asd)
            })
        },
        updateComponent(){
            this.key = !this.key
        }
    }
}
</script>
<style lang="scss" scoped>
    .custom-checkbox-input{
        position: absolute;
        left: -999px;
        opacity: 0;
        pointer-events: none;
    }

    .custom-checkbox-label{
        border-color: #1D173F;
        display: block;
        width: 1em;
        height: 1em;
        border-radius: 0.25em;
        border: 1px solid #cecece;
        position: relative;
        &.active{
            background-color: #1D173F;
        }
    }

    .custom-checkbox-label:before {
        content: "";
        display: block;
        background-size: contain;
        background-position: 100%;
        width: calc(100% - 0.125em);
        height: calc(100% - 0.125em);
        box-sizing: border-box;
        position: absolute;
        top: 0.0625em;
        left: 0.0625em;
    }

    .custom-checkbox-label.active:before{
        background-color: #41b883;
        -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>');
        mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M8.76,56.2c-6.38-6.34,3.26-16,9.64-9.69L38,65.88,80.56,23.29c6.38-6.38,16.07,3.32,9.69,9.69L42.84,80.37a6.83,6.83,0,0,1-9.65,0Z"/></svg>');
    }

    .custom-checkbox-text{
        @apply ml-4;
    }
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Title',{attrs:{"type":"main"}},[_vm._v("Base Icon")]),_c('Title',[_vm._v("Usage")]),_c('Code',{attrs:{"title":"Simple use","code":"\n        <base-icon name=\"check\"/>\n    "}}),_c('Code',{attrs:{"title":"Use with custom options","code":"\n        <base-icon \n            :size=\"6\" \n            :iconSize=\"3\"\n            name=\"arrow-up\"\n            :fill=\"false\"  \n            border\n            background=\"bg-white\"\n            @action=\"$emit(\"event\")\" \n            defaultIcon=\"arrow-down\"\n            wrapperClass=\"my-class\"\n            iconClass=\"my-class\"\n        />\n    "}}),_c('Title',[_vm._v("Props")]),_c('Table',{attrs:{"title":"The component accepts the following props:","columns":['Name', 'Type', 'Required', 'Default', 'Description'],"data":[
            ['name', 'String', 'true', '-', 'Name of the icon that will be displayed'],
            ['defaultIcon', 'String', 'false', '-', 'Name of a default icon to be displayed'],
            ['size', 'Number', 'false', '5', 'Size for the containers of the icon'],
            ['iconSize', 'Number', 'false', '-', 'Size for the actual icon'],
            ['fill', 'Boolean', 'false', 'true', 'Some icons have two states: filled and hollow, this toggles between those two'],
            ['wrapperClass', 'String', 'false', '-', 'Classes to be applied to the icon wrapper element'],
            ['iconClass', 'String', 'false', 'h-full w-full', 'Classes to be applied to the icon element'],
            ['border', 'Boolean', 'false', 'false', 'Whether a border will be displayed on the icon'],
            ['background', 'String', 'false', '-', 'Tailwind class for icons background color']
        ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="container">
        <div class="title">Columns</div>
        <base-table
            :data="mapperVal"
            class="mt-8 mb-12"
            :key="`mapper-table-key-${key}`"
            :columns="[
                { 
                    name: 'target_column', 
                    label: 'Target Column', 
                    type: 'html',
                    value: computedTargetColumnValue
                },
                { 
                    name: 'mapper', 
                    label: 'File Column', 
                    type: 'single-select', 
                    placeholder: 'Select the file column',
                    width: '250px',
                    value: (element, column) => {
                        return !element.fileColumn ? {
                            label: 'Ignore column',
                            value: 'ignore-column'
                        } : {
                            label: element.fileColumn,
                            value: element.fileColumn
                        }
                    },
                    options: () => {
                        return [
                            { label: 'Ignore column', value: 'ignore-column' }, 
                            ...fileColumns.map(element => {
                                return {
                                    label: element,
                                    value: element
                                }
                            })
                        ]
                    } 
                },
                { 
                    name: 'example', 
                    label: 'Example data',
                    value: (element) => {
                        return element.examples.join(', ');
                    }
                }
            ]"
            :search="false"
            @change-single-select="changeFileColumn"
        />

        <div class="title">Additional Information</div>

        <base-table
            :data="additionalInformationVal"
            class="mt-8 mb-12"
            :key="`additional-information-table-key-${key}`"
            :columns="[
                {
                    name: 'is-selected',
                    label: '',
                    type: 'checkbox',
                    value: (element) => {
                        return element.selected;
                    },
                    options: {
                        cellStyles: 'width: 62px;'
                    }
                },
                {
                    name: 'name', 
                    label: 'Column'
                },
                { 
                    name: 'example', 
                    label: 'Example data',
                    value: (element) => {
                        return element.examples.join(', ');
                    }
                }
            ]"
            :onRowClick="onRowClick"
            :search="false"
        >
            <template v-slot:table-header>
                <div class="additional-information-table-header">
                    <div class="title">{{selectedAdditionalInformation.length}} selected</div>
                    <base-button
                        v-if="additionalInformationVal.length == selectedAdditionalInformation.length"
                        size="md"
                        @action="deselectAllAdditionalInformation"
                        label="Deselect all"
                        bold
                    />
                    <base-button
                        v-else
                        size="md"
                        @action="selectAllAdditionalInformation"
                        label="Select all"
                        bold
                    />
                </div>
            </template>
        </base-table>

    </div>
</template>
<script>
import { copyObject, isNotNull, isValidArray } from '../../common';
import { fields } from './fields';
export default { 
    props: ['fileCreators', 'mapper', 'additionalInformation', 'mountEventTrigger'],
    data(){
        return {
            mapperVal: this.mapper,
            additionalInformationVal: this.additionalInformation,
            key: false
        }
    },
    watch: {
        // trigger the mounted functions again,
        // only if we go from step 2 to step 3
        mountEventTrigger(val, old){
            if(val !== old && old == 2 && val == 3){
                this.buildMapper();
                this.buildAdditionalInformation();
            }
        }
    },
    mounted(){ 
        this.buildMapper();
        this.buildAdditionalInformation();
    },
    methods: {
        commit(){
            setTimeout(() => {
                this.key = !this.key;
            }, 50)
        },
        buildMapper(){
            let arr = [];
            if(isValidArray(this.fileColumns)){
                for (let field of fields) {
                    const { autoFound, mapped, fileColumn, examples } = this.findFieldsFileColumn(field);
                    field.autoFound = autoFound;
                    field.mapped = mapped;
                    field.fileColumn = fileColumn;
                    field.examples = examples;
                    arr.push(field);
                }
            }
            this.mapperVal = arr;
            this.$emit('update:mapper', this.mapperVal);
            this.commit();
        },
        buildAdditionalInformation(){
            let arr = [];
            if(isValidArray(this.fileColumns)){
                for (const fileColumn of this.fileColumns) {
                    let found = false; 
                    for (let field of fields) {
                        const bySlug = field.slug == fileColumn;
                        let byVariant = false;
                        for (let variant of field.variants) {
                            variant = variant.toLowerCase().replaceAll(' ', '_');
                            if(variant == fileColumn){
                                byVariant = true;
                            }
                        }
                        if(bySlug || byVariant){
                            found = true;
                        }
                    }
                    if(!found){
                        arr.push({ 
                            name: fileColumn, 
                            selected: false,
                            examples: this.getExamples(fileColumn)
                        });
                    }
                }
            }
            this.additionalInformationVal = arr;
            this.$emit('update:additional-information', this.additionalInformationVal);
            this.commit();
        },
        computedTargetColumnValue(element){
            const { required, mapped, fileColumn, label } = element;
            const danger = (required == true) && (!mapped || !fileColumn);
            const asterisk = `<span ${danger ? `style="color: red;"` : ``}>*</span>`;
            return `
            <div>
                <div>${label}${required ? ` ${asterisk}` : ''}</div>
                ${danger ? `<div style="color: red; font-size: 12px;">This field is required</div>` : ``}
            </div>
            `
        },
        findFieldsFileColumn(field){
            
            let autoFound = false;
            let mapped = false
            let fileColumn = null;
            let examples = [];

            const found = this.fileColumns.find(element => {
                const bySlug = element == field.slug;
                let byVariant = false;
                for (let variant of field.variants) {
                    variant = variant.toLowerCase().replaceAll(' ', '_');
                    if(element == variant){
                        byVariant = true;
                    }
                }
                return bySlug || byVariant;
            });
            if(found){
                autoFound = true;
                mapped = true;
                fileColumn = found;
                examples = this.getExamples(found);
            }     
            return { autoFound, mapped, fileColumn, examples }
        },
        getExamples(fileColumn){
            let creators = copyObject(this.fileCreators);
            creators = creators.filter(element => isNotNull(element[fileColumn]));
            creators = creators.slice(0, 3);
            return creators.map(element => element[fileColumn]);
        },
        changeFileColumn(element, column, val){
            const index = this.mapperVal.findIndex(e => e.slug == element.slug);
            if(val.value == 'ignore-column'){
                // reset value
                // this value should only be defined in the buildMapper function
                // this.mapperVal[index].autoFound = false;
                this.mapperVal[index].mapped = false;
                this.mapperVal[index].fileColumn = null;
                this.mapperVal[index].examples = [];
                this.$emit('update:mapper', this.mapperVal);
            }else{
                // this value should only be defined in the buildMapper function
                // this.mapperVal[index].autoFound = true;
                this.mapperVal[index].mapped = true;
                this.mapperVal[index].fileColumn = val.value;
                this.mapperVal[index].examples = this.getExamples(val.value);
                this.$emit('update:mapper', this.mapperVal);
            }
        },
        onRowClick(element){
            const name = element.name;
            const found = this.additionalInformationVal.find(e => e.name == name);
            if(found){
                const index = this.additionalInformationVal.findIndex(e => e.name == name);
                this.additionalInformationVal[index].selected = !this.additionalInformationVal[index].selected;
                this.$emit('update:additional-information', this.additionalInformationVal);
                this.commit();
            }
        },
        selectAllAdditionalInformation(){
              this.additionalInformationVal = this.additionalInformationVal.map(element => {
                element.selected = true;
                return element;
            });
            this.$emit('update:additional-information', this.additionalInformationVal);
            this.commit();
        },
        deselectAllAdditionalInformation(){
            this.additionalInformationVal = this.additionalInformationVal.map(element => {
                element.selected = false;
                return element;
            });
            this.$emit('update:additional-information', this.additionalInformationVal);
            this.commit();
        }
    },
    computed: {
        selectedAdditionalInformation(){
            return this.additionalInformationVal.filter(element => element.selected == true);
        },
        fileColumns(){
            let arr = [];
            for (const creator of this.fileCreators) {
                for (const key in creator){
                    if (Object.hasOwnProperty.call(creator, key)) {
                        if(key && !arr.includes(key)){
                            arr.push(key)
                        }
                    }
                }
            }
            return arr;
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        > .title{
            @apply font-bold text-h2;
        }
    }
    .additional-information-table-header{
        @apply flex justify-between items-center;
        > .title{
            @apply font-bold;
        }
    }
</style>
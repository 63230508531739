<template>
    <div :class="`fixed inset-0 bg-black text-white opacity-${opacity} flex flex-col items-center justify-center z-${index}`" v-if="visible">
        <img class="h-auto w-40 mb-10" src="../assets/images/logo.svg"/>
        <div class="whitespace-no-wrap text-2xl font-semibold">{{ label }}</div>
        <div class="whitespace-no-wrap text-2xl font-semibold">please wait a moment</div>
        <img class="h-auto w-10 mt-10" src="../assets/images/spinner.gif" v-if="spinner"/>
        <div v-else class="w-80 h-2 mt-8 rounded-full bg-white bg-opacity-20">
            <div class="h-2 rounded-full bg-white" :style="`width: ${percentage}%`"></div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            default: 'Loading'
        },
        visible: {
            type: Boolean,
            default: false
        },
        spinner: {
            type: Boolean,
            default: false
        },
        index: {
            type: Number,
            default: 70
        },
        percentage: {
            type: Number,
            default: 0
        },
        opacity: {
            type: Number,
            default: 80
        }
    }
}
</script>
<template>
    <base-modal
        :title="label"
        :visible="visible"
        styles="max-height: 90vh; max-width: 1200px; overflow-y: auto;"
        no-padding
        @close="$emit('close')"
    >
        <div class="container">
            
            <div class="content">
                {{ value }}
            </div>

            <div class="footer">
                <base-button
                    label="Close"
                    type="secondary"
                    @action="$emit('close')"
                />
            </div>
        </div>
    </base-modal>
</template>
<script>
export default {
    props: {
        visible: {
            type: Boolean
        },
        value: {
            type: String,
            required: false,
            default: ''
        },
        label: {
            type: String,
            required: false,
            default: ''
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply relative;
        .content{
            @apply px-8 pb-20;
        }
        .footer{
            @apply sticky bottom-0 border-t border-gray-200 bg-tan-m px-8 py-4 flex justify-between;
        }
    }
</style>

<template>
    <base-modal
        title="Selected Brands"
        :visible="visible"
        @close="$emit('close')"
    >
        <div class="list">
            
            <div class="item" v-for="(brand, index) in value" :key="index">
                
                <img class="logo" :src="brand.logo" />
                <div class="name">{{brand.name}}</div>
                <div class="domain">{{brand.domain}}</div>
                
                <div class="icon-container">
                    <base-icon name="trash" class="cursor-pointer" @action="$emit('remove-brand', brand)" />
                </div>
            </div>

        </div>
    </base-modal>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean
        },
        value: {
            type: Array
        }
    },
    watch: {
        // if you remove all brand, the modal closes
        value(val){
            if(val && Array.isArray(val) && !val.length){
                this.$emit('close')
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .list{
        @apply flex justify-center items-start flex-wrap gap-6 mt-6;
        .item{
            // no transition, cursor-pointer, hover state, and + relative
            width: 150px;
            height: 150px;
            @apply relative bg-white border-2 border-gray-500 rounded-lg p-2;
            @apply flex flex-col justify-center items-center;
            .logo{
                width: 50px;
                height: 50px;
                object-fit: contain;
            }
            .name{
                @apply font-bold text-h5 text-center mt-2;
                width: 100%;
                white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
            }
            .domain{
                @apply text-pxs text-center;
                width: 100%;
                white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
            }
            .icon-container{
                @apply absolute top-2 right-2;
            }
        }
    }
</style>

<template>
    <base-modal :visible="visible" @close="$emit('close')" :noPadding="true" :hideHeader="true">
        <div class="container" v-if="post && post.id">
            <template v-if="!showEdit">
                <template v-if="!showReplacementPosts">
                    <div class="flex items-center">
                        <div class="media-wrapper w-1/2">
                            <template v-if="postData.network_id === 14">
                                <iframe
                                    :src="`https://www.tiktok.com/embed/${postData.post_identifier}`"
                                    scrolling="no"
                                    allow="encrypted-media;"
                                    frameborder="0"
                                    border="0"
                                    cellspacing="0"
                                    style="width: 325px; height: 620px;"
                                />
                            </template>
                            <template v-else-if="postData.is_video && postData.video">
                                <video :src="postData.video" autoplay muted loop />
                            </template>
                            <template v-else>
                                <div class="media-img"><img :src="postData.display_url" /></div>
                            </template>
                        </div>

                        <div class="media-content w-1/2 px-8 py-4">
                            <p class="text-sm">{{ postData.caption }}</p>
                            <div class="stat-wrapper mt-4 flex space-x-4">
                                <span class="flex items-center"><base-icon name="like" class="mr-1" /> {{ postData.likes | numeral("0a") }}</span>
                                <span class="flex items-center"><base-icon name="comments" class="mr-1" /> {{ postData.comments | numeral("0a") }}</span>
                                <span class="flex items-center" v-if="postData.views"
                                    ><base-icon name="views" class="mr-1" /> {{ postData.views | numeral("0a") }}</span
                                >
                            </div>

                            <div class="actions mt-12">
                                <button
                                    class="focus:outline-none rounded-full bg-tan-m hover:bg-green-m-main text-sm font-bold py-4 w-full border-2 border-gray-800"
                                    @click="openLink(postData.url)"
                                >
                                    View content
                                </button>
                            </div>

                            <div class="flex mt-2 space-x-2" v-if="canEdit">
                                <button
                                    class="w-full px-8 focus:outline-none rounded-full bg-tan-m hover:bg-green-m-main text-sm font-bold py-2 border-2 border-gray-800"
                                    @click="swapPost"
                                    v-if="!hidePostSwap"
                                >
                                    Swap Post...
                                </button>

                                <button
                                    class="w-full px-8 focus:outline-none rounded-full bg-tan-m hover:bg-green-m-main text-sm font-bold py-2 border-2 border-gray-800"
                                    @click="showEdit = true"
                                >
                                    Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="showReplacementPosts">
                    <div class="text-center px-4 py-4" v-if="loadingReplacements"><em>LOADING REPLACEMENTS...</em></div>
                    <div class="replacement-post-wrapper">
                        <div class="post-list flex overflow-x-auto mx-12 space-x-2 pt-12">
                            <div
                                :class="[
                                    'px-1 py-1 border border-gray-200 hover:border-gray-700 cursor-pointer post-wrapper',
                                    { 'border-gray-700': rPost.post_id === selectedReplacement.post_id },
                                    { 'existing-post': existingPosts.includes(rPost.post_id) },
                                ]"
                                style="min-width: 150px"
                                v-for="(rPost, i) in replacementPosts"
                                :key="i"
                                @click="selectedReplacement = rPost"
                            >
                                <img :src="rPost.image" />
                            </div>
                        </div>
                        <div class="my-12 px-12" v-if="!loadingReplacements">
                            <div v-if="!selectedReplacement.post_id"><em>Select a post above to continue...</em></div>
                            <div class="flex" v-else>
                                <div class="media-wrapper w-1/3 flex justify-center">
                                    <!-- removed video from autoplaying due to weird crash within modal -->
                                    <!-- <template v-if="selectedReplacement.type === 'video' && selectedReplacement.video">
                                        <video :src="selectedReplacement.video" style="max-height: 350px" autoplay muted loop />
                                    </template>
                                    <template v-else> -->
                                    <div class="media-img"><img :src="selectedReplacement.image" style="max-height: 350px" /></div>
                                    <!-- </template> -->
                                </div>
                                <div class="media-content w-2/3 px-8 py-4 text-sm">
                                    <p>{{ selectedReplacement.text }}</p>
                                    <div>{{ selectedReplacement.created | moment("MMM D, Y") }}</div>
                                    <div class="stat-wrapper mt-4 flex space-x-4" v-if="selectedReplacement.stat">
                                        <span class="flex items-center"
                                            ><base-icon name="like" class="mr-1" /> {{ selectedReplacement.stat.likes | numeral("0a") }}</span
                                        >
                                        <span class="flex items-center"
                                            ><base-icon name="comments" class="mr-1" /> {{ selectedReplacement.stat.comments | numeral("0a") }}</span
                                        >
                                        <span class="flex items-center" v-if="selectedReplacement.stat.views"
                                            ><base-icon name="views" class="mr-1" /> {{ selectedReplacement.stat.views | numeral("0a") }}</span
                                        >
                                    </div>
                                    <div class="flex mt-2 space-x-2">
                                        <button
                                            class="w-full px-8 focus:outline-none rounded-full bg-tan-m hover:bg-green-m-main text-sm font-bold py-2 border-2 border-gray-800"
                                            @click="completeSwap"
                                        >
                                            Complete Swap
                                        </button>

                                        <button
                                            class="w-full px-8 focus:outline-none rounded-full bg-tan-m hover:bg-green-m-main text-sm font-bold py-2 border-2 border-gray-800"
                                            @click="openLink(selectedReplacement.link)"
                                        >
                                            Open Link To Post
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <template v-else>
                <div>
                    <div class="gap-5 px-12 pt-0 pb-5 pt-8">
                        <div class="w-full flex gap-5">
                            <FormulateInput type="text" class="col-span-2 w-full" placeholder="Likes" label="Likes" v-model="editedValues.likes" />
                            <FormulateInput type="text" class="col-span-2 w-full" placeholder="Comments" label="Comments" v-model="editedValues.comments" />
                            <FormulateInput type="text" class="col-span-2 w-full" placeholder="Views" label="Views" v-model="editedValues.views" />
                        </div>
                    </div>

                    <div class="gap-5 px-12 pt-0 pb-5 pt-8">
                        <div class="w-full flex gap-5">
                            <FormulateInput type="text" class="col-span-2 w-full" placeholder="Shares" label="Shares" v-model="editedValues.shares" />
                            <FormulateInput type="text" class="col-span-2 w-full" placeholder="Saves" label="Saves" v-model="editedValues.saves" />
                        </div>
                    </div>
                </div>
                <div class="flex px-12 pt-0 pb-5 pt-8">
                    <button
                        class="px-12 focus:outline-none rounded-full bg-tan-m hover:bg-green-m-main text-sm font-bold py-4 border-2 border-gray-800 mr-4"
                        @click="editPost"
                    >
                        Save Edits
                    </button>

                    <button class="px-12 focus:outline-none rounded-full bg-tan-m text-sm font-bold py-4 text-red-500" @click="showEdit = false">
                        Cancel
                    </button>
                </div>
            </template>
        </div>

        <!-- <div class="footer">
            <base-button label="Close" @action="$emit('close')" type="secondary" size="md" />
        </div> -->
    </base-modal>
</template>

<script>
export default {
    props: ["visible", "post", "existingPosts", "hidePostSwap", "canEdit"],

    data() {
        return {
            showReplacementPosts: false,
            loadingReplacements: false,
            replacementPosts: [],
            selectedReplacement: {},

            showEdit: false,
            postData: {},
            editedValues: {},
        };
    },

    watch: {
        visible(val) {
            if (!val) {
                this.showReplacementPosts = false;
                this.replacementPosts = [];
                this.selectedReplacement = {};
            }
        },
        post() {
            this.resetEditedValues();
        },
    },

    mounted() {
        this.postData = this.post;
    },

    methods: {
        openLink(url) {
            window.open(url);
        },
        async swapPost() {
            this.loadingReplacements = true;
            this.showReplacementPosts = true;
            const { user_id, network_id } = this.post;
            const { data } = await this.$http.get(`/api/reports/v2/${this.$route.params.id}/${user_id}/media?network_id=${network_id}`);
            this.replacementPosts = data;
            this.loadingReplacements = false;
        },
        async editPost() {
            try {
                const { data } = await this.$http.post(`/api/reports/v2/${this.$route.params.id}/${this.post.user_id}/media/${this.post.id}`, {
                    values: { ...this.editedValues },
                });
                this.$emit("edited-post", data);
                this.postData.likes = data.likes || 0;
                this.postData.comments = data.comments || 0;
                this.postData.views = data.views || 0;
                this.postData.shares = data.shares || 0;
                this.postData.saves = data.saves || 0;
                this.showEdit = false;
            } catch (e) {
                console.log("error updating post", this.post.id, this.editedValues);
            }
        },
        async completeSwap() {
            try {
                const payload = { ...this.selectedReplacement, network_id: this.post.network_id, audience_id: this.post.audience_id };
                const postId = this.post.original_post_id || this.post.id;
                const { data } = await this.$http.post(`/api/reports/v2/${this.$route.params.id}/${this.post.user_id}/media/${postId}/swap`, payload);
                this.$emit("swapped-post", data);
            } catch (e) {
                console.log("error completing swap", e);
            }
        },

        resetEditedValues() {
            this.editedValues = {
                likes: this.post.likes || 0,
                comments: this.post.comments || 0,
                views: this.post.views || 0,
                shares: this.post.shares || 0,
                saves: this.post.saves || 0,
            };

            this.postData = this.post;
        },
    },
};
</script>

<style lang="scss">
.replacement-post-wrapper {
    .post-wrapper {
        &.existing-post {
            @apply bg-black p-0 relative;
            img {
                opacity: 0.35;
            }
            &:after {
                position: absolute;
                bottom: 5px;
                left: 2px;
                content: "POST USED";
                color: #fff;
                text-align: center;
                width: 100%;
            }
        }
    }
}
</style>

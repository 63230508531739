<template>
    <div class="relative">
        <div class="sticky top-0 left-0 right-0">

            <FormulateInput
                type="text"
                :placeholder="placeholder"
                :input-class="['searchbar']"
                v-model="searchTerm"
                autocomplete="off"
                :disabled="onlySelected"
                v-debounce="(inputValue, e) => $emit('search', inputValue)"
            />
        </div>

        <div v-if="onlySelected" class="overview" :key="`overview-${key}`">
            <div
                v-for="(option, index) in v" 
                :key="index"
                class="list"
            >
                <div class="option">
                    <div class="slot-container">
                        <slot :option="option" />
                    </div>
                    <base-icon 
                        name="close"
                        class="cursor-pointer"
                        @action="toggle(option)" 
                    />
                </div>
            </div>
        </div>

       <template v-else>
            <div v-if="!loading" class="option-list" :key="`options-${key}`">
                <div v-if="!options.length" class="empty-state">No results found</div>
                <div
                    v-for="(option, index) in options" 
                    :key="index"
                    @click.stop="toggle(option)"
                    :class="`option ${isActive(option) ? `active` : ``}`"
                >
                    <slot :option="option" />
                </div>
            </div>
       </template>

    </div>
</template>

<script>
export default {
    props: ['placeholder', 'options', 'value', 'loading', 'onlySelected'],
    data(){
        return {
            searchTerm: '',
            v: this.value,
            key: 0
        }
    },
    watch: {
        value(val){
            this.v = val
            if(this.onlySelected && !val.length){
                this.$emit('toggle-only-selected', false)
            }
        }
    },
    methods: {
        toggle(value){
            let temp = this.v
            if(!this.isActive(value)){
                temp.push(value) 
            }else{
                temp = temp.filter(element => element.value != value.value)
            }
            this.emit(temp)
        },
        isActive(option){
            const found = this.v.find(element => element.value == option.value)
            return found ? true : false
        },
        emit(value){
            this.v = value
            this.$emit('input', value)
            this.key++
        }
    }
}
</script>
<style scoped lang="scss">
    .option-list{
        @apply w-full overflow-auto flex flex-col;
        height: calc(100vh - 360px);
        .empty-state{
            @apply px-6 py-6 text-center;
        }
        .option{
            @apply px-4 py-2 cursor-pointer transition-opacity duration-200;
            &:hover{
                @apply opacity-50;
            }
            &.active{
                @apply bg-purple-m-main text-white font-bold;
            }
        }
    }
    .overview{
        @apply mt-8 flex flex-col gap-y-2;
        .list{
            @apply px-4 py-2 text-white bg-purple-m-main rounded-lg;
            .option{
                @apply flex justify-between items-center;
                .slot-container{
                    @apply flex flex-col gap-y-1;
                }
            }
        }
    }
</style>
<template>
    <div class="flex gap-x-8 items-center">

        <div class="flex justify-between">
            <div class="text-h2 font-bold text-purple-m-secondary">
                {{title}} {{ count ? `(${count})` : `` }}
            </div>    
        </div>

        <base-icon v-if="loading" name="loader" />

    </div>
</template>

<script>
export default {
    props: ['title', 'count', 'loading']
}
</script>

<style lang="scss" scoped>
    
</style>
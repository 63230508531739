
<template>
    <div class="code-container">
        <Paragraph v-if="title" class="-mb-4">{{title}}</Paragraph>
        
        <div class="collapse-button-container">
            <base-button
                type="label"
                @action="collapsed = !collapsed"
                :icon="collapsed ? 'chevron-up' : 'chevron-down'"
                iconColor="#ffffff"
            />
        </div>

        <pre 
            :class="`language-${language} ${collapsed ? 'cursor-pointer' : ''}`" 
            @click="() => collapsed ? collapsed = !collapsed : null"
            v-highlightjs="collapsed ? 'Expand to view code snippet' : curated"
        >
            <code :class="`language-${language}`"></code>
        </pre>
    </div>
</template>
<script>
import Paragraph from './Paragraph.vue'
export default {
    components: {
        Paragraph
    },
    props: {
        language: {
            type: String,
            default: 'plaintext'
        },
        code: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: false
        },
        initialState: {
            type: Boolean,
            required: false
        }
    },
    data(){
        return {
            collapsed: false
        }
    },
    mounted(){
        if(this.initialState) this.collapsed = this.initialState
    },
    computed: {
        curated(){
            // Replace single quotes: str.replace(`'`,`&#39;`)
            // Replace double quotes: str.replace(`"`,`&#34;` || `&quot;`) 
            return this.code
        }
    }
}
</script>
<style lang="scss" scoped>
    .code-container{
        @apply relative;
        .collapse-button-container{
            @apply absolute top-12 right-4;
        }
    }
</style>
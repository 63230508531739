<template>
    <div
        v-if="attachment"
        @click="$emit('on-click', attachment, contentType, audioAttachment)" 
        class="version-media-item"
    >
        <!-- icon -->
        <div class="icon-wrapper">
            <base-icon 
                class="text-purple-m-main"
                :size="5" 
                :name="contentType" 
            />
            <div
                v-if="audioAttachment"
                class="absolute rounded-full bg-black h-6 w-6 flex justify-center items-center" 
                style="bottom: -6px; right: -6px;"
            >
                <base-icon
                    class="text-white"
                    :size="3"
                    name="audio"
                />
            </div>
        </div>
    
        <!-- content -->
        <div class="flex flex-col flex-grow flex-shrink">
            <div class="flex flex-row justify-between">
                <!-- file name -->
                <div class="flex-grow flex-shrink w-80">
                    <div class="truncate font-semibold text-lg text-black" >
                        {{ attachment.name }}
                    </div>
                    <div class="text-ps text-gray-m-disable font-normal">
                        {{ computedFileSize }}
                    </div>
                </div>                        
            </div>
        </div>

        <!-- preview -->
        
        <div v-if="contentType == 'video'" style="height: 42.75px" class="w-full flex justify-end items-center">
            <video
                 style="height: 100%"
            >
                <source :src="attachment.signedUrl" :type="computedMimetype">
                Your browser does not support the video tag.
            </video>
        </div>

        <div v-if="contentType == 'image'" style="height: 42.75px" class="w-full h-full flex justify-end items-center">
            <img
                style="height: 100%"
                :src="attachment.signedUrl"
            />
        </div>

        <template v-if="contentType == 'link'">
            <div class="link">
                {{attachment.url}}
            </div>
        </template>

    </div>
</template>

<script>
    import { getContentType } from '../../lib/files'
    import formatBytes from '../../lib/files'
    import PdfPreview from '../../components/PdfPreview.vue'
    export default {
        props: ['attachment', 'audioAttachment'],
        components: {
            PdfPreview
        },
        computed: {
            computedMimetype(){
                return this.attachment.mimetype == 'video/quicktime' ? 'video/mp4' : this.attachment.mimetype
            },
            computedFileSize(){
                return this.attachment.size ? formatBytes(this.attachment.size) : '-'
            },
            contentType(){
                return getContentType(this.attachment.mimetype)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .version-media-item{
        @apply overflow-hidden border-2 border-gray-300 rounded-md transition duration-150 ease-in-out relative cursor-pointer p-4 flex justify-between items-center gap-x-4;
        &:hover{
            @apply bg-gray-50;
        }
        &:focus{
            @apply outline-none bg-gray-50;
        }
        .icon-wrapper{
            @apply relative flex items-center justify-center flex-grow-0 flex-shrink-0 rounded-full bg-white w-10 h-10 border border-gray-m-light
        }
        .link{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
</style>
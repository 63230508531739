<template>
    <div class="list">
        <div
            class="item"
            v-for="(gender, index) in options" 
            :key="index"
            @click="toggleGender(gender)"
        >
            <div class="box" :class="{ 'selected': isSelected(gender) }">
                <base-icon :name="gender.icon" />
            </div>
            <div class="name">{{gender.name}}</div>
        </div>
    </div>
</template>
<script>
import { genderOptions } from '@/lib/genders'
export default {
    props: ['value'],
    data(){
        return {
            val: this.value,
            options: genderOptions
        }
    },
    watch: {
        value(val){
            this.val = val
        }
    },
    methods: {
        isSelected(gender){
            if(!this.val) return false;
            return this.val === gender.id;
        },
        toggleGender(gender){
            this.val = gender.id
            this.$emit('input', this.val)
        }
    }
}
</script>
<style lang="scss" scoped>
    .list{
        @apply flex flex-row flex-wrap justify-center gap-x-6;
        .item{
            @apply flex flex-col gap-y-4 justify-start items-center;
            @apply cursor-pointer;
            .box{
                @apply relative;
                @apply flex justify-center items-center;
                @apply border border-2 border-gray-300 rounded-lg;
                @apply transition-colors duration-200;
                width: 100px;
                height: 100px;
                &.selected{
                    @apply border-purple-m-main;
                }
            }
            &:hover{
                .box{
                    @apply bg-gray-100;
                }
            }
            .name{
                @apply flex justify-center items-center;
                @apply font-bold;
            }
        }
    }
</style>

<script>
// Load speperate modules
import { Pie } from 'vue-chartjs';

export default {
    extends: Pie,
    // mixins: [mixins.reactiveProp],
    props: ["chartData", "options"],

    mounted () {
        this.renderChart(this.chartData, this.options)
    }
};
</script>
<style lang="sass">

</style>
<template>
    <div class="business-info">

        <base-icon v-if="loading" name="loader"/>   
        
        <template v-if="company.fb_business_id">
            <div class="name">{{ businessInfo.name }}</div>
        </template>
        <!-- There is no business set for this company -->
        <template v-else>

            <div class="text">
                No facebook business linked to this company
            </div>

            <!-- If user has logged in with facebook we can get his companies -->
            <base-button
                v-if="fbLogin"
                label="Set up"
                @action="selectFbBusinessModalVisible = true"
                class="font-bold"
                size="lg"
            />

            <div v-else>
                You must log in with facebook first!
                <!-- todo: provide a link to account linking -->
            </div>

            <select-fb-business-modal
                v-if="fbLogin && company.name"
                :visible="selectFbBusinessModalVisible"
                :facebook="fbLogin"
                :companyName="company.name"
                @close="selectFbBusinessModalVisible = false"
                @confirm="fbBusinessId => submit(fbBusinessId)"
            />

        </template>

    </div>
</template>

<script>
import SelectFbBusinessModal from '../SelectFbBusinessModal.vue'
import { mapGetters } from 'vuex';
export default {
    components: {
        SelectFbBusinessModal
    },
    data(){
        return {
            businessInfo: {},
            loading: false,
            selectFbBusinessModalVisible: false
        }
    },
    computed: {
        ...mapGetters(['company', 'fbLogin']),
    },
    mounted(){
        this.getBusinessInfo()
    },
    methods: {
        async getBusinessInfo(){
            this.loading = true
            this.$http.get(`/api/facebook?entity_id=${this.company.fb_business_id}`).then(async (res) => {
                if(res.data){
                    this.businessInfo = res.data
                }
            }).catch((err) => {
                console.log('getBusinessInfo error', err)
            }).finally(() => {
                this.loading = false
            })
        },
        submit(fbBusinessId){
            this.loading = true
            this.$http.put('/api/companies', {
                fb_business_id: fbBusinessId
            }).then(async (res) => {
                if(res.data){                     
                    this.selectFbBusinessModalVisible = false
                    this.$notify({title: 'Facebook Business set successfully', text: '', type: 'success'})
                }
            }).catch((err) => {
                console.log('submit error', err)
                this.$notify({ title: 'Something went wrong', text: 'Try again later', type: 'warn' });
            }).finally(() => {
                this.loading = false
            })
        } 
    }
    
}
</script>

<style lang="scss" scoped>
  .name{
    @apply font-bold text-h4 py-4;
  }
  .text{
    @apply text-h5 font-bold mb-4;
  }
</style>
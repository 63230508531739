<template>
    <base-modal
        :visible="visible"
        :title="title"
        @close="$emit('close')"
    >
        <div class="relative">
            <facebook-token-info
                subject="me"
                :user-id="me.id"
                @onFacebookLoginSuccess="data => $emit('onFacebookLoginSuccess', data)"
            />
        </div>
    </base-modal>
</template>

<script>
import FacebookTokenInfo from '@/components/FacebookTokenInfo'
import { mapGetters } from 'vuex'
export default {
    props: ['visible', 'title'],
    components: {
        FacebookTokenInfo
    },
    data() {
        return {
          data: ''
        }
    },
    computed: {
        ...mapGetters(['me']),
    }
}
</script>
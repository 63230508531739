<template>
    <div class="overlay" v-if="image">
        <div class="wrapper" @click="$emit('close')">
            <div class="header">
                <base-icon class="icon" :size="10" @action="$emit('close')" name="close" />
            </div>
            <div class="content">
                <img style="height: 100%" :src="image" />
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['image']
    }
</script>
<style lang="scss" scoped>
    .overlay {
        @apply inset-0 fixed bg-black overflow-y-auto;
        .wrapper {
            @apply relative w-screen h-screen py-16 px-36;
            .header {
                @apply absolute right-4 top-4;
                .icon{
                    @apply text-white cursor-pointer;
                    &:hover{
                        @apply opacity-50;
                    }
                }
            }
            .content {
                @apply w-full h-full flex justify-center items-center overflow-hidden;
            }
        }
    }
</style>
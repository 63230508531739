<template>
    <div :class="[wrapperClass, containerClass]">
        <div v-if="!loading">
            <div v-if="!hideIcon" class="flex gap-2">
                <base-icon v-if="icon" :name="icon" :fill="iconFill" :class="{'cursor-pointer': !!iconLink}" :size="iconSize" @action="runIcon"/>
                <div v-else class="h-8 flex items-center">
                    <doughnut v-if="showChart" :chart-data="chartData" :options="chartOptions" :class="chartClass"/>
                    <h3 class="text-base text-gray-m-disable font-normal uppercase" v-if="!!chartValue">
                        {{ chartValue }}
                    </h3>
                </div>
                <div v-if="descriptionValue" class="text-sm">{{ descriptionValue }}</div>
            </div>
            <div v-else :class="`h-${iconSize}`"></div>
        </div>
        <skeleton v-else :width="6" :height="6"/>
        <div class="flex gap-6 items-center">
            <div v-if="leftIcon" :class="iconClass">
                <base-icon :name="leftIcon" :fill="iconFill" :class="{'cursor-pointer': !!iconLink}" :size="iconSize" @action="runIcon"/>
            </div>
            <div :class="{ 'border-r border-gray-m-light' : showBorder }" v-if="!loading">
                <h1 :class="['uppercase', 'text-left', semiBold ? 'font-semibold' : 'font-bold', titleSize]">
                    {{ value }}
                </h1>
                <div class="flex flex-row">
                    <template v-if="slotPosition && slotPosition === 'label'">
                        <slot />
                    </template>
                    <h3 :class="[uppercaseClass, truncateClass, colorClass, labelClass]">{{ label }}</h3>
                </div>
            </div>
            <div :class="[{ 'border-r border-gray' : showBorder }]" v-else>
                <skeleton :width="12" :height="10" :no-margin="true"/>
                <skeleton :height="6" :no-margin="true" class="mt-2"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showBorder: {
            type: Boolean,
            default: true,
        },
        label: {
            type: String,
            default: '',
        },
        value: {
            default: '0'
        },
        hideIcon: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String
        },
        iconLink: {
            type: String
        },
        iconFill: {
            type: Boolean,
            default: true
        },
        descriptionValue: {},
        iconSize: {
            type: Number,
            default: 5,
        },
        wrapperClass: {
            type: String,
            default: 'w-1/4'
        },
        containerClass: {
            type: String,
            default: 'pl-4'
        },
        labelClass: {
            type: String,
            default: 'text-sm',
        },
        colorClass: {
            type: String,
            default: 'text-gray-m-disable'
        },
        uppercase: {
            type: Boolean,
            default: true
        },
        truncate: {
            type: Boolean,
            default: false
        },
        showChart: {
            type: Boolean,
            default: false
        },
        chartData: {
            type: Object,
            default: function () {
                return {};
            }
        },
        chartOptions: {
            type: Object,
            default: function () {
                return {};
            }
        },
        chartClass: {
            type: String,
            default: 'h-6 w-6'
        },
        chartValue: {
            type: String,
            default: null,
        },
        titleSize: {
            type: String,
            default: 'text-2xl',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        semiBold: {
            type: Boolean,
            default: true,
        },
        slotPosition: {
            type: String
        },
        leftIcon: {},
        iconClass: {
            type: String,
            default: ''
        }
    },

    computed: {
        uppercaseClass () {
            return this.uppercase ? 'uppercase' : '';
        },
        truncateClass () {
            return this.truncate ? 'truncate' : '';
        }
    },

    methods: {
        runIcon() {
            if (this.iconLink) {
                window.open(this.iconLink, '_blank');
            }
        }
    }
}
</script>
<template>
    <FormulateForm name="form" @submit="submitForm" :key="formKey">

        <div class="form-wrapper">

            <div class="form-container" :class="{'inline': inline}">

                <div class="general-info" v-if="step == 1">

                    <div class="avatar-wrapper" v-if="!inline">
                        <img class="loader" v-if="uploadingFile" src="../assets/images/spinner.gif" />
                        <div class="avatar-container">
                            <img :src="avatarUrl" class="avatar" @click="$refs.file.click()"/>
                            <div class="camera-icon">
                                <base-icon name="camera" @action="$refs.file.click()" background="bg-purple-m-main" border class="cursor-pointer text-green-m-main" :size="10" :icon-size="5"/>
                            </div>
                        </div>
                        <input class="invisible" type="file" ref="file" accept="image/*" @change="onFileChange">
                    </div>

                    <template v-if="existingUser">
                        <div role="alert" class="alert" v-if="existingUser.belongsToCurrentCompany">
                            <p>
                                <span class="font-bold">This user already exists in the company.</span>
                                <!-- is creator and there is a project id -->
                                <template v-if="existingUser.currentCompanyRole == 13 && projectId && (typeof projectId !== 'object')">
                                    <span> ¿ Do you want to add it to the project ? </span>
                                    <base-button
                                        label="Add creator"
                                        icon="plus"
                                        class="mt-2"
                                        size="lg"
                                        @action="$emit('created-influencer', existingUser, false)"
                                    />
                                </template>
                            </p>
                        </div>
                        <div v-else class="info" role="alert">
                            <base-icon name="info-tailwind" />
                            <p class="ml-2 font-bold">This user belongs to another company.</p>
                        </div>
                    </template>

                    <FormulateInput
                        type="text"
                        label="First name"
                        class="w-full"
                        placeholder="Jane"
                        validation="required"
                        validation-name="First name"
                        v-model="formValues.first_name"
                        :label-class="['font-bold', 'required', 'label-inside']"
                    />
                    <FormulateInput
                        type="text"
                        label="Last name"
                        class="w-full"
                        placeholder="Smith"
                        validation="required"
                        validation-name="Last name"
                        v-model="formValues.last_name"
                        :label-class="['font-bold', 'required', 'label-inside']"
                    />
                    <div class="email-wrapper">
                        <FormulateInput
                            type="email"
                            label="Email"
                            class="w-full"
                            :placeholder="autogenerateEmail ? 'ignored@creatorsaurus.com' : 'jane.smith@gmail.com'"
                            autocomplete="null"
                            validation="required|email"
                            validation-name="Email"
                            :disabled="autogenerateEmail == true"
                            v-model="formValues.email"
                            v-debounce="handleDebounce"
                            @blur="onEmailBlur()"
                            @focus="getRecommendedUsers('')"
                            @input="handleInput()"
                            :label-class="['font-bold', 'required', 'label-inside']"
                        />
                        <div class="absolute" style="top: 12px; left: 58px;">
                            <base-icon v-if="findingUser" name="loader"/>
                        </div>
                        <div class="recommended-users-list" v-if="emailFocus && recommendedUsers.length">
                            <div class="header">
                                <div>Recommended creators</div>
                                <base-icon @action="recommendedUsers = []" name="close" class="cursor-pointer" :size="4"/>
                            </div>
                            <div 
                                class="user" 
                                v-for="(user, index) in recommendedUsers" 
                                :key="index"
                                @click.prevent="onRecommendedUserSelect(user)"
                            >
                                <avatar-list :users="[user]" />
                            </div>
                        </div>
                    </div>
                    <div class="autogenerate-email">
                        <div v-if="autogenerateEmail == false" class="label">¿Don't have the creator's email? create without email.</div>
                        <base-button label="Ignore email" class="h-8 text-pxs" v-if="autogenerateEmail == false" @action="formValues.email = ''; autogenerateEmail = true" />
                        <base-button label="Use email" class="h-8 text-pxs" v-if="autogenerateEmail == true" @action="autogenerateEmail = false" />
                    </div>
                    <FormulateInput
                        type="text"
                        label="Phone (Optional)"
                        class="col-span-1"
                        placeholder="123-456-7890"
                        v-model="formValues.phone"
                        @input="formatPhone('formValues')"
                        :label-class="['font-bold']"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        validation="max:15,length|min:0"
                        error-behavior="live"
                        :validation-messages="{max: 'Phone must be less than 15 numbers.'}"
                    />
                    
                    <!-- creator specific fields -->
                    <custom-pills
                        :key="`tags-${tagsKey}`"
                        class="col-span-1"
                        label="Tags"
                        required-label="A tag"
                        placeholder="add tags"
                        :options="tags"
                        allow-create
                        create-label="Create new tag"
                        model-name="Tag"
                        :create-route="`/api/tags`"
                        :multiple="true"
                        size="auto"
                        :required="!noTagRequired"
                        v-model="selectedTags"
                        @finish-create="$emit('reload-tags')"
                    />
                    
                    <div class="w-full col-span-2 border-t pt-4" v-if="inline">
                        <network-select
                            :values="networkValues" 
                            show-custom 
                            cols="grid-cols-1" 
                            :container-class="`overflow-y-auto ${!inline ? `h-96 max-h-96` : ``}`" 
                            custom-label
                            @toggle-search="(val) => searchingHandle = val"
                            @users-exists="handleExistingUsername"
                        />
                    </div>

                    <FormulateInput
                        type="checkbox"
                        v-model="addingAdditionalContact"
                        input-class="cursor-pointer"
                        label-class="ml-3 cursor-pointer"
                        label="Add additional contact (agents)"
                        class="w-full col-span-2"
                    />

                </div>

                <div class="additional-contact" v-if="step == 2 || (inline && addingAdditionalContact)">

                    <FormulateInput
                        type="text"
                        label="First name"
                        class="w-full"
                        placeholder="Jane"
                        validation="required"
                        validation-name="First name"
                        v-model="secondaryContact.first_name"
                        :label-class="['font-bold']"
                    />
                    <FormulateInput
                        type="text"
                        label="Last name"
                        class="w-full"
                        placeholder="Smith"
                        validation="required"
                        validation-name="Last name"
                        v-model="secondaryContact.last_name"
                        :label-class="['font-bold']"
                    />
                    <FormulateInput
                        type="email"
                        label="Email"
                        placeholder="Enter email"
                        validation="required"
                        validation-name="Email"
                        v-model="secondaryContact.email"
                        @input="onSecondaryContactEmailInput"
                        v-debounce="findSecondaryContactByEmail"
                    />
                    <FormulateInput
                        type="text"
                        label="Phone (Optional)"
                        class="w-full"
                        placeholder="123-456-7890"
                        @input="formatPhone('secondaryContact')"
                        v-model="secondaryContact.phone"
                        :label-class="['font-bold']"
                        inputmode="numeric"
                        pattern="[0-9]*"
                    />
                  
                    <entity-select
                        :selectedEntity="selectedEntity"
                        @selected="(entity) => selectedEntity = entity"
                        :overrideTerm="entitySelectTerm"
                    />

                </div>

                <div v-if="step == 3 && !inline">
                    <network-select
                        :values="networkValues" 
                        show-custom 
                        cols="grid-cols-1" 
                        :container-class="`overflow-y-auto ${!inline ? `h-96 max-h-96` : ``}`" 
                        custom-label
                        @toggle-search="(val) => searchingHandle = val"
                        @users-exists="handleExistingUsername"
                    />
                </div>

            </div>

            <!-- not inline footer -->
            <div class="footer" v-if="!inline">
                <div class="left">
                    <FormulateInput
                        type="checkbox"
                        v-model="sendInvite"
                        input-class="cursor-pointer"
                        label-class="ml-3 cursor-pointer"
                        label="Send invite to create account"
                        class="w-full"
                    />
                </div>
                <div class="right">
                    <base-button
                        :label="nextStepLabel"
                        type="main"
                        secondary-icon="arrow-right"
                        :size="step == 1 ? 'md' : 'lg'"
                        @action="next"
                        :disabled="!validateForm || loading || submitting || searchingHandle || (step == 3 && userExists)"
                        v-tooltip="{
                            content: 
                                (loading || submitting || searchingHandle) ? 'Loading...' :
                                !validateForm ? `Check the form fields and try again.` :
                                userExists ? `A user with that email or handle already exists.` : ``,
                            classes: 'v-tooltip-black' 
                        }"
                    />
                    <base-button
                        v-if="step > 1"
                        label="Back"
                        type="main"
                        theme="back"
                        @action="onBackClick"
                        :disabled="loading || submitting"
                        icon="arrow-left"
                    />
                </div>
            </div>
            <!-- inline footer -->
            <div class="inline-footer" v-else>
                <base-button label="Cancel" type="main" theme="dark" @action="cancel"/>
                <base-button
                    label="Save"
                    type="main"
                    size="md"
                    @action="submitForm"
                    :disabled="!validateForm || loading || submitting || searchingHandle || userExists"
                    v-tooltip="{ 
                        content: 
                            (loading || submitting || searchingHandle) ? 'Loading...' :
                            !validateForm ? `Check the form fields and try again.` :
                            userExists ? `A user with that email or handle already exists.` : ``,
                        classes: 'v-tooltip-black' 
                    }"
                />
            </div>
        </div>

        <base-modal 
            title="Creator handle already exists" 
            @close="existingHandleModalVisible = !existingHandleModalVisible" 
            hide-close-button 
            :visible="existingHandleUser && existingHandleModalVisible && userExists && !assigningToProject"
        >
            <div class="flex flex-col gap-4">
                <div v-if="existingHandleUser !== null" class="flex flex-col gap-2">
                    <p>The username belongs to the following user. Do you want to attach this user instead?</p>
                    <div>
                        <div class="flex gap-2 items-center">
                            <avatar :user="existingHandleUser"/>
                            <div class="flex flex-col gap-0">
                                <div class="font-semibold text-lg text-black">{{ existingHandleUser.first_name }} {{ existingHandleUser.last_name }}</div>
                                <div>{{ existingHandleUser.email }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <p v-else>This username belongs to an existing user. Do you want to select that user instead?</p>
                <div class="mt-4 flex justify-between">
                    <base-button @action="existingHandleModalVisible = !existingHandleModalVisible" type="secondary" size="md" label="Cancel"/>
                    <base-button @action="acceptExisting" label="Accept"/>
                </div>
            </div>
        </base-modal>

        <!-- bookmarklet: uncomment -->
        <!-- <tiktok-bookmarklet :open="adviseTiktok" @close="adviseTiktok = false" @confirm="processCreator(currentNetworkValues)"/> -->
        <advanced-loader :index="100" :visible="submitting" label="We are saving the creator" spinner />
    </FormulateForm>
</template>
<script>
import NetworkSelect from './NetworkSelect.vue';
import EntitySelect from './EntitySelect.vue';
import DefaultUser from './../assets/images/default-user.png';
import AvatarList from './AvatarList.vue';
import { validateEmail } from '../lib/strings';
import { customNetwork, errorMessage } from "@/common";
import { mapGetters } from 'vuex';
import { notifyCatchError } from '../common';
// bookmarklet: uncomment
// import TiktokBookmarklet from '@/views/ReportCreate/TiktokBookmarklet';
// import { scrapperFn } from '../lib/scrapperDom';

export default {
    props: {
        projectId: {},
        tags: {},
        // Show all in one line, not in steps
        inline: {
            type: Boolean,
            default: false
        },
        // Force IG, Tiktok or Youtube platforms to be added.
        forceNetworks: {
            type: Boolean,
            default: false
        },
        // Tags not required to be added
        noTagRequired: {
            type: Boolean,
            default: false
        },
        standalone: {
            type: Boolean,
            default: false
        },
        forcePrompt: {
            type: Boolean,
            default: false
        },
        assigningToProject: {
            type: Boolean,
            default: false
        }
    },
    components: {
        NetworkSelect,
        EntitySelect,
        AvatarList,
        // bookmarklet: uncomment
        // TiktokBookmarklet
    },
    data() {
        return {
            
            step: 1,
            
            avatar: null, // temp file
            formValues: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                avatar: '', // from db
            },
            autogenerateEmail: false,
            
            recommendedUsers: [],
            existingUser: null, // if a user already exists in the DB, from any company.

            selectedTags: [],
            tagsKey: false,
            searchingHandle: false,

            addingAdditionalContact: false,
            secondaryContact: {
                first_name: '',
                last_name: '',
                email: '',
                phone: ''
            },
            selectedEntity: null,
            entitySelectTerm: '',

            networkValues: {
                instagram_handle: '',
                tiktok_handle: '',
                youtube_handle: '',
                pinterest_handle: {...customNetwork(4, false)},
                facebook_handle: {...customNetwork(6, false)},
                twitter_handle: {...customNetwork(7, false)},
                linkedin_handle: {...customNetwork(16, false)},
                custom_handle: {...customNetwork(17, false)}
            },

            loading: false,
            submitting: false,
            findingUser: false,
            uploadingFile: false,
            sendInvite: false,
            userExists: false,
            inProject: false,
            existingHandleModalVisible: false,
            emailFocus: false,

            existingHandleUser: null,

            formKey: 0,

            customProjectId: null,
            
            // bookmarklet: uncomment
            // adviseTiktok: false,
            // currentNetworkValues: {},
            // processedCustomNetworks: [],
        }
    },

    created() {
        this.getProjectByRoute();
    },

    watch: {
        // bookmarklet: uncomment
        // adviseTiktok: function (val) {
        //     if (!val) {
        //         this.currentNetworkValues = {};
        //     }
        // },
        stepTitle: function (val) {
            this.$emit('change-title', val);
        },
        formValues: {
            deep: true,
            handler: function () {
                this.formatPhone('formValues');
                this.formatPhone('secondaryContact');
                this.checkFilling()
            }
        },
        selectedTags: {
            deep: true,
            handler: function () {
                this.checkFilling()
            }
        },
        networkValues: {
            deep: true,
            handler: function () {
                this.checkFilling()
            }
        }
    },
    
    methods: {
        async getProjectByRoute() {
            let projectId = parseInt(this.projectId);
            if (isNaN(projectId) && this.$route.query && this.$route.params && !!this.$route.params.projectId) {
                let param = this.$route.params.projectId;
                if (param !== '') {
                    try {
                        let { data } = await this.$http.get(`/api/projects/${param}/single`);
                        if (data) {
                            this.customProjectId = data.id;
                        }
                    } catch (e) {
                        console.log('get project error', e);
                    }
                }
            }
        },
        getProjectId() {
            let projectId = parseInt(this.projectId);

            if (isNaN(projectId) && !isNaN(parseInt(this.customProjectId))) {
                projectId = parseInt(this.customProjectId);
            }

            return projectId;
        },
        handleExistingUsername(users) {
            if (users && Array.isArray(users) && users.length) {
                let projectId = this.getProjectId();

                if (!isNaN(projectId)) {
                    let inProject = users.filter(u => u.user_projects && u.user_projects.length).find(u => {
                        let projects = u.user_projects;
                        let project = projects.find(p => p.project_id === projectId);
                        return !!project;
                    });

                    if (inProject) {
                        this.inProject = true;
                        this.$notify({ title: 'Warning', text: 'This creator is already attached to this project', type: 'warn' });
                    }
                    this.existingHandleUser = users[0];
                    this.existingHandleModalVisible = true;
                } else {

                    if (this.$listeners && this.$listeners['toggle-existent']) {
                        if (this.forcePrompt) {
                            this.existingHandleModalVisible = true;
                            this.existingHandleUser = users[0];
                        } else {
                            this.$emit('toggle-existent', users[0]);
                        }
                    } else {
                        // check if the creator belongs to the current company
                        const foundUser = users.find(element => {
                            const foundCompanyUser = element.user_companies.find(e => {
                                return e.company_id == this.company.id
                            })
                            return !!foundCompanyUser
                        });
                        if(!foundUser){
                            this.$swal.fire({
                                title: `This creator already exists but its not part of the ${this.company.name} company.`,
                                text: `Do you want to add this creator to the ${this.company.name} company ?`,
                                icon: 'warning',
                                iconColor: '#0E092C',
                                showCancelButton: true,
                                confirmButtonText: 'Add creator',
                                reverseButtons: true,
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    const foundCreator = users.find(element => {
                                        const foundRole = element.roles.find(e => {
                                            return e.id == 13 // creator
                                        })
                                        return !!foundRole
                                    })
                                    if(foundCreator){
                                        this.$http.post(`/api/users/${foundCreator.id}/add-creator-to-company`).then(({data}) => {
                                            this.$notify({ title: 'Creator added to company', text: '', type: 'success' });
                                        }).catch(err => {
                                            notifyCatchError(err, this.$notify);
                                        });
                                    }
                                }
                            });
                        }else{
                            // check if user has roles, if they dont, show a warning.
                            if(!foundUser.roles.length){
                                this.$swal.fire({
                                    title: `Creator belongs to the company but does not have a role`,
                                    text: 'Add the creator to the company to set their role',
                                    icon: 'warning',
                                    iconColor: '#0E092C',
                                    showCancelButton: true,
                                    confirmButtonText: 'Add creator',
                                    reverseButtons: true,
                                }).then((result) => {
                                    if (result.isConfirmed) {   
                                        this.$http.post(`/api/users/${foundUser.id}/add-creator-to-company`).then(({data}) => {
                                            this.$notify({ title: 'Creator added to company', text: '', type: 'success' });
                                        }).catch(err => {
                                            notifyCatchError(err, this.$notify);
                                        });
                                    }
                                });
                            }
                            this.$notify({ title: 'Warning', text: `A creator in this company is already using this handle. (ID: ${foundUser.id})`, type: 'warn' }); 
                        }
                    }

                }

                this.userExists = true;
            } else {
                this.userExists = false;
                this.existingHandleModalVisible = false;
                this.existingHandleUser = null;
                this.inProject = false;
            }
        },
        async acceptExisting() {
            let projectId = this.getProjectId();
            if (this.standalone && this.existingHandleUser !== null && !isNaN(projectId)) {
                let user = {id: this.existingHandleUser.id};
                const { data } = await this.$http.post(`/api/projects/${projectId}/update-users`, {users: [user]});
                if (data) {
                    this.$notify({ title: 'Creator assigned successfully', text: '', type: 'success' });
                    this.$emit('created-influencer', user, false);
                    this.clearForm();
                } else {
                    this.$notify({ title: 'Warning', text: 'Something went wrong', type: 'warn' })
                }
            } else {
                this.$emit('toggle-existent', this.existingHandleUser);
            }
        },
        formatPhone(model) {
            let str = this[model].phone;
            let formatted = str.replace(/\D/g, '').replace(/(\d{3})(\d{1,3})(\d{1,4})/, '$1-$2-$3').replace(/(-\d{4})\d+?$/, '$1');
            this[model].phone = formatted;
        },
        checkFilling() {
            let filled = 0;
            for (let v in this.formValues) {
                if (this.formValues[v] !== undefined && this.formValues[v] !== '') {
                    filled++;
                }
            }

            if (this.selectedTags.length) {
                filled++;
            }

            for (let networkValue in this.networkValues) {
                if (typeof networkValue === 'string' && networkValue !== '') {
                    filled++;
                } else {
                  if (networkValue.is_custom) {
                    let { avg_likes, followers, username } = networkValue.payload.user_profile;
                    if (networkValue.social_url !== '' && avg_likes !== '' && followers !== '' && username !== '') {
                      filled++;
                    }
                  }
                }
            }

            this.$emit('filling', (filled > 0));
        },

        next() {
            if (this.validateForm) {
                if(this.step == 1 && !this.addingAdditionalContact) {
                    this.step = 3
                } else if (this.step == 3) {
                    this.submitForm();
                } else {
                    this.step++
                }

            }
        },
        onBackClick(){
            if(this.step == 3 && !this.addingAdditionalContact){
                this.step = 1
            }else{
                this.step--
            }
        },

        async submitForm() {
            this.submitting = true
            try {
                let networkValues = {};
                let customNetworks = [];
                for (let n in this.networkValues) {
                  if (typeof this.networkValues[n] === 'string' && this.networkValues[n] !== '') {
                    networkValues[n] = this.networkValues[n];
                  } else {
                    if (this.networkValues[n].is_custom) {
                      let { avg_likes, followers, username } = this.networkValues[n].payload.user_profile;
                      if (this.networkValues[n].social_url !== '' && avg_likes !== '' && followers !== '' && username !== '') {
                        customNetworks.push(this.networkValues[n]);
                      }
                    }
                  }
                }

                const api_link = ( this.projectId ) ? `/api/projects/${this.projectId}/create-user` : `/api/users/create`;

                let values = JSON.parse(JSON.stringify(this.formValues));
                values.phone = values.phone.replace(/-/gi, '');
                let secondary_contact = JSON.parse(JSON.stringify(this.secondaryContact));
                secondary_contact.phone = secondary_contact.phone.replace(/-/gi, '');

                const { data } = await this.$http.post(api_link, {
                    ...values,
                    ...networkValues,
                    customs: customNetworks,
                    tags: this.selectedTags.map(st => {return st.value || st.id}),
                    additional_contact: this.addingAdditionalContact,
                    secondary_contact,
                    entity: this.selectedEntity,
                    send_invite: this.sendInvite,
                    url: window.location.origin,
                    autogenerate_email: this.autogenerateEmail 
                })

                if(data && data.user){
                    let text = data.message ? data.message : 'User created successfully';
                    this.$notify({ title: 'Success', text, type: 'success' });

                    let user = data.user

                    // add avatar
                    if(this.avatar){

                        let userId = user.id
                        let fileData = new FormData();
                        fileData.append('image', this.avatar);

                        this.$http.post(`/api/files/upload-avatar/${userId}`, fileData).then(async ({data}) => {
                            this.$notify({ title: 'Success', text: 'Avatar added successfully', type: 'success' })
                            user.avatar = data.avatar
                        }).catch((err) => {
                            console.log('upload avatar error', err.response)
                            this.$notify({ title: 'Something went wrong while uploading avatar.', text: 'Try again later', type: 'warn' })
                        })

                    }
                    this.$emit('created-influencer', user);
                    this.clearForm();
                }
                
            } catch(e) {
                console.log(e);
                this.$notify({ title: 'Error', text: `${(e.response && e.response.data) ? e.response.data.message : ``}`, type: 'warn' })
                this.submitting = false;
            }

            this.submitting = false;
        },

        // bookmarklet: uncomment 3 methods
        // async submitForm() {
        //     this.submitting = true
        //     try {
        //         let networkValues = {};
        //         this.processedCustomNetworks = [];
        //         for (let n in this.networkValues) {
        //           if (typeof this.networkValues[n] === 'string' && this.networkValues[n] !== '') {
        //             networkValues[n] = this.networkValues[n];
        //           } else {
        //             if (this.networkValues[n].is_custom) {
        //               let { avg_likes, followers, username } = this.networkValues[n].payload.user_profile;
        //               if (this.networkValues[n].social_url !== '' && avg_likes !== '' && followers !== '' && username !== '') {
        //                 this.processedCustomNetworks.push(this.networkValues[n]);
        //               }
        //             }
        //           }
        //         }

        //         if (networkValues.tiktok_handle !== undefined && networkValues.tiktok_handle !== '') {
        //             this.adviseTiktok = true;
        //             this.currentNetworkValues = networkValues;
        //         } else {
        //             this.sendCreator({ customVar: networkValues });
        //         }
        //     } catch(e) {
        //         console.log(e);
        //         this.$notify({ title: 'Error', text: `${(e.response && e.response.data) ? e.response.data.message : ``}`, type: 'warn' })
        //         this.submitting = false;
        //     }

        //     this.submitting = false;
        // },

        // async sendCreator({ customVar = {}, scrapperId = null}) {
        //     this.adviseTiktok = false;
        //     let networkValues = customVar
        //     const api_link = ( this.projectId ) ? `/api/projects/${this.projectId}/create-user` : `/api/users/create`;

        //     let values = JSON.parse(JSON.stringify(this.formValues));
        //     values.phone = values.phone.replace(/-/gi, '');
        //     let secondary_contact = JSON.parse(JSON.stringify(this.secondaryContact));
        //     secondary_contact.phone = secondary_contact.phone.replace(/-/gi, '');

        //     let payload = {
        //         ...values,
        //         ...networkValues,
        //         customs: this.processedCustomNetworks,
        //         tags: this.selectedTags.map(st => {return st.value || st.id}),
        //         additional_contact: this.addingAdditionalContact,
        //         secondary_contact,
        //         entity: this.selectedEntity,
        //         send_invite: this.sendInvite,
        //         url: window.location.origin,
        //         autogenerate_email: this.autogenerateEmail 
        //     };

        //     if (scrapperId !== null) {
        //         payload.scrapperId = scrapperId;
        //     }

        //     const { data } = await this.$http.post(api_link, payload);

        //     if(data && data.user){
        //         let text = data.message ? data.message : 'User created successfully';
        //         this.$notify({ title: 'Success', text, type: 'success' });

        //         let user = data.user

        //         // add avatar
        //         if(this.avatar){

        //             let userId = user.id
        //             let fileData = new FormData();
        //             fileData.append('image', this.avatar);

        //             this.$http.post(`/api/files/upload-avatar/${userId}`, fileData).then(async ({data}) => {
        //                 this.$notify({ title: 'Success', text: 'Avatar added successfully', type: 'success' })
        //                 user.avatar = data.avatar
        //             }).catch((err) => {
        //                 console.log('upload avatar error', err.response)
        //                 this.$notify({ title: 'Something went wrong while uploading avatar.', text: 'Try again later', type: 'warn' })
        //             })

        //         }
        //         this.$emit('created-influencer', user);
        //         this.clearForm();
        //     }
        // },

        // async processCreator(networkValues) {
        //     if (networkValues.tiktok_handle !== undefined && networkValues.tiktok_handle !== '') {
        //         let link = `https://www.tiktok.com/@${networkValues.tiktok_handle}`;
        //         scrapperFn({ instance: this, customVar: networkValues, link, storePost: false, callback: this.sendCreator });
        //     } else {
        //         this.sendCreator({ customVar: networkValues });
        //     }
        // },

        // immediate on input
        handleInput(){
            let { email } = this.formValues

            // this means the user backspaced all the way through
            if(email.trim() == '') this.recommendedUsers = []
            
            // remove existingUser if email differs
            if(this.existingUser){
                if(email.trim() !== this.existingUser.email.trim()) this.existingUser = null
            }
        },

        // debounced
        handleDebounce(){
            let { email } = this.formValues

            if(email && email.trim() !== ''){                
                if(this.validEmail(email)){
                    this.findUserByEmail(email)
                }else{
                    this.getRecommendedUsers(email)
                }
            }
        },

        async getRecommendedUsers(email) {
            if (email == '' && this.formValues.email !== '') {
                email = this.formValues.email;
            }
            this.emailFocus = true;
            this.findingUser = true;
            this.$http.get('/api/users/project-users-simple-es', {
                params: { 
                    page: 1, 
                    perPage: 10, 
                    q: email 
                }
            }).then( ({ data }) => {
                let users = (data && Array.isArray(data)) ? data : (data && data.data && Array.isArray(data.data)) ? data.data : [];
                this.recommendedUsers = users;
            }).catch(err => {
                console.log('err', err);
            }).finally(() => {
                this.findingUser = false;
            });
        },

        onEmailBlur(){
            setTimeout(() => { this.emailFocus = false }, 200)
        },

        onRecommendedUserSelect(user){
            this.formValues.email = user.email; 
            this.findUserByEmail(user.email); 
            this.recommendedUsers = [];
            this.emailFocus = false;
        },

        async findUserByEmail(email){
            this.findingUser = true
            this.$http.get(`/api/users/byEmail`, {
                params: {
                    email: email
                }
            }).then( ({ data }) => {
                if (data) this.handleFoundUser(data)
            }).catch(err => {
                console.log('No user found')
            }).finally(() => {
                this.findingUser = false
            });
        },

        handleFoundUser(user){
            
            this.$notify({title: 'Found user', text: '', type: 'success'});
            this.recommendedUsers = [];
            
            const companyId = this.company.id;
            const roles = user.user_roles.map(element => { return { role_id: element.role_id, company_id: element.company_id } } );
            const belongsToCurrentCompany = !!roles.find(element => element.company_id == companyId);
            let currentCompanyRole = null;
            let otherCompanyRole = null;
            let isAgent = false;
            let isCreator = false;

            if(belongsToCurrentCompany){
                currentCompanyRole = roles.find(element => element.company_id == companyId).role_id;
                isAgent = !!roles.find(element => element.company_id == companyId && element.role_id == 14);
                isCreator = !!roles.find(element => element.company_id == companyId && element.role_id == 13);
            }else{
                otherCompanyRole = roles[0].role_id;
            }
            let u = { ...user, belongsToCurrentCompany, currentCompanyRole, otherCompanyRole, isAgent, isCreator }
            this.existingUser = u

            if(!belongsToCurrentCompany){
                if(isCreator){
                    this.fillCreator(user)
                }else{
                    // could be agent or any other role
                    this.fillUser(user)
                }
                // TODO 3: if its any other role, stop, show a warning or something.
            }
        },

        fillCreator(user){             
            const {first_name, last_name, phone, avatar} = user
            this.formValues.first_name = first_name;
            this.formValues.last_name = last_name;
            this.formValues.phone = phone ? phone : this.formValues.phone;
            this.formValues.avatar = avatar ? avatar : this.formValues.avatar;

            if(user.user_tags && user.user_tags.length){
                this.selectedTags = user.user_tags.map(user_tag => {
                    return { label: user_tag.tag.name, value: user_tag.tag.id }
                })
                this.tagsKey = !this.tagsKey
            }

            if (user.social_accounts && user.social_accounts.length) {
                // if social account are found we set the handle values
                user.social_accounts.forEach(element => {
                    this.networkValues[`${element.network.name}_handle`] = element.handle
                });
            }
        },

        fillUser(user){
            const {first_name, last_name, phone, avatar} = user
            this.formValues.first_name = first_name;
            this.formValues.last_name = last_name;
            this.formValues.phone = phone ? phone : this.formValues.phone;
            this.formValues.avatar = avatar ? avatar : this.formValues.avatar;
            this.formKey = !this.formKey;
        },

        onSecondaryContactEmailInput(value){
            let v = value.split('@')
            if(v[1]) this.entitySelectTerm = v[1]
        },

        cancel() {
            this.clearForm();
            this.$emit('cancel-form');
        },

        clearForm() {
            this.submitting = false;
            this.formKey = !this.formKey
            this.avatar = null,
            this.formValues = {
                first_name: '',
                last_name: '',
                email: '',
                phone: ''
            }
            this.selectedTags = []
            this.networkValues = {
                instagram_handle: '',
                tiktok_handle: '',
                youtube_handle: ''
            };
            this.selectedEntity = null;
            this.recommendedUsers = [];
            this.existingUser = null;
        },

        onFileChange(e) {
            this.avatar = e.target.files[0]
        },

        async findSecondaryContactByEmail() {
            if (this.secondaryContact.email !== '' && this.validEmail(this.secondaryContact.email)) {
                const {data} = await this.$http.get(`/api/users/find?email=${this.secondaryContact.email}`);

                if (data && data.user) {
                    // if user is found, then we populate names, phone
                    const {first_name, last_name, phone} = data.user
                    this.secondaryContact.first_name = first_name;
                    this.secondaryContact.last_name = last_name;
                    this.secondaryContact.phone = phone ? phone : this.secondaryContact.phone;

                    this.$notify({title: 'Found creator', text: '', type: 'success'})
                }
            }
        },

        validEmail(email){
            return validateEmail(email);
        },
    },
    computed: {
        ...mapGetters(['company']),
        avatarUrl(){
            return this.avatar ? URL.createObjectURL(this.avatar) : DefaultUser;
        },
        validateForm(){
            let errors = 0
            if (this.step == 1 || this.inline) {
                if (this.formValues.first_name === '') errors++
                if (this.formValues.last_name === '') errors++
                if (this.autogenerateEmail == false && (this.formValues.email === '' || !validateEmail(this.formValues.email))) errors++
                
                if (!this.selectedTags.length && !this.noTagRequired) errors++

                if (this.formValues.phone !== '' && this.formValues.phone.length > 15) errors++
            }

            if ((this.step == 2 || this.inline) && this.forceNetworks) {
                let completed = 0;
                let handles = ['instagram_handle', 'tiktok_handle', 'youtube_handle'];

                for (let handle of handles) {
                    if (this.networkValues[handle] !== undefined && this.networkValues[handle] !== '') {
                        completed++;
                    }
                }

                if (!completed) {
                    // errors++;
                }
            }
            return !errors
        },
        stepTitle() {
            if (this.step == 2) {
                return 'Add additional contact';
            }
            if (this.step == 3) {
                return 'Add social channels';
            }
            return '';
        },
        nextStepLabel() {
            let networks = 0;
            for (const prop in this.networkValues) {
                if(this.networkValues[prop] !== '') {
                    networks++;
                }
            }
            let hasNetworks = networks > 0 ? '' : 'Skip & ';
            return this.step == 1 || this.step == 2 ? 'Next' : hasNetworks + 'Save'
        }
    }
}
</script>

<style lang="scss" scoped>
    .form-wrapper{
        @apply flex flex-col justify-between;
        > .form-container{
            min-height: 40vh;
            &:not(.inline){
                @apply pb-5 px-14;
            }
            &.inline{
                @apply flex flex-col gap-y-4 py-0 px-0;
            }
            > .general-info{
                @apply grid grid-cols-2 gap-x-5 gap-y-4 mb-4;
                > .avatar-wrapper{
                    @apply w-full col-span-2 flex flex-col items-center;
                    > .loader{
                        @apply h-auto w-5;
                    }
                    > .avatar-container{
                        @apply w-32 h-32 relative;
                        > .avatar{
                            @apply w-32 h-32 rounded-full cursor-pointer border-2 border-dashed object-contain;
                        }
                        > .camera-icon{
                            @apply absolute;
                            top: 2.65rem;
                            right: -1rem;
                        }
                    }
                }
                > .alert{
                    @apply col-span-2 bg-orange-100 rounded-lg border-l-4 border-orange-500 text-orange-700 p-4;
                    @apply flex justify-between items-center;
                }
                > .info{
                    @apply col-span-2 flex items-center bg-blue-500 text-white text-sm font-bold rounded-lg px-4 py-3;
                }
                > .email-wrapper{
                    @apply relative col-span-1;
                    > .recommended-users-list{
                        max-height: 155px;
                        top: 64px;
                        @apply absolute bg-white left-0 right-0; 
                        @apply px-3 py-2 rounded-lg border z-10;
                        @apply flex flex-col gap-y-1 overflow-y-auto;
                        > .header{
                            @apply text-pxs border-b pb-1 mb-1 w-full flex justify-between items-center;
                        }
                        > .user{
                            @apply cursor-pointer transition duration-200 bg-white rounded-lg;
                            &:hover{
                                filter: brightness(0.8);
                            }
                        }
                    }
                }
                > .autogenerate-email{
                    @apply relative col-span-1 flex flex-col justify-center gap-y-1;
                    height: 64px;
                    .label{
                        @apply text-pxs;
                    }
                }
            }

            > .additional-contact{
                @apply grid grid-cols-2 gap-x-5 gap-y-4 pb-4 border-b;
            }

        }
        > .footer{
            @apply flex flex-row items-center justify-between gap-3 bg-white rounded-br-2xl py-3 px-14;
            > .left{
                @apply w-full;
            }
            > .right{
                @apply w-full flex flex-row-reverse gap-3;
            }
        }
        > .inline-footer{
            @apply flex gap-3 rounded-br-2xl py-3;
        }
    }
</style>

<template>
<div>
    
    <tab :visible="step == 1">

        <tab :visible="!tokenInfoVisible">

            <div class="mb-4">Send a permission request or claim this creator's facebook pages, this way, markerly's facebook business will be able to manage ads and analyze data.</div>

            <div class="w-full flex justify-between items-center mb-2" v-if="user">

                <avatar-list :users="[user]" /> 

                <base-button
                    v-if="facebook"
                    @action="tokenInfoVisible = true"
                    style="width: 250px; padding: 10px 10px;"
                    type="secondary"
                    icon="info-circle"
                    label="View Access Token Info"
                />

                <base-button
                    v-else
                    @action="showInviteToLoginWithFacebook"
                    style="width: 300px; padding: 10px 10px;"
                    type="secondary"
                    icon="facebook"
                    label="Invite to login with facebook"
                />

            </div>

            <template v-if="facebook">

                <div class="text-h5 font-bold">Facebook pages:</div>

                <base-icon v-if="loading" name="loader" />

                <div v-else>

                    <div v-if="!pages.length" class="font-bold">
                        No facebook user pages found
                    </div>

                    <!-- page list -->
                    <div v-else class="page-list">
                        <div 
                            v-for="(page, index) in pages" 
                            :key="index"
                            @click="onPageClick(page)" 
                            class="page"
                            :class="{
                                active: pageId == page.fb_page,
                                owned: clientPages.includes(page.fb_page)
                            }" 
                        >   
                            <div class="header">
                                <div class="name">{{page.name}} <span class="id">ID: {{page.fb_page}}</span></div>
                                <div
                                    v-if="page.long_lived_access_token && page.valid_page_long_lived_access_token && page.page_long_lived_access_token"
                                    @click.stop="showPageToken(page.long_lived_access_token)"
                                    class="flex items-center gap-x-2"
                                >
                                    <base-icon name="key"/> <div>Valid Token</div>
                                </div>
                            </div>
                            <div class="content">
                                <div>{{page.category}} <span v-if="page.category_list" class="category-list">{{ page.category_list.map(cat => cat.name).join(' - ') }}</span></div>
                                <div v-if="clientPages.includes(page.fb_page)" class="flex items-center gap-x-2">
                                    <base-icon name="check"/> <div>Page already claimed</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div @click="step++" class="mt-4 link">Didn't find the page you were looking for ?</div>

            </template>

            <template v-else>
                <div class="mt-4 text-h5 font-bold">Facebook pages:</div>
                <div>Ask this creator to log in with facebook to get their facebook pages</div>
                <div @click="step++" class="link">More options...</div>
            </template>

            <base-button
                type="secondary"
                icon="arrow-left"
                @action="$emit('back')"
                label="Back"
            />

        </tab>

        <tab :visible="tokenInfoVisible">
            <token-info
                :user="user"
                @close="tokenInfoVisible = false"
            />
        </tab>

    </tab>

    <tab :visible="step == 2">
        <other-options 
            :user="user"
            @back="step--"
            @setPageId="val => pageId = val"
            @submit="submit"
        />
    </tab>

</div>
</template>
<script>
import AvatarList from '@/components/AvatarList.vue';
import TokenInfo from './TokenInfo.vue';
import OtherOptions from './OtherOptions.vue';
export default {
    props: ['user'],
    components: {
        AvatarList,
        TokenInfo,
        OtherOptions
    },
    data(){
        return {
            step: 1,

            pages: [],
            clientPages: [],
            pageId: '',
            
            loading: false,
            tokenInfoVisible: false
        }
    },
    mounted(){  
        this.getClientPages();
        this.getPages();
    },
    computed: {
        facebook(){
            return this.user?.social_logins?.find(element => element.network_id == 6)
        }
    },
    methods: {
        async getClientPages(){
            this.loading = true
            this.$http.get('/api/facebook/client-pages').then(async (res) => {
                if(res.data){
                    this.clientPages = res.data.map(page => page.id)
                }
            }).catch((err) => {
                console.log('getClientPages error', err)
            }).finally(() => {
                this.loading = false
            })
        },
        async getPages(){
            this.loading = true
            this.$http.get(`/api/user-fb-page?user_id=${this.user.id}&validation=true`).then(async (res) => {
                if(res.data){
                    this.pages = res.data
                }
            }).catch((err) => {
                console.log('getPages error', err)
            }).finally(() => {
                this.loading = false
            }) 
        },

        async onPageClick(page){

            let endpoint = 'permission-request'

            // check if page token is valid so we can claim the page, or do the permission request
            try{
                this.loading = true
                const { data } = await this.$http.get(`/api/facebook/access-token?access_token=${page.long_lived_access_token}`)
                if(data && data.is_valid){
                    endpoint = 'claim-page'
                }
            }catch(err){
                console.log('error trying to get token info')
            }finally{
                this.loading = false
            }
            
            const title = `${
                endpoint == 'permission-request' ? 
                    `Are you sure you want to send a permission request to this facebook page (${page.fb_page}) ?` : 
                    `Are you sure you want to claim this user's facebook page (${page.fb_page}) ?`
            }`
            const text = `${
                endpoint == 'permission-request' ? 
                    `The owners of the page will get a notification` : 
                    `The user will receive a notification, and the permissions will be granted to you automatically`
            }`

            this.$swal.fire({
                title: title,
                text: text,
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                reverseButtons: true,
            }).then((result) => {
                if(result.isConfirmed){
                    this.$emit('setPageId', page.fb_page)
                    this.$emit('submit', endpoint, page.long_lived_access_token)
                }
            })
        },

        submit(endpoint, token){
            this.loading = true
            this.$http.post(`/api/facebook/${endpoint}`, {
                page_id: this.pageId,
                ...(token ? { access_token: token } : {})
            }).then(async (res) => {
                if(res.data){
                    this.$notify({ title: 'Claimed page successfully', text: '', type: 'success' })
                }
            }).catch((err) => {
                console.log('submit error', err)
                if(err.response && err.response.data && err.response.data.message){
                    this.$notify({ title: 'Warning', text: err.response.data.message, type: 'warn' })
                }else{
                    this.$notify({ title: 'Warning', text: 'Something went wrong', type: 'warn' })
                }
            }).finally(() => {
                this.loading = false
            })   
        },

        showPageToken(token){
            this.$swal.fire({
                title: 'Valid Page Token',
                text: token,
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Done',
                reverseButtons: true,
            });
        },

        showInviteToLoginWithFacebook(){
            this.$swal.fire({
                title: 'Invite this creator to login with facebook?',
                text: 'This will send an email to the creator to login in on the platform with facebook',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Send Email',
                reverseButtons: true,
            }).then((result) => {
                if(result.isConfirmed){
                    this.inviteToLoginWithFacebook()
                }
            })
        },

        inviteToLoginWithFacebook(){
            this.loading = true
            this.$http.post('/api/invite/intent', {
                roleId: 13, // creator
                targetEmails: this.user.email,
                targetId: this.user.id,
                networkId: 6
            }).then(async (res) => {
                
                if(res.data){
                    
                    await this.$http.post('/api/invite/social-login', { 
                        email: this.user.email,
                        url: `${window.location.origin}/social-login?t=${res.data.token}` ,
                        networkName: 'facebook'
                    })

                    this.$notify({ title: 'Success', text: 'Invite sent successfully', type: 'success' })
                    
                }

            }).catch((err) => {
                console.log('sendRequest error', err)
                if(err.response && err.response.data && err.response.data.message){
                    this.$notify({ title: 'Warning', text: err.response.data.message, type: 'warn' })
                }else{
                    this.$notify({ title: 'Warning', text: 'Something went wrong', type: 'warn' })
                }
            }).finally(() => {
                this.loading = false
            })  
        },
    }
}
</script>
<style lang="scss" scoped>
    .link{
        @apply w-full text-right text-blue-700 cursor-pointer;
    }
    .page-list{
        @apply flex flex-col gap-y-4 mt-4;
        .page{
            @apply relative px-6 py-4 border border-black rounded-xl cursor-pointer transition-opacity duration-500;
            &:hover{
                @apply opacity-50;
            }
            &.active{
                @apply bg-gray-300;
            }
            &.owned{
                @apply bg-purple-m-main text-white;
                &:hover{
                    @apply opacity-100;
                }
            }
            .header{
                @apply w-full flex justify-between items-center;
                .name {
                    @apply text-h5 font-bold;
                }
                .id {
                    @apply text-pxs font-normal text-gray-400;
                }
            }
            .content{
                @apply w-full flex justify-between items-center;
                .category-list {
                    @apply text-pxs font-normal text-gray-400;
                }
                .task-list{
                    @apply flex gap-x-4 flex-wrap pt-2 pb-4;
                    .task{
                        @apply text-pxs;
                    }
                }
            }
        }
    }
</style>
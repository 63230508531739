<template>
<main class="main" tabindex="0">

    <div class="searchbar-wrapper">
        <div class="searchbar-container">
            <div class="searchbar">
                <div class="icon-container">
                    <base-icon name="search" class="text-purple-m-secondary" />
                </div>                    
                <FormulateInput
                    type="text"
                    placeholder="Search"
                    outer-class="w-full"
                    element-class="w-full"
                    wrapper-class="border rounded-md py-2 px-3 pl-16 bg-white h-16 flex items-center"
                />
            </div>
        </div>
    </div> 

    <div class="header-wrapper">
        <div class="header">
            <div class="title">
                Agents
            </div>
            <base-button                    
                :iconSize="5"
                @action="createSecondaryContactModalVisible = true"
                class="font-bold"
                size="xlg"
                label="Add Agent"
                :disabled="!isAdminOrHigher"
                v-tooltip="!isAdminOrHigher ? { content: 'Only admins can add agents', classes: 'bg-black text-white rounded py-1 px-2 text-xs' } : null"
                icon="plus"
            />
        </div>          
    </div>

    <!-- TODO: change for new base table functionality  -->
    <div class="table space-y-10">
        
        <div v-if="loading" class="loader">
            <base-icon :size="8" name="loader" />
        </div>

        <template v-else>

            <div class="empty-state" v-if="entities.length <= 0">
                <div class="text">There are no entities yet</div>
                <base-button                    
                    :iconSize="5"
                    @action="createSecondaryContactModalVisible = true"
                    class="font-bold"
                    size="xlg"
                    label="Add Agent"
                    :disabled="!isAdminOrHigher"
                    v-tooltip="!isAdminOrHigher ? { content: 'Only admins can add agents', classes: 'bg-black text-white rounded py-1 px-2 text-xs' } : null"
                    icon="plus"
                />
            </div>

            <div class="list" v-for="entity in entities" :key="entity.id">
                
                <div class="entity">
                    <base-icon
                        @action="entity.collapsed = !entity.collapsed"
                        :name="entity.collapsed ? 'chevron-down' : 'chevron-up'" 
                        class="cursor-pointer"
                    />
                    <base-button type="label" justify="start">
                        <img :src="entity.logo || require('../../assets/images/default-picture.png')" class="h-12 w-12"/>                    
                        <div class="text-h2 font-bold text-purple-m-secondary">{{entity.name}}</div>                    
                        <div>{{entity.domain}}</div>
                    </base-button>
                </div>
                
                <table v-if="!entity.collapsed" class="agent-table">

                    <thead>
                        <tr>
                            <th>Agent</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Creators</th>
                            <th></th>
                        </tr>
                    </thead>

                    <template v-if="entity.agents.length > 0">
                        <tbody v-for="(agent, i) in entity.agents" :key="i">
                            <tr class="agent">
                                <td> <avatar-list :users="[agent]"/> </td>
                                <td> {{ agent.email }} </td>
                                <td> {{ agent.phone }} </td>
                                <td>
                                    <div class="child-users">
                                        <base-button 
                                            @action="() => { agent.showChildUsers = !agent.showChildUsers; $forceUpdate() }" 
                                            type="label" 
                                            :icon="agent.showChildUsers ? 'chevron-up' : 'chevron-down'" 
                                        />
                                        <base-icon name="user-group" />
                                        {{ agent.child_users.length }}
                                    </div>
                                </td>
                                <td> <!-- options dropdown --> </td>
                            </tr>
                            
                            <!-- separator -->
                            <tr><td>{{  }}</td></tr>

                            <!-- show agent users -->
                            <tr v-if="agent.showChildUsers" class="child-users-list">
                                <td :colspan="5">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Creator</th>
                                                <th>Email</th>
                                                <th>Phone</th> 
                                                <th></th>                                   
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(creator, i) in agent.child_users" :key="i" class="child-user">
                                                <td> <avatar-list :users="[creator]" /> </td>
                                                <td>{{creator.email}}</td>
                                                <td>{{creator.phone}}</td>
                                                <td> <!-- options dropdown --> </td>
                                            </tr>
                                            <tr class="assign-creator-button">
                                                <td>
                                                    <base-button
                                                        @action="handleAssignCreatorClick(agent)"
                                                        :disabled="!isAdminOrHigher"
                                                        v-tooltip="!isAdminOrHigher ? { content: 'Only admins can assign creators', classes: 'bg-black text-white rounded py-1 px-2 text-xs' } : null"
                                                        type="label"
                                                        icon="plus"
                                                    >Assign Creator</base-button>
                                                </td>                                        
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                    <template v-else>
                        <tbody>
                            <tr class="empty-state">
                                <td :colspan="4">
                                    No agents assigned to this company
                                </td>                        
                            </tr>                    
                        </tbody>
                    </template>
                </table>
            </div>
        </template>
    </div>

    <assign-creator-modal
        title="Assign Creators to Agent"
        :visible="assignCreatorModalVisible"
        :creators="creators"
        :tags="tags"
        :initialSelectedElements="initialSelectedCreators"
        :loading="creatorsLoading"
        @close="assignCreatorModalVisible = false"
        @reload-creators="getCreators"
        @confirm-selected-creators="onConfirmSelectedCreators"
    />

    <create-secondary-contact-modal
        :visible="createSecondaryContactModalVisible"
        @created="getData"
        @close="createSecondaryContactModalVisible = false"
    />

</main>
</template>

<script>
import AvatarList from '../../components/AvatarList.vue'
import CreateSecondaryContactModal from './CreateSecondaryContactModal.vue'
import AssignCreatorModal from '../../components/AssignCreatorModal.vue'
import { mapGetters } from 'vuex';

export default {
    components: {
        AvatarList,
        CreateSecondaryContactModal,
        AssignCreatorModal
    },
    data() {
        return {
            loading: false,
            entities: [],

            createSecondaryContactModalVisible: false,

            // assign creator modal
            assignCreatorModalVisible: false,
            selectedAgent: null,
            creators: [],
            creatorsLoading: false,
            tags: [],
            tagsLoading: false

        }
    },
    mounted(){
        this.getData();
        this.getCreators();
        this.getTags();          
    },
    computed: {
        ...mapGetters(['isAdminOrHigher']),
        initialSelectedCreators(){
            if(this.selectedAgent && this.selectedAgent.child_users){
                const childUsers = this.selectedAgent.child_users.map(element => element.id)
                const creators = this.creators.map(element => element.id)
                return creators.filter(creator => childUsers.includes(creator))
            }
            return []
        }
    },
    methods: {
        async getData() {
            this.loading = true

            try {

                const { data } = await this.$http.get('/api/entities')
                this.entities = data.map(entity => {
                    entity.refresh = false
                    entity.collapsed = false
                    return entity
                });

            } catch (error) {
                if (error.response.status >= 400) {
                    
                    // Handle the 400+ error

                    this.$notify({ title: 'Error', text: error.response.data.message, type: 'error' });

                    this.$router.push("/home");

                } else {
                    // Handle other errors
                    console.error(error);
                }
            }

            this.loading = false;
        },
        
        async handleAssignCreatorClick(agent){
            this.selectedAgent = agent
            this.assignCreatorModalVisible = true 
        },
        async getCreators() {
            this.creatorsLoading = true
            const { data } = await this.$http.get('/api/users?creators=true');
            this.creators = data
            this.creatorsLoading = false
        },
        async getTags(){
            this.tagsLoading = true
            const { data } = await this.$http.get(`/api/tags`)
            this.tags = data
            this.tagsLoading = false
        },
        async onConfirmSelectedCreators(creators){

            const { data } = await this.$http.put(`/api/users/agents/${this.selectedAgent.id}/child-users`, {
                creators: creators
            })

            if(data){
                this.assignCreatorModalVisible = false
                this.$notify({ title: 'Creators assigned successfully', text: '', type: 'success' })
                this.getData()
            }else{
                this.$notify({ title: 'Warning', text: 'Something went wrong', type: 'warn' })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .main{
        @apply flex-1 relative z-0 overflow-y-auto;
        &:focus{
            @apply outline-none;
        }
    }
    .searchbar-wrapper{
        @apply flex justify-between px-12 pt-10 pb-4;
        .searchbar-container{
            @apply flex-shrink w-full;
            .searchbar{
                @apply relative flex w-full;
                .icon-container{
                    @apply absolute w-16 h-full flex items-center justify-center pointer-events-none;
                }
            }
        }
    }
    .header-wrapper{
        @apply px-12 my-2 pb-2;
        .header{
            @apply bg-tan-m flex justify-between items-center my-2 py-8 px-10 rounded-3xl;
            .title{
                @apply text-h2 font-bold text-purple-m-secondary;
            }
        }
    }
    .table{
        @apply w-full px-12 pb-10 overflow-x-auto;
        .loader{
            @apply w-full flex justify-center items-center;
        }
        .empty-state{
            @apply flex flex-col items-center p-12 gap-y-4;
            .text{
                @apply italic text-sm text-gray-800;
            }
        }
        .list{
            .entity{
                @apply flex gap-4 items-center;
            }
            .agent-table{
                @apply bg-white w-full;
                thead{
                    @apply border-b border-gray-m-light;
                    tr{
                        @apply text-purple-m-main-light text-h6 font-bold h-16;
                        th{
                            @apply w-56 px-6 text-left;
                        }
                    }
                }
                tbody{
                    tr.empty-state{
                        @apply text-gray-m-disable text-h6 border-b border-gray-m-light h-20;
                         td{
                            @apply px-6;
                        }
                    }
                    tr.agent{
                        @apply text-purple-m-secondary text-h6 border-b border-gray-m-light h-20;
                        td{
                            @apply px-6;
                            div.child-users{
                                @apply inline-flex gap-4;
                            }
                        }
                    }
                    tr.child-users-list{
                        @apply border-l-4 border-purple-m-main;
                        td{
                            table{
                                @apply w-full;
                                thead{
                                    @apply border-b border-gray-m-light;
                                    tr{
                                        @apply text-purple-m-main-light text-h6 font-bold h-16;
                                        th{
                                            @apply w-56 px-6 text-left;
                                        }
                                    }
                                }
                                tbody{
                                    tr.child-user{
                                        @apply text-purple-m-secondary text-h6 h-20 border-b border-gray-m-light;
                                        td{
                                            @apply px-6;
                                        }
                                    }
                                    tr.assign-creator-button{
                                        @apply h-16;
                                        td{
                                            @apply px-6;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
<template>
   <div :class="['post', { 'use-embed': post && post.use_embed }]">
        <template v-if="value.found && value.preview">
            <!-- embeded tiktok video -->
            <div class="embedded-video" v-if="post.use_embed">
                <iframe
                    :src="`https://www.tiktok.com/embed/${post.id}`" 
                    scrolling="no"
                    allow="encrypted-media;"
                    frameborder="0" border="0" cellspacing="0"
                    style="width: 325px; height: 575px;" 
                />
            </div>
            <template v-else>
                <!-- video or image -->
                <template>
                    <video v-if="post.is_video" autoplay muted>
                        <source :src="post.media_url" type="video/mp4"/>
                    </video>
                    <img v-else :src="post.media_url" />
                </template>
                <!-- date -->
                <div class="date">{{post.date}}</div>
                <!-- caption -->
                <div 
                    ref="caption"
                    :class="['caption', { 'not-expanded': !expanded } ]"
                >
                    {{ parseUnicodeCharacters(post.caption) }}
                </div>
                <!-- show more button -->
                <div class="show-more" v-if="isExpandable">
                    <span @click.stop="expanded = !expanded">{{ expanded ? 'Collapse' : 'Show more' }}</span>
                </div>
                
                <!-- stats, we have to check this -->
                <div class="stats">
                    <template v-for="(stat, index) in stats">
                        <div class="stat" v-if="post[stat.key]" :key="`stat-${index}`">
                            <div class="icon-container">
                                <base-icon :size="3" class="text-green-m-main" :name="stat.icon"/>
                            </div>
                            <div class="value">{{post[stat.key] | numeral(stat.numeral)}}</div>
                        </div>
                    </template>                    
                </div>

            </template>
        </template>
        <template v-else>
            <!-- if the post was not found or it does not have a preview it will still be visible -->
            <div class="default">
                <div class="content">
                    <!-- network icon -->
                    <base-icon :name="value.network_name" />
                    <!-- post id -->
                    <div v-if="postId" class="text-pxs">ID: {{postId}}</div>
                    <!-- loader icon -->
                    <base-icon name="loader" v-if="loading" />
                    <!-- not found text -->
                    <div v-else class="text">Post not found</div>
                </div>
            </div>
        </template>

        <!-- view on instagram button -->
        <div 
            @click.stop="()=>null"
            v-if="value.network_name == 'instagram' || value.network_name == 'tiktok'"
        >
            <base-button
                :label="`View on ${value.network_display_name == 'Instagram' ? 'IG' : value.network_display_name}`"
                type="secondary"
                class="h-9 font-bold text-pxs py-0 mx-auto mt-2"
                @action="viewOnInstagram()"
            />
        </div>

        <!-- add featured post button -->
        <div @click.stop="()=>null">
            <base-button
                label="Add post"
                type="secondary"
                :disabled="disabled"
                class="h-9 font-bold text-pxs py-0 mx-auto mt-2"
                @action="$emit('add-post'); $emit('close');"
            />
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { parseUnicodeCharacters } from '../../lib/strings'
    import { isValidString } from '../../common';
    const defaultImage = require('../../assets/images/default-picture.png');
    const stats = [ 
        { key: 'likes', icon: 'post-like', numeral: '0.0a' },
        { key: 'comments', icon: 'post-comments', numeral: '0.0a' },
        { key: 'engagement_rate', icon: 'post-engagement-rate', numeral: '0.00%' }
    ];
    export default {
        name: 'AddFeaturedPostModalItem',
        props: ['value', 'readonly', 'disabled'],
        data() {
            return {
                isExpandable: false,
                expanded: false,
                key: false,

                defaultImage,
                stats,
                parseUnicodeCharacters
            }
        },
        // wait for caption element to be fully rendered to check if the caption is expandable
        mounted(){
            this.$nextTick(() => {
                this.checkIsExpandable();
            });
        },
        computed: {
            post(){
                let post = this.value.preview

                if(!post) return {};
                
                // if we are using embed for tiktok we only need this information for the post
                if(post.use_embed){
                    return {
                        "id": post.id,
                        "use_embed": post.use_embed,
                        "url": post.url
                    }
                }

                // media url, type and mimetype
                let media_url = null;
                let media_mimetype = null;
                let is_video = false;
                
                if(post.media_type == "CAROUSEL_ALBUM" && post.children && post.children.data){
                    const children = post.children.data.filter(element => element.media_url && element.media_type);
                    if(children.length){
                        media_url = children[0].media_url;
                        if(children[0].media_type == "IMAGE"){
                            media_mimetype = "image/png"
                        }
                        if(children[0].media_type == "VIDEO"){
                            media_mimetype = "video/mp4"
                            is_video = true
                        }
                    }
                }else if(post.media_url){
                    media_url = post.media_url;
                    if(post.media_type == "IMAGE"){
                        media_mimetype = "image/png"
                    }
                    if(post.media_type == "VIDEO"){
                        media_mimetype = "video/mp4"
                        is_video = true
                    }
                }

                if(!media_url || !media_mimetype){
                    // try to use the thumbnail
                    if(isValidString(post.thumbnail_url)){
                        media_url = post.thumbnail_url;
                    }else{
                        media_url = defaultImage;
                    }
                    media_mimetype = "image/png"
                } 

                // date
                let date = moment(post.timestamp).format('LL');

                return {
                    "id": post.id,
                    "url": post.permalink,
                    "caption": post.caption,
                    "date": date,
                    "likes": post.like_count,
                    "comments": post.comments_count,
                    "media_url": media_url,
                    "media_mimetype": media_mimetype,
                    "is_video": is_video
                }
            }
        },
        methods: {
            checkIsExpandable(){
                const { caption } = this.$refs
                const captionHeight = caption && caption.offsetHeight ? caption.offsetHeight : null
                if (captionHeight && captionHeight >= 72) {
                    this.isExpandable = true;
                }
            },
            viewOnInstagram(){
                window.open(this.value.url, '_blank')
            }
        }
    }
</script>
<style lang="scss" scoped>
    .post{
        @apply relative rounded-md p-4 cursor-pointer transition-colors duration-200;
        @apply flex flex-col overflow-hidden;
        &:not(.use-embed){
            width: calc(150px + 2rem);
        }
        &:hover{
            @apply bg-gray-200;
        }
        > .default{
            @apply bg-gray-300 rounded-lg text-white flex justify-center items-center;
            width: 150px; 
            height: 150px;
            > .content{
                @apply flex flex-col items-center gap-y-2;
                > .text{
                    @apply font-bold;
                }
            }
        }
        > .embedded-video{
            // w-32 h-32
            @apply cursor-pointer border-2 object-contain transition duration-300;
        }
        > video, img{
            @apply rounded-md;
            width: calc(150px);
            height: calc(150px);
        }
        > .date{
            @apply text-right text-pxs mt-2 font-bold;
        }
        > .caption{
            @apply mt-1 text-gray-m-disable text-sm overflow-x-hidden leading-6;
            text-overflow: ellipsis;
            // initial state
            &.not-expanded {
                @apply overflow-y-hidden max-h-20;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                transition: max-height 3s cubic-bezier(0, 1, 0, 1);
            }
        }
        .show-more{
            @apply text-right;
            > span{
                @apply cursor-pointer text-xs font-bold text-gray-800;
            }
        }
        .stats{
            @apply pt-2 pb-1 flex justify-center flex-wrap gap-x-3 gap-y-2;
            > .stat{
                @apply flex items-center gap-x-1;
                > .icon-container{
                    @apply w-6 h-6 rounded-full bg-purple-m-main;
                    @apply flex justify-center items-center;
                }
                > .value{
                    //
                }
            }
        }
    }
</style>

<template>
  <!-- this component is used most of the times on creator and post reports -->
  <!-- "id" is being used for pdf generation -->
  <div :id="`report-post-card-container-${index}`" class="report-post-card-container space-y-6 break-inside">

    <div class="media-box">

      <div class="warnings-header" v-if="!printView && (requestAttention || post.is_boosted)">

        <div class="animated heartbeat exclamation-icon" v-if="requestAttention">
          <base-icon
              name="exclamation"
              :size="4"
              class="text-black cursor-pointer"
              @action="$emit('edit-post', post)"
              v-tooltip="{ 
                content: requestAttentionMessage, 
                classes: 'v-tooltip-black' 
              }"
          />
        </div>
        
        <div class="boosted-icon" v-if="post.is_boosted">
          <base-icon
            name="speakerphone"
            :size="3"
            class="text-black"
            :class="{'cursor-pointer': isAdminOrHigher} "
            v-tooltip="{ 
              content: `Boosted post.${isAdminOrHigher ? ` Click to see details` : ``}`, 
              classes: 'v-tooltip-black' 
            }"
            @action="isAdminOrHigher && post.boosted_data ? $swal.fire({
              title: `Boosted post details`,
              html: boostedDataHtml(post),
              showCancelButton: false,
              reverseButtons: true,
              confirmButtonText: 'Done'
            }) : null"
          />
        </div>

      </div>
    
      <!-- navigation -->
      <div class="dots-navigation" v-if="!printView && postMedia.length > 1">
        <div v-for="(media, i) in postMedia" class="dot-container" :style="'width: ' + (100 / postMedia.length) + '%'" :key="'post_' + i">
          <div class="dot" v-if="position >= i"></div>
        </div>
      </div>

      <div class="left-navigation" @click="position--" v-if="!printView && postMedia.length > 1 && position">
        <button type="button">
          <base-icon name="chevron-left" :size="5"/>
        </button>
      </div>

      <div class="right-navigation" @click="position++" v-if="!printView && postMedia.length > 1 && position < postMedia.length - 1">
        <button type="button">
          <base-icon name="chevron-right" :size="5"/>
        </button>
      </div>

      <div class="play-button" v-if="!printView && isVideo && acceptedVideo" @click="playing ? pause() : play()" :class="!playing && !loadingVideo ? 'text-white' : 'text-black'" >
        <base-icon :size="10" name="play" v-if="!playing && !loadingVideo && !hidePlayIcon"/>
        <base-icon name="loader" v-else-if="!playing && loadingVideo"/>
      </div>

      <!-- loader -->
      <div class="loader" v-else-if="!printView && isVideo && loadingVideo">
        <base-icon name="loader" />
      </div>

      <!-- embeded tiktok video -->
      <!-- Style like other report posts  -->
      <!-- width: 100%; -->
      <!-- height: 484px; -->
      
      <!-- Actual embeded tiktok height -->
      <!-- width: 325px; -->
      <!-- height: 575px; -->

      <!-- dont render the iframe while printing so we can show the display image (thumbnail) -->
      <iframe
        v-if="!printView && useEmbed"
        :src="embed_tiktok_url" 
        scrolling="no"
        allow="encrypted-media;"
        frameborder="0" border="0" cellspacing="0"
        style="width: 325px; height: 575px; transform: scaleX(0.9) scaleY(0.845) translate(-27px, -55px); margin-bottom: -91px;" 
      />
      
      <!-- img -->
      <img-selector 
        v-else-if="!isVideo" 
        :prop-class="`object-cover animate-fade-in rounded-xl ${isPreview ? 'h-92' : ''}`" 
        width="100%" 
        height="240px" 
        :images="[selectedMedia, defaultDataPicture]"
      />
      
      <!-- video -->
      <!-- * [canvas] if we want to use canvas we have to set the crossorigin attr to anonymous while printView to let dom-to-image render the tainted canvas, otherwise it will throw error -->
      <!-- * [canvas] however adding this crossorigin attribute threw errors on production :crossorigin="printView ? 'anonymous' : ''" -->
      <!-- * we have to hide this <video> element when we are printing, we need the video element to be rendered so we can paint the canvas, but hidden so the canvas can be shown instead -->
      <video
        v-else-if="acceptedVideo && postMedia.length"
        :style="sizeStyle" 
        autoplay 
        muted 
        ref="video" 
        @play="togglePlay" 
        :poster="computedDisplay" 
        :class="[computedPostClass, isPreview ? 'h-92 object-cover' : '', printView ? 'hidden' : '']" 
        width="100%" 
        height="240px" 
        :id="computedId"
      >
        <source :src="selectedMedia" type="video/mp4" @error="onVideoError"/>
      </video>
      
      <!-- [canvas] we will use a canvas only on instagram/fb videos, we will grab the video element and paint the canvas with it -->
      <canvas 
        v-if="useCanvas"
        :id="`canvas_${computedId}`" 
        :class="{ 'hidden': !printView }" 
      >
        Canvas not supported on this browser
      </canvas>

    </div>
    
    <!-- post-info: network, user profile, date, description -->
    <div class="post-info">

      <div class="general-info" v-if="post.profile_item">

        <div class="network-icon">
          <a v-if="post.network && post.network.name" :href="profileUrl" target="_blank">
            <base-icon :name="post.network.name" style="display: inline-block;"/>
          </a>
        </div>

        <div class="avatar">
          <avatar size="small" @callback="openUser" :user="computedAvatarUser"/>
        </div>
        <div class="name-date">
          <div class="name"><a :href="profileUrl" target="_blank">{{ parseUnicodeCharacters(post.profile_item.full_name ? post.profile_item.full_name : post.profile_item.username) }}</a></div>
          <div class="date"><a :href="post.url" target="_blank">{{ customizedDate(post) }}</a></div>
        </div>

      </div>

      <!-- we format the caption because sometimes emojis are shown as &#12335; -->
      <div
        ref="caption"
        class="caption"
        :class="[!isExpanded ? 'not-expanded' : (!isExpanded ? 'not-expanded' : '')]"
      >
        {{ parseUnicodeCharacters(post.caption) }}
      </div>

      <!-- show more button -->
      <div class="show-more" v-if="!printView && isExpandable">
        <span @click="isExpanded = !isExpanded">{{ isExpanded ? 'Collapse' : 'Show more' }}</span>
      </div>
    
    </div>

    <!-- stats (likes, comments, shares, views) counts -->
    <div class="stats" v-if="!hideStats">
      <div class="space">&nbsp;</div>
      <div class="stats-wrapper">
        <div class="stats-grid">

          <report-post-card-detail 
            class="stats-item" 
            inline 
            v-if="post.type.name !== 'story' && getPostStat('likes') > 0" 
            :value="getPostStat('likes') | numeral('0.0a')" 
            :icon="post.network.name === 'youtube' ? 'youtube-like' : 'post-like'"
            :breakdown="showBoostedData && isPostStatBoosted('likes') ? getPostStatBreakdown('likes') : null"
            :tooltip="`Likes (${statsQuotient('likes')}% of total interactions)`" 
            :bold="false"
          />

          <report-post-card-detail 
            class="stats-item" 
            inline 
            v-if="post.network.name === 'youtube' && getPostStat('dislikes') > 0" 
            :value="getPostStat('dislikes') | numeral('0.0a')" 
            icon="dislike"
            :breakdown="showBoostedData && isPostStatBoosted('dislikes') ? getPostStatBreakdown('dislikes') : null"
            tooltip="Dislikes" 
            :bold="false"
          />
          <report-post-card-detail 
            class="stats-item" 
            inline 
            v-if="post.type.name !== 'story' && getPostStat('comments') > 0"
            :value="getPostStat('comments') | numeral('0.0a')" 
            icon="post-comments"
            :breakdown="showBoostedData && isPostStatBoosted('comments') ? getPostStatBreakdown('comments') : null"
            :tooltip="`Comments (${statsQuotient('comments')}% of total interactions)`" 
            :bold="false"
          />
          <report-post-card-detail 
            class="stats-item" 
            inline 
            v-if="!isUserReport && post.profile_item && post.profile_item.total_followers > 0" 
            :value="parseInt(post.profile_item.total_followers) | numeral('0.0a')" 
            icon="post-reach" 
            tooltip="Reach" 
            :bold="false"
          />
          <report-post-card-detail 
            class="stats-item" 
            inline 
            v-if="post.views && post.type.name !== 'story' && getPostStat('views') > 0" 
            :value="getPostStat('views') | numeral('0.0a')" 
            icon="post-views"
            :breakdown="showBoostedData && isPostStatBoosted('views') ? getPostStatBreakdown('views') : null"
            :tooltip="`Views (${statsQuotient('views')}% of total interactions)`" 
            :bold="false"
          />
          <report-post-card-detail 
            class="stats-item" 
            inline 
            v-if="getPostStat('shares') && getPostStat('shares') > 0" 
            :value="getPostStat('shares') | numeral('0.0a')" 
            icon="post-share" 
            :breakdown="showBoostedData && isPostStatBoosted('shares') ? getPostStatBreakdown('shares') : null"
            :tooltip="`Shares (${statsQuotient('shares')}% of total interactions)`" 
            :bold="false"
          />
          <report-post-card-detail 
            class="stats-item" 
            inline 
            v-if="getPostStat('saves') && getPostStat('saves') > 0" 
            :value="getPostStat('saves') | numeral('0.0a')" 
            icon="post-save" 
            :breakdown="showBoostedData && isPostStatBoosted('saves') ? getPostStatBreakdown('saves') : null"
            :tooltip="`Saves (${statsQuotient('saves')}% of total interactions)`" 
            :bold="false"
          />

          <report-post-card-detail 
            class="stats-item" 
            inline
            v-if="!isUserReport && post.profile_item && post.profile_item.total_followers > 0 && totalInteractions() > 0" 
            :value="engagementRate()" 
            icon="post-engagement-rate" 
            :tooltip="`Engagement Rate: ${engagementRate(false)} <br> Total number of interactions (likes, comments, views, shares and saves) divided by total followers, multiplied by 100%`" 
            :bold="false"
          />

        </div>
      </div>
    </div>

    <!-- updated_at, config, view content, replace, etc -->
    <report-post-bottom
      :post="post"
      :print-view="printView"
      :hide-stats="hideStats"
      :show-boosted-data="showBoostedData"
      :allow-edit="allowEdit"
      :allow-replace="allowReplace"
      :is-preview="isPreview"
      :is-social="false"
      :allow-unlink="$listeners.unlink !== undefined && post.project_user_deliverable_posts == null"
      :deliverables="deliverables"
      :updated-at="updatedAt"
      @link="$emit('link')"
      @replace-post="(p) => $emit('replace-post', p)"
      @edit-post="(p) => $emit('edit-post', p)"
      @unlink="(postId) => $emit('unlink', postId)"
    />
  </div>
</template>

<script>
import {isBase64, prepareAsB64} from '../lib/strings';

const defaultPicture = require('../assets/images/default-picture.png');
const defaultVideo = require('../assets/images/default-video.png');
const defaultProfile = require('../assets/images/default-profile.jpg');
import ReportPostCardDetail from './ReportPostCardDetail';
import ReportPostBottom from './ReportPostBottom';
import ImgSelector from './ImgSelector.vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { getProfileUri } from '../lib/user';
import { getBoostedData, isPostPropertyBoosted, getPostPropertyAds } from '../lib/fbBoostedData';
import { getObjectValue, isArray, isNumber, isObject, convertNumeral, isValidDate } from '../common';
import { parseUnicodeCharacters } from '../lib/strings'
export default {
    props: {
      post: {},
      printView: {
        type: Boolean,
        default: false
      },
      hideStats: {
        type: Boolean,
        default: false
      },
      showBoostedData: {
        type: Boolean,
        default: true
      },
      allowEdit: {
        type: Boolean,
        default: false
      },
      allowReplace: {
        type: Boolean,
        default: false
      },
      isPreview: {
        type: Boolean,
        default: false
      },
      isReport: {
        type: Boolean,
        default: false
      },
      isUserReport: {
        type: Boolean,
        default: false
      },
      runLoader: {
        type: Boolean,
        default: false
      },
      forceWait: {
        type: Boolean,
        default: false
      },
      deliverables: {
          type: Array,
          default: function () {
              return []
          }
      },
      updatedAt: {
        type: String,
        default: null,
      },
      index: {
        type: [Number, String],
        default: 0
      }
    },

    components: {
      ReportPostCardDetail,
      ReportPostBottom,
      ImgSelector
    },
    
    computed: {
      ...mapGetters(['isAdminOrHigher']),
      requestAttention() {
        if(this.allowEdit){
          const isVideoWithNoViews = (this.post.is_video && (!this.post.views || this.post.views <= 0));
          const hasNoMedia = !this.post.media.length;
          const hasValidDate = isValidDate(this.post.date);
          if(isVideoWithNoViews || hasNoMedia || !hasValidDate){
            return true;
          }
        }
        return false;
      },
      requestAttentionMessage() {
        if(this.post.is_video){
          if(!this.post.views || this.post.views <= 0){
            return 'This post is a video but views are not defined. Click here to add the views.';
          }
        }
        if(!this.post.media.length){
          return 'This post does not have media, Click here to add the media.';
        }
        return 'There are pending fields for this post. Click here to edit the stats.';
      },
      profileUrl() {
        let uri = this.post.profile_item.uri;
        if (!uri) {
          return getProfileUri(this.post.network.name, this.post.profile_item.username);
        }
        return uri;
      },
      // currently not using this function, deprecated ?
      computedGridStats() {
        let total = 0;
        let post = this.post;
        if (post.type.name !== 'story' && post.likes > 0) {
          total++;
        }
        if (post.network.name === 'youtube' && post.dislikes > 0) {
          total++;
        }
        if (post.type.name !== 'story' && post.comments > 0) {
          total++;
        }
        if (!this.isUserReport && post.profile_item.total_followers > 0) {
          total++;
        }
        if ((post.is_video || this.hasVideo) && post.type.name !== 'story' && post.views > 0) {
          total++;
        }
        if (post.shares > 0) {
          total++;
        }
        if (post.saves > 0) {
          total++;
        }
        return total > 0 && total <= 2 ? total : 2;
      },
      computedAvatarUser() {
        let imagesArray = [prepareAsB64(this.post.profile_item.profile_picture_url)];
        if (this.post.user) {
          let user = this.post.user;
          if (user.avatar && user.avatar !== '') {
            imagesArray.push(this.post.user.avatar);
          }

          if (user.social_accounts && Array.isArray(user.social_accounts)) {
            for(let i = 0; i < user.social_accounts.length; i++) {
              imagesArray.push(user.social_accounts[i].avatar);
            }
          }
        }

        return {
          avatar: '',
          social_accounts: [],
          imagesArray: imagesArray.filter(img => img)
        }
      },

      sizeStyle() {
        let wD = this.tagWidth !== 'unset' ? 'px' : '';
        let hD = this.tagHeight !== 'unset' ? 'px' : '';
        return {
          height: this.tagHeight + hD,
          width: this.tagWidth + wD,
          'object-fit': 'cover'
        }
      },

      embed_tiktok_url() {
        if (this.isTiktok) {
          return `https://www.tiktok.com/embed/${this.post.post_identifier}`;
        }

        return '';
      },

      viewOptions() {
        if (this.post.project_user_deliverable_posts) {
          return this.post.project_user_deliverable_posts.map(post => {
            return { value: post.project_user_deliverable_id, label: post.project_user_deliverable.name };
          });
        }

        return [];
      },

      computedPostClass() {
        return `post_${this.post.id}`;
      },

      computedId() {
        let media = this.post.media.find(e => e.url === this.selectedMedia);
        let hasMediaId = media && media.id;
        let hasPostId = this.post && (this.post.post_identifier || this.post.id);
        if (hasMediaId || hasPostId) {
          return `media_${this.post.post_identifier || this.post.id || media.id}`;
        }
        return null;
      },

      hasVideo() {
        let has_video = this.post.media.find(item => {
          return item.is_video || item.mimetype === 'video/mp4';
        });

        return !!has_video;
      },

      isVideo() {
        if (this.selectedMedia && this.selectedMedia !== '') {
          return this.selectedMedia.split(".").pop().toLowerCase() === 'mp4' || this.selectedMedia.includes('.mp4');
        }

        return this.post.is_video;
      },

      isTiktok() {
        return this.post.network.name === 'tiktok';
      },

      computedDisplay() {
        return prepareAsB64(this.post.display_url);
      },

      selectedMedia() {
        let media = this.postMedia[this.position];

        // When we change printView to true
        if (this.printView) {
          
          // this is previous functionality
          // [canvas] if its an instagram or fb video we probably won't get the thumbnail, so we can try the canvas, for this we have to not change the video to an image          
          // if(this.post.is_video && (this.post.network.name == 'instagram' || this.post.network.name == 'facebook')){
          //   // [canvas] this changes the video to an image, if you want to use canvas, comment this line
          //   media = this.post.display_url;
          // }else{
          //   // for all other cases we set the selected media to be the display_url, which is always an image (sometimes the default one)
          //   media = this.post.display_url;
          // }

          if(this.post.is_video){
            if(this.post.display_url && this.post.display_url != ''){
              media = this.post.display_url
            }else{
              media = this.defaultDataPicture
            }
          }

        }

        return prepareAsB64(media);
      },

      computedProfilePicture() {
        let original = this.post.profile_item.profile_picture_url;
        let custom = this.customPicture !== '' ? this.customPicture : original;
        return prepareAsB64(custom);
      },

      postMedia() {
        let items = [this.post.display_url];
        if (this.post.media && this.post.media.length) {
          items = this.post.media.map(m => m.url);
        }

        return items.filter(p => {
          return p && p != '';
        });
      }
    },

    watch: {
      // this watcher is set different because otherwise it was not picking
      // up when printView was changed from false to true on the first time you clicked the generate PDF button
      printView: {
        handler (val, old) {

          // printing
          if(val && val == true){

            // [canvas] we will use canvas only for instagram & fb videos
            if(this.post.is_video && (this.post.network.name == 'instagram' || this.post.network.name == 'facebook')){

              // this.useCanvas = true;
              
              // // wait for canvas element to render
              // setTimeout(() => {
              //   // we will grab the video element and basically screenshot it into a canvas element
              //   let canvas = document.getElementById(`canvas_${this.computedId}`);
              //   let video = document.getElementById(this.computedId);

              //   // video element might return unproportional video dimensions, so we have to adjust them to fit the canvas
              //   // we can always asume that the post width will be 272 so we can compute the height based on the 
              //   // video dimensions
                
              //   // (video width) = 272
              //   // (video height) = computedHeight
              //   const width = 272;
              //   const height = (video.videoHeight * 272) / video.videoWidth;
                
              //   // set the canvas dimensions to be the same
              //   canvas.width = width;
              //   canvas.height = height;
                
              //   // wait for canvas element to apply new dimensions
              //   setTimeout(() => {
              //     canvas.getContext('2d').drawImage(video, 0, 0, width, height);
              //   }, 1000);

              // }, 1000);

            }
          }else{
            // set to false when not printing
            this.useCanvas = false;
          }
        },
        immediate: true
      },

      isVideo(val) {
        if (val) {
          setTimeout(() => {
            let id = this.computedId;
            let obj = document.getElementById(id);

            if (obj) {
              obj.addEventListener('loadeddata', () => {
                if (obj.readyState >= 2) {
                  this.$emit('redraw');
                }
              });
            }
          }, 150);
        }
      },

      isExpanded() {
        setTimeout(() => {
          this.$emit('redraw');
        }, 50);
      },

      position() {
        this.$emit('redraw');
      },

      videoWidth() {
        this.processDimensions();
      },

      videoHeight() {
        this.processDimensions();
      }
    },

    data() {
        return {
          position: 0,
          isExpanded: true,
          isExpandable: false,
          selected: false,
          playing: false,
          hidePlayIcon: false,
          customPicture: '',
          defaultDataPicture: defaultPicture,
          mediaAttempts: 0,
          dimensionsAttempts: 0,
          videoWidth: 0,
          videoHeight: 0,
          tagWidth: 'unset',
          tagHeight: 'unset',
          isIos: /iPad|iPhone|iPod/.test(navigator.userAgent),
          acceptedVideo: true,
          loadingVideo: this.post.is_video,
          tries: 0,
          useEmbed: false,
          useCanvas: false,
          parseUnicodeCharacters,
        }
    },
    
    // wait for "video" element to be fully rendered to pause it 
    // wait for "caption" element to be fully rendered to check if its expandable 
    mounted() {
      this.$nextTick(() => {
        this.pauseVideo();
        this.checkCaptionIsExpandable();
      });
    },

    methods: {
      pauseVideo() {
        setTimeout(() => {
          let video = document.getElementById(this.computedId)
          if (video) {
            let pauseFn = (e) => {
              if (!this.tries) {
                this.tries++;
                try {
                  video.pause();
                } catch (e) {
                  console.log(e);
                } finally {
                  video.muted = false;
                  this.playing = false;
                  this.loadingVideo = false;
                  this.$emit('redraw');
                }
                video.removeEventListener('play', pauseFn);
              }
            }
            video.addEventListener('play', pauseFn);
          }
        }, 500);
      },
      
      togglePlay() {
        this.playing = true;
        this.loadingVideo = false;
        if (!this.tries) {
          this.pauseVideo();
        }
      },

      checkCaptionIsExpandable() {
        const { caption } = this.$refs
        const captionHeight = caption && caption.offsetHeight ? caption.offsetHeight : null
        if (captionHeight && captionHeight >= 72) {
          this.isExpanded = false;
          this.isExpandable = true;
        }
      },

      openUser() {
        if (this.profileUrl) {
          let a = document.createElement('a');
          a.href = this.profileUrl;
          a.target = '_blank';
          a.click();
        }
      },

      processDimensions() {
        if (this.post.is_video && this.videoHeight > 0 && this.videoWidth > 0) {
          let videos = document.getElementsByClassName(this.computedPostClass);
          if (videos.length) {
            let width = videos[0].offsetWidth;
            let ratio = this.videoHeight / this.videoWidth;
            this.tagWidth = width;
            this.tagHeight = width * ratio;
            this.$emit('redraw');
          }
        }
      },

      async processVideoDimensions(p) {
        if (p.videoWidth && p.videoHeight) {
          this.videoWidth = p.videoWidth;
          this.videoHeight = p.videoHeight;
        } else {
          let media = p.media.find(m => m.mimetype == 'video/mp4');
          if (media) {
              try {
                  let videoTag = document.createElement('video');
                  videoTag.src = media.url;
                  if (this.isIos) {
                    videoTag.muted = true;
                    videoTag.autoplay = true;
                    videoTag.playsinline = true;
                    videoTag.preload = 'metadata';
                  }
                  let instance = this;
                  videoTag.addEventListener('loadedmetadata', function () {
                      let { videoWidth, videoHeight, duration: video_duration } = this;
                      instance.updateMetadata({ id: p.id, mediaId: media.id, videoWidth, videoHeight, video_duration });
                  }, false);
              } catch (e) {
                  if (!this.dimensionsAttempts) {
                    this.dimensionsAttempts++;
                    this.processVideoDimensions();
                  } else {
                    console.error('Error while trying to process video dimensions', e);
                    this.acceptedVideo = false;
                  }
              }
          }
        }
      },

      async processVideoMetadata() {
          let p = this.post;
          if (p.media && !p.media.length) {
            console.error('Error while trying to process video metadata');
            this.acceptedVideo = false;
          }

          let loaded = false;

          if (p.is_video && p.media && p.media.length) {
            setTimeout(() => {
              let video = document.getElementById(this.computedId);
              if (video) {
                video.addEventListener('error', (event) => {
                  console.error('Video error event listener', event);
                  this.acceptedVideo = false;
                }, true);
              }
              if (this.acceptedVideo && video && video.play !== undefined) {
                video.muted = true;
                if (this.isIos) {
                  video.autoplay = true;
                  video.playsinline = true;
                }
                let isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > video.HAVE_CURRENT_DATA;

                if (!isPlaying) {
                  video.play().then(() => {
                    if (this.acceptedVideo) {
                      video.pause();
                      video.muted = false;
                      video.autoplay = false;
                      video.playsinline = false;
                    }
                    this.playing = false;
                    loaded = true;
                    this.$emit('loaded', true);
                    this.$emit('redraw');
                  }).catch((e) => {
                    if (!this.mediaAttempts) {
                      this.mediaAttempts++;
                      this.processVideoMetadata();
                    } else {
                      console.log('error', e);
                      this.$emit('loaded', false);
                    }
                  });
                }
              }
            }, 300);

            if (loaded) {
              this.processVideoDimensions();
            }
          }
      },

      boostedDataHtml(post){
        const insightsArr = getObjectValue(post, 'boosted_data.insights.data', null)
        if(isArray(insightsArr)) {
          const insightsData = insightsArr[0]
          if(isObject(insightsData)) {
            const actionsArr = insightsData.actions
            if(isArray(actionsArr)){
              return `<ul>
                ${actionsArr.map(element => `<li style='text-transform: capitalize'>${element.action_type.replaceAll('_', ' ').replaceAll('.', ' ')}: ${element.value}</li>`).join('</br>')}
              </ul>`
            }
          }
        }
        return ``;
      },

      async updateMetadata(post) {
          this.videoWidth = post.videoWidth;
          this.videoHeight = post.videoHeight;

          if (post.id) {
            this.$http.post(`/api/reports/posts/update-meta`, post);
          }
      },

      getPostStatBreakdown(property){
        const organic = this.post[property];
        const boosted = getBoostedData(this.post.boosted_data, property);
        const total = organic + boosted;
        const interactionsPercentage = this.statsQuotient(property);
        return { property, organic, boosted, total, interactionsPercentage, adCount: this.postStatAds(property).length };
      },

      postStatAds(property){
        return getPostPropertyAds(this.post.boosted_data, property);
      },
      
      isPostStatBoosted(property){
        return isPostPropertyBoosted(this.post.boosted_data, property);
      },

      getPostStat(property){
        let val = this.post[property];

        // Aggregate the boosted number if found, otherwise it returns 0
        val += getBoostedData(this.post.boosted_data, property);
        
        return parseInt(val);
      },

      totalInteractions(){
        const properties = ['likes', 'comments', 'views', 'shares', 'saves'];
        let count = 0;
        for (const property of properties) {
          const val = this.getPostStat(property);
          if(isNumber(val)){
            count += val;
          }
        }
        return count;
      },

      statsQuotient(property) {
        // We used to calculate the quotient with "this.post.profile_item.total_followers" instead of total interactions
        const total = this.totalInteractions();
        let val = this.getPostStat(property);
        if (isNumber(val)) {
          let value = (val * 100) / total;
          // If its a 0, toFixed will be 3, otherwise toFixed will be 1
          if(value.toFixed(0) == 0) return value.toFixed(3);
          return value.toFixed(1);
        }

        return 0;
      },

      engagementRate(formatted = true){
        const totalFollowers = this.post.profile_item.total_followers;
        const totalInteractions = this.totalInteractions();
        const engagementRate = (totalInteractions / totalFollowers) * 100;
        if(formatted){
          return `${Math.round(parseFloat(engagementRate).toFixed(2))}%`; 
        }else{
          return `${engagementRate.toFixed(2)}%`;
        }
      },

      play(){
        this.$refs.video.play()
        this.loadingVideo = false;
        this.playing = true
      },
      pause(){
        this.$refs.video.pause();
        this.playing = false
      },
      changeMedia() {
        let url = this.postMedia[this.position];
        let defaultThumbnail = this.post.is_video ? defaultVideo : defaultPicture;

        if (this.post.display_url == '') {
          this.post.display_url = defaultThumbnail;
        }

        if (this.post.display_url === url) {
          this.post.display_url = defaultThumbnail;
        } else {
          this.post.media = this.post.media.map(m => {
            if (m.url === url) {
              m.url = defaultThumbnail;
            }
            return m;
          });
        }
        this.$emit('redraw');
      },
      changeProfile() {
        this.customPicture = defaultProfile;
      },
      onVideoError(e){
        this.acceptedVideo = false;
        if(this.isTiktok){
          this.changeToEmbed();
        }else{
          this.changeToDisplay();
        }
      },
      changeToEmbed(){
        this.useEmbed = true;
        this.loadingVideo = false;
        this.hidePlayIcon = true;
        this.$forceUpdate();
      },
      changeToDisplay() {
        let url = this.postMedia[this.position];
        this.position = 0;
        let random1 = (Math.random() + 1).toString(36).substring(7);
        let random2 = (Math.random() + 1).toString(36).substring(7);
        let media = [{
          id: random1,
          url: this.post.display_url,
          mimetype: 'image/png',
          type: 'image/png'
        }, {
          id: random2,
          url: this.defaultDataPicture,
          mimetype: 'image/png',
          type: 'image/png'
        }];
        this.loadingVideo = false;
        this.hidePlayIcon = true;
        let mediaElements = this.post.media.filter(element => {
          return element.url !== url;
        });
        for (let mediaItem of mediaElements) {
          media.push(mediaItem);
        }
        this.post.media = media;
        this.$forceUpdate();
      },
      customizedDate(post) {
        let date = new Date(post.date);
        let tenYearsAgo = new Date();
        tenYearsAgo.setFullYear(tenYearsAgo.getFullYear() - 10);
        if (date < tenYearsAgo) {
          // * when dates are null, the year 1969 is returned, in those cases we use created_at, or return null
          // date = post.created_at;
          return '';
        }
        return moment(date).format('ll');
      },
      openLink(url) {
        window.open(url);
      },
      openDeliverable(option) {
        this.$router.push(`/deliverables/${option.value}`);
      }
    }
}
</script>
<style lang="scss" scoped>
  .report-post-card-container{
    @apply w-68 flex flex-col flex-no-wrap rounded overflow-hidden;
    .media-box{
      @apply relative;
      .warnings-header{
        @apply absolute top-1 right-1 z-80 flex gap-x-1;
        .exclamation-icon{
          @apply bg-yellow-exclamation h-5 w-5 rounded-full items-center flex justify-center;
        }
        .boosted-icon{
          @apply bg-blue-400 h-5 w-5 rounded-full items-center flex justify-center;
        }
      }
      .dots-navigation{
        @apply flex px-4 absolute top-1 inset-x-0 gap-1 z-80 bg-transparent justify-center items-center;
        .dot-container{
          @apply bg-gray-400 bg-opacity-60 h-1 pt-1 mt-0.5 rounded relative;
          .dot{
            @apply bg-white h-1 rounded absolute top-0 inset-x-0;
          }
        }
      }
      .left-navigation{
        @apply absolute left-2 flex items-center h-full z-80;
        > button{
          @apply text-white z-80 bg-gray-300 rounded-full flex justify-center items-center;
          &:focus{
            @apply outline-none;
          }
          &:hover{
            @apply bg-gray-400;
          }
        }
      }
      .right-navigation{
        @apply absolute right-2 flex items-center h-full z-80;
        > button{
          @apply text-white z-80 bg-gray-300 rounded-full flex justify-center items-center;
          &:focus{
            @apply outline-none;
          }
          &:hover{
            @apply bg-gray-400;
          }
        }
      }
      .play-button{
        @apply absolute h-full w-full flex items-center text-center justify-center cursor-pointer z-70;
      }
      .loader{
        @apply absolute h-full w-full flex items-center text-center justify-center text-white cursor-pointer z-70;
      }
      > video{
        @apply animate-fade-in z-70; 
      }
    }
    .post-info{
      @apply flex flex-col;
      .general-info{
        @apply flex items-center relative gap-1;
        .network-icon{
          @apply absolute top-0 right-0;
        }
        .avatar{
          @apply w-1/6;
        }
        .name-date{
          @apply w-full;
          padding-right: 20px;
          .name{
            @apply font-bold capitalize text-sm text-left;
          }
          .date{
            @apply text-gray-m-disable text-xs text-left;
          }
        }
      }
      .caption{
        @apply mt-4 text-gray-m-disable text-sm overflow-x-hidden leading-6;
        text-overflow: ellipsis;
        // initial state
        &.not-expanded {
          @apply overflow-y-hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          transition: max-height 3s cubic-bezier(0, 1, 0, 1);
        }
        &.not-expanded{
          @apply max-h-20;
        }
      }
      .show-more{
        @apply text-right;
        > span{
          @apply cursor-pointer text-xs font-bold text-gray-800;
        }
      }
    }
    .stats{
      @apply flex;
      .space{
        @apply flex-none;
      }
      .stats-wrapper{
        @apply flex-grow text-center;
        .stats-grid {
          @apply grid grid-cols-2 items-center;
          .stats-item:last-child:nth-child(odd) {
            grid-column: span 2 / span 2;
          }

          .stats-item:nth-last-child(2):nth-child(odd),
          .stats-item:last-child:nth-child(even) {
            grid-column: span 1 / span 1;
          }
        }
      }
    }
  }
</style>


<template>
    <div class="container">
        <div class="content">
            <div class="header">
                <div class="flex gap-x-4">
                    <div class="font-bold mb-2">{{data.name}}</div>
                    <base-icon v-if="loading" name="loader" />
                </div>
                <div class="flex items-center gap-x-2">
                    <div>{{selected.length}} selected</div>
                        <base-icon 
                            name="minus-circle" 
                            :size="6" 
                            class="text-gray-500 cursor-pointer hover:opacity-50 transition-opacity duration-200" 
                            @action="selected = []"
                            v-tooltip="{ 
                                content: 'Clear Selection', 
                                classes: 'bg-black text-white rounded py-1 px-2 text-xs', 
                                delay: { 
                                    show: 30,
                                    hide: 50 
                                } 
                            }"
                        /> 
                        <base-icon 
                            :name="onlySelected ? 'eye-closed' : 'eye'" 
                            :size="6" 
                            class="text-gray-500 cursor-pointer hover:opacity-50 transition-opacity duration-200"
                            @action="(!selected.length && !onlySelected) ? null : onlySelected = !onlySelected"
                            v-tooltip="{ 
                                content: onlySelected ? 'Hide Selected' : `View Selected ${(!selected.length && !onlySelected) ? `, none selected yet` : ``}`,
                                classes: 'bg-black text-white rounded py-1 px-2 text-xs',
                                placement: 'left',
                                delay: { 
                                    show: 30,
                                    hide: 50 
                                } 
                            }"
                        />
                    
                </div>
            </div>

            <filter-select
                placeholder="Search states"
                :loading="loading"
                :options="options"
                v-model="selected"
                :onlySelected="onlySelected"
                @toggle-only-selected="(option) => onlySelected = option"
                @search="getOptions"
            >
                <div slot-scope="{ option }" class="flex gap-x-2 items-center">
                    <div class="font-bold">{{option.label}}</div>
                    <div class="text-pxs text-gray-600">{{option.country}}</div>
                </div>   
            </filter-select>

        </div>
        <div class="footer">
            <base-button
                :label="`${filters.state ? `Update Filter` : `Add Filter`}`"
                icon="filter"
                size="lg"
                @action="addFilter"
            />

            <base-button
                type="secondary"
                label="Cancel"
                @action="$emit('close')"
            />
        </div>
    </div>
</template>

<script>
import FilterSelect from "../FilterSelect.vue"
export default {
    props: ['data', 'filters'],
    components: { 
        FilterSelect 
    },
    data(){
        return {
            options: [],
            selected: [],
            onlySelected: false,
            loading: false
        }
    },
    mounted(){
        this.getOptions()
        if(this.filters.state){
            this.selected = this.filters.state
        }
    },
    methods: {
        async getOptions(query){
            this.loading = true
            try {
                const { data } = await this.$http.get(`/api/facebook/geos`, {
                    params: {
                        ...(query && query != '' ? { query: query } : {}),
                        type: 'state'
                    }
                })
                this.options = data.map(state => {
                    return {
                        label: state.name,
                        name: state.name,
                        country: state.country_name,
                        value: state.name
                    }
                })
            } catch(e) {
                console.log(e)
            } finally{
                this.loading = false
            }
        },
        addFilter(){
            this.$emit('addFilter', 'state', this.selected)
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply relative h-full flex flex-col;
        .content{
            @apply flex-grow flex-shrink;
            .header{
                @apply flex justify-between items-center;
            }
        }
        .footer{
            @apply sticky bottom-0 left-0 right-0 py-5 bg-tan-m flex justify-between items-center;
        }
    }
</style>
<template>
    <div class="network-select" :class="containerClass">
        
        <div class="limit" v-if="!availableNetworks.length && !selectedNetworks.length">
            No more social accounts can be added
        </div>
        
        <!-- Network List -->
        <div :class="itemClass || 'network'" v-for="(selectedNetwork, index) in selectedNetworks" :key="index">
            
            <!-- Remove network button -->
            <base-button 
                class="absolute top-12 -left-8"
                v-if="index"
                @action="removeSelectedNetwork(selectedNetwork)" icon="trash2" type="label"
            />

            <!-- Network select field -->
            <div :class="elementClass || 'network-select'">
                <div class="title" v-if="externalLabel">Social Platform</div>
                <custom-select
                    :label="externalLabel ? undefined : 'Social platform'"
                    :options="availableNetworks"
                    v-model="selectedNetworks[index]"
                    :key="`${customSelectKey}_index`"
                />
                <base-button 
                    type="label" 
                    class="my-2"
                    v-if="selectedNetwork.id !== undefined && selectedNetwork.is_custom" 
                    @action="triggerAdvanced(selectedNetwork)" 
                    label="Advanced Custom Network" 
                    icon="plus-circle" 
                    bold 
                />
            </div>

            <!-- Network Handle -->
            <div :class="handleClass || elementClass || 'network-handle-container'">
                
                <!-- Not custom network -->
                <div :class="innerClass || 'handle'" v-if="selectedNetwork.id && !selectedNetwork.is_custom">
                    <div class="title" v-if="externalLabel">{{ selectedNetwork.prefixedUrl }}</div>
                    <network-handle
                        v-if="selectedNetwork.id"
                        :label="selectedNetwork.prefixedUrl"
                        :name="selectedNetwork.name"
                        :slug="selectedNetwork.slug"
                        :network="selectedNetwork"
                        v-model="values[selectedNetwork.valuesKey]"
                        @toggle-search="(val) => $emit('toggle-search', val)"
                        @users-exists="(users) => $emit('users-exists', users)"
                    />
                </div>

                <!-- Custom network form -->
                <div class="custom-network-form" :class="[innerClass || '', cols]" v-if="selectedNetwork.id !== undefined && selectedNetwork.is_custom">
                    <div class="field">
                        <div class="label" v-if="!customLabel">URL</div>
                        <FormulateInput type="text" placeholder="Social URL" v-model="values[selectedNetwork.valuesKey].social_url"/>
                    </div>
                    <!-- facebook only -->
                    <div class="field" v-if="selectedNetwork.id === 6">
                        <div class="label" v-if="!customLabel">Facebook Page ID</div>
                        <FormulateInput type="text" placeholder="Page ID" v-model="values[selectedNetwork.valuesKey].fb_page_id"/>
                    </div>
                    <div class="field">
                        <div class="label" v-if="!customLabel">Avg Likes</div>
                        <FormulateInput type="text" placeholder="Avg Likes" v-model="values[selectedNetwork.valuesKey].payload.user_profile.avg_likes"/>
                    </div>
                    <div class="field">
                        <div class="label" v-if="!customLabel">Followers</div>
                        <FormulateInput type="text" placeholder="Followers" v-model="values[selectedNetwork.valuesKey].payload.user_profile.followers"/>
                    </div>
                    <div class="field">
                        <div class="label" v-if="!customLabel">Username</div>
                        <FormulateInput type="text" placeholder="Username" v-model="values[selectedNetwork.valuesKey].payload.user_profile.username"/>
                    </div>
                    <div class="field">
                        <div class="label" v-if="!customLabel">Location</div>
                        <FormulateInput type="text" placeholder="Location" v-model="values[selectedNetwork.valuesKey].payload.user_profile.geo.city.name"/>
                    </div>
                </div>

            </div>
        </div>

        <!-- Add platform button -->
        <base-button 
            v-if="!(selectedNetworks.length == networks.length) && availableNetworks.length && !minimized"
            @action="addEmptySelectedNetwork"
            class="my-4" type="label" icon="plus"
            justify="left"
            bold
            label="Add platform"
        />

        <!-- only on create report modal -->
        <div v-if="minimized" class="flex gap-2">
            <base-button label="Cancel" rounded="lg" bold theme="dark" type="secondary" @action="$emit('cancel')"/>
            <base-button label="Save" rounded="lg" bold theme="dark" @action="$emit('accept')"/>
        </div>

        <advanced-audience
          :open="advancedAudience !== null"
          :currentAudience="advancedAudience"
          @close="advancedAudience = null"
          @save-audience="saveAdvancedAudience"
        />
    </div>
</template>

<script>
    import NetworkHandle from './NetworkHandle';
    import {customNetwork} from '../common';
    import AdvancedAudience from '@/views/CreatorEdit/AdvancedAudience';

    export default {
        components: {
            NetworkHandle,
            AdvancedAudience
        },
        props: {    
            values: {
                type: Object,
                required: true
            },
            excludedNetworks: {
                type: Array,
                required: false,
                default: function () {
                    return []
                }
            },
            containerClass: {
                type: String,
                default: ''
            },
            cols: {
                type: String,
                default: 'grid-cols-5'
            },
            customLabel: {
                type: Boolean,
                default: false
            },
            itemClass: {
                type: String,
                default: ''
            },
            elementClass: {
                type: String,
                default: ''
            },
            handleClass: {
                type: String,
                default: ''
            },
            innerClass: {
                type: String,
                default: ''
            },
            externalLabel: {
                type: Boolean,
                default: false
            },
            showCustom: {
                type: Boolean,
                default: false
            },
            minimized: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                networks: [
                    {
                        id: 2,
                        name: 'Instagram', // display & placeholder
                        slug: 'instagram', // keys - ids - v-model - socialdata call
                        valuesKey: 'instagram_handle', // values prop object key
                        prefixedUrl: 'instagram.com/',
                        suggested: {
                            loading: false,
                            show: false,
                            items: [] 
                        }
                    },
                    {
                        id: 14,
                        name: 'Tiktok',
                        slug: 'tiktok',
                        valuesKey: 'tiktok_handle',
                        prefixedUrl: 'tiktok.com/@',
                        suggested: {
                            loading: false,
                            show: false,
                            items: [] 
                        }
                    },
                    {
                        id: 3,
                        name: 'Youtube',
                        slug: 'youtube',
                        valuesKey: 'youtube_handle',
                        prefixedUrl: 'youtube.com/',
                        suggested: {
                            loading: false,
                            show: false,
                            items: []
                        }
                    },
                    {
                        id: 4,
                        name: 'Pinterest',
                        slug: 'pinterest',
                        valuesKey: 'pinterest_handle',
                        prefixedUrl: 'pinterest.com/',
                        is_custom: true,
                    },
                    {
                        id: 6,
                        name: 'Facebook',
                        slug: 'facebook',
                        valuesKey: 'facebook_handle',
                        prefixedUrl: 'facebook.com/',
                        is_custom: true,
                    },
                    {
                        id: 7,
                        name: 'Twitter',
                        slug: 'twitter',
                        valuesKey: 'twitter_handle',
                        prefixedUrl: 'twitter.com/',
                        is_custom: true,
                    },
                    {
                        id: 16,
                        name: 'LinkedIn',
                        slug: 'linkedin',
                        valuesKey: 'linkedin_handle',
                        prefixedUrl: 'linkedin.com/in/',
                        is_custom: true,
                    },
                    {
                        id: 17,
                        name: 'Custom Platform',
                        platform: {
                          value: '',
                          label: 'Select'
                        },
                        slug: 'custom',
                        valuesKey: 'custom_handle',
                        prefixedUrl: '',
                        is_manual: true,
                        is_custom: true,
                    }
                ],

                selectedNetworks: [],
                selectedNetwork: null,

                customSelectKey: 0,
                advancedAudience: null
            }
        },
        mounted(){
            // check if values.instagram_handle or values.tiktok_hanlde exist
            // if they do, add selected network
            if(this.values != {}){
                this.networks.forEach(network => {
                    if(this.values[network.valuesKey]) {
                        if (!network.is_custom || (network.is_custom && this.values[network.valuesKey].user_id !== undefined)) {
                            this.selectedNetworks.push(network);
                        }
                    }
                });
            }

            if (!this.selectedNetworks.length) {
                this.addEmptySelectedNetwork();
            }
        },
        computed: {
            availableNetworks() {
                return this.networks.filter(el => {
                    let is_custom = this.showCustom || (!this.showCustom && !el.is_custom);
                    return is_custom && !this.excludedNetworks.find(excludedNetwork => excludedNetwork.id == el.id)
                }).map(el => {
                    el.disabled = this.selectedNetworks.includes(el);
                    return el;
                });
            },
            manualAvailableNetworks() {
                let networks = this.networks.map(el => {
                  el.disabled = this.selectedNetworks.includes(el);
                  return el;
                }).filter(el => {
                  return !el.is_custom && !el.disabled && !this.excludedNetworks.find(excludedNetwork => excludedNetwork.id == el.id)
                });

                networks.unshift({
                  id: 17,
                  name: "Other network",
                  slug: "other",
                  valuesKey: "other_handle",
                  prefixedUrl: "network/",
                  disabled: false
                });
                return networks;
            }
        },
        methods: {
            triggerAdvanced(selectedNetwork) {
              this.selectedNetwork = selectedNetwork;
              this.advancedAudience = this.values[selectedNetwork.valuesKey];
            },
            addEmptySelectedNetwork(){
                this.selectedNetworks.push({id:'', name: 'Select platform'})
            },
            removeSelectedNetwork(selectedNetwork){
                this.values[selectedNetwork.valuesKey] = selectedNetwork.is_custom ? customNetwork(selectedNetwork.id) : '';
                this.selectedNetworks = this.selectedNetworks.filter(item => item.id !== selectedNetwork.id);
                this.customSelectKey++;
            },
            saveAdvancedAudience(audience) {
              this.values[this.selectedNetwork.valuesKey] = audience;
              this.selectedNetwork = null;
              this.advancedAudience = null;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .network-select{
        .limit{
            @apply pb-6;
        }
        .network{
            @apply flex justify-between gap-x-4 mb-4 relative;
            .network-select{
                @apply w-1/2;
                .title{
                    @apply text-h6 font-bold;
                }
            }
            .network-handle-container{
                @apply w-1/2;
                .handle{
                    @apply relative;
                    .title{
                        @apply text-h6 font-bold;
                    }
                }
            }
            .custom-network-form{
                @apply grid gap-5;
                .field{
                    .label{
                        @apply text-h6 font-bold;
                    }
                }
            }
        }
    }
</style>

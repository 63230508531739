
<template>
<div>

    <Title type="main">Base Table</Title>

    <Title>Usage</Title>

    <Code title="Usage for a simple table:" :code='`
        <template>
            <base-table
                :columns="[
                    { name: "name", label: "Username" },
                    { name: "company", label: "Company" },
                    { name: "city", label: "City" },
                    { name: "state", label: "State" }
                ]"
                :data="[
                    { name: "Joe James", company: "Test Corp", city: "Yonkers", state: "NY" },
                    { name: "John Walsh", company: "Test Corp", city: "Hartford", state: "CT" },
                    { name: "Bob Herm", company: "Test Corp", city: "Tampa", state: "FL" },
                    { name: "James Houston", company: "Test Corp", city: "Dallas", state: "TX" },
                ]"
            />
        </template>
        <script>

        import BaseTable from "../components/BaseTable"

        export default {
            components: {
                BaseTable
            }
        }

        </script>
    `'/>

    <Code :initialState="true" title="Usage with custom options:" :code='`
    <template>
        <base-table
            :columns="[
                {
                    name: "name",
                    label: "Username",
                    value: (user) => {
                        return user.name
                    }
                },
                {
                    name: "budget",
                    label: "Budget",
                    type: "price"
                },
                {
                    name: "roles",
                    label: "Roles",
                    filter: {
                        options: [{},{},...],
                        placeholder: "Roles",
                        type: "select",
                        allSelectedPlaceholder: "All Roles"
                    }
                },
                {
                    name: "posts",
                    label: "Posts",
                    searchBy: customSearchBy
                },
                {
                    name: "country",
                    label: "Country",
                    hidden: true
                }
            ]"
            :data="[
                { first_name: "", last_name: "", budget: 25000, roles: [], posts: [], country: "" },
                ...
            ]"
            :rowActions="[
                {
                    label: "Edit", 
                    icon: "pencil-alt", 
                    event:"edit",
                    separator: true,
                    handler: (user) => {
                        $router.push("/users/user.id")
                    }
                }
            ]"
        />
    </template>
    <script>

    import BaseTable from "../components/BaseTable"

    export default {
        components: {
            BaseTable
        },
        methods: {
            customSearchBy(user){
                return posts.map(post => post.name).toString().toLowerCase()
            }
        }
    }

    </script>
    `'/>

    <Title>Props</Title>

    <Table
        title="The component accepts the following props:"
        :columns="['Name', 'Type', 'Required', 'Description']"
        :data="[
            ['columns', 'array', 'true', 'Columns used to describe table. Must be an array of objects describing a column'],
            ['data', 'array', 'true', 'Data used to describe table. Must be an array containing objects of key/value pairs.'],
            ['rowActions', 'array', 'false', `Actions available to be shown in each row's dropdown, must be an array of objects. [More details in here, #row-actions]`],
            ['bulkActions', 'array', 'false', `Actions available to be applied on all selected elements, must be an array of objects. If this prop is false, the row selection checkboxes will be hidden. [More details in here, #bulk-actions]`],
            ['loading', 'boolean', 'false', 'Shows an skeleton loader for the table'],
            ['pinnedElements', 'array', 'false', 'Row elements that should be pinned to the top, must be an array of element ids. If this prop is set, it will add the pinned elements column on the table and emit the pinning events. [More details in here, #pinned-elements]']
        ]"
    />

    <Title>Column options</Title>

    <Table
        title="The column object accepts the following options:"
        :columns="['Name', 'Type', 'Required', 'Description']"
        :data="[
            ['name', 'string', 'true', 'Name for the column (used as identifier, preffered snake_case). Its also used to look for the value on the row data object. (Ex. if column name is email, it will look for user.email to get the email )'],
            ['label', 'string', 'true', 'label'],
            ['type', 'string', 'false', 'The type of the data, represents how it will be displayed on the data cell. By default it will display as a String. [All datatypes here, #column-datatypes]'],
            ['onClick', 'function', 'false', `Only needed when type == 'link'. Must be a handler function for the link click, the row's element is passed a parameter`],
            ['value', 'function', 'false', `If the value for the cell is something more complex than a string or number, for example an array or object, then you can use value to return whatever value you like, it passes the row's element as parameter.`],
            ['options', 'object', 'false', `Additional options for the column, currently the only available options are: cellClasses and cellStyles. (ex. { options: { cellClasses: ..., cellStyles: ... } })`],
            ['filter', 'object', 'false', `Display column in filter list, must be an object with all the filter options. [More on filters here, #column-filter-options]`],
            ['searchBy', 'function', 'false', `This option may be needed in case that you want to sort, filter or search through data that it not a string or number (an array or object for example), the row's element is passed as parameter. The function must return a string by which the data will be searched through.`],
            ['sort', 'boolean', 'false', `Default true. By default all columns are sortable, use false if you dont want to consider this column as sortable`],
            ['hidden', 'boolean', 'false', `Hides the column but you can still search or filter through it.`]
        ]"
    />

    <Title>Column Datatypes</Title>
    <Table
        title="These are the different datatypes for columns"
        :columns="['Name', 'Expected Data', 'Description']"
        :data="[
            ['string', 'string', 'Will display plain text'],
            ['date', 'datetime', 'Will display a date with the month/day/year format. Data must be formatted like 2021-06-29T21:29:22.000Z'],
            ['price', 'number', 'Will display a formatted price with prefixed dollar sign, separation commmas and decimals'],
            ['link', 'string', `Will display a clickable text, will also emit('on-click'), so you must add the @onClick to the column option.`],
            ['avatar', 'object', `Will display an avatar or the users initials, expected data is an object that has the following: { first_name: '', last_name: '', user: '' }. This is let the avatar component decide if it can show the image or just the users initials.`],
            ['avatar-list', 'object', `Will display a list of avatars or  users initials, expected data is an array of object that have the following: { first_name: '', last_name: '', user: '' }. This is let the avatar component decide if it can show the image or just the users initials.`],
            ['status', 'object', 'Status dropdown specific to projects, expected data is a project object (with an id and a statusLabel)'],
            ['role-list', 'array', 'Display a list of roles, expected data must be an array of role objects (with a display_name)']
        ]"
    />

    <Title>Column Filter Options</Title>
    <Table
        title="The column filter object requires the following options:"
        :columns="['Name', 'Type', 'Description']"
        :data="[
            ['options', 'array', 'All the filter options. Must be an array of objects with: { value, label }. Label will be used just for display and value as the actual searchable string'],
            ['type', 'string', `Type of the filter, currently we only support 'select'.`],
            ['placeholder', 'string', 'Displayed on the filter component when no option is selected.'],
            ['allSelectedPlaceholder', 'string', `Displayed as the first filter option, where all options are selected. Should be something like 'All Categories'.`]
        ]"
    />

    <Title>Bulk Actions</Title>
    <Paragraph>Bulk actions can be applied to all the elements that the user selects. The column's bulkActions option should be an array objects with the following options.</Paragraph>

    <Title type="subtitle">name</Title>
    <Paragraph>The name of the action, must be an string, currently we only support the "boardAssign" action.</Paragraph>

    <Title type="subtitle">addToBoard</Title>
    <Paragraph>Handler to be executed when the user has selected projects, a board, and clicks the `assign to board` button.</Paragraph>
    
    <Code title="Ex." language="javascript" :code='`
        {
            addToBoard: (selectedBoard, selectedProjects) => {
                addProjectsToBoard(selectedBoard, selectedProjects)
            }
        }
    `'/>

    <Title type="subtitle">removeFromBoard</Title>
    <Paragraph>Handler to be executed when the user has selected projects, a board, and clicks the `remove from board` button.</Paragraph>

    <Code title="Ex." language="javascript" :code='`
        {
            removeFromBoard: (selectedBoard, selectedProjects) => {
                removeProjectsFromBoard(selectedBoard, selectedProjects)
            }
        }
    `'/>

    <Title>Row Actions</Title>
    <Paragraph>Row actions are shown on each row, a dropdown is shown when the user clicks the three dots icon. The column's rowActions option should be an array objects with the following options.</Paragraph>

    <Title type="subtitle">label</Title>
    <Paragraph>Used just for displaying the action name. Must be a string</Paragraph>

    <Title type="subtitle">icon</Title>
    <Paragraph>Used to render an icon by name. Must be a string</Paragraph>

    <Title type="subtitle">iconColor</Title>
    <Paragraph>Color of the icon. Must be a color or hex string</Paragraph>

    <Title type="subtitle">event</Title>
    <Paragraph>Used as an identifier for the fired event. Must be a unique string</Paragraph>

    <Title type="subtitle">separator</Title>
    <Paragraph>Render a separator on top of the action to differentate it from the rest. Must be a boolean</Paragraph>

    <Title type="subtitle">handler</Title>
    <Paragraph>Handler to be executed when the user clicks on the action, sends the row's element as a parameter.</Paragraph>
    
    <Code title="Ex." language="javascript" :code='`
        handler: (user) => {
            $router.push("/users/user.id")
        }
    `'/>

    <Code title="Full example" language="javascript" :code='`
        :rowActions="[
            {
                label: "Edit", 
                icon: "pencil-alt", 
                event:"edit",
                separator: true,
                handler: (user) => {
                    $router.push("/users/user.id")
                }
            },
            {
                label: "Delete", 
                icon: "trash", 
                event: "delete",
                iconColor: "red", 
                handler: (user) => {
                    deleteUser(user)
                } 
            },
        ]"
    `'/>

    <Title>Pinned Elements</Title>
    <Paragraph>If this table option is set, the pinned elements column will appear and an action handler will be needed. element id and isPinned are passed as parameters</Paragraph>

    <Code title="Ex." language="javascript" :code='`
        @on-toggle-pinned="togglePinned"
        // ...
        async togglePinned(id, isPinned) {
            // update your backend
        }, 
    `'/>
</div>
</template>
<script>
import Code from '../Code.vue'
import Table from '../Table.vue'
import Title from '../Title.vue'
import Paragraph from '../Paragraph.vue'
export default {
    components: {
        Code,
        Table,
        Title,
        Paragraph
    }
}
</script>     
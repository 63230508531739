<template>
    <component :is="reportType" v-if="report && report.type" :report="report" :networks="networks" :can-edit="canEdit" />
</template>

<script>
import CreatorReport from "./CreatorReport";
import PostReport from "./PostReport";
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";

const NETWORKS = {
    2: {
        name: "instagram",
        label: "Instagram",
    },
    3: {
        name: "youtube",
        label: "Youtube",
    },
    14: {
        name: "tiktok",
        label: "Tiktok",
    },
};

export default {
    components: {
        Loading,
        CreatorReport,
        PostReport,
    },

    mounted() {
        this.getData();
    },

    data() {
        return {
            report: {},
            loading: true,
        };
    },

    computed: {
        ...mapGetters(["me"]),

        reportType() {
            return this.report.type === 1 ? CreatorReport : PostReport;
        },
        networks() {
            return NETWORKS;
        },
        canEdit() {
            return this.me ? this.me.roles.includes("admin") || this.me.roles.includes("superAdmin") : false;
        },
    },

    methods: {
        async getData() {
            try {
                const { data } = await this.$http.get(`/api/reports/v2/${this.$route.params.id}`);
                this.report = data;
            } catch (e) {
                console.log("error fetching report", e);
            }
        },
    },
};
</script>

<style lang="scss"></style>

<template>
<div class="container">

    <div class="header">
        <img class="logo" src="../../assets/images/logo.svg">
        <div class="title">
            Facebook Page Setup
        </div>
        <div class="subtitle">
            Set up your facebook page account so that the business can manage your ads and analyze data.
        </div>
    </div>

    <div class="content">
        <div>
            <base-icon
                v-if="loading"
                name="loader"
            />
            <template v-if="!done">
                <wizard v-if="userFbPage" @done="submit" />
            </template>
            <template v-else>
                <div class="text-center">
                    <base-icon name="check" :size="16"/>
                    <div class="text-h4 font-bold mt-2">Facebook page ID set successfully</div>
                    <div class="text-h6 mt-2">We will send a permission request soon, be sure to check the notification tray of your facebook page</div>
                </div>
            </template>

        </div>
    </div>

</div>
</template>
<script>
import Wizard from './Wizard.vue'
export default {
    components: {
        Wizard
    },
    data() {
        return {
            loading: false,
            userFbPage: null,
            done: false
        }
    },
    mounted(){
        this.getUserFbPage()
    },
    methods: {
        getUserFbPage() {
            if(this.$route.query.token){
                this.loading = true
                this.$http.get(`/api/user-fb-page?token=${this.$route.query.token}`).then(res => {
                    if(res.data){
                        this.userFbPage = res.data
                    }
                }).catch(err => {
                    console.log('getUserFbPage error', err)
                    if (err.response.data) this.$notify({ title: 'Error', text: err.response.data, type: 'error' })
                }).finally(() => {
                    this.loading = false
                })
            }else{
                this.$swal.fire({
                    title: `Token not found`,
                    text: `Contact an admin for permission to set your facebook page ID`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'OK',
                    buttonsStyling: false,
                    reverseButtons: true,
                    focusCancel: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        console.log('asd')
                    }
                })
            }
        },
        async submit(pageId) {
            this.loading = true
            this.$http.put(`/api/user-fb-page/${this.userFbPage.id}`, {
                page_id: pageId
            }).then(res => {
                if(res.data){
                    this.$notify({ title: 'Success', text: 'Facebook page ID set successfully', type: 'success' });
                    console.log(res.data)
                    this.done = true
                }
            }).catch(err => {
                console.log('submit error', err)
                if (err.response.data) this.$notify({ title: 'Error', text: err.response.data, type: 'error' })
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply min-h-screen bg-purple-m-main flex flex-col items-center px-8 overflow-y-auto;
        .header{
            @apply mt-28 flex flex-col items-center;
            width: 500px;
            .logo{
                @apply h-12 w-auto;
            }
            .title{
                @apply mt-6 text-2xl text-center leading-9 font-extrabold text-white;
            }
            .subtitle{
                @apply mt-4 text-lg text-center text-white;
            }
        }
        .content{
            @apply mt-8 mb-12 bg-white py-8 px-4 shadow rounded-lg;
            width: 500px;
        }
    }
</style>
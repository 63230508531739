<template>
    <div class="w-full h-full flex justify-center items-start">
        <div class="flex flex-col items-center gap-y-3 p-16">
            <base-icon v-if="icon" :name="icon" class="text-gray-m-disable" />
            <div class="h-8" v-else></div> 

            <span class="text-lg font-medium text-gray-m-disable">{{ message }}</span>

            <base-button v-if="action" @action="$emit('action')" :label="action"/>
            <template v-else-if="$slots.default">            
                <slot />            
            </template>
            <div v-else class="h-10"></div> 
        </div>
    </div>
</template>
<script>
export default {
    props: {
        icon:{
            type: String            
        },
        message:{
            type: String,
            default: 'Empty section'
        },
        action:{
            type: String            
        }
    },
}
</script>

{/*
    ---------------------
    Basic Implementation
    ---------------------

    <div class="container">This element will not be affected</div>

    <test-component
        :classes="{
            container: 'bg-red-100 cursor-pointer',
            wrapper: 'bg-blue-100'
        }"
        :styles="{
            container: {
                'background-color': 'red',
                'height': '100px'
            }
        }"
    />

    ---------------------
    Inside Test Component
    ---------------------

    <template>
        <div class="container"> This element WILL be affected
            <test-component-2 /> This element wont be affected either 
        </div>
    </template>

    <script>
    import { cssApplier } from '../lib/cssApplier';
    export default {
        mixins: [cssApplier]
    }
    </script>

*/}

var cssApplier = {
    props: {
        classes: {
            type: Object,
            required: false
        },
        styles: {
            type: Object,
            required: false
        }
    },
    data(){
        return {
            ranOnce: false,
            allAppliedOnFirstRun: false,

        }
    },
    updated: function () {
        this.$nextTick(function () {
            
            if(this.classes || this.styles){
                if(!this.ranOnce || !this.allAppliedOnFirstRun){

                    let errors = 0
                    for (const key in this.classes) {
                    
                        if (Object.hasOwnProperty.call(this.classes, key)) {
                             
                            // key => target class
                            let newClasses = this.classes[key] // str
        
                            // convert new classes string to an array
                            newClasses = newClasses.trim()
                            if(/\s/g.test(newClasses)){ // if string has space characters we separate them
                                newClasses = newClasses.split(' ')
                            }else{
                                newClasses = [newClasses]
                            }
        
                            let elements = this.findElementsByClassName(key)
        
                            // if elements are not found, then we will have to check again
                            // when the component is updated and apply them then
                            if(!this.ranOnce && !elements.length){
                                errors++
                            }
                            
                            // apply classes for all found elements
                            for (const element of elements){
                                this.applyClasses(element, newClasses)   
                            }
        
                        }
        
                    }
        
                    for (const key in this.styles) {
                        
                        if (Object.hasOwnProperty.call(this.styles, key)) {
                            
                            // key => target class
                            let newStyles = this.styles[key] // obj
                            
                            let elements = this.findElementsByClassName(key)
                            
                            // if elements are not found, then we will have to check again
                            // when the component is updated and apply them then
                            if(!this.ranOnce && !elements.length){
                                errors++
                            }
        
                            // apply styles for all found elements
                            for (const element of elements){
                                this.applyStyles(element, newStyles)   
                            }
                        
                        }
                        
                    }
                    
                    if(!this.ranOnce){
                        this.allAppliedOnFirstRun = !errors
                    }
                    this.ranOnce = true
                }
            }

        })
    },
    methods: {
        findElementsByClassName: function(className){

            let elements = []
                    
            // search root element
            if( this.$el.classList.contains(className) ) {
                elements.push(this.$el)
            }

            const excludeClass = 'childComponentExcludeClass'

            // apply class to all sub components, so that applied styles and classes dont bleed into them (scope them)
            for (const child of this.$children) {
                child.$el.classList.add(excludeClass)
            }

            // search elements inside root
            if( this.$el.querySelectorAll(`.${className}:not(.${excludeClass} *)`) ){
                const arr = Array.from( this.$el.querySelectorAll(`.${className}:not(.${excludeClass} *)`) )
                elements = elements.concat(arr)
            }

            // remove the class for all sub components
            for (const child of this.$children) {
                child.$el.classList.remove(excludeClass)
            }

            return elements

        },
        applyClasses: function(element, classes) {
            for (const className of classes){
                element.classList.add(className)
            }
        },
        applyStyles: function (element, styles) {
            for (const property in styles) {
                if (Object.hasOwnProperty.call(styles, property)) {
                    let value = styles[property]
                    element.style[property] = value
                }
            }
        }
    }
}

export { cssApplier };

<template>
    <div class="general-form">

        <FormulateInput
            type="text"
            label="Report Name"
            class="w-full"
            placeholder="Enter report name"
            validation="required"
            validation-name="Report name"
            :value="reportName"
            @input="val => $emit('update:report-name', val)"
        />

        <custom-select
            label="Report Type"
            placeholder="Select the report type"
            :options="types"
            :required="true"
            size="base"
            validation-name="Report type"
            :value="type"
            @input="val => val ? $emit('update:type', val) : null"
        />

        <custom-select
            label="Project"
            v-if="!projectId"
            :options="computedProjects"
            placeholder="Select the project for this report"
            :value="customProject"
            @input="val => $emit('update:custom-project', val)"
        />

        <custom-pills
            class="w-full"
            :class="!projectId ? '' : 'col-span-2'"
            label="Tags"
            placeholder="Select the report tag(s)"
            :options="reportTags"
            allow-create
            search
            create-label="Create new tag"
            model-name="Tag"
            :create-route="`/api/tags`"
            :create-body="{type: 'reports'}"
            :multiple="true"
            size="auto"
            :value="selectedTags"
            @input="val => $emit('update:selected-tags', val)"
            @finish-create="getReportTags"
        />
        
        <vue-editor 
            class="col-span-2" 
            :value="reportDescription"
            @input="val => $emit('update:report-description', val)"
            placeholder="Description" 
            :editorToolbar="$customToolbar()"
        />

        <div class="col-span-1" v-if="type && type.value === 1">
            <custom-select
                label="Posts limit"
                :key="postLimitKey"
                placeholder="Posts limit"
                :options="postLimitOptions"
                :value="postsLimit"
                @input="val => $emit('update:posts-limit', val)"
            />
        </div>

        <div class="col-span-2 flex gap-5">

            <div class="w-1/2">
                <FormulateInput
                    type="checkbox"
                    label="Protect with password"
                    class="flex items-center label-alone"
                    :style="!reportPasswordProtected ? 'margin-bottom: -15px;' : ''"
                    :value="reportPasswordProtected"
                    @input="val => $emit('update:report-password-protected', val)"
                />
                <FormulateInput
                    v-if="reportPasswordProtected"
                    type="text"
                    :value="reportPassword"
                    @input="val => $emit('update:report-password', val)"
                    placeholder="Enter password"
                    wrapper-class="border rounded-md py-2 px-3 bg-white h-16 flex items-center mt-2"
                />
                <!-- <FormulateInput
                    type="checkbox"
                    label="View content as platforms template"
                    class="flex items-center label-alone col-span-2 relative bottom-5"
                    v-model="settings_data.platforms"
                /> -->
            </div>

        </div>
        <div class="col-span-2">
            
            <FormulateInput
                v-if="type && type.value === 1"
                type="checkbox"
                label="Enable creator feedback"
                :value="allowFeedback"
                @input="val => $emit('update:allow-feedback', val)"
            />

            <div class="pl-6 text-sm" v-if="(type && type.value === 1) && allowFeedback">
                <div>
                    <p>Please select the questions you want to include in the report</p>
                    
                    <FormulateInput
                        v-for="(feedback_option, index) in feedback"
                        :key="feedback_option.id"
                        type="checkbox"
                        :label="`${feedback_option.label} (${feedbackOptionLabel(feedback_option.type)})`"
                        :value="feedback_option.active"
                        @input="val => $emit('update:feedback', index, val)"
                    />

                    <div class="mt-1 mb-2" v-if="!addFeedbackOption">
                        <base-button @action="addFeedbackOption = true" type="label" icon="plus-circle" label="Add Option"/>
                    </div>
                    <template v-else>
                        <div class="mt-2 flex gap-4">
                            <FormulateInput
                                label="Field"
                                type="text"
                                placeholder="Enter"
                                class="w-3/5"
                                v-model="newFeedbackOptionField"
                            />
                            <custom-select
                                label="Type"
                                placeholder="Select"
                                :options="feedbackOptionTypes"
                                v-model="newFeedbackOptionType"
                                class="w-2/5"
                            />
                        </div>
                        <div class="py-3 flex gap-3 justify-end">
                            <base-button @action="cancelNewFeedbackOption" type="label" size="sm" label="Cancel"/>
                            <base-button @action="addNewFeedbackOption" type="label" size="sm" label="Save" :disabled="!newFeedbackOptionField || !newFeedbackOptionType"/>
                        </div>
                    </template>
                </div>
            </div>

            <FormulateInput
                v-if="type && type.value === 2"
                type="checkbox"
                label="Hide the social media breakdown at the top of the report"
                :value="hideResume"
                @input="val => $emit('update:hide-resume', val)"
            />
            <FormulateInput
                v-if="type && [1, 2].includes(type.value)"
                type="checkbox"
                label="Show organic + boosted stat breakdown and post ads"
                :value="showBoostedData"
                @input="val => $emit('update:show-boosted-data', val)"
            />
            <FormulateInput
                v-if="type && [1, 2].includes(type.value)"
                type="checkbox"
                label="Hide stats on each individual post"
                :value="hideStats"
                @input="val => $emit('update:hide-stats', val)"
            />
            <FormulateInput
                v-if="type && [1].includes(type.value)"
                type="checkbox"
                label="Show users that don't have audience data"
                :value="preview"
                @input="val => $emit('update:preview', val)"
            />

        </div>
    </div>
</template>

<script>
const types = [
    { value: 1, label: 'Creators' },
    { value: 2, label: 'Content' },
    { value: 3, label: 'Brand' }
]
const postLimitOptions = [
    { id: 3, label: 3}, { id: 4, label: 4}, { id: 5, label: 5},
    { id: 6, label: 6}, { id: 7, label: 7}, { id: 8, label: 8},
    { id: 9, label: 9}
]
const feedbackOptionTypes = [
    { id: 1, label: 'Rank 1-5' },
    { id: 2, label: 'Free Text' },
]
export default {
    props: [
        'projectId', 
        'projects', 
        'reportName', 
        'type', 
        'customProject', 
        'selectedTags', 
        'reportDescription', 
        'reportPasswordProtected', 
        'reportPassword',
        'postsLimit',
        'allowFeedback',
        'feedback',
        'hideResume',
        'showBoostedData',
        'hideStats',
        'preview',
        'postLimitKey'
    ],
    data(){
        return {
            reportTags: [],
            
            addFeedbackOption: false,
            newFeedbackOptionField: '',
            newFeedbackOptionType: null,

            feedbackOptionTypes,
            types,
            postLimitOptions,

        }
    },
    mounted(){
        this.getReportTags();
    },
    computed: {
        computedProjects() {
            if (this.projects) {
                return this.projects.map(project => {
                    return { value: project.id, label: project.name };
                });
            }

            return [];
        }
    },
    methods: {
        async getReportTags(){
            const { data } = await this.$http.get(`/api/tags?type=reports`);
            this.reportTags = data.map(tag => {
                return {
                    value: tag.id,
                    label: tag.name
                }
            });
        },
        feedbackOptionLabel(type) {
            let option = this.feedbackOptionTypes.find(t => t.id === type);
            if (option) {
                return option.label;
            }
            return '';
        },
        addNewFeedbackOption() {
            this.$emit('add-new-feedback-option', {
                id: this.feedback.length + 1,
                active: true,
                label: this.newFeedbackOptionField,
                type: this.newFeedbackOptionType.id
            });
            this.cancelNewFeedbackOption();
        },
        cancelNewFeedbackOption() {
            this.newFeedbackOptionField = '';
            this.newFeedbackOptionType = null;
            this.addFeedbackOption = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    .general-form{
        @apply grid grid-cols-2 gap-x-5 gap-y-4;
    }
</style>

<template>
<div class="container max-w-none">
    <div class="project flex items-start pb-10">
        <h1 class="title font-black" v-if="!loading">{{project.brand}} - {{project.name}}</h1>
        <!-- <skeleton v-else :height="6" :width="36"/>

            <div class="dropdown" v-if="!loading">
                <crud-dropdown
                    rounded="full"
                    :label="{ name: statusLabel, id: project.status }"
                    :disabled="isDeleted"
                    addLabel="New Status"
                    :add="{
                        route: '/api/statuses',                                        
                        success: 'Status created successfully',
                        error: 'Failed to create new status',
                        warning: 'Status name required'
                    }"
                    :select="{
                        route: `/api/projects/${project.id}/status`,
                        success: 'Status updated successfully',
                    }"
                    :edit="{
                        route: '/api/statuses/',
                        success: 'Status updated successfully',
                        error: 'Failed to edit status',
                        warning: 'Status name required',
                    }"
                    :delete="{
                        deleteLabel: 'status',
                        route: '/api/statuses/',
                        success: 'Status deleted successfully',
                        error: 'Failed to delete status'
                    }"
                    :options="statuses"
                    :colors="colors"
                    @done="$emit('resetData')"
                    v-if="isAdminOrHigher"
                />
                <p v-else class="label">{{ statusLabel }}</p>
            </div>
        <skeleton v-else :height="6" :full-rounded="true"/> -->

        <div v-if="!loading" class="flex items-center flex-row-reverse">
            <options-dropdown
                class="float-right self-start"
                theme="light"
                v-if="(isProjectOwner || isAdminOrHigher) && !isDeleted"
                :options="[
                    {label: 'Edit', icon: 'pencil-alt', event: 'edit', separator: true},
                    ...(isAdminOrHigher ? {label: 'Share project', icon: 'pencil-alt', event: 'shareProject'} : {}),
                    {label: 'Archive', icon: 'trash', event: 'delete', theme: 'cancel'}
                ]"
                @edit="$router.push(`/projects/${project.slug}/edit`)"
                @delete="deleteProjectIntent(project)"
                @shareProject="$emit('shareProject')"
            />   

            <div class="self-start hidden md:flex items-center float-right mr-20 gap-2">
                <stat-card
                    v-if="isUserAdminOrHigher"
                    class="ml-15"
                    :iconSize="3"
                    :cardSize="8"
                    :type="'mini'"
                    :loading="!project.id" 
                    label="Creators" 
                    :value="totalCreators" 
                    icon="user-group" 
                />
                <stat-card
                    v-if="totalReach != 0 && isUserAdminOrHigher"
                    class="ml-15"
                    :iconSize="3"
                    :cardSize="8"
                    :type="'mini'"
                    :loading="!project.id"
                    label="Reach"
                    :value="totalReach | numeral('0.0a')"
                    theme="light"
                    icon="trending-up"
                    value-class="uppercase"
                    :truncate="true"
                />

                <stat-card 
                    class="ml-15"
                    :iconSize="3"
                    :cardSize="8"
                    :type="'mini'"
                    v-if="isUserAdminOrHigher"
                    :loading="!project.id"
                    label="Budget Used" 
                    :value="totalCost | numeral('0.0%')"
                    icon="cash"
                    :compareFirst="totalCost | numeral('$0,0')"
                    :compareSecond="project.budget | numeral('$0,0')"
                />

                <stat-card 
                    class="ml-15"
                    :iconSize="3"
                    :cardSize="8"
                    :type="'mini'"
                    v-if="totalAdSpend > 0 && totalAds > 0  && isUserAdminOrHigher"
                    :loading="!project.id"
                    :label="`Ad spend (${totalAds} ads)`" 
                    :value="totalAdSpend | numeral('$0,0')"
                    icon="cash"
                />

                <stat-card
                    class="ml-15"
                    :iconSize="3"
                    :cardSize="8"
                    :type="'mini'"
                    :loading="!project.id"
                    label="End Date"
                    :value="endsAt | moment('MMM D, Y')"
                    icon="calendar"
                    v-if="!!endsAt"
                />
                <!-- <stat-card 
                    :iconSize="3"
                    :cardSize="8"
                    :type="'mini'"
                    v-else
                    :loading="!project.id"
                    label="Ending Date"
                    :value="'-'" 
                    icon="calendar" 
                /> -->
            </div>       
        </div>

        <skeleton v-else :height="6"/>

    </div>

    <!-- tabs -->
    <div class="tabs">
        <tabs
            theme="light"
            :tabs="projectTabs"
            :value="value"
            @input="val => {
                $emit('input', val); 
                $forceUpdate();
            }"
        />
        <div class="date">
            <time v-if="!!project.start_at">Started {{ project.starts_at | moment('MMM D, Y')}}</time>
        </div>
    </div>
    <hr>
</div>  
</template>

<script>
import AvatarList from '../../components/AvatarList';
import StatCard from '../../components/StatCard.vue';
import { mapGetters } from 'vuex';
export default {
    props: [
        'project', 
        'loading', 
        'isProjectOwner', 
        'isDeleted', 
        'value', 
        'totalCreators', 
        'totalReach', 
        'budgetUsed', 
        'totalCost',
        'totalAdSpend',
        'totalAds', 
        'endsAt'
    ],
    components: {
        AvatarList,
        StatCard
    },
    computed: {
        ...mapGetters(['isAdminOrHigher', 'isUserAdminOrHigher', 'isOnlyCreator', 'colors', 'statuses']),
        statusLabel () {
            return (this.project.project_status && this.project.project_status.name) ? this.project.project_status.name : 'w/o status';
        },
        projectTabs() {
            let limit = this.loading ? 1 : 4;
            if (this.isOnlyCreator) {
                limit = this.loading ? 1 : 1;
            }
            return ['Overview', 'Documents', 'Reports', 'Notes', 'Deliverables'].filter(option => {

                if (this.isOnlyCreator) {
                    return ['Deliverables'].includes(option);
                }

                return !['Deliverables'].includes(option);
            }).slice(0, limit);
        },
    },
    methods: {
        async deleteProjectIntent(project) {
            this.$swal.fire({
                title: `Are you sure you want to archive the '${project.name}' project?`,
                text: '',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Archive',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteProject(project);
                }
            });
        },
        async deleteProject({id}) {
            await this.$http.post(`/api/projects/${id}/delete`);
            this.$notify({ title: 'Success', text: `Project archived successfully`, type: 'success' });
            this.$router.push('/home');
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply px-12 py-2 pb-0;
        .project{
            @apply flex justify-between items-center pt-10;
            @apply flex gap-x-8 items-center;
            .title{
                @apply text-h3 font-bold text-black;
            }
            .dropdown{
                @apply w-44;
                .label{
                    @apply text-black;
                }
            }
        }
        .owners{
            .avatar{
                @apply flex mt-2 items-center pb-10;
            }
        }
        .tabs{
            @apply flex justify-between items-center;
            .date{
                @apply text-purple-m-main-light text-sm
            }
        }
    }
</style>
<template>
<div>
  <div :class="{'h-full': size !== 'base'}">
    <!-- <label v-if="label" for="input" class="text-pxs text-purple-m-main-light font-bold">{{ label }}</label> -->
    <div class="select-wrapper relative" :class="{ 'h-full' : size !== 'sm' && size !== 'base', 'h-small' : size === 'sm', 'h-base' : size === 'base', 'h-auto': size === 'auto' }">
      <button id="input" type="button"
        :style="`${selected ? selected.color ? `color: #444}` : `` : ``}`"
        :class="[
                `custom_select_${selfKey}`,
                'w-full h-full text-left px-3 pl-4 pr-10 focus:outline-none',
                {'bg-white rounded-md border border-gray-300 shadow-sm': type === 'select'},
                {'focus:border-blue-300 focus:shadow-outline-blue': type === 'select' && !disabled},
                {'rounded-full': type === 'pill'},
                {'bg-select-pill rounded-full border-0 shadow-sm font-semibold focus:border-0': type === 'pill' && !disabled},
                {'min-h-base': minBase},
                {'border-0 font-semibold focus:border-0 cursor-default': type === 'pill' && disabled},
                { 'text-gray-m': (!multiple && !selected) || (multiple && (!selected || !selected.length)) },
                { 'text-purple-m-secondary': (!multiple && selected)},
                { 'select-button' : size !== 'sm' },
                { 'h-small min-select-button' : size === 'sm' },
                { 'pb-2': size === 'auto', 'pt-2': size === 'auto' && !label },
                { 'cursor-not-allowed outline-none focus:outline-none': disabled }
                ]"
        @click="toggleSelect(!open)">
        <label v-if="label" for="input" class="text-pxs text-purple-m-main font-bold pointer-events-none">{{ label }}</label>
        <div class="">
          <template v-if="multiple && selected && selected.length ">
            <div class="flex flex-wrap gap-2 items-center">
              <span v-for="(el, i) in selected" :key="i"
                class="text-pxs font-bold h-8 px-3 rounded-full bg-purple-m-secondary text-green-m-main inline-flex items-center"
                @click.stop="unselectOption(el)">                
                <span class="inline-flex items-center">
                  <span class="font-bold" v-if="selected[i].helper">{{ selected[i].helper }} -</span>
                  <span class="pl-0.5 my-auto">
                    {{selected[i].label ? selected[i].label : selected[i].name}}
                  </span>
                  <base-icon class="pl-2" name="x" :size="5" />                  
                </span>
              </span>          
            </div>
          </template>
          <div v-else>
            <template v-if="!multiple && selected">
              <div :class="['flex items-center', {'text-lg font-semibold py-2': selected.help && size !== 'sm' && showDesc}, {'font-bold': bold}]">
                <img :src="selected.img" v-if="selected && selected.img !== undefined && false" :class="['border border-purple-m-main-light rounded-full',
                   {'w-7 h-7': size === 'sm'},
                   {'w-9 h-9': size === 'base'},
                   {'w-12 h-12': size !== 'sm' && size !== 'base'}
                   ]"/>
                <div :class="['flex flex-col w-full', {'pl-2': selected && selected.img !== undefined}]">
                  <div>
                    <span class="font-bold" v-if="selected.helper">{{ selected.helper }}</span>
                    {{selected.label ? selected.label : selected.name}}
                  </div>
                  <div v-if="selected.help && size !== 'sm' && showDesc" class="font-normal text-pxs text-gray-m">
                    {{selected.help}}
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              {{placeholder}}
            </template>                
          </div>
        </div>
      </button>
      <span class="absolute top-0 flex items-center h-full right-3 pointer-events-none" v-if="!disabled">
        <base-icon 
          :style="`${selected ? selected.color ? `color: ${hexToTextColor(selected.color)}` : `` : ``}`"
          name="chevron-down" 
          class="text-purple-m-secondary"
        />
      </span>      
      <div v-if="open" class="select-menu absolute z-20 top-full left-0 w-full shadow-lg rounded-md">
        <div class="bg-white">
          <!-- regular search (with options that are already in data) -->
          <div v-if="search" class="p-4 relative">            
            <input id="search"
              v-model="filter"
              type="text" placeholder="Search..."
              :class="['w-full text-left rounded-md border border-gray-300 leading-9 shadow-sm pr-5 pl-10 text-sm',                      
                      'focus:outline-none focus:border-blue-300 focus:shadow-outline-blue']"
            >
            <span class="absolute top-6 left-6 pointer-events-none">
              <!-- Heroicon name: search -->
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </span>
          </div>

          <!-- custom search (emits search event to change options from parent component) -->
          <div v-if="customSearch" class="p-2">
            <div class="w-full relative">
              <FormulateInput
                type="text"
                placeholder="Search..."
                wrapper-class="border rounded-md pr-3 pl-10 bg-white h-12 py-0"	
                v-debounce="(inputValue, e) => $emit('search', inputValue)"
              />
              <div class="absolute top-0 bottom-0 left-3 flex items-center">
                  <base-icon name="search" class="text-purple-m-secondary" />
              </div>
            </div>
          </div>
          
          <div>
            <template v-if="computedOptions.length">
              <a v-if="allowCreate" class="block px-4 py-2 cursor-pointer text-h6 font-base hover:bg-tan-m text-purple-m-secondary" @click.prevent="createItem = true">{{ createLabel }}</a>
              <a href="#" v-if="allowNull"
                 class="block px-4 py-2 cursor-pointer text-h6 font-base hover:bg-tan-m text-purple-m-secondary"
                 :class="{'rounded-t-md': !search}"
                 @click.prevent="selectOption(null)"
              >{{ nullLabel }}</a>
              <a href="#" v-for="(option, i) in computedOptions" :key="i"
                 class="block px-4 py-2 cursor-pointer text-h6 font-base hover:bg-tan-m text-purple-m-secondary"
                 :class="[{'rounded-t-md': i === 0 && !allowNull && !search}, {'rounded-b-md': i === (computedOptions.length-1)},
                        {'bg-tan-m': option.disabled || (!multiple && option === selected) || (multiple && selected.includes(option))},
                        {'cursor-not-allowed': option.disabled}]"
                @click.prevent="selectOption(option)">
                <div :class="['flex items-center', {'text-lg font-semibold': option.help && size !== 'sm' && showDesc}, {'font-semibold': bold}]">
                  <img :src="option.img" v-if="option.img !== undefined && false" :class="['border border-purple-m-main-light rounded-full',
                   {'w-7 h-7': size === 'sm'},
                   {'w-9 h-9': size === 'base'},
                   {'w-12 h-12': size !== 'sm'}
                   ]"/>
                  <div :class="['flex flex-col w-full', {'pl-2': option.img !== undefined}]">
                    <div>
                      <span class="font-bold" v-if="option.helper">{{ option.helper }}</span>
                      {{option.label ? option.label : option.name}}
                    </div>
                    <div v-if="option.help && showDesc" class="font-normal text-sm text-gray-400">
                      {{option.help}}
                    </div>
                  </div>
                </div>
              </a>
            </template>
            <template v-else>
              <div class="px-6 leading-10 py-4 text-sm text-gray-400 rounded-md text-center flex flex-col gap-2">
                <p>There are no options available.</p>
                <p v-if="allowCreate" @click.prevent="createItem = true" class="text-black cursor-pointer">{{ createLabel }}</p>
              </div>
            </template>
          </div>
        </div>      
      </div>      
    </div>    
  </div>
  <div v-if="required && checkValidation && ((!multiple && !selected) || (multiple && (!selected || !selected.length)))"
    class="pb-1 flex content-start">
    <span class="text-xs text-red-700">
      {{label ? label : "This field"}} is required.
    </span>
  </div>
  <item-creator-modal
    v-if="allowCreate"
    :visible="createItem"
    :title="createLabel"
    :model-name="modelName"
    :route="createRoute"
    :create-body="createBody"
    @close="createItem = !createItem"
    @finish-create="finishCreate"
  />
</div>
</template>

<script>
import { hexToTextColor } from '../lib/strings'
import itemCreatorModal from "@/components/ItemCreatorModal";
import { cssApplier } from '../lib/cssApplier';
export default {
  mixins: [cssApplier],
  props:{
    options:{
      type: Array,
      default: function () {
        return [];
      },
      required: true
    },
    label:{
      type: String         
    },
    placeholder:{
      type: String
    },
    value:{      
      default: null
    },
    search:{
      type: Boolean
    },
    customSearch: {
      type: Boolean
    },
    multiple:{
      type: Boolean
    },
    required:{
      type: Boolean
    },
    size: {
      type: String,
      default: 'base'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    nullLabel: {
      type: String,
      default: ''
    },
    allowNull: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'select'
    },
    showDesc: {
      type: Boolean,
      default: true
    },
    minBase: {
      type: Boolean,
      default: false
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    createLabel: {
      type: String,
      default: ''
    },
    createRoute: {
      type: String,
      default: ''
    },
    createBody: {
      type: Object,
      default: function () {
        return {};
      }
    },
    modelName: {
      type: String,
      default: ''
    },
    preventMountedEmit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    itemCreatorModal
  },

  data() {
    return {
      selected: this.value,
      open: false,
      createItem: false,
      filter: "",
      checkValidation: false,
      classList: [],
      selfKey: 0,
      interact: false
    };
  },

  watch: {
    open(val, old){
      if(val !== old && !this.interact){
        this.interact = true
        this.$emit('interact')
      }
    }
  },

  computed: {
    computedOptions () {
      return this.options.filter(option => {
        if (this.filter) {
          // Get each word in uppercase.
          let filters = (this.filter.toUpperCase()).split(" ");
          // Remove empty elements.
          filters = filters.filter(f => f.length);

          let valid = false;
          // Check if option include all filters.
          filters.forEach(f => {
            if (((option.label ? option.label : option.name).toUpperCase()).includes(f)) {
              valid = true;
            }
          });
          // Check to add option.
          return valid;
        }
        return true;
      });
    },
    hasFocus() {
      return this.classList.includes(`custom_select_${this.selfKey}`);
    }
  },

  mounted() {
    this.selfKey = Math.floor((Math.random() * 100) + 1).toString() + Math.floor((Math.random() * 100) + 1).toString() ;
    document.addEventListener('click', this.close);
    setTimeout(() => {
      /* if (this.value.length) {
        this.selected = this.value;
      } */
      if(!this.preventMountedEmit) this.emitSelection();
    }, 500);

    document.addEventListener('focusin', this.changeFocus);
    document.addEventListener('keyup', this.changeKeys);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.close);
    document.removeEventListener('focusin', this.changeFocus);
    document.removeEventListener('keyup', this.changeKeys);
  },

  methods: {
    changeKeys(event) {
      let which = event.which;
      if (which === 27) { // esc
        this.open = false;
      } else if (this.hasFocus) {
        if (!this.open) {
          if (which === 38 || which === 40) {
            this.open = true;
          }
        }
      }
    },

    changeFocus() {
      this.classList = document.activeElement.classList.value.split(' ');
      this.$forceUpdate();
    },

    emitSelection() {
      //if (this.selected && this.selected.length)
        this.$emit('input', this.selected);
        this.$emit('changed', this.selected);
    },

    toggleSelect(state) {
      if (!this.disabled) {
        this.open = state;
        setTimeout(() => {
          let search = document.getElementById('search');
          if (state && search && this.search) {
            search.focus();
          }
        }, 150);
        if (!this.open)
          this.checkValidation = true;
      }
    },

    close(e) {
      if (!this.$el.contains(e.target) && this.open) {
          this.toggleSelect(false);
      }
    },

    selectOption(option) {
      if(this.multiple){
        if(this.selected.find(element => element.value == option.value)){
          this.unselectOption(option);
        }else{
          this.selected.push(option);
        }
      }
      else{
        if (option === null) {
          this.selected = null;
          this.open = false;
        } else if (!option.disabled) {
          this.selected = option;
          this.open = false;
        }
      }
      this.emitSelection();
    },

    unselectOption(option) {
      if(this.multiple){
        let found = this.selected.find(element => element.value == option.value)
        this.selected.splice(this.selected.indexOf(found), 1);
      }else{
        this.selected = null;
        this.checkValidation = true;
      }
    },

    finishCreate() {
      this.createItem = false;
      this.$emit('finish-create');
    },

    hexToTextColor: hexToTextColor
  }
};
</script>

<style lang="scss">
.select-wrapper {
  .select-menu {
    max-height: 14em;
    overflow-y: auto;

    /* .disabled {
        @apply text-gray-300 bg-gray-50 cursor-not-allowed;
    } */
  }
  .select-button:not(.bg-select-pill):not(.min-h-base) {
    min-height: 2.45rem;
  }
  .min-select-button {
    max-height: 2.45rem;
  }
}
.h-small {
  height: 2.45rem;
}
.h-base {
  height: 4rem;
}
.min-h-base {
  min-height: 4rem;
}
.top-2-5 {
  top: 0.625rem;
}
</style>
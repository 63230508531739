var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isCreator && _vm.profileCompletion && !_vm.profileCompletion.completed && !_vm.hidden)?_c('div',{staticClass:"profile-completion"},[_c('div',{staticClass:"absolute right-2 top-2"},[_c('base-icon',{staticClass:"cursor-pointer hover:opacity-50",attrs:{"size":6,"name":"close"},on:{"action":function($event){_vm.hidden = true}}})],1),_c('div',{staticClass:"left"},[_c('div',[_c('div',{staticClass:"title"},[_vm._v("Complete your profile")]),_c('div',{staticClass:"message"},[_vm._v(_vm._s(_vm.profileCompletion.message))]),_c('div',{staticClass:"view-details-button",on:{"click":function($event){_vm.detailsVisible = !_vm.detailsVisible}}},[_vm._v(" "+_vm._s(_vm.detailsVisible ? 'Hide' : 'View')+" details "),(!_vm.detailsVisible)?_c('base-icon',{attrs:{"size":"3","name":"chevron-down"}}):_c('base-icon',{attrs:{"size":"3","name":"chevron-up"}})],1),(_vm.detailsVisible)?_c('ul',{staticClass:"details"},[_vm._l((_vm.profileCompletion.errors),function(field,index){return [(field.errors && field.errors.length)?_vm._l((field.errors),function(error,i){return _c('li',{key:(index + "-" + i),staticClass:"item"},[_vm._v(" "+_vm._s(error.name)+" ")])}):_vm._e()]})],2):_vm._e()]),(_vm.profileCompletion.redirect)?_c('base-button',{attrs:{"label":_vm.redirectLabel(_vm.profileCompletion),"size":"lg"},on:{"action":function($event){return _vm.$router.push(_vm.profileCompletion.redirect)}}}):_vm._e()],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"label"},[_c('div',{staticClass:"percentage"},[_vm._v(_vm._s(_vm.profileCompletion.percentage))]),_c('div',{staticClass:"text"},[_vm._v("Completed")])]),_c('div',{staticClass:"chart"},[_c('doughnut',{key:_vm.chartKey,attrs:{"chartData":{
                labels: ['Female', 'Male'],
                datasets: [
                        {
                            data: [
                                parseInt(_vm.profileCompletion.percentage.replace('%','')), 
                                100 - parseInt(_vm.profileCompletion.percentage.replace('%',''))
                            ],
                            backgroundColor: [
                                '#70F880',
                                '#FFFFFF'
                            ]
                        }
                    ]
                },"options":{
                    legend: { 
                        display: false 
                    },
                    tooltips: {
                        enabled: false
                    },
                    cutoutPercentage: 50
                }}})],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
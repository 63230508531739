<template>
    <!-- empty state on modal title -->
    <base-modal
        :title="`${!isValidArray(this.val) ? `No additional info` : 'Additional info'}`"
        :visible="visible"
        styles="max-height: 90vh; max-width: 800px; width: 800px; overflow-y: auto;"
        no-padding
        @close="$emit('close')"
    >
        <div class="container">
            
            <div 
                class="header" 
                :class="{ 'empty-state': !isValidArray(this.val) }"
                v-if="!readonly"
            >
                <base-button
                    label="Create Info"
                    icon="plus"
                    size="lg"
                    @action="createInfoIntent"
                    bold
                />
            </div>

            <div class="content">

                <base-icon v-if="loading" name="loader" />

                <div 
                    v-else 
                    class="info-list divide-y divide-gray-200"
                >
                    <div
                        class="info-item" 
                        v-for="(info, index) in this.val" 
                        :key="`info-${index}`"
                    >
                        <div class="content">
                            
                            <div class="name">{{info.name}}</div>

                            <template v-if="info.type_detail && info.parsed_value">
                                <!-- link type -->
                                <a 
                                    v-if="info.type_detail.slug == 'link'" 
                                    :href="info.parsed_value.split('|')[1]"
                                    class="value link"
                                    target="_blank"
                                >
                                    {{info.parsed_value.split('|')[0]}}
                                </a>
                                <!-- other values -->
                                <div v-else class="value">{{ info.parsed_value || info.value }}</div>
                            </template>
                            <div @click.stop class="actions" v-if="!readonly">
                                <options-dropdown               
                                    :options="[
                                        {label: 'Edit', event: 'edit'},
                                        {label: 'Delete', event: 'delete'}
                                    ]"
                                    theme="light"
                                    @edit="editInfoIntent(info, index)"
                                    @delete="removeInfo(index)"
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="footer">
                <base-button
                    label="Close"
                    type="secondary"
                    @action="$emit('close')"
                />
                <base-button
                    label="Done"
                    bold
                    @action="$emit('close')"
                />
            </div>

            <base-table-column-additional-info-create-modal
                :visible="modalVisible"
                :current-additional-info="currentAdditionalInfo"
                @edit-info="editInfo"
                @create-info="createInfo"
                @close="closeModal()"
            />

        </div>
    </base-modal>
</template>

<script>
import { isValidArray, notifyCatchError } from '../common';
import BaseTableColumnAdditionalInfoCreateModal from './BaseTableColumnAdditionalInfoCreateModal.vue';
export default {
    components: { 
        BaseTableColumnAdditionalInfoCreateModal 
    },
    props: {
        visible: {
            type: Boolean
        },
        value: {
            type: Array
        },
        readonly: {
            type: Boolean
        }
    },
    data(){
        return {
            val: this.value,
            loading: false,
            loaded: false,

            modalVisible: false,
            currentAdditionalInfo: null,
            currentAdditionalInfoIndex: null,

            isValidArray
        }
    },
    mounted(){
        if(isValidArray(this.val) && this.val.length && this.loaded == false){                
            this.getTypesAndParse();
        }
    },
    watch: {
        // when value is set
        value(val){
            if(isValidArray(val) && this.val.length && this.loaded == false){                
                this.getTypesAndParse();
            }
        },
        val(val){
            this.$emit('update:value', val);
        }
    },
    methods: {
        async getTypesAndParse(){
            this.loading = true;
            this.$http.post('/user-additional-info/import/parse', {
                additional_info: this.val
            }).then(({ data }) => {
                this.val = data;
            }).catch(err => {
                notifyCatchError(err, this.$notify);
            }).finally(() => {
                this.loaded = true;
                this.loading = false;
            });
        },
        createInfoIntent(){
            this.modalVisible = true;
        },
        editInfoIntent(info, index){
            this.currentAdditionalInfo = info;
            this.currentAdditionalInfoIndex = index;
            this.modalVisible = true;
        },
        closeModal(){
            this.currentAdditionalInfo = null;
            this.currentAdditionalInfoIndex = null;
            this.modalVisible = false;
        },
        editInfo(info){
            this.val[this.currentAdditionalInfoIndex] = info;
        },
        createInfo(info){
            this.val.push(info);
        },
        removeInfo(index){
            this.val.splice(index, 1);
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply relative;
        > .header{
            @apply w-full mt-6 px-8;
            @apply flex justify-end;
            &.empty-state{
                @apply justify-center;
            }
        }
        > .content{
            @apply mt-6 mb-10 px-8;
            > .info-list{
                // @apply grid grid-cols-2;
                > .info-item{
                    @apply flex py-5 px-8 gap-x-5 justify-center;
                    > .content{
                        @apply flex;
                        > .name{
                            @apply font-bold;
                            width: 300px;
                            text-align: left;
                        }
                        > .value{
                            @apply truncate;
                            width: 300px;
                            text-align: left;
                            &.link{
                                @apply font-bold text-blue-500 cursor-pointer;
                                &:hover{
                                    @apply opacity-70;
                                }
                            }
                        }
                    }
                }
            }
        }

        .footer{
            @apply sticky bottom-0 border-t border-gray-200 bg-tan-m px-8 py-4 flex justify-between;
            z-index: 100;
        }
    }
</style>

<template>
    <div class="flex gap-x-10 gap-y-4 flex-wrap">
        
        <div v-if="!files.length">No files to preview</div>
        <component
            v-else
            :is="component"
            :files="files"
            :description="description"
            :postedBy="postedBy"
            :postedAt="postedAt"
            @edit="$emit('edit')"
            :page="page"
            :viewOnly="viewOnly"
            :key="`component-${key}`"
            @navigate="(option) => option == 'prev' ? page-- : page++"
            @removeAudio="$emit('removeAudio', page)"
            @audioDrop="(audio)=>$emit('audioDrop', audio, page)"
        />
        
        <audio-list
            v-if="audios.length"
            :audios="audios"
            :key="key"
            :page="page"
            @removeAudio="(fileIndex) => $emit('removeAudio', fileIndex)"
        />

    </div>
</template>

<script>
import { BlogPost, FacebookPost, InstagramPost, InstagramReel, InstagramInFeed, InstagramStory, TiktokVideo, YoutubeVideo } from './NetworkComponents/index';
import { mapGetters } from 'vuex'
import moment from 'moment'
import previewProfile from '../../assets/images/preview-profile.png'
import { componentName } from '../../lib/deliverable'
import AudioList from './AudioList.vue'
import { formatNumber } from '../../lib/strings'
export default {
    props: {
        deliverableSlug: {
            type: String,
            required: true
        },
        files: {
            type: Array,
            required: false
        },
        audios: {
            type: Array,
            required: false
        },
        description: {
            type: String,
            required: true
        },
        stats: {
            type: Array,
            required: true
        },
        networkId: {
            type: Number,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        viewOnly: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        BlogPost, FacebookPost, InstagramPost, InstagramReel, InstagramInFeed, InstagramStory, TiktokVideo, YoutubeVideo, AudioList
    },
    data(){
        return {
            key: 0,
            page: 0
        }
    },
    watch: {
        page: {
            immediate: true,
            handler () {
                this.key = !this.key
            }
        },
        files: {
            immediate: true,
            deep: true,
            handler () {
                this.key = !this.key
            }
        }
    },
    computed: {
        ...mapGetters(['me']),
        component(){
            return componentName(this.deliverableSlug)
            // return 'instagram-post'
            // return 'instagram-story'
            // return 'instagram-reel'
            // return 'blog-post'
            // return 'tiktok-video'
            // return 'youtube-video'
            // return 'facebook-post'
        },
        postedAt(){
            return moment().format('MMMM DD, YYYY [at] h:mm a');
        },
        postedBy(){
            return {
                name: `${this.user.first_name} ${this.user.last_name}`,
                handle: this.computedHandle,
                avatar: this.computedAvatar,
                ...this.computedStats
             }
        },
        computedAvatar(){
            const found = this.user.social_accounts?.find(social_account => {
                return social_account.network_id == this.networkId
            })
            const socialAccountsAvatar = found ? found.avatar : null
            return socialAccountsAvatar || this.user.avatar || previewProfile
        },
        computedHandle(){
            const found = this.user.social_accounts?.find(social_account => {
                return social_account.network_id == this.networkId
            })
            return found ? found.handle : 'jenpinkston' 
        },
        computedStats(){
            // if user has this network stats (audience data) we'll use that
            const found = this.stats.find(stat => {
                return stat.network_id == this.networkId
            })
             // if there are no stats from the user, lets use some random ones
             // we cant get shares from social data so we just do comments/2
            let res = found ? 
                { 
                    ...found, 
                    shares: Math.trunc( found.comments / 2 ),
                    saves: 0
                } : 
                {
                    comments: 20000,
                    engagement_rate: 0.04,
                    engagements: 100000,
                    likes: 80000,
                    network_id: -1,
                    views: 200000,
                    shares: 10000,
                    saves: 10000
                }

            res.comments = formatNumber(res.comments || 20000)
            res.engagements = formatNumber(res.engagements || 100000)
            res.likes = formatNumber(res.likes || 80000)
            res.views = formatNumber(res.views || 200000)
            res.shares = formatNumber(res.shares || 10000)
            res.saves = formatNumber(res.saves || 10000)

            return res

        }
    }

}
</script>
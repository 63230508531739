<template>
    <base-modal
        title="Boosted Data"
        :visible="visible"
        styles="max-height: 90vh; overflow-y: auto;"
        no-padding
        @close="$emit('close')"
    >
        <div class="container">
            <div class="content">
                <div class="ad-list">
                    <div class="ad-item" v-for="(ad, index) in value" :key="index">
                        <div class="ad-header">
                            <div class="id">Ad ID: {{ad.ad_id}}</div>
                            <div class="post-id">Post ID: {{ad.post_id}}</div>    
                        </div>

                        <div class="ad-section">
                            <div class="ad-section-title">Summary</div>
                            <div class="dates">
                                <div class="date">
                                    <div class="label">Ad started on:</div>
                                    <div class="value"><base-icon name="calendar"/>{{moment(ad.insights.date_start).format('LL')}}</div>
                                </div>
                                <div class="date">
                                    <div class="label">Ad ended on:</div>
                                    <div class="value"><base-icon name="calendar"/>{{moment(ad.insights.date_stop).format('LL')}}</div>
                                </div>
                            </div>
                            <template v-if="ad.payload">
                                <!-- <div>Created at: <b>{{moment(ad.payload.created_time).format('LL')}}</b></div> -->
                                <div>Configured Status: <b>{{ad.payload.configured_status}}</b></div>
                            </template>
                        </div>
                        <!-- <div>Last updated by:{{ad.last_updated_by}}</div> -->
                    

                        <div class="ad-section" v-if="ad.creative">
                            <div class="ad-section-title">Creative</div>
                            <!-- <div v-if="ad.creative.id">Id: <b>{{ad.creative.id}}</b></div> -->
                            <div v-if="ad.creative.instagram_permalink_url">Permalink url: <b>{{ad.creative.instagram_permalink_url}}</b></div>
                            <div v-if="ad.creative.source_instagram_media_id">Source Instagram media id: <b>{{ad.creative.source_instagram_media_id}}</b></div>
                            <div v-if="ad.creative.effective_instagram_story_id">Effective Instagram story id: <b>{{ad.creative.effective_instagram_story_id}}</b></div>
                        </div>

                        <div class="ad-section">
                            <div class="ad-section-title">General Info</div>
                            <div>Reach: <b>{{ad.insights.reach | numeral('0,0a') }}</b></div>
                            <div>Impressions: <b>{{ad.insights.impressions | numeral('0,0a')}}</b></div>
                            <div>Inline post engagement: <b>{{ad.insights.inline_post_engagement | numeral('0,0a')}}</b></div>
                        </div>
                        
                        <div class="ad-section">
                            <div class="ad-section-title">Actions</div>
                            <div class="ad-action-list">
                                <div 
                                    class="ad-action-item" 
                                    v-for="(action, _index) in ad.insights.actions" 
                                    :key="`action-${_index}`"
                                >
                                    <!-- tooltip -->
                                    <div class="ad-action-item-tooltip">
                                        <div>Action name: <b>{{action.action_type}}</b></div>
                                        <div>Action description: <b>{{getAction(action.action_type, 'description') ? getAction(action.action_type, 'description') : 'Not found'}}</b></div>
                                        <div>Raw value: <b>{{action.value}}</b></div>
                                    </div>

                                    <div>{{getAction(action.action_type, 'description') ? getAction(action.action_type, 'description') : action.action_type}}: <b>{{action.value | numeral('0,0a')}}</b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <base-button
                    label="Close"   
                    type="secondary"
                    @action="$emit('close')" 
                    size="sm"
                />
            </div>
        </div>
    </base-modal>
</template>

<script>
import moment from 'moment'
import { getAction } from '../lib/fbAdsActions'
export default {
    props: {
        visible: {
            type: Boolean
        },
        value: {
            type: Array,
            required: true
        }
    },
    data(){
        return {
            getAction,
            moment
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply relative;
        > .content{
            @apply flex flex-col w-full py-5 px-7;
            > .ad-list{
                @apply flex flex-col gap-y-6;
                > .ad-item{
                    @apply bg-white border border-black rounded-lg py-10 px-14;
                    @apply flex flex-col gap-y-7;
                    > .ad-header{
                        @apply flex justify-between;
                        > .id{
                            @apply text-gray-700 font-bold;
                        }
                        > .post-id{
                            @apply font-bold
                        } 
                    }
                    > .ad-section{
                        > .ad-section-title{
                            @apply text-h5 font-bold mb-2; 
                        }
                        > .dates{
                            @apply flex gap-x-10 mb-4;
                            > .date{
                                @apply flex flex-col gap-y-1;
                                > .label{
                                    //
                                }
                                > .value{
                                    @apply flex items-center gap-x-3 font-bold;
                                }
                            }
                        }
                        > .ad-action-list{
                            @apply flex flex-col;
                            > .ad-action-item{
                                @apply relative cursor-pointer;
                                > .ad-action-item-tooltip{
                                    @apply absolute left-20 bg-white border border-black rounded-lg z-50 px-4 py-3;
                                    @apply flex flex-col;
                                    bottom: 28px; 
                                    > .section{
                                        //
                                        > .title{
                                            @apply text-pxs font-bold mb-1;
                                        }
                                        > .text{
                                            @apply text-pxs;
                                        }
                                    }
                                }
                                &:not(:hover) {
                                    > .ad-action-item-tooltip{
                                        @apply hidden;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        > .footer{
            @apply sticky bg-tan-m py-5 px-7 bottom-0 left-0 right-0;
        }
    }
</style>

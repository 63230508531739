<template>
    <div :class="isReport ? 'w-full' : 'container'">
        
        <div v-if="postItem && postItem.post_identifier">

            <!-- content -->
            <iframe :src="`https://www.tiktok.com/embed/v2/${postItem.post_identifier}`" :style="`height: 730px;border: 0;${isReport ? 'width: 100%;' : ''}`"></iframe>

            <!-- audio dropzone -->
            <audio-dropzone
                v-if="!isReport"
                :file="files[page]"
                :key="key"
                :viewOnly="viewOnly"
                class="absolute top-0 bottom-0"
                @removeAudio="$emit('removeAudio')"
                @audioDrop="(audio)=>$emit('audioDrop', audio)"
            />
        </div>

        <div v-else>

            <!-- header -->
            <div class="header">
                <div class="user">
                    <avatar size="medium" :user="postedBy"/>
                    <div>
                        {{postedBy.handle}}
                    </div>
                </div>
                <div class="edit">
                    <base-icon :size="8" name="tiktok-options" v-if="!isReport"/>
                    <span v-else>{{ postItem.profile_item.total_followers | numeral('0a') }}</span>
                </div>
            </div>
            <!-- content -->
            <div class="content">
                <media-viewer :file="files[page]" :is-report="isReport" :viewOnly="viewOnly" @set-fail="$emit('set-fail')"/>
                <!-- navigation buttons -->
                <div v-if="page > 0" class="navigation-container left">
                    <div @click="$emit('navigate', 'prev')" class="button">
                        <base-icon name="chevron-left" />
                    </div>
                </div>
                <div v-if="page < files.length-1" class="navigation-container right">
                    <div @click="$emit('navigate', 'next')" class="button">
                        <base-icon name="chevron-right" />
                    </div>
                </div>
                <!-- navigation dots -->
                <div v-if="files.length > 1" class="dots-container">
                    <div class="dots">
                        <div 
                        v-for="(file, index) in files"
                        class="dot"
                        :class="{ 'active': index == page }"
                        :key="index"
                        />
                    </div>
                </div>
            </div>
            <!-- interaction -->
            <div class="interaction" :class="isReport && files.length > 1 ? 'mt-8' : ''">
                <div class="flex gap-x-4">
                    <base-icon :size="6" name="tiktok-like"/>
                    <span v-if="isReport" class="font-bold uppercase">{{ postItem.likes | numeral('0.0a') }}</span>
                    <base-icon :size="6" name="tiktok-comments"/>
                    <span v-if="isReport" class="font-bold uppercase">{{ postItem.comments | numeral('0.0a') }}</span>
                    <base-icon :size="6" name="tiktok-share" v-if="!isReport || (isReport && postItem.shares)"/>
                    <span v-if="isReport && postItem.shares > 0" class="font-bold uppercase">{{ postItem.shares | numeral('0.0a') }}</span>
                    <!-- <base-icon :size="6" name="tiktok-save" v-if="!isReport || (isReport && postItem.saves)"/>
                    <span v-if="isReport && postItem.saves > 0" class="font-bold uppercase">{{ postItem.saves | numeral('0.0a') }}</span> -->
                </div>
                <div v-if="!isReport">
                    <base-icon :size="8" name="tiktok-bookmark"/>
                </div>
            </div>
            <div class="interaction font-bold" v-if="isReport && postItem.views > 0">
                {{ postItem.views | numeral('0,0') }} views
            </div>
            <div class="interaction" v-if="isReport && description">{{ description }}</div>

        </div>

    </div>
</template>

<script>
import Avatar from '../../../../components/Avatar.vue'
import MediaViewer from '../MediaViewer.vue'
import AudioDropzone from '../../AudioDropzone.vue'
export default {
    props: ['files', 'description', 'postedBy', 'postedAt', 'page', 'viewOnly', 'isReport', 'postItem'],
    components: {
        MediaViewer,
        Avatar,
        AudioDropzone
    },
    data(){
        return {
            key: 0,
            icons: [
                {name: 'tiktok-like', count: this.postedBy.likes, size: 7, reportMeta: 'likes'},
                {name: 'tiktok-comments', count: this.postedBy.comments, size: 8, reportMeta: 'comments'},
                {name: 'tiktok-share', count: this.postedBy.shares, size: 8, reportMeta: 'shares'},
                // {name: 'tiktok-save', count: this.postedBy.saves, size: 8, reportMeta: 'saves'}
            ],
            isOpen: false
        }
    },
    watch: {
        files: {
            immediate: true,
            deep: true,
            handler () {
                this.key = !this.key
            }
        }
    },
}
</script>
<style lang="scss" scoped>
    .container{
        width: 300px;
        height: 532px;
        position: relative;
        background-color: black;
    }
    .container.w-full {
        width: 100%;
    }
    .header{
        @apply absolute left-0 right-0 top-0 p-4 flex justify-between items-center;
        &  .tabs{
            @apply flex gap-x-6;
            & .tab {
                @apply font-bold text-ps text-white;
            }
        }
    }
    .footer{
        @apply absolute left-0 right-0 bottom-0 p-4 flex justify-between pointer-events-none;
        
        & .info{
            @apply flex flex-col justify-end flex-shrink flex-grow gap-y-2 overflow-hidden;
            & .handle{
                @apply text-pxs text-white font-bold;
            }
            & .description{
                @apply text-pxs text-white;
            }
            & .sound-name{
                @apply text-pxs text-white truncate;
            }
        }
        & .interaction{
            @apply flex flex-col flex-shrink-0 flex-grow-0 items-center gap-y-5;
            & .user {
                @apply relative;
                & .follow-container {
                    @apply absolute left-0 right-0 flex justify-center items-center;
                    bottom: -4px;
                    & .follow {
                        @apply h-4 w-4 rounded-full flex justify-center items-center;
                        background-color: #f43b5f;
                    }
                }
            }
            & .icon-container{
                @apply flex flex-col items-center gap-y-1;
                & .count{
                    @apply text-pxs text-white;
                }
            }
            & .sound-image-container{
                @apply h-10 w-10 rounded-full flex justify-center items-center;
                background: rgb(26,21,22);
                background: linear-gradient(30deg, rgba(26,21,22,1) 0%, rgba(52,54,57,1) 50%, rgba(23,22,24,1) 100%);
                & .sound-image {
                    @apply h-6 w-6 rounded-full;
                    background-image: url('../../../../assets/images/dinosaur-modal.png');
                    @apply bg-cover bg-center bg-no-repeat;
                }
            }
        }
    }
    .navigation-container{
        position: absolute;
        bottom: 0px;
        top: 0px;
        display: flex;
        align-items: center;            

        &.left{
            left: -40px;
        }

        &.right{
            right: -40px;
        }

        & .button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #bbbbbd;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            cursor: pointer;
            box-shadow: #a3a3a3 -1px 0px 13px 0px;
        }
    }
    
    @-webkit-keyframes rotating {
        from {
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    .rotating {
        -webkit-animation: rotating 4s linear infinite;
        -moz-animation: rotating 4s linear infinite;
        -ms-animation: rotating 4s linear infinite;
        -o-animation: rotating 4s linear infinite;
        animation: rotating 4s linear infinite;
    }
</style>
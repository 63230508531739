<template>
    <base-button
        @action="action === 'request-instagram-permissions' ? requestIgPermissions() : login()"
        v-if="fb.initialized"
        icon="facebook"
        icon-color="#3B5998"
        :class="classes"
        :label="label"
        bold
        :disabled="disabled || !fb.initialized"
        type="secondary"
        theme="dark"
        border
    />
</template>
<script>
import { checkFacebookStatus, facebookLogin, requestInstagramPermissions } from '../lib/facebook'
import { mapGetters } from 'vuex';

export default {

    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'Sign in with Facebook'
        },
        action: {
            type: String,
            default: 'login'
        },
        classes: {
            type: String,
            require: false,
            default: 'mt-6 w-full'
        },
        handle: {
            type: String,
            required: false,
            default: null 
        }
    },

    data() {
        return {
            data: {

            }
        }
    },

    watch: {
        '$store.state.fb.status': {
            deep: true,
            handler: function (val, old) {
                if(val != old){
                    // console.log('FB status changed to: ', val)
                }
            }
        },
        '$store.state.fb.initialized': {
            handler: function (val, old) {
                if(val != old && val == true){
                    // console.log('Facebook initialized', val)
                    checkFacebookStatus()
                }
            }
        }
    },

    computed: {
        ...mapGetters(['fb'])
    },

    methods: {
        login(){

            facebookLogin().then(async res => {

                const { email, first_name, last_name, picture, userId, id, permissions, accounts, accessToken, longLivedAccessToken } = res
                let identifier = userId || id
                if(this.hasInstagramAccount(accounts)){
                    this.checkHandle(accounts)
                    this.$emit('success', {
                        email,
                        first_name,
                        last_name,
                        picture: picture.data.url,
                        userId: identifier,
                        accessToken: longLivedAccessToken,
                        networkId: 6,
                        accounts: this.simplifyAccounts(accounts)
                    })
                }else{
                    this.$swal.fire({
                        title: 'No Instagram account connected',
                        html: `
                            <div>Creatorsaurus requires an Instagram account to be connected to be able to use all its features. Follow the steps below to link your instagram account.</div>
                            <ul class='mt-6 flex flex-col gap-y-4 list-disc text-left'>
                                <li>On mobile, go to your Instagram profile</li>
                                <li>Click "Edit profile"</li>
                                <li>Under "Profile information" select "Page"</li>
                                <li>Ensure the correct Facebook Page is linked to your Instagram account</li>
                                <li>If not, "Change or create Page"</li>
                            </ul>
                        `,
                        iconColor: '#0E092C',
                        showCancelButton: true,
                        cancelButtonText: 'Dismiss',
                        confirmButtonText: 'Done',
                        reverseButtons: true,
                    }).then(async (result) => {
                        // wether they dismiss or click done, we emit success
                        // if (result.isConfirmed) { ... }           
                        this.checkHandle(accounts)
                        this.$emit('success', {
                            email,
                            first_name,
                            last_name,
                            picture: picture.data.url,
                            userId: identifier,
                            accessToken: longLivedAccessToken,
                            networkId: 6,
                            accounts: this.simplifyAccounts(accounts)
                        })
                    });
                }
            }).catch(err => {
                console.log('Facebook login error', err)
            })

        },
        requestIgPermissions(){

            requestInstagramPermissions().then(res => {

                const { email, first_name, last_name, picture, userId, id, permissions, accounts, accessToken, longLivedAccessToken } = res
                let identifier = userId || id
                this.$emit('success', {
                    email,
                    first_name,
                    last_name,
                    picture: picture.data.url,
                    userId : identifier,
                    accessToken: longLivedAccessToken,
                    networkId: 6,
                    permissions,
                    accounts: this.simplifyAccounts(accounts)
                })

            }).catch(err => {
                console.log('Request Instagram permissions error', err)
            })

        },
        // [ { data: { instagram_business_account: { username: 'handle' } }, paging: {} } ] => ['handle']
        simplifyAccounts(accounts){
            if(!accounts || !accounts.data || !Array.isArray(accounts.data)) return []
            return accounts.data.filter(account => {
                // remove if it does not have instagram_business_account or username
                return account.instagram_business_account && account.instagram_business_account.username
            }).map(element => {
                return element.instagram_business_account.username
            })
        },
        hasInstagramAccount(accounts){
            let found = 0
            if(accounts && accounts.data && Array.isArray(accounts.data)){
                for (const account of accounts.data) {
                    if(account.instagram_business_account){
                        found = 1
                    }
                }
            }
            return found
        },
        // with the connected instagram accounts, try to find the target users's handle, if not found, show notice
        checkHandle(accounts){
            if(!this.handle || !accounts) return;
            if(accounts.data && Array.isArray(accounts.data)){
                let found = false
                for (const account of accounts.data) {
                    const ac = account.instagram_business_account
                    if(ac && ac.username && ac.username === this.handle) found = true     
                }
                if(!found){
                    this.$notify({ title: 'Warning', text: `The connected instagram account(s) do not match the handle: '${this.handle}'`, type: 'warn', duration: 7500 })
                }
            }
            return;
        },
        checkPermissions(){
            // check if they declined a permission, we can check this later because the promt
            // will always ask if there are missing permisions, what we do have to check
            // is if they connected an instagram account.
            // if(permissions){
            //     let igBasic = permissions.find(element => {
            //         return element.permission == 'instagram_basic'
            //     })
            //     let igManageInsights = permissions.find(element => {
            //         return element.permission == 'instagram_manage_insights'
            //     })
            //     if((!igBasic || !igManageInsights) || (igBasic.status === 'declined' || igManageInsights.status === 'declined')){
            //         // cta to log in again, or dismiss and continue
            //     }
            // }
        }
    }
}
</script>

<template>
    <div :class="[color, styles, pulse ? 'animate-pulse' : '', rounded ? 'rounded' : '', fullRounded ? 'rounded-full' : '', !noMargin ? 'mb-2' : '']"></div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: 'line'
        },
        color: {
            type: String,
            default: 'bg-indigo-200'
        },
        height: {
            type: Number,
            default: 5
        },
        width: {
            type: Number,
            default: 24
        },
        rounded: {
            type: Boolean,
            default: true
        },
        fullRounded: {
            type: Boolean,
            default: false
        },
        noMargin: {
            type: Boolean,
            default: false
        },
        pulse: {
            type: Boolean,
            default: true
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        customClass: {
            type: String,
            default: 'h-5 w-24'
        }
    },
    computed: {
        styles () {
            let fullClass = this.fullWidth ? 'full' : this.width;
            switch (this.type) {
                case 'line':
                    return `h-${this.height} w-${fullClass}`;
                case 'icon':
                    return `h-${this.height} w-${this.height} mr-1`;
                case 'circle':
                    return `h-${this.width} w-${this.width} rounded-full`;
            }
            return this.customClass;
        }
    }
}
</script>

<template>
    <div 
        class="container" 
        :class="{ 
            'unread' : userNotification.status == 1,
            'read' : userNotification.status == 2,
            'deleting': deleting 
        }"
        @click="onClick"
    >
        <div class="content">
            <!-- anonymous users id: 0 -->
            <avatar v-if="notification.actor" :user="notification.actor" size="large" />
            <div class="message">
                <div class="text">{{notification.message}}</div>
                <div class="date">{{ moment(userNotification.created_at).format('LL') }}</div>
            </div>
        </div>

        <div class="options-container" @click.stop>
            <options-dropdown
                :options="[
                    ...(userNotification.status == 1 ? [{ label: 'Mark as read', icon: 'check', event: 'read' }] : []),
                    ...(userNotification.status == 2 ? [{ label: 'Mark as unread', icon: 'check', event: 'unread' }] : []),
                    { label: 'Delete', icon: 'trash', event: 'trash' },
                ]"
                @read="update({status: 2}, true)"
                @unread="update({status: 1}, true)"
                @trash="del()"
            />
        </div>

        <div v-if="loading" class="absolute" style="top:6px; right: 6px;">
            <base-icon 
                name="loader"
            />
        </div>

    </div>
    <!-- Notification status (enabled, disabled, etc) {{ userNotification.notification.status }} -->
    <!-- {{ userNotification.notification.message }} -->
</template>

<script>
import moment from 'moment'
export default {
    props: ['userNotification'],
    data(){
        return {
            moment: moment,
            loading: false,
            deleting: false
        }
    },
    computed: {
        notification(){
            return this.userNotification.notification
        },
        route(){
            const routeSwitch = (entity) => ({
                'project': `/projects/${this.notification.project?.slug}`,
                'deliverable': `/deliverables/${this.notification.entity_id}`,
                'version': `/version/${this.notification.entity_id}`
            })[entity]
            let route = routeSwitch(this.notification.entity_type)
            // if data.url exists we overwrite it
            let url = this.notification.data?.url 
            if(url) route = url
            return route
        },
    },
    methods: {
        async onClick(){
            // if unread, mark as read
            if(this.userNotification.status == 1){
                this.loading = true
                await this.update({ status: 2 })
            }
            if(this.route){
                this.$router.push(this.route)
            }
        },
        async update(body, notify = false){
            try{
                await this.$http.put(`/api/user-notification/${this.userNotification.id}`, body)
                if(notify) this.$notify({title: 'Success', text: `Notification updated successfully`, type: 'success'})
                this.$emit('userNotificationUpdate', this.userNotification.id, body)
            }catch(err){
                console.log('PUT user-notification error: ', err)
                return;
            }finally{
                this.loading = false
            }
        },
        del(){

            this.loading = true
            this.$http.delete(`/api/user-notification/${this.userNotification.id}`).then(res => {
                this.deleting = true
                this.$store.dispatch('restorer/set', {
                    message: 'Notification deleted successfully',
                    success: 'Notification restored successfully',
                    fail: 'Notification cannot be restored',
                    route: `/api/user-notification/${this.userNotification.id}/restore`,
                    action: res.data.show_undo, // bool, whether or not to show it
                    forceFn: true,
                    fn: (time = 6100) => {
                        setTimeout(() => {
                            this.$emit('refresh')
                            this.deleting = false
                        }, time);
                    }
                })
            }).catch(err => {
                console.log('deleteNotification error', err)
            }).finally(() => {
                this.loading = false
            })
            
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply w-full relative flex justify-between items-center rounded-md p-4 cursor-pointer transition-colors duration-500;
        &.read{
            @apply bg-white;
        }
        &.unread{
            @apply bg-tan-m;
        }
        &.deleting{
            @apply opacity-10;
        }

        .content{
            @apply flex items-center flex-grow flex-shrink gap-x-6 transition-opacity duration-500;
            &:hover{
                @apply opacity-50;
            }
            .message{
                @apply flex flex-col gap-y-1;
                .date{
                    @apply text-gray-500 text-pxs;
                }
            }
        }

        .options-container{
            @apply flex-grow-0 flex-shrink-0;
        }

    }
</style>
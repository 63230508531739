var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Title',{attrs:{"type":"main"}},[_vm._v("Options Dropdown")]),_c('Title',[_vm._v("Usage")]),_c('Code',{attrs:{"title":"Simple use","code":"\n        <options-dropdown               \n            :options=\"[\n                {label: \"Edit\", icon: \"pencil-alt\", event: \"edit\"},\n                {label: \"Delete\", icon: \"trash\", event: \"delete\"}\n            ]\"\n            @delete=\"\"\n            @edit=\"\"\n        />\n    "}}),_c('Title',[_vm._v("Props")]),_c('Table',{attrs:{"title":"These are the props:","columns":['Name', 'Type', 'Default', 'Description'],"data":[
            ['options', 'Array', 'empty array', 'An array of objects representing the options. Each object can have the following keys: {label: String, disabled: Boolean, disabledTooltip: Boolean'],
            ['right', 'Boolean', 'false', 'If the dropdown will open to the right'],
            ['top', 'Boolean', 'false', 'If the dropdown will open on top'],
            ['grow', 'Boolean', 'false', 'If the dropdown can grow depending on its contents (just adds padding insead of setting static width and height)'],
            ['hideSecondaryIcon', 'Boolean', 'false', 'Hides the secondary icon'],
            ['theme', 'String', 'light', 'Changes the theme of the dropdown. Accepts: {light, dark, cancel, neutral}'],
            ['customDropDown', 'String', '-', 'Classes to be applied to the dropdown'],
            ['noBgIcon', 'String', "''", 'Name of the icon to be displayed if the secondary icon is showing'],
            ['externalStatus', '-', '-', 'We can progamatically toggle the dropdown from the parent component with this prop']

        ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
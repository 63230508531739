<template>

    <base-modal
        :visible="visible"
        @close="$emit('close')"
        with-picture
        :title="title"
        modal-icon="user-inverted"
        modal-title="Add Creator"
        modal-subtitle="Add Creator form"
        styles="max-height: 90vh; min-width: 80vw; width: 80vw; overflow-y: auto;"
        no-padding
    >
        <create-influencer-form
            :tags="tags"
            :standalone="standalone"
            @created-influencer="emitSuccess"
            @change-title="changeTitle"
            @reload-tags="getTags"
        />
    </base-modal>
</template>

<script>
import BaseModal from './BaseModal';
import CreateInfluencerForm from './CreateInfluencerForm.vue';
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        standalone: {
            type: Boolean,
            default: false
        }
    },
    components: {
        BaseModal,
        CreateInfluencerForm,
    },

    data(){
        return {
            tags: [],
            title: '',
            loading: false
        }
    },

    mounted(){
        this.getTags()
    },

    methods: {
        changeTitle(title) {
            this.title = title;
        },

        async getTags(){
            this.loading = true
            const { data } = await this.$http.get('/api/tags')
            this.tags = data.map(tag => {
                return {
                    value: tag.id,
                    label: tag.name
                }
            });
            this.loading = false
        },
        emitSuccess(data, param = true){
            this.$emit('created-influencer', data, param);
        }
    }

}
</script>
import axios from 'axios';

export const colors = {
    namespaced: true,
    state: {
        colors: []
    },
    mutations: {
        setColors(state, val) {
            state.colors = val;
        }
    },
    actions: {
        async getColors({commit, state}){
            if (state.colors.length > 0) {
                return;
            }

            const { data } = await axios.get('/api/statuses/colors');
            await commit('setColors', data)
        }
    }
};
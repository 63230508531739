<template>
    <div class="reports-tab">

        <div class="header">
            <div class="title">
                Reports
                <span>({{ reports.length }})</span>
            </div>
        </div>

        <base-table 
            class="px-12 pb-10"
            :columns="[
                {
                    name: 'name',
                    label: 'Report name'
                },
                {
                    name: 'description',
                    label: 'Description',
                    type: 'html'
                },
                {
                    name: 'type',
                    label: 'Report type',
                    type: 'report-type'
                },
                {
                    name: 'is-default',
                    label: 'Default',
                    value: (element) => {
                        return element.is_default ? 'Default report' : '-'
                    }
                },
                {
                    name: 'created',
                    label: 'Created at',
                    type: 'date',
                    value: (element) => {
                        return element.created_at
                    }
                }
            ]"
            :data="reports"                
            :onRowClick="onRowClick"
            :loading="loading"
        />

    </div>
</template>
<script>
import { notifyCatchError } from '../../../common';
export default {
    data(){
        return {
            reports: [],
            loading: false
        }
    },
    mounted(){
        this.getReports();
    },
    methods: {
        getReports(){
            this.loading = true;
            this.$http.get(`/api/users/${this.$route.params.userId}/reports`, {
                params: {
                    detail: true
                }
            }).then(({ data }) => {
                this.reports = data;
            }).catch(err => {
                notifyCatchError(err, this.$notify);
            }).finally(() => {
                this.loading = false;
            });  
        },
        onRowClick(report){
            window.open(`/reports/${report.guid}`, '_blank');
        }
    }
}
</script>
<style lang="scss" scoped>
    .header{
        @apply flex justify-between px-12 py-10;
        .title{
            @apply text-h2 font-bold text-purple-m-secondary;
            >span{
                @apply text-gray-m-disable;
            }
        }
        .subtitle{
            @apply text-h6 font-bold text-purple-m-secondary;
            >span{
                @apply text-gray-m-disable;
            }
        }
    }
</style>

// [recruitment]
export const languages = [
    { id:"AF", value:"AF", name: "Afrikaans"},
    { id:"SQ", value:"SQ", name:"Albanian"},
    { id:"AR", value:"AR", name:"Arabic"},
    { id:"HY", value:"HY", name:"Armenian"},
    { id:"EU", value:"EU", name:"Basque"},
    { id:"BN", value:"BN", name:"Bengali"},
    { id:"BG", value:"BG", name:"Bulgarian"},
    { id:"CA", value:"CA", name:"Catalan"},
    { id:"KM", value:"KM", name:"Cambodian"},
    { id:"ZH", value:"ZH", name:"Chinese (Mandarin)"},
    { id:"HR", value:"HR", name:"Croatian"},
    { id:"CS", value:"CS", name:"Czech"},
    { id:"DA", value:"DA", name:"Danish"},
    { id:"NL", value:"NL", name:"Dutch"},
    { id:"EN", value:"EN", name:"English"},
    { id:"ET", value:"ET", name:"Estonian"},
    { id:"FJ", value:"FJ", name:"Fiji"},
    { id:"FI", value:"FI", name:"Finnish"},
    { id:"FR", value:"FR", name:"French"},
    { id:"KA", value:"KA", name:"Georgian"},
    { id:"DE", value:"DE", name:"German"},
    { id:"EL", value:"EL", name:"Greek"},
    { id:"GU", value:"GU", name:"Gujarati"},
    { id:"HE", value:"HE", name:"Hebrew"},
    { id:"HI", value:"HI", name:"Hindi"},
    { id:"HU", value:"HU", name:"Hungarian"},
    { id:"IS", value:"IS", name:"Icelandic"},
    { id:"ID", value:"ID", name:"Indonesian"},
    { id:"GA", value:"GA", name:"Irish"},
    { id:"IT", value:"IT", name:"Italian"},
    { id:"JA", value:"JA", name:"Japanese"},
    { id:"JW", value:"JW", name:"Javanese"},
    { id:"KO", value:"KO", name:"Korean"},
    { id:"LA", value:"LA", name:"Latin"},
    { id:"LV", value:"LV", name:"Latvian"},
    { id:"LT", value:"LT", name:"Lithuanian"},
    { id:"MK", value:"MK", name:"Macedonian"},
    { id:"MS", value:"MS", name:"Malay"},
    { id:"ML", value:"ML", name:"Malayalam"},
    { id:"MT", value:"MT", name:"Maltese"},
    { id:"MI", value:"MI", name:"Maori"},
    { id:"MR", value:"MR", name:"Marathi"},
    { id:"MN", value:"MN", name:"Mongolian"},
    { id:"NE", value:"NE", name:"Nepali"},
    { id:"NO", value:"NO", name:"Norwegian"},
    { id:"FA", value:"FA", name:"Persian"},
    { id:"PL", value:"PL", name:"Polish"},
    { id:"PT", value:"PT", name:"Portuguese"},
    { id:"PA", value:"PA", name:"Punjabi"},
    { id:"QU", value:"QU", name:"Quechua"},
    { id:"RO", value:"RO", name:"Romanian"},
    { id:"RU", value:"RU", name:"Russian"},
    { id:"SM", value:"SM", name:"Samoan"},
    { id:"SR", value:"SR", name:"Serbian"},
    { id:"SK", value:"SK", name:"Slovak"},
    { id:"SL", value:"SL", name:"Slovenian"},
    { id:"ES", value:"ES", name:"Spanish"},
    { id:"SW", value:"SW", name:"Swahili"},
    { id:"SV", value:"SV", name:"Swedish "},
    { id:"TA", value:"TA", name:"Tamil"},
    { id:"TT", value:"TT", name:"Tatar"},
    { id:"TE", value:"TE", name:"Telugu"},
    { id:"TH", value:"TH", name:"Thai"},
    { id:"BO", value:"BO", name:"Tibetan"},
    { id:"TO", value:"TO", name:"Tonga"},
    { id:"TR", value:"TR", name:"Turkish"},
    { id:"UK", value:"UK", name:"Ukrainian"},
    { id:"UR", value:"UR", name:"Urdu"},
    { id:"UZ", value:"UZ", name:"Uzbek"},
    { id:"VI", value:"VI", name:"Vietnamese"},
    { id:"CY", value:"CY", name:"Welsh"},
    { id:"XH", value:"XH", name:"Xhosa"},
]

export const languageLevels = [
    { value: '10%', label:'10%', alias: 'Beginner', id:'10'},
    { value: '20%', label:'20%', alias: 'Beginner', id:'20'},
    { value: '30%', label:'30%', alias: 'Elementary', id:'30'},
    { value: '40%', label:'40%', alias: 'Intermediate', id:'40'},
    { value: '50%', label:'50%', alias: 'Intermediate', id:'50'},
    { value: '60%', label:'60%', alias: 'Intermediate', id:'60'},
    { value: '70%', label:'70%', alias: 'Upper Intermediate', id:'70'},
    { value: '80%', label:'80%', alias: 'Advanced', id:'80'},
    { value: '90%', label:'90%', alias: 'Advanced', id:'90'},
    { value: '100%', label:'100%', alias: 'Proficient', id:'100'},
]
<template>
    <p>
        You can also 
        <span class="text" @click="selectorVisible = !selectorVisible">
            download
            <template v-if="selectorVisible" @click="selectorVisible = false">
                <div class="selector">
                    <a class="option" href="/template.csv" download> <div>CSV</div> <base-icon name="download"/> </a>
                    <a class="option" href="/template.xls" download> <div>XLS</div> <base-icon name="download"/> </a>
                    <a class="option" href="/template.xlsx" download><div>XLSX</div> <base-icon name="download"/> </a>
                </div>
                <div class="overlay"/>
            </template>
        </span> 
        this template, add the creator information, and then import it.
    </p>
</template>
<script>
export default {
    data(){
        return {
            selectorVisible: false
        }
    }
}
</script>
<style lang="scss" scoped>
    .text{
        @apply relative font-bold cursor-pointer;
    }
    .selector{
        @apply absolute z-20 border border-gray-400 rounded-lg bg-white flex flex-col;
        @apply left-0 right-0;
        top: 30px;
        width: 200px;
        .option{
            @apply font-bold p-4 transition-opacity duration-200 flex justify-between items-center;
            &:hover{
                @apply opacity-50;
            }
        }
    }
    .overlay{
        @apply fixed inset-0 z-10 cursor-pointer;
        content: '';
    }

</style>
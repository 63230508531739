<template>
    <div class="user-importable-status">

        <!-- If its not importable it will show a big yellow warning icon -->
        <base-icon
            v-if="!isImportable"
            name="exclamation-2"
            class="cursor-pointer text-yellow-300"
            @action="hasActions ? modalVisible = true : null"
            v-tooltip="{ 
                content: 'This user requires your attention, click to view details', 
                classes: 'v-tooltip-black ml-60', 
                delay: { 
                    show: 100, 
                    hide: 50 
                } 
            }"
        />

        <!-- If it is importable  -->
        <div
            v-else
            class="icon-wrapper"
            @click="hasActions ? modalVisible = true : null"
            v-tooltip="{ 
                content: `The user is importable${hasActions ? `, but there are details that require your attention, click to view` : ``}`, 
                classes: `v-tooltip-black${hasActions ? ` ml-72 mb-4` : `` }`, 
                delay: { 
                    show: 100, 
                    hide: 50 
                }
            }"
        >

            <!-- Will always show the green icon -->
            <base-icon name="check-circle" class="text-green-500" />
            
            <!-- But if there are available actions will show a smaller warning icon -->
            <div 
                class="actions-container" 
                v-if="hasActions"
            >
                <base-icon name="exclamation-circle" class="text-yellow-300" :size="4" />
            </div>

        </div>

        <base-table-column-user-importable-status-modal
            :visible="modalVisible"
            :value="value"
            :creator="creator"
            @update:user="user => $emit('update:user', user)"
            @close="modalVisible = false"
        />

    </div>
</template>
<script>
import { isValidArray } from '../common';
import BaseTableColumnUserImportableStatusModal from './BaseTableColumnUserImportableStatusModal.vue';
export default {
  components: { BaseTableColumnUserImportableStatusModal },
    props: {
        value: {
            type: Object,
            required: true
        },
        creator: {
            type: Object
        }
    },
    data(){
        return {
            modalVisible: false
        }
    },
    computed: {
        isImportable(){
            return this.value.is_importable
        },
        hasActions(){
            return this.actions.length > 0; 
        },
        actions(){
            if(this.value && isValidArray(this.value.actions)){
                return this.value.actions;
            }
            return [];
        }
    }
}
</script>
<style lang="scss" scoped>
    .user-importable-status{
        @apply flex;
        > .icon-wrapper{
            @apply relative cursor-pointer;
            > .actions-container{
                @apply absolute;
                @apply flex justify-center items-center;
                top: -10px;
                right: -10px;
            }
        }
    }
</style>

<template>
    <div class="wrapper">
        <div class="container">
            <div class="text">
                <span>Step {{step}}</span> / 4
            </div>
            <div class="dots">
                <div class="dot" :class="{ 'active': step >= index }" v-for="index in 4" :key="index"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['step']
}
</script>
<style lang="scss" scoped>
    .wrapper{
        @apply flex justify-center;
        .container{
            @apply flex justify-center items-center py-6 gap-x-4;
            .text{
                .span{
                    @apply font-bold;
                }
            }
            .dots{
                @apply flex items-center gap-x-1;
                .dot{
                    width: 14px;
                    height: 14px;
                    @apply rounded-full bg-gray-300;
                    @apply transition-colors duration-500;
                    &.active{
                        @apply bg-purple-m-main;
                    }
                }
            }
        }
    }
</style>

<template>
    <base-modal
        :visible="visible"
        :title="computedTitle"
        persistent
        @close="cancel"
    >
        <div class="container">
            <div class="content">
                <FormulateInput
                    v-if="step === 'links'"
                    type="text"
                    class="w-full"
                    placeholder="Network Link"
                    label="Link New Content"
                    :disabled="isGuest || isClient"
                    v-model="linkedPostUrl"
                />

                <div class="texts" v-if="step === 'reports'">
                    <template v-if="attachToAnotherReportMessageVisible">
                        <p>This content will be attached to the default report for this project. Do you want to attach to another report?</p>
                    </template>
                    <template v-else>
                        <div>Select the report where you want to add the content</div>
                        <custom-select @finish-create="getReports" model-name="Report" :create-route="computedAddReportRoute" multiple :disabled="loadingSelected" allow-create create-label="Create a new report" placeholder="Report" v-model="selectedReports" :options="currentReports"/>
                    </template>
                </div>
            </div>

            <div class="footer">
                <base-button 
                    label="Back"
                    v-if="step === 'reports' && !propLinks.length" 
                    @action="previousStep" 
                    :disabled="submitting" 
                    type="secondary" 
                    size="md" 
                    :icon-size="4"
                    bold 
                />
                <base-button 
                    label="Add to a report"
                    v-if="!submitting && attachToAnotherReportMessageVisible && step === 'reports'" 
                    @action="attachToAnotherReportMessageVisible = false"  
                    bold
                />
                <base-button
                    :label="computedNextLabel"
                    @action="next"
                    :size="!submitting && step === 'reports' && (attachToAnotherReportMessageVisible || (!attachToAnotherReportMessageVisible && !selectedReports.length)) ? 'xl' : 'md'"
                    :disabled="disabledNext"
                    bold
                />
            </div>
        </div>

        <!-- deliverable report created modal -->
        <base-modal title="Deliverable Report Created" :visible="reportCreated !== null" @close="emitLinked">
            <div class="flex flex-col space-y-3">
                <div>
                    <base-button @action="openReportLink" type="label" icon="eye" label="View Report"/>
                </div>
                <base-button @action="copyToClipboard" type="label" icon="link" justify="start" label="Copy Link"/>
            </div>
            
            <div class="w-full flex justify-center mx-auto mt-4">
                <base-button label="Close" @action="emitLinked"/>
            </div>
        </base-modal>
        

        <!-- add manual content suggestion modal -->
        <base-modal title="Add Manual Content" :visible="promptManual" @close="promptManual = false">
            
            <template v-if="currentUserDeliverable && currentUserDeliverable.network_id == 14">
                <p>We were unable to get this tiktok post, please make sure that the creator has connected their tiktok account so we can automatically get their profile information and posts.</p>
                <p>You can also add this post manually. Do you want to continue?</p>
                <div class="mt-4 flex justify-between">
                    <base-button @action="promptManual = false" type="secondary" size="md" label="Cancel"/>
                    <base-button @action="promptManual = false;showManual = true" size="lg" label="Add post manually"/>
                </div>
            </template>

            <template v-else>
                <p>This content is coming from an unsupported platform or the post is unavailable, but you can add it manually. Do you want to continue?</p>
                <p>You can also invite the user to authenticate with supported platforms to automatically get their profile information and posts.</p>
                <div class="mt-4 flex justify-between">
                    <base-button @action="promptManual = false" type="secondary" size="md" label="Cancel"/>
                    <base-button @action="promptManual = false;showManual = true" size="lg" label="Add post manually"/>
                </div>
            </template>

        </base-modal>
        
        <!-- add manual content modal -->
        <manual-post 
            @close="showManual = false"
            :open="showManual" 
            :suggested-link="suggestedLink" 
            :report-id="0" 
            @finish-manual="finishAdd" 
            @attach-existent="onAttachExistent"
        />
        
        <!-- loader -->
        <fixed-loader :visible="submitting" label="Linking content"/>

        <!-- request handle modal -->
        <base-modal title="Insert the handle for the user" @close="cancelHandle" :visible="requestHandleModalVisible">
            <p v-if="handleAttempts == 1">
                We were unable to get the post. Do you want to try again with another handle ?
            </p>
            <p v-else>We were unable to get the post.</p>
            <div>Make sure you are using the same handle displayed in the post, the creator may have changed their handle.</div>
            <FormulateInput
                type="text"
                class="w-full mt-4"
                placeholder="User Handle"
                label="User Handle"
                v-model="userHandle"
            />

            <div class="mt-4 flex justify-between">
                <base-button @action="cancelHandle" type="secondary" size="md" label="Cancel"/>
                <base-button @action="confirmAddLinkedPosts" label="Continue"/>
            </div>
        </base-modal>
        
        <!-- copyright modal -->
        <base-modal :hide-header="true" persistent :visible="missingMediaModalVisible">
            <div class="flex flex-col gap-4">
                <base-icon class="m-auto mt-6" size="12" name="exclamation-circle"/>
                <p class="text-h4 text-center mt-4 font-bold">We weren't able to get the media for this post.</p>
                <p class="text-center mt-2">FB won't return media if the handle is incorrect, if the post contains copyrighted material or has been flagged for a copyright violation. Examples of copyrighted material can include audio on reels.</p>
                <p class="text-center mt-2 font-bold">Would you still like to add the post ? If its copyrighted, the thumbnail will be shown and you can upload the media manually.</p>
                
                <div class="flex justify-center w-full gap-16 mt-6">
                    <base-button @action="cancelLinking" type="secondary" size="md" bold label="Cancel"/>
                    <base-button @action="confirmAddLinkedPosts(true)" size="md" label="Add post" bold/>
                </div>
            </div>
        </base-modal>
        
        <!-- summary modal -->
        <post-summary-modal 
            :visible="summaryModalVisible" 
            :post="summaryModalPost"
            @close="emitLinked(); summaryModalVisible = false"
            @done="emitLinked();"
        />
        
        <!-- bookmarklet uncomment -->
        <!-- <tiktok-bookmarklet :open="adviseTiktok" @close="adviseTiktok = false" @confirm="preProcessBody(currentBody)"/> -->
    </base-modal>
</template>

<script>
import ManualPost from "@/views/ReportCreate/ManualPost/index.vue";
import { recommendationsAlert } from '../lib/alerts';
import { mapGetters } from 'vuex';
import PostSummaryModal from '../views/ReportCreate/PostSummaryModal.vue';
// bookmarklet uncomment
// import TiktokBookmarklet from '@/views/ReportCreate/TiktokBookmarklet';
// import { scrapperFn } from '../lib/scrapperDom';
export default {
    components: {
        ManualPost,
        PostSummaryModal
        // bookmarklet uncomment
        // TiktokBookmarklet
    },
    props: {
        currentUserDeliverable: {},
        visible: {
            type: Boolean,
            default: false
        },
        propLinks: {
            type: Array,
            default: function () {
                return [];
            }
        }
    },
    watch: {
        // bookmarklet uncomment
        // adviseTiktok: function (val) {
        //     if (!val) {
        //         this.currentBody = {};
        //     }
        // },
        visible: function (val) {
            this.links = [];
            if (val) {
                this.getReports();
                this.getInstagramAccounts()
                this.getUser();
            }
            if (val && this.propLinks.length) {
                this.links = JSON.parse(JSON.stringify(this.propLinks));
                this.step = 'reports';
            }
        },
        linkedPostUrl(val, old){
            const copyPasted = val.length - old.length > 5
            if(copyPasted && val.includes('instagram.com') && val.includes('/stories')){
                let handle = val.split('/stories/')[1].split('/')[0]
                if(!this.instagramAccounts.map(element => element.username).includes(handle)){
                    this.$swal.fire({
                        title: `Unable to get instagram stories!`,
                        text: `We are not able to get this creator's instagram stories because they haven't logged in with facebook yet.`,
                        icon: 'warning',
                        iconColor: '#0E092C',
                        showCancelButton: true,
                        confirmButtonText: this.user ? 'Invite to login with Facebook' : 'Ok',
                        reverseButtons: true,
                    }).then((result) => {
                        if(result.isConfirmed && this.user){
                            this.inviteToLoginWithFacebook()
                        }
                    })
                }
            }
        },
        currentUserDeliverable() {
            this.processHandle();
        }
    },
    data() {
        return {
            handleAttempts: 0,
            missingMediaModalVisible: false,
            submitting: false,
            attachToAnotherReportMessageVisible: true,
            loadingResume: false,
            gettingReports: false,
            loadingSelected: false,
            // bookmarklet uncomment
            // adviseTiktok: false,
            linkedPostUrl: '',
            step: 'links',
            links: [],
            resume: [],
            currentReports: [],
            selectedReports: [],
            instagramAccounts: [],
            pendingPosts: [],
            p_post_key: false,
            user: null,
            loading: false,
            reportCreated: null,
            showManual: false,
            promptManual: false,
            userHandle: '',
            requestHandleModalVisible: false,

            summaryModalVisible: false,
            summaryModalPost: null,

            // bookmarklet uncomment
            // currentBody: {}
        }
    },
    mounted() {
        this.processHandle();
    },
    computed: {
        ...mapGetters(['me', 'isAdminOrHigher', 'isGuest', 'isClient']),
        suggestedLink() {
            if (this.propLinks.length) {
                return this.propLinks[0];
            }

            return this.linkedPostUrl;
        },
        // bookmarklet uncomment
        // isTiktok() {
        //     return this.currentUserDeliverable && this.currentUserDeliverable.network_id == 14;
        // },
        computedAddReportRoute() {
            if (this.currentUserDeliverable) {
                return `/api/reports/createFromDeliverable/${this.currentUserDeliverable.id}`;
            }

            return '';
        },
        computedTitle() {
            switch (this.step) {
                case 'links':
                    return 'Link Content to a Deliverable';
                case 'reports':
                    return `Add ${this.links.length > 1 ? 'these' : 'this'} content to a report`;
                case 'resume':
                    return 'Resume';
            }

            return 'Link Content to a Deliverable';
        },
        computedNextLabel() {
            if (this.submitting) {
                return 'Saving';
            }
            switch (this.step) {
                case 'links':
                    if (this.currentReports.length) {
                        return 'Next';
                    }
                    return `Link Content`;
                case 'reports':
                    if (this.attachToAnotherReportMessageVisible || (!this.attachToAnotherReportMessageVisible && !this.selectedReports.length)) {
                        return 'Continue without report';
                    }
                    break;
            }

            return 'Save';
        },
        disabledNext() {
            if (this.step === 'links') {
                return this.linkedPostUrl === '' || this.submitting || this.gettingReports;
            }

            return this.submitting || this.loadingSelected;
        }
    },
    methods: {
        cancel() {
            this.attachToAnotherReportMessageVisible = true;
            this.handleAttempts = 0;
            this.$emit('close');
        },
        cancelHandle() {
            this.requestHandleModalVisible = false;
            this.processHandle();
        },
        onAttachExistent(post) {
            this.finishAdd(post);
        },
        processHandle() {
            if (this.currentUserDeliverable && this.currentUserDeliverable.project_user) {
                let pU = this.currentUserDeliverable.project_user;
                if (pU && pU.user && pU.user.social_accounts && pU.user.social_accounts.length) {
                    let accounts = pU.user.social_accounts;
                    let account = accounts.find(a => a.network_id == this.currentUserDeliverable.network_id);

                    if (account && account.handle !== undefined && account.handle !== '') {
                        this.userHandle = account.handle;
                        return account.handle;
                    }
                }
            }

            return '';
        },
        copyToClipboard () {
            let text = window.location.origin + `/reports/${this.reportCreated.guid}`;
            this.$copyToClipboard(this.$notify, text);
        },
        openReportLink() {
            let href = `/reports/${this.reportCreated.guid}`
            let a = document.createElement('a');
            a.href = href;
            a.target = '_blank';
            a.click();
        },
        async getUser() {
            this.loading = true;
            const userId = this.currentUserDeliverable?.project_user?.user_id ? this.currentUserDeliverable.project_user.user_id : this.currentUserDeliverable.user_id;
            const { data } = await this.$http.get(`/api/users/${userId}`);
            this.user = data
            this.loading = false
        },
        async getInstagramAccounts() {
            const userId = this.currentUserDeliverable?.project_user?.user_id
            if(userId) {
                let { data } = await this.$http.get(`/api/facebook/instagram-accounts?user_id=${userId}`);
                if(data){
                    this.instagramAccounts = data
                } 
            }
        },
        async getReports() {
            this.gettingReports = true;
            let { data } = await this.$http.get(`/api/reports?single&only=posts&deliverableId=${this.currentUserDeliverable.id}`);
            this.currentReports = data.map(report => {
                report.value = report.id;
                report.helper = report.project_id ? report.project_name : 'No Project';
                return report;
            });
            this.gettingReports = false;

            this.$emit('got-reports', data);
        },
        previousStep() {
            switch (this.step) {
                case 'reports':
                    this.step = 'links';
                    break;
                case 'resume':
                    this.step = 'reports';
                    break;
            }
        },
        next() {
            switch (this.step) {
                case 'links':
                    if (this.currentReports.length) {
                        this.getSelectedReports();
                        this.step = 'reports';
                    } else {
                        this.confirmAddLinkedPosts();
                    }
                    break;
                case 'reports':
                    this.confirmAddLinkedPosts();
                    break;
                case 'resume':
                    this.confirmAddLinkedPosts();
                    break;
            }
        },
        async getSelectedReports() {
            let body = {
                newPostsUrl: this.propLinks.length ? this.propLinks : [this.linkedPostUrl],
                reports: this.currentReports.map(report => report.id)
            };
            this.loadingSelected = true;
            let {data} = await this.$http.put(`/api/userDeliverables/${this.currentUserDeliverable.id}/posts/preselected-reports`, body);
            const uniqueIds = new Set(this.selectedReports.map(report => report.id));
            for (let item of data) {
                let current = this.currentReports.find(report => report.id === parseInt(item));
                if (current && !uniqueIds.has(current.id)) {
                    this.selectedReports.push(current);
                    uniqueIds.add(current.id);
                }
            }
            this.loadingSelected = false;
        },
        async getResume() {
            let body = {
                newPostsUrl: this.propLinks.length ? this.propLinks : [this.linkedPostUrl],
                reports: this.selectedReports.map(report => report.id)
            };
            this.loadingResume = true;
            let {data} = await this.$http.put(`/api/userDeliverables/${this.currentUserDeliverable.id}/posts/resume`, body);
            this.resume = data.map(report => {
                report.open = true;
                return report;
            });
            this.loadingResume = false;
        },
        removePending(id, cancel = false) {
            let postIndex = this.pendingPosts.findIndex(p => p.id == id);
            if (postIndex > -1) {
                this.pendingPosts.splice(postIndex, 1);
            }
            if (!cancel) {
                this.emitLinked();
            }
        },
        emitLinked() {
            if (this.currentUserDeliverable) {
                this.$emit('posts-linked', this.currentUserDeliverable);
            }
        },
        async finishAdd(post) {
            this.promptManual = false;
            this.showManual = false;

            let body = {
                newPostsId: post.id,
                newPostsUrl: [],
                reports: this.selectedReports.map(report => report.id),
                currentReports: this.currentReports.map(report => report.id)
            };

            this.processBody(body);
        },
        cancelLinking() {
            this.missingMediaModalVisible = false;
            this.cancel();
        },
        async confirmAddLinkedPosts(force = false) {
            this.missingMediaModalVisible = false;
            // bookmarklet uncomment
            // this.currentBody = null;
            let body = {
                newPostsId: null,
                newPostsUrl: this.propLinks.length ? this.propLinks : [this.linkedPostUrl],
                reports: this.selectedReports.map(report => report.id),
                currentReports: this.currentReports.map(report => report.id),
                force
            };

            // bookmarklet: uncomment & remove "this.processBody(body)"
            // if (this.isTiktok) {
            //     this.currentBody = body;
            //     this.adviseTiktok = true;
            // } else {
            //     this.preProcessBody(body);
            // }
            this.processBody(body);
        },
        // bookmarklet uncomment
        // preProcessBody(body) {
        //     if (this.isTiktok) {
        //         let ids = [];
        //         let tries = 0;
        //         let callback = ({scrapperId = null}) => {
        //             tries++;
        //             if (scrapperId !== null) {
        //                 ids.push(scrapperId);
        //             }

        //             if (tries >= body.newPostsUrl.length) {
        //                 body.newScrapperIds = ids;
        //                 this.processBody(body);
        //             }
        //         }
        //         for (let link of body.newPostsUrl) {
        //             scrapperFn({ instance: this, link, callback });
        //         }
        //     } else {
        //         this.processBody(body);
        //     }
        // },
        async processBody(body) {
            this.requestHandleModalVisible = false;
            
            // bookmarklet: if (this.userHandle == '' && !this.isTiktok) {
            if (this.userHandle == '') {
                this.userHandle = this.processHandle();
            }

            body.handle = this.userHandle !== '' ? this.userHandle : null;
            this.submitting = true;
            try {
                let { data } = await this.$http.put(`/api/userDeliverables/${this.currentUserDeliverable.id}/posts`, {...body});
                if (data) {

                    if (data.pending) {
                        if(data.pendingHandle){
                            this.requestHandleModalVisible = true;
                        }else{
                            this.missingMediaModalVisible = true;
                        }
                        return null;
                    }

                    let text = 'Content linked successfully.';
                    if (data.message !== undefined) {
                        text = data.message;
                    }

                    this.$notify({title: data.warning ? 'The content was saved' : 'Success', text, type: data.warning ? 'warn' : 'success'}); 
                    
                    this.selectedReports = [];
                    this.handleAttempts = 0;
                    this.attachToAnotherReportMessageVisible = true;

                    if (data.report_created && data.deliverableReport !== undefined) {
                        this.reportCreated = data.deliverableReport;
                        // check if post has pending fields
                        if(data.posts[0].pending){
                            this.summaryModalVisible = true;
                            this.summaryModalPost = data.posts[0];
                        }
                    } else {
                        // check if post has pending fields
                        if(data.posts[0].pending){
                            this.summaryModalVisible = true;
                            this.summaryModalPost = data.posts[0];
                        }else{
                            this.emitLinked();
                        }
                    }
                } else {

                    this.$notify({
                        title: 'Error',
                        text: 'The content could not be linked. Try again later',
                        type: 'error'
                    });
                    
                    this.promptManual = true;
                }
            } catch (e) {
                console.error('error', e);
                this.processFailed(e);
            } finally {
                this.submitting = false;
            }
        },
        processFailed(e) {
            this.userHandle = '';
            if (e.response && e.response.data && e.response.data.message) {
                if (e.response.data.pendingHandle) {
                    this.requestHandleModalVisible = true;
                } else {
                    if (!this.handleAttempts) {
                        this.userHandle = this.processHandle();
                        this.handleAttempts++;
                        this.requestHandleModalVisible = true;
                    } else {
                        if (e.response.data.recommendations) {
                            recommendationsAlert(this, e.response.data.message, e.response.data.recommendations);
                        } else {
                            this.$notify({ title: 'Error', text: 'The content could not be linked.', type: 'error' });
                            this.promptManual = true;
                        }
                    }
                }
            } else if (e.response && e.response.data && e.response.data.error) {
                this.$notify({
                    title: 'Error',
                    text: e.response.data.error,
                    type: 'error'
                });
                this.promptManual = true;
            } else {
                if (!this.handleAttempts) {
                    this.userHandle = this.processHandle();
                    this.handleAttempts++;
                    this.requestHandleModalVisible = true;
                } else {
                    this.$notify({ title: 'Error', text: 'The content could not be linked.', type: 'error' });
                    this.promptManual = true;
                }
            }
        },
        inviteToLoginWithFacebook(){
            this.loading = true
            this.$http.post('/api/invite/intent', {
                roleId: 13, // creator
                targetEmails: this.user.email,
                targetId: this.user.id,
                networkId: 6
            }).then(async (res) => {
                if(res.data){
                    await this.$http.post('/api/invite/social-login', { 
                        email: this.user.email,
                        url: `${window.location.origin}/social-login?t=${res.data.token}` ,
                        networkName: 'facebook'
                    })
                    this.$notify({ title: 'Success', text: 'Invite sent successfully', type: 'success' })
                }
            }).catch((err) => {
                console.log('sendRequest error', err)
                if(err.response && err.response.data && err.response.data.message){
                    this.$notify({ title: 'Warning', text: err.response.data.message, type: 'warn' })
                }else{
                    this.$notify({ title: 'Warning', text: 'Something went wrong', type: 'warn' })
                }
            }).finally(() => {
                this.loading = false
            })  
        },
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply flex flex-col justify-between;
        .content{
            @apply py-5;
            .texts{
                @apply flex flex-col gap-3;
            }
        }
        .footer{
            @apply flex justify-end gap-3;
            @media (min-width: 640px) {
                @apply flex;
            }

        }
    }
</style>

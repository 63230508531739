<template>
<div :class="['inset-x-0 bottom-0', $attrs.fixed ? 'fixed' : '' ]">
    <div :class="['inner-banner-wrapper', color.background]">
        <div class="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div class="flex items-center justify-between flex-wrap">
                <div class="w-0 flex-1 flex items-center">
                    <span :class="['flex p-2 rounded-lg', color.iconBg]" v-if="$slots.icon">
                        <slot name="icon" />
                    </span>
                    <p class="ml-3 font-medium text-white truncate">
                        <slot />
                    </p>
                </div>
                <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto" v-if="$slots.action">
                    <slot name="action" />
                </div>
                <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-3" v-if="dismissable">
                    <button type="button" :class="['-mr-1 flex p-2 rounded-md focus:outline-none sm:-mr-2 transition ease-in-out duration-150', color.buttonBg]" aria-label="Dismiss">
                        <!-- Heroicon name: x -->
                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        dismissable: {
            type: Boolean,
            default: false
        },

        type: {
            type: String,
            default: 'info'
        }
    },

    computed: {
        color() {
            switch (this.type) {
                case 'info':
                    return {
                        background: 'bg-purple-600',
                        iconBg: 'bg-purple-800',
                        buttonBg: 'hover:bg-purple-500 focus:bg-purple-500'
                    }
                case 'danger':
                    return {
                        background: 'bg-red-600',
                        iconBg: 'bg-red-800',
                        buttonBg: 'hover:bg-80-500 focus:bg-80-500'
                    }
                case 'success':
                    return {
                        background: 'bg-green-600',
                        iconBg: 'bg-green-800',
                        buttonBg: 'hover:bg-green-500 focus:bg-green-500'
                    }
                default:
                    return {
                        background: 'bg-purple-600',
                        iconBg: 'bg-purple-800',
                        buttonBg: 'hover:bg-purple-500 focus:bg-purple-500'
                    }
            }
        }
    }
}
</script>
<template>
    <div class="container">
        <loader label="Preparing TikTok" visible/>
    </div>
</template>

<script>
import { validString } from '@/lib/strings'; 
export default {
    data() {
        return {
            validString
        }
    },
    beforeMount() {
        this.checkRedirection();
        this.saveTokenOnLocalStorage();
    },
    methods: {

        async checkRedirection() {
            let { state, code } = this.$route.query
            if (this.validString(state) && this.validString(code)) {
                // if the current url does not match the state url, we redirect there to save the code in that subdomain
                if (!state.includes(window.location.origin)) {
                    state = state + '/tiktok/auth?hard_token=' + code;
                    window.location.href = state;
                } else {
                    this.saveTokenOnLocalStorage(code);
                }
            }
        },

        async saveTokenOnLocalStorage(code = null) {
            // redirected hard_token
            let { hard_token } = this.$route.query;
            if (this.validString(hard_token)) {
                code = hard_token;
            }
            if (this.validString(code)) {
                localStorage.setItem('tiktok_auth', code);
                await this.$store.dispatch('auth/setTikTokAuth', code);
                window.close();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .container{
        @apply py-16 px-24;
    }
</style>
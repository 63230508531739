
<template>
<div>

    <Title type="main">Base Icon</Title>

    <Title>Usage</Title>

    <Code title="Simple use" :code='`
        <base-icon name="check"/>
    `'/>

    <Code title="Use with custom options" :code='`
        <base-icon 
            :size="6" 
            :iconSize="3"
            name="arrow-up"
            :fill="false"  
            border
            background="bg-white"
            @action="$emit("event")" 
            defaultIcon="arrow-down"
            wrapperClass="my-class"
            iconClass="my-class"
        />
    `'/>


    <Title>Props</Title>

    <Table
        title="The component accepts the following props:"
        :columns="['Name', 'Type', 'Required', 'Default', 'Description']"
        :data="[
            ['name', 'String', 'true', '-', 'Name of the icon that will be displayed'],
            ['defaultIcon', 'String', 'false', '-', 'Name of a default icon to be displayed'],
            ['size', 'Number', 'false', '5', 'Size for the containers of the icon'],
            ['iconSize', 'Number', 'false', '-', 'Size for the actual icon'],
            ['fill', 'Boolean', 'false', 'true', 'Some icons have two states: filled and hollow, this toggles between those two'],
            ['wrapperClass', 'String', 'false', '-', 'Classes to be applied to the icon wrapper element'],
            ['iconClass', 'String', 'false', 'h-full w-full', 'Classes to be applied to the icon element'],
            ['border', 'Boolean', 'false', 'false', 'Whether a border will be displayed on the icon'],
            ['background', 'String', 'false', '-', 'Tailwind class for icons background color']
        ]"
    />

</div>
</template>
<script>
import Code from '../Code.vue'
import Table from '../Table.vue'
import Title from '../Title.vue'
import Paragraph from '../Paragraph.vue'
export default {
    components: {
        Code,
        Table,
        Title,
        Paragraph
    }
}
</script>
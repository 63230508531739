<template>
    <base-modal
        :title="`You are about to accept this ${updateStatusConfirmationType === 1 ? 'concept' : 'draft'}:`"
        :visible="visible && updateStatusConfirmationItem != null"
        styles="max-height: 90vh; overflow-y: auto;"
        @close="$emit('close')"
    >
        <div class="update-status-confirmation-modal-container">
            <div class="content" v-if="updateStatusConfirmationItem">
                
                <div class="name-description">
                    <div class="name">{{ updateStatusConfirmationItem.name }}</div>
                    
                    <div class="description-wrapper">
                        <div class="description-container" v-if="updateStatusConfirmationItem.description && updateStatusConfirmationItem.description !== ''">
                            <div class="title">Description</div>
                            <div class="description enriched-format" v-html="updateStatusConfirmationItem.description"></div>
                        </div>
                        <div v-else class="description-empty-state">No description</div>
                    </div>
                </div>

                <ul class="media-list" v-if="updateStatusConfirmationItem">
                    <li v-for="(media, i) in updateStatusConfirmationItem.media" :key="i">
                        <version-media-item
                            :attachment="media.attachment"
                            :audioAttachment="media.audio_attachment"
                        />
                    </li>
                </ul>

            </div>
            <div class="footer">
                <base-button @action="$emit('close')" type="secondary" size="md" bold label="Cancel"/>
                <base-button @action="$emit('confirm')" size="xl" :label="`Accept ${updateStatusConfirmationType === 1 ? 'Concept' : 'Draft'}`" bold/>
            </div>
        </div>
    </base-modal>
</template>
<script>
import VersionMediaItem from './VersionMediaItem.vue'
export default {
    props: ['visible', 'updateStatusConfirmationItem', 'updateStatusConfirmationType'],
    components: {
        VersionMediaItem
    }
}
</script>
<style lang="scss" scoped>
    .update-status-confirmation-modal-container{
        @apply flex flex-col gap-3;
        > .content{
            @apply flex flex-col gap-y-4;
            > .name-description{
                @apply flex flex-col gap-y-4;
                > .name{
                    @apply text-h4 font-bold;
                }
                > .description-wrapper{
                    > .description-container{
                        > .title{
                            @apply font-semibold;
                        }
                    }
                    > .description-empty-state{
                        @apply italic;
                    }
                }
            }
            > .media-list{
                @apply flex flex-col gap-y-2;
            }
        }
        > .footer{
            @apply flex justify-between;
        }
    }

</style>

<template>
<div>

    <!-- Step 1 - User already has FB ID? -->
    <template v-if="step == 1">
        <div class="title">Select an option:</div>
        <option-select
            :options="[ { value: true, label: 'I already know my facebook page ID' }, { value: false, label: `I don't know what my facebook page ID is` }]"
            @select="value => { step = 2; hasId = value }"
        />
    </template> 

    <!-- Step 2 -->
    <template v-if="step == 2">
        
        <!-- Input FB ID -->
        <div v-if="hasId">
            <div class="title">Enter your facebook page ID</div>
            <FormulateInput
                type="text"
                label="Facebook Page ID"
                v-model="pageId"
                validation="required"
                validation-name="Facebook page ID"
                class="w-full"
            />  

        </div>

        <!-- Use FB URL or find on FB config? -->
        <template v-else>
            <div class="title">Select an option:</div>
            <option-select
                :options="[{ value: true, label: 'Find ID by page url', description: 'Use your facebook page URL to find the ID', disabled: true }, { value: false, label: 'Find ID on FB config', description: `Find your facebook page ID on facebook configuration` }]"
                @select="value => { step = 3; useUrl = value }"
            />
        </template>

    </template>

    <!-- Step 3 -->
    <template v-if="step == 3">
        
        <div v-if="useUrl">
            <!-- <div class="title">Enter your facebook page URL</div>
            <FormulateInput
                type="text"
                label="Facebook Page URL"
                v-model="pageUrl"
                validation="required"
                validation-name="Facebook page URL"
                class="w-full"
            /> -->
        </div>

        <div v-else>
            <div class="title">Facebook config</div>
            
            <walkthrough />

        </div>


    </template>

    <!-- footer -->
    <div class="mt-6 w-full flex justify-between">
        <base-button
            @action="step--" 
            :disabled="loading"
            v-if="step > 1"
            type="secondary"
            icon="arrow-left"
            label="Back"
        />

        <!-- FB ID FORM-->
        <base-button
            @action="submit"
            v-if="step == 2 && hasId"
            :disabled="loading || !formValidated"
            submit
            bold
            label="Submit"
        />

        <!-- FB URL FORM-->
        <!-- <base-button
            @action="submitUrl"
            v-if="step == 3 && useUrl"
            :disabled="loading || !formValidated"
            submit
            bold
            label="Submit"
        /> -->

        <!-- Walkthrough done -->
        <base-button
            @action="step = 2; hasId = true; useUrl = false"
            v-if="step == 3 && !useUrl"
            :disabled="loading"
            submit
            bold
            size="xl"
            label="I found my page ID"
        />    
    </div>

</div>
</template>

<script>
import OptionSelect from './Select.vue'
import Walkthrough from './Walkthrough';
export default {
    components: {
        OptionSelect,
        Walkthrough    
    },
    data() {
        return {
            step: 1,
            hasId: false,
            useUrl: false,
            pageId: '',
            pageUrl: '',
            loading: false
        }
    },
    computed: {
        formValidated(){
            if(!this.pageId || this.pageId.trim() == ''){
                return false
            }
            return true
        }
    },
    methods: {
        submit(){
            this.$emit('done', this.pageId)
        }
    }
}
</script>
<style lang="scss" scoped>
    .title{
        @apply text-h4 font-bold mb-6;
    }
</style>
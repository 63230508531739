<template>
    <div class="facebook-business-sidebar">
        
        <div class="title">Business Settings</div>
        <div v-if="fbLogin" class="fbid">Your FBID: {{fbLogin.profile_id}}</div>
        <business-info />

        <!-- routes -->
        <div class="routes">

            <div class="route-wrapper" v-for="(route, index) in routes" :key="`route-${index}`">
                <div :class="`route ${currentRoute && (currentRoute == route.slug) ? `active` : ``}`" @click="onRouteClick(route, index)">
                    <div class="left">
                        <base-icon :name="route.icon" />
                        <div>{{route.name}}</div>
                    </div>
                    <div v-if="route.children" class="right">
                        <base-icon :name="route.expanded ? 'chevron-down' : 'chevron-right'"/>
                    </div>
                </div>

                <!-- children -->
                <template v-if="route.children && route.expanded">
                    <template v-for="(child, i) in route.children">
                        <div @click="onRouteClick(child)" :class="`children-route ${currentRoute && (currentRoute == child.slug) ? `active` : ``}`" :key="`child-${i}`">
                            <div class="name">{{child.name}}</div>
                        </div>
                    </template>
                </template>

            </div>

        </div>

    </div>
</template>

<script>
import BusinessInfo from './BusinessInfo.vue'
import { mapGetters } from 'vuex';

export default {
    components: {
        BusinessInfo
    },
    data(){
        return {
            selectedRoute: null,
            routes: [
                { 
                    name: 'Users',
                    icon: 'user-group',
                    children: [
                        { name: 'People', slug: 'people'},
                        // { name: 'Parters', slug: 'partners' },
                        { name: 'System Users', slug: 'system-users' }
                    ] 
                },
                {
                    name: 'Accounts',
                    icon: 'archive-box', 
                    children: [
                        { name: 'Pages', slug: 'pages' },
                        { name: 'Client Pages', slug: 'client-pages' },
                        { name: 'Ad Accounts', slug: 'ad-accounts' },
                        { name: 'Business Resource Groups', slug: 'business-resource-groups' },
                        { name: 'Apps', slug: 'apps' },
                        { name: 'Instagram Accounts', slug: 'instagram-accounts' },
                        { name: 'Whatsapp Business Accounts', slug: 'whatsapp-business-accounts' },
                    ] 
                },
                { 
                    name: 'Permission Requests', 
                    icon: 'check-circle',
                    slug: 'permission-requests'
                },
                { 
                    name: 'Webhooks', 
                    icon: 'link',
                    slug: 'webhooks'
                },
                { 
                    name: 'Info', 
                    icon: 'info-circle',
                    slug: 'info'
                },
            ]
        }
    },
    mounted(){
        this.onRouteClick({slug: this.$route.query.route || 'people'})    
    },
    computed: {
        ...mapGetters(['fbLogin']),
        currentRoute(){
            return this.$route.query.route;
        }
    },
    methods: {
        onRouteClick(route, index){
            if(route.children){
                this.routes[index].expanded = !this.routes[index].expanded
            }else{
                this.expandParentIfItExists(route)
                this.selectedRoute = route.slug
                this.$router.push({ query: { ...this.$route.query, route: route.slug }})
            }
            this.$forceUpdate();
        },
        expandParentIfItExists(route){
            // if route has children then it can't be a parent
            if(route.children) return;
            const parents = this.routes.filter(route => route.children)
            let found = false
            let parentIndex = null
            parents.forEach((parent, index) => {
                parent.children.forEach(child => {
                    if(child.slug === route.slug) {
                        found = true
                        parentIndex = index
                    }
                })
            })
            return found ? this.routes[parentIndex].expanded = true : null
        }
    }
}
</script>

<style lang="scss" scoped>
    .facebook-business-sidebar{
        width: 300px;
        height: calc(100vh - 190px);
        @apply p-6 border-r border-gray-500 flex-shrink-0 flex-grow-0;
        
        .title{
            @apply font-bold text-h3;
        }

        .fbid{
            @apply text-pxs text-gray-400;
        }

        .routes{
            @apply w-full flex flex-col;
            .route-wrapper{

                .route{
                    @apply p-2 rounded cursor-pointer transition-colors duration-200;
                    @apply flex justify-between items-center;
                    &.active{
                        @apply bg-tan-m;
                    }
                    &:hover{
                        @apply bg-gray-300;
                    }
                    .left{
                        @apply flex items-center flex-shrink flex-grow gap-x-2;
                    }
                    .right{
                        @apply flex items-center flex-shrink-0 flex-grow-0;
                    }
                }
                .children-route{
                    @apply py-1 pr-2 pl-8 rounded cursor-pointer transition-colors duration-200;
                    &.active{
                        @apply bg-tan-m;
                    }
                    &:hover{
                        @apply bg-gray-300;
                    }
                }

            }
        }
    }
</style>

<script>
// Load speperate modules
import { Bar } from 'vue-chartjs';

export default {
    extends: Bar,
    // mixins: [mixins.reactiveProp],
    props: ["data", "options"],

    mounted () {
        this.renderChart(this.data, this.options)
    }
};
</script>
<template>
    <div>
        
        <Header title="Permission Requests" :count="permissionRequests.length" :loading="loading" />

        <div class="permission-request-list">
            <div v-if="!permissionRequests.length">No requests were found</div>
            <div class="permission-request" v-for="(permissionRequest, index) in permissionRequests" :key="index">
                <div>Page ID: <span class="font-bold">{{permissionRequest.id}}</span></div>
                <div class="permitted-task-list">
                    <div class="permitted-task" v-for="(permittedTask, index) in permissionRequest.permitted_tasks" :key="`pt-${index}`">
                        {{ permittedTask }}
                    </div>
                </div>
            </div>
        </div>
     
    </div>
</template>

<script>
import Header from './Header.vue'
import { mapGetters } from 'vuex';
export default {
    components: {
        Header
    },
    data(){
        return {
            permissionRequests: [],
            loading: false,
        }
    },
    mounted(){
        this.getPermissionRequests()
    },
    computed: {
        ...mapGetters(['company']),
    },
    methods: {
        async getPermissionRequests(){
            this.loading = true
            this.$http.get('/api/facebook', {
                params: {
                    entity_id: this.company.fb_business_id,
                    edge: 'pending_client_pages',
                    fields: ['permitted_tasks'],
                    user_access_token: true
                }
            }).then(async (res) => {
                if(res.data){
                    this.permissionRequests = res.data
                }
            }).catch((err) => {
                console.log('getPermissionRequests error', err)
            }).finally(() => {
                this.loading = false
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .permission-request-list{
        @apply flex flex-col mt-6 gap-y-4 items-start;
        .permission-request{
            @apply px-6 py-2 border border-black rounded-xl;
            .permitted-task-list{
                @apply flex w-full mt-2 gap-x-2;
                .permitted-task{
                    @apply rounded-full bg-blue-500 px-4 py-2 text-white text-pxs;
                }
            }
        }
    }
</style>
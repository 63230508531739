<template>
    <div class="post-show-type">
        
        <div class="content">
            
            <div class="text">{{ label }}</div>
            
            <!-- absolute -->
            <div class="icon-wrapper">
                <div class="icon-container" @click="dropdownVisible = !dropdownVisible">
                    <base-icon name="pencil" :size="3" />
                </div>
            </div>

            <!-- absolute -->
            <div class="dropdown-wrapper" v-if="dropdownVisible">
                <div class="dropdown">
                    <div 
                        class="item" 
                        :class="{ 'active': option.id == value }"
                        v-for="(option, index) in options" 
                        :key="index"
                        @click="$emit('update:value', option.id); dropdownVisible = false"
                    >
                        {{ option.option_label }}
                    </div>
                </div>
            </div>

        </div>
        
    </div>
</template>
<script>
import { isNotNull } from '../common'
export default {
    props: {
        value: {
            type: Number
        }
    },
    data(){
        return {
            options: [
                {
                    id: 1,
                    display_label: 'Showing top posts',
                    option_label: 'Show top posts',
                },
                {
                    id: 2,
                    display_label: 'Showing featured posts',
                    option_label: 'Show featured posts'
                }
            ],
            dropdownVisible: false
        }
    },
    computed: {
        label(){
            if(isNotNull(this.value)){
                let found = this.options.find(element => element.id == this.value);
                if(found){
                    return found.display_label
                }
            }
            return 'Not set'
        }
    }
}
</script>
<style lang="scss" scoped>
    .post-show-type{
        @apply flex;
        .content{
            @apply relative;
            .text{

            }
            .icon-wrapper{
                @apply absolute;
                right: -30px;
                top: -19px;
                .icon-container{
                    @apply bg-white border border-gray-300 rounded-lg cursor-pointer;
                    @apply flex justify-center items-center;
                    @apply transition-colors duration-200;
                    height: 30px;
                    width: 30px;       
                    &:hover{
                        @apply bg-gray-100;
                    }
                }
            }
            .dropdown-wrapper{
                @apply absolute;
                z-index: 1;
                top: 10px;
                right: -30px;
                .dropdown{
                    @apply bg-white border border-gray-300 rounded-lg py-1;
                    width: 185px;
                    .item{
                        @apply px-3 py-1 bg-white;
                        @apply cursor-pointer transition-colors duration-200;
                        &:hover{
                            @apply bg-gray-100;
                        }
                        &.active{
                            @apply font-bold;
                        }
                    }
                }
            }

        }
    }
</style>

<template>
    <base-modal
        :title="title"
        :visible="visible"
        styles="max-height: 90vh; overflow-y: auto;"
        no-padding
        @close="close()"
    >
        <div class="container">
            <div class="content">
                <tab :visible="tab == 'view'">
                    <div class="ad-list">
                        <div class="empty-state" v-if="!isArray(value)">No ads found for this post</div>
                        <ad-item
                            v-for="(ad, index) in value"
                            :key="index"
                            :ad="ad"
                            @done="() => { $emit('reload'); close() }"
                        />
                    </div>
                </tab>
                <tab :visible="tab == 'create'">
                    <base-icon name="loader" class="m-auto" v-if="loading" />
                    <div class="create-ad-form">
                        <FormulateInput
                            type="text"
                            class="w-full"
                            placeholder="1987236918723"
                            label="Ad ID"
                            validation="required"
                            v-model="adId"
                            :disabled="loading"
                        />
                        <FormulateInput
                            type="text"
                            readonly
                            :disabled="true"
                            class="w-full"
                            label="Post ID"
                            :value="postId"
                        />
                    </div>
                </tab>
            </div>
            <div class="footer">
                <base-button
                    label="Close"
                    v-if="tab == 'view'"   
                    type="secondary"
                    @action="close()" 
                    size="sm"
                    bold
                    :disabled="loading"
                />
                <base-button
                    label="Back"
                    v-if="tab == 'create'"
                    @action="tab = 'view'"
                    bold
                    icon="arrow-left"
                    type="secondary"
                    :disabled="loading"
                />
                <!-- navigation button -->
                <base-button
                    label="Create New Ad"
                    size="xl"
                    v-if="tab == 'view'"
                    @action="tab = 'create'"
                    bold
                    icon="plus"
                    :disabled="loading"
                />
                <!-- submit button -->
                <base-button
                    label="Create Ad"
                    size="lg"
                    v-if="tab == 'create'"
                    @action="createAd()"
                    bold
                    :disabled="loading || !validateId(adId)"
                />
            </div>
        </div>
    </base-modal>
</template>

<script>
import { notifyCatchError, isArray, isString } from '../../../common';
import AdItem from './AdItem.vue';
export default {
    components: { 
        AdItem 
    },
    props: {
        visible: {
            type: Boolean
        },
        value: {
            type: Array,
            required: false,
            default: () => []
        },
        // required for creating a new ad
        postId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data(){
        return {
            tab: 'view',

            // new ad form
            adId: '',
            loading: false,

            isArray
        }
    },
    computed: {
        title(){
            return this.tab == 'view' ? 'Manage Post Ads' : 'Create Post Ad'
        }
    },
    methods: {
        validateId(val){
            return isString(val) && (!isNaN(parseInt(val)));
        },
        createAd(){
            this.loading = true;
            this.$http.post(`/api/boosted-posts`, { 
                ad_id: this.adId,
                post_id: this.postId
            }).then(({ data }) => {
                this.$notify({ title: 'Ad successfully created', text: '', type: 'success' });
                this.close();
                this.$emit('reload');
            }).catch(err => {
                notifyCatchError(err, this.$notify);
            }).finally(() => {
                this.loading = false;
            }); 
        },
        close(){
            this.tab = 'view';
            this.adId = '';
            this.$emit('close');
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply relative;
        > .content{
            @apply flex flex-col w-full py-5 px-7;
            // view
            .ad-list{
                @apply flex flex-col gap-y-6;
                > .empty-state{
                    @apply m-auto;
                }
            }
            // create 
            .create-ad-form{
                @apply w-1/2 flex flex-col gap-y-4;
            }
        }
        > .footer{
            @apply sticky bg-tan-m py-5 px-7 bottom-0 left-0 right-0;
        }
    }
</style>

<template>
    <div>
        <div class="stats-wrapper">
            <div class="stats-container">
                <stat-card label="Active Projects" :value="pendingProjects.length" icon="layers" wrapperStyles="width: 250px" />
                <stat-card v-if="isAdminOrHigher" label="Total Earned" :value="totalBudget | numeral('$0,0')" icon="cash" wrapperStyles="width: 250px" />
                <stat-card v-if="firstDate" label="Joined" :value="firstDate | moment('MMM D, Y')" icon="calendar" wrapperStyles="width: 250px" />
                <stat-card v-else label="Joined" value="-" icon="calendar" wrapperStyles="width: 250px" />
            </div>
        </div>
        <div class="stats-wrapper">
            <div class="stats-container">
                <stat-card label="Total Deliverables" :value="totalDeliverables" icon="deliverable-check" wrapperStyles="width: 250px" />
                <stat-card label="Completed Deliverables" :value="completedDeliverables" icon="check" wrapperStyles="width: 250px" />
                <stat-card label="Reports" :value="totalReports" icon="graph-stats" wrapperStyles="width: 250px" />
            </div>
        </div>
    </div>    
</template>
<script>
import StatCard from '../../components/StatCard.vue';
import { mapGetters } from 'vuex';
import { isValidArray } from '../../common';
export default {
    props: ['projects', 'status', 'userDeliverables', 'reports'],
    components: {
        StatCard
    },
    computed: {
        ...mapGetters(['isAdminOrHigher']),
        pendingProjects() {
            let projects = this.projects;
            return projects.filter(project => project.status[0].id !== this.completedStatusId);
        },
        completedStatusId() {
            let status = this.status.find(status => status.name.toLowerCase() === 'completed');
            if (status) {
                return status.id;
            }
            return null;
        },
        totalBudget() {
            if (this.userDeliverables && this.userDeliverables.length) {
                return this.userDeliverables.reduce((a, c) => a += c.budget, 0);
            }
            return 0;
        },
        firstDate() {
            let dates = this.projects.map(project => {
                return new Date(project.project_users[0].created_at);
            }).sort();
            return dates.length ? dates[0] : null;
        },
        totalDeliverables() {
            if(isValidArray(this.userDeliverables)){
                return this.userDeliverables.length;
            }
            return 0;
        },
        completedDeliverables() {
            if(isValidArray(this.userDeliverables)){
                return this.userDeliverables.filter(e => [40, 41].includes(e.status)).length;
            }
            return 0;
        },
        totalReports() {
            if(isValidArray(this.reports)){
                return this.reports.length;
            }   
            return 0;
        }
        // totalReach() {
        //     if (this.user.social_accounts && this.user.social_accounts.length) {
        //         return this.user.social_accounts.reduce((a, c) => a += c.reach, 0);
        //     }
        //     return 0;
        // }
        // currentStatus() {
        //     let out = '';
        //     switch (this.projectUser.status) {
        //         case 1:
        //             out = 'Pending Approval';
        //             break;
        //         default:
        //             out = 'Pending';
        //     }

        //     return out;
        // },
        // editingUserDeliverable() {
        //     if (this.createUserDeliverable.editingId) {
        //         return this.userDeliverables.find(r => r.id === this.createUserDeliverable.editingId);
        //     }

        //     return null;
        // },
    }
}
</script>
<style lang="scss" scoped>
    .stats-wrapper{
        @apply bg-tan-m flex justify-between items-center px-12 py-10;
        .stats-container{
            @apply flex w-full justify-between items-start;
        }
    }
</style>
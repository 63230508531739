<template>
    <div class="interests-container">
        <div class="title">Tell us about your interests</div>
        
        <!-- header -->
        <div class="header">
            <div class="count">
                ({{val.length}}) interests selected
            </div>
            <div class="buttons">
                <base-button 
                    label="Clear all" 
                    type="label" 
                    icon="trash" 
                    class="text-pxs gap-x-1" 
                    icon-size="4"
                    :disabled="!val.length"
                    @action="clearAll()" 
                />
                <base-button 
                    label="View selected" 
                    type="label" 
                    icon="eye" 
                    class="text-pxs gap-x-1" 
                    icon-size="4" 
                    :disabled="!val.length"
                    @action="selectedInterestsModalVisible = true" 
                />
            </div>
        </div>

        <!-- main categories -->
        <div class="main-categories" :key="`main-${mainKey}`">
            
            <div class="item" v-for="(category, index) in mainCategories" :key="index">
                
                <div class="content">
                    <div class="name">{{ category.name }}</div>
                    <base-icon name="chevron-down" class="cursor-pointer" v-if="!category.dropdownVisible" @action="toggleMainCategoryDropdown(index)" />
                    <base-icon name="chevron-up" class="cursor-pointer" v-if="category.dropdownVisible" @action="toggleMainCategoryDropdown(index)" />
                </div>
                
                <!-- interests -->
                <div class="interests" v-if="category.interests && category.dropdownVisible">

                    <div class="item" v-for="(interest, i) in category.interests" :key="i" @click="toggleInterest(interest)">
                        <FormulateInput
                            type="checkbox"
                            :checked="isInterestSelected(interest)"
                        />
                        <div class="name">{{ interest.name }}</div>
                    </div>
                
                </div>
                
                <!-- sub categories -->
                <div class="sub-categories" v-if="category.sub_categories && category.dropdownVisible">
                    <div class="item" v-for="(sub_category, i) in category.sub_categories" :key="i">
                        
                        <div class="content">
                            <div class="name">{{ sub_category.name }}</div>
                            <base-icon name="chevron-down" class="cursor-pointer" v-if="!sub_category.dropdownVisible" @action="toggleSubCategory(index, i)" />
                            <base-icon name="chevron-up" class="cursor-pointer" v-if="sub_category.dropdownVisible" @action="toggleSubCategory(index, i)" />
                        </div>

                        <!-- interests -->
                        <div class="interests" v-if="sub_category.interests && sub_category.dropdownVisible">
                            <div class="item" v-for="(interest, indx) in sub_category.interests" :key="indx" @click="toggleInterest(interest)">
                                <FormulateInput
                                    type="checkbox"
                                    :checked="isInterestSelected(interest)"
                                />
                                <div class="name">{{ interest.name }}</div>
                            </div>
                        </div>

                    </div>    
                </div>

            </div>

        </div>
        <div class="actions">
            <base-button label="Next" size="xl" class="font-bold" @action="$emit('next')" />
            <base-button label="Back" type="label" @action="$emit('back')" />
        </div>

        <selected-interests-modal
            :visible="selectedInterestsModalVisible"
            :value="val"
            @close="selectedInterestsModalVisible = false"
            @remove-interest="interest => toggleInterest(interest)"
        />
    </div>
</template>
<script>
import { notifyCatchError } from '../../common'
import SelectedInterestsModal from './SelectedInterestsModal.vue'
export default {
    components: { 
        SelectedInterestsModal 
    },
    props: ['value'],
    data(){
        return {
            mainCategories: [],
            val: this.value,
            mainKey: false,
            selectedInterestsModalVisible: false
        }
    }, 
    mounted(){
        this.getData()
    },
    methods: {
        isValidArray(arr){
            return arr && Array.isArray(arr) && arr.length
        },
        getMainCategories(categories){
            return categories.filter(element => !element.parent_id)
        },
        getSubCategories(categories, mainCategoryId){
            return categories.filter(element => element.parent_id == mainCategoryId)
        },
        // categoryId can be subcategory or main category
        getInterests(interests, categoryId){
            return interests.filter(element => element.category_id == categoryId)
        },
        toggleInterest(interest){
            if(this.isInterestSelected(interest)){
                this.val = this.val.filter(element => element.id !== interest.id)
            }else{
                this.val.push(interest)
            }
            this.$emit('input', this.val)
            this.mainKey = !this.mainKey
        },
        isInterestSelected(interest){
            const found = this.val.find(element => element.id === interest.id)
            if(found){
                return true
            }else{
                return false
            }
        },
        toggleMainCategoryDropdown(index){
            if(!this.mainCategories[index].dropdownVisible){
                this.mainCategories[index].dropdownVisible = true
            }else{
                this.mainCategories[index].dropdownVisible = false
            }
            this.mainKey = !this.mainKey
        },
        toggleSubCategory(mainIndex, subCategoryIndex){
            if(!this.mainCategories[mainIndex].sub_categories[subCategoryIndex].dropdownVisible){
                this.mainCategories[mainIndex].sub_categories[subCategoryIndex].dropdownVisible = true
            }else{
                this.mainCategories[mainIndex].sub_categories[subCategoryIndex].dropdownVisible = false
            }
            this.mainKey = !this.mainKey        
        },
        clearAll(){
            this.val = []
            this.$emit('input', this.val)
            this.mainKey = !this.mainKey
        },
        async getData(){
            this.loading = true
            try{
                
                // get interest categories
                let { data } = await this.$http.get('/api/interests/categories');
                const categories = data

                // get interests
                const res = await this.$http.get('/api/interests')
                const interests = res.data

                const main_categories = this.getMainCategories(categories)
                for (const main_category of main_categories) {
                    // check subcategories
                    const sub_categories = this.getSubCategories(categories, main_category.id)
                    if(this.isValidArray(sub_categories)){
                        main_category.sub_categories = sub_categories
                        // check interests
                        for (const sub_category of main_category.sub_categories) {
                            const sub_category_interests =  this.getInterests(interests, sub_category.id)
                            if(this.isValidArray(sub_category_interests)){
                                sub_category.interests = sub_category_interests
                            }
                        }
                    }else{
                        // if it does not have subcategories, we check direct intrests
                        const main_category_interests = this.getInterests(interests, main_category.id)
                        if(this.isValidArray(main_category_interests)){
                            main_category.interests = main_category_interests
                        }
                    }
                }

                this.mainCategories = main_categories

            }catch(err){
                console.log(err)
                notifyCatchError(err, this.$notify)
            }finally{
                this.loading = false
            }
      
        }
    }
}
</script>
<style lang="scss" scoped>
    .interests-container{
        @apply h-full flex flex-col justify-start items-center;
        .title{
            @apply text-h3 font-bold mb-6;
        }
        .header{
            width: 320px;
            .count{
                @apply w-full text-right;
            }
            .buttons{
                @apply mt-2 flex justify-between items-center;
            }
        }
        .main-categories{
            @apply flex flex-col gap-y-4 mt-6;
            > .item{
                @apply flex flex-col gap-y-3;
                > .content{
                    @apply relative flex justify-between items-center gap-x-4;
                    > .name{
                        @apply text-h4 font-bold;
                    }
                    > button{
                        @apply cursor-pointer;
                    }
                }

                .interests{
                    @apply ml-4 flex flex-col gap-y-1;
                    > .item{
                        @apply flex justify-start items-center gap-x-3;
                        @apply cursor-pointer transition-colors duration-200;
                        &:hover{
                            @apply bg-gray-100;
                        }
                        > .name{
                            @apply text-xs;
                        }
                    }
                }

                > .sub-categories{
                    @apply ml-4 flex flex-col gap-y-4;
                    > .item{
                        @apply flex flex-col gap-y-3;
                        > .content{
                            @apply relative flex justify-between items-center gap-x-4;
                            > .name{
                                @apply font-bold text-h6;
                            }
                        }
                    }
                }
            }
        }
        .actions{
            @apply mt-10 flex flex-col items-center gap-y-6;
        }
    }
</style>

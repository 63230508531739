<template>
    <base-modal
        :visible="visible"
        title="Invite to change IG Account"
        @close="$emit('close')"
    >
        <div class="container">
            
            <div class="content">
                
                <div class="copy-links">

                    <div class="copy-link">
                        <div class="title">Instagram Business Account setup.</div>
                        <div class="form">
                            <FormulateInput 
                                readonly
                                :value="igBusinessLink"  
                                class="w-full" 
                            />
                            <base-button 
                                @action="copyIgBusinessLink()" 
                                :disabled="loading"
                                size="sm" 
                                label="Copy"
                            />
                        </div>
                    </div>
                    
                    <div class="copy-link">
                        <div class="title">Creator Instagram User setup.</div>
                        <div class="form">
                            <FormulateInput 
                                readonly
                                :value="igCreatorLink"  
                                class="w-full" 
                            />
                            <base-button 
                                @action="copyIgCreatorLink()" 
                                :disabled="loading"
                                size="sm" 
                                label="Copy"
                            />
                        </div>
                    </div>

                </div>
                
                <div class="send-email">
                    
                    <div class="title">Or send an email with the setup links</div>
                    
                    <div class="form">
                        <FormulateInput
                            v-model="email"
                            class="w-full"
                            placeholder="example@mail.com"
                            validation="email"
                        />

                        <FormulateInput
                            type="textarea"
                            class="w-full"
                            placeholder="Enter message..."
                            v-model="emailMessage"
                            input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-32"
                            :rows="1"
                        />
                    </div>
                </div>

            </div>
            <div class="footer">
                <base-button 
                    @action="$emit('close')" 
                    :disabled="loading" 
                    type="secondary" 
                    size="md" 
                    bold 
                    label="Cancel"
                />
                <base-button 
                    @action="sendEmail()" 
                    :disabled="loading" 
                    size="md" 
                    label="Send email" 
                    bold
                />
            </div>
        </div>
    </base-modal>
</template>
<script>
import { notifyCatchError } from '../common';
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            email: '',
            emailMessage: '',
            loading: false
        }
    },
    computed: {
        igBusinessLink() {
            return `https://help.instagram.com/502981923235522`;
        },
        igCreatorLink() {
            return `https://help.instagram.com/2358103564437429`;
        }
    },
    methods: {
        copyIgBusinessLink(){
            this.$copyToClipboard(this.$notify, this.igBusinessLink);
        },
        copyIgCreatorLink(){
            this.$copyToClipboard(this.$notify, this.igCreatorLink);
        },
        sendEmail(){
            this.loading = true
            this.$http.post('/api/facebook/change-ig-account-email', {
                email: this.email,
                message: this.emailMessage
            }).then(({ data }) => {
                if(data){
                    this.$notify({ title: 'Success', text: 'Email sent successfully', type: 'success' })
                }
            }).catch(err => {
                notifyCatchError(err, this.$notify);
            }).finally(() => {
                this.loading = false
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        .content{
            @apply flex flex-col gap-y-4;
            .copy-links{
                @apply flex flex-col gap-y-4;
                .copy-link{
                    @apply flex flex-col gap-y-1;
                    .title{
                        @apply text-black font-bold;
                    }
                    .form{
                        @apply flex justify-between gap-x-5;
                    }
                }
            }
            .send-email{
                .title{
                    @apply font-bold text-black;
                }
                .form{
                    @apply mt-1 flex flex-col gap-y-4;
                }
            }
        }
        .footer{
            @apply flex justify-end gap-3 rounded-2xl py-3;
        }
    }
</style>

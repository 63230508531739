<template>
    <span 
        :class="['avatar-container', displaySize, { 'rounded-full': rounded }, ...containerClasses]" 
        :style="{ 'background-color': bgColor }"
        @click="openAccount"
    >
        <img 
            :draggable="false"
            v-if="avatar && showImage" 
            :src="avatar"
            :class="[displaySize, { 'rounded-full': rounded }]"
            @error="handleError"
        >
        <img 
            v-else 
            :draggable="false"
            :src="randomAvatar"
            :class="[displaySize, { 'rounded-full': rounded }]"
        >
        <span
            v-if="!showImage && !randomAvatar"
            :class="['text-white', { 'text-2xs': size === 'small' }]" 
        >{{ initials }}</span>
    </span>
</template>

<script>
import stringToColor from  'string-to-color';
import { 
    avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7,
    avatar8, avatar9, avatar10, avatar11, avatar12, avatar13, avatar14
} from '../assets/images/avatars/index.js'
import { prepareAsB64 } from "../lib/strings";
import { defaultUserObject } from '../lib/user.js';

export default {
    props: {
        user: {},
        size: {},
        rounded: {
            type: Boolean,
            default: true
        },
        containerClasses: {
            type: Object,
            required: false,
            default: function () {
                return {}
            }
        }
    },
    
    data() {
        return {
            showImage: true,
            index: 0,
            avatars: [
                    avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7,
                    avatar8, avatar9, avatar10, avatar11, avatar12, avatar13, avatar14
                ]
        }
    },

    methods: {
        handleError() {
            this.index++;
            this.showImage = this.computedUser && this.computedArray[this.index] !== undefined;
        },
        openAccount() {
            if (this.$listeners && this.$listeners.callback) {
                this.$emit('callback', this);
                return null;
            }
            let link = '#';
            let account = this.computedUser.account && Array.isArray(this.computedUser.account) ? this.computedUser.account[0] : null;
            if (account) {
                if (account.direct) {
                    link = account.social_url || account.handle || account.social_id;
                } else {
                    link = `https://${account.network.name}.com/${this.getHandle(account)}`;
                }
                window.open(link, '_blank');
            }
        },

        getHandle(account) {
            let handle = account.network.name === 'tiktok' ? '@' : '';
            let username = account.handle ? account.handle : (account.social_id ? account.social_id : '');
            handle = handle + username;
            return handle;
        },
    },

    computed: {

        computedUser(){
            if(!this.user || this.user == null || this.user == undefined){
                return defaultUserObject
            }
            return this.user
        },

        computedArray() {
            if (this.computedUser.imagesArray !== undefined && Array.isArray(this.computedUser.imagesArray)) {
                let items = this.computedUser.imagesArray.filter(img => img && img !== '');
                return [...new Set(items)];
            }
            return [];
        },
        
        avatar() {
            if (this.computedArray.length) {
                if (this.computedArray[this.index] !== undefined) {
                    return prepareAsB64(this.computedArray[this.index]);
                }
            }

            if (this.computedUser.avatar && this.computedUser.avatar !== '') {
                return prepareAsB64(this.computedUser.avatar);
            }

            if (this.computedUser.social_accounts && this.computedUser.social_accounts.length > 0 && this.computedUser.social_accounts[0].avatar && this.computedUser.social_accounts[0].avatar !== '') {
                return prepareAsB64(this.computedUser.social_accounts[0].avatar);
            }

            return null;
        },

        randomAvatar(){
            const computedIndex = this.computedIndex
            return this.avatars[computedIndex];
        },

        computedIndex(){
            let id = this.computedUser && this.computedUser.id ? this.computedUser.id.toString() : '0';
            if(id.length == 1) id = `${0}${id}` // force it to be two digits
            id = `${id[id.length-1]}${id[id.length-2]}` // grab last two digits
            id = parseInt(id)

            // Get ranges progamatically: 
            // we just put the result down 
            // to not have to run this process for every avatar, but
            // this is how you can get a new one if we add new avatars etc

            // divide 100 by amount of avatars
            // const avatarRangeValue = 100 / this.avatars.length
            // let ranges = []
            // let start = 0
            // for(const avatar of this.avatars){
            //     ranges.push({from: start, to: start+avatarRangeValue})
            //     start = start+avatarRangeValue
            // }

            const ranges = [
                {from: 0, to: 7, i: 0},
                {from: 7, to: 14, i: 1},
                {from: 14, to: 21, i: 2},
                {from: 21, to: 28, i: 3},
                {from: 28, to: 35, i: 4},
                {from: 35, to: 42, i: 5},
                {from: 42, to: 50, i: 6},
                {from: 50, to: 57, i: 7},
                {from: 57, to: 64, i: 8},
                {from: 64, to: 71, i: 9},
                {from: 71, to: 78, i: 10},
                {from: 78, to: 85, i: 11},
                {from: 85, to: 92, i: 12},
                {from: 92, to: 99, i: 13}
            ]

            let index = 0
            for (const range of ranges) {
                if(range.from <= id && range.to > id){
                    index = range.i
                }
            }

            return index
        },

        initials() {

            if (this.computedUser.handle && this.computedUser.handle !== '') {
                return prepareAsB64(this.computedUser.handle);
            }
            
            if (this.computedUser.first_name && this.computedUser.last_name) {
                return `${this.computedUser.first_name[0]}${this.computedUser.last_name[0]}`.toUpperCase();
            }
            return '';
        },

        displaySize() {
            switch (this.size) {
                case 'small':
                    return 'h-6 w-6';
                case 'medium':
                    return 'h-9 w-9';
                case 'large':
                    return 'h-12 w-12';
                case 'x-large':
                    return 'h-15 w-15';
                case 'xl2':
                    return 'h-32 w-32';
                case 'custom':
                    return '';
                case 'auto':
                    return 'h-6 w-auto';
                default:
                    return 'h-12 w-12';
            }
        },

        bgColor() {
            return stringToColor(`${this.initials}`);
        }
    }
}
</script>
<style lang="scss" scoped>
    .avatar-container{
        @apply inline-flex items-center justify-center;
    }
</style>

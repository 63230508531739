import axios from 'axios';

export const boards = {
    namespaced: true,
    state: {
        boards: [],
        selectedBoard: null
    },
    mutations: {
        setSelectedBoard(state, val) {
            state.selectedBoard = val;
        },
        setBoards(state, val) {
            state.boards = val;
        },
        toggleBoardOption(state, {index, option, value}){
            state.boards = state.boards.map(board => {
                board.showActions = false
                board.editing = false
                board.editName = board.name
                board.deleting = false
                return board
            })
            state.boards[index][option] = value
        }
    },
    actions: {
        async setSelectedBoard({commit}, board) {
            await commit('setSelectedBoard', board);
        },
        async getBoards({commit, state}){

            if (state.boards.length > 0) {
                return;
            }

            const { data } = await axios.get('/api/boards');
            await commit('setBoards', data ? data.map(board => {
                board.showActions = false
                board.editing = false
                board.editName = board.name
                board.deleting = false
                return board
            }) : []);
        },
        async toggleBoardOption({commit}, options){
            await commit('toggleBoardOption', options)
        }
    },
    modules: {
    },
    getters : {
    }
};
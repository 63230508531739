<template>
    <div class="additional-info">
        
        <div class="content" v-if="isArray(value)">
            <div class="title">{{value.length}} item{{value.length > 1 ? 's' : ''}}</div>
            <base-button 
                style="padding: 9px 0px; width:128px"
                label="View"
                bold
                size="sm"  
                @action="modalVisible = true" 
            />
        </div>
        <div v-else class="empty-state">
            <div class="title">No info</div> 
            <base-button 
                style="padding: 9px 0px; width:128px"
                label="Add info"
                bold
                size="sm"  
                @action="modalVisible = true" 
            />
        </div>

        <base-table-column-additional-info-modal
            :visible="modalVisible"
            :value="value"
            :readonly="readonly"
            @update:value="val => $emit('update:value', val)"
            @close="modalVisible = false"
        />

    </div>
</template>
<script>
import { isArray } from '../common';
import BaseTableColumnAdditionalInfoModal from './BaseTableColumnAdditionalInfoModal.vue';
export default {
    props: {
        value: {
            type: Array,
            required: true
        },
        readonly: {
            type: Boolean,
            required: true
        }
    },
    components: {
        BaseTableColumnAdditionalInfoModal
    },
    data(){
        return {
            modalVisible: false,
            isArray
        }
    }
}
</script>
<style lang="scss" scoped>
    .additional-info{
        .content{
            @apply flex flex-col gap-y-1 items-center;
            .title{
                @apply font-bold;
            }   
        }
        .empty-state{
            @apply flex flex-col items-center gap-y-1;
            > .title{
                @apply font-bold;
            }
        }
    }
</style>

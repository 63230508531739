<template>
    <div>
        
        <Header title="People" :count="people.length" :loading="loading" />
        
        <div class="people-list">
            <div v-if="!people.length">No people were found</div>
            <div class="item" v-for="(person, index) in people" :key="index">

                <!-- <div>ID: {{person.id}}</div> -->
                <div>Name: {{person.name}}</div>
                <div>Role: {{person.role}}</div>

                <div v-if="person.business">
                    <!-- <div>Business ID{{person.business.id}}</div> -->
                    <div>Business: {{person.business.name}}</div>
                </div>
            </div>
        </div>
 
    </div>
</template>

<script>
import Header from './Header.vue'
import { mapGetters } from 'vuex';
export default {
    components: {
        Header
    },
    data(){
        return {
            people: [],
            loading: false
        }
    },
    mounted() {
        this.getPeople()
    },
    computed: {
        ...mapGetters(['company', 'fbLogin']),
    },
    methods: {
        async getPeople(){
            this.loading = true
            this.$http.get('/api/facebook', {
                params: {
                    entity_id: this.company.fb_business_id,
                    edge: 'business_users',
                    user_access_token: true
                }
            }).then(async (res) => {
                if(res.data){
                    this.people = res.data
                }
            }).catch((err) => {
                console.log('getPeople error', err)
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .people-list{
        @apply flex flex-col mt-6 gap-y-4 items-start;
        .item{
            @apply w-full;
            @apply px-6 py-2 rounded-xl bg-tan-m;
        }
    }
</style>
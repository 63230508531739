<template>
<div class="flex justify-center mt-8">Redirecting...</div>
</template>
    
<script>
import { mapGetters } from 'vuex';
export default {
    components: {
    },
    data() {
        return {
        }
    },
    mounted(){
        console.log("token", this.$route.query.access_token);
        this.$router.push('/home');
    },
    computed: {
        ...mapGetters(['isGuest', 'user']),
        
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped></style>
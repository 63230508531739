<template>
    <base-modal
        title="Export Creators"
        :visible="visible"
        @close="$emit('close')"
    >
        <div class="export-creators">
            
            <!-- creators scope -->
            <div v-if="step == 1">
                
                <div class="font-bold text-h4 mb-4">Select how many creators you want to export:</div>
                
                <div class="flex justify-between">

                    <div class="scope-list">
                        <div 
                            @click="toggleSelectedScope('currentPage')" 
                            class="option"
                            :class="{ 'active': selectedScope == 'currentPage' }"
                        >
                            <div class="left">
                                <div class="text">Export current page</div>
                                <div class="results">{{getFilterParams('perPage')}} creators</div>
                            </div>
                            <div class="right">
                                <base-icon name="export" />
                            </div>
                        </div>
                        <div 
                            @click="toggleSelectedScope('all')" 
                            class="option"
                            :class="{ 'active': selectedScope == 'all' }"
                        >
                            <div class="left">
                                <div class="text">Export all</div>
                                <div class="results">{{total}} creators</div>
                            </div>
                            <div class="right">
                                <base-icon name="export" />
                            </div>
                        </div>
                    </div>

                   <!--  <div style="width: 1px; height: 200px" class="bg-gray-200"></div>

                    <custom-select
                        :options="limitOptions"
                        v-model="limit"
                        style="width: 300px"
                    /> -->

                </div>

            </div>

            <!-- export to file type -->
           <!--  <div v-if="step == 2" class="file-type-list">
                <div class="font-bold text-h4 mb-4">Choose a file type:</div>
                <div 
                    @click="toggleSelectedFileType('csv')" 
                    class="option"
                    :class="{ 'active': selectedFileType == 'csv' }"
                >
                    <div class="font-bold">CSV</div>
                </div>
            </div> -->

            <!-- file name -->
            <!-- <div v-if="step == 3" class="file-type-list">
                <div class="font-bold text-h4 mb-4">Choose a file name:</div>
                <div class="flex items-center gap-x-2">
                    <FormulateInput
                        type="text"
                        style="width: 250px"
                        placeholder="creators"
                        label="File Name"
                        validation="required"
                        v-model="fileName"
                    />
                    <div>.{{selectedFileType}}</div>
                </div>
            </div> -->

            <!-- overview -->
            <div class="font-bold text-h4 flex flex-col flex-1" v-if="step == 2">
                <div class="flex-grow-0 flex-shrink-0">Export ({{count}}) creators to a {{selectedFileType}} file?</div>

                <div v-if="exporting" class="flex flex-col items-center justify-center gap-y-3 flex-grow flex-shrink">
                    <base-icon :size="10" name="loader" />
                    <div class="font-bold text-h4">Exporting {{count}} creators</div>
                </div>
            </div>

            <div class="footer">
                <base-button
                    v-if="step == 1"
                    type="secondary" 
                    label="Cancel"
                    @action="$emit('close')"
                    size="md"
                />
                <base-button
                    v-if="step > 1"
                    :disabled="exporting"
                    icon="arrow-left"
                    label="Back"
                    @action="step--"
                    size="md"
                />
                <base-button
                    v-if="step < 2"
                    :disabled="nextDisabled"
                    @action="step++"
                    class="font-bold"
                    size="md"
                    label="Next"
                    secondary-icon="arrow-right"
                />
                <base-button
                    v-if="step == 2"
                    @action="submit()"
                    class="font-bold"
                    label="Export"
                    icon="export"
                />
            </div>

        </div>
    </base-modal>
</template>

<script>
import columns from './columns.js'
import limitOptions from './limitOptions.js'
export default {
    props: {
        visible: {
            type: Boolean
        },
        total: {
            type: Number
        },
        pageTotal: {
            type: Number
        },
        companyColumns: {
            type: Array
        },
        pageCreators: {
            type: Array
        },
        searchTerm: {
            type: String
        },
        currentQueryString: {
            type: String  
        }
    },
    data() {
        return {
            step: 1,
            selectedScope: null,
            selectedFileType: null,
            fileName: 'creators',
            exporting: false,
            columns: columns,
            limitOptions: limitOptions,
            limit_val: 10
        }
    },
    computed: {
        count(){
            let count = this.selectedScope == 'currentPage' ? this.pageTotal : this.selectedScope == 'all' ? this.total : 0
            if(count <= this.limit_val){
                return count 
            }else{
                return this.limit_val
            }
        },
        filteredColumns(){
            return this.columns.filter(column => {
                return !column.id ? true : this.companyColumns.find(col => col.creator_column_id === column.id) ? true : false
            })
        },
        nextDisabled(){
            if(this.step == 1){
                return !this.selectedScope ? true : false
            }
            if(this.step == 2){
                return !this.selectedFileType
            }
            if(this.step == 3){
                return this.fileName == ''
            }
            return true
        }
    },
    methods: {

        getFilterParams(param_name) {
            const the_params = new URLSearchParams(this.currentQueryString);
            return the_params.get(param_name);

        },

        async submit() {
            const the_params = new URLSearchParams(this.currentQueryString);
            
            the_params.set('exportFile', true); 
            the_params.set('perPage', true); 

            try {
                
                if(this.selectedScope == 'all') {
                    
                    the_params.set('perPage', this.limit_val);

                    // It's generally safer to use the entries() method to iterate over the parameter/value pairs in a 
                    // URLSearchParams object, as it guarantees to only return the enumerable properties of the object.
                    for (const [key, value] of the_params.entries()) {
                        if (key !== 'exportFile' && key !== 'perPage') {
                            the_params.delete(key);
                        }
                    }
                }

                const response = await this.$http.get('/api/users/project-users-simple-es', {
                    responseType: 'blob',
                    params: the_params
                });

                const fileName = 'user-export.csv';
                const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');

                if (link.download !== undefined) {
                    
                    const url = URL.createObjectURL(blob);

                    link.setAttribute('href', url);
                    link.setAttribute('download', fileName);
                    link.style.visibility = 'hidden';
                    
                    document.body.appendChild(link);
                    
                    link.click();
                    document.body.removeChild(link);

                }

            } catch(e) {
                console.log(e);
                this.$notify({ title: 'Warning', text: 'An error occurred. Please try again.', type: 'warn' })
            } finally {
                
                this.step = 1
                this.selectedScope = null
                this.selectedFileType = null
                this.fileName = 'creators'
                this.limit = { label: 'Limit to 1000 creators', value: the_params.get('perPage') }
                this.exporting = false
                this.$emit('close')
            }
        },
        toggleSelectedScope(option){
            this.selectedScope = this.selectedScope == option ? null : option;
            const the_params = new URLSearchParams(this.currentQueryString);

            if ( this.selectedScope == "all" ) {
                this.limit_val = 10000;
            } else {
                this.limit_val = parseInt(the_params.get('perPage'));
            }

            console.log('this.limit_val', this.limit_val);


        },
        toggleSelectedFileType(option){
            this.selectedFileType = this.selectedFileType == option ? null : option;
        }
    }
}
</script>
<style lang="scss" scoped>
    .export-creators{
        min-height: 440px;
        @apply flex flex-col justify-between;
    }
    .scope-list, .file-type-list{
        @apply flex flex-col gap-y-4;
        .option{
            @apply px-6 py-4 bg-white rounded-lg border border-black;
            @apply flex items-center;
            @apply cursor-pointer transition-opacity duration-300;
            width: 300px;
            &:hover{
                @apply opacity-50;
            }
            &.active{
                @apply bg-purple-m-main text-white;                
            }
            .left{
                @apply flex-shrink flex-grow;
                .text{
                    @apply font-bold text-h4;
                }
                .results{
                    @apply text-gray-500;
                }
            }
            .right{
                @apply flex-shrink-0 flex-grow-0;
                @apply flex items-center;
            }
        }
    }
    .footer{
        @apply w-full flex justify-between items-center;
    }
</style>
<template>
    <div class="p-12 flex justify-between gap-x-12">

        <!-- left column -->
        <div class="flex-grow flex-shrink">
            
            <!-- title -->
            <skeleton :height="10" :width="72"/>

            <!-- description -->
            <div class="mt-8">
                <skeleton :height="8" :width="36"/>
                <skeleton :height="20" :full-width="true"/>
            </div>

            <!-- button -->
            <skeleton :height="12" :width="52" :full-rounded="true"/>

            <!-- attachment list -->
            <div class="mt-8 flex flex-col gap-y-2">
                <skeleton :height="20" :full-width="true"/>
                <skeleton :height="20" :full-width="true"/>
            </div>

            <!-- comments -->
            <div class="mt-8">
                <!-- title -->
                <skeleton :height="10" :width="72"/>

                <!-- textarea -->
                 <skeleton :height="20" :full-width="true"/>
                
                <div class="w-full flex justify-end">
                    <!-- button -->
                       <skeleton :height="12" :width="52" :full-rounded="true"/>
                </div>
            </div>

            <!-- comment list -->
            <!-- ... -->

        </div>

        <!-- left column -->
        <div style="width:350px" class="flex-grow-0 flex-shrink-0">

            <!-- status title -->
            <skeleton :height="10" :width="72"/>

            <!-- approval button -->
            <skeleton class="mt-8" :height="12" :width="52" :full-rounded="true"/>

            <!-- draft history -->
            <div class="mt-24">
                <!-- title -->
                <skeleton :height="10" :width="72"/>

                <div class="mt-8 flex gap-x-12">
                    <!-- vertical line -->
                    <skeleton :height="92" :width="2"/>
                    
                    <!-- versions list -->
                    <div class="flex-grow flex-shrink">
                        <skeleton :height="20" :full-width="true"/>
                        <skeleton :height="20" :full-width="true"/>
                        <skeleton :height="20" :full-width="true"/>
                        <skeleton :height="20" :full-width="true"/>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
}
</script>
